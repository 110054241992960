var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React from 'react';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../i18n';
import { UploadItemFile } from '../Uploads/UploadItem';
import UploadDropItem from '../Uploads/UploadDropItem';
import { getSnapshot } from 'mobx-state-tree';
import { Upload } from '../../models/Upload';
import { AddFormButton } from '../AddFormButton';
import { Grid, Typography } from '@material-ui/core';
import UploadItem from './Item';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { theme } from '../../theme';
import { inject } from 'mobx-react';
import { FetchUploadTypes } from '../../graphql/uploadType';
import { CircularIndeterminate } from '../CircularIndeterminate';
import { isCanBeSigned } from '../../utils';
var allFiles = [];
export default inject('store')(function (_a) {
    var _b, _c, _d, _e;
    var config = _a.config, value = _a.value, parent_collection_name = _a.parent_collection_name, store = _a.store, docType = _a.docType, noUpload = _a.noUpload, isBatch = _a.isBatch, props = __rest(_a, ["config", "value", "parent_collection_name", "store", "docType", "noUpload", "isBatch"]);
    if (props.singleFileV1)
        props.singleFile = true;
    var _f = __read(React.useState(value || getSnapshot(props.item).files), 2), files = _f[0], setFiles = _f[1];
    var langApp = useTranslation(KEYS.APP);
    var langForm = useTranslation(KEYS.FORM);
    var _g = __read(React.useState(__assign(__assign({}, props.UploadProps), JSON.parse(JSON.stringify(getSnapshot(Upload.create({
        parent_collection_name: parent_collection_name || 'invoices',
        parent: props.item && props.item._id,
    })))))), 2), tempNewItem = _g[0], setTempNewItem = _g[1];
    /*eslint-disable */
    React.useEffect(function () {
        config &&
            config.client
                .query({
                query: FetchUploadTypes,
                variables: {
                    filter: {
                        fields: [
                            { name: 'existOrNew', value: docType || (parent_collection_name === 'terms' ? 'Terms' : 'Invoice') },
                        ],
                    },
                },
            })
                .then(function (res) {
                if (res.data.uploadTypes.data.length)
                    setTempNewItem(__assign(__assign({}, tempNewItem), { type: res.data.uploadTypes.data[0] }));
            });
        allFiles = files;
        return function () {
            allFiles = [];
        };
    }, []);
    /*eslint-enable */
    var _onAdd = function (_files) {
        if (props.allowToAccessSign) {
            _files = _files.map(function (file) {
                return __assign(__assign({}, file), { isCanBeSigned: isCanBeSigned(file, props) });
            });
        }
        if (props.singleFile) {
            allFiles = __spread(_files);
        }
        else {
            allFiles = __spread(allFiles, _files);
        }
        if (!props.singleFileV1) {
            setFiles(allFiles);
        }
        if (props.onChange) {
            props.onChange({ target: { name: props.name, value: allFiles } });
        }
        else {
            store.invoiceStore.updateItemDetails({ files: allFiles });
        }
    };
    var _onUpdate = function (file) {
        allFiles = allFiles.map(function (inner_file) { return (file._id === inner_file._id ? file : inner_file); });
        setFiles(allFiles);
        if (props.onChange) {
            props.onChange({ target: { name: props.name, value: allFiles } });
        }
        else {
            store.invoiceStore.updateItemDetails({ files: allFiles });
        }
    };
    var _onDelete = function (file) {
        var _newFiles = files.filter(function (e) { return e._id !== file._id; });
        setFiles(_newFiles);
        allFiles = _newFiles;
        if (props.onChange) {
            props.onChange({ target: { name: props.name, value: _newFiles } });
        }
        else {
            store.invoiceStore.updateItemDetails({ files: _newFiles });
        }
    };
    if (props.singleFileV1 && files.length) {
        return (React.createElement(UploadItem, { key: files[0]._id, file: files[0], config: config, onUpdate: _onUpdate, singleFileV1: props.singleFileV1, parent_collection_name: parent_collection_name, onDelete: (((_c = (_b = files[0]) === null || _b === void 0 ? void 0 : _b.createdBy.company) === null || _c === void 0 ? void 0 : _c._id) === store.accountStore.currentCompany._id ||
                ((_e = (_d = files[0]) === null || _d === void 0 ? void 0 : _d.createdBy) === null || _e === void 0 ? void 0 : _e._id) === store.accountStore.currentUser._id) &&
                _onDelete }));
    }
    return (React.createElement(UploadDropItem, { isBatch: isBatch, singleFile: props.singleFile, singleFileV1: props.singleFileV1, config: config, onChange: props.singleFileV1 && files.length ? null : _onAdd, UploadProps: tempNewItem, noUpload: noUpload },
        files.length ? (React.createElement(Grid, { container: true, direction: 'column', justify: 'center', style: __assign({ padding: theme.spacing(2) }, (props.singleFileV1 && { padding: 0 })) },
            !props.singleFileV1 && !files.length && (React.createElement(InsertDriveFileIcon, { style: { margin: 'auto', width: 64, height: 64 } })),
            files.map(function (e) {
                var _a, _b, _c, _d;
                return (React.createElement(UploadItem, { key: e._id, file: e, config: config, onUpdate: _onUpdate, parent_collection_name: parent_collection_name, singleFileV1: props.singleFileV1, onDelete: (((_b = (_a = files[0]) === null || _a === void 0 ? void 0 : _a.createdBy.company) === null || _b === void 0 ? void 0 : _b._id) === store.accountStore.currentCompany._id ||
                        ((_d = (_c = files[0]) === null || _c === void 0 ? void 0 : _c.createdBy) === null || _d === void 0 ? void 0 : _d._id) === store.accountStore.currentUser._id) &&
                        _onDelete }));
            }))) : null,
        props.singleFileV1 && files.length ? null : (React.createElement(UploadItemFile, { isBatch: isBatch, noUpload: noUpload, config: config, InputProps: __assign({ multiple: !props.singleFile }, props.InputProps), UploadProps: tempNewItem, onChange: _onAdd, fileInput: props.fileInput, actionInit: function (loading, onShow, progress) { return (React.createElement(Grid, { container: true, alignItems: 'center', justify: 'center' },
                props.singleFileV1 && !files.length ? React.createElement(InsertDriveFileIcon, { style: { width: 25, height: 25 } }) : null,
                React.createElement(AddFormButton, { disabled: loading, withoutIcon: props.singleFileV1, onClick: props.onShow || onShow, title: [
                        props.singleFile ? langApp.t('add_file') : langForm.t('form_invoice_create_add_file'),
                        props.helpTitle,
                    ]
                        .filter(function (e) { return e; })
                        .join('. ') }),
                loading ? (React.createElement(Grid, { container: true, alignItems: 'center', style: { width: 'initial' }, key: progress },
                    React.createElement(Typography, null,
                        " ", " " + progress + "%",
                        "..."),
                    React.createElement(CircularIndeterminate, __assign({ size: '1.5rem', variant: "determinate", value: progress }, (progress && { progress: progress }))))) : null)); } }))));
});
