var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import clsx from 'clsx';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
var useStyles = makeStyles(function (theme) {
    return createStyles({
        root: {
            display: 'flex',
            '& > * + *': {
                marginLeft: theme.spacing(2),
            },
        },
    });
});
export var CircularIndeterminate = function (p) {
    var classes = useStyles();
    return (React.createElement("div", { className: clsx(classes.root, p.className), id: "izi-loading" },
        React.createElement(CircularProgress, __assign({}, p))));
};
