var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import { inject, observer, useObserver } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Grid, MenuItem } from '@material-ui/core';
import { FormikTextField } from '../../../../components';
import { FormikSelect } from '../../../../components';
import { Formik, Form } from 'formik';
import { getSnapshot } from 'mobx-state-tree';
import { OrderCurrency } from '../../../../models';
import { OrderStatus } from '../../../../models';
import { canEdit } from '../utils';
import * as Yup from 'yup';
import { KEYS } from '../../../../i18n';
import { useSectionStyles, useFormStyles } from '../../../../styles';
import { onlySpacesRegExp } from '../../../../common';
import { OrderRestore } from './OrderRestore';
import { FormikCheckBoxField } from '../../../Formik/FormikCheckboxField';
import PublishOrder from './PublishOrder';
import PaddingSpace from './PaddingSpce';
import AddSplitButton from '../../../../pages/Invoices/AddButton';
import { OrderCreationMode } from '../../../../pages/Order/FormV1';
export var CreationForm = inject('store')(observer(React.forwardRef(function (_a, formRef) {
    var store = _a.store, noQuotes = _a.noQuotes, props = __rest(_a, ["store", "noQuotes"]);
    var langOrderForm = useTranslation(KEYS.ORDER_FORM);
    var langForm = useTranslation(KEYS.FORM);
    var classes = useSectionStyles();
    var classesForm = useFormStyles();
    var order = store.orderStore.order;
    var defaultValue = JSON.parse(JSON.stringify(getSnapshot(order)));
    ['price', 'templateName'].forEach(function (e) {
        if (!defaultValue[e])
            defaultValue[e] = '';
    });
    var _b = __read(React.useState(false), 2), loading = _b[0], setLoading = _b[1];
    var _c = __read(React.useState(defaultValue), 2), input = _c[0], setInput = _c[1];
    var onChange = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, name_1, value, v, e_1;
        var _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _c.trys.push([0, 2, , 3]);
                    _a = e.target, name_1 = _a.name, value = _a.value;
                    if (input[name_1] === value) {
                        return [2 /*return*/, console.warn('No changes!')];
                    }
                    setLoading(true);
                    return [4 /*yield*/, formRef.current.setFieldValue(name_1, value)];
                case 1:
                    _c.sent();
                    if (formRef.current.errors[name_1]) {
                        setLoading(false);
                        return [2 /*return*/, formRef.current.setFieldTouched(name_1)];
                    }
                    v = (_b = {
                            _id: order._id
                        },
                        _b[name_1] = value,
                        _b);
                    store.orderStore.updateOrder(v);
                    setInput(__assign(__assign({}, input), v));
                    setLoading(false);
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _c.sent();
                    setLoading(false);
                    throw e_1;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var onRestore = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, props.onSubmit()];
                case 1: return [2 /*return*/, (_a.sent()) > 0];
            }
        });
    }); };
    var noEdit = !canEdit(order, store); //|| store.orderStore.isOrderUpdating;
    var isRestore = location.href.toLowerCase().match(/restore/);
    var noEditDate = noEdit && !isRestore;
    var defValue = __assign(__assign({}, defaultValue), { rangeDates: [order.startDate, order.endDate] });
    ['price'].forEach(function (e) {
        if (!defValue[e])
            defValue[e] = '0';
    });
    var validationSchema = __assign({ price: Yup.number()
            .min(0, langForm.t('form_input_validate_min_size').replace(/_X_/g, '0'))
            .max(100000000, langForm.t('form_input_validate_max_size').replace(/_X_/g, '100000000')) }, (defValue.isTemplate
        ? {
            templateName: Yup.string()
                .matches(onlySpacesRegExp, langForm.t('form_input_validate_invalid_text'))
                .max(50, langForm.t('form_input_validate_max_char').replace(/_X_/g, '50'))
                .required(langForm.t('form_input_validate_required')),
        }
        : {
            templateName: Yup.string()
                .matches(onlySpacesRegExp, langForm.t('form_input_validate_invalid_text'))
                .max(50, langForm.t('form_input_validate_max_char').replace(/_X_/g, '50')),
        }));
    // if (noQuotes || order.noQuotes) {
    //   Object.assign(validationSchema, {
    //     price: Yup.number()
    //       .min(0, langForm.t('form_input_validate_min_size').replace(/_X_/g, '0'))
    //       .max(100000000, langForm.t('form_input_validate_max_size').replace(/_X_/g, '100000000'))
    //       .required(langForm.t('form_input_validate_required')),
    //   });
    // }
    var buttons = [
        {
            state: OrderCreationMode.BASIC,
            button: React.createElement(PublishOrder, __assign({}, props, { loading: loading, noQuotes: noQuotes })),
        },
        order.status === OrderStatus.Draft &&
            !order.isTemplate &&
            store.accountStore.currentCompany.settings.canPublishOrderForAnotherUser
            ? {
                state: OrderCreationMode.BASIC,
                button: React.createElement(PublishOrder, __assign({}, props, { loading: loading, noQuotes: noQuotes, canPublishOrderForAnotherUser: true })),
                disabled: order.noQuotes,
                disabledTitle: langOrderForm.t('order_form_publish_for_inner_order'),
            }
            : null,
        !order.preparedAt && !order.isTemplate
            ? {
                state: OrderCreationMode.PREPARATION,
                button: React.createElement(PublishOrder, __assign({}, props, { loading: loading, noQuotes: noQuotes, canStartPreparing: true })),
            }
            : null,
    ].filter(function (e) { return e; });
    var publishOrder = buttons.length < 2 || props.selectedTab === 0 ? ((buttons.length && buttons[0].button) || null) : (React.createElement(AddSplitButton, { v1: true, v2: true, buttons: buttons }));
    return useObserver(function () { return (React.createElement("div", { className: classes.section },
        React.createElement(Formik, { innerRef: formRef, key: order._id + store.orderStore.isOrderPublishing, initialValues: defValue, onSubmit: function () { return ({}); }, validationSchema: Yup.object(validationSchema) }, function (formik) {
            return (React.createElement(Form, { id: 'creation-form' },
                React.createElement(Grid, { container: true, direction: "row", alignItems: "center", spacing: 3 },
                    React.createElement(PaddingSpace, null),
                    React.createElement(Grid, { item: true, xs: 12, md: 3 },
                        React.createElement(FormikCheckBoxField, { disabled: noEdit || formik.values.isTemplate || order.status !== OrderStatus.Draft, label: langOrderForm.t('create_order_template_name_label_as'), name: formik.values.isTemplate ? 'isTemplate' : 'asTemplate', onChange: function (e) { return onChange({ target: { name: 'asTemplate', value: e.target.checked } }); }, color: 'primary', value: formik.values.isTemplate || formik.values.asTemplate, style: { padding: 0 }, labelStyle: { margin: 0 } })),
                    React.createElement(Grid, { item: true, xs: 12, md: 3 },
                        React.createElement(FormikTextField, { disabled: noEdit || !formik.values.asTemplate || order.status !== OrderStatus.Draft, title: langOrderForm.t('create_order_template_name_label'), help: langForm.t('form_input_help_order_template_as'), variant: "outlined", name: "templateName", onBlur: onChange, value: formik.values.templateName })),
                    React.createElement(Grid, { container: true, direction: "column", item: true, xs: 12, md: 2 },
                        React.createElement(Grid, { container: true, direction: "row", spacing: 0 },
                            React.createElement(Grid, { item: true, xs: 7 },
                                React.createElement(FormikTextField, { disabled: noEdit, fullWidth: true, title: langForm.t('form_input_name_price'), className: classesForm.priceInput, variant: "outlined", type: "number", name: "price", value: formik.values.price, onBlur: onChange })),
                            React.createElement(Grid, { item: true, xs: 5 },
                                React.createElement(FormikSelect, { v22: true, disabled: noEdit, fullWidth: true, className: classesForm.currencyInput, variant: "outlined", title: langForm.t('form_invoice_currency'), name: "currency", value: formik.values.currency, onChange: onChange }, Object.keys(OrderCurrency).map(function (value) {
                                    return (React.createElement(MenuItem, { key: value, value: value }, value));
                                }))))),
                    React.createElement(Grid, { item: true, xs: 12, md: 2, container: true, alignItems: 'flex-end' }, isRestore ? (([OrderStatus.Finished, OrderStatus.ApprovedQuote].includes(order.status) && (React.createElement(Grid, { item: true, style: { marginBottom: 3, paddingRight: 0 }, xs: 12, container: true },
                        React.createElement(OrderRestore, { order: defaultValue, onSubmit: onRestore, config: props.config })))) ||
                        null) : (React.createElement(Grid, { item: true, style: { paddingRight: 0 }, xs: 12 }, publishOrder))))));
        }))); });
})));
