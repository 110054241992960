var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DescriptionIcon from '@material-ui/icons/Description';
import List from '@material-ui/core/List';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { MenuItem, useMediaQuery } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../../i18n';
import { inject, observer } from 'mobx-react';
import { BaseUserAppTYpe } from '../../../stores/common';
import { OrderRouteType, OrderStatus } from '../../../models';
import { theme } from '../../../theme';
import { CircularIndeterminate } from '../../CircularIndeterminate';
import OrderRouteMenuItem from './OrderRoutesMenuItem';
import { MainButton } from '../../MainButton';
import { ACTION_CHANGE } from '../../BasicList';
import { Form, Formik } from 'formik';
import { FormikSelect } from '../../Formik';
import * as Yup from 'yup';
import { TextOverflow } from '../../TextOverflow';
import BaseTooltip from '../../BaseTooltip';
import { HTTP } from '../../../api/apolloClient';
export var useCMRStyles = makeStyles(function (theme) {
    return createStyles({
        appBar: {
            position: 'relative',
        },
        title: {
            marginLeft: theme.spacing(2),
            flex: 1,
            color: theme.palette.common.white,
        },
        ul: {
            '& textarea': {
                resize: 'none',
            },
        },
    });
});
var Transition = React.forwardRef(function Transition(props, ref) {
    return React.createElement(Slide, __assign({ direction: "up", ref: ref }, props));
});
var OrderCMR = inject('store')(observer(function (_a) {
    var _this = this;
    var order = _a.order, props = __rest(_a, ["order"]);
    var _b = props.store, orderStore = _b.orderStore, _c = _b.accountStore, currentUser = _c.currentUser, currentCompany = _c.currentCompany;
    var formRef = React.useRef();
    var isCustomer = currentUser.appType.includes(BaseUserAppTYpe.CUSTOMER);
    var isSenderConsignee = [BaseUserAppTYpe.SENDER, BaseUserAppTYpe.CONSIGNEE].find(function (t) {
        return currentUser.appType.includes(t);
    });
    var isForwarder = currentUser.appType.includes(BaseUserAppTYpe.FORWARDER);
    var isWarehouse = currentUser.appType.includes(BaseUserAppTYpe.WAREHOUSE); //|| currentUser.roles.includes(USER_ROLE.ADMIN);
    var isCompaniesOrder = (order.companies || []).includes(currentCompany._id);
    var customerOrderAvailable = (isCustomer || isForwarder || isWarehouse || isSenderConsignee) &&
        isCompaniesOrder &&
        [OrderStatus.ApprovedQuote, OrderStatus.Finished].includes(order.status);
    var langOrderList = useTranslation(KEYS.ORDER_LIST);
    var langOrderForm = useTranslation(KEYS.ORDER_FORM);
    var langForm = useTranslation(KEYS.FORM);
    var classes = useCMRStyles();
    var container = React.useRef(null);
    var _d = __read(React.useState(false), 2), open = _d[0], setOpen = _d[1];
    var _e = __read(React.useState(false), 2), pending = _e[0], setPending = _e[1];
    var _f = __read(React.useState(false), 2), loading = _f[0], setLoading = _f[1];
    var _g = __read(React.useState(null), 2), quote = _g[0], setQuote = _g[1];
    var _h = __read(React.useState(null), 2), transportation = _h[0], setTransportation = _h[1];
    var loadData = function () { return __awaiter(_this, void 0, void 0, function () {
        var _quote, _tr, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 4, 5, 6]);
                    if (!open) return [3 /*break*/, 3];
                    setPending(true);
                    _quote = quote;
                    if (!!_quote) return [3 /*break*/, 2];
                    return [4 /*yield*/, orderStore.loadOrderQuote(order.latestQuote._id)];
                case 1:
                    _quote = _a.sent();
                    setQuote(_quote);
                    _a.label = 2;
                case 2:
                    if (open && order.latestQuote && order.latestQuote._id) {
                        _tr = ((_quote === null || _quote === void 0 ? void 0 : _quote.order) || order).transportations[0];
                        if (_tr)
                            setTransportation(_tr);
                    }
                    _a.label = 3;
                case 3: return [3 /*break*/, 6];
                case 4:
                    e_1 = _a.sent();
                    return [3 /*break*/, 6];
                case 5:
                    setPending(false);
                    return [7 /*endfinally*/];
                case 6: return [2 /*return*/];
            }
        });
    }); };
    /*eslint-disable */
    React.useEffect(function () {
        loadData();
        return function () {
            return setTransportation(null);
        };
    }, [open]);
    /*eslint-enable */
    /*eslint-disable */
    React.useEffect(function () {
        if ((transportation === null || transportation === void 0 ? void 0 : transportation._id) && !transportation.templateCMR && !loading) {
            setLoading(true);
            HTTP({ url: "transportation-cmr/" + transportation._id, isPath: true })
                .then(function (res) {
                setTransportation(__assign(__assign({}, transportation), { templateCMR: { html: res.templateHTML } }));
            })
                .finally(function () {
                setLoading(false);
            });
        }
    }, [transportation === null || transportation === void 0 ? void 0 : transportation._id]);
    /*eslint-enable */
    if (!customerOrderAvailable) {
        return null;
    }
    var _j = props.store.orderStore, createHtmlTemplateItem = _j.createHtmlTemplateItem, isOrderUpdating = _j.isOrderUpdating, isOrderLoading = _j.isOrderLoading;
    var handleClickOpen = function () {
        setOpen(true);
    };
    var handleClose = function () {
        setOpen(false);
    };
    var onSave = function () { return __awaiter(_this, void 0, void 0, function () {
        var html, _a, e_2;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, 3, 4]);
                    html = container.current.innerHTML;
                    // const parser = new DOMParser();
                    // const doc = parser.parseFromString(html, 'text/xml');
                    // const error: any = doc.documentElement.querySelector('parsererror');
                    // if (error?.innerText) {
                    //   toast.error(`You probably provided invalid value, please reload the page. ${error?.innerText}`);
                    //   console.log(error?.innerText, html);
                    //   return;
                    // }
                    _a = transportation;
                    return [4 /*yield*/, createHtmlTemplateItem({
                            parent: transportation._id,
                            html: html,
                        })];
                case 1:
                    // const parser = new DOMParser();
                    // const doc = parser.parseFromString(html, 'text/xml');
                    // const error: any = doc.documentElement.querySelector('parsererror');
                    // if (error?.innerText) {
                    //   toast.error(`You probably provided invalid value, please reload the page. ${error?.innerText}`);
                    //   console.log(error?.innerText, html);
                    //   return;
                    // }
                    _a.templateCMR = _b.sent();
                    if (props.onChange)
                        props.onChange(transportation, ACTION_CHANGE.DELETED);
                    handleClose();
                    return [3 /*break*/, 4];
                case 2:
                    e_2 = _b.sent();
                    console.log(e_2);
                    return [3 /*break*/, 4];
                case 3: return [7 /*endfinally*/];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var _onSubmit = function (model) {
        var _a;
        if (((_a = model.transportation) === null || _a === void 0 ? void 0 : _a._id) === transportation._id) {
            return model;
        }
        setTransportation(model.transportation);
        return model;
    };
    var initialValues = { transportation: transportation || { _id: '' } };
    var theme1 = useTheme();
    var isSmall = useMediaQuery(theme1.breakpoints.down('sm'));
    var dialogBody = (React.createElement(Formik, { innerRef: formRef, initialValues: initialValues, validationSchema: Yup.object({
            transportation: Yup.object({
                _id: Yup.string(),
            }).test('_id', langForm.t('form_input_validate_required'), function (val) {
                return !!val._id;
            }),
        }), onSubmit: _onSubmit }, function (formik) {
        return (React.createElement(Form, { style: { width: '100%', padding: theme.spacing(2) }, id: 'form-order-cmr', name: 'form-order-cmr' },
            React.createElement(Grid, { container: true, spacing: 2, alignItems: 'center' },
                React.createElement(Grid, { item: true, md: 2 },
                    React.createElement(TextOverflow, null, langOrderForm.t('create_order_transportation_label'))),
                React.createElement(Grid, { item: true, md: 8 },
                    React.createElement(FormikSelect, { fullWidth: true, variant: "outlined", name: "transportation", value: formik.values.transportation }, (quote.order || order).transportations.map(function (value, index) {
                        var pickup = value.routes.find(function (e) { return e.type === OrderRouteType.Preferred; });
                        var deivery = value.routes.find(function (e) { return e.type === OrderRouteType.Delivery; });
                        return (React.createElement(MenuItem, { key: value, value: value }, langOrderForm.t('create_order_transportation_label') + " " + (index + 1) + " " + [
                            [pickup.origin.address.country, pickup.origin.address.postal].filter(function (e) { return e; }).join(' '),
                            [deivery.origin.address.country, deivery.origin.address.postal].filter(function (e) { return e; }).join(' '),
                        ]
                            .filter(function (e) { return e; })
                            .join('-')));
                    }))),
                React.createElement(Grid, { item: true, md: 2 },
                    React.createElement(MainButton, { type: 'submit', title: langForm.t('form_btn_confirm'), onClick: function () { return formRef.current.submitForm(); } })))));
    }));
    var content = ((pending || loading) && (React.createElement(Grid, { container: true, justify: 'center' },
        React.createElement(CircularIndeterminate, null)))) ||
        (!transportation && React.createElement("span", null)) ||
        (transportation.templateCMR && (React.createElement("div", { ref: function (dom) {
                if (dom)
                    dom.addEventListener('input', function (e) {
                        if (e.target.tagName === 'TEXTAREA') {
                            e.target.textContent = e.target.value;
                        }
                        e.target.setAttribute('value', e.target.value);
                    });
            }, dangerouslySetInnerHTML: {
                __html: transportation.templateCMR.html,
            } }))) ||
        null;
    var icon = (React.createElement(BaseTooltip, { title: langOrderList.t('in_progress_cmr') },
        React.createElement(IconButton, { onClick: (!props.label && handleClickOpen), color: 'primary', style: props.iconStyle, id: 'btn-create-cmr' },
            React.createElement(DescriptionIcon, null))));
    return (React.createElement(React.Fragment, null,
        props.label ? React.createElement(OrderRouteMenuItem, __assign({}, { icon: icon, label: props.label, handleClickOpen: handleClickOpen })) : icon,
        React.createElement(Dialog, { id: 'izi-dialog-CMR', fullScreen: true, open: open, disableEnforceFocus: true, onClose: handleClose, TransitionComponent: Transition },
            React.createElement(AppBar, { className: classes.appBar },
                React.createElement(Toolbar, null,
                    React.createElement(IconButton, { edge: "start", color: "inherit", onClick: handleClose, "aria-label": "close" },
                        React.createElement(CloseIcon, null)),
                    React.createElement(Typography, { variant: "h6", className: classes.title }, "CMR"),
                    (transportation && (React.createElement(Grid, { container: true, alignItems: 'center', style: { width: 'initial' } },
                        React.createElement(MainButton, { v1: true, id: 'btn-save-cmr', loading: isOrderUpdating || isOrderLoading, disabled: isOrderUpdating || isOrderLoading, onClick: onSave, title: langForm.t('form_btn_save') })))) ||
                        null)),
            React.createElement("div", { key: (transportation && transportation._id) || 1 },
                !pending && dialogBody,
                React.createElement(List, { ref: container, className: classes.ul, style: transportation && !isSmall
                        ? {
                            padding: theme.spacing(2),
                            width: 740,
                            position: 'relative',
                            margin: 'auto',
                            transform: 'scale(2)',
                            transformOrigin: 'top',
                        }
                        : {} }, content)))));
}));
export default OrderCMR;
