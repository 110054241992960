var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect } from 'react';
import { useObserver } from 'mobx-react';
import { Typography, Button, TextField, Grid, IconButton } from '@material-ui/core';
import { DatePicker, PickersDay } from '@material-ui/pickers';
import { MobileDatePicker } from '@material-ui/pickers';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { useField } from 'formik';
import { useRangeStyles } from './RangeDatePicker';
import moment from 'moment';
import { i18n, KEYS } from '../i18n';
import { toDateOnly } from '../utils';
import { TextOverflow } from './TextOverflow';
import { HelpIconComponent } from './HelpIcon';
import { theme } from '../theme';
import BaseTooltip from './BaseTooltip';
import { FormikTextField } from './Formik';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useStyles as useStylesFormik } from './Formik/styles';
var useCalendarStyles = makeStyles(function (theme) {
    return createStyles({
        root: {
            '& *': {
                color: theme.palette.primary.main,
                fill: theme.palette.primary.main,
            },
        },
        rootV2: {
            '& *': {
                color: theme.palette.grey['200'],
                fill: theme.palette.grey['200'],
            },
        },
    });
});
export var BaseSingleDatePicker = function (props) {
    var _a = __read(React.useState(props.defaultValue || props.value), 2), value = _a[0], setValue = _a[1];
    var title = props.title, onClose = props.onClose, onChange = props.onChange, onOpen = props.onOpen, is_mini = props.is_mini, moreProps = __rest(props, ["title", "onClose", "onChange", "onOpen", "is_mini"]);
    var _b = __read(React.useState(false), 2), open = _b[0], setOpen = _b[1];
    var rootRef = React.useRef();
    var classesCalendar = useCalendarStyles();
    var _c = __read(React.useState(false), 2), show = _c[0], setShow = _c[1];
    var classesFormik = useStylesFormik();
    var isTitleComponent = props.v1 || props.v14;
    var _onClose = function () {
        setShow(false);
        setOpen(false);
        onClose && onClose(value);
    };
    var _onChange = function (value) {
        var _newVal = (value && moment(value.format('YYYY/MM/DD'))) || value;
        onChange && onChange(_newVal);
        setValue(_newVal);
    };
    var inputProps = __assign(__assign(__assign({}, moreProps), { label: moreProps.placeholder }), (!isTitleComponent ? { placeholder: '' } : {}));
    if (!inputProps.disabled &&
        inputProps.minDate &&
        inputProps.maxDate &&
        !props.notDisablePast &&
        toDateOnly(inputProps.maxDate) < toDateOnly(new Date())) {
        inputProps.disabled = true;
        inputProps.helperText = i18n.getResource(i18n.language, KEYS.FORM, 'form_date_validate_expired');
    }
    if (!inputProps.minDate) {
        inputProps.minDate = moment(new Date()).startOf('day');
    }
    var style = { marginTop: 0, marginBottom: 0 };
    if (props.style)
        Object.assign(style, props.style);
    var onOpenV = function () {
        setOpen(true);
        if (onOpen)
            onOpen(moreProps.name, { target: { value: value } });
    };
    var dateProds = __assign(__assign({ id: 'date-picker', showToolbar: false, minDate: moment(new Date()).startOf('day'), inputFormat: 'DD/MM/YYYY' }, inputProps), { value: value, onChange: _onChange, open: open, onOpen: onOpenV, onClose: _onClose, renderInput: function (startDate) {
            var _a;
            var _b, _c;
            var label = ((_b = props.InputProps) === null || _b === void 0 ? void 0 : _b.placeholder) || ((_c = startDate.InputProps) === null || _c === void 0 ? void 0 : _c.placeholder);
            var errorText = (startDate.error &&
                startDate.helperText &&
                i18n.getResource(i18n.language, KEYS.FORM, 'form_date_validate_expired')) ||
                inputProps.helperText;
            // if (errorText && !is_mini) Object.assign(style, { marginBottom: 12 });
            if (props.v1) {
                return (React.createElement(Button, __assign({}, startDate, { ref: rootRef, onClick: onOpenV, color: props.v2 ? 'primary' : undefined, className: props.v2 ? classesCalendar.rootV2 : classesCalendar.root, style: __assign({ disaply: 'flex', alignItems: 'center', textTransform: 'capitalize' }, style) }),
                    React.createElement(BaseTooltip, { title: open
                            ? i18n.getResource(i18n.language, KEYS.APP, 'collapse_less')
                            : i18n.getResource(i18n.language, KEYS.APP, 'collapse_more') },
                        React.createElement(IconButton, { style: { padding: theme.spacing(0.5) } },
                            React.createElement(CalendarTodayIcon, null))),
                    React.createElement(TextOverflow, { variant: 'subtitle1', style: { marginRight: theme.spacing(1) } }, (value && moment(value).format(inputProps.inputFormat || 'yyyy')) || '')));
            }
            var finalInputProps = __assign(__assign(__assign(__assign(__assign({ id: props.id || (title || 'date-picker-input').toString().replace(/ /gi, '').toLowerCase(), fullWidth: true, margin: 'dense', variant: 'outlined', name: 'startDate', autoComplete: 'off' }, inputProps), { onFocus: function () {
                    setShow(true);
                }, className: clsx(inputProps.className, (_a = {}, _a[classesFormik.rootPrimaryStyle] = props.v21 && !inputProps.disabled, _a)) }), startDate), { InputProps: __assign(__assign(__assign({}, startDate.InputProps), (props.withLabel && {
                    placeholder: '',
                })), { startAdornment: props.v21 && (React.createElement(DateRangeIcon, { style: { marginRight: theme.spacing(1), fontSize: '1rem', color: theme.palette.primary.main } })) }), style: style, readOnly: true, helperText: !is_mini && errorText && React.createElement(TextOverflow, { v2: true }, errorText), label: !isTitleComponent && label }), (props.withLabel && {
                required: false,
                placeholder: '',
                title: '',
            }));
            var input = props.isFormik ? React.createElement(FormikTextField, __assign({}, finalInputProps)) : React.createElement(TextField, __assign({}, finalInputProps));
            if (is_mini) {
                return (React.createElement("div", { style: { position: 'relative' } },
                    input,
                    (is_mini && errorText && (React.createElement(HelpIconComponent, { style: { position: 'absolute', right: -7, top: -12, width: 20, height: 20 }, error: true, title: errorText }))) ||
                        null));
            }
            return input;
        } });
    // if (dateProds.components) {
    //   dateProds.components.ActionBar = MyActionBar;
    // } else {
    //   dateProds.components = {
    //     ActionBar: MyActionBar,
    //   };
    // }
    if (props.v1) {
        return (React.createElement(DatePicker, __assign({}, dateProds, { PopperProps: {
                anchorEl: function () { return rootRef.current; },
            } })));
    }
    return (React.createElement(MobileDatePicker, __assign({ disablePast: !props.notDisablePast, keyboard: true }, dateProds, { renderDay: function (day, selectedDays, pickersDayProps) { return (React.createElement(PickersDay, __assign({}, pickersDayProps, { style: props.specialEnd && props.specialStart && props.specialStart <= day && day <= props.specialEnd
                ? { fontWeight: 700 }
                : {} }))); } })));
};
var MyActionBar = function (_a) {
    var onAccept = _a.onAccept, onCancel = _a.onCancel, onClear = _a.onClear, onSetToday = _a.onSetToday;
    return (React.createElement(Grid, { container: true, alignItems: "center" },
        React.createElement(Button, { onClick: onClear }, " customCleanText "),
        React.createElement(Button, { onClick: onCancel }, " customCancelText "),
        React.createElement(Button, { onClick: onAccept }, " customAcceptText ")));
};
var date;
export var SingleDatePicker = function (props) {
    var title = props.title, value = props.value, onClose = props.onClose, onChange = props.onChange, onlyTextFieldError = props.onlyTextFieldError, moreProps = __rest(props, ["title", "value", "onClose", "onChange", "onlyTextFieldError"]);
    var classes = useRangeStyles();
    var _a = __read(React.useState(false), 2), show = _a[0], setShow = _a[1];
    var _b = __read(useField(props), 3), field = _b[0], meta = _b[1], helpers = _b[2];
    var error = meta.error, touched = meta.touched;
    var touchedError = (touched && error) || '';
    if (touchedError && typeof touchedError === 'object') {
        if (Object.keys(touchedError).length) {
            touchedError = touchedError.toString();
        }
        else {
            touchedError = '';
        }
    }
    /*eslint-disable */
    useEffect(function () {
        return function () {
            date = null;
        };
    }, []);
    /*eslint-enable */
    var _onClose = function (value) {
        setShow(false);
        onClose && onClose(date);
    };
    var _onChange = function (value) {
        helpers.setValue(value);
        onChange && onChange({ target: __assign(__assign({}, field), { value: value }) });
        date = value;
    };
    var inputProps = __assign({}, moreProps);
    if (inputProps.minDate && inputProps.maxDate && moment(inputProps.maxDate).isSameOrBefore(moment())) {
        inputProps.disabled = true;
    }
    if (!inputProps.minDate) {
        inputProps.minDate = new Date();
    }
    return useObserver(function () { return (React.createElement(React.Fragment, null,
        title && React.createElement(Typography, null, title),
        React.createElement(BaseSingleDatePicker, __assign({ value: value }, inputProps, { onClose: _onClose, onChange: _onChange })),
        !props.onlyTextFieldError && touchedError && React.createElement(TextOverflow, { v2: true }, touchedError))); });
};
export var DATE_FORMAT = 'DD MMM YYYY';
