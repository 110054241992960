var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React, { useEffect } from 'react';
import { inject, observer, useObserver } from 'mobx-react';
import * as material from '@material-ui/core';
import { Grid, useTheme, useMediaQuery } from '@material-ui/core';
import clsx from 'clsx';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import * as Yup from 'yup';
import { OrderRouteType } from '../../../../../models/OrderRoute';
import { OrderLocation, OrderLocationMode } from '../../../../../models/Location';
import { getSnapshot } from 'mobx-state-tree';
import { canEdit } from '../../utils';
import { OrderRoute } from '../../../../../models';
import { ACTION_CHANGE, TextOverflow } from '../../../../../components';
import { KEYS } from '../../../../../i18n';
import { FormLocationInput } from '../../../../../components';
import { LimitDateRangePicker } from '../../../../../components/LimitDateRangePicker';
import { FormikAutocomplete } from '../../../../../components';
import { formikAutocompleteMultiOptionsProps } from '../../../../../components';
import { toDateOnly } from '../../../../../utils';
import PreloadingComponent from '../../../../PreloadingComponent';
import PaddingSpace from '../PaddingSpce';
import { FormikIconCheckboxField } from '../../../../Formik/FormikIconCheckboxField';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { StrictlyRouteIcon } from '../../../../assets/components/StrictlyRouteIcon';
import { useRouteFormStyles } from '../../../../../styles';
import { useInputV1Styles } from '../../../../../styles/form/input';
import { FormikCheckBoxField } from '../../../../Formik/FormikCheckboxField';
import { OrderCreationMode } from '../../../../../pages/Order/FormV1';
var Box = material.Box;
var CargoStatusDetails = React.lazy(function () { return import('../../../../../pages/Quotes/CargoStatusDetails'); });
var dateTemp;
export var RouteFormItem = inject('store')(observer(React.forwardRef(function (_a, ref) {
    var store = _a.store, index = _a.index, maxLimit = _a.maxLimit, minLimit = _a.minLimit, client = _a.config.client, declineProps = _a.declineProps, locationType = _a.locationType, mode = _a.mode, props = __rest(_a, ["store", "index", "maxLimit", "minLimit", "config", "declineProps", "locationType", "mode"]);
    var _b = __read(React.useState(1), 2), updates = _b[0], setUpdates = _b[1];
    var _c = __read(React.useState(1), 2), updateLocationFilter = _c[0], setUpdateLocationFilter = _c[1];
    var classesRouteForm = useRouteFormStyles();
    var classesInputV1Styles = useInputV1Styles();
    var langForm = useTranslation(KEYS.FORM);
    var langOrderForm = useTranslation(KEYS.ORDER_FORM);
    var isManufacturing = location.href.toLowerCase().match(/manufacturing/);
    var isEdit = !!store.orderStore.order.publishedAt;
    var theme = useTheme();
    var _ref = React.useRef();
    ref = ref || _ref;
    var dateTitle = props.dateTitle;
    var defaultValue = JSON.parse(JSON.stringify(props.defaultValue || {}));
    if (!defaultValue.rangeDates)
        defaultValue.rangeDates = [];
    ['startDate', 'endDate'].forEach(function (key, index) {
        if (!defaultValue.rangeDates[index]) {
            defaultValue.rangeDates[index] = defaultValue[key] || null;
        }
    });
    if (!defaultValue.origin)
        delete defaultValue.origin;
    var v = __assign(__assign(__assign({}, getSnapshot(OrderRoute.create())), defaultValue), { isMyLocations: false });
    ['email'].forEach(function (e) {
        if (!v[e])
            v[e] = '';
    });
    var _d = __read(React.useState(v), 2), formValues = _d[0], setFormValues = _d[1];
    var onChangeDateRange = function (d) {
        dateTemp = d;
    };
    function onSubmit(input) {
        return input;
    }
    function onChange(_a) {
        var rangeDates = _a.rangeDates, input = __rest(_a, ["rangeDates"]);
        return __awaiter(this, void 0, void 0, function () {
            var updates, i, list, name_1, changes, _formValues_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        updates = Object.assign(input, rangeDates
                            ? {
                                startDate: rangeDates[0],
                                endDate: rangeDates[1],
                            }
                            : {});
                        if (!rangeDates) return [3 /*break*/, 2];
                        return [4 /*yield*/, ref.current.setFieldValue('rangeDates', rangeDates)];
                    case 1:
                        _b.sent();
                        if (ref.current.errors['rangeDates'])
                            return [2 /*return*/, ref.current.setFieldTouched('rangeDates')];
                        _b.label = 2;
                    case 2:
                        if (!(!input.email || input.email.length !== 0)) return [3 /*break*/, 6];
                        i = 0, list = Object.keys(updates);
                        _b.label = 3;
                    case 3:
                        if (!(i < list.length)) return [3 /*break*/, 6];
                        name_1 = list[i];
                        return [4 /*yield*/, ref.current.setFieldValue(name_1, updates[name_1])];
                    case 4:
                        _b.sent();
                        if (ref.current.errors[name_1]) {
                            return [2 /*return*/, ref.current.setFieldTouched(name_1)];
                        }
                        _b.label = 5;
                    case 5:
                        i++;
                        return [3 /*break*/, 3];
                    case 6:
                        changes = Object.assign(__assign({}, updates), !formValues._id
                            ? {
                                type: defaultValue.type,
                                orderTransportation: defaultValue.orderTransportation,
                            }
                            : { _id: formValues._id });
                        if (Object.keys(updates).length &&
                            Object.keys(updates).filter(function (key) { return formValues[key] !== updates[key]; }).length) {
                            _formValues_1 = __assign(__assign({}, formValues), changes);
                            ['startDate', 'endDate'].forEach(function (e) {
                                if (_formValues_1[e])
                                    _formValues_1[e] = toDateOnly(_formValues_1[e]);
                            });
                            _formValues_1.rangeDates = [_formValues_1.startDate, _formValues_1.endDate];
                            setFormValues(_formValues_1);
                            store.orderStore.updateOrderRoute(__assign(__assign({}, changes), { type: formValues.type }));
                        }
                        else {
                            console.log('no updates');
                        }
                        return [2 /*return*/];
                }
            });
        });
    }
    function onChangeInput(e) {
        var _a;
        onChange((_a = {}, _a[e.target.name] = e.target.value, _a));
    }
    function onChangeCheckbox(e) {
        var _a;
        onChange((_a = {}, _a[e.target.name] = e.target.checked, _a));
    }
    function selectLocation(location, action) {
        var _a;
        var email = (location.mode === OrderLocationMode.ADVANCED
            ? location.members.length && location.members.map(function (e) { return e.email; })
            : ((_a = location.email) === null || _a === void 0 ? void 0 : _a.length) && location.email) ||
            (location.warehouse && location.warehouse.emails) ||
            [];
        var updates = action === ACTION_CHANGE.DELETED
            ? __assign({ origin: getSnapshot(OrderLocation.create()) }, (email.length && { email: email })) : __assign({ origin: location }, (email.length && { email: email }));
        var newFormValues = __assign(__assign(__assign({}, formValues), updates), { updatedAt: Date.now() });
        setFormValues(newFormValues);
        onChange(updates);
    }
    /*eslint-disable */
    useEffect(function () {
        return function () {
            dateTemp = null;
        };
    }, [dateTemp]);
    /*eslint-enable */
    var hundredYearsBeforeNow = new Date();
    hundredYearsBeforeNow.setFullYear(hundredYearsBeforeNow.getFullYear() - 100);
    var noEdit = !canEdit(store.orderStore.order, store); //|| store.orderStore.isOrderUpdating;
    var noEditV1 = !canEdit(store.orderStore.order, store, { inProgress: 1 }); //|| store.orderStore.isOrderUpdating;
    var isRestore = location.href.toLowerCase().match(/restore/);
    var noEditDate = noEdit && !isRestore;
    var formProps = {
        onSubmit: onSubmit,
    };
    if (props.canAdd || formValues._id) {
        var errorText = (isRestore &&
            ((v.type === OrderRouteType.Delivery && 'Delivery') || 'Pick up') + " date cant be early than today") ||
            langForm.t('form_input_validate_invalid_dates');
        var errorTextDeliveryEndLessThenPickup = langForm.t('form_input_validate_invalid_dates_delivery_end_less_then_pickup_end');
        var rangeValidation_1 = function (value) {
            var isValid = value.filter(function (e) { return e; }).length === 2 &&
                toDateOnly(value[0]) <= toDateOnly(value[1]) &&
                toDateOnly(value[1]) >= toDateOnly(isEdit ? hundredYearsBeforeNow : new Date()) &&
                toDateOnly(value[0]) >= toDateOnly(isEdit ? hundredYearsBeforeNow : new Date());
            return isValid;
        };
        var callbackValidation = function (value) {
            var _a, _b;
            var isValid = rangeValidation_1(value);
            if (!isValid) {
                if (props.endLocation &&
                    (defaultValue === null || defaultValue === void 0 ? void 0 : defaultValue._id) !== ((_a = props.endLocation) === null || _a === void 0 ? void 0 : _a._id) &&
                    props.endLocation.startDate &&
                    props.endLocation.endDate) {
                    isValid = rangeValidation_1([moment(props.endLocation.startDate), moment(props.endLocation.endDate)]);
                }
                else if (props.startLocation &&
                    (defaultValue === null || defaultValue === void 0 ? void 0 : defaultValue._id) !== ((_b = props.startLocation) === null || _b === void 0 ? void 0 : _b._id) &&
                    props.startLocation.startDate &&
                    props.startLocation.endDate) {
                    isValid = rangeValidation_1([moment(props.startLocation.startDate), moment(props.startLocation.endDate)]);
                }
            }
            return isValid;
        };
        var callbackValidationBaseOnStartDate = function (value) {
            var _a;
            var isValid = true;
            if (((_a = props.startLocation) === null || _a === void 0 ? void 0 : _a.endDate) && value[1]) {
                isValid = toDateOnly(value[1]) >= props.startLocation.endDate;
            }
            return isValid;
        };
        Object.assign(formProps, {
            validationSchema: Yup.object(mode === OrderCreationMode.BASIC
                ? {
                    origin: Yup.object({
                        _id: Yup.string(),
                    }).test('_id', langForm.t('form_input_validate_required'), function (val) {
                        return !!val._id;
                    }),
                    email: Yup.array()
                        .typeError(langForm
                        .t('form_input_validate_required_list')
                        .replace('_X_', langForm.t('form_input_name_emails').toLowerCase()))
                        .of(Yup.string()
                        .max(50, langForm.t('form_input_validate_max_char').replace(/_X_/, '50'))
                        .email(langForm.t('form_input_validate_invalid_email')))
                        .min(1, 'Must have at least 1 email')
                        .max(10, 'Must have no more 10 email'),
                    rangeDates: Yup.array()
                        .typeError(langForm.t('form_input_validate_required'))
                        .test('rangeDates', errorText, callbackValidation)
                        .test('rangeDates', errorTextDeliveryEndLessThenPickup, callbackValidationBaseOnStartDate)
                        .required(langForm.t('form_input_validate_required')),
                }
                : {
                    origin: Yup.object({
                        _id: Yup.string(),
                    }).test('_id', langForm.t('form_input_validate_required'), function (val) {
                        return !!val._id;
                    }),
                }),
        });
    }
    var rangeLimit = {};
    if (maxLimit) {
        if (maxLimit.endDate)
            rangeLimit.maxDate = maxLimit.endDate;
    }
    if (minLimit) {
        if (minLimit.startDate)
            rangeLimit.minDate = minLimit.startDate;
    }
    // console.log(formValues.type, minLimit, maxLimit);
    var theme1 = useTheme();
    var isSmall = useMediaQuery(theme1.breakpoints.down('sm'));
    return useObserver(function () { return (React.createElement(Formik, __assign({ innerRef: ref, enableReinitialize: true, initialValues: formValues }, formProps, { key: (defaultValue === null || defaultValue === void 0 ? void 0 : defaultValue.updatedAt) || 1 }), function (formik) {
        var _a, _b;
        var _c, _d;
        var onFieldChangeHandler = function (e) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        formik.handleChange(e);
                        if (!['email'].includes(e.target.name)) return [3 /*break*/, 2];
                        return [4 /*yield*/, formik.setFieldValue(e.target.name, e.target.value)];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); };
        var onBlur = function (e, value) {
            if (Array.isArray(formik.values[e.target.name])) {
                onChangeInput({ target: { name: e.target.name, value: value || formik.values[e.target.name] } });
            }
            else if (typeof formik.values[e.target.name] === 'string') {
                var newValue = typeof e.target.value === 'string' ? e.target.value.trim() : e.target.value;
                if (newValue !== '') {
                    onChangeInput({
                        target: { name: e.target.name, value: __spread(formValues[e.target.name], [newValue]) },
                    });
                }
            }
            setUpdates(Date.now());
        };
        var onDeleteEmail = function (email) { return __awaiter(void 0, void 0, void 0, function () {
            var val, value, event;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        val = formik.values.email || [];
                        value = (Array.isArray(val) ? val : [val]).filter(function (e) { return e !== email; });
                        event = {
                            target: { name: 'email', value: value },
                        };
                        onBlur(event, value);
                        if (!value.length) return [3 /*break*/, 2];
                        return [4 /*yield*/, formik.setFieldValue(event.target.name, event.target.value)];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); };
        var locationInputKey = ((_c = formik.values.origin) === null || _c === void 0 ? void 0 : _c._id) && formik.values.origin._id !== ''
            ? formik.values.origin.updatedAt
            : undefined;
        var optionFilter = (React.createElement(Grid, { item: true, xs: 6 },
            React.createElement(FormikCheckBoxField, { color: "primary", labelStyle: { pointerEvents: 'none' }, checkboxStyle: { pointerEvents: 'auto' }, label: React.createElement(TextOverflow, { style: { pointerEvents: 'none' } }, langOrderForm.t('order_form_my_locations_filter_text')), variant: "outlined", name: "isMyLocations", value: formik.values.isMyLocations, onChange: function () {
                    setUpdateLocationFilter(Date.now());
                } })));
        return (React.createElement(Form, { id: "route-form-" + (index - 1) }, props.canAdd || formValues._id ? (React.createElement(React.Fragment, null,
            React.createElement(Grid, { container: true, direction: "row", spacing: 3 },
                React.createElement(PaddingSpace, { style: { position: 'relative' } },
                    !isSmall ? (React.createElement(React.Fragment, null,
                        React.createElement("div", { className: clsx(classesRouteForm.root, (_a = {}, _a[classesRouteForm.rootV1] = props.v1, _a)) }),
                        React.createElement("div", { className: clsx((_b = {},
                                _b[classesRouteForm.start] = formik.values.type === OrderRouteType.Preferred,
                                _b[classesRouteForm.end] = formik.values.type !== OrderRouteType.Preferred,
                                _b[classesRouteForm.startV1] = formik.values.type === OrderRouteType.Preferred && props.v1,
                                _b[classesRouteForm.endV1] = formik.values.type !== OrderRouteType.Preferred && props.v1,
                                _b)) }))) : null,
                    React.createElement("div", null)),
                !props.isOnlyRange ? (React.createElement(Grid, { item: true, xs: 12, md: 4 },
                    React.createElement(FormLocationInput, { optionFilter: optionFilter, optionFilterStyle: {
                            margin: theme.spacing(0, 3.5),
                        }, refresh: updateLocationFilter, locationType: locationType, help: langForm.t('form_input_help_order_location'), config: { client: client }, name: 'origin', title: props.originTitle, noEdit: noEdit, required: true, defaultFilter: __assign({}, (formik.values.isMyLocations && {
                            filter: {
                                page: 1,
                                fields: [{ name: 'isMyLocations', value: formik.values.isMyLocations }],
                            },
                        })), inputId: "location-input-" + props.transportationIndex + "-" + index, defaultValue: formik.values.origin, key: locationInputKey, onChange: selectLocation, onAutocompleteClose: function () {
                            formik.setFieldValue('isMyLocations', false);
                        } }))) : null,
                !props.isOnlyRange ? (React.createElement(Grid, { item: true, xs: 12, md: 3 },
                    React.createElement(FormikAutocomplete, __assign({ v17: true, v25: true, v26: true }, formikAutocompleteMultiOptionsProps(formik.values.email, onBlur, onDeleteEmail, [], {
                        disabled: noEditV1,
                        v25: true,
                    }), { key: updates, title: props.emailTitle1, placeholder: ((_d = formik.values.email) === null || _d === void 0 ? void 0 : _d.length) ? langForm.t('form_input_name_emails')
                            : langForm.t('form_input_name_emails_placeholder'), help: props.emailTitle, name: "email", required: true, disabled: noEditV1 || !(formik.values.origin && formik.values.origin._id), value: formik.values.email, onChange: onFieldChangeHandler })))) : null,
                isManufacturing ? null : (React.createElement(React.Fragment, null,
                    React.createElement(Grid, { item: true, xs: 12, md: props.isOnlyRange ? 10 : 2, key: (formik.values.warehouse ||
                            (formik.values.warehouse && formik.values.warehouse._id) ||
                            1).toString() },
                        React.createElement(Grid, { container: true, direction: 'row' },
                            React.createElement(Grid, { container: true, direction: 'row', className: classesInputV1Styles.root },
                                React.createElement(LimitDateRangePicker, __assign({ v1: props.v1, isMobile: props.isMobileDateRangePicker, notDisablePast: isEdit, id: "route-range-input-" + props.transportationIndex + "-" + index, route: formik.values }, rangeLimit, { disabled: noEditDate || !(formik.values.origin && formik.values.origin._id), title: dateTitle, name: "rangeDates", required: !props.v1, help: props.v1 ? null : langForm.t('form_input_help_order_route_range'), value: formik.values.rangeDates, onClose: function (value) {
                                        return setTimeout(function () {
                                            return onChange({
                                                rangeDates: dateTemp || value,
                                                startDate: (dateTemp || value)[0],
                                                endDate: (dateTemp || value)[1],
                                            });
                                        });
                                    }, onChange: onChangeDateRange }))),
                            isRestore && (React.createElement("div", { style: { marginTop: theme.spacing(0.5) } },
                                React.createElement(PreloadingComponent, null,
                                    React.createElement(CargoStatusDetails, __assign({}, declineProps, { item: { order: getSnapshot(store.orderStore.order) } }))))))),
                    !props.isOnlyRange ? (React.createElement(Grid, { item: true, container: true, direction: 'row', xs: 12, md: 1 }, (!noEdit && (React.createElement(Box, { flexDirection: "row", display: "flex", alignItems: "flex-start", style: {
                            gap: theme.spacing(1),
                            marginLeft: theme.spacing(1),
                            marginTop: theme.spacing(0.5),
                        } },
                        React.createElement(FormikIconCheckboxField, { id: "strictly-checkbox-" + props.transportationIndex + "-" + index, style: { padding: 0 }, disabled: !formValues.origin._id || noEdit, icon: React.createElement(StrictlyRouteIcon, null), checkedIcon: React.createElement(StrictlyRouteIcon, { style: { color: theme.palette.success.light } }), title: props.strictlyDateHelp, name: "allowBookingOnlyOnSelectedPeriods", onChange: function (e) { return onChangeCheckbox(e); }, value: formik.values.isCreationCopy }),
                        React.createElement(FormikIconCheckboxField, { id: "duplicate-checkbox-" + props.transportationIndex + "-" + index, style: { padding: 0 }, disabled: !formValues.origin._id || noEdit, icon: React.createElement(FileCopyIcon, null), checkedIcon: React.createElement(FileCopyIcon, { style: { color: theme.palette.success.light } }), title: props.creationCopyHelp, name: "isCreationCopy", onChange: function (e) { return onChangeCheckbox(e); }, value: formik.values.isCreationCopy })))) ||
                        null)) : null,
                    !props.isOnlyRange ? (React.createElement(Grid, { item: true, direction: 'row', xs: 12, md: 1 },
                        React.createElement(Box, { flexDirection: "row", display: "flex", alignItems: "center" }))) : null))))) : null));
    })); });
})));
