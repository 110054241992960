var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import { inject, observer, useObserver } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { makeStyles, Grid, MenuItem, useMediaQuery, IconButton } from '@material-ui/core';
import { FormikTextField, TextOverflow } from '../../../../components';
import { DeleteDialog } from '../../../../components';
import { Formik, Form } from 'formik';
import { AddFormButton } from '../../../../components';
import * as Yup from 'yup';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import { getSnapshot } from 'mobx-state-tree';
import { OrderCargo } from '../../../../models/OrderCargo';
import { OrderCargoProductTypeSelect } from '../dialogs/OrderCargoProductType';
import { OrderCargoPalletType } from '../../../../models/OrderCargoPalletType';
import { OrderCargoPackageSelect } from '../dialogs/OrderCargoPackage';
import { FormikAutocompleteAsync } from '../../../../components';
import { canEdit } from '../utils';
import { onPreventEvent } from '../../../../components';
import { FormikSelect } from '../../../../components';
import { useFormStyles } from '../../../../styles';
import { ORDER_CARGO_WEIGHT_MEASURE } from '../../../../models';
import { KEYS } from '../../../../i18n';
import { onlySpacesRegExp } from '../../../../common';
import { OrderCargoPalletField, } from '../../../../graphql';
import { theme } from '../../../../theme';
import PaddingSpace, { PaddingSpaceSmall } from './PaddingSpce';
import BaseTooltip from '../../../BaseTooltip';
import { OrderCreationMode } from '../../../../pages/Order/FormV1';
import { OrderCargoPalletTypeSelect } from '../dialogs/OrderCargoPalletType';
import { OrderCargoCustomCode } from '../dialogs/OrderCargoCustomCode';
var is_USA_APP = parseInt(process.env.REACT_APP_APP_FOR_USA || '0') === 1;
export var useCargoFormStyles = makeStyles(function (theme) { return ({
    section: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(3, 4),
        marginBottom: theme.spacing(2),
    },
    title: {
        margin: theme.spacing(2, 0, 3, 0),
    },
    line: {
        width: '100%',
        height: '1px',
        backgroundColor: theme.palette.secondary.main,
        marginBottom: theme.spacing(2),
    },
    icon: {
        color: 'red',
    },
    checkBoxRoot: {
        width: '100%',
    },
    checkBoxLabel: {
        width: '100%',
    },
}); });
export var CargoFormItem = inject('store')(observer(React.forwardRef(function (_a, ref) {
    var store = _a.store, index = _a.index, transportationId = _a.transportationId, _b = _a.defaultValue, defaultValue = _b === void 0 ? getSnapshot(OrderCargo.create()) : _b, mode = _a.mode, onRef = _a.onRef, canDelete = _a.canDelete, props = __rest(_a, ["store", "index", "transportationId", "defaultValue", "mode", "onRef", "canDelete"]);
    var classesFormStyle = useFormStyles();
    var langOrderForm = useTranslation(KEYS.ORDER_FORM);
    var langOfferForm = useTranslation(KEYS.OFFER_FORM);
    var langForm = useTranslation(KEYS.FORM);
    var langApp = useTranslation(KEYS.APP);
    var _c = __read(React.useState({ palletType: 1, taintedCode: 1, package: 1, productType: 1 }), 2), updated = _c[0], setUpdated = _c[1];
    var noEdit = !props.isPublic && (!canEdit(store.orderStore.order, store) || !transportationId); // || store.orderStore.isOrderUpdating;
    var client = props.config.client;
    var copyDef = JSON.parse(JSON.stringify(defaultValue));
    ['amount', 'height', 'weight', 'cargoId', 'volume', 'taintedCode'].forEach(function (key, index) {
        if (!copyDef[key] || (index < 3 && isNaN(parseFloat(copyDef[key]))))
            copyDef[key] = '';
    });
    function onKeyDown(keyEvent) {
        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
            keyEvent.preventDefault();
        }
    }
    if (!copyDef.palletType) {
        copyDef.palletType = JSON.parse(JSON.stringify(__assign({}, getSnapshot(OrderCargoPalletType.create()))));
    }
    ['length', 'width'].forEach(function (e) {
        copyDef['palletType'][e] = isNaN(parseFloat(copyDef['palletType'][e]))
            ? ''
            : parseFloat(copyDef['palletType'][e]);
    });
    var isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    var isXSmall = useMediaQuery(theme.breakpoints.down('xs'));
    var isLarge = useMediaQuery(theme.breakpoints.up('lg'));
    var v2 = isLarge;
    if (!copyDef.weightMeasure)
        copyDef.weightMeasure =
            store.accountStore.currentCompany.settings.weightMeasure || ORDER_CARGO_WEIGHT_MEASURE.Tonnes;
    Object.assign(copyDef, {
        width: copyDef.palletType.width,
        length: copyDef.palletType.length,
    });
    var _d = __read(React.useState(copyDef), 2), formValues = _d[0], setFormValues = _d[1];
    function onSubmit(input) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, input];
            });
        });
    }
    function onDeleteItem() {
        if (props.v5) {
            return props.onDelete(formValues, index - 1);
        }
        if (!formValues._id)
            return; //no need to save not created
        store.orderStore.deleteOrderCargo(formValues);
    }
    ['productType', 'package', 'palletType'].forEach(function (e) {
        if (!formValues[e] || !formValues[e]._id) {
            formValues[e] = { _id: '' };
        }
    });
    var formProps = {
        onSubmit: onSubmit,
    };
    if (!props.isPublic && (props.canAdd || formValues._id)) {
        Object.assign(formProps, {
            validationSchema: Yup.object(mode === OrderCreationMode.BASIC
                ? Object.assign({
                    length: Yup.number()
                        .typeError(langForm.t('form_input_validate_required'))
                        .min(0, langForm.t('form_input_validate_min_size').replace(/_X_/g, '0'))
                        .max(100000000, langForm.t('form_input_validate_max_size').replace(/_X_/g, '100000000'))
                        .required(langForm.t('form_input_validate_required')),
                    width: Yup.number()
                        .typeError(langForm.t('form_input_validate_required'))
                        .min(0, langForm.t('form_input_validate_min_size').replace(/_X_/g, '0'))
                        .max(100000000, langForm.t('form_input_validate_max_size').replace(/_X_/g, '100000000'))
                        .required(langForm.t('form_input_validate_required')),
                    package: Yup.object({
                        _id: Yup.string(),
                    }).test('_id', langForm.t('form_input_validate_required'), function (val) {
                        return !!(val === null || val === void 0 ? void 0 : val._id);
                    }),
                    palletType: Yup.object({
                        _id: Yup.string(),
                    })
                        .typeError(langForm.t('form_input_validate_required'))
                        .test('_id', langForm.t('form_input_validate_required'), function (val) {
                        var _a;
                        if ((_a = ref === null || ref === void 0 ? void 0 : ref.current) === null || _a === void 0 ? void 0 : _a.values.isOpenCustomDimensions) {
                            return true;
                        }
                        return !!((val === null || val === void 0 ? void 0 : val.length) && (val === null || val === void 0 ? void 0 : val.width));
                    }),
                    amount: Yup.number()
                        .typeError(langForm.t('form_input_validate_field_ony_number'))
                        .min(0, langForm.t('form_input_validate_min_size').replace(/_X_/g, '0'))
                        .max(100000000, langForm.t('form_input_validate_max_size').replace(/_X_/g, '100000000'))
                        .required(langForm.t('form_input_validate_required'))
                        .integer(langForm.t('form_error_should_be_integer')),
                    height: Yup.number()
                        .typeError(langForm.t('form_input_validate_field_ony_number'))
                        .min(0, langForm.t('form_input_validate_min_size').replace(/_X_/g, '0'))
                        .max(100000000, langForm.t('form_input_validate_max_size').replace(/_X_/g, '100000000'))
                        .required(langForm.t('form_input_validate_required')),
                    volume: Yup.number()
                        .typeError(langForm.t('form_input_validate_field_ony_number'))
                        .min(0, langForm.t('form_input_validate_min_size').replace(/_X_/g, '0'))
                        .max(100000000, langForm.t('form_input_validate_max_size').replace(/_X_/g, '100000000')),
                    weight: Yup.number()
                        .typeError(langForm.t('form_input_validate_field_ony_number'))
                        .min(0, langForm.t('form_input_validate_min_size').replace(/_X_/g, '0'))
                        .max(100000000, langForm.t('form_input_validate_max_size').replace(/_X_/g, '100000000'))
                        .required(langForm.t('form_input_validate_required')),
                    taintedCode: Yup.string()
                        .max(250, langForm.t('form_input_validate_max_char').replace(/_X_/, '250'))
                        .matches(onlySpacesRegExp, langForm.t('form_input_validate_invalid_text')),
                }, formValues._id
                    ? {
                        cargoId: Yup.string()
                            .max(50, langForm.t('form_input_validate_max_char').replace(/_X_/, '50'))
                            .matches(onlySpacesRegExp, langForm.t('form_input_validate_invalid_text'))
                            .required(langForm.t('form_input_validate_required')),
                    }
                    : {})
                : {}),
        });
    }
    return useObserver(function () {
        var _a, _b, _c, _d;
        return (React.createElement(Formik, __assign({ key: formValues._id }, (onRef ? { innerRef: onRef } : { innerRef: ref }), { initialValues: __assign(__assign({}, formValues), { isOpenCustomCodeInput: !!defaultValue.taintedCode, isOpenCustomDimensions: (!((_a = defaultValue.palletType) === null || _a === void 0 ? void 0 : _a.length) || !((_b = defaultValue.palletType) === null || _b === void 0 ? void 0 : _b.width)) &&
                    !(!((_c = defaultValue.palletType) === null || _c === void 0 ? void 0 : _c.length) && !((_d = defaultValue.palletType) === null || _d === void 0 ? void 0 : _d.width)) }) }, formProps), function (formik) {
            var _a, _b, _c, _d, _e;
            var refForm = ref || { current: formik };
            var onAdd = function (e) { return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            onPreventEvent(e);
                            if (e.key === 'Enter')
                                return [2 /*return*/];
                            return [4 /*yield*/, onSave({}, true)];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            }); };
            function onChange(input) {
                return __awaiter(this, void 0, void 0, function () {
                    var i, list, name_1;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (Object.keys(input).filter(function (key) { return formValues[key] !== input[key]; }).length === 0) {
                                    console.log('cargo no changes');
                                    return [2 /*return*/];
                                }
                                i = 0, list = Object.keys(input);
                                _a.label = 1;
                            case 1:
                                if (!(i < list.length)) return [3 /*break*/, 5];
                                name_1 = list[i];
                                return [4 /*yield*/, (refForm === null || refForm === void 0 ? void 0 : refForm.current.setFieldValue(name_1, input[name_1]))];
                            case 2:
                                _a.sent();
                                return [4 /*yield*/, (refForm === null || refForm === void 0 ? void 0 : refForm.current.validateForm())];
                            case 3:
                                _a.sent();
                                if (refForm === null || refForm === void 0 ? void 0 : refForm.current.errors[name_1]) {
                                    return [2 /*return*/, refForm === null || refForm === void 0 ? void 0 : refForm.current.setFieldTouched(name_1)];
                                }
                                _a.label = 4;
                            case 4:
                                i++;
                                return [3 /*break*/, 1];
                            case 5:
                                if (input.amount && input.amount % 1) {
                                    console.log('amount no changes');
                                    return [2 /*return*/];
                                }
                                return [4 /*yield*/, onSave(input)];
                            case 6:
                                _a.sent();
                                return [2 /*return*/];
                        }
                    });
                });
            }
            var updateVolume = function (values) {
                var _a, _b;
                if (!!values.amount &&
                    (values.width || ((_a = values.palletType) === null || _a === void 0 ? void 0 : _a.width)) &&
                    (values.length || ((_b = values.palletType) === null || _b === void 0 ? void 0 : _b.length)) &&
                    values.height) {
                    var width = (!parseFloat(values.width) && values.palletType.width) || values.width;
                    var length_1 = (!parseFloat(values.length) && values.palletType.length) || values.length;
                    values.volume =
                        parseFloat(values.amount) * parseFloat(values.height) * parseFloat(length_1) * parseFloat(width);
                    if (isNaN(values.volume)) {
                        delete values.volume;
                    }
                    else {
                        values.volume = (values.volume / (100 * 100 * 100)).toFixed(2);
                        refForm === null || refForm === void 0 ? void 0 : refForm.current.setFieldValue('volume', values.volume);
                    }
                }
                return values;
            };
            function onSave(input, isNew) {
                return __awaiter(this, void 0, void 0, function () {
                    var newVal, cargoRes;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                newVal = __assign(__assign({}, (!isNew && formValues)), input);
                                newVal = updateVolume(newVal);
                                if (props.isPublic) {
                                    setFormValues(newVal);
                                    if (props.onChange)
                                        props.onChange({ target: { value: newVal } });
                                    return [2 /*return*/];
                                }
                                return [4 /*yield*/, store.orderStore.updateOrderCargo(__assign(__assign(__assign({}, input), { orderTransportation: props.transportation }), (!isNew && { _id: formValues._id })))];
                            case 1:
                                cargoRes = _a.sent();
                                newVal = __assign(__assign(__assign({}, (!isNew && formValues)), (cargoRes.data.updateOrderCargo || cargoRes.data.createOrderCargo)), input);
                                if (!isNew)
                                    setFormValues(newVal);
                                if (props.onChange)
                                    props.onChange({ target: { value: newVal } });
                                return [2 /*return*/];
                        }
                    });
                });
            }
            function onDelete(field) {
                return __awaiter(this, void 0, void 0, function () {
                    var _a;
                    return __generator(this, function (_b) {
                        onSave((_a = {}, _a[field] = null, _a));
                        return [2 /*return*/];
                    });
                });
            }
            function onChangeInput(e, checked) {
                var _a;
                onChange((_a = {},
                    _a[e.target.name] = ['exportDeclaration', 'importDeclaration'].includes(e.target.name)
                        ? checked
                        : e.target.value,
                    _a));
            }
            var loadingId = (React.createElement(FormikTextField, { id: "cargoId-input-" + props.transportationIndex + "-" + index, required: true, disabled: noEdit, title: "" + langOrderForm.t('create_order_cargo_id'), help: langOfferForm.t('create_order_cargo_id_help'), variant: "outlined", name: "cargoId", onBlur: onChangeInput, value: formik.values.cargoId }));
            var customCode = (React.createElement(Grid, { item: true, xs: 12, md: 4, style: (!props.v5 && { paddingRight: 0 }) || {} },
                React.createElement(OrderCargoCustomCode, __assign({}, props, { isClient: true, inputId: "taintedCode-input-" + props.transportationIndex + "-" + index, title: langOrderForm.t('create_order_cargo_tainted_code'), variant: "outlined", margin: 'dense', fullWidth: true, name: "taintedCode", value: formik.values.taintedCode, key: "" + updated.taintedCode, style: { minWidth: '50px' }, autocompleteProps: {
                        disabled: noEdit,
                    }, onChange: function (value) {
                        onChangeInput({
                            target: { name: 'taintedCode', value: value.name },
                        });
                        setUpdated(__assign(__assign({}, updated), { taintedCode: Date.now() }));
                    }, onDeleteItem: function (e, item) {
                        if (item.name === formik.values.taintedCode) {
                            onChangeInput({
                                target: { name: 'taintedCode', value: '' },
                            });
                            onSave({ taintedCode: '' });
                        }
                        setUpdated(__assign(__assign({}, updated), { taintedCode: Date.now() }));
                    }, defaultValue: formik.values.taintedCode }))));
            function onChangePalletTypeInput(e) {
                var _a, _b, _c, _d, _e, _f;
                return __awaiter(this, void 0, void 0, function () {
                    var _g, name, value, input, res, updates, newVal;
                    var _h, _j;
                    return __generator(this, function (_k) {
                        switch (_k.label) {
                            case 0:
                                _g = e.target, name = _g.name, value = _g.value;
                                value = parseFloat(value);
                                formik.setFieldValue(name, value);
                                if (!value)
                                    return [2 /*return*/];
                                input = (_h = {
                                        width: (formValues === null || formValues === void 0 ? void 0 : formValues.width) || null,
                                        length: (formValues === null || formValues === void 0 ? void 0 : formValues.length) || null,
                                        _id: ((_a = formik.values) === null || _a === void 0 ? void 0 : _a.palletType._id) || null
                                    },
                                    _h[name] = value,
                                    _h);
                                if (!(((_c = (_b = formik.values) === null || _b === void 0 ? void 0 : _b.palletType) === null || _c === void 0 ? void 0 : _c.name) ||
                                    !((_d = formik.values) === null || _d === void 0 ? void 0 : _d.palletType._id) || ((_f = (_e = formik.values) === null || _e === void 0 ? void 0 : _e.palletType) === null || _f === void 0 ? void 0 : _f.isGlobal))) return [3 /*break*/, 2];
                                return [4 /*yield*/, store.orderStore.createOrderCargoPalletType(input, transportationId, formik.values._id, props.isPublic)];
                            case 1:
                                res = _k.sent();
                                return [3 /*break*/, 4];
                            case 2: return [4 /*yield*/, store.orderStore.updateOrderCargoPalletType(input, transportationId, formik.values._id, props.isPublic)];
                            case 3:
                                res = _k.sent();
                                _k.label = 4;
                            case 4:
                                formik.setFieldValue('palletType', res);
                                updates = (_j = {
                                        width: formik.values.width,
                                        length: formik.values.length
                                    },
                                    _j[name] = value,
                                    _j);
                                newVal = __assign(__assign({}, formValues), updates);
                                newVal = updateVolume(newVal);
                                setFormValues(__assign({}, newVal));
                                return [2 /*return*/];
                        }
                    });
                });
            }
            var deleteCargo = (React.createElement(Grid, { item: true, xs: 12, md: 1, container: true, justify: 'flex-end', alignItems: 'center', style: { width: '120px', padding: theme.spacing(0, 1.5) } },
                React.createElement(TextOverflow, { variant: "subtitle1", className: classesFormStyle.sectionTitle1 }, index + "/" + props.total),
                React.createElement(DeleteDialog, { iconStyle: props.v5 ? { padding: 2 } : { marginRight: theme.spacing(2) }, onOk: onDeleteItem, value: langOrderForm.t('create_order_cargo_label') + " " + formValues.cargoId })));
            var cargoPackage = (React.createElement(OrderCargoPackageSelect, __assign({}, props, { required: true, inputId: "package-input-" + props.transportationIndex + "-" + index, help: langForm.t('form_input_help_order_cargo_package'), autocompleteProps: { disabled: noEdit }, name: 'package', key: ((_a = formValues === null || formValues === void 0 ? void 0 : formValues.package) === null || _a === void 0 ? void 0 : _a._id) + " " + updated.package, onChange: function (value) {
                    onChangeInput({
                        target: { name: 'package', value: value },
                    });
                    setUpdated(__assign(__assign({}, updated), { package: Date.now() }));
                }, onDeleteItem: function (e, item) {
                    if (item._id === formValues.package._id) {
                        onDelete('package');
                        formik.setFieldValue('package', null);
                        onChangeInput({
                            target: { name: 'package', value: null },
                        });
                    }
                    setUpdated(__assign(__assign({}, updated), { package: Date.now() }));
                }, value: formik.values.package || {}, defaultValue: Object.assign(__assign({}, formik.values.package), formik.values._id ? { orderCargo: formik.values._id } : null) })));
            var cargoProductSelect = (React.createElement(OrderCargoProductTypeSelect, __assign({}, props, { required: true, inputId: "productType-input-" + props.transportationIndex + "-" + index, autocompleteProps: { disabled: noEdit }, help: langForm.t('form_input_help_order_cargo_product'), name: 'productType', key: (((_b = formValues === null || formValues === void 0 ? void 0 : formValues.productType) === null || _b === void 0 ? void 0 : _b.updatedAt) || ((_c = formValues === null || formValues === void 0 ? void 0 : formValues.productType) === null || _c === void 0 ? void 0 : _c.createdAt)) + " " + updated.productType, onChange: function (value) {
                    onChangeInput({
                        target: { name: 'productType', value: value },
                    });
                    setUpdated(__assign(__assign({}, updated), { productType: Date.now() }));
                }, onDeleteItem: function (e, item) {
                    if (item._id === formValues.productType._id) {
                        onDelete('productType');
                        formik.setFieldValue('productType', null);
                        onChangeInput({
                            target: { name: 'productType', value: null },
                        });
                    }
                    setUpdated(__assign(__assign({}, updated), { productType: Date.now() }));
                }, value: formik.values.productType || {}, defaultValue: Object.assign(__assign({}, formik.values.productType), formik.values._id ? { orderCargo: formik.values._id } : null) })));
            var cargoHeight = (React.createElement(FormikTextField, { id: "height-input-" + props.transportationIndex + "-" + index, disabled: noEdit, placeholder: langForm.t('form_input_good_loading_loads_height') + ',' + " " + langApp.t('app_cm'), variant: "outlined", onBlur: onChangeInput, name: 'height', value: formik.values.height }));
            var cargoAmount = (React.createElement(FormikTextField, { required: true, id: "amount-input-" + props.transportationIndex + "-" + index, disabled: noEdit, title: langForm.t('form_good_quantity'), help: langForm.t('form_input_help_order_cargo_amount'), variant: "outlined", type: "number", onBlur: onChangeInput, name: "amount", value: formik.values.amount, style: { minWidth: '50px' } }));
            var cargoWeight = (React.createElement(Grid, { container: true, direction: "row", spacing: 0, item: true, xs: 12, md: 4, style: { paddingRight: isSmall ? 0 : undefined } },
                React.createElement(Grid, { item: true, xs: 10, md: 7 },
                    React.createElement(FormikTextField, { id: "weight-input-" + props.transportationIndex + "-" + index, disabled: noEdit, placeholder: langApp.t('weight'), className: classesFormStyle.beginInput, variant: "outlined", onBlur: onChangeInput, name: 'weight', value: formik.values.weight })),
                React.createElement(Grid, { item: true, xs: 2, md: 5 },
                    React.createElement(FormikSelect, { v22: true, id: "weightMeasure-select-" + props.transportationIndex + "-" + index, disabled: noEdit, fullWidth: true, className: classesFormStyle.endInput, variant: "outlined", type: "text", name: 'weightMeasure', value: formik.values.weightMeasure, onChange: onChangeInput }, (is_USA_APP ? ['Pounds'] : Object.keys(ORDER_CARGO_WEIGHT_MEASURE)).map(function (value) {
                        return (React.createElement(MenuItem, { key: value, value: ORDER_CARGO_WEIGHT_MEASURE[value] || value }, langOrderForm.t("create_order_cargo_weight_" + (ORDER_CARGO_WEIGHT_MEASURE[value] || value) + "_short")));
                    })))));
            var cargoVolume = (React.createElement(Grid, { item: true, xs: 12, md: 4, style: { paddingRight: isSmall ? 0 : undefined } },
                React.createElement(FormikTextField, { required: true, id: "volume-input-" + props.transportationIndex + "-" + index, disabled: noEdit, title: langApp.t('app_volume') + ',' + " " + (is_USA_APP ? langApp.t('app_ft3') : langApp.t('app_m3')), variant: "outlined", type: "volume", onBlur: onChangeInput, name: "volume", value: formik.values.volume })));
            var dimensionCargo = !formik.values.isOpenCustomDimensions ? (React.createElement(React.Fragment, null,
                React.createElement(Grid, { item: true, xs: 12, md: 4, style: { paddingRight: isSmall ? 0 : undefined } },
                    React.createElement(OrderCargoPalletTypeSelect, __assign({}, props, { required: true, cargoId: defaultValue === null || defaultValue === void 0 ? void 0 : defaultValue._id, title: "" + langApp.t('app_dimensions'), name: "palletType", inputId: "palletType-input-" + props.transportationIndex + "-" + index, help: langForm.t('form_input_help_order_cargo_product'), key: "" + updated.palletType, onChange: function (e, item) { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                if (item && item._id) {
                                    ['length', 'width'].forEach(function (key) {
                                        formik.setFieldValue(key, item[key]);
                                    });
                                    formik.setFieldValue('palletType', item);
                                    setFormValues(__assign(__assign({}, formValues), { length: item.length, width: item.width }));
                                    onChangeInput({
                                        target: { name: 'palletType', value: item },
                                    });
                                    setUpdated(__assign(__assign({}, updated), { palletType: Date.now() }));
                                }
                                return [2 /*return*/];
                            });
                        }); }, value: formik.values.palletType, autocompleteProps: {
                            disabled: noEdit,
                        }, onDeleteItem: function (e, item) {
                            var _a;
                            if ((item === null || item === void 0 ? void 0 : item._id) === ((_a = formValues.palletType) === null || _a === void 0 ? void 0 : _a._id)) {
                                onDelete('palletType');
                                formik.setFieldValue('palletType', null);
                                onChangeInput({
                                    target: { name: 'palletType', value: null },
                                });
                            }
                            setUpdated(__assign(__assign({}, updated), { palletType: Date.now() }));
                        }, defaultValue: ((_d = formik.values.palletType) === null || _d === void 0 ? void 0 : _d.width) !== '' && ((_e = formik.values.palletType) === null || _e === void 0 ? void 0 : _e.length) !== ''
                            ? formik.values.palletType
                            : null }))))) : (React.createElement(React.Fragment, null,
                React.createElement(Grid, { item: true, xs: 12, md: (props.v5 && 4) || (v2 ? 2 : 3), style: { paddingRight: isSmall ? 0 : undefined } },
                    React.createElement(FormikAutocompleteAsync, { v17: true, required: true, inputId: "length-input-" + props.transportationIndex + "-" + index, 
                        // help={langForm.t('form_helper_length_width_height')}
                        title: langForm.t('form_input_name_dimension_length') + ',' + " " + langApp.t('app_cm'), variant: "outlined", margin: 'dense', fullWidth: true, canAddNew: true, canAddNewV1: true, type: "number", name: "length", client: client, query: OrderCargoPalletField, variables: { field: 'length' }, value: formik.values.length, style: { minWidth: '50px' }, autocompleteProps: {
                            disabled: noEdit,
                            freeSolo: false,
                            defaultValue: formik.values.length
                                ? {
                                    _id: parseFloat(formik.values.length.toString()),
                                }
                                : null,
                            getOptionSelected: function (option, value) {
                                return parseFloat(option._id) === parseFloat(value._id);
                            },
                            getOptionLabel: function (option) {
                                return (option.title || option._id || '').toString();
                            },
                        }, onChange: onChangePalletTypeInput })),
                React.createElement(Grid, { container: true, alignItems: 'center', item: true, xs: 12, md: props.v5 ? 4 : v2 ? 2 : 3, style: { paddingRight: isSmall ? 0 : undefined } },
                    React.createElement(Grid, { item: true, xs: 12, md: 10 },
                        React.createElement(FormikAutocompleteAsync, { v17: true, required: true, inputId: "width-input-" + props.transportationIndex + "-" + index, title: langForm.t('form_input_good_loading_loads_width') + ',' + " " + langApp.t('app_cm'), variant: "outlined", margin: 'dense', fullWidth: true, canAddNew: true, canAddNewV1: true, type: "number", name: "width", client: client, query: OrderCargoPalletField, variables: { field: 'width' }, value: formik.values.width, style: { minWidth: '50px' }, autocompleteProps: {
                                disabled: noEdit,
                                defaultValue: formik.values.width
                                    ? {
                                        _id: parseFloat(formik.values.width.toString()),
                                    }
                                    : null,
                                getOptionSelected: function (option, value) {
                                    return parseFloat(option._id) === parseFloat(value._id);
                                },
                                getOptionLabel: function (option) {
                                    return (option.title || option._id || '').toString();
                                },
                            }, onChange: onChangePalletTypeInput })),
                    React.createElement(Grid, { item: true, xs: 12, md: 2, style: { padding: theme.spacing(0, 0, 0, 1) } },
                        React.createElement(BaseTooltip, { title: langApp.t('app_dimensions') },
                            React.createElement(IconButton, { style: { padding: 0 }, onClick: function () { return formik.setFieldValue('isOpenCustomDimensions', false); } },
                                React.createElement(SettingsBackupRestoreIcon, null)))))));
            if (props.v5) {
                return (React.createElement(Form, { id: "cargo-form-" + (index - 1), onKeyDown: onKeyDown },
                    React.createElement(Grid, { container: true, spacing: 3, style: { marginBottom: theme.spacing(1.5) } },
                        React.createElement(Grid, { item: true, xs: 12, md: canDelete ? 3 : 4 }, loadingId),
                        canDelete && deleteCargo,
                        React.createElement(Grid, { item: true, xs: 12, md: 4 }, cargoProductSelect),
                        React.createElement(Grid, { item: true, xs: 12, md: 4 }, cargoPackage),
                        dimensionCargo,
                        React.createElement(Grid, { item: true, xs: 12, md: 4 }, cargoHeight),
                        React.createElement(Grid, { item: true, xs: 12, md: 4 }, cargoAmount),
                        cargoWeight,
                        cargoVolume,
                        customCode,
                        (props.canAdd && (React.createElement(Grid, { item: true, xs: 12 },
                            React.createElement(AddFormButton, { id: 'create_order_cargo_add', style: { paddingLeft: 0 }, onClick: props.onAddCargo, title: langOrderForm.t('create_order_cargo_add') })))) ||
                            null)));
            }
            return (React.createElement(Form, { id: "cargo-form-" + (index - 1), onKeyDown: onKeyDown },
                React.createElement(Grid, { container: true, alignItems: "center", spacing: 3, style: { marginBottom: theme.spacing(1.5) } },
                    React.createElement(PaddingSpace, null),
                    React.createElement(Grid, { container: true, item: true, xs: 12, md: 2, justify: 'space-between' },
                        React.createElement(Grid, { item: true, xs: 8, style: { paddingTop: 3 } },
                            React.createElement(TextOverflow, { variant: "subtitle1", className: classesFormStyle.sectionTitle1, style: { margin: 0 } }, "" + langOrderForm.t('create_order_cargo_label')))),
                    React.createElement(Grid, { container: true, item: true, xs: 12, md: 7, spacing: 3, style: { marginRight: 0, paddingRight: 0 } },
                        React.createElement(Grid, { item: true, xs: 12, md: 4, style: {
                                display: isXSmall ? 'none' : undefined,
                                paddingRight: isSmall ? 0 : undefined,
                            } }, loadingId),
                        React.createElement(Grid, { item: true, xs: 12, md: v2 ? 8 : 4, style: { paddingRight: isSmall ? 0 : v2 ? 0 : undefined } }, cargoProductSelect),
                        !v2 ? (React.createElement(Grid, { item: true, xs: 12, md: 4, style: { paddingRight: 0 } }, cargoPackage)) : null),
                    formValues._id && !noEdit && !props.noDelete && !props.isPublic ? deleteCargo : null),
                React.createElement(Grid, { container: true, direction: "row", spacing: 3, style: { marginBottom: theme.spacing(1.5) } },
                    React.createElement(PaddingSpace, null),
                    React.createElement(Grid, { container: true, item: true, xs: 12, md: 2 }),
                    React.createElement(Grid, { container: true, item: true, xs: 12, md: (props.v5 && 12) || 7, spacing: 3, style: { marginRight: 0, paddingRight: 0 } },
                        v2 ? (React.createElement(Grid, { item: true, xs: 12, md: (props.v5 && 12) || 4, style: { paddingRight: props.v5 || isSmall ? 0 : undefined } }, cargoPackage)) : null,
                        dimensionCargo,
                        React.createElement(Grid, { item: true, xs: 12, md: v2 ? 2 : formik.values.isOpenCustomDimensions ? 3 : 4, style: { paddingRight: isSmall ? 0 : undefined } }, cargoHeight),
                        React.createElement(Grid, { item: true, xs: 12, md: v2
                                ? formik.values.isOpenCustomDimensions
                                    ? 2
                                    : 2
                                : formik.values.isOpenCustomDimensions
                                    ? 3
                                    : 4, style: { paddingRight: 0 } }, cargoAmount))),
                React.createElement(Grid, { container: true, alignItems: "center", spacing: 3, style: { marginBottom: theme.spacing(2) } },
                    props.v5 ? null : React.createElement(PaddingSpace, null),
                    isSmall || props.v5 ? null : React.createElement(Grid, { item: true, xs: 12, md: 2 }),
                    React.createElement(Grid, { container: true, item: true, xs: 12, md: 7, spacing: 3, style: { marginRight: 0, paddingRight: 0 } },
                        cargoWeight,
                        cargoVolume,
                        customCode)),
                !props.v5 && isSmall ? (React.createElement(PaddingSpaceSmall, { item: true, xs: 12 }, loadingId)) : null,
                React.createElement(Grid, { container: true, direction: "row", style: { marginBottom: theme.spacing(4) } },
                    props.v5 ? null : React.createElement(PaddingSpace, null),
                    props.showAdd && (React.createElement(Grid, { container: true, direction: "row", md: 11 },
                        React.createElement(AddFormButton, { id: 'create_order_cargo_add', style: { paddingLeft: 0 }, onClick: onAdd, title: langOrderForm.t('create_order_cargo_add'), type: "submit" }))))));
        }));
    });
})));
export var CargoForm = inject('store')(observer(React.forwardRef(function (_a, formRef) {
    var store = _a.store, index = _a.index, props = __rest(_a, ["store", "index"]);
    var classes = useFormStyles();
    return useObserver(function () { return (React.createElement("div", { className: classes.sectionFOrm, style: { paddingTop: 0 }, id: "step-content-cargo-" + index }, props.transportation.cargoes.map(function (e, index) { return (React.createElement(CargoFormItem, __assign({}, props, { noDelete: index === 0 && props.transportation.cargoes.length === 1, showAdd: props.transportation.cargoes.length < 10 && index === props.transportation.cargoes.length - 1, ref: formRef[index], index: index + 1, transportationId: props.transportation._id, defaultValue: e, key: "cargo-" + e._id, total: props.transportation.cargoes.length }))); }))); });
})));
