var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React from 'react';
import clsx from 'clsx';
import { inject, observer, useObserver } from 'mobx-react';
import { Select } from './Select';
import { FlagComponent } from './Flag';
import { Grid, makeStyles } from '@material-ui/core';
import { TextOverflow } from './TextOverflow';
import { theme } from '../theme';
var useStyles = makeStyles(function (theme) { return ({
    root: {
        '& .MuiSelect-root': {
            paddingTop: 0,
            paddingBottom: 0,
        },
    },
}); });
export var LangOption = inject('store')(function (_a) {
    var languages = _a.store.accountStore.languages, lang = _a.lang;
    var item = languages.find(function (e) { return e.id === ((lang && lang.value) || lang); }) || languages[0];
    return (React.createElement(Grid, { container: true, alignItems: 'center' },
        React.createElement(FlagComponent, { country: item.code || item.id }),
        React.createElement(TextOverflow, { style: { marginLeft: theme.spacing(1) } }, item.name)));
});
export var LanguageMenu = inject('store')(observer(function (_a) {
    var _b = _a.store.accountStore, changeLanguage = _b.changeLanguage, currentUser = _b.currentUser, languages = _b.languages, v2 = _a.v2, hideLabel = _a.hideLabel;
    var classes = useStyles();
    var _c = __read(React.useState(false), 2), loading = _c[0], setLoading = _c[1];
    // const { i18n } = useTranslation();
    // /*eslint-disable */
    // React.useEffect(() => {
    //   const defaultLang = getQueryParams(location.href).lang;
    //   if (currentUser.language) {
    //     if (defaultLang && i18nOptions.availableLangs.includes(defaultLang)) return;
    //     if (i18n.changeLanguage) i18n.changeLanguage(currentUser.language);
    //     browserStorage.setItem('lang', currentUser.language);
    //   }
    //
    //   return () => {};
    // }, [currentUser.language]);
    // /*eslint-enable */
    function onChange(e) {
        return __awaiter(this, void 0, void 0, function () {
            var e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!e.target.value)
                            return [2 /*return*/];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        setLoading(true);
                        return [4 /*yield*/, changeLanguage(e.target.value)];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 3:
                        e_1 = _a.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        setLoading(false);
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    }
    var options = __spread(languages).filter(function (e) { return e.isActive; })
        .map(function (e) { return (__assign(__assign({}, e), { icon: React.createElement(FlagComponent, { country: e.code || e.id }) })); });
    if (hideLabel)
        options = options.map(function (_a) {
            var name = _a.name, e = __rest(_a, ["name"]);
            return e;
        });
    return useObserver(function () {
        var _a;
        return (React.createElement(Select, { disabled: loading, className: clsx((_a = {}, _a[classes.root] = v2, _a)), style: { width: '100%' }, defaultValue: currentUser.language, hideLabel: hideLabel, onChange: onChange, items: options }));
    });
}));
export var LanguageMenuSmall = inject('store')(function (_a) {
    var defaultValue = _a.defaultValue, _b = _a.store.accountStore, changeLanguage = _b.changeLanguage, currentUser = _b.currentUser, languages = _b.languages, hideLabel = _a.hideLabel;
    var _c = __read(React.useState(defaultValue.language), 2), value = _c[0], setValue = _c[1];
    function onChange(e) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (!e.target.value)
                    return [2 /*return*/];
                setValue(e.target.value);
                changeLanguage(e.target.value);
                currentUser.updateUser({
                    _id: defaultValue._id,
                });
                return [2 /*return*/];
            });
        });
    }
    var options = __spread(languages).filter(function (e) { return e.isActive; })
        .map(function (e) { return (__assign(__assign({}, e), { icon: React.createElement(FlagComponent, { country: e.code || e.id }) })); });
    if (hideLabel)
        options = options.map(function (_a) {
            var name = _a.name, e = __rest(_a, ["name"]);
            return e;
        });
    return React.createElement(Select, { defaultValue: value, key: value, hideLabel: hideLabel, onChange: onChange, items: options });
});
