var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { BasicDialog } from './BasicDialog';
import { dialogStyles } from './styles';
import { TextOverflow } from '../TextOverflow';
export var ConfirmDialog = function (_a) {
    var disabled = _a.disabled, props = __rest(_a, ["disabled"]);
    var classesDialogStyles = dialogStyles();
    var actonInit = props.actionInit;
    return (React.createElement(BasicDialog, __assign({}, props, { actionInit: actonInit, isMiddleBody: true, body: React.createElement(TextOverflow, { variant: "subtitle1", className: classesDialogStyles.text }, props.text) })));
};
