var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import { toast } from 'react-toastify';
import { createStyles, Grid } from '@material-ui/core';
import { TextOverflow } from '../TextOverflow';
import { makeStyles } from '@material-ui/core/styles';
import SectionRow, { SectionRowQuoteSubmitted, useSectionRowStyles } from './SectionRow';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../i18n';
import { CircularIndeterminate } from '../CircularIndeterminate';
import { useContainerDimensions } from '../../HOK/useContainerDimensions';
import clsx from 'clsx';
import { BaseSelect } from '../Formik';
import { theme } from '../../theme';
import Pagination from '@material-ui/lab/Pagination';
import { checkIsDevEnv, wrapTextToId } from '../../utils';
export var useSectionDetailsStyles = makeStyles(function (theme) {
    return createStyles({
        title: {
            background: theme.palette.primary.light,
            borderRadius: theme.spacing(0.5),
            color: theme.palette.common.white,
            padding: theme.spacing(1, 0),
            width: '100%',
            textAlign: 'center',
            marginBottom: theme.spacing(2),
        },
    });
});
export default (function SectionDetails(_a) {
    var _b;
    var _this = this;
    var title = _a.title, rows = _a.rows, order = _a.order, config = _a.config, query = _a.query, onChange = _a.onChange, dataView = _a.dataView, rowContent = _a.rowContent, withoutTitle = _a.withoutTitle, props = __rest(_a, ["title", "rows", "order", "config", "query", "onChange", "dataView", "rowContent", "withoutTitle"]);
    var styles = useSectionDetailsStyles();
    var langOrderList = useTranslation(KEYS.ORDER_LIST);
    var _c = __read(React.useState(JSON.parse(JSON.stringify(props.variables || {}))), 2), variables = _c[0], setVariables = _c[1];
    var _d = __read(React.useState(true), 2), loading = _d[0], setLoading = _d[1];
    var _e = __read(React.useState({ count: 0, data: [] }), 2), details = _e[0], setDetails = _e[1];
    var ref = React.useRef(null);
    var width = useContainerDimensions(ref).width;
    var className = useSectionRowStyles();
    var loadData = function (newVariables) {
        if (query) {
            setLoading(true);
            config.client.query({ query: query, variables: newVariables || variables }).then(function (result) { return __awaiter(_this, void 0, void 0, function () {
                var res, e_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 1, 2, 4]);
                            res = JSON.parse(JSON.stringify(result.data[Object.keys(result.data)[0]]));
                            setDetails(res);
                            return [3 /*break*/, 4];
                        case 1:
                            e_1 = _a.sent();
                            toast.error(e_1.message || e_1);
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, checkIsDevEnv()];
                        case 3:
                            _a.sent();
                            setLoading(false);
                            return [7 /*endfinally*/];
                        case 4: return [2 /*return*/];
                    }
                });
            }); });
        }
    };
    React.useEffect(function () {
        loadData();
    }, [query]);
    var titleDOM = typeof title === 'string' ? (React.createElement(TextOverflow, { v7: props.v2, className: styles.title }, title)) : (React.createElement(Grid, { className: styles.title }, title));
    var onChangePerPage = function (e) {
        var _a;
        var _b = e.target, value = _b.value, name = _b.name;
        var s = __assign(__assign({}, variables), { filter: __assign(__assign({}, variables.filter), (_a = {}, _a[name] = parseInt(value), _a)) });
        setVariables(s);
        loadData(s);
    };
    var data = details.data;
    return (React.createElement(Grid, { container: true, ref: ref, id: 'izi-section-' + wrapTextToId(title) },
        !withoutTitle ? titleDOM : null,
        rows &&
            rows.map(function (e) {
                return ((rowContent && rowContent(e)) ||
                    e.rowContent || React.createElement(SectionRow, __assign({ parentWidth: width, key: e.name || e.value }, e, { v1: props.v1 })));
            }),
        (data && dataView && dataView(data)) ||
            (data &&
                data.map(function (e) {
                    return (rowContent && rowContent(e)) || (React.createElement(SectionRowQuoteSubmitted, { key: e._id, record: e, order: order, v1: props.v1, config: config, onChange: onChange, onChangeV1: props.onChangeV1 }));
                })),
        query && data && !data.length && !loading && (React.createElement(TextOverflow, { variant: 'subtitle1', style: { textAlign: 'center', width: '100%' } }, langOrderList.t('order_list_filter_no_data_general'))),
        (query && !loading && details.count > variables.filter.limit && (React.createElement(Grid, { container: true, alignItems: 'center', justify: 'flex-end', className: clsx((_b = {}, _b[className.root] = true, _b)) },
            React.createElement(BaseSelect, { v18: true, fullWidth: false, name: "limit", style: { marginLeft: theme.spacing(1), width: 'initial' }, title: langOrderList.t('order_list_filter_pagination_per_page'), value: variables.filter.limit, onChange: onChangePerPage, options: ['10', '25', '50'] }),
            React.createElement(Pagination, { style: { marginLeft: theme.spacing(1) }, count: Math.ceil(details.count / variables.filter.limit), page: variables.filter.page, onChange: function (e, page) { return onChangePerPage({ target: { name: 'page', value: page } }); }, color: "primary" })))) ||
            null,
        (query && loading && (React.createElement(Grid, { container: true, justify: 'center' },
            React.createElement(CircularIndeterminate, null)))) ||
            null));
});
