export var NOTIFICATION_ACTIONS;
(function (NOTIFICATION_ACTIONS) {
    NOTIFICATION_ACTIONS["REGISTER_USER"] = "user_request_register";
    NOTIFICATION_ACTIONS["REGISTER_USER_SHORT"] = "user_request_register_short";
    NOTIFICATION_ACTIONS["REGISTER_USER_APPROVED"] = "user_registration_approved";
    NOTIFICATION_ACTIONS["REGISTER_USER_APPROVED_SORT"] = "user_registration_approved_short";
    NOTIFICATION_ACTIONS["REGISTER_USER_APPROVED_INVITE"] = "user_registration_approved_invite";
    NOTIFICATION_ACTIONS["REGISTER_USER_INVITE_TO_TEAM"] = "user_registration_invite_to_team";
    NOTIFICATION_ACTIONS["REGISTER_USER_AFFILIATE_INVITE_TO_TEAM"] = "user_registration_affiliate_invite_to_team";
    NOTIFICATION_ACTIONS["REGISTER_USER_APPROVED_FROM_OLD"] = "user_registration_approved_from_old";
    NOTIFICATION_ACTIONS["RESET_PSW"] = "user_forgot_password";
    NOTIFICATION_ACTIONS["USER_JOINED_TO_ENV"] = "user_joined_to_env";
    NOTIFICATION_ACTIONS["USER_LEFT_ENV"] = "user_left_env";
    NOTIFICATION_ACTIONS["QUOTE_CREATED"] = "notify_customer_order_quote_created";
    NOTIFICATION_ACTIONS["QUOTE_UPDATED"] = "notify_customer_quote_updated";
    NOTIFICATION_ACTIONS["DOCUMENT_UPLOADED_FOR_NONE_APP_USER"] = "notify_emails_document_uploaded";
    NOTIFICATION_ACTIONS["REQUEST_ORDER_DOCUMENT"] = "notify_emails_request_order_document";
    NOTIFICATION_ACTIONS["QUOTE_ASSIGN_TRANSPORT"] = "notify_customer_order_quote_assign_transport";
    NOTIFICATION_ACTIONS["QUOTE_LOADING_AT_BOOKED"] = "notify_customer_order_quote_loading_time_booked";
    NOTIFICATION_ACTIONS["QUOTE_UNLOADING_AT_BOOKED"] = "notify_customer_order_quote_unloading_time_booked";
    NOTIFICATION_ACTIONS["QUOTE_UNLOADING_AT_CHANGED"] = "notify_customer_order_quote_unloading_time_changed";
    NOTIFICATION_ACTIONS["QUOTE_LOADING_AT_CHANGED"] = "notify_customer_order_quote_loading_time_changed";
    NOTIFICATION_ACTIONS["QUOTE_LOADING_AT"] = "notify_order_quote_loading_at";
    NOTIFICATION_ACTIONS["QUOTE_LOADING_AT_SYSTEM"] = "notify_order_quote_loading_at_by_system";
    NOTIFICATION_ACTIONS["QUOTE_UNLOADING_AT"] = "notify_order_quote_un_loading_at";
    NOTIFICATION_ACTIONS["QUOTE_UNLOADING_AT_SYSTEM"] = "notify_order_quote_un_loading_at_by_system";
    NOTIFICATION_ACTIONS["QUOTE_NO_LOADING_AT"] = "notify_order_quote_no_loading_at";
    NOTIFICATION_ACTIONS["QUOTE_NO_UNLOADING_AT"] = "notify_order_quote_no_un_loading_at";
    NOTIFICATION_ACTIONS["QUOTE_BOOKING_NOTIFY_LOCATION_ROUTE_COMPANY"] = "notify_location_route_company_order_quote_booking";
    NOTIFICATION_ACTIONS["QUOTE_ACCEPTED"] = "notify_carrier_order_quote_accepted";
    NOTIFICATION_ACTIONS["QUOTE_REJECTED"] = "notify_carrier_order_quote_rejected";
    NOTIFICATION_ACTIONS["QUOTE_REJECTED_BY_SYSTEM"] = "notify_carrier_order_quote_rejected_by_system";
    NOTIFICATION_ACTIONS["ORDER_PUBLISHED_FOR_QUOTE"] = "notify_carrier_order_published";
    NOTIFICATION_ACTIONS["ORDER_CARRIER_IN_PROGRESS"] = "notify_customer_order_in_progress";
    NOTIFICATION_ACTIONS["NOTIFICATION_ORDER_PUBLISHED_FOR"] = "notify_order_published_for";
    NOTIFICATION_ACTIONS["ORDER_NOTIFY_CUSTOMER_FINISHED"] = "notify_customer_order_finished";
    NOTIFICATION_ACTIONS["ORDER_FINISHED"] = "notify_user_order_finished";
    NOTIFICATION_ACTIONS["ORDER_TO_PUBLISH"] = "fake_order_to_publish";
    NOTIFICATION_ACTIONS["ORDER_PUBLISHED_FOR_QUOTE_REPORT"] = "notify_carrier_order_published_report";
    NOTIFICATION_ACTIONS["ORDER_MISSING_BOOKING_PICKUP"] = "notify_carrier_order_missing_booking_pickup";
    NOTIFICATION_ACTIONS["ORDER_MISSING_BOOKING_DELIVERY"] = "notify_carrier_order_missing_booking_delivery";
    NOTIFICATION_ACTIONS["ORDER_BOOKING_PICKUP_NOT_CHANGED"] = "notify_carrier_order_booking_pickup_not_changed";
    NOTIFICATION_ACTIONS["ORDER_BOOKING_DELIVERY_NOT_CHANGED"] = "notify_carrier_order_booking_delivery_not_changed";
    NOTIFICATION_ACTIONS["ORDER_RESTORED"] = "notify_carrier_order_restored";
    NOTIFICATION_ACTIONS["LOCATION_UPDATED_WAREHOUSE"] = "notify_location_updated_warehouse";
    NOTIFICATION_ACTIONS["ORDER_QUOTE_REQUEST_CHANGE"] = "notify_customer_order_quote_request_change";
    NOTIFICATION_ACTIONS["ORDER_UPDATED_1"] = "notify_customer_order_expired";
    NOTIFICATION_ACTIONS["ORDER_DELETED"] = "notify_carrier_order_removed";
    NOTIFICATION_ACTIONS["ORDER_CANCELED"] = "notify_carrier_order_canceled";
    NOTIFICATION_ACTIONS["ORDER_CARRIER_APPROVED"] = "notify_carrier_order_quote_lose";
    NOTIFICATION_ACTIONS["ORDER_CARRIER_APPROVED_NOTIFY_CUSTOMER"] = "notify_customer_order_quote_approved";
    NOTIFICATION_ACTIONS["ORDER_CARRIER_ORDER_QUOTE_APPROVED"] = "notify_carrier_order_quote_approved";
    NOTIFICATION_ACTIONS["ORDER_CARRIER_BOOKING_PASS"] = "notify_carrier_order_booking_pass";
    NOTIFICATION_ACTIONS["ORDER_SENDER_CONSIGNER_ORDER_QUOTE_APPROVED"] = "notify_third_party_order_quote_approved";
    NOTIFICATION_ACTIONS["ORDER_PREPARING_STATUS_UPDATED"] = "notify_consignee_order_preparing_status";
    NOTIFICATION_ACTIONS["ORDER_QUOTE_CHANGES"] = "order_quote_changes";
    NOTIFICATION_ACTIONS["ORDER_PAID_CHANGES"] = "order_paid_changes";
    NOTIFICATION_ACTIONS["ORDER_PAID_RELEASE"] = "notify_carrier_order_payment_release";
    NOTIFICATION_ACTIONS["ORDER_PAID_ACCEPT"] = "notify_customer_order_payment_accept";
    NOTIFICATION_ACTIONS["ORDER_PAID_REJECT"] = "notify_customer_order_payment_reject";
    NOTIFICATION_ACTIONS["ORDER_GET_PAYMENT"] = "carrier_request_loan";
    NOTIFICATION_ACTIONS["CHAT_UNREAD_MESSAGES"] = "notify_chat_unread_messages";
    NOTIFICATION_ACTIONS["INVOICE_CREATED"] = "notify_order_invoice_created";
    NOTIFICATION_ACTIONS["INVOICE_PAYED"] = "notify_order_invoice_payed";
    NOTIFICATION_ACTIONS["INVOICE_REQUEST"] = "notify_order_invoice_request";
    NOTIFICATION_ACTIONS["LINK_TO_BOOK"] = "booking_access_token";
    NOTIFICATION_ACTIONS["COMPANY_DOCUMENT_EXPIRED"] = "notify_carrier_company_document_expired";
    NOTIFICATION_ACTIONS["SOFT_INVITATION"] = "notify_user_soft_invitation";
    NOTIFICATION_ACTIONS["COMPANY_REQUEST_TERM"] = "notify_admin_company_term_request";
    NOTIFICATION_ACTIONS["COMPANY_REQUEST_TERM_OWNER"] = "notify_owner_company_term_request";
    NOTIFICATION_ACTIONS["COMPANY_REQUEST_TERM_APPROVED"] = "notify_admin_company_term_request_approved";
    NOTIFICATION_ACTIONS["COMPANY_REQUEST_TERM_DECLINED"] = "notify_admin_company_term_request_declined";
    NOTIFICATION_ACTIONS["NOTIFY_CARRIER_NEED_TO_DELIVERY"] = "notify_carrier_need_to_delivery";
    NOTIFICATION_ACTIONS["NOTIFY_CARRIER_NEED_TO_PICKUP"] = "notify_carrier_need_to_pickup";
    NOTIFICATION_ACTIONS["ISSUE_CREATE"] = "notify_issue_created";
    NOTIFICATION_ACTIONS["ISSUE_UPDATE"] = "notify_issue_updated";
    NOTIFICATION_ACTIONS["ISSUE_DELETED"] = "notify_issue_deleted";
    NOTIFICATION_ACTIONS["FAST_PAYMENT_CREATE"] = "notify_fast_payment_created";
    NOTIFICATION_ACTIONS["CREATE_CHAT_MESSAGE"] = "create_chat_message";
    NOTIFICATION_ACTIONS["UPDATE_CACHE"] = "update_cache";
    NOTIFICATION_ACTIONS["EXPORT_ORDERS"] = "export_orders";
    NOTIFICATION_ACTIONS["PUSH_NOTIFICATIONS"] = "push_notifications";
    NOTIFICATION_ACTIONS["DOCUMENT_MISSING"] = "notify_document_missing";
})(NOTIFICATION_ACTIONS || (NOTIFICATION_ACTIONS = {}));
