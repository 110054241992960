var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import List from '@material-ui/core/List';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { useMediaQuery } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../../i18n';
import { inject, observer } from 'mobx-react';
import { BaseUserAppTYpe } from '../../../stores/common';
import { OrderStatus } from '../../../models';
import { theme } from '../../../theme';
import { CircularIndeterminate } from '../../CircularIndeterminate';
import OrderRouteMenuItem from './OrderRoutesMenuItem';
import { MainButton } from '../../MainButton';
import PrintIcon from '@material-ui/icons/Print';
import BaseTooltip from '../../BaseTooltip';
export var useCMRStyles = makeStyles(function (theme) {
    return createStyles({
        appBar: {
            position: 'relative',
        },
        title: {
            marginLeft: theme.spacing(2),
            flex: 1,
            color: theme.palette.common.white,
        },
        ul: {
            '& textarea': {
                resize: 'none',
            },
        },
    });
});
var Transition = React.forwardRef(function Transition(props, ref) {
    return React.createElement(Slide, __assign({ direction: "up", ref: ref }, props));
});
export default inject('store')(observer(function OrderPDF(_a) {
    var _this = this;
    var order = _a.order, props = __rest(_a, ["order"]);
    var _b = props.store, orderStore = _b.orderStore, currentUser = _b.accountStore.currentUser;
    var isCustomer = currentUser.appType.includes(BaseUserAppTYpe.CUSTOMER);
    var isForwarder = currentUser.appType.includes(BaseUserAppTYpe.FORWARDER); //|| currentUser.roles.includes(USER_ROLE.ADMIN);
    var customerOrderAvailable = (isCustomer || isForwarder) && [OrderStatus.ApprovedQuote].includes(order === null || order === void 0 ? void 0 : order.status);
    var langForm = useTranslation(KEYS.FORM);
    var langApp = useTranslation(KEYS.APP);
    var classes = useCMRStyles();
    var container = React.useRef(null);
    var _c = __read(React.useState(false), 2), open = _c[0], setOpen = _c[1];
    var _d = __read(React.useState(null), 2), template = _d[0], setTemplate = _d[1];
    var _e = __read(React.useState(false), 2), pending = _e[0], setPending = _e[1];
    var loadData = function () { return __awaiter(_this, void 0, void 0, function () {
        var _a, e_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 3, 4, 5]);
                    if (!open) return [3 /*break*/, 2];
                    setPending(true);
                    _a = setTemplate;
                    return [4 /*yield*/, orderStore.loadOrderPDF(order._id)];
                case 1:
                    _a.apply(void 0, [(_b.sent()).html]);
                    _b.label = 2;
                case 2: return [3 /*break*/, 5];
                case 3:
                    e_1 = _b.sent();
                    console.log(e_1);
                    return [3 /*break*/, 5];
                case 4:
                    setPending(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    /*eslint-disable */
    React.useEffect(function () {
        loadData();
    }, [open]);
    /*eslint-enable */
    if (!customerOrderAvailable) {
        return null;
    }
    var handleClickOpen = function () {
        setOpen(true);
    };
    var handleClose = function () {
        setOpen(false);
    };
    var onPrint = function () { return __awaiter(_this, void 0, void 0, function () {
        var w, dom;
        return __generator(this, function (_a) {
            w = window.open();
            dom = container.current;
            w.document.write("<link\n      href=\"https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap\"\n      rel=\"stylesheet\"\n    /><style>\n    @page {\n    size: auto;\n    margin: 5px;\n}\n\n      body {\n        font-family: 'Open Sans', sans-serif;\n      }\ninput,\n      textarea {\n        border:'none';\n          resize: none;\n        z-index:-1;\n        font-family: 'Open Sans', sans-serif;\n      }</style>" + dom.innerHTML);
            try {
                w.document.querySelector('img').onload = function () {
                    w.print();
                    w.close();
                };
            }
            catch (e) {
                setTimeout(function () {
                    w.print();
                    w.close();
                }, 1000);
            }
            return [2 /*return*/];
        });
    }); };
    var theme1 = useTheme();
    var isSmall = useMediaQuery(theme1.breakpoints.down('sm'));
    var content = (pending && (React.createElement(Grid, { container: true, justify: 'center' },
        React.createElement(CircularIndeterminate, null)))) ||
        (template && (React.createElement("div", { dangerouslySetInnerHTML: {
                __html: template,
            } }))) ||
        null;
    var icon = (React.createElement(BaseTooltip, { title: langForm.t('form_btn_print_order') },
        React.createElement(IconButton, { onClick: (!props.label && handleClickOpen), color: 'primary', style: props.iconStyle, id: 'btn-create-order-pdf' },
            React.createElement(PrintIcon, null))));
    return (React.createElement(React.Fragment, null,
        props.label ? React.createElement(OrderRouteMenuItem, __assign({}, { icon: icon, label: props.label, handleClickOpen: handleClickOpen })) : icon,
        React.createElement(Dialog, { disableEnforceFocus: true, fullScreen: true, open: open, onClose: handleClose, TransitionComponent: Transition },
            React.createElement(AppBar, { className: classes.appBar },
                React.createElement(Toolbar, null,
                    React.createElement(IconButton, { edge: "start", color: "inherit", onClick: handleClose, "aria-label": "close" },
                        React.createElement(CloseIcon, null)),
                    React.createElement(Typography, { variant: "h6", className: classes.title }, langApp.t('main_order')),
                    React.createElement(Grid, { container: true, alignItems: 'center', style: { width: 'initial' } },
                        React.createElement(MainButton, { v1: true, id: 'btn-print-cmr-1', style: { margin: theme.spacing(0, 2) }, onClick: onPrint, title: langForm.t('form_btn_print') })))),
            React.createElement("div", { key: template },
                React.createElement(List, { ref: container, className: classes.ul, style: !isSmall
                        ? {
                            padding: theme.spacing(2),
                            width: 740,
                            position: 'relative',
                            margin: 'auto',
                            transform: 'scale(2)',
                            transformOrigin: 'top',
                        }
                        : {} }, content)))));
}));
