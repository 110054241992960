var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React from 'react';
import { Grid, LinearProgress, useMediaQuery, useTheme, withStyles } from '@material-ui/core';
import StepConnector from '@material-ui/core/StepConnector';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { TextOverflow } from './TextOverflow';
import { makeStyles } from '@material-ui/core/styles';
import Check from '@material-ui/icons/Check';
import clsx from 'clsx';
import { theme } from '../theme';
import BaseTooltip from './BaseTooltip';
import { i18n, KEYS } from '../i18n';
import { BasicDialog } from './Dialog';
import { RouteFormItem } from './Order/form/formsV1/Routes/RouteFormBasic';
import { OrderRouteType } from '../models';
import moment from 'moment';
import { OrderCreationMode } from '../pages/Order/FormV1';
import { useTranslation } from 'react-i18next';
import { useFormStyles } from '../styles';
import { ColorlibStepIconV1, QontoConnectorV1, useStepperStylesV1 } from './Order/List/OrderProgress';
import { MdiAccountCogOutlineIcon, TruckOkIcon } from './icons';
var styles = makeStyles(function (theme) { return ({
    root: {
        width: '100%',
        padding: theme.spacing(2, 0),
    },
}); });
var QontoConnector = withStyles({
    alternativeLabel: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    active: {
        '& $line': {
            borderColor: theme.palette.primary.main,
        },
    },
    completed: {
        '& $line': {
            borderColor: theme.palette.primary.main,
        },
    },
    line: {
        borderColor: theme.palette.grey['400'],
        borderTopWidth: 3,
        borderRadius: 1,
    },
})(StepConnector);
var useQontoStepIconStyles = makeStyles({
    root: {
        color: '#eaeaf0',
        display: 'flex',
        height: 22,
        alignItems: 'center',
    },
    rootV2: {
        transition: '0.3s',
        transform: 'scale(0.5)',
        '&:hover': {
            transform: 'none',
        },
    },
    active: {
        color: theme.palette.primary.main,
    },
    circle: {
        position: 'relative',
        width: 30,
        height: 30,
        borderWidth: 4,
        borderStyle: 'solid',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    circleActive: {
        borderColor: theme.palette.primary.main,
    },
    circleCompeted: {
        '&::after': {
            content: "' '",
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%,-50%)',
            borderRadius: '50%',
            width: '70%',
            height: '70%',
            backgroundColor: theme.palette.primary.main,
        },
    },
    circleRegular: {
        borderColor: theme.palette.grey['400'],
    },
    completed: {
        color: theme.palette.common.white,
        zIndex: 1,
        fontSize: 22,
    },
});
function QontoStepIcon(props) {
    var _a, _b, _c, _d;
    var classes = useQontoStepIconStyles();
    var active = props.active, completed = props.completed;
    return (React.createElement("div", { className: clsx(classes.root, (_a = {},
            _a[classes.active] = active,
            _a)) },
        React.createElement("div", { className: clsx(classes.circle, (_b = {}, _b[classes.circleRegular] = !completed && !active, _b), (_c = {}, _c[classes.circleActive] = completed || active, _c), (_d = {}, _d[classes.circleCompeted] = completed, _d)) }, completed ? React.createElement(Check, { className: classes.completed }) : null)));
}
export default (function (_a) {
    var steps = _a.steps, v1 = _a.v1, v2 = _a.v2, isProgress = _a.isProgress, activeIndex = _a.activeIndex, disabled = _a.disabled, props = __rest(_a, ["steps", "v1", "v2", "isProgress", "activeIndex", "disabled"]);
    var classesStep = useQontoStepIconStyles();
    var stepperClasses = useStepperStylesV1();
    var index = v1 || v2 ? steps.findIndex(function (e) { return props.current === e._id; }) : steps.filter(function (e) { return e.active; }).length + 1;
    var _b = __read(React.useState(disabled ? -1 : activeIndex ? activeIndex : index < 0 ? 0 : index), 1), activeStep = _b[0];
    var langOrderForm = useTranslation(KEYS.ORDER_FORM);
    var langOrderList = useTranslation(KEYS.ORDER_LIST);
    var classes = styles();
    var theme1 = useTheme();
    var formClasses = useFormStyles();
    var isSmall = useMediaQuery(theme1.breakpoints.down('sm'));
    return (React.createElement(Grid, { container: true, direction: 'column' },
        React.createElement(Stepper, { alternativeLabel: isProgress, connector: isProgress ? React.createElement(QontoConnectorV1, null) : React.createElement(QontoConnector, null), activeStep: activeStep, orientation: isProgress ? undefined : isSmall ? 'vertical' : 'horizontal', style: isProgress
                ? { padding: 0, backgroundColor: 'transparent' }
                : v2
                    ? {}
                    : __assign({}, (props.loading && { pointerEvents: 'none', opacity: 0.7 })), classes: { root: classes.root } }, steps.map(function (step, index) { return (React.createElement(Step, { key: step._id || step.label, classes: isProgress ? { root: stepperClasses.stepRoot } : {}, style: v2 ? {} : __assign(__assign({}, (index === 0 && !isSmall && !v2 ? { paddingLeft: 0 } : {})), props.stepStyle) },
            React.createElement(StepLabel, { StepIconProps: {
                    icons: function (getIconClass) {
                        var _a, _b, _c;
                        return ({
                            1: {
                                icon: React.createElement(Check, { className: getIconClass(1) }),
                                title: (_a = steps[0]) === null || _a === void 0 ? void 0 : _a.label,
                            },
                            2: {
                                icon: React.createElement(MdiAccountCogOutlineIcon, { className: getIconClass(2) }),
                                title: (_b = steps[1]) === null || _b === void 0 ? void 0 : _b.label,
                            },
                            3: {
                                icon: React.createElement(TruckOkIcon, { className: getIconClass(3) }),
                                title: (_c = steps[2]) === null || _c === void 0 ? void 0 : _c.label,
                            },
                        });
                    },
                    disabled: disabled,
                }, StepIconComponent: isProgress
                    ? ColorlibStepIconV1
                    : v1 || v2
                        ? function (p) {
                            var _a;
                            var _b;
                            var isCurrent = props.current === step._id;
                            var icon = (React.createElement(BaseTooltip, { title: !props.onClick
                                    ? ''
                                    : v2
                                        ? step.text
                                        : (_b = i18n
                                            .getResource(i18n.language, KEYS.FORM, 'fom_start_preparation_step')) === null || _b === void 0 ? void 0 : _b.replace(/_X_/, step.text.toLowerCase()) },
                                React.createElement(Grid, { container: true, justify: 'center', alignItems: 'center', direction: 'column', className: clsx((_a = {}, _a[classesStep.rootV2] = v2, _a)), style: __assign({}, (v2 || !props.onClick
                                        ? {}
                                        : isCurrent
                                            ? { cursor: 'pointer' }
                                            : { pointerEvents: 'none' })) },
                                    React.createElement(QontoStepIcon, __assign({}, p)),
                                    (!v2 && (React.createElement(React.Fragment, null,
                                        React.createElement("br", null),
                                        step.label))) ||
                                        null)));
                            if (isCurrent) {
                                if (v2) {
                                    return icon;
                                }
                                var transportations_1 = props.order.transportations || [];
                                var dialogBody = (React.createElement(Grid, { container: true, style: { justifyContent: 'center', alignItems: 'center', height: '200px' } },
                                    React.createElement(Grid, { container: true, item: true, xl: 12, style: { marginBottom: '10px', justifyContent: 'center', alignItems: 'center' } },
                                        React.createElement(TextOverflow, { style: { margin: 'auto' } }, langOrderForm.t('order_preparation_confirmation'))),
                                    transportations_1.map(function (transportation, index) {
                                        var nextDates = transportations_1[index + 1] &&
                                            transportations_1[index + 1].routes
                                                .map(function (e) { return [e.startDate, e.endDate]; })
                                                .reduce(function (a, b) { return __spread(a, b); }, [])
                                                .filter(function (e) { return e; })
                                                .map(function (e) { return moment(e); });
                                        var maxDate = nextDates && nextDates.length && moment.max(nextDates);
                                        var minDate = undefined;
                                        var loading = transportation.routes.find(function (route) { return route.type === OrderRouteType.Preferred; });
                                        var unloading = transportation.routes.find(function (route) { return route.type === OrderRouteType.Delivery; });
                                        var startRouteProps = Object.assign({
                                            maxLimit: unloading,
                                        }, minDate
                                            ? {
                                                minLimit: {
                                                    orderTransportation: transportation,
                                                    type: OrderRouteType.Preferred,
                                                    startDate: minDate,
                                                },
                                            }
                                            : null);
                                        var endRouteProps = Object.assign({
                                            minLimit: loading,
                                        }, maxDate
                                            ? {
                                                maxLimit: {
                                                    orderTransportation: props.transportation,
                                                    type: OrderRouteType.Delivery,
                                                    endDate: maxDate,
                                                },
                                            }
                                            : null);
                                        return (React.createElement(React.Fragment, null,
                                            React.createElement(Grid, { container: true, item: true, xl: 12 },
                                                React.createElement(TextOverflow, { variant: "subtitle1", className: formClasses.sectionTitle1 }, langOrderForm.t('create_order_transportation_label') + " " + (transportations_1.length > 1 ? index + 1 : ''))),
                                            React.createElement(Grid, { container: true, item: true, xl: 12, style: __assign({}, (index !== 0 && { marginTop: '25px' })) },
                                                React.createElement(RouteFormItem, __assign({ v1: true, 
                                                    // title={langOrderForm.t('create_order_route_origin_from')}
                                                    dateTitle: langOrderList.t('order_list_filter_sort_loading_at'), isMobileDateRangePicker: true, isOnlyRange: true, index: 1, canAdd: true, config: props.config, defaultValue: loading, key: loading._id + "-" + (loading.updatedAt || 1), locationType: OrderRouteType.Preferred, endLocation: unloading, transportationIndex: index, mode: OrderCreationMode.BASIC }, startRouteProps, { noEdit: false }))),
                                            React.createElement(Grid, { container: true, item: true, xl: 12 },
                                                React.createElement(RouteFormItem, __assign({ v1: true, title: langOrderForm.t('create_order_route_origin_to'), dateTitle: langOrderForm.t('create_order_route_origin_to_date'), isMobileDateRangePicker: true, isOnlyRange: true, index: 2, canAdd: true, config: props.config, defaultValue: unloading, key: unloading._id + "-" + (unloading.updatedAt || 1), locationType: OrderRouteType.Delivery, startLocation: loading, transportationIndex: index, mode: OrderCreationMode.BASIC }, endRouteProps, { noEdit: false })))));
                                    })));
                                if (!props.onClick)
                                    return React.createElement(Grid, null, icon);
                                return (React.createElement(BasicDialog, { withoutWrapper: true, body: dialogBody, dialogProps: {
                                        maxWidth: 'sm',
                                    }, title: i18n.getResource(i18n.language, KEYS.FORM, 'form_btn_confirm'), onOk: function () { return isCurrent && props.onClick && props.onClick(step); }, actionInit: function (handleClickOpen) { return React.createElement(Grid, { onClick: handleClickOpen }, icon); } }));
                            }
                            return icon;
                        }
                        : QontoStepIcon }, (!(v1 || v2) && React.createElement(TextOverflow, null, step.label)) || null))); })),
        props.loading && React.createElement(LinearProgress, null)));
});
