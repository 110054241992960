var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import gql from "graphql-tag";
import { BASE_QUERY, BASE_RESPONSE } from "../base";
export var ORDER_VEHICLE_TYPE_BASE = "\n    " + BASE_QUERY + "\n      origin\n      isGlobal\n      approvedAt\n      name\n      description \n      orderIndex\n";
export var CreateOrderVehicleType = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    mutation createOrderVehicleType($input: OrderVehicleTypeInput!) {\n        createOrderVehicleType(input: $input) {\n            ", "\n        }\n    }\n"], ["\n    mutation createOrderVehicleType($input: OrderVehicleTypeInput!) {\n        createOrderVehicleType(input: $input) {\n            ", "\n        }\n    }\n"])), ORDER_VEHICLE_TYPE_BASE);
export var UpdateOrderVehicleType = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    mutation updateOrderVehicleType($input: OrderVehicleTypeInputUpdate!) {\n        updateOrderVehicleType(input: $input) {\n            ", "\n        }\n    }\n"], ["\n    mutation updateOrderVehicleType($input: OrderVehicleTypeInputUpdate!) {\n        updateOrderVehicleType(input: $input) {\n            ", "\n        }\n    }\n"])), ORDER_VEHICLE_TYPE_BASE);
export var FetchOrderVehicleType = gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    query orderVehicleTypes($filter:MainFilter) {\n        orderVehicleTypes(filter:$filter) {\n            data{\n                ", "\n            }\n            count\n        }\n    }\n"], ["\n    query orderVehicleTypes($filter:MainFilter) {\n        orderVehicleTypes(filter:$filter) {\n            data{\n                ", "\n            }\n            count\n        }\n    }\n"])), ORDER_VEHICLE_TYPE_BASE);
export var DeleteOrderVehicleType = gql(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    mutation deleteOrderVehicleType($_id: MongoId!) {\n        deleteOrderVehicleType(_id: $_id) {\n            ", "\n        }\n    }\n"], ["\n    mutation deleteOrderVehicleType($_id: MongoId!) {\n        deleteOrderVehicleType(_id: $_id) {\n            ", "\n        }\n    }\n"])), BASE_RESPONSE);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
