var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { inject, observer, useObserver } from 'mobx-react';
import React from 'react';
import { getSnapshot } from 'mobx-state-tree';
import { OrderLocation } from '../../../../models';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../../../i18n';
import { canEdit } from '../utils';
import * as Yup from 'yup';
import { onlySpacesRegExp } from '../../../../common';
import { Form, Formik } from 'formik';
import { Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import InputTitle from '../../../Formik/InputTitle';
import { FormikGoogleTextFieldV1, FormikTextField } from '../../../Formik';
import { useCargoFormStyles } from './CargoForm';
import { TextOverflow } from '../../../TextOverflow';
import { theme } from '../../../../theme';
import { useSectionStyles } from '../../../../styles';
import PaddingSpace from './PaddingSpce';
import { OrderCreationMode } from '../../../../pages/Order/FormV1';
export default inject('store')(observer(React.forwardRef(function (_a, ref) {
    var store = _a.store, index = _a.index, defaultValue = _a.defaultValue, mode = _a.mode, props = __rest(_a, ["store", "index", "defaultValue", "mode"]);
    var langOrderForm = useTranslation(KEYS.ORDER_FORM);
    var langForm = useTranslation(KEYS.FORM);
    var noEdit = !canEdit(store.orderStore.order, store); // || store.orderStore.isOrderUpdating;
    var classes = useCargoFormStyles();
    var classesSection = useSectionStyles();
    var order = store.orderStore.order;
    var copyDef = JSON.parse(JSON.stringify(defaultValue || getSnapshot(order)));
    [
        'exportDeclaration',
        'exportDeclarationAddress',
        'exportDeclarationBrokerCompany',
        'exportDeclarationContacts',
        'importDeclaration',
        'importDeclarationAddress',
        'importDeclarationBrokerCompany',
        'importDeclarationContacts',
    ].forEach(function (key, index) {
        if (!copyDef[key]) {
            copyDef[key] = '';
        }
        else if (key.match(/address/gi)) {
            copyDef[key] = getSnapshot(OrderLocation.create({ address: copyDef[key] }));
        }
    });
    var _b = __read(React.useState(copyDef), 2), formValues = _b[0], setFormValues = _b[1];
    function onSubmit(input) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, input];
            });
        });
    }
    function onChange(input) {
        return __awaiter(this, void 0, void 0, function () {
            var i, list, name_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (Object.keys(input).filter(function (key) { return formValues[key] !== input[key]; }).length === 0) {
                            return [2 /*return*/];
                        }
                        i = 0, list = Object.keys(input);
                        _a.label = 1;
                    case 1:
                        if (!(i < list.length)) return [3 /*break*/, 4];
                        name_1 = list[i];
                        return [4 /*yield*/, ref.current.setFieldValue(name_1, input[name_1])];
                    case 2:
                        _a.sent();
                        if (ref.current.errors[name_1])
                            return [2 /*return*/, ref.current.setFieldTouched(name_1)];
                        _a.label = 3;
                    case 3:
                        i++;
                        return [3 /*break*/, 1];
                    case 4: return [4 /*yield*/, onSave(input)];
                    case 5:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    }
    function onSave(input) {
        return __awaiter(this, void 0, void 0, function () {
            var newVal;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, store.orderStore.updateOrder(__assign(__assign({}, input), { _id: formValues._id }))];
                    case 1:
                        _a.sent();
                        newVal = __assign(__assign({}, formValues), input);
                        setFormValues(newVal);
                        return [2 /*return*/];
                }
            });
        });
    }
    function onChangeInput(e, checked) {
        var _a;
        onChange((_a = {},
            _a[e.target.name] = ['exportDeclaration', 'importDeclaration'].includes(e.target.name)
                ? checked
                : e.target.value,
            _a));
    }
    var formProps = {
        onSubmit: onSubmit,
    };
    Object.assign(formProps, {
        validationSchema: Yup.object(mode === OrderCreationMode.BASIC
            ? Object.assign({}, formValues.exportDeclaration
                ? {
                    exportDeclarationAddress: Yup.object({
                        postal: Yup.string().typeError(langForm.t('form_input_validate_required')),
                    })
                        .typeError(langForm.t('form_input_validate_required'))
                        .test('postal', langForm.t('form_input_validate_required'), function (val) {
                        return !!val.postal;
                    }),
                    exportDeclarationBrokerCompany: Yup.string()
                        .max(250, langForm.t('form_input_validate_max_char').replace(/_X_/, '250'))
                        .matches(onlySpacesRegExp, langForm.t('form_input_validate_invalid_text')),
                    exportDeclarationContacts: Yup.string()
                        .max(250, langForm.t('form_input_validate_max_char').replace(/_X_/, '250'))
                        .matches(onlySpacesRegExp, langForm.t('form_input_validate_invalid_text')),
                }
                : {}, formValues.importDeclaration
                ? {
                    importDeclarationAddress: Yup.object({
                        postal: Yup.string().typeError(langForm.t('form_input_validate_required')),
                    })
                        .typeError(langForm.t('form_input_validate_required'))
                        .test('postal', langForm.t('form_input_validate_required'), function (val) {
                        return !!val.postal;
                    }),
                    importDeclarationBrokerCompany: Yup.string()
                        .max(250, langForm.t('form_input_validate_max_char').replace(/_X_/, '250'))
                        .matches(onlySpacesRegExp, langForm.t('form_input_validate_invalid_text')),
                    importDeclarationContacts: Yup.string()
                        .max(250, langForm.t('form_input_validate_max_char').replace(/_X_/, '250'))
                        .matches(onlySpacesRegExp, langForm.t('form_input_validate_invalid_text')),
                }
                : {})
            : {}),
    });
    return useObserver(function () { return (React.createElement(Grid, { container: true, direction: 'column', className: classesSection.section },
        React.createElement(Grid, { container: true },
            React.createElement(PaddingSpace, null),
            React.createElement(Grid, { item: true, xs: 12, md: 11 },
                React.createElement(TextOverflow, { variant: "h4", className: classesSection.sectionTitle, style: { marginBottom: theme.spacing(2) } }, langOrderForm.t('create_order_cargo_declaration_more')))),
        React.createElement(Formik, __assign({ key: formValues._id, innerRef: ref, initialValues: formValues }, formProps), function (formik) {
            var declarationsForm = (React.createElement(React.Fragment, null,
                React.createElement(Grid, { container: true },
                    React.createElement(PaddingSpace, null),
                    React.createElement(Grid, { item: true, xs: 12, md: 8 },
                        React.createElement(FormControlLabel, { classes: {
                                root: classes.checkBoxRoot,
                                label: classes.checkBoxLabel,
                            }, control: React.createElement(Checkbox, { disabled: noEdit, checked: formik.values.exportDeclaration, name: "exportDeclaration", onChange: onChangeInput, color: "primary" }), label: React.createElement(InputTitle, { v1: true, help: langForm.t('form_input_help_order_cargo_custom_clearance'), title: langOrderForm.t('create_order_cargo_export_declaration') }) }))),
                React.createElement(Grid, { container: true, alignItems: "center", spacing: 3 },
                    React.createElement(PaddingSpace, null),
                    React.createElement(Grid, { item: true, xs: 12, md: 5 },
                        React.createElement(FormikGoogleTextFieldV1, { disabled: noEdit, v17: true, title: "" + langOrderForm.t('create_order_cargo_export_declaration_address'), variant: "outlined", name: "exportDeclarationAddress", onChange: onChangeInput, value: formik.values.exportDeclarationAddress })),
                    React.createElement(Grid, { item: true, xs: 12, md: 2 },
                        React.createElement(FormikTextField, { disabled: noEdit, title: "" + langOrderForm.t('create_order_cargo_export_broker'), variant: "outlined", name: "exportDeclarationBrokerCompany", onBlur: onChangeInput, value: formik.values.exportDeclarationBrokerCompany })),
                    React.createElement(Grid, { item: true, xs: 12, md: 3 },
                        React.createElement(FormikTextField, { disabled: noEdit, title: "" + langOrderForm.t('create_order_cargo_export_contacts'), variant: "outlined", name: "exportDeclarationContacts", onBlur: onChangeInput, value: formik.values.exportDeclarationContacts }))),
                React.createElement(Grid, { container: true, alignItems: "center", spacing: 3 },
                    React.createElement(PaddingSpace, null),
                    React.createElement(Grid, { item: true, xs: 12, md: 8 },
                        React.createElement(FormControlLabel, { style: { width: '100%' }, classes: {
                                root: classes.checkBoxRoot,
                                label: classes.checkBoxLabel,
                            }, control: React.createElement(Checkbox, { disabled: noEdit, checked: formik.values.importDeclaration, name: "importDeclaration", onChange: onChangeInput, color: "primary" }), label: React.createElement(InputTitle, { v1: true, help: langForm.t('form_input_help_order_cargo_custom_clearance'), title: langOrderForm.t('create_order_cargo_import_declaration') }) }))),
                React.createElement(Grid, { container: true, alignItems: "center", spacing: 3 },
                    React.createElement(PaddingSpace, null),
                    React.createElement(Grid, { item: true, xs: 12, md: 5 },
                        React.createElement(FormikGoogleTextFieldV1, { v17: true, disabled: noEdit, title: "" + langOrderForm.t('create_order_cargo_import_declaration_address'), variant: "outlined", name: "importDeclarationAddress", onChange: onChangeInput, value: formik.values.importDeclarationAddress })),
                    React.createElement(Grid, { item: true, xs: 12, md: 2 },
                        React.createElement(FormikTextField, { disabled: noEdit, title: "" + langOrderForm.t('create_order_cargo_export_broker'), variant: "outlined", name: "importDeclarationBrokerCompany", onBlur: onChangeInput, value: formik.values.importDeclarationBrokerCompany })),
                    React.createElement(Grid, { item: true, xs: 12, md: 3 },
                        React.createElement(FormikTextField, { disabled: noEdit, title: "" + langOrderForm.t('create_order_cargo_export_contacts'), variant: "outlined", name: "importDeclarationContacts", onBlur: onChangeInput, value: formik.values.importDeclarationContacts })))));
            return (React.createElement(Form, { id: "declaration-form", style: { width: '100%' } }, declarationsForm));
        }))); });
})));
