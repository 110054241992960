var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { types, castToSnapshot, applySnapshot } from 'mobx-state-tree';
import { AsyncActionStatus, BaseUser, MaybeEmptyModel } from '../stores/common';
import { OrderLocationAddress } from './Location';
import { BaseModel } from './BaseModel';
export var Warehouse = types
    .compose(AsyncActionStatus, types
    .model('Warehouse', {
    _id: types.maybeNull(types.optional(types.string, function () { return ''; })),
    updatedAt: types.maybeNull(types.optional(types.string, '')),
    // updatedBy: types.maybeNull(types.optional(MaybeEmptyModel(User), () => ({}))),
    // createdBy: types.maybeNull(types.optional(MaybeEmptyModel(User), () => ({}))),
    address: types.maybeNull(types.optional(OrderLocationAddress, function () { return OrderLocationAddress.create(); })),
    description: types.maybeNull(types.optional(types.string, '')),
    name: types.maybeNull(types.optional(types.string, '')),
    selected: types.maybeNull(types.optional(types.number, 0)),
    isDayWorkingMatchBooking: types.maybeNull(types.optional(types.boolean, false)),
    manager: types.maybeNull(MaybeEmptyModel(BaseUser)),
    parent: types.maybeNull(MaybeEmptyModel(BaseModel)),
    company: types.maybeNull(MaybeEmptyModel(BaseModel)),
    ramp: types.maybeNull(MaybeEmptyModel(BaseModel)),
    phones: types.maybeNull(types.optional(types.array(types.string), [])),
    emails: types.maybeNull(types.optional(types.array(types.string), [])),
})
    .actions(function (self) { return ({
    clear: function () {
        var snap = castToSnapshot(Warehouse.create());
        applySnapshot(self, snap);
    },
    reset: function (snapshot) {
        applySnapshot(self, __assign(__assign({}, snapshot), { updatedAt: new Date().toString() }));
    },
}); })
    .views(function (self) { return ({}); }))
    .named('Warehouse');
