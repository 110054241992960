var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { createContext, useReducer } from 'react';
var initialState = {
    record: null,
};
var OrderListItemStore = createContext(JSON.parse(JSON.stringify(initialState)));
var Provider = OrderListItemStore.Provider, Consumer = OrderListItemStore.Consumer;
export var ACTIONS;
(function (ACTIONS) {
    ACTIONS["DELETE_ITEM"] = "DELETE_ITEM";
    ACTIONS["UPDATE_ITEM"] = "UPDATE_ITEM";
})(ACTIONS || (ACTIONS = {}));
export var OrderListItemProvider = function (_a) {
    var children = _a.children, defaultValue = _a.defaultValue;
    var initState = __assign(__assign({}, JSON.parse(JSON.stringify(initialState))), JSON.parse(JSON.stringify(defaultValue)));
    var _b = __read(useReducer(function (state, action) {
        switch (action.type) {
            case ACTIONS.DELETE_ITEM:
            case ACTIONS.UPDATE_ITEM: {
                return __assign(__assign({}, state), action.payload);
            }
            default:
                throw new Error("No " + action.type + " found");
        }
    }, initState), 2), state = _b[0], dispatch = _b[1];
    var onChange = function (payload) {
        var e = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            e[_i - 1] = arguments[_i];
        }
        dispatch({
            type: ACTIONS.UPDATE_ITEM,
            payload: JSON.parse(JSON.stringify(payload)),
        });
    };
    var value = {
        state: state,
        onChange: onChange,
    };
    return React.createElement(Provider, { value: value }, children);
};
export var withOrderListItemStore = function (Component) {
    return function (props, ref) {
        return (React.createElement(Consumer, null, function (orderListItemProps) {
            return React.createElement(Component, __assign({}, __assign(__assign({}, props), { orderListItemProps: orderListItemProps })));
        }));
    };
};
