var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect } from 'react';
import { useObserver } from 'mobx-react';
import { Typography, TextField, IconButton } from '@material-ui/core';
import { MobileDateTimePicker, MobileDatePicker } from '@material-ui/pickers';
import { useField } from 'formik';
import { useRangeStyles } from './RangeDatePicker';
import moment from 'moment';
import InputTitle from './Formik/InputTitle';
import { useInputStyles } from '../styles/form';
import CloseIcon from '@material-ui/icons/Close';
import { BaseTextField } from './Formik/BaseTextField';
export var BaseSingleDateTimePicker = function (props) {
    var _a = __read(React.useState(props.defaultValue || props.value), 2), value = _a[0], setValue = _a[1];
    var title = props.title, onClose = props.onClose, onChange = props.onChange, moreProps = __rest(props, ["title", "onClose", "onChange"]);
    var classes = useRangeStyles();
    var _b = __read(React.useState(false), 2), show = _b[0], setShow = _b[1];
    var isTitleComponent = props.v1 || props.v14;
    var _onClose = function (value) {
        setShow(false);
        onClose && onClose(value);
    };
    var _onChange = function (value) {
        onChange && onChange(value);
        setValue(value);
    };
    var onClear = function () {
        onChange && onChange(null);
        setValue(null);
        onClose && onClose(null);
    };
    var style = { marginTop: 0, marginBottom: 0 };
    if (props.style)
        Object.assign(style, props.style);
    return (React.createElement(MobileDateTimePicker, __assign({ disableToolbar: true, disablePast: true, showToolbar: false, minDate: moment(new Date()).startOf('day') }, moreProps, { value: value, onClose: _onClose, onChange: _onChange, ampm: false, inputFormat: props.withoutSeconds ? 'DD/MM/YYYY HH:mm' : 'DD/MM/YYYY HH:mm:ss', allowKeyboardControl: false, renderInput: function (startDate) {
            var _a, _b;
            var label = ((_a = props.InputProps) === null || _a === void 0 ? void 0 : _a.placeholder) || ((_b = startDate.InputProps) === null || _b === void 0 ? void 0 : _b.placeholder);
            var inputProps = __assign(__assign({ 
                // endAdornment: (
                //   <InputAdornment position="end">
                //     <IconButton>
                //       <DateRangeIcon className={classes.icon} />
                //     </IconButton>
                //   </InputAdornment>
                // ),
                endAdornment: null }, props.InputProps), (!isTitleComponent ? { placeholder: '' } : {}));
            if (props.InputProps && props.InputProps.endAdornment) {
                inputProps.endAdornment = props.InputProps.endAdornment;
            }
            else if (props.canClear) {
                inputProps.endAdornment = (React.createElement(IconButton, { style: { padding: 0 }, onClick: onClear, id: 'modal_close_icon_button' },
                    React.createElement(CloseIcon, null)));
            }
            return (React.createElement(TextField, __assign({ id: props.id || (title || 'time-picker-input').toString().replace(/ /gi, '').toLowerCase(), fullWidth: true, margin: "dense", autoComplete: "off", variant: "outlined", name: 'startDate' }, moreProps, { InputProps: inputProps, onFocus: function () {
                    setShow(true);
                } }, startDate, { helperText: "", style: style, label: !isTitleComponent && label })));
        } })));
};
export var BaseSingleDateTimePickerV1 = function (props) {
    var _a = __read(React.useState(props.defaultValue || props.value), 2), value = _a[0], setValue = _a[1];
    var title = props.title, onClose = props.onClose, onChange = props.onChange, moreProps = __rest(props, ["title", "onClose", "onChange"]);
    var classes = useRangeStyles();
    var classes1 = useInputStyles();
    var _b = __read(React.useState(false), 2), show = _b[0], setShow = _b[1];
    var isTitleComponent = props.v1 || props.v14;
    var _onClose = function (value) {
        setShow(false);
        onClose && onClose(value);
    };
    var _onChange = function (value) {
        onChange && onChange(value);
        setValue(value);
    };
    var onClear = function () {
        onChange && onChange(null);
        setValue(null);
        onClose && onClose(null);
    };
    var style = { marginTop: 0, marginBottom: 0 };
    if (props.style)
        Object.assign(style, props.style);
    return (React.createElement(MobileDatePicker, __assign({ id: 'date-picker' }, moreProps, { showToolbar: false, value: value, onClose: _onClose, onChange: _onChange, ampm: false, inputFormat: 'DD/MM/YYYY', allowKeyboardControl: false, allowSameDateSelection: true, renderInput: function (startDate) {
            var _a, _b;
            var label = ((_a = props.InputProps) === null || _a === void 0 ? void 0 : _a.placeholder) || ((_b = startDate.InputProps) === null || _b === void 0 ? void 0 : _b.placeholder);
            var copy = startDate;
            var inputProps = __assign(__assign({ 
                // endAdornment: (
                //   <InputAdornment position="end">
                //     <IconButton>
                //       <DateRangeIcon className={classes.icon} />
                //     </IconButton>
                //   </InputAdornment>
                // ),
                endAdornment: null }, props.InputProps), (!isTitleComponent ? { placeholder: '' } : {}));
            if (props.InputProps && props.InputProps.endAdornment) {
                inputProps.endAdornment = props.InputProps.endAdornment;
            }
            else if (props.canClear) {
                inputProps.endAdornment = (React.createElement(IconButton, { style: { padding: 0 }, onClick: onClear, id: 'modal_close_icon_button' },
                    React.createElement(CloseIcon, null)));
            }
            if (!inputProps.style)
                inputProps.style = {};
            Object.assign(inputProps.style, moreProps.inputStyle);
            return (React.createElement(BaseTextField, __assign({ className: classes1.root2, id: props.id || (title || 'time-picker-input-v1').toString().replace(/ /gi, '').toLowerCase(), fullWidth: true, margin: "dense", variant: "outlined", name: 'startDate' }, moreProps, { onFocus: function () {
                    setShow(true);
                } }, copy, { InputProps: inputProps, error: false, helperText: "", style: style, label: !isTitleComponent && label }, (props.withLabel && {
                required: false,
                placeholder: '',
                title: '',
            }))));
        } })));
};
var date = new Date();
export var FormikSingleDatePicker = function (props) {
    var title = props.title, value = props.value, onClose = props.onClose, onChange = props.onChange, v1 = props.v1, moreProps = __rest(props, ["title", "value", "onClose", "onChange", "v1"]);
    var _a = __read(useField(props), 3), field = _a[0], meta = _a[1], helpers = _a[2];
    var error = meta.error, touched = meta.touched;
    var touchedError = (touched && error) || '';
    if (touchedError && typeof touchedError === 'object') {
        if (Object.keys(touchedError).length) {
            touchedError = touchedError.toString();
        }
        else {
            touchedError = '';
        }
    }
    /*eslint-disable */
    useEffect(function () {
        return function () {
            date = null;
        };
    }, []);
    /*eslint-enable */
    var _onClose = function (value) {
        onClose && onClose(date);
    };
    var _onChange = function (value) {
        date = value;
        helpers.setValue(value);
        onChange && onChange(value);
    };
    return useObserver(function () { return (React.createElement(React.Fragment, null,
        !props.withoutTitle ? React.createElement(InputTitle, __assign({}, props)) : null,
        v1 ? (React.createElement(BaseSingleDateTimePickerV1, __assign({ value: value, minDate: new Date() }, moreProps, { placeholder: title, onClose: _onClose, onChange: _onChange }))) : (React.createElement(BaseSingleDateTimePicker, __assign({ value: value, minDate: new Date() }, moreProps, { onClose: _onClose, onChange: _onChange }))),
        touchedError && React.createElement(Typography, { color: 'error' }, touchedError))); });
};
