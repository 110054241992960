import React from 'react';
import { Grid, useMediaQuery } from '@material-ui/core';
import { TextOverflow } from '@izi-logistics/common/lib/components';
import { dateViewFormat } from '@izi-logistics/common/lib/components/DateView';
import { theme } from '@izi-logistics/common/lib/theme';
import { inject } from 'mobx-react';
import { DeleteDialog } from '@izi-logistics/common/lib/components';
import { ACTION_CHANGE } from '@izi-logistics/common/lib/components/context.store.enums';
import { OrderLocation } from '@izi-logistics/common/lib/models';
import MenuListItem from '@izi-logistics/common/lib/components/MenuListItem';
import { StyledBadge } from '@izi-logistics/common/lib/components/StyledBadge';
import { FlagComponent } from '@izi-logistics/common/lib/components/Flag';
import BaseTooltip from '@izi-logistics/common/lib/components/BaseTooltip';
import CompanyFormModal from '@izi-logistics/common/lib/components/Account/CompanyFormModal';
import { BaseListItemV1 } from '@izi-logistics/common/lib/components/BasicList/ListItem/BaseListItemV1';
import CompanyEjectFromEnv from '@izi-logistics/common/lib/pages/Companies/CompanyEjectFromEnv';
import { useTranslation } from 'react-i18next';
import { KEYS } from '@izi-logistics/common/lib/i18n';
import { USER_ROLE_TOOLS } from '@izi-logistics/common/lib/models/UserRoles';
import { AppType } from '@izi-logistics/common/lib/pages/Quotes/Documents/DocumentItemVisibleTo';

export default inject('store')(
  ({
    record,
    onChange,
    expandContent,
    handleExpandClick,
    headerColumns,
    store: {
      accountStore: { currentUser, currentCompany },
    },
  }: any) => {
    const langForm = useTranslation(KEYS.FORM);
    const onDelete = async () => {
      await currentCompany.deleteItem(record);
      if (onChange) onChange(record, ACTION_CHANGE.DELETED);
    };
    const canEdit = currentUser.rolesV1.find((e) =>
      e.tools.general.includes(USER_ROLE_TOOLS.GENERAL_can_edit_company_data)
    );
    const isDiffCompany = record.type !== currentCompany.type;
    const createdBy = record.createdBy?._id === currentUser._id && !isDiffCompany;
    const createdByCompany = record.createdBy?.company?._id?.toString() === currentCompany._id && !isDiffCompany;
    const isMiddle = useMediaQuery(theme.breakpoints.down('sm'));
    const childs = [
      {
        value: (
          <Grid item xs={12} container alignItems={'center'}>
            <BaseTooltip title={langForm.t('form_list_members_total')}>
              <StyledBadge badgeContent={record.totalUsers} color="primary">
                <div style={{ width: 6, height: 24 }} />
              </StyledBadge>
            </BaseTooltip>
            <TextOverflow style={{ maxWidth: 'calc(100% - 20px)' }}>{record.name || '-'}</TextOverflow>
          </Grid>
        ),
      },
      {
        value: (
          <Grid item xs={12} container alignItems={'center'}>
            {record.vatCode || '-'}
          </Grid>
        ),
      },
      {
        value: (
          <Grid item xs={12} container alignItems={'center'}>
            {record.companyCode || '-'}
          </Grid>
        ),
      },
      {
        value: (
          <Grid item xs={12} container alignItems={'center'}>
            {record.addressV1 && record.addressV1.country && (
              <FlagComponent country={record.addressV1.country} style={{ marginRight: theme.spacing(0.5) }} />
            )}
            <TextOverflow style={{ maxWidth: 'calc(100% - 44px)' }}>
              {(record.addressV1 && OrderLocation.create({ address: record.addressV1 }).fullAddress) || '-'}
            </TextOverflow>
          </Grid>
        ),
      },
      {
        value: (
          <Grid item xs={12} container alignItems={'center'}>
            <TextOverflow variant={'caption'}>
              {[record.createdAt]
                .filter((e) => e)
                .map((e) => dateViewFormat(e, 1))
                .join('/')}
            </TextOverflow>
          </Grid>
        ),
      },
      {
        value: (
          <Grid
            item
            xs={12}
            container
            alignItems={'center'}
            justify={isMiddle ? 'flex-start' : 'flex-end'}
            style={{ width: 'initial' }}>
            <AppType type={record.type} />
            <div style={{ padding: theme.spacing(0, 0.5) }}>
              <MenuListItem
                show
                list={[
                  (((canEdit && createdByCompany) || createdBy) && {
                    content: <CompanyFormModal label withText record={record} onChange={onChange} />,
                    available: true,
                  }) ||
                    null,
                  (((canEdit && createdByCompany) || createdBy) && {
                    content: <DeleteDialog withoutWrapper withText onOk={onDelete} v1 value={record.name} />,
                    available: true,
                  }) ||
                    null,
                  (((canEdit && createdByCompany) ||
                    createdBy ||
                    record.companiesParents?.includes(currentCompany._id)) && {
                    content: <CompanyEjectFromEnv onChange={onChange} label record={record} />,
                    available: true,
                  }) ||
                    null,
                ].filter((e) => e)}
              />
            </div>
            {expandContent}
          </Grid>
        ),
      },
    ];

    return (
      <Grid container style={{ padding: theme.spacing(0.5, 1) }} onClick={handleExpandClick}>
        <BaseListItemV1 v1 childs={childs} headerMobileSize={4} headerColumns={headerColumns} />
      </Grid>
    );
  }
);
