import { toDateOnly } from '../../../utils';
import { BaseUserAppTYpe } from '../../../stores';
import { Grid } from '@material-ui/core';
import { theme } from '../../../theme';
import MenuListItem from '../../MenuListItem';
import AddNoneWorkingTime from './AddNoneWorkingTime';
import AddOrder from './AddOrder';
import React from 'react';
import { inject } from 'mobx-react';
import { withCalendarStore } from '../store';
import moment from 'moment';
function DayOptions(_a) {
    var currentCompany = _a.store.accountStore.currentCompany, _b = _a.state, ramps = _b.ramps, workingDays = _b.workingDays, holidays = _b.holidays, warehouse = _b.warehouse, isPublic = _b.isPublic, day = _a.day;
    var currentDay = toDateOnly(day);
    var currentDayMoment = moment(toDateOnly(day));
    var currentWeekDay = currentDayMoment.format('dddd');
    var _holidays = holidays.data.filter(function (e) {
        return moment(toDateOnly(e.start)).isSameOrBefore(currentDayMoment) &&
            moment(toDateOnly(e.end)).isSameOrAfter(currentDayMoment) &&
            e.warehouse._id === (warehouse === null || warehouse === void 0 ? void 0 : warehouse._id);
    });
    var isAvailable = workingDays.data.find(function (e) { return e.day === currentWeekDay && e.maxTime && e.maxTime._id; }) &&
        !_holidays.length;
    var isNotPast = toDateOnly(currentDay) >= toDateOnly();
    if (isPublic) {
        return ((isNotPast && isAvailable && (React.createElement(Grid, { container: true, alignItems: 'center', style: { width: 'initial', marginRight: theme.spacing(1) } },
            React.createElement(MenuListItem, { show: true, list: [
                    {
                        content: React.createElement(AddOrder, { isPickup: true, day: day }),
                        available: ramps.count,
                    },
                    {
                        content: React.createElement(AddOrder, { day: day }),
                        available: ramps.count,
                    },
                ] })))) ||
            null);
    }
    return ((isNotPast &&
        [BaseUserAppTYpe.CUSTOMER, BaseUserAppTYpe.FORWARDER, BaseUserAppTYpe.WAREHOUSE].includes(currentCompany.type) &&
        isAvailable && (React.createElement(Grid, { container: true, alignItems: 'center', style: { width: 'initial', marginRight: theme.spacing(1) } },
        React.createElement(MenuListItem, { show: true, list: [
                {
                    content: React.createElement(AddNoneWorkingTime, { label: true, day: day, noneWorking: true }),
                    available: true,
                },
                {
                    content: React.createElement(AddOrder, { isPickup: true, day: day }),
                    available: ramps.count,
                },
                {
                    content: React.createElement(AddOrder, { day: day }),
                    available: ramps.count,
                },
            ].filter(function (e) { return e.available; }) })))) ||
        null);
}
export default inject('store')(withCalendarStore(DayOptions));
