var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Grid, IconButton } from '@material-ui/core';
import { BasicDialog, onPreventEvent } from '../../Dialog';
import { useTranslation } from 'react-i18next';
import { i18n, KEYS } from '../../../i18n';
import { observer, useLocalStore } from 'mobx-react';
import { toast } from 'react-toastify';
import { ACTION_CHANGE } from '../../BasicList';
import OrderRouteMenuItem from './OrderRoutesMenuItem';
import BaseTooltip from '../../BaseTooltip';
import { TextOverflow } from '../../TextOverflow';
import { UpdateOrder } from '../../../graphql';
import AdbIcon from '@material-ui/icons/Adb';
function OrderIsTest(_a) {
    var _this = this;
    var config = _a.config, order = _a.order, props = __rest(_a, ["config", "order"]);
    var formRef = React.useRef();
    var langOrderForm = useTranslation(KEYS.ORDER_FORM);
    var langToast = useTranslation(KEYS.TOAST);
    var langApp = useTranslation(KEYS.APP);
    var localStore = useLocalStore(function () { return ({
        error: '',
        isLoading: false,
        sent: false,
        onSubmit: function () {
            return __awaiter(this, void 0, void 0, function () {
                var res, _a, e_1;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            localStore.sent = false;
                            localStore.error = '';
                            localStore.isLoading = true;
                            _b.label = 1;
                        case 1:
                            _b.trys.push([1, 4, 5, 6]);
                            res = void 0;
                            _a = config;
                            if (!_a) return [3 /*break*/, 3];
                            return [4 /*yield*/, config.client.mutate({
                                    mutation: UpdateOrder,
                                    variables: {
                                        input: {
                                            isTest: !order.isTest,
                                            _id: order._id,
                                        },
                                    },
                                })];
                        case 2:
                            _a = (_b.sent());
                            _b.label = 3;
                        case 3:
                            res = (_a).data.updateOrder;
                            localStore.sent = true;
                            toast.success(langToast.t('toast_items_saved').replace(/_X_/, langApp.t('main_order')));
                            if (props.onChange)
                                props.onChange(order, ACTION_CHANGE.DELETED);
                            return [2 /*return*/, res];
                        case 4:
                            e_1 = _b.sent();
                            console.log('eeeee----', e_1);
                            toast.error(e_1.message);
                            return [3 /*break*/, 6];
                        case 5:
                            localStore.isLoading = false;
                            return [7 /*endfinally*/];
                        case 6: return [2 /*return*/];
                    }
                });
            });
        },
        clearError: function () {
            localStore.error = '';
        },
    }); });
    var onClose = function () {
        localStore.sent = false;
    };
    var onOk = function (e) { return __awaiter(_this, void 0, void 0, function () {
        var rejectedItem;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    onPreventEvent(e);
                    return [4 /*yield*/, localStore.onSubmit()];
                case 1:
                    rejectedItem = _a.sent();
                    if (!rejectedItem)
                        throw Error(i18n.getResource(i18n.language, KEYS.TOAST, 'toast_form_not_valid'));
                    if (props.onChange)
                        props.onChange(rejectedItem, ACTION_CHANGE.DELETED);
                    return [2 /*return*/];
            }
        });
    }); };
    var dialogBody = (React.createElement(TextOverflow, { color: "primary", style: { margin: 'auto' } }, !order.isTest ? 'Mark as test' : 'Un mark as test'));
    var title = langOrderForm.t('is_test');
    var dialogTitle = (React.createElement(Grid, { container: true, alignItems: 'center', justify: 'center' },
        React.createElement(TextOverflow, { variant: 'h6' }, title)));
    return ((config && (React.createElement(BasicDialog, { onOk: onOk, onClose: onClose, body: dialogBody, title: dialogTitle, dialogProps: { fullWidth: true, maxWidth: 'sm' }, withoutWrapper: true, bodyStyle: {
            flexDirection: 'column',
            justifyContent: 'center',
        }, actionInit: function (handleClickOpen) {
            var icon = (React.createElement(BaseTooltip, { title: title },
                React.createElement(IconButton, { onClick: handleClickOpen },
                    React.createElement(AdbIcon, { color: 'primary' }))));
            if (props.label)
                return React.createElement(OrderRouteMenuItem, __assign({}, { icon: icon, label: title, handleClickOpen: handleClickOpen }));
            return icon;
        } }))) ||
        null);
}
export default observer(OrderIsTest);
