var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React from 'react';
import { useLocalStore } from 'mobx-react';
import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { getSnapshot } from 'mobx-state-tree';
import { useStyles2 } from './styles';
import { BasicDialog, onPreventEvent } from '../../Dialog';
import { FormikAutocompleteAsyncAdd, FormikTextField } from '../../Formik';
import { formUserErrorMessage, onlySpacesRegExp } from '../../../common';
import { i18n, KEYS } from '../../../i18n';
import { TextOverflow } from '../../TextOverflow';
import DropDownItemActions from '../form/dialogs/DropDownItemActions';
import { MainButton } from '../../MainButton';
import { CreateOrderCertificate, DeleteOrderCertificate, FetchOrderCertificates, UpdateOrderCertificate, } from '../../../graphql/order/orderCertificate';
import { OrderCertificate } from '../../../models/OrderCertificate';
import BaseChip from '../../BaseChip';
export var BaseOrderCertificatesSelect = function (_a) {
    var autocompleteProps = _a.autocompleteProps, config = _a.config, order = _a.order, isButton = _a.isButton, props = __rest(_a, ["autocompleteProps", "config", "order", "isButton"]);
    var langForm = useTranslation(KEYS.FORM);
    var onChange = props.onChange;
    var classes = useStyles2();
    var formRef = React.useRef();
    var _b = __read(React.useState(JSON.parse(JSON.stringify(__assign(__assign({}, getSnapshot(OrderCertificate.create())), props.defaultValue)))), 2), _initialValue = _b[0], setInitialValue = _b[1];
    var addOrEdit = function (input) { return __awaiter(void 0, void 0, void 0, function () {
        var _id, res_1, data, data, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _id = input._id;
                    localStore.error = '';
                    localStore.isLoading = true;
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 7, 8, 9]);
                    if (!config) return [3 /*break*/, 6];
                    if (!_id) return [3 /*break*/, 3];
                    return [4 /*yield*/, config.client.mutate({
                            mutation: UpdateOrderCertificate,
                            variables: {
                                input: {
                                    name: input.name,
                                    description: input.description,
                                    _id: _id,
                                },
                            },
                        })];
                case 2:
                    res_1 = (_a.sent()).data.updateOrderCertificate;
                    data = config.client.readQuery({
                        query: FetchOrderCertificates,
                    });
                    config.client.writeQuery({
                        query: FetchOrderCertificates,
                        data: {
                            orderCertificates: {
                                count: data.orderCertificates.count,
                                data: data.orderCertificates.data.map(function (e) {
                                    if (e._id === res_1._id) {
                                        return res_1;
                                    }
                                    return e;
                                }),
                            },
                        },
                    });
                    return [3 /*break*/, 5];
                case 3: return [4 /*yield*/, config.client.mutate({
                        mutation: CreateOrderCertificate,
                        variables: {
                            input: __assign(__assign({}, input), { order: order && order._id }),
                        },
                    })];
                case 4:
                    res_1 = (_a.sent()).data.createOrderCertificate;
                    data = config.client.readQuery({
                        query: FetchOrderCertificates,
                    });
                    config.client.writeQuery({
                        query: FetchOrderCertificates,
                        data: {
                            orderCertificates: {
                                count: data.orderCertificates.count + 1,
                                data: __spread(data.orderCertificates.data, [res_1]),
                            },
                        },
                    });
                    _a.label = 5;
                case 5: return [2 /*return*/, res_1];
                case 6: return [3 /*break*/, 9];
                case 7:
                    error_1 = _a.sent();
                    console.log(error_1);
                    localStore.error = formUserErrorMessage(error_1);
                    return [3 /*break*/, 9];
                case 8:
                    localStore.isLoading = false;
                    return [7 /*endfinally*/];
                case 9: return [2 /*return*/];
            }
        });
    }); };
    var localStore = useLocalStore(function () { return ({
        error: '',
        isLoading: false,
        onSubmit: function (input) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    return [2 /*return*/, addOrEdit(input)];
                });
            });
        },
        clearError: function () {
            localStore.error = '';
        },
    }); });
    var onOk = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var createdItem;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    onPreventEvent(e);
                    return [4 /*yield*/, formRef.current.submitForm()];
                case 1:
                    createdItem = _a.sent();
                    if (!createdItem)
                        throw Error(i18n.getResource(i18n.language, KEYS.TOAST, 'toast_form_not_valid'));
                    if (onChange) {
                        onChange(createdItem.updatedAt
                            ? __spread((props.value || []).filter(function (e) { return e._id !== createdItem._id; }), [createdItem]) : __spread((props.value || []), [createdItem]));
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var _onChange = function (e, item) { return __awaiter(void 0, void 0, void 0, function () {
        var autocompletedItem, createdItem;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    autocompletedItem = item.find(function (e) { return e._id === 'autocompleted'; });
                    if (!autocompletedItem) return [3 /*break*/, 3];
                    if (!(autocompletedItem === null || autocompletedItem === void 0 ? void 0 : autocompletedItem.name)) return [3 /*break*/, 2];
                    return [4 /*yield*/, addOrEdit({ name: autocompletedItem.name })];
                case 1:
                    createdItem = _a.sent();
                    item.pop();
                    if (onChange && autocompleteProps && autocompleteProps.multiple)
                        onChange(__spread(item, [createdItem]));
                    _a.label = 2;
                case 2: return [3 /*break*/, 4];
                case 3:
                    ((autocompleteProps && autocompleteProps.multiple) || (item && item._id)) && onChange && onChange(item, e);
                    _a.label = 4;
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var initialValue = _initialValue || __assign({}, getSnapshot(OrderCertificate.create()));
    var dialogBody = (React.createElement(React.Fragment, null,
        React.createElement(Typography, { color: "error" }, localStore.error),
        React.createElement(Formik, { key: initialValue._id, innerRef: formRef, initialValues: initialValue, validationSchema: Yup.object({
                name: Yup.string()
                    .max(10, langForm.t('form_input_validate_max_char').replace(/_X_/, '10'))
                    .matches(onlySpacesRegExp, langForm.t('form_input_validate_invalid_text'))
                    .required(langForm.t('form_input_validate_required')),
                description: Yup.string()
                    .max(1000, langForm.t('form_input_validate_max_char').replace(/_X_/, '1000'))
                    .matches(onlySpacesRegExp, langForm.t('form_input_validate_invalid_text')),
            }), onSubmit: localStore.onSubmit }, function (formik) {
            return (React.createElement(Form, { className: classes.form, id: 'form-order-create-certificates-select' },
                React.createElement(Grid, { container: true, direction: "row", spacing: 2 },
                    React.createElement(Grid, { item: true, xs: 12, md: 12 },
                        React.createElement(FormikTextField, { title: langForm.t('form_input_name'), variant: "outlined", type: "text", name: "name", value: formik.values.name })),
                    React.createElement(Grid, { item: true, xs: 12, md: 12 },
                        React.createElement(FormikTextField, { title: langForm.t('form_input_description'), variant: "outlined", multiline: true, rows: 4, name: "description", value: formik.values.description })))));
        })));
    return ((config && (React.createElement(BasicDialog, { body: dialogBody, askCanClose: true, title: langForm.t('form_input_certificate_help'), onOk: onOk, dialogProps: { fullWidth: true, maxWidth: 'sm' }, actionInit: function (handleClickOpen) {
            return isButton ? (React.createElement(MainButton, { id: 'btn-add-certificate', onClick: handleClickOpen, title: langForm.t('form_btn_add') })) : (React.createElement(FormikAutocompleteAsyncAdd, __assign({}, props, { v7: true, v8: true, v17: true, canAddNew: true, canAddNewV3: true, onOpenDialog: function (e, _a) {
                    var item = _a.item;
                    return __awaiter(void 0, void 0, void 0, function () {
                        var createdItem;
                        return __generator(this, function (_b) {
                            switch (_b.label) {
                                case 0:
                                    if (!!item) return [3 /*break*/, 3];
                                    if (!(Object.keys(props.value).length !== 0 && !props.value._id)) return [3 /*break*/, 2];
                                    return [4 /*yield*/, addOrEdit({ name: props.value })];
                                case 1:
                                    createdItem = _b.sent();
                                    if (onChange)
                                        onChange(createdItem);
                                    _b.label = 2;
                                case 2: return [3 /*break*/, 4];
                                case 3:
                                    handleClickOpen(e);
                                    setInitialValue(item);
                                    _b.label = 4;
                                case 4: return [2 /*return*/];
                            }
                        });
                    });
                }, onChange: _onChange, title: langForm.t('form_input_certificate'), client: config.client, query: FetchOrderCertificates, addTitle: "" + langForm.t('form_btn_add'), autocompleteProps: __assign(__assign({}, autocompleteProps), { value: props.value, getOptionLabel: function (option) { return option && option.name; }, freeSolo: false, renderTags: function (value, getTagProps) {
                        var re = (Array.isArray(value) && value) || [];
                        return re.map(function (option, index) { return (React.createElement(BaseChip, __assign({ label: option.name }, getTagProps({ index: index })))); });
                    } }), onRemove: function () { return onChange && onChange(); }, removeQuery: DeleteOrderCertificate, deleteTitle: function (option) { return option.name; }, renderOption: function (option) { return (React.createElement(Grid, { container: true, alignItems: 'center', justify: 'space-between' },
                    React.createElement(TextOverflow, null, option.name),
                    React.createElement(DropDownItemActions, { option: option, canEdit: !option.approvedAt }))); } })));
        } }))) ||
        null);
};
