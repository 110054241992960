import React from 'react';
import { i18n, KEYS } from '../../../i18n';
import { copyLink } from '../../../utils';
import { inject } from 'mobx-react';
import { BaseUserAppTYpe } from '../../../stores';
import { TextOverflow } from '../../TextOverflow';
import { theme } from '../../../theme';
import { Button } from '@material-ui/core';
function MenuCopyCurrentLocation(_a) {
    var store = _a.store;
    var handleClickOpen = function () {
        var _a;
        copyLink([
            (_a = store.accountStore.env.find(function (e) { return e.name === BaseUserAppTYpe.CARRIER; })) === null || _a === void 0 ? void 0 : _a.value,
            'public',
            location.href.split(location.origin)[1].substr(1),
        ].join('/'));
    };
    return (React.createElement(Button, { onClick: handleClickOpen, color: 'primary', style: {
            textTransform: 'capitalize',
            paddingLeft: 0,
        } },
        React.createElement(TextOverflow, { style: { color: theme.palette.secondary['900'], textDecoration: 'underline' } }, i18n.getResource(i18n.language, KEYS.APP, 'app_copy_shareable_link'))));
}
export default inject('store')(MenuCopyCurrentLocation);
