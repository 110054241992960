var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React from 'react';
import { Grid, IconButton, useMediaQuery, useTheme } from '@material-ui/core';
import { ACTION_CHANGE, BaseListItem, DeleteDialog, FilePreview, onPreventEvent, TextOverflow, } from '../../../components';
import { DeleteUpload, UpdateUpload } from '../../../graphql';
import { toast } from 'react-toastify';
import { inject } from 'mobx-react';
import { getSnapshot } from 'mobx-state-tree';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../../i18n';
import { Formik } from 'formik';
import UploadType from '../dialogs/UploadType';
import DocumentItemVisibleTo from './DocumentItemVisibleTo';
import ResendNotification from './ResendNotification';
import { BaseUserAppTYpe } from '../../../stores/common';
import { USER_ROLE_TOOLS } from '../../../models/UserRoles';
import MenuListItem from '../../../components/MenuListItem';
import OrderRouteMenuItem from '../../../components/Order/List/OrderRoutesMenuItem';
import { SignDocument } from './SignDocument';
import RequestSign from '../../../pages/Quotes/DocumentSendNotificationToSign';
import { useIconStyles } from '../../../styles';
import { CMRIcon } from '../../../components/icons';
import { DocumentHistory } from './DocumentHistory';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import EditIcon from '@material-ui/icons/Edit';
import { UploadList } from '../../../components/Uploads/List';
import EmailStatusV1 from './EmailStatusV1';
import RequestDocument from './RequestDocument';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import OrderCMR from '../../../components/Order/List/OrderCMR';
import { checkIfOrderDetailsIsHide, langTranslateValue } from '../../../utils1';
import { OrderStatus } from '../../../models';
import UploadWithDragAndDrop from '../../../components/UploadWithDragAndDrop';
import { documentInitialValues } from '../../../components/Uploads/AddFormDialog';
import { wrapTextToId } from '../../../utils';
export default inject('store')(function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r;
    var quote = _a.quote, record = _a.record, onChange = _a.onChange, config = _a.config, store = _a.store, headerColumns = _a.headerColumns, props = __rest(_a, ["quote", "record", "onChange", "config", "store", "headerColumns"]);
    var _s = store.accountStore, currentUser = _s.currentUser, currentCompany = _s.currentCompany;
    var _t = __read(React.useState(record), 2), tempValue = _t[0], setTempValue = _t[1];
    var langApp = useTranslation(KEYS.APP);
    var langForm = useTranslation(KEYS.FORM);
    var langMenu = useTranslation(KEYS.MENU);
    var langToast = useTranslation(KEYS.TOAST);
    var langOfferForm = useTranslation(KEYS.OFFER_FORM);
    var langOrderList = useTranslation(KEYS.ORDER_LIST);
    var _u = __read(React.useState(JSON.parse(JSON.stringify(record))), 2), item = _u[0], setItem = _u[1];
    var fileInput = React.useRef();
    var filePreviewDialog = React.useRef();
    var theme = useTheme();
    var iconStyles = useIconStyles();
    var isMiddle = useMediaQuery(theme.breakpoints.down('sm'));
    var isPlaceholder = !record.path;
    var _onChange = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, name, value, _b, user, newItem, e_1;
        var _c, _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    _a = e.target, name = _a.name, value = _a.value;
                    if (record[name] === value) {
                        return [2 /*return*/, console.warn('no changes')];
                    }
                    _e.label = 1;
                case 1:
                    _e.trys.push([1, 4, , 5]);
                    _b = config;
                    if (!_b) return [3 /*break*/, 3];
                    return [4 /*yield*/, config.client.mutate({
                            mutation: UpdateUpload,
                            variables: {
                                input: (_c = {
                                        _id: item._id
                                    },
                                    _c[name] = name === 'type' ? value && value._id : value,
                                    _c),
                            },
                        })];
                case 2:
                    _b = (_e.sent());
                    _e.label = 3;
                case 3:
                    _b;
                    user = getSnapshot(currentUser);
                    if (user._id !== record.createdBy._id && name === 'access' && !user.appType.find(function (e) { return value.includes(e); })) {
                        onChange && onChange(record, ACTION_CHANGE.DELETED);
                    }
                    newItem = __assign(__assign({}, item), (_d = { updatedAt: new Date().toString() }, _d[name] = value, _d));
                    setItem(newItem);
                    Object.assign(record, newItem);
                    toast.success(langToast.t('toast_items_saved').replace(/_X_/, langApp.t('main_document')));
                    return [3 /*break*/, 5];
                case 4:
                    e_1 = _e.sent();
                    console.log(e_1);
                    toast.error(e_1.message);
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var onDelete = function () { return __awaiter(void 0, void 0, void 0, function () {
        var _a, e_2;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 3, , 4]);
                    _a = config;
                    if (!_a) return [3 /*break*/, 2];
                    return [4 /*yield*/, config.client.mutate({
                            mutation: DeleteUpload,
                            variables: {
                                _id: item._id,
                            },
                        })];
                case 1:
                    _a = (_b.sent());
                    _b.label = 2;
                case 2:
                    _a;
                    toast.success(langToast.t('toast_items_deleted').replace(/_X_/, langApp.t('main_document')));
                    onChange && onChange(record, ACTION_CHANGE.DELETED);
                    return [3 /*break*/, 4];
                case 3:
                    e_2 = _b.sent();
                    toast.error(e_2.message);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var canEditV1 = currentUser.rolesV1.find(function (e) { var _a; return (_a = e.tools.general) === null || _a === void 0 ? void 0 : _a.includes(USER_ROLE_TOOLS.GENERAL_can_edit_company_data); });
    var canEdit = currentUser._id && currentUser._id === item.createdBy._id && !isPlaceholder;
    var isOrderForwarder = !!quote.order.forwarders.find(function (e) { return ((e._id && e._id.toString()) || e.toString()) === currentUser._id.toString(); });
    var isOrderManager = ((_c = (_b = quote.order.manager) === null || _b === void 0 ? void 0 : _b._id) === null || _c === void 0 ? void 0 : _c.toString()) === currentUser._id.toString();
    var isWorkWithDocument = item.needSign && currentUser._id && config && quote && quote.createdBy && ((_d = quote.createdBy) === null || _d === void 0 ? void 0 : _d.company);
    var orderOfferManager = ((_f = (_e = quote.createdBy) === null || _e === void 0 ? void 0 : _e.company) === null || _f === void 0 ? void 0 : _f._id) === store.accountStore.currentCompany._id;
    var canSign = orderOfferManager || ((_g = quote.order) === null || _g === void 0 ? void 0 : _g.transportations.find(function (e) {
        return e.routes.find(function (e) { return e.email.map(function (e) { return e.toLowerCase(); }).includes(store.accountStore.currentUser.email); });
    }));
    var isCreatedByUserCompany = record.createdBy.company._id.toString() === store.accountStore.currentCompany._id.toString();
    var forwarders = ((_h = quote.order.forwarders) === null || _h === void 0 ? void 0 : _h.map(function (e) { return e.company._id.toString(); }).filter(function (e) { return e; })) || [];
    if (!forwarders.length)
        forwarders =
            ((_j = store.accountStore.currentUser.forwarders) === null || _j === void 0 ? void 0 : _j.map(function (e) { return e.company._id.toString(); }).filter(function (e) { return e; })) || [];
    var isCreatedByFoCompany = (forwarders.includes(record.createdBy.company._id.toString()) ||
        forwarders.includes(store.accountStore.currentCompany._id)) &&
        record.access.includes(store.accountStore.currentCompany.type);
    var signers = ['consignee', 'sender', 'carrier'];
    var hasOwnLog = item.logs.find(function (e) { return e.createdBy.company._id === store.accountStore.currentCompany._id; });
    var transportation = quote.order && quote.order.transportation && quote.order.transportation[0];
    var isHide = checkIfOrderDetailsIsHide(store, quote.order);
    var isCMR = ((_k = record.type) === null || _k === void 0 ? void 0 : _k.name) === 'CMR';
    var isInvoice = ((_l = record.type) === null || _l === void 0 ? void 0 : _l.name) === 'Transport invoice';
    var isForwarder = currentUser.appType.includes(BaseUserAppTYpe.FORWARDER); //|| currentUser.roles.includes(USER_ROLE.ADMIN);
    var isCustomer = currentUser.appType.includes(BaseUserAppTYpe.CUSTOMER); //|| currentUser.roles.includes(USER_ROLE.ADMIN);
    var isCarrier = currentUser.appType.includes(BaseUserAppTYpe.CARRIER); //|| currentUser.roles.includes(USER_ROLE.ADMIN);
    var isWarehouse = currentUser.appType.includes(BaseUserAppTYpe.WAREHOUSE); //|| currentUser.roles.includes(USER_ROLE.ADMIN);
    var isSenderConsignee = [BaseUserAppTYpe.SENDER, BaseUserAppTYpe.CONSIGNEE].find(function (t) {
        return currentUser.appType.includes(t);
    });
    var isCompaniesOrder = (((_m = quote.order) === null || _m === void 0 ? void 0 : _m.companies) || []).includes(currentCompany._id);
    var customerOrderAvailable = (isCustomer || ((isWarehouse || isSenderConsignee) && isCompaniesOrder)) &&
        [OrderStatus.ApprovedQuote, OrderStatus.Finished].includes((_o = quote.order) === null || _o === void 0 ? void 0 : _o.status);
    var headerSize = headerColumns ? 3 : 0;
    var senderConsigneeOrderAvailable = (isCustomer || isForwarder || isWarehouse || isSenderConsignee) &&
        isCompaniesOrder &&
        [OrderStatus.ApprovedQuote, OrderStatus.Finished].includes((_p = quote.order) === null || _p === void 0 ? void 0 : _p.status);
    return (React.createElement(BaseListItem, { record: record, key: item.updatedAt, "data-id": "file-record-" + (wrapTextToId((_q = record === null || record === void 0 ? void 0 : record.type) === null || _q === void 0 ? void 0 : _q.name) || 'type') + "-" + wrapTextToId(record._id) },
        React.createElement(FilePreview, { item: item, title: langOfferForm.t('offer_overview') + " " + (item.name || langApp.t('main_document').toLowerCase()), config: config, actionInit: function (onOpen) { return React.createElement("span", { ref: filePreviewDialog, onClick: onOpen }); } }),
        React.createElement("input", { "data-id": "file-select-" + (wrapTextToId((_r = record === null || record === void 0 ? void 0 : record.type) === null || _r === void 0 ? void 0 : _r.name) || 'type') + "-" + wrapTextToId(record._id), id: 'file-select-record', type: 'file', style: { position: 'absolute', left: "-100vw", zIndex: 99999999 }, ref: fileInput }),
        React.createElement(Grid, { container: true, justify: 'center', alignItems: 'center', spacing: 1, style: { padding: theme.spacing(1) }, onClick: function (e) {
                if (!isPlaceholder) {
                    filePreviewDialog.current.click();
                }
            } },
            React.createElement(Formik, { onSubmit: function (e) { return e; }, initialValues: item, key: item.updatedAt }, function (formik) {
                var _a, _b;
                return (React.createElement(React.Fragment, null,
                    isMiddle && (headerColumns === null || headerColumns === void 0 ? void 0 : headerColumns.length) && 0 < headerColumns.length && (React.createElement(Grid, { item: true, xs: headerSize || 3, container: true, alignItems: 'center' },
                        React.createElement(TextOverflow, { style: { fontWeight: 600 }, variant: 'caption' }, headerColumns[0].name))),
                    React.createElement(Grid, { item: true, xs: (12 - headerSize), md: 2 },
                        React.createElement(UploadType, { disabled: !canEdit, defaultValue: formik.values.type, value: formik.values.type, config: config, onChange: function (value) {
                                return _onChange({
                                    target: { name: 'type', value: value },
                                });
                            }, autocompleteProps: { disabled: !canEdit, defaultValue: formik.values.type } })),
                    isMiddle && (headerColumns === null || headerColumns === void 0 ? void 0 : headerColumns.length) && 1 < headerColumns.length && (React.createElement(Grid, { item: true, xs: headerSize || 3, container: true, alignItems: 'center' },
                        React.createElement(TextOverflow, { style: { fontWeight: 600 }, variant: 'caption' }, headerColumns[1].name))),
                    React.createElement(Grid, { item: true, xs: (12 - headerSize), md: 3, style: __assign({}, (!isMiddle && { paddingLeft: theme.spacing(2) })) },
                        React.createElement(UploadList, { folder: props.folder, parent: quote._id, record: item, config: config, quote: quote, fileInput: fileInput, defaultValue: tempValue, isPreparation: props.isPreparation, lastActiveStep: props.lastActiveStep, onChange: function (files) { return onChange && onChange(record, ACTION_CHANGE.SOFT_REFRESH); }, actionInit: function (handleClickOpen) { return (React.createElement(UploadWithDragAndDrop, { singleFileV1: true, allowToAccessSign: true, config: config, name: 'files', onShow: function () {
                                    handleClickOpen();
                                    setTempValue(__assign(__assign({}, record), { files: [] }));
                                }, value: isPlaceholder ? [] : [record], onChange: function (e) {
                                    var _a;
                                    var order = quote.order;
                                    var allAvailableUsers = documentInitialValues(order, currentUser, props).users;
                                    var list = [];
                                    order.transportations.forEach(function (e) {
                                        var item = e.routes
                                            .filter(function (e) {
                                            return e.origin.locationAdvanced.find(function (e) { return e.documentType && e.documentType._id; });
                                        })
                                            .map(function (e) {
                                            return e.origin.locationAdvanced.filter(function (e) { return e.documentType && e.documentType._id; });
                                        })
                                            .reduce(function (a, b) { return __spread(a, b); }, []);
                                        if (item && item.length) {
                                            list.push.apply(list, __spread(item));
                                        }
                                    });
                                    list = list.filter(function (e) {
                                        var _a, _b, _c;
                                        return ((_a = e.documentType) === null || _a === void 0 ? void 0 : _a._id) === record.type._id || ((_c = (_b = e.documentType) === null || _b === void 0 ? void 0 : _b.name) === null || _c === void 0 ? void 0 : _c.match(new RegExp(record.type.name, 'gi')));
                                    });
                                    if (list.length) {
                                        list = __spread(new Set(__spread(list.map(function (e) { return e.email; }).reduce(function (a, b) { return __spread(a, b); })).filter(function (e) { return e; })));
                                    }
                                    setTempValue(__assign(__assign({}, record), { files: (_a = e === null || e === void 0 ? void 0 : e.target) === null || _a === void 0 ? void 0 : _a.value, 
                                        // users: getUsersFromAccess(allAvailableUsers, record),
                                        emails: list }));
                                    handleClickOpen();
                                }, UploadProps: { _id: record._id, folder: "private/quotes/" + (record._id || 'create') + "/" }, noEmail: props.noEmail, item: props.quote, parent_collection_name: 'quotes', docType: 'Other', noUpload: true })); } })),
                    isMiddle && (headerColumns === null || headerColumns === void 0 ? void 0 : headerColumns.length) && 2 < headerColumns.length && (React.createElement(Grid, { item: true, xs: headerSize || 3, container: true, alignItems: 'center' },
                        React.createElement(TextOverflow, { style: { fontWeight: 600 }, variant: 'caption' }, headerColumns[2].name))),
                    React.createElement(Grid, { item: true, xs: (12 - headerSize), md: 2, lg: 3, container: true, alignItems: 'center' },
                        React.createElement(EmailStatusV1, { record: record, config: config, quote: quote })),
                    isMiddle && (headerColumns === null || headerColumns === void 0 ? void 0 : headerColumns.length) && 3 < headerColumns.length && (React.createElement(Grid, { item: true, xs: headerSize || 3, container: true, alignItems: 'center' },
                        React.createElement(TextOverflow, { style: { fontWeight: 600 }, variant: 'caption' }, headerColumns[3].name))),
                    React.createElement(Grid, { item: true, xs: (12 - headerSize), md: props.isPreparation ? 2 : 3, lg: 2, container: true, justify: isMiddle || props.isPreparation ? 'flex-start' : 'flex-end', onClick: onPreventEvent }, props.isPreparation ? (React.createElement(TextOverflow, null, langTranslateValue((_a = record === null || record === void 0 ? void 0 : record.orderPreparingStep) === null || _a === void 0 ? void 0 : _a.name, store.accountStore) ||
                        langForm.t('order_creation'))) : (isCreatedByUserCompany && !isPlaceholder) || isCreatedByFoCompany ? (React.createElement(DocumentItemVisibleTo, { v1: !isMiddle, v2: true, quote: quote, isEditable: canEdit, item: item, record: record, onChange: _onChange })) : null),
                    isMiddle && (headerColumns === null || headerColumns === void 0 ? void 0 : headerColumns.length) && 4 < headerColumns.length && (React.createElement(Grid, { item: true, xs: headerSize || 3, container: true, alignItems: 'center' },
                        React.createElement(TextOverflow, { style: { fontWeight: 600 }, variant: 'caption' }, headerColumns[4].name))),
                    React.createElement(Grid, { item: true, xs: (12 - headerSize), md: 2, container: true, alignItems: 'center', justify: isMiddle ? 'flex-start' : 'flex-end', onClick: onPreventEvent },
                        React.createElement(MenuListItem, { show: true, v2: true, list: [
                                {
                                    content: (React.createElement(OrderCMR, { order: quote.order, tranportation: transportation, config: config, label: langOrderList.t('in_progress_cmr'), onChange: function () { return onChange && onChange(record, ACTION_CHANGE.SOFT_REFRESH); } })),
                                    available: isPlaceholder &&
                                        isCMR &&
                                        ((!isHide && customerOrderAvailable) || senderConsigneeOrderAvailable),
                                },
                                {
                                    content: (React.createElement(RequestDocument, { item: item, config: config, quote: quote, store: store, defaultValue: null, actionInit: function (handleClickOpen) { return (React.createElement(OrderRouteMenuItem, __assign({}, {
                                            handleClickOpen: handleClickOpen,
                                            icon: (React.createElement(IconButton, { color: 'primary' },
                                                React.createElement(NoteAddIcon, null))),
                                            label: langMenu.t('menu_request_document_title'),
                                        }))); } })),
                                    available: isPlaceholder,
                                },
                                {
                                    content: React.createElement(ResendNotification, { item: item, config: config, quote: quote, label: true }),
                                    available: !isPlaceholder && !props.noEmail,
                                },
                                {
                                    content: function (e) { return (React.createElement(UploadList, { folder: props.folder, parent: quote._id, record: item, config: config, quote: quote, isPreparation: props.isPreparation, lastActiveStep: props.lastActiveStep, fileInput: fileInput, onChange: function (files) { return onChange && onChange(record, ACTION_CHANGE.SOFT_REFRESH); }, actionInit: function (handleClickOpen) { return (React.createElement(OrderRouteMenuItem, __assign({}, {
                                            handleClickOpen: handleClickOpen,
                                            icon: (React.createElement(IconButton, { color: 'primary' },
                                                React.createElement(EditIcon, null))),
                                            label: langForm.t('form_btn_edit'),
                                        }))); } })); },
                                    available: (((isInvoice && (isCarrier || isCreatedByUserCompany)) || !isInvoice) &&
                                        !props.noEmail &&
                                        isCreatedByUserCompany) ||
                                        isPlaceholder ||
                                        isOrderForwarder ||
                                        isOrderManager,
                                },
                                {
                                    content: (React.createElement(DeleteDialog, { disabled: !(canEdit || canEditV1), withoutWrapper: true, withText: true, value: item.name, onOk: onDelete })),
                                    available: !isPlaceholder,
                                },
                                {
                                    content: (React.createElement(SignDocument, { quote: quote, item: item, title: langOfferForm.t('offer_overview') + " " + (item.name || langApp.t('main_document').toLowerCase()), store: store, config: config })),
                                    available: isWorkWithDocument && canSign && !hasOwnLog,
                                },
                                {
                                    content: (React.createElement(DocumentHistory, { quote: quote, item: item, store: store, config: config, actionInit: function (handleClickOpen) { return (React.createElement(OrderRouteMenuItem, __assign({ style: { width: '100%' } }, {
                                            handleClickOpen: handleClickOpen,
                                            icon: (React.createElement(IconButton, { onClick: handleClickOpen },
                                                React.createElement(AssignmentTurnedInIcon, null))),
                                            label: langApp.t('signed_documents'),
                                        }))); } })),
                                    available: !isPlaceholder && isWorkWithDocument && canSign && hasOwnLog,
                                },
                                {
                                    content: (React.createElement(RequestSign, { item: item, config: config, actionInit: function (handleClickOpen) { return (React.createElement(OrderRouteMenuItem, __assign({}, {
                                            handleClickOpen: handleClickOpen,
                                            icon: (React.createElement(IconButton, { color: 'primary' },
                                                React.createElement(CMRIcon, { className: iconStyles.iconCMR }))),
                                            label: langApp.t('request_sign'),
                                        }))); } })),
                                    available: isWorkWithDocument && ((_b = quote.order) === null || _b === void 0 ? void 0 : _b.transportations.find(function (e) { return (e.endTime && e.endTime[0]) || (e.startDate && e.startDate[0]); })) &&
                                        item.logs.filter(function (e) { return signers.includes(JSON.parse(e.changes).user_access); }).length <
                                            signers.length &&
                                        (!canSign || hasOwnLog),
                                },
                            ] }))));
            }))));
});
