var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React from 'react';
import clsx from 'clsx';
import { Box, createStyles, Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { TextOverflow } from '../TextOverflow';
import { makeStyles } from '@material-ui/core/styles';
import { dateViewFormat } from '../DateView';
import { OrderQuoteStatus, OrderStatus } from '../../models';
import { CurrencyFormat, PriceComponent } from '../PriceComponent';
import { AcceptDialog } from '../Order/List/Customer/OrderQuoteItem/Quotes/AcceptDialog';
import { OrderQuoteEditModal } from '../Order/List/OrderQuoteItem/Quotes/OrderQuoteEditModal';
import { DeleteDialog } from '../Dialog';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../i18n';
import { useLocalStore } from 'mobx-react-lite';
import { DeleteOrderQuote, UpdateOrderQuote } from '../../graphql/order';
import { toast } from 'react-toastify';
import { BaseUserAppTYpe } from '../../stores/common';
import { inject, observer } from 'mobx-react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { FormikTextField } from '../Formik';
import { HelpIconComponent } from '../HelpIcon';
import SectionOrderBookingChat from './SectionOrderBookingChat';
import { theme } from '../../theme';
import { AvatarItemView } from '../Account/Avatar';
import { withOrderListItemStore } from '../Order/List/Customer/storeListItem';
import TimeLeft from '../TimeLeft';
import PreloadingComponent from '../PreloadingComponent';
import { getTextWidth, wrapTextToId } from '../../utils';
import MenuListItem from '../MenuListItem';
import BaseTooltip from '../BaseTooltip';
import { MainButton } from '../MainButton';
var OrderContraQuoteAccept = React.lazy(function () { return import('../Order/List/OrderQuoteItem/Quotes/OrderContraQuoteAccept'); });
var SectionHistory = React.lazy(function () { return import('./SectionHistory'); });
export var useSectionRowStyles = makeStyles(function (theme) {
    return createStyles({
        root: {
            borderBottom: "1px solid " + theme.palette.grey['300'],
            minHeight: 31,
            color: theme.palette.primary.main,
        },
        textFieldV1: {
            '& .MuiInputBase-root': {
                '& div': {
                    flexWrap: 'initial',
                },
            },
            '& input': {
                width: 'calc(100% - 10px)',
            },
        },
        textField: {
            margin: 0,
            '& .MuiOutlinedInput-adornedEnd': {
                paddingRight: 2,
            },
            '& input': {
                padding: theme.spacing(0.5, 0.1, 0.5, 1) + "!important",
                margin: 0,
            },
        },
    });
});
function QuoteStatus(_a) {
    var children = _a.children;
    return (React.createElement(Grid, { container: true, justify: 'flex-end', alignItems: 'center', style: { marginRight: theme.spacing(1) } },
        React.createElement(TextOverflow, { style: { fontSize: '0.65rem' } }, children)));
}
function AdditionalRquirementsSectionRow(props) {
    var name = props.name, value = props.value, additional = props.additional, nameStyle = props.nameStyle;
    var className = useSectionRowStyles();
    return (React.createElement(Grid, { container: true, alignItems: 'center', className: className.root },
        React.createElement(Grid, { item: true, xs: 12, container: true, alignItems: 'center', style: {
                width: 'initial',
            } },
            React.createElement(Typography, { variant: 'caption', style: nameStyle }, name),
            additional),
        React.createElement(Grid, { item: true, xs: 12, style: {
                display: 'grid',
            } },
            React.createElement(Typography, { variant: 'caption', style: { textAlign: 'left' } }, value))));
}
function CargoSectionRow(_a) {
    var name = _a.name, value = _a.value, sizeName = _a.sizeName, sizeValue = _a.sizeValue, nameStyle = _a.nameStyle, isSmall = _a.isSmall;
    var className = useSectionRowStyles();
    return (React.createElement(Grid, { container: true, alignItems: 'center', className: className.root },
        React.createElement(Grid, { item: true, xs: 12, alignItems: 'flex-start', md: sizeName || 6, style: {
                display: 'grid',
                height: isSmall ? 'fit-content' : '100%',
                paddingTop: '5px',
            } },
            React.createElement(TextOverflow, { variant: 'caption', style: nameStyle }, name)),
        React.createElement(Grid, { item: true, xs: 12, md: sizeValue || 6, alignItems: 'flex-start', style: {
                display: 'grid',
                paddingTop: '4px',
            } },
            React.createElement(Typography, { variant: 'caption', style: { textAlign: 'left', lineHeight: 2.2 } }, value))));
}
export default function SectionRow(p) {
    var _a;
    var name = p.name, value = p.value, sizeName = p.sizeName, sizeValue = p.sizeValue, v1 = p.v1, props = __rest(p, ["name", "value", "sizeName", "sizeValue", "v1"]);
    var className = useSectionRowStyles();
    var theme1 = useTheme();
    var isSmall = useMediaQuery(theme1.breakpoints.down('sm'));
    var style = '400 12px Montserrat';
    var nameStyle = { fontWeight: 700, display: 'block' };
    if (props.isAdditionalRquirements && props.parentWidth * 0.5 < (getTextWidth(value, style) || 0)) {
        return React.createElement(AdditionalRquirementsSectionRow, __assign({}, p, { nameStyle: nameStyle }));
    }
    var part = sizeValue ? sizeValue / 12 : 0.5;
    if (props.isCargo && props.parentWidth * part < (getTextWidth(value, style) || 0)) {
        return React.createElement(CargoSectionRow, __assign({}, p, { nameStyle: nameStyle, isSmall: isSmall }));
    }
    return (React.createElement(Grid, { container: true, alignItems: 'center', className: clsx((_a = {}, _a[className.root] = !v1, _a)), id: "row-" + (typeof name === 'string' && wrapTextToId(name)) },
        React.createElement(Grid, { item: true, xs: 12, md: sizeName || 6, container: true, alignItems: 'center', style: {
                width: 'initial',
            } },
            React.createElement(TextOverflow, { variant: 'caption', style: nameStyle }, name),
            p.additional),
        React.createElement(Grid, { item: true, xs: 12, md: sizeValue || 6, style: {
                display: 'grid',
            } },
            React.createElement(TextOverflow, { variant: 'caption', style: { textAlign: isSmall ? 'left' : 'right' } }, value))));
}
export var SectionRowQuoteSubmitted = inject('store')(observer(withOrderListItemStore(function (props) {
    var _a;
    var _this = this;
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v;
    var order = props.order, record = props.record, v1 = props.v1, _w = props.store.accountStore, currentUser = _w.currentUser, currentCompany = _w.currentCompany, orderListItemProps = props.orderListItemProps;
    var className = useSectionRowStyles();
    var langOfferForm = useTranslation(KEYS.OFFER_FORM);
    var langToast = useTranslation(KEYS.TOAST);
    var langForm = useTranslation(KEYS.FORM);
    var langApp = useTranslation(KEYS.APP);
    var formRef = React.useRef();
    var openOrders = [OrderStatus.WaitingQuotes, OrderStatus.HasQuotes, OrderStatus.AcceptedQuote].includes(order.status);
    var localStore = useLocalStore(function () { return ({
        defaultValue: __assign({}, record),
        error: '',
        isLoading: false,
        onUpdated: function (record) {
            var e = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                e[_i - 1] = arguments[_i];
            }
            Object.assign(localStore.defaultValue, record, { updatedAt: new Date().toString() });
            if (props.onChange)
                props.onChange.apply(props, __spread([record], e));
        },
        onChangeOffer: function (e) {
            return __awaiter(this, void 0, void 0, function () {
                var _a, name, value, hasChanges, res, _b, e_1;
                var _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0:
                            _a = e.target, name = _a.name, value = _a.value;
                            if (record[name] === value) {
                                return [2 /*return*/, console.warn('No changes!')];
                            }
                            return [4 /*yield*/, formRef.current.setFieldValue(name, value)];
                        case 1:
                            _d.sent();
                            if (formRef.current.errors[name])
                                return [2 /*return*/, formRef.current.setFieldTouched(name)];
                            hasChanges = ['forwarderAmount', 'amount'].includes(name);
                            if (hasChanges) {
                                if ((localStore.defaultValue[name] || '').toString() === (value || '').toString()) {
                                    console.warn('no changes');
                                    return [2 /*return*/];
                                }
                            }
                            _d.label = 2;
                        case 2:
                            _d.trys.push([2, 5, , 6]);
                            _b = props.config;
                            if (!_b) return [3 /*break*/, 4];
                            return [4 /*yield*/, props.config.client.mutate({
                                    mutation: UpdateOrderQuote,
                                    variables: {
                                        input: (_c = {
                                                _id: record._id
                                            },
                                            _c[name] = (hasChanges && parseFloat(value)) || value,
                                            _c),
                                    },
                                })];
                        case 3:
                            _b = (_d.sent());
                            _d.label = 4;
                        case 4:
                            res = _b;
                            Object.assign(localStore.defaultValue, res.data.updateOrderQuote);
                            toast.success((langToast.t('toast_items_updated') || '').replace(/_X_/, langOfferForm.t('offer_title').toLowerCase()));
                            return [3 /*break*/, 6];
                        case 5:
                            e_1 = _d.sent();
                            console.log(e_1);
                            toast.error(e_1.message);
                            return [3 /*break*/, 6];
                        case 6: return [2 /*return*/];
                    }
                });
            });
        },
        clearError: function () {
            localStore.error = '';
        },
    }); });
    var onDelete = function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, (props.config &&
                        props.config.client.query({
                            query: DeleteOrderQuote,
                            variables: {
                                _id: record._id,
                            },
                        }))];
                case 1:
                    _a.sent();
                    if (orderListItemProps)
                        orderListItemProps.onChange({
                            record: __assign(__assign(__assign(__assign({}, orderListItemProps.state.record), { updatedAt: new Date().toString() }), (orderListItemProps.state.record.totalQuotes
                                ? { totalQuotes: (orderListItemProps.state.record.totalQuotes || 0) - 1 }
                                : {})), { latestQuote: null }),
                        });
                    // if (props.onChange) props.onChange(record, ACTION_CHANGE.DELETED);
                    order.latestQuote = null;
                    return [2 /*return*/];
            }
        });
    }); };
    var isOWN = currentUser._id === (record.createdBy && record.createdBy._id);
    var isOWNCompany = currentCompany._id === ((_b = record.createdBy) === null || _b === void 0 ? void 0 : _b.company._id);
    var is_forwarder = currentUser.appType.includes(BaseUserAppTYpe.FORWARDER);
    var is_carrier = currentUser.appType.includes(BaseUserAppTYpe.CARRIER);
    var is_customer = currentUser.appType.includes(BaseUserAppTYpe.CUSTOMER);
    var isAdmin = window.location.href.match('localhost:3002') || window.location.href.match('admin');
    var isForwarderCarrier = is_forwarder && (isOWN || isOWNCompany);
    var isOrderForwarder = is_forwarder &&
        record.order.forwarders
            .map(function (e) { return e.company && e.company._id; })
            .filter(function (e) { return e; })
            .includes(currentCompany._id);
    var canManage = currentUser.appType.includes(BaseUserAppTYpe.CUSTOMER) ||
        (!isOWN && currentUser.appType.includes(BaseUserAppTYpe.FORWARDER));
    var theme1 = useTheme();
    var isSmall = useMediaQuery(theme1.breakpoints.down('sm'));
    var textProps = {};
    if ([OrderQuoteStatus.Approved, OrderQuoteStatus.Finished].includes(record.status) &&
        record.approvedAt &&
        !record.canceledAt) {
        Object.assign(textProps, { style: { color: theme.palette.success.light } });
    }
    var disabled = is_forwarder &&
        record.order.forwarders &&
        !record.order.forwarders
            .map(function (e) { return e.company && e.company._id; })
            .filter(function (e) { return e; })
            .includes(currentCompany._id) &&
        ![record.createdBy.company._id].includes(currentCompany._id);
    var isNotFor = !isForwarderCarrier && record.status === OrderQuoteStatus.Requested;
    var isNotForCarrier = !isForwarderCarrier && [OrderQuoteStatus.Requested, OrderQuoteStatus.Accepted].includes(record.status);
    var isForV1 = isForwarderCarrier && [OrderQuoteStatus.Accepted, OrderQuoteStatus.Requested].includes(record.status);
    var statusComponent = ((is_carrier || isForwarderCarrier) &&
        !((_c = record.parent) === null || _c === void 0 ? void 0 : _c._id) &&
        record.status === OrderQuoteStatus.Requested &&
        !record.deletedAt &&
        !isAdmin && (React.createElement(React.Fragment, null,
        React.createElement(QuoteStatus, null, langOfferForm.t('offer_form_waiting_customer_status')),
        (isNotForCarrier || isForV1) && !is_customer ? (React.createElement(OrderQuoteEditModal, { order: order, onChange: localStore.onUpdated, config: props.config, defaultValue: record, canManage: canManage, label: true, actionInit: function (handleClickOpen) { return (React.createElement(MainButton, { disabled: props.disabled, onClick: handleClickOpen, title: langOfferForm.t('offer_form_edit_btn'), disabledStyle: { pointerEvents: 'none' }, style: __assign({ height: 25, backgroundColor: theme.palette.warning.main }, ((!props.disabled && {
                    padding: theme.spacing(0),
                    color: theme.palette.primary.main,
                }) || {
                    padding: theme.spacing(0),
                })) })); } })) : null))) ||
        ((is_customer || is_forwarder) &&
            !isForwarderCarrier &&
            record.status === OrderQuoteStatus.Requested &&
            !record.deletedAt &&
            !isAdmin &&
            ((((_d = record.parent) === null || _d === void 0 ? void 0 : _d._id) && (React.createElement(QuoteStatus, null, langOfferForm.t('offer_form_waiting_carrier_status')))) || React.createElement("span", null))) ||
        ((is_carrier || isForwarderCarrier || is_customer || is_forwarder) &&
            (record.deletedAt || record.rejectedAt) &&
            !isAdmin && (React.createElement(QuoteStatus, null, record.deletedAt ? langApp.t('app_deleted') : langApp.t('main_expired')))) ||
        null;
    return (React.createElement(Grid, { id: "offer-record-" + record._id, container: true, alignItems: 'center', className: clsx((_a = {}, _a[className.root] = !v1, _a)), style: { minHeight: 34 }, key: "" + localStore.defaultValue.updatedAt },
        React.createElement(Grid, { item: true, xs: 12, md: openOrders ? 2 : 4 },
            React.createElement(TextOverflow, __assign({ variant: 'subtitle1' }, textProps), [
                [
                    record.tempTransportations &&
                        record.tempTransportations[0] &&
                        dateViewFormat(record.tempTransportations[0].startDate),
                    ([OrderQuoteStatus.Approved, OrderQuoteStatus.Finished].includes(record.status) &&
                        order.transportations &&
                        order.transportations[0] &&
                        order.transportations[0].startTime[0]) ||
                        '',
                ].join(' '),
                [
                    record.tempTransportations &&
                        record.tempTransportations[record.tempTransportations.length - 1] &&
                        dateViewFormat(record.tempTransportations[record.tempTransportations.length - 1].endDate),
                    ([OrderQuoteStatus.Approved, OrderQuoteStatus.Finished].includes(record.status) &&
                        order.transportations &&
                        order.transportations[order.transportations.length - 1] &&
                        order.transportations[order.transportations.length - 1].endTime[0]) ||
                        '',
                ].join(' '),
            ].join(' - '))),
        React.createElement(Grid, { item: true, xs: 12, md: 1 },
            React.createElement(AvatarItemView, { moreDetail: true, withoutRole: true, withRating: true, avatarStyle: { width: 26, height: 26 }, typographyProps: {
                    variant: 'h6',
                    style: {
                        fontSize: 14,
                        fontWeight: 600,
                        color: theme.palette.common.white,
                    },
                }, user: record.createdBy })),
        React.createElement(Grid, { item: true, xs: 12, md: openOrders ? 9 : 7, container: true, justify: isSmall ? 'flex-start' : 'space-between', alignItems: 'center' },
            React.createElement(Grid, { xs: 12, md: 6, container: true, item: true, alignItems: 'center', style: __assign(__assign({ maxWidth: isSmall ? 'none' : "calc(100% - " + (isAdmin ? 48 : openOrders ? 300 : 120) + "px)" }, (!(is_forwarder && ((_e = order === null || order === void 0 ? void 0 : order.forwarders) === null || _e === void 0 ? void 0 : _e.length)) && {
                    paddingRight: theme.spacing(1),
                })), (isSmall && { marginBottom: theme.spacing(1) })) },
                !isAdmin ? (React.createElement(Grid, { item: true, xs: 12, md: (is_forwarder && ((_f = order === null || order === void 0 ? void 0 : order.forwarders) === null || _f === void 0 ? void 0 : _f.length) && 7) || 12, style: __assign({}, (is_forwarder && ((_g = order === null || order === void 0 ? void 0 : order.forwarders) === null || _g === void 0 ? void 0 : _g.length) && { paddingRight: theme.spacing(0.5) })), container: true, justify: is_forwarder && ((_h = order === null || order === void 0 ? void 0 : order.forwarders) === null || _h === void 0 ? void 0 : _h.length) && !isSmall ? 'flex-end' : 'flex-start' },
                    React.createElement("div", { style: __assign({ position: 'relative', display: 'flex', maxWidth: '100%' }, (openOrders && { width: '100%', justifyContent: 'space-between' })) },
                        openOrders ? (React.createElement(Box, { style: {
                                display: 'flex',
                                height: '100%',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                width: '70px',
                            } }, record.expireEnd && !record.deletedAt && (React.createElement(TimeLeft, { date: record.expireEnd, style: { marginRight: theme.spacing(1) } })))) : null,
                        React.createElement(PriceComponent, { id: 'offer-amount', amount: record.amount, currency: record.currency, style: __assign(__assign(__assign(__assign({ color: theme.palette.success.light }, (record.contraOffer && {
                                color: theme.palette.grey['500'],
                            })), (((_j = record.parent) === null || _j === void 0 ? void 0 : _j._id) && {
                                color: theme.palette.primary.light,
                            })), ((record.status === OrderQuoteStatus.Approved && { color: theme.palette.success.light }) ||
                                ((record.rejectedAt || record.deletedAt || record.status === OrderQuoteStatus.Cancelled) && {
                                    color: theme.palette.grey['500'],
                                }))), (is_forwarder && ((_k = order === null || order === void 0 ? void 0 : order.forwarders) === null || _k === void 0 ? void 0 : _k.length) && !isSmall && { marginRight: theme.spacing(1) })), hide: [BaseUserAppTYpe.WAREHOUSE, BaseUserAppTYpe.SENDER, BaseUserAppTYpe.CONSIGNEE].find(function (t) {
                                return currentUser.appType.includes(t);
                            }) })))) : null,
                React.createElement(Grid, { id: 'comeId', item: true, xs: 12, md: isAdmin ? 12 : 5, style: { paddingRight: isSmall ? 0 : theme.spacing(1) } },
                    React.createElement(Formik, { innerRef: formRef, initialValues: localStore.defaultValue, validationSchema: Yup.object({
                            amount: Yup.number()
                                .max(100000, langForm.t('form_input_validate_max_size').replace(/_X_/, '100000'))
                                .min(1, langForm.t('form_input_validate_min_size').replace(/_X_/, '1'))
                                .required(langForm.t('form_input_validate_required')),
                            forwarderAmount: Yup.number()
                                .max(100000, langForm.t('form_input_validate_max_size').replace(/_X_/, '100000'))
                                .min(record.amount || 1, langForm.t('form_input_validate_min_size').replace(/_X_/, ((_l = record.amount) === null || _l === void 0 ? void 0 : _l.toString()) || '1'))
                                .required(langForm.t('form_input_validate_required')),
                        }), onSubmit: function (e) { return e; } }, function (formik) {
                        var _a;
                        return (React.createElement(Form, { style: { display: 'flex', gap: theme.spacing(2) }, id: 'form-order-offer-edit' },
                            isAdmin ? (React.createElement(Grid, { container: true, direction: "column" },
                                React.createElement(FormikTextField, { v14: true, className: clsx(className.textField, className.textFieldV1), disabled: isAdmin
                                        ? record.deletedAt
                                        : record.status !== OrderQuoteStatus.Requested || disabled || record.deletedAt, type: 'number', placeholder: langForm.t('form_input_name_price'), variant: "outlined", name: "amount", value: formik.values.amount, onBlur: localStore.onChangeOffer, InputProps: {
                                        endAdornment: (React.createElement(Grid, { container: true, alignItems: 'center', style: { width: 64 } },
                                            React.createElement(TextOverflow, { color: 'primary' }, CurrencyFormat(record.currency)),
                                            React.createElement(HelpIconComponent, { title: langOfferForm.t('offer_form_carrier_price') }))),
                                    } }))) : null,
                            (is_forwarder && ((_a = order === null || order === void 0 ? void 0 : order.forwarders) === null || _a === void 0 ? void 0 : _a.length) &&
                                order.forwarders.filter(function (forwarder) { var _a; return ((_a = forwarder.company) === null || _a === void 0 ? void 0 : _a._id) === currentCompany._id; })
                                    .length) ||
                                (isAdmin && localStore.defaultValue.forwarderAmount) ? (React.createElement(Grid, { container: true, direction: "column" },
                                React.createElement(FormikTextField, { v14: true, className: clsx(className.textField, className.textFieldV1), disabled: isAdmin
                                        ? record.deletedAt
                                        : record.status !== OrderQuoteStatus.Requested || disabled || record.deletedAt, type: 'number', placeholder: isAdmin
                                        ? langOfferForm.t('offer_form_price_with_percents')
                                        : langForm.t('form_input_name_price'), variant: "outlined", name: "forwarderAmount", value: formik.values.forwarderAmount, onBlur: localStore.onChangeOffer, InputProps: {
                                        endAdornment: (React.createElement(Grid, { container: true, alignItems: 'center', style: { width: 64 } },
                                            React.createElement(TextOverflow, { color: 'primary' }, CurrencyFormat(record.currency)),
                                            React.createElement(HelpIconComponent, { title: isAdmin
                                                    ? langOfferForm.t('order_form_forwarder_price')
                                                    : langForm.t('form_input_forwarder_price_help') }))),
                                    } }))) : null));
                    }))),
            React.createElement(Grid, { xs: 12, md: 6, justify: 'flex-end', style: __assign({ display: 'flex', flexDirection: 'row' }, (isSmall && { width: '100%', marginBottom: theme.spacing(1) })) },
                React.createElement(Box, { display: 'flex', alignItems: 'center', justifyContent: 'flex-end' },
                    (is_carrier || isForwarderCarrier) && ((_m = record.parent) === null || _m === void 0 ? void 0 : _m._id) &&
                        record.status === OrderQuoteStatus.Requested &&
                        !record.deletedAt &&
                        !isAdmin && (React.createElement(PreloadingComponent, null,
                        React.createElement(OrderContraQuoteAccept, __assign({}, props, { onChange: props.onChange, quote: record, disabled: disabled || isAdmin })))),
                    (is_carrier || isForwarderCarrier) && ((_o = record.parent) === null || _o === void 0 ? void 0 : _o._id) &&
                        record.status === OrderQuoteStatus.Requested &&
                        !record.deletedAt &&
                        !isAdmin && (React.createElement(PreloadingComponent, null,
                        React.createElement(OrderContraQuoteAccept, __assign({}, props, { isDeny: true, onChange: props.onChange, quote: record, disabled: disabled || isAdmin })))),
                    statusComponent,
                    (is_forwarder && isOWNCompany && ((_p = order.forwarderOrder) === null || _p === void 0 ? void 0 : _p.length) === 0) ||
                        isAdmin ||
                        record.deletedAt || ((_q = record.parent) === null || _q === void 0 ? void 0 : _q._id)
                        ? null
                        : (((isNotFor && canManage) || isForV1) && !isForwarderCarrier && (React.createElement(AcceptDialog, __assign({}, props, { disabled: disabled })))) ||
                            null,
                    ((isNotForCarrier || isForV1) &&
                        !isAdmin &&
                        (is_customer || (isOrderForwarder && !isForwarderCarrier)) &&
                        !((_r = record.parent) === null || _r === void 0 ? void 0 : _r._id) &&
                        !record.deletedAt &&
                        !((is_customer || is_forwarder) &&
                            (record.contraOffer || record.status !== OrderQuoteStatus.Requested)) && (React.createElement(OrderQuoteEditModal, { order: order, onChange: localStore.onUpdated, config: props.config, defaultValue: record, canManage: canManage, isLowerModal: true, actionInit: function (handleClickOpen) { return (React.createElement(BaseTooltip, { title: langOfferForm.t('offer_form_request_lower_price') },
                            React.createElement(Box, null,
                                React.createElement(MainButton, { disabled: props.disabled, onClick: handleClickOpen, title: langOfferForm.t('offer_form_lower_price'), disabledStyle: { pointerEvents: 'none' }, style: {
                                        height: 25,
                                        marginLeft: theme.spacing(1),
                                        padding: theme.spacing(0, 1),
                                    } })))); } }))) ||
                        null,
                    React.createElement("div", { style: { marginRight: theme.spacing(1) } }),
                    (((_t = (_s = record.createdBy) === null || _s === void 0 ? void 0 : _s.company) === null || _t === void 0 ? void 0 : _t._id) !== order.company._id && (React.createElement(React.Fragment, null,
                        React.createElement(SectionOrderBookingChat, { record: record, defaultValue: (_u = record.lastChatMessage) === null || _u === void 0 ? void 0 : _u.chat, config: props.config, order: order, quote: record, isLoading: true }),
                        React.createElement("div", { style: { marginRight: theme.spacing(0.5) } })))) ||
                        null,
                    React.createElement(MenuListItem, { v1: true, show: true, list: [
                            // {
                            //   content: (
                            //
                            //   ),
                            //   available: record.createdBy?.company?._id !== order.company._id,
                            // },
                            {
                                id: 'btn-offer-history',
                                content: (React.createElement(PreloadingComponent, null,
                                    React.createElement(SectionHistory, { v2: true, config: props.config, onChange: props.onChange, filters: {
                                            fields: [
                                                { name: 'object', value: record._id },
                                                { name: 'type', value: 2 },
                                            ],
                                        }, record: record, order: order }))),
                                available: true,
                            },
                            {
                                id: 'btn-offer-history',
                                content: (React.createElement(DeleteDialog, { v1: true, withoutWrapper: true, withText: true, disabled: !isOWN, onOk: onDelete, value: (langOfferForm.t('offer_title') || '').toLowerCase() })),
                                available: !((_v = record.parent) === null || _v === void 0 ? void 0 : _v._id) &&
                                    ((isNotForCarrier && !canManage) || isForV1) &&
                                    !isAdmin &&
                                    !record.deletedAt,
                            },
                        ] }))))));
})));
