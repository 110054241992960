import { createStyles, makeStyles } from '@material-ui/core/styles';
import { BaseUserAppTYpe, USER_ROLE } from '../../stores';
import { NOTIFICATION_ACTIONS } from '../../notifications';
export var useSettingsStyles = makeStyles(function (theme) {
    var _a, _b, _c, _d;
    return createStyles({
        paper: (_a = {
                maxWidth: '100%',
                width: '66%'
            },
            _a[theme.breakpoints.down('sm')] = {
                width: '100%',
            },
            _a),
        root: {
            width: '100%',
            backgroundColor: theme.palette.background.paper,
        },
        rootV2: (_b = {},
            _b[theme.breakpoints.up('sm')] = {
                maxWidth: 1170,
            },
            _b[theme.breakpoints.down('xs')] = {
                width: '100%',
            },
            _b),
        rootV1: {
            width: '100%',
            backgroundColor: theme.palette.background.paper,
        },
        list: {
            width: 250,
        },
        fullList: {
            width: 'auto',
        },
        tabs: (_c = {},
            _c[theme.breakpoints.up('sm')] = {
                maxWidth: 'calc(100% - 48px)',
            },
            _c.maxHeight = '30vh',
            _c.minHeight = '60px',
            _c['& .MuiTab-root'] = {
                color: theme.palette.success.light,
                borderBottom: "1px solid " + theme.palette.secondary.main,
                borderRight: "1px solid " + theme.palette.secondary.main,
                height: '60px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            },
            _c['& .MuiTab-wrapper'] = {
                alignItems: 'flex-start !important',
            },
            _c),
        tab: (_d = {
                '&.MuiTab-wrapper.MuiTypography-root': {
                    color: theme.palette.primary.main,
                }
            },
            _d[theme.breakpoints.down('sm')] = {
                maxWidth: "100%",
            },
            _d),
        button: {
            position: 'fixed',
            right: 0,
            top: 120,
            zIndex: 1299,
            backgroundColor: theme.palette.primary.light,
            borderBottomLeftRadius: '50%',
            borderTopLeftRadius: '50%',
            '& path': {
                fill: theme.palette.common.white,
            },
        },
        orderSettingsColor: {
            color: theme.palette.primary.main,
        },
        closeIcon: {
            transform: 'scale(1.5)',
            fill: theme.palette.primary.main,
        },
        closeIconV1: {
            fill: theme.palette.primary.main,
        },
        title: {
            fontWeight: 700,
            fontSize: '1.5rem',
            color: theme.palette.success.light,
        },
    });
});
export var emailSettingsList = [
    // {
    //   name: 'user_forgot_password',
    // },
    {
        name: 'user_request_register',
        access: [USER_ROLE.ADMIN],
    },
    {
        name: 'notify_order_invoice_created',
        access: [BaseUserAppTYpe.CUSTOMER, BaseUserAppTYpe.FORWARDER, BaseUserAppTYpe.CARRIER],
    },
    {
        name: 'notify_order_invoice_request',
        access: [BaseUserAppTYpe.CARRIER, BaseUserAppTYpe.FORWARDER],
    },
    {
        name: 'notify_carrier_order_published',
        access: [BaseUserAppTYpe.CARRIER, BaseUserAppTYpe.FORWARDER],
    },
    // {
    //   name: 'notify_order_published_for',
    //   access: [BaseUserAppTYpe.CONSIGNEE, BaseUserAppTYpe.SENDER, BaseUserAppTYpe.CUSTOMER, BaseUserAppTYpe.FORWARDER],
    // },
    {
        name: 'notify_carrier_order_published_report',
        access: [BaseUserAppTYpe.CARRIER, BaseUserAppTYpe.FORWARDER],
    },
    {
        name: 'notify_carrier_order_removed',
        access: [BaseUserAppTYpe.CARRIER, BaseUserAppTYpe.FORWARDER],
    },
    {
        name: 'notify_customer_order_quote_created',
        access: [BaseUserAppTYpe.CUSTOMER, BaseUserAppTYpe.FORWARDER],
    },
    {
        name: 'notify_customer_quote_updated',
        props: { disabled: true },
        access: [
            BaseUserAppTYpe.CUSTOMER,
            BaseUserAppTYpe.CARRIER,
            BaseUserAppTYpe.WAREHOUSE,
            BaseUserAppTYpe.SENDER,
            BaseUserAppTYpe.CONSIGNEE,
            BaseUserAppTYpe.FORWARDER,
        ],
    },
    {
        name: 'notify_carrier_order_quote_rejected',
        access: [BaseUserAppTYpe.CARRIER, BaseUserAppTYpe.FORWARDER],
    },
    {
        name: 'notify_carrier_order_quote_rejected_by_system',
        access: [BaseUserAppTYpe.CARRIER, BaseUserAppTYpe.FORWARDER],
    },
    {
        name: 'notify_customer_order_quote_approved',
        access: [BaseUserAppTYpe.CUSTOMER, BaseUserAppTYpe.FORWARDER],
    },
    {
        name: 'notify_carrier_order_quote_lose',
        access: [BaseUserAppTYpe.CARRIER],
    },
    {
        name: 'notify_carrier_order_quote_approved',
        access: [BaseUserAppTYpe.CARRIER, BaseUserAppTYpe.FORWARDER, BaseUserAppTYpe.SENDER, BaseUserAppTYpe.CONSIGNEE],
    },
    {
        name: NOTIFICATION_ACTIONS.ORDER_CARRIER_IN_PROGRESS,
        access: [BaseUserAppTYpe.CUSTOMER],
    },
    {
        props: { disabled: true },
        name: NOTIFICATION_ACTIONS.ORDER_MISSING_BOOKING_DELIVERY,
        access: [BaseUserAppTYpe.CARRIER],
    },
    {
        props: { disabled: true },
        name: NOTIFICATION_ACTIONS.ORDER_MISSING_BOOKING_PICKUP,
        access: [BaseUserAppTYpe.CARRIER],
    },
    {
        props: { disabled: true },
        name: NOTIFICATION_ACTIONS.ORDER_BOOKING_PICKUP_NOT_CHANGED,
        access: [BaseUserAppTYpe.CARRIER],
    },
    {
        props: { disabled: true },
        name: NOTIFICATION_ACTIONS.ORDER_BOOKING_DELIVERY_NOT_CHANGED,
        access: [BaseUserAppTYpe.CARRIER],
    },
    {
        name: NOTIFICATION_ACTIONS.NOTIFY_CARRIER_NEED_TO_PICKUP,
        access: [BaseUserAppTYpe.CARRIER],
    },
    {
        name: NOTIFICATION_ACTIONS.NOTIFY_CARRIER_NEED_TO_DELIVERY,
        access: [BaseUserAppTYpe.CARRIER],
    },
    {
        name: NOTIFICATION_ACTIONS.ORDER_CARRIER_BOOKING_PASS,
        access: [BaseUserAppTYpe.CARRIER, BaseUserAppTYpe.FORWARDER],
    },
    {
        name: 'notify_carrier_order_canceled',
        access: [BaseUserAppTYpe.CARRIER, BaseUserAppTYpe.FORWARDER, BaseUserAppTYpe.CUSTOMER],
    },
    {
        name: 'notify_customer_order_quote_assign_transport',
        access: [BaseUserAppTYpe.CUSTOMER, BaseUserAppTYpe.FORWARDER, BaseUserAppTYpe.SENDER, BaseUserAppTYpe.CONSIGNEE],
    },
    {
        name: 'notify_customer_order_quote_loading_time_booked',
        access: [BaseUserAppTYpe.CUSTOMER, BaseUserAppTYpe.FORWARDER, BaseUserAppTYpe.SENDER, BaseUserAppTYpe.CONSIGNEE],
    },
    {
        name: 'notify_customer_order_quote_unloading_time_booked',
        access: [BaseUserAppTYpe.CUSTOMER, BaseUserAppTYpe.FORWARDER, BaseUserAppTYpe.SENDER, BaseUserAppTYpe.CONSIGNEE],
    },
    {
        name: 'notify_customer_order_quote_loading_time_changed',
        access: [BaseUserAppTYpe.CUSTOMER, BaseUserAppTYpe.FORWARDER, BaseUserAppTYpe.SENDER, BaseUserAppTYpe.CONSIGNEE],
    },
    {
        name: 'notify_customer_order_quote_unloading_time_changed',
        access: [BaseUserAppTYpe.CUSTOMER, BaseUserAppTYpe.FORWARDER, BaseUserAppTYpe.SENDER, BaseUserAppTYpe.CONSIGNEE],
    },
    {
        name: 'notify_order_quote_loading_at',
        access: [
            BaseUserAppTYpe.CUSTOMER,
            BaseUserAppTYpe.CARRIER,
            BaseUserAppTYpe.FORWARDER,
            BaseUserAppTYpe.SENDER,
            BaseUserAppTYpe.CONSIGNEE,
        ],
    },
    {
        name: 'notify_order_quote_un_loading_at',
        access: [
            BaseUserAppTYpe.CUSTOMER,
            BaseUserAppTYpe.CARRIER,
            BaseUserAppTYpe.FORWARDER,
            BaseUserAppTYpe.SENDER,
            BaseUserAppTYpe.CONSIGNEE,
        ],
    },
    {
        name: 'notify_order_quote_loading_at_by_system',
        access: [
            BaseUserAppTYpe.CUSTOMER,
            BaseUserAppTYpe.CARRIER,
            BaseUserAppTYpe.FORWARDER,
            BaseUserAppTYpe.SENDER,
            BaseUserAppTYpe.CONSIGNEE,
        ],
    },
    {
        name: 'notify_order_quote_un_loading_at_by_system',
        access: [
            BaseUserAppTYpe.CUSTOMER,
            BaseUserAppTYpe.CARRIER,
            BaseUserAppTYpe.FORWARDER,
            BaseUserAppTYpe.SENDER,
            BaseUserAppTYpe.CONSIGNEE,
        ],
    },
    {
        name: 'notify_customer_order_finished',
        access: [BaseUserAppTYpe.CUSTOMER],
    },
    {
        name: 'notify_user_order_finished',
        access: [BaseUserAppTYpe.FORWARDER, BaseUserAppTYpe.CARRIER],
    },
    {
        name: 'notify_carrier_order_payment_release',
        access: [BaseUserAppTYpe.CARRIER, BaseUserAppTYpe.FORWARDER],
    },
    {
        name: 'notify_customer_order_payment_accept',
        access: [BaseUserAppTYpe.CUSTOMER, BaseUserAppTYpe.FORWARDER],
    },
    {
        name: 'notify_customer_order_payment_reject',
        access: [BaseUserAppTYpe.CUSTOMER, BaseUserAppTYpe.FORWARDER],
    },
    {
        name: 'notify_carrier_order_restored',
        access: [BaseUserAppTYpe.CARRIER, BaseUserAppTYpe.FORWARDER],
    },
    {
        name: 'notify_customer_order_expired',
        access: [BaseUserAppTYpe.CUSTOMER, BaseUserAppTYpe.FORWARDER],
    },
    {
        name: 'notify_customer_order_quote_request_change',
        access: [BaseUserAppTYpe.CUSTOMER, BaseUserAppTYpe.FORWARDER],
    },
    {
        name: 'notify_order_quote_no_loading_at',
        access: [BaseUserAppTYpe.CUSTOMER, BaseUserAppTYpe.CARRIER, BaseUserAppTYpe.SENDER, BaseUserAppTYpe.CONSIGNEE],
    },
    {
        name: 'notify_order_quote_no_un_loading_at',
        access: [BaseUserAppTYpe.CUSTOMER, BaseUserAppTYpe.CARRIER, BaseUserAppTYpe.SENDER, BaseUserAppTYpe.CONSIGNEE],
    },
    {
        name: 'notify_chat_unread_messages',
        access: [
            BaseUserAppTYpe.CARRIER,
            BaseUserAppTYpe.CUSTOMER,
            BaseUserAppTYpe.FORWARDER,
            BaseUserAppTYpe.SENDER,
            BaseUserAppTYpe.CONSIGNEE,
        ],
    },
    {
        name: 'notify_location_updated_warehouse',
        access: [BaseUserAppTYpe.CARRIER, BaseUserAppTYpe.CUSTOMER, BaseUserAppTYpe.FORWARDER],
    },
    {
        name: 'notify_carrier_company_document_expired',
        access: [BaseUserAppTYpe.CARRIER],
    },
    {
        name: 'notify_admin_company_term_request_approved',
        access: [BaseUserAppTYpe.CUSTOMER],
    },
    {
        name: 'notify_admin_company_term_request_declined',
        access: [BaseUserAppTYpe.CUSTOMER],
    },
    {
        name: 'user_joined_to_env',
        access: [BaseUserAppTYpe.CARRIER],
    },
    {
        name: 'user_left_env',
        access: [BaseUserAppTYpe.CARRIER],
    },
    {
        name: NOTIFICATION_ACTIONS.ORDER_PREPARING_STATUS_UPDATED,
        access: [BaseUserAppTYpe.CUSTOMER, BaseUserAppTYpe.SENDER, BaseUserAppTYpe.CONSIGNEE, BaseUserAppTYpe.FORWARDER],
    },
];
