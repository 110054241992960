var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import { FetchUploadByPathPreview, FetchUploadPreview } from '../../graphql';
import { toast } from 'react-toastify';
import GetAppIcon from '@material-ui/icons/GetApp';
import { MainButton } from '../MainButton';
import { i18n, KEYS } from '../../i18n';
import BaseTooltip from '../BaseTooltip';
import { IconButton, useMediaQuery, useTheme } from '@material-ui/core';
import OrderRouteMenuItem from '../Order/List/OrderRoutesMenuItem';
import { onPreventEvent } from './utill';
export default function DownLoadDocument(_a) {
    var _this = this;
    var item = _a.item, handleClose = _a.handleClose, config = _a.config, props = __rest(_a, ["item", "handleClose", "config"]);
    var theme = useTheme();
    var isMiddle = useMediaQuery(theme.breakpoints.down('sm'));
    var _b = __read(React.useState(false), 2), loading = _b[0], setLoading = _b[1];
    var onClick = function (e) { return __awaiter(_this, void 0, void 0, function () {
        var result, res_1, ext_1, _a, _b, _c, filePath, e_1;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    onPreventEvent(e);
                    _d.label = 1;
                case 1:
                    _d.trys.push([1, 9, 10, 11]);
                    result = void 0;
                    if (!(item && item.pathBlob)) return [3 /*break*/, 2];
                    result = item.pathBlob;
                    return [3 /*break*/, 8];
                case 2:
                    _a = config;
                    if (!_a) return [3 /*break*/, 7];
                    _c = item._id;
                    if (!_c) return [3 /*break*/, 4];
                    return [4 /*yield*/, config.client.query({
                            query: FetchUploadPreview,
                            variables: {
                                _id: item._id,
                            },
                        })];
                case 3:
                    _c = (_d.sent());
                    _d.label = 4;
                case 4:
                    _b = (_c);
                    if (_b) return [3 /*break*/, 6];
                    return [4 /*yield*/, config.client.query({
                            query: FetchUploadByPathPreview,
                            variables: {
                                path: item.path,
                            },
                        })];
                case 5:
                    _b = (_d.sent());
                    _d.label = 6;
                case 6:
                    _a = (_b);
                    _d.label = 7;
                case 7:
                    res_1 = _a;
                    res_1 = res_1.data.uploadPreview || res_1.data.uploadPreviewByPath;
                    result = res_1.path;
                    filePath = new URL(result).pathname;
                    ext_1 = filePath.split('.')[filePath.split('.').length - 1];
                    _d.label = 8;
                case 8:
                    if (!result)
                        throw 'No file';
                    fetch(window.APP_API_URL.replace(/\/graphql/gi, '') + "/source?url=" + encodeURIComponent(result))
                        .then(function (resp) { return resp.blob(); })
                        .then(function (blob) {
                        var url = window.URL.createObjectURL(blob);
                        var a = document.createElement('a');
                        a.style.display = 'none';
                        a.href = url;
                        a.download = (res_1 === null || res_1 === void 0 ? void 0 : res_1.name) || '';
                        if (ext_1 && !a.download.match(new RegExp("." + ext_1, 'gi'))) {
                            a.download += "." + ext_1;
                        }
                        document.body.appendChild(a);
                        a.click();
                        window.URL.revokeObjectURL(url);
                    })
                        .catch(function (e) { return toast.error(e.message || e); });
                    return [3 /*break*/, 11];
                case 9:
                    e_1 = _d.sent();
                    console.log(e_1);
                    toast.error(e_1.message || e_1);
                    return [3 /*break*/, 11];
                case 10:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 11: return [2 /*return*/];
            }
        });
    }); };
    var title = i18n.getResource(i18n.language, KEYS.APP, 'download') || 'Download';
    if (props.label) {
        return (React.createElement(OrderRouteMenuItem, __assign({}, {
            handleClickOpen: onClick,
            icon: (React.createElement(BaseTooltip, { title: title },
                React.createElement(IconButton, { color: 'primary', style: { padding: theme.spacing(1) } },
                    React.createElement(GetAppIcon, null)))),
            label: title,
        })));
    }
    if (props.v1) {
        return (React.createElement(BaseTooltip, { title: title },
            React.createElement(IconButton, { onClick: onClick, color: 'primary', style: __assign({ padding: theme.spacing(1) }, (isMiddle && { padding: 2 })) },
                React.createElement(GetAppIcon, null))));
    }
    return React.createElement(MainButton, { loading: loading, title: title, onClick: onClick, style: props.style });
}
