import { types } from 'mobx-state-tree';
import { OrderCurrency } from './types';
var is_USA_APP = parseInt(process.env.REACT_APP_APP_FOR_USA || '0') === 1;
export var BaseUserEmailSettings = types.model('BaseUserEmailSettings', {
    user_request_register: types.maybeNull(types.optional(types.boolean, false)),
    user_registration_approved: types.maybeNull(types.optional(types.boolean, false)),
    user_forgot_password: types.maybeNull(types.optional(types.boolean, false)),
    notify_carrier_order_published: types.maybeNull(types.optional(types.boolean, false)),
    notify_order_published_for: types.maybeNull(types.optional(types.boolean, false)),
    notify_customer_order_quote_created: types.maybeNull(types.optional(types.boolean, false)),
    notify_carrier_order_quote_rejected: types.maybeNull(types.optional(types.boolean, false)),
    notify_carrier_order_quote_rejected_by_system: types.maybeNull(types.optional(types.boolean, false)),
    notify_customer_order_quote_loading_time_booked: types.maybeNull(types.optional(types.boolean, false)),
    notify_customer_order_quote_unloading_time_booked: types.maybeNull(types.optional(types.boolean, false)),
    notify_customer_order_quote_loading_time_changed: types.maybeNull(types.optional(types.boolean, false)),
    notify_customer_order_quote_unloading_time_changed: types.maybeNull(types.optional(types.boolean, false)),
    notify_order_quote_loading_at: types.maybeNull(types.optional(types.boolean, false)),
    notify_order_quote_loading_at_by_system: types.maybeNull(types.optional(types.boolean, false)),
    notify_order_quote_un_loading_at: types.maybeNull(types.optional(types.boolean, false)),
    notify_order_quote_un_loading_at_by_system: types.maybeNull(types.optional(types.boolean, false)),
    notify_carrier_order_payment_release: types.maybeNull(types.optional(types.boolean, false)),
    notify_customer_order_payment_accept: types.maybeNull(types.optional(types.boolean, false)),
    notify_customer_order_payment_reject: types.maybeNull(types.optional(types.boolean, false)),
    carrier_request_loan: types.maybeNull(types.optional(types.boolean, false)),
    notify_customer_quote_updated: types.maybeNull(types.optional(types.boolean, false)),
    notify_carrier_order_removed: types.maybeNull(types.optional(types.boolean, false)),
    notify_carrier_order_quote_lose: types.maybeNull(types.optional(types.boolean, false)),
    notify_customer_order_quote_approved: types.maybeNull(types.optional(types.boolean, false)),
    notify_customer_order_quote_assign_transport: types.maybeNull(types.optional(types.boolean, false)),
    notify_carrier_order_canceled: types.maybeNull(types.optional(types.boolean, false)),
    notify_order_quote_no_loading_at: types.maybeNull(types.optional(types.boolean, false)),
    notify_order_quote_no_un_loading_at: types.maybeNull(types.optional(types.boolean, false)),
    notify_carrier_order_quote_approved: types.maybeNull(types.optional(types.boolean, false)),
    notify_customer_order_expired: types.maybeNull(types.optional(types.boolean, false)),
    notify_carrier_order_restored: types.maybeNull(types.optional(types.boolean, false)),
    notify_customer_order_quote_request_change: types.maybeNull(types.optional(types.boolean, false)),
    notify_chat_unread_messages: types.maybeNull(types.optional(types.boolean, false)),
    notify_order_invoice_created: types.maybeNull(types.optional(types.boolean, false)),
    notify_order_invoice_request: types.maybeNull(types.optional(types.boolean, false)),
    notify_carrier_order_published_report: types.maybeNull(types.optional(types.boolean, false)),
    notify_customer_order_finished: types.maybeNull(types.optional(types.boolean, false)),
    notify_user_order_finished: types.maybeNull(types.optional(types.boolean, false)),
    notify_location_updated_warehouse: types.maybeNull(types.optional(types.boolean, false)),
    notify_carrier_company_document_expired: types.maybeNull(types.optional(types.boolean, false)),
    notify_admin_company_term_request: types.maybeNull(types.optional(types.boolean, false)),
    notify_admin_company_term_request_declined: types.maybeNull(types.optional(types.boolean, false)),
    notify_admin_company_term_request_approved: types.maybeNull(types.optional(types.boolean, false)),
    user_joined_to_env: types.maybeNull(types.optional(types.boolean, false)),
    user_left_env: types.maybeNull(types.optional(types.boolean, false)),
    notify_issue_deleted: types.maybeNull(types.optional(types.boolean, false)),
    notify_issue_created: types.maybeNull(types.optional(types.boolean, false)),
    notify_issue_updated: types.maybeNull(types.optional(types.boolean, false)),
    notify_fast_payment_created: types.maybeNull(types.optional(types.boolean, false)),
    notify_owner_company_term_request: types.maybeNull(types.optional(types.boolean, false)),
    notify_carrier_order_booking_pass: types.maybeNull(types.optional(types.boolean, false)),
    notify_carrier_order_booking_delivery_not_changed: types.maybeNull(types.optional(types.boolean, false)),
    notify_carrier_order_booking_pickup_not_changed: types.maybeNull(types.optional(types.boolean, false)),
    notify_carrier_order_missing_booking_pickup: types.maybeNull(types.optional(types.boolean, false)),
    notify_carrier_order_missing_booking_delivery: types.maybeNull(types.optional(types.boolean, false)),
    notify_carrier_need_to_pickup: types.maybeNull(types.optional(types.boolean, false)),
    notify_carrier_need_to_delivery: types.maybeNull(types.optional(types.boolean, false)),
    notify_consignee_order_preparing_status: types.maybeNull(types.optional(types.boolean, false)),
    notify_customer_order_in_progress: types.maybeNull(types.optional(types.boolean, false)),
});
var BaseUserSettingsFilters = types.model('BaseUserSettingsFilters', {
    name: types.maybeNull(types.optional(types.string, '')),
    id: types.maybeNull(types.optional(types.string, '')),
    value: types.maybeNull(types.optional(types.string, '')),
    type: types.maybeNull(types.optional(types.string, '')),
});
export var BaseUserSettings = types.model('BaseUserSettings', {
    _id: types.maybeNull(types.optional(types.string, '')),
    terms: types.maybeNull(types.optional(types.array(types.union(types.string)), [])),
    selectedVehicleTypesAt: types.maybeNull(types.union(types.null, types.undefined, types.string)),
    updatedAt: types.maybeNull(types.union(types.null, types.undefined, types.string)),
    forwarderOrderMarkUpAuto: types.maybeNull(types.optional(types.boolean, false)),
    forwarderOrderMarkUpPercentage: types.maybeNull(types.optional(types.number, 0)),
    forwarderOrderMarkUpMinPrice: types.maybeNull(types.optional(types.number, 0)),
    forwarderOrderMarkUpMinPriceCurrency: types.maybeNull(types.optional(types.enumeration('USD', Object.keys(OrderCurrency)), is_USA_APP ? OrderCurrency.USD : OrderCurrency.EUR)),
    forwarderOrderMarkUpMaxPriceCurrency: types.maybeNull(types.optional(types.enumeration('USD', Object.keys(OrderCurrency)), is_USA_APP ? OrderCurrency.USD : OrderCurrency.EUR)),
    forwarderOrderMarkUpMaxPrice: types.maybeNull(types.optional(types.number, 0)),
    hideToDo: types.maybeNull(types.optional(types.boolean, false)),
    showPlatformDuty: types.maybeNull(types.optional(types.boolean, false)),
    openWizardOnClick: types.maybeNull(types.optional(types.boolean, false)),
    disablePushNotificationModal: types.maybeNull(types.optional(types.boolean, false)),
    orderAutoArchiveDelay: types.maybeNull(types.optional(types.number, 40)),
    filters: types.maybeNull(types.optional(types.array(BaseUserSettingsFilters), function () { return []; })),
    personalAffiliateRevenue: types.maybeNull(types.number),
    emailSettings: types.maybeNull(types.optional(BaseUserEmailSettings, function () { return BaseUserEmailSettings.create(); })),
    phoneSettings: types.maybeNull(types.optional(BaseUserEmailSettings, function () { return BaseUserEmailSettings.create(); })),
});
