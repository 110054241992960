var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { theme } from '../theme';
import { IconButton } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import BaseTooltip from './BaseTooltip';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../i18n';
import { copyLink } from '../utils';
import { onPreventEvent } from './Dialog';
import clsx from 'clsx';
var useStyles = makeStyles(function (theme) {
    var _a;
    return createStyles({
        root: (_a = {
                // whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
            },
            _a[theme.breakpoints.down('xs')] = {
                whiteSpace: 'break-spaces!important',
            },
            _a['&:hover'] = {
                '& .copy-item': {
                    display: 'block',
                },
            },
            _a),
        icon: {
            position: 'absolute',
            right: -4,
            top: -4,
            backgroundColor: theme.palette.common.white + "!important",
            display: 'none',
            padding: 4,
        },
    });
});
export var TextOverflow = function (_a) {
    var _b;
    var noTitle = _a.noTitle, v1 = _a.v1, v2 = _a.v2, v3 = _a.v3, v4 = _a.v4, v5 = _a.v5, v6 = _a.v6, v7 = _a.v7, v8 = _a.v8, warn = _a.warn, className = _a.className, props = __rest(_a, ["noTitle", "v1", "v2", "v3", "v4", "v5", "v6", "v7", "v8", "warn", "className"]);
    var classes = useStyles();
    var title = props.title;
    var titleVal = props.children || '';
    if (!title && Array.isArray(titleVal) && titleVal.filter(function (e) { return typeof e === 'string'; }).length === titleVal.length)
        titleVal = titleVal.join('') || '';
    var langApp = useTranslation(KEYS.APP);
    if (typeof titleVal === 'string' && !title)
        title = titleVal;
    var onCopy = function (e) {
        copyLink(props.valueToCopy || title, langApp.t('main_copy_text_copied'));
        return onPreventEvent(e);
    };
    var icon = (React.createElement(BaseTooltip, { title: props.titleToCopy || langApp.t('main_copy_text') },
        React.createElement(IconButton, { onClick: onCopy, className: clsx('copy-item', (_b = {}, _b[classes.icon] = v3, _b)), style: __assign({}, (((v5 || props.v9) && { padding: theme.spacing(0.5) }) || null)) },
            React.createElement(FileCopyIcon, { style: __assign({ width: 16, height: 16 }, (props.v9 && { width: 12, height: 12 })) }))));
    var fileIcon = (v3 && icon) || null;
    var typography = (React.createElement(Typography, __assign({}, props, { title: (!noTitle && !props.dangerouslySetInnerHTML && title) || '', style: __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({ maxWidth: '100%', position: 'relative' }, (v6 && { cursor: 'pointer', textDecoration: 'underline', color: theme.palette.primary.light })), (!v1 && { whiteSpace: 'nowrap' })), (v2 && { color: theme.palette.error.main, fontSize: '0.75rem', lineHeight: 1 })), (warn && { color: theme.palette.warning.light, fontSize: '0.75rem', fontWeight: 800, lineHeight: 1 })), ((v3 || v4) && { lineHeight: 1 })), (v5 && { maxWidth: 'calc(100% - 24px)' })), (v7 && { fontWeight: 600, textTransform: 'uppercase' })), (v8 && { color: theme.palette.common.black })), props.style), classes: { root: classes.root } }, (!props.v31 && (v3 || v5) && { onClick: onCopy }), { className: className }),
        (!props.dangerouslySetInnerHTML && props.children) || null,
        fileIcon));
    if (v5) {
        return (React.createElement(Grid, { container: true, alignItems: 'center', justify: 'center', style: { width: 'initial' } },
            typography,
            icon));
    }
    return typography;
};
