var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { inject, observer, useObserver } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { RouteFormItem } from './RouteFormBasic';
import { OrderRouteType } from '../../../../../models';
import { useFormStyles } from '../../../../../styles';
import { KEYS } from '../../../../../i18n';
import { TextOverflow } from '../../../../TextOverflow';
import PaddingSpace from '../PaddingSpce';
import { theme } from '../../../../../theme';
export var RouteForm = inject('store')(observer(React.forwardRef(function (_a, ref) {
    var store = _a.store, index = _a.index, minDate = _a.minDate, maxDate = _a.maxDate, props = __rest(_a, ["store", "index", "minDate", "maxDate"]);
    // const [filters, setFilters]: any = React.useState<any>({
    //   filter: { fields: [{ name: 'simpleLocationData', value: 1 }].filter((e) => e) },
    // });
    var langOfferForm = useTranslation(KEYS.OFFER_FORM);
    var langApp = useTranslation(KEYS.APP);
    var langOrderForm = useTranslation(KEYS.ORDER_FORM);
    var langOrderList = useTranslation(KEYS.ORDER_LIST);
    var langFormLocations = useTranslation(KEYS.FORM_LOCATIONS);
    var langForm = useTranslation(KEYS.FORM);
    var classes = useFormStyles();
    var startLocation = (props.transportation.routes || []).find(function (e) { return e.type === OrderRouteType.Preferred; }) || {
        orderTransportation: props.transportation,
        type: OrderRouteType.Preferred,
    };
    var endLocation = (props.transportation.routes || []).find(function (e) { return e.type === OrderRouteType.Delivery; }) || {
        orderTransportation: props.transportation,
        type: OrderRouteType.Delivery,
    };
    var startRouteProps = Object.assign({
        maxLimit: endLocation,
    }, minDate
        ? {
            minLimit: {
                orderTransportation: props.transportation,
                type: OrderRouteType.Preferred,
                startDate: minDate,
            },
        }
        : null);
    var endRouteProps = Object.assign({
        minLimit: startLocation,
    }, maxDate
        ? {
            maxLimit: {
                orderTransportation: props.transportation,
                type: OrderRouteType.Delivery,
                endDate: maxDate,
            },
        }
        : null);
    return useObserver(function () { return (React.createElement("div", { className: classes.root, style: { marginBottom: theme.spacing(4) }, id: "step-content-route-" + index },
        React.createElement(Grid, { container: true, alignItems: 'center', className: classes.sectionFOrm, style: { paddingBottom: 0 } },
            React.createElement(PaddingSpace, null),
            React.createElement(Grid, { item: true, xs: 12, md: 9, container: true, direction: 'row', alignItems: 'center' },
                React.createElement(TextOverflow, { variant: "subtitle1", className: classes.sectionTitle1 }, langOrderForm.t('create_order_route_list_label'))),
            React.createElement(Grid, { item: true, xs: 12, md: 1, container: true, justify: 'flex-end' }, props.content)),
        React.createElement("div", { style: { marginBottom: theme.spacing(1), position: 'relative' } },
            React.createElement(RouteFormItem, __assign({}, props, { index: 1, canAdd: true, key: "" + startLocation._id, ref: ref[0], locationType: OrderRouteType.Preferred, title: langOrderForm.t('create_order_route_origin_from'), originTitle: langOrderForm.t('create_order_route_origin_from'), dateTitle: langOrderList.t('order_list_filter_sort_loading_at'), emailTitle: langForm.t('form_input_help_order_route_email_sender'), emailTitle1: langFormLocations.t('form_location_pickup_email'), timeTitle: langForm.t('form_input_time_to_spend_on_load'), timeTitleHelp: langForm.t('form_input_help_order_time_to_spend_on_load'), strictlyDateHelp: langOrderForm.t('form_checkbox_helper_is_upload_date_strictly'), creationCopyHelp: langOrderForm.t('form_checkbox_helper_is_upload_date_copy'), defaultValue: startLocation, endLocation: endLocation }, startRouteProps, { declineProps: {
                    keyVal: 'inTransitAt',
                    transportation: props.transportation,
                    title: langOrderList.t('in_progress_has_loaded'),
                    titleNoOk: langOrderList.t('in_progress_has_not_loaded'),
                    titleConfirm: langApp.t("modal_prompt_question_no_in_transit"),
                } }))),
        React.createElement("div", { style: { marginBottom: theme.spacing(1) } },
            React.createElement(RouteFormItem, __assign({}, props, { index: 2, locationType: OrderRouteType.Delivery, canAdd: true, ref: ref[1], key: "" + endLocation._id, title: langOrderForm.t('create_order_route_origin_to'), originTitle: langOrderForm.t('create_order_route_origin_to'), emailTitle: langForm.t('form_input_help_order_route_email_consignee'), emailTitle1: langFormLocations.t('form_location_pickup_email'), dateTitle: langOrderForm.t('create_order_route_origin_to_date'), timeTitle: langForm.t('form_input_time_to_spend_on_unload'), timeTitleHelp: langForm.t('form_input_help_order_time_to_spend_on_unload'), strictlyDateHelp: langOrderForm.t('form_checkbox_helper_is_upload_date_strictly'), creationCopyHelp: langOrderForm.t('form_checkbox_helper_is_upload_date_copy'), defaultValue: endLocation, startLocation: startLocation }, endRouteProps, { declineProps: {
                    keyVal: 'unloadedAt',
                    transportation: props.transportation,
                    title: langOfferForm.t('offer_un_loaded'),
                    titleNoOk: langOrderList.t('in_progress_has_not_un_loaded'),
                    titleConfirm: langApp.t("modal_prompt_question_no_delivered"),
                } }))))); });
})));
