var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { MenuItem, Select as SelectMaterialUI, FormControl } from '@material-ui/core';
import BaseTooltip from './BaseTooltip';
var useStyles = makeStyles(function (theme) { return ({
    icon: {
        width: '100%',
        '& .MuiSelect-icon': {
            color: theme.palette.secondary.main,
        },
    },
    select: {
        width: '100%',
        '&:focus': {
            color: theme.palette.primary.main,
        },
        '&.Mui-selected': {
            backgroundColor: 'transparent',
            '&:hover': {
                backgroundColor: theme.palette.secondary.light,
            },
        },
    },
}); });
export var Select = function (_a) {
    var onChange = _a.onChange, items = _a.items, defaultValue = _a.defaultValue, props = __rest(_a, ["onChange", "items", "defaultValue"]);
    var classes = useStyles();
    return (React.createElement(FormControl, __assign({}, props),
        React.createElement(SelectMaterialUI, { className: classes.icon, value: defaultValue, onChange: onChange, disableUnderline: true, IconComponent: ExpandMore }, items.map(function (item) {
            var content = (React.createElement(Grid, { container: true, alignItems: 'center', justify: 'center', style: {
                    minWidth: props.hideLabel ? 24 : 56,
                    width: 'initial',
                } }, item.icon));
            return (React.createElement(MenuItem, { key: item.id, value: item.id, className: classes.select }, item.icon ? (React.createElement(Grid, { container: true, alignItems: 'center' },
                props.hideLabel ? content : React.createElement(BaseTooltip, { title: item.name }, content),
                (item.name && React.createElement("span", { className: 'lang' },
                    " ",
                    item.name)) || null)) : null));
        }))));
};
