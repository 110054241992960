var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React from 'react';
import UpdateIcon from '@material-ui/icons/Update';
import TimerOffIcon from '@material-ui/icons/TimerOff';
import { OrderRouteType } from '../../../models';
import { toast } from 'react-toastify';
import { Grid, IconButton, MenuItem } from '@material-ui/core';
import { BASIC_DIALOG_ACTIONS, BasicDialog, onPreventEvent } from '../../Dialog';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { FormikAutocomplete, FormikSelect, FormikTextField } from '../../Formik';
import { i18n, KEYS } from '../../../i18n';
import { useTranslation } from 'react-i18next';
import OrderRouteMenuItem from '../../Order/List/OrderRoutesMenuItem';
import { withCalendarStore } from '../store';
import BaseTooltip from '../../BaseTooltip';
import browserHistory from '../../../browserHistory';
import { toDateOnly } from '../../../utils';
import { inject } from 'mobx-react';
import { times } from '../../Order/form/utils';
import { theme } from '../../../theme';
import { COLOR_DELIVERY, COLOR_PICKUP } from '../../../styles';
import { onlySpacesRegExp } from '../../../common';
import EditIcon from '@material-ui/icons/Edit';
import { MainButton } from '../../MainButton';
import { BaseSingleDatePicker, DATE_FORMAT } from '../../DatePicker';
import { ACTION_CHANGE } from '../../context.store.enums';
import moment from 'moment';
function AddNoneWorkingTime(_a) {
    var _this = this;
    var _b, _c, _d, _e, _f;
    var state = _a.state, warehouseStore = _a.store.warehouseStore, config = _a.config, _g = _a.defaultValue, defaultValue = _g === void 0 ? {} : _g, isPickup = _a.isPickup, day = _a.day, noneWorking = _a.noneWorking, props = __rest(_a, ["state", "store", "config", "defaultValue", "isPickup", "day", "noneWorking"]);
    var noneWorkingDayTime = props.noneWorkingDayTime || state.noneWorkingDayTime;
    var workingDays = props.workingDays || state.workingDays;
    var ramps = ((_b = props.stateDefault) === null || _b === void 0 ? void 0 : _b.ramps) || props.ramps || state.ramps;
    var langForm = useTranslation(KEYS.FORM);
    var ramp = ((defaultValue === null || defaultValue === void 0 ? void 0 : defaultValue.warehouse) && (ramps === null || ramps === void 0 ? void 0 : ramps.data.find(function (e) { return (defaultValue === null || defaultValue === void 0 ? void 0 : defaultValue.warehouse._id) === e._id; }))) || (ramps === null || ramps === void 0 ? void 0 : ramps.data[0]) || { _id: '' };
    var defVal = __assign({ ramp: ramp, time: '', name: '', description: '', timeToSpend: ((_f = (_e = (_d = (_c = workingDays === null || workingDays === void 0 ? void 0 : workingDays.data) === null || _c === void 0 ? void 0 : _c.find(function (e) { return e.warehouse._id === ramp._id && e.day === moment(day).format('dddd'); })) === null || _d === void 0 ? void 0 : _d.plans) === null || _e === void 0 ? void 0 : _e.find(function (e) { return e.duration; })) === null || _f === void 0 ? void 0 : _f.duration) || 60, date: day }, defaultValue);
    var _h = __read(React.useState(defVal), 2), record = _h[0], setRecord = _h[1];
    var _j = __read(React.useState(times.filter(function (e) { return e.value % defVal.timeToSpend === 0; })), 2), rangeOptions = _j[0], setRangeOptions = _j[1];
    var _k = __read(React.useState([]), 2), timeBooking = _k[0], setTimes = _k[1];
    var formRef = React.useRef();
    var langApp = useTranslation(KEYS.APP);
    var langOfferForm = useTranslation(KEYS.OFFER_FORM);
    if (!ramps.count)
        return null;
    var onSaveNoneWorkingTime = function (record) { return __awaiter(_this, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, warehouseStore.updateWarehouseNoneWorkingDayTime(record)];
                case 1:
                    response = _a.sent();
                    if (response && props.onChange)
                        props.onChange(response, ACTION_CHANGE.DELETED);
                    if (props.changeData)
                        props.changeData({
                            noneWorkingDayTime: noneWorkingDayTime.data.find(function (e) { return e._id === response._id; })
                                ? {
                                    data: noneWorkingDayTime.data.map(function (e) { return (__assign(__assign({}, e), (e._id === response._id && response))); }),
                                    count: noneWorkingDayTime.count,
                                }
                                : {
                                    data: __spread([response], noneWorkingDayTime.data),
                                    count: noneWorkingDayTime.count++,
                                },
                        });
                    return [2 /*return*/, response];
            }
        });
    }); };
    var onOk = function (e) { return __awaiter(_this, void 0, void 0, function () {
        var item, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 5, , 6]);
                    onPreventEvent(e);
                    return [4 /*yield*/, formRef.current.submitForm()];
                case 1:
                    item = _a.sent();
                    if (!item)
                        throw Error(i18n.getResource(i18n.language, KEYS.TOAST, 'toast_form_not_valid'));
                    if (!noneWorking) return [3 /*break*/, 3];
                    return [4 /*yield*/, onSaveNoneWorkingTime(item)];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 3:
                    browserHistory.push("/orders/create?warehouse=" + item.ramp._id + "&" + (isPickup ? 'timeIsPickup=1&pickup' : 'delivery') + "=" + toDateOnly(day) + "&time=" + item.time + (item.timeToSpend && "&timeToSpend=" + item.timeToSpend));
                    _a.label = 4;
                case 4: return [3 /*break*/, 6];
                case 5:
                    e_1 = _a.sent();
                    throw BASIC_DIALOG_ACTIONS.WAIT;
                case 6: return [2 /*return*/];
            }
        });
    }); };
    var loadAvailableTime = function (_a) { var _b; return __awaiter(_this, void 0, void 0, function () {
        var filter;
        var ramp = _a.ramp, date = _a.date, updates = __rest(_a, ["ramp", "date"]);
        return __generator(this, function (_c) {
            filter = {
                fields: [
                    {
                        name: 'transportation',
                        value: {
                            order: __assign(__assign({}, record), updates),
                            startDate: toDateOnly(day),
                            endDate: toDateOnly(day),
                            routes: [
                                {
                                    type: isPickup ? OrderRouteType.Preferred : OrderRouteType.Delivery,
                                    origin: { address: warehouseStore.item.address, warehouse: ramp },
                                },
                            ],
                        },
                    },
                    record._id && { name: 'excludeTime', value: record.time },
                    { name: 'startFrom', value: 0 },
                    { name: 'endTo', value: 0 },
                    { name: 'keyVal', value: isPickup ? 'startTime' : 'endTime' },
                    { name: 'warehouse', value: (ramp === null || ramp === void 0 ? void 0 : ramp._id) || ((_b = record.ramp) === null || _b === void 0 ? void 0 : _b._id) },
                    { name: 'day', value: toDateOnly(date || record.date) },
                ].filter(function (e) { return e; }),
            };
            warehouseStore.loadTimeToBook(filter).then(function (times) {
                if (times)
                    setTimes(times);
                if (!times || !times.length)
                    toast.error(langOfferForm.t('offer_no_time_to_select'));
            });
            return [2 /*return*/];
        });
    }); };
    var validationSchema = {
        time: Yup.string().required(langForm.t('form_input_validate_required')),
    };
    if (noneWorking) {
        Object.assign(validationSchema, {
            name: Yup.string()
                .min(1, langForm.t('form_input_validate_min_char').replace(/_X_/g, '8'))
                .max(50, langForm.t('form_input_validate_max_char').replace(/_X_/g, '50'))
                .matches(onlySpacesRegExp, langForm.t('form_input_validate_invalid_text'))
                .required(langForm.t('form_input_validate_required')),
            description: Yup.string()
                .max(1050, langForm.t('form_input_validate_max_char').replace(/_X_/g, '1050'))
                .matches(onlySpacesRegExp, langForm.t('form_input_validate_invalid_text')),
        });
    }
    validationSchema = Yup.object(validationSchema);
    var dialogBody = (React.createElement(Formik, { innerRef: formRef, key: timeBooking.join('-') + "-" + record.ramp._id + "-" + record.date, initialValues: record, validationSchema: validationSchema, onSubmit: function (e) { return e; }, validateOnBlur: false, validateOnChange: false }, function (formik) {
        var handleChange = function (e) { return __awaiter(_this, void 0, void 0, function () {
            var _a, name, value, updates;
            var _b, _c, _d;
            var _e, _f, _g, _h, _j, _k;
            return __generator(this, function (_l) {
                switch (_l.label) {
                    case 0:
                        _a = e.target, name = _a.name, value = _a.value;
                        return [4 /*yield*/, formik.setFieldValue(name, value)];
                    case 1:
                        _l.sent();
                        updates = __assign({}, record);
                        if (!(name === 'ramp')) return [3 /*break*/, 4];
                        return [4 /*yield*/, formik.setFieldValue('time', '')];
                    case 2:
                        _l.sent();
                        updates.time = '';
                        updates.timeToSpend =
                            ((_g = (_f = (_e = workingDays.data
                                .find(function (e) { return e.warehouse._id === value._id && e.day === moment(day).format('dddd'); })) === null || _e === void 0 ? void 0 : _e.plans) === null || _f === void 0 ? void 0 : _f.find(function (e) { return e.duration; })) === null || _g === void 0 ? void 0 : _g.duration) || 60;
                        return [4 /*yield*/, formik.setFieldValue('timeToSpend', updates.timeToSpend)];
                    case 3:
                        _l.sent();
                        setRangeOptions(times.filter(function (e) { return e.value % updates.timeToSpend === 0; }));
                        loadAvailableTime(__assign(__assign({}, updates), (_b = {}, _b[name] = value, _b)));
                        return [3 /*break*/, 7];
                    case 4:
                        if (!(name === 'date')) return [3 /*break*/, 7];
                        return [4 /*yield*/, formik.setFieldValue('time', '')];
                    case 5:
                        _l.sent();
                        updates.time = '';
                        updates.timeToSpend =
                            ((_k = (_j = (_h = workingDays.data
                                .find(function (e) { return e.warehouse._id === formik.values.ramp._id && e.day === moment(value).format('dddd'); })) === null || _h === void 0 ? void 0 : _h.plans) === null || _j === void 0 ? void 0 : _j.find(function (e) { return e.duration; })) === null || _k === void 0 ? void 0 : _k.duration) || 60;
                        return [4 /*yield*/, formik.setFieldValue('timeToSpend', updates.timeToSpend)];
                    case 6:
                        _l.sent();
                        setRangeOptions(times.filter(function (e) { return e.value % updates.timeToSpend === 0; }));
                        loadAvailableTime(__assign(__assign({}, updates), (_c = {}, _c[name] = value, _c)));
                        _l.label = 7;
                    case 7:
                        setRecord(__assign(__assign({}, updates), (_d = {}, _d[name] = value, _d)));
                        return [2 /*return*/];
                }
            });
        }); };
        var style = { marginBottom: theme.spacing(3.5) };
        return (React.createElement(Form, { style: { width: '100%' }, id: 'form-warehouse-none-working-time' },
            React.createElement(Grid, { container: true, direction: 'column' },
                React.createElement(Grid, { container: true, style: style },
                    React.createElement(BaseSingleDatePicker, { value: formik.values.date, defaultValue: formik.values.date, name: 'date', isFormik: true, inputFormat: DATE_FORMAT, minDate: new Date(0), onClose: function (value) {
                            handleChange({ target: { name: 'date', value: value } });
                        } })),
                React.createElement(Grid, { container: true, style: style },
                    React.createElement(FormikSelect, { title: langApp.t('warehouse_ramps'), fullWidth: true, variant: "outlined", name: "ramp", onChange: handleChange, value: formik.values.ramp }, ramps.data.map(function (ramp) {
                        return (React.createElement(MenuItem, { key: ramp._id, value: ramp }, ramp.name));
                    }))),
                React.createElement(Grid, { container: true, style: style },
                    React.createElement(FormikSelect, { v17: true, title: noneWorking
                            ? langApp.t('app_time')
                            : langOfferForm.t(isPickup ? 'offer_loading_time_assigning' : 'offer_un_loading_time_assigning'), fullWidth: true, variant: "outlined", name: "time", value: formik.values.time }, timeBooking.map(function (time) {
                        return (React.createElement(MenuItem, { key: time, value: time }, time));
                    }))),
                React.createElement(Grid, { container: true, style: style },
                    React.createElement(FormikAutocomplete, { v17: true, style: { width: '100%' }, onChageText: function () { return 0; }, fullWidth: true, variant: "outlined", name: "timeToSpend", autocompleteProps: {
                            onChange: function (e) {
                                return handleChange({
                                    target: { name: 'timeToSpend', value: e.target.value && e.target.value.value },
                                });
                            },
                            getOptionLabel: function (option) { return option && option.label; },
                            renderOption: function (option) { return option && option.label; },
                            value: formik.values.timeToSpend &&
                                times
                                    .map(function (e) {
                                    var _a;
                                    return (__assign(__assign({}, e), { label: ((_a = e.label) === null || _a === void 0 ? void 0 : _a.length) ? langForm.t("form_time_" + e.label)
                                            : [e.value, langApp.t('app_time_minutes_short')].join(' ') }));
                                })
                                    .find(function (e) { return e.value === formik.values.timeToSpend; }),
                        }, title: langForm.t('form_input_time_to_spend_on_load'), options: rangeOptions.map(function (e) {
                            var _a;
                            return (__assign(__assign({}, e), { label: ((_a = e.label) === null || _a === void 0 ? void 0 : _a.length) ? langForm.t("form_time_" + e.label)
                                    : [e.value, langApp.t('app_time_minutes_short')].join(' ') }));
                        }) })),
                (noneWorking && (React.createElement(React.Fragment, null,
                    React.createElement(Grid, { style: style },
                        React.createElement(FormikTextField, { placeHolder: langForm.t('form_input_name'), title: langForm.t('form_input_name'), variant: "outlined", name: "name", value: formik.values.name })),
                    React.createElement(Grid, { style: style },
                        React.createElement(FormikTextField, { multiline: true, rows: 4, placeHolder: langForm.t('form_input_description'), title: langForm.t('form_input_description'), variant: "outlined", name: "description", value: formik.values.description }))))) ||
                    null)));
    }));
    var actionOk = noneWorking
        ? langApp.t('none_work_time')
        : langApp.t(isPickup ? 'add_pickup_time' : 'add_delivery_time');
    var dialogProps = {};
    if (record._id && noneWorking) {
        dialogProps.actionOkTitle = langForm.t('form_btn_save');
    }
    return (React.createElement(BasicDialog, __assign({ body: dialogBody, onOk: onOk, v1: true, actionOkTitle: langForm.t('form_btn_add') }, dialogProps, { title: actionOk, withoutWrapper: true, isMiddleBody: true, dialogProps: { fullWidth: true, maxWidth: 'sm' }, actionInit: function (onOpen) {
            var _onClick = function (e) {
                loadAvailableTime(record);
                onOpen(e);
            };
            var onClick = _onClick;
            var icon = (React.createElement(BaseTooltip, { title: (record && record._id && record.comment) || actionOk },
                React.createElement(IconButton, { id: ((record && record._id && record.comment) || actionOk || '')
                        .toString()
                        .replace(/ /gi, '')
                        .toLowerCase(), "aria-label": "menu", onClick: onClick, color: (record && record._id && 'secondary') || 'primary' }, noneWorking ? (defaultValue._id ? (React.createElement(EditIcon, { style: { color: theme.palette.primary.light, marginLeft: theme.spacing(1) } })) : (React.createElement(TimerOffIcon, { style: { color: theme.palette.primary.light, marginLeft: theme.spacing(1) } }))) : (React.createElement(UpdateIcon, { style: { color: isPickup ? COLOR_PICKUP : COLOR_DELIVERY, marginLeft: theme.spacing(1) } })))));
            if (props.label)
                return React.createElement(OrderRouteMenuItem, __assign({}, { icon: icon, label: actionOk, handleClickOpen: onClick }));
            if (props.withBtn)
                return React.createElement(MainButton, { onClick: onClick, color: 'primary', title: actionOk });
            return icon;
        } })));
}
export default withCalendarStore(inject('store')(AddNoneWorkingTime));
