var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { ApolloProvider } from '@apollo/client';
import { ThemeProvider } from '@material-ui/styles';
import { reaction } from 'mobx';
import * as reactRouterBrowser from 'react-router-dom';
import dotenv from 'dotenv';
import { LocalizationProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { inject, observer, Provider } from 'mobx-react';
import * as reactRouter from 'react-router-dom';
import { ToastContainer, Zoom } from 'react-toastify';
import { configure } from 'mobx';
import 'typeface-open-sans';
import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { theme } from '../theme';
import browserHistory from '../browserHistory';
import { LocationListen } from './LocationHistory';
import { initApp } from '../init';
import Sounds from '../components/Sounds';
import { globalUtils, IS_DEV_ENV } from '../utils';
import * as serviceWorker from '../serviceWorker';
import { browserStorage } from '../browserStorage';
import { googleTagManager } from '../utils1';
import BlankPage from '../components/BlankPage';
var Router = reactRouter.Router;
var BrowserRouter = reactRouterBrowser.BrowserRouter;
configure({ isolateGlobalState: true });
var BaseComponent = observer(function (_a) {
    var children = _a.children, store = _a.store;
    return React.createElement(React.Fragment, { key: store.accountStore.currentUser.language }, children);
});
var findParent = function (target, index) {
    if (index === void 0) { index = 1; }
    if (index > 10 || !target) {
        return 0;
    }
    else if (target === null || target === void 0 ? void 0 : target.id) {
        return target;
    }
    else {
        return findParent(target.parentNode, index + 1);
    }
};
var isDev = !!(process.env.REACT_APP_API_URL || '').match(/localhost|127.0.0.1/);
var DelayApp = inject('store')(observer(function (_a) {
    var client = _a.client, store = _a.store, App = _a.App;
    var _b = __read(React.useState(false), 2), loaded = _b[0], setLoaded = _b[1];
    var _c = __read(React.useState([
        {
            name: 'click',
            context: document.body,
            callback: function (e) {
                try {
                    var attributes = ['name', 'title', 'id', 'className'];
                    var values = attributes
                        .map(function (k) {
                        var _a;
                        return e.target[k] || e.target.getAttribute(k)
                            ? (_a = {}, _a["izi_attribute_" + k] = e.target[k] || e.target.getAttribute(k), _a) : 0;
                    })
                        .filter(function (e) { return e; })
                        .reduce(function (a, b) { return (__assign(__assign({}, a), b)); }, {});
                    if (!values['izi_attribute_id']) {
                        var parent_1 = findParent(e.target);
                        if (parent_1)
                            Object.assign(values, attributes
                                .map(function (k) {
                                var _a;
                                return parent_1[k] || parent_1.getAttribute(k)
                                    ? (_a = {}, _a["izi_attribute_" + k] = parent_1[k] || parent_1.getAttribute(k), _a) : 0;
                            })
                                .filter(function (e) { return e; })
                                .reduce(function (a, b) { return (__assign(__assign({}, a), b)); }, {}));
                    }
                    if (values['izi_attribute_id']) {
                        googleTagManager('event', 'izi_click', values);
                    }
                }
                catch (e) {
                    console.log(e);
                }
            },
        },
    ]), 1), events = _c[0];
    React.useEffect(function () {
        if (isDev) {
            window.storeItem = store;
        }
        if (store.hasBeenBuilt()) {
            setLoaded(true);
        }
        events.forEach(function (_a) {
            var name = _a.name, context = _a.context, callback = _a.callback;
            context.addEventListener(name, callback);
        });
        return function () {
            events.forEach(function (_a) {
                var name = _a.name, context = _a.context, callback = _a.callback;
                context.removeEventListener(name, callback);
            });
        };
    }, [store]);
    React.useEffect(function () {
        if (loaded)
            store.accountStore.relogin();
    }, [loaded]);
    React.useEffect(function () {
        if (!browserStorage.getItem('logistics-latest-visit')) {
            browserStorage.setItem('logistics-latest-visit', location.pathname);
        }
        if (store.accountStore.isChecked) {
            var preloader = document.getElementById('preloader');
            if (preloader) {
                preloader.className = 'fade-out';
            }
            globalUtils.sounds = new Sounds();
            setTimeout(function () { return browserStorage.removeItem('logistics-latest-visit'); }, 1000);
        }
    }, [store.accountStore.isChecked]);
    if (!loaded || !store.accountStore.isChecked)
        return null;
    return (React.createElement(ApolloProvider, { client: client },
        React.createElement(ThemeProvider, { theme: theme },
            React.createElement(BrowserRouter, null,
                React.createElement(LocalizationProvider, { dateAdapter: MomentUtils },
                    React.createElement(ToastContainer, { position: "bottom-left", autoClose: IS_DEV_ENV ? 500 : 5000, hideProgressBar: true, transition: Zoom, newestOnTop: false, closeOnClick: true, rtl: false, pauseOnFocusLoss: true, draggable: true, pauseOnHover: false, limit: 3 }),
                    React.createElement(Router, { history: browserHistory },
                        React.createElement(LocationListen, { URL: URL }),
                        React.createElement(BaseComponent, { store: store }, store.accountStore.isApiLoaded ? (React.createElement(App, { store: store, key: (store.accountStore.isLoggedIn && 1) || -1 })) : (React.createElement(BlankPage, null)))))))));
}));
export var baseWithAllProviders = function (App, client, Store, URL) {
    dotenv.config();
    initApp();
    var store = Store.create();
    reaction(function () { return !store.accountStore.isLoggedIn; }, function () { return store.clear(); });
    return (React.createElement(Provider, { store: store },
        React.createElement(DelayApp, __assign({}, { client: client, store: store, App: App }))));
};
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA//
serviceWorker.register();
