import { types } from 'mobx-state-tree';
import { BaseCompany } from './BaseCompany';
import { BaseUserAppTYpe, USER_ROLE } from './types';
import { Upload } from '../../models/Upload';
import { BaseUserSettings } from '../../models/UserSettings';
import { MaybeEmptyModel } from './MaybeEmptyModel';
import { BaseModel } from '../../models/BaseModel';
import { langOptions } from '../../i18n/utils';
import { BaseUserRoles } from '../../models/UserRoles';
import { UserForwarder } from './BaseUserShort';
export var USER_CATEGORY;
(function (USER_CATEGORY) {
    USER_CATEGORY[USER_CATEGORY["REGULAR"] = 1] = "REGULAR";
    USER_CATEGORY[USER_CATEGORY["AFFILIATE"] = 2] = "AFFILIATE";
})(USER_CATEGORY || (USER_CATEGORY = {}));
export var CompanyStatus;
(function (CompanyStatus) {
    CompanyStatus["ACTIVE"] = "ACTIVE";
    CompanyStatus["INACTIVE"] = "INACTIVE";
})(CompanyStatus || (CompanyStatus = {}));
export var USER_STATUS;
(function (USER_STATUS) {
    USER_STATUS["ACTIVE"] = "ACTIVE";
    USER_STATUS["INACTIVE"] = "INACTIVE";
    USER_STATUS["REGISTER"] = "REGISTER";
    USER_STATUS["APPROVE_REGISTER"] = "APPROVE_REGISTER";
    USER_STATUS["INVITE_AFFILIATE"] = "INVITE_AFFILIATE";
    USER_STATUS["ORDER_SHARE_INVITED"] = "ORDER_SHARE_INVITED";
})(USER_STATUS || (USER_STATUS = {}));
export var USER_STATES;
(function (USER_STATES) {
    USER_STATES[USER_STATES["RESTRICTED_INVITED_VIA_LOCATION"] = 1] = "RESTRICTED_INVITED_VIA_LOCATION";
})(USER_STATES || (USER_STATES = {}));
export var Language;
(function (Language) {
    Language["en"] = "en";
    Language["ru"] = "ru";
    Language["lt"] = "lt";
})(Language || (Language = {}));
export var BaseUserRole;
(function (BaseUserRole) {
    BaseUserRole["ADMIN"] = "ADMIN";
    BaseUserRole["MEMBER"] = "MEMBER";
})(BaseUserRole || (BaseUserRole = {}));
export var BaseUser = types
    .model('BaseUser', {
    _id: types.maybeNull(types.optional(types.string, '')),
    firstName: types.maybeNull(types.optional(types.string, '')),
    lastName: types.maybeNull(types.optional(types.string, '')),
    email: types.maybeNull(types.optional(types.string, '')),
    additionalEmail: types.maybeNull(types.optional(types.string, '')),
    language: types.maybeNull(types.optional(types.string, function () { return langOptions.lng; })),
    phone: types.maybeNull(types.maybeNull(types.string)),
    p44Code: types.maybeNull(types.maybeNull(types.string)),
    orderListUpdatedAt: types.maybeNull(types.optional(types.number, 0)),
    settings: types.maybeNull(types.optional(BaseUserSettings, function () { return BaseUserSettings.create(); })),
    roles: types.maybeNull(types.optional(types.array(types.enumeration('MEMBER', Object.keys(BaseUserRole))), function () { return [BaseUserRole.MEMBER]; })),
    rolesV1: types.maybeNull(types.optional(types.array(MaybeEmptyModel(BaseUserRoles)), function () { return []; })),
    createdBy: types.maybeNull(MaybeEmptyModel(BaseModel)),
    appType: types.maybeNull(types.optional(types.array(types.enumeration('CUSTOMER', Object.keys(BaseUserAppTYpe))), function () { return [
        BaseUserAppTYpe.CUSTOMER,
    ]; })),
    company: types.maybeNull(types.optional(MaybeEmptyModel(BaseCompany), function () { return BaseCompany.create(); })),
    // forwarders: types.maybeNull(types.array(types.string)),
    forwarders: types.maybeNull(types.optional(types.array(MaybeEmptyModel(UserForwarder)), function () { return []; })),
    portalLinK: types.maybeNull(types.union(types.null, types.undefined, types.string)),
    affiliateUrl: types.maybeNull(types.union(types.null, types.undefined, types.string)),
    createdAt: types.maybeNull(types.union(types.null, types.undefined, types.string)),
    updatedAt: types.maybeNull(types.union(types.null, types.undefined, types.string)),
    passwordUpdatedAt: types.maybeNull(types.union(types.null, types.undefined, types.string)),
    avatar: types.maybeNull(MaybeEmptyModel(Upload)),
    isVerified: types.maybeNull(types.optional(types.boolean, false)),
    invited: types.maybeNull(types.optional(types.string, '')),
})
    .extend(function (self) {
    // const localState = observable.box(3);
    return {
        views: {
            get fullName() {
                return self.firstName + " " + self.lastName;
            },
            get isCompanyAdmin() {
                return !!(self.createdBy && self.createdBy._id) || (self.roles && self.roles.includes(USER_ROLE.ADMIN));
            },
        },
    };
});
