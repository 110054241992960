var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React from 'react';
import clsx from 'clsx';
import { LinearProgress, createStyles, Typography, Box } from '@material-ui/core';
import { useField } from 'formik';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { CircularProgress, Grid } from '@material-ui/core';
import { useLazyQuery } from '@apollo/client';
import { TextOverflow } from '../TextOverflow';
import { onPreventEvent } from '../Dialog';
import { TIME_OUT_INPUT } from '../types';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../i18n';
import { theme } from '../../theme';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { HelpIconComponent } from '../HelpIcon';
import InputTitle from './InputTitle';
import { useInputStyles } from '../../styles/form';
import { useStyles as useStylesFormik } from './styles';
import { BaseTextField } from './BaseTextField';
import { checkIsDevEnv, wrapTextToId } from '../../utils';
var filter = createFilterOptions();
var useStyles = makeStyles(function (theme) {
    return createStyles({
        adorRoot: {
            '& .MuiOutlinedInput-root': {
                paddingRight: "34px!important",
            },
            '& .custom-ador': {
                display: 'none',
            },
            '& .MuiAutocomplete-clearIndicator': {
            // display: 'none',
            },
            '&:hover': {
                '& .MuiOutlinedInput-root': {
                // paddingRight: '65px!important',
                },
                '& .custom-ador': {
                    display: 'flex',
                },
                '& .MuiAutocomplete-clearIndicator': {
                // display: 'block',
                },
            },
        },
        w100: {
            width: '100%',
        },
        noOptions: {
            padding: 0,
        },
        ul: {
            padding: 0,
            '& li': {
                padding: 0,
            },
        },
        chipRoot: {
            margin: theme.spacing(0, 0.5, 0, 0),
            height: 24,
        },
    });
});
//TODO: using not isCLient break the option, option is clearing all keys exept _id
export var ListboxComponent = React.forwardRef(function ListboxComponentInner(_a, ref) {
    var titleOptions = _a.titleOptions, addContent = _a.addContent, loading = _a.loading, noOptions = _a.noOptions, optionFilter = _a.optionFilter, listStyle = _a.listStyle, optionFilterStyle = _a.optionFilterStyle, onScroll = _a.onScroll, id = _a.id, rest = __rest(_a, ["titleOptions", "addContent", "loading", "noOptions", "optionFilter", "listStyle", "optionFilterStyle", "onScroll", "id"]);
    return (React.createElement(Grid, __assign({ container: true, direction: 'column' }, { onScroll: onScroll }, { id: id }),
        rest.v20 ? addContent : null || null,
        titleOptions && (React.createElement(Typography, { style: { padding: theme.spacing(1, 2) }, variant: 'subtitle1' }, titleOptions)),
        React.createElement("ul", __assign({}, rest, { style: __assign(__assign({ margin: 0 }, (optionFilter && { paddingTop: 0 })), (listStyle || {})), ref: ref, role: "listbox" }),
            React.createElement(React.Fragment, null,
                optionFilter ? React.createElement(Box, { style: __assign({}, optionFilterStyle) }, optionFilter) : null,
                rest.children,
                rest.lastElementRef ? React.createElement(Grid, { ref: rest.lastElementRef }) : null,
                rest.loadingMore ? React.createElement(LinearProgress, null) : null)),
        !rest.hideNoOption && noOptions,
        loading && React.createElement(LinearProgress, { color: 'primary', style: { width: '100%' } }),
        (!rest.v20 ? addContent : null) || null));
});
var timeout;
var loadingSync;
var lastTimeUpdated;
var lastCallback;
export var FormikAutocompleteAsync = function (props) {
    var _a, _b, _c;
    var freeSolo = props.freeSolo, autocompleteProps = props.autocompleteProps, textFieldProps = props.textFieldProps, canAddNew = props.canAddNew, canAddNewV1 = props.canAddNewV1, canAddNewV2 = props.canAddNewV2, canAddNewV3 = props.canAddNewV3, client = props.client, query = props.query, variables = props.variables, options = props.options, _d = props.helperText, helperText = _d === void 0 ? '' : _d, title = props.title, mapResponse = props.mapResponse, canSearch = props.canSearch, isClient = props.isClient, v17 = props.v17, resetOnNewRequest = props.resetOnNewRequest, loadOnScroll = props.loadOnScroll, inputProps = __rest(props, ["freeSolo", "autocompleteProps", "textFieldProps", "canAddNew", "canAddNewV1", "canAddNewV2", "canAddNewV3", "client", "query", "variables", "options", "helperText", "title", "mapResponse", "canSearch", "isClient", "v17", "resetOnNewRequest", "loadOnScroll"]);
    var _e = __read(useLazyQuery(query), 2), fetchData = _e[0], _f = _e[1], loading = _f.loading, data = _f.data, fetchMore = _f.fetchMore;
    var _g = __read(React.useState(null), 2), _data = _g[0], setData = _g[1];
    var _h = __read(React.useState(null), 2), load = _h[0], setLoad = _h[1];
    var _j = __read(React.useState(false), 2), _loading = _j[0], setLoading = _j[1];
    var _k = __read(React.useState(false), 2), loadingMore = _k[0], setLoadingMore = _k[1];
    var _l = __read(React.useState(null), 2), allData = _l[0], setAllData = _l[1];
    var _m = __read(React.useState(false), 2), loaded = _m[0], setLoaded = _m[1];
    var _o = __read(React.useState(false), 2), open = _o[0], setOpen = _o[1];
    var _p = __read(React.useState(0), 2), changes = _p[0], setChanges = _p[1];
    var _q = __read(React.useState(1), 2), page = _q[0], setPage = _q[1];
    var _r = __read(React.useState(null), 2), search = _r[0], setSearch = _r[1];
    var _s = __read(React.useState(Date.now()), 2), keyIndex = _s[0], setKeyIndex = _s[1];
    var langForm = useTranslation(KEYS.FORM);
    var _t = __read(React.useState(null), 2), tempVal = _t[0], setTempVal = _t[1];
    var classes = useStyles();
    var classes1 = useInputStyles();
    var classesFormik = useStylesFormik();
    var _u = __read(useField(inputProps), 2), field = _u[0], _v = _u[1], error = _v.error, touched = _v.touched;
    var touchedError = touched && error;
    var observer = React.useRef();
    var lastElementRef = React.useCallback(function (node) {
        if (loading)
            return;
        if (observer.current)
            observer.current.disconnect();
        observer.current = new IntersectionObserver(function (entries) {
            var _a;
            if (entries[0].isIntersecting && allData && ((_a = allData[Object.keys(allData)[0]]) === null || _a === void 0 ? void 0 : _a.hasMore)) {
                setPage(page + 1);
                setLoadingMore(true);
            }
        });
        if (node)
            observer.current.observe(node);
    }, [loading, allData && ((_a = allData[Object.keys(allData)[0]]) === null || _a === void 0 ? void 0 : _a.hasMore)]);
    // console.log('=========', data, query);
    var onChange = function (e, _value) {
        var r = [];
        for (var _i = 2; _i < arguments.length; _i++) {
            r[_i - 2] = arguments[_i];
        }
        if (_value && _value.content)
            return onPreventEvent(e);
        var value = JSON.parse(JSON.stringify(_value));
        if (props.onChange &&
            value &&
            ((autocompleteProps && autocompleteProps.multiple) || (value._id && !value.content))) {
            props.onChange.apply(props, __spread([{
                    target: __assign(__assign({}, inputProps), { value: (autocompleteProps && autocompleteProps.multiple && value) ||
                            (autocompleteProps && autocompleteProps.mapValue && autocompleteProps.mapValue(value)) ||
                            (value && value._id) }),
                }, value], r));
        }
        else {
            if (props.onClear)
                props.onClear({
                    target: __assign(__assign({}, inputProps), { value: value || '' }),
                }, value || '');
        }
        setTempVal(value);
    };
    var onKeyUp = function (e) {
        if (e.keyCode === 13) {
            if (timeout)
                clearTimeout(timeout);
            timeout = setTimeout(loadData);
            return onPreventEvent(e);
        }
    };
    var onScroll = function (e) {
        var key = allData && Object.keys(allData)[0];
        // console.log('onScroll', key);
        if (e.target.scrollHeight - e.target.parentNode.scrollHeight > 0 &&
            e.target.scrollHeight - e.target.parentNode.scrollHeight - 10 < e.target.scrollTop) {
            if (allData && allData[key] && allData[key].count && allData[key].count <= allData[key].data.length) {
                console.warn('no more data');
                return;
            }
            if (timeout)
                clearTimeout(timeout);
            timeout = setTimeout(function () {
                setPage(page + 1);
                loadData(search, { page: page + 1 });
            }, 1000);
        }
    };
    var onInputChange = function (e) {
        var searchText = (e.target.value || '').toLowerCase().trim();
        if (searchText === search) {
        }
        else {
            if (!searchText)
                searchText = null;
            setSearch(searchText);
            setPage(1);
            if (timeout)
                clearTimeout(timeout);
            timeout = setTimeout(function () {
                loadData(searchText);
            }, TIME_OUT_INPUT);
            // if (inputProps.onChange) inputProps.onChange(e);
        }
    };
    React.useEffect(function () {
        if (!props.loadOnSearch)
            loadData();
        return function () {
            loadingSync = null;
            lastCallback = null;
            if (timeout)
                clearTimeout(timeout);
        };
    }, [open, props.refresh]);
    React.useEffect(function () {
        loadData();
    }, [fetchMore, page]);
    React.useEffect(function () {
        if (loaded) {
            setChanges(changes + 1);
        }
        if (!isClient && data) {
            checkNewData(JSON.parse(JSON.stringify(data)));
        }
        return function () { };
    }, [open, data]);
    var checkNewData = function (data) {
        if (data) {
            var key_1 = Object.keys(data)[0];
            var existData = resetOnNewRequest ? [] : allData && allData[key_1];
            var newData = JSON.parse(JSON.stringify(data || {}));
            var _newData = newData[key_1];
            if (existData &&
                Array.isArray(existData.data) &&
                Array.isArray(_newData.data) &&
                existData.data.map(function (e) { return e._id; }).join('_') === _newData.data.map(function (e) { return e._id; }).join('_')) {
                return console.warn('same data');
            }
            if (existData && _newData.count === existData.count) {
                var loaded_1 = _newData.data || _newData;
                var existLoaded_1 = loaded_1.map(function (e) { return e._id; });
                var existDataList = (existData.data || existData).filter(function (e) { return !existLoaded_1.includes(e._id); });
                var newV = __spread(existDataList, loaded_1);
                if (!_newData.data) {
                    newData[key_1] = {
                        data: newV,
                        count: _newData.count,
                    };
                }
                else {
                    _newData.data = newV;
                }
            }
            var _data_1 = JSON.parse(JSON.stringify(newData || {}));
            setTimeout(function () { return __awaiter(void 0, void 0, void 0, function () {
                var e_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            //Update Apollo Cache
                            return [4 /*yield*/, client.writeQuery({
                                    query: query,
                                    data: _data_1,
                                })];
                        case 1:
                            //Update Apollo Cache
                            _a.sent();
                            return [3 /*break*/, 3];
                        case 2:
                            e_1 = _a.sent();
                            console.log(e_1);
                            return [3 /*break*/, 3];
                        case 3: return [2 /*return*/];
                    }
                });
            }); }, 1000);
            setAllData(_data_1);
        }
    };
    var loadData = function (searchMain, filters) { return __awaiter(void 0, void 0, void 0, function () {
        var variablesQ, searchText, currentTime, res, res, _a, _b, _c, _d, _e, res, _f, _g, _h, _j, res, _k, _l, _m, _o, e_2;
        var _p;
        return __generator(this, function (_q) {
            switch (_q.label) {
                case 0:
                    if (!open) {
                        // console.log('is not open');
                        return [2 /*return*/, undefined];
                    }
                    if (loadingSync) {
                        lastCallback = function () { return loadData(searchMain, filters); };
                        console.log('lastCallback');
                        return [2 /*return*/, null];
                    }
                    loadingSync = 1;
                    variablesQ = __assign({}, JSON.parse(JSON.stringify(variables || {})));
                    searchText = searchMain === null ? null : searchMain ? searchMain : search;
                    if (!filters && page)
                        filters = { page: page };
                    if (searchText || filters) {
                        if (!variablesQ)
                            variablesQ = {};
                        if (!variablesQ.filter)
                            variablesQ.filter = {};
                        Object.assign(variablesQ.filter, JSON.parse(JSON.stringify(filters || {})));
                        if (!variablesQ.filter.fields)
                            variablesQ.filter.fields = [];
                        if (searchText) {
                            (_p = variablesQ.filter.fields).push.apply(_p, __spread((props.filterFields || ['name']).map(function (name) { return (__assign(__assign({ name: name, isSearch: true }, ((props.skipModelValidationFilterFields || []).includes(name) ? { skipModelValidation: true } : {})), { value: searchText })); })));
                        }
                    }
                    _q.label = 1;
                case 1:
                    _q.trys.push([1, 13, 14, 16]);
                    setLoading(true);
                    currentTime = Date.now();
                    if (!(allData && allData[Object.keys(allData)[0]])) return [3 /*break*/, 7];
                    if (!isClient) return [3 /*break*/, 3];
                    return [4 /*yield*/, client.query({
                            query: query,
                            variables: variablesQ,
                        })];
                case 2:
                    res = _q.sent();
                    if (currentTime > lastTimeUpdated || !lastTimeUpdated) {
                        setData(res.data);
                        checkNewData(res.data);
                    }
                    return [3 /*break*/, 6];
                case 3:
                    _a = fetchMore;
                    if (!_a) return [3 /*break*/, 5];
                    _c = (_b = JSON).parse;
                    _e = (_d = JSON).stringify;
                    return [4 /*yield*/, fetchMore({ variables: variablesQ })];
                case 4:
                    _a = _c.apply(_b, [_e.apply(_d, [_q.sent()])]);
                    _q.label = 5;
                case 5:
                    res = _a;
                    if (currentTime > lastTimeUpdated || !lastTimeUpdated) {
                        checkNewData(res.data);
                    }
                    _q.label = 6;
                case 6: return [3 /*break*/, 12];
                case 7:
                    if (!isClient) return [3 /*break*/, 9];
                    _g = (_f = JSON).parse;
                    _j = (_h = JSON).stringify;
                    return [4 /*yield*/, client.query({
                            // fetchPolicy: 'no-cache', //"network-only",
                            query: query,
                            variables: variablesQ,
                        })];
                case 8:
                    res = _g.apply(_f, [_j.apply(_h, [_q.sent()])]);
                    if (currentTime > lastTimeUpdated || !lastTimeUpdated) {
                        setData(res.data);
                        checkNewData(res.data);
                    }
                    return [3 /*break*/, 12];
                case 9: return [4 /*yield*/, fetchData({ variables: variablesQ })];
                case 10:
                    _q.sent();
                    if (!fetchMore) return [3 /*break*/, 12];
                    _l = (_k = JSON).parse;
                    _o = (_m = JSON).stringify;
                    return [4 /*yield*/, fetchMore({ variables: variablesQ })];
                case 11:
                    res = _l.apply(_k, [_o.apply(_m, [_q.sent()])]);
                    if (currentTime > lastTimeUpdated || !lastTimeUpdated) {
                        checkNewData(res.data);
                    }
                    _q.label = 12;
                case 12:
                    lastTimeUpdated = currentTime;
                    setLoaded(true);
                    return [3 /*break*/, 16];
                case 13:
                    e_2 = _q.sent();
                    console.log('error--:', e_2);
                    return [3 /*break*/, 16];
                case 14: return [4 /*yield*/, checkIsDevEnv()];
                case 15:
                    _q.sent();
                    setLoading(false);
                    setLoadingMore(false);
                    setLoad(null);
                    setKeyIndex(Date.now());
                    loadingSync = null;
                    if (lastCallback) {
                        setTimeout(lastCallback, TIME_OUT_INPUT);
                        lastCallback = null;
                    }
                    return [7 /*endfinally*/];
                case 16: return [2 /*return*/];
            }
        });
    }); };
    var optionsList = [];
    if (allData) {
        optionsList = JSON.parse(JSON.stringify(allData[Object.keys(allData)[0]] || [])); //clone, to not edit source
        if (mapResponse)
            mapResponse(optionsList);
        if (optionsList.data)
            optionsList = optionsList.data; // if we have data list with count
    }
    if (Array.isArray(options) && options.length) {
        var exist_1 = optionsList.map(function (e) { return e._id; });
        var optionsContent = options.filter(function (e) { return !exist_1.includes(e._id); });
        var existOptions_1 = __spread(optionsContent.map(function (e) { return e._id; }), optionsContent.map(function (e) { return e.origin; })).filter(function (e) { return e; });
        optionsList = __spread(optionsContent, optionsList.filter(function (e) { return !existOptions_1.includes(e._id); }));
    }
    optionsList = optionsList.filter(function (e) { return e; });
    // if (
    //   autocompleteProps.defaultValue &&
    //   autocompleteProps.defaultValue._id &&
    //   !optionsList.find((e) => e._id === autocompleteProps.defaultValue._id || autocompleteProps.defaultValue === e)
    // ) {
    //   // optionsList.splice(0, 0, autocompleteProps.defaultValue);
    // }
    // if (loading) {
    //   optionsList.push({
    //     _id: 'loading',
    //     content: () => (
    //       <Grid container justify={'center'}>
    //         <CircularIndeterminate size="1.5em" />
    //       </Grid>
    //     ),
    //   });
    // }
    var defaultRenderOption = function (option) {
        var remainProps = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            remainProps[_i - 1] = arguments[_i];
        }
        // renderOption: (option: FormikAutocompleteAsyncAddOption) =>
        //   (option.content && option.content(option)) ||
        //   (renderOption && renderOption(option)),
        // defaultRenderOption(option),
        if (option && option.content)
            return option.content(option);
        if (autocompleteProps.renderOption)
            return autocompleteProps.renderOption.apply(autocompleteProps, __spread([option || ''], remainProps));
        var label = autocompleteProps === null || autocompleteProps === void 0 ? void 0 : autocompleteProps.getOptionLabel(option || '');
        return (React.createElement("div", { style: { width: '100%' }, key: option._id }, label));
    };
    var _onBlur = function (e) {
        setSearch(null);
        if (props.onBlur) {
            props.onBlur(e);
        }
        if (canAddNew && !canAddNewV3 && props.onChange) {
            props.onChange(e);
        }
    };
    if (autocompleteProps.getOptionLabel) {
        var propsOptionLabel_1 = autocompleteProps.getOptionLabel;
        autocompleteProps.getOptionLabel = function (option) {
            var val = typeof option === 'string' ? option : propsOptionLabel_1(option) || '';
            var value = typeof val === 'string' ? val.toString() : val || '';
            return value;
        };
    }
    if (canAddNew && !autocompleteProps.filterOptions) {
        autocompleteProps.filterOptions = function (options, params) {
            var filtered = filter(options, params);
            // Suggest the creation of a new value
            if (canAddNewV1 && params.inputValue !== '') {
                if (!canAddNewV2 || (canAddNewV2 && !filtered.length)) {
                    filtered.push({
                        _id: params.inputValue,
                        title: params.inputValue,
                    });
                }
            }
            return filtered;
        };
    }
    if (canAddNew && canAddNewV3) {
        autocompleteProps.filterOptions = function (options, params) {
            var filtered = filter(options, params);
            if (params.inputValue !== '' && !filtered.filter(function (item) { return item.name === params.inputValue; }).length) {
                filtered.unshift({
                    _id: 'autocompleted',
                    name: params.inputValue,
                });
            }
            return filtered;
        };
    }
    var key = keyIndex;
    try {
        key =
            (changes > 2 &&
                ((allData &&
                    (allData[Object.keys(allData)[0]].data || allData[Object.keys(allData)[0]])
                        .map(function (e) { return e.updatedAt; })
                        .join('_')) ||
                    (data &&
                        (data[Object.keys(data)[0]].data || data[Object.keys(data)[0]] || [])
                            .map(function (e) { return e.updatedAt; })
                            .join('_')))) ||
                keyIndex;
    }
    catch (e) { }
    var id = "popup-" + wrapTextToId(props.inputId || (typeof props.title === 'string' && props.title) || props.name);
    var ListboxComponentI = function (props) { return React.createElement(ListboxComponent, __assign({}, props, { id: id, loading: loading || _loading })); };
    var autocompletePropsMore = {
        ListboxComponent: ListboxComponentI,
    };
    if (autocompleteProps && autocompleteProps.ListboxComponent) {
        autocompletePropsMore.ListboxComponent = function (p) {
            return autocompleteProps.ListboxComponent(__assign(__assign({}, p), { id: id, loading: loading || _loading }));
        };
    }
    autocompleteProps.ListboxProps = __assign(__assign(__assign({}, (autocompleteProps.ListboxProps || {})), { lastElementRef: lastElementRef,
        loadingMore: loadingMore }), ((loadOnScroll && { onScroll: onScroll }) || {}));
    var ListboxProps = Object.assign(__assign(__assign({ id: id }, autocompleteProps.ListboxProps), { key: key }));
    return (React.createElement(Autocomplete, __assign({ id: id, autoHighlight: true, disableCloseOnSelect: props.v25, popupIcon: React.createElement(ExpandMoreIcon, null), noOptionsText: autocompletePropsMore.ListboxComponent({
            noOptions: (React.createElement(Grid, { container: true, direction: 'column', style: { padding: theme.spacing(1, 2) } },
                React.createElement(TextOverflow, null, autocompleteProps.noDataTitle || "No " + (autocompleteProps.titleOptions || 'options').toLowerCase()))),
            listStyle: {
                paddingLeft: 0,
            },
            optionFilter: (_b = autocompleteProps === null || autocompleteProps === void 0 ? void 0 : autocompleteProps.ListboxProps) === null || _b === void 0 ? void 0 : _b.optionFilter,
            optionFilterStyle: {
                margin: theme.spacing(0, 2.2),
            },
            v20: (_c = autocompleteProps === null || autocompleteProps === void 0 ? void 0 : autocompleteProps.ListboxProps) === null || _c === void 0 ? void 0 : _c.v20,
        }), ListboxProps: ListboxProps, ChipProps: {
            className: classes.chipRoot,
        } }, autocompleteProps, autocompletePropsMore, (!loadOnScroll && { ListboxComponent: ListboxComponentI }), { open: open, onOpen: function () {
            setOpen(true);
        }, onClose: function () {
            var e = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                e[_i] = arguments[_i];
            }
            if (props.v25 && e[1] === 'toggleInput')
                return;
            if (autocompleteProps && autocompleteProps.ListboxComponent) {
                setTimeout(function () { return setOpen(false); }, 300);
            }
            else {
                setOpen(false);
            }
            if (autocompleteProps.onClose)
                setTimeout(function () { return autocompleteProps.onClose.apply(autocompleteProps, __spread(e)); }, 300);
        }, onChange: onChange, options: optionsList, loading: loading, renderOption: defaultRenderOption, renderInput: function (params) {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
            var _m, _o, _p, _q, _r, _s;
            var inputProp = __assign(__assign({}, inputProps), field);
            if (canSearch) {
                inputProp = __assign(__assign({}, field), inputProps);
            }
            if (inputProps.help && !inputProp.endAdornment && !title) {
                Object.assign(inputProp, {
                    endAdornment: React.createElement(HelpIconComponent, { title: inputProps.help }),
                });
            }
            var style = { marginTop: 0, marginBottom: 0 };
            if (props.style)
                Object.assign(style, props.style);
            var p_props = {};
            var noTitle = true;
            // if (!inputProp.InputProps) inputProp.InputProps = {};
            // delete params.InputProps.value;
            if (inputProps.name !== 'certificates') {
                var customEndAdor = textFieldProps && textFieldProps.InputProps && textFieldProps.InputProps.endAdornment;
                var loader = (React.createElement(CircularProgress, { color: "inherit", style: { position: 'absolute', right: theme.spacing(0.5) }, size: 20 }));
                Object.assign(p_props, {
                    InputProps: __assign(__assign(__assign(__assign({}, params.InputProps), (props.label && { label: props.label })), (inputProps.help &&
                        !props.v19 && {
                        startAdornment: (React.createElement(React.Fragment, null,
                            React.createElement("span", { className: 'start-icon', style: { display: 'flex', alignItems: 'center' } },
                                React.createElement(HelpIconComponent, { title: [inputProps.help].filter(function (e) { return e; }).join('. ') })),
                            params.InputProps.startAdornment)),
                    })), { endAdornment: (React.createElement(React.Fragment, null, loading ? (loader) : (React.createElement(React.Fragment, null,
                            (React.createElement(Grid, { container: true, className: 'custom-ador', style: { width: 'initial', marginRight: theme.spacing(2) } }, customEndAdor)) || null,
                            params.InputProps.endAdornment)))) }),
                });
                if (inputProps.help)
                    noTitle = false;
            }
            var titlePlace = inputProp.placeholder || (typeof title === 'string' && title) || langForm.t('form_input_search_label');
            if (noTitle)
                Object.assign(params, { title: titlePlace });
            var errorText = touchedError || helperText;
            if (Array.isArray(errorText)) {
                errorText = errorText.map(function (e) { return e.email || (typeof e === 'object' ? 'Please fix me' : e); }).join('. ');
            }
            var onKeyDown = function (e) {
                var _a;
                var form = e.target.form;
                if (e.key === 'Enter') {
                    if (!props.v7 && props.onEnter && optionsList.length) {
                        props.onEnter(e, optionsList.filter(function (e) { return e; }));
                    }
                    else if (props.v7) {
                        if (props.onEnter && !optionsList[0] && field.value && field.value !== '') {
                            props.onEnter(e, field.value);
                        }
                    }
                    if (form) {
                        var els = Array.from(form.elements);
                        els = els.filter(function (elem) { return elem.className.includes('MuiInputBase-input'); });
                        var index = Array.prototype.indexOf.call(els, e.target);
                        if (!props.v8) {
                            (_a = els[index + 1]) === null || _a === void 0 ? void 0 : _a.focus();
                        }
                    }
                }
                // if (e.key === "Tab" && e.shiftKey) {
                // }
                // else if (e.key === "Tab") {
                // }
            };
            if (props.v8 && inputProp.onChange) {
                delete inputProp.onChange;
            }
            var isShrinked = !!((inputProp.value && props.v22 ? (_m = inputProp.value) === null || _m === void 0 ? void 0 : _m.name : (_o = inputProp.value) === null || _o === void 0 ? void 0 : _o._id) ||
                (typeof inputProp.value !== 'object' && inputProp.value) ||
                (Array.isArray(inputProp.value) && inputProp.value.length) ||
                params.inputProps.value ||
                (Array.isArray(tempVal) && tempVal.length) ||
                (props.v24 && inputProps.value === ''));
            var isTitleComponent = props.v1 || props.v14;
            var isSwitchToPlaceholder = (props.v23 && isShrinked) || titlePlace === langForm.t('form_input_search_label') || props.v16;
            if (props.label)
                p_props.label = props.label;
            return (React.createElement(React.Fragment, null,
                !props.withoutTitle ? React.createElement(InputTitle, __assign({}, props)) : null,
                React.createElement(BaseTextField, __assign({ type: "text", variant: "outlined" }, params, inputProp, { onBlur: _onBlur, onKeyDown: onKeyDown, onInput: onInputChange, onKeyUp: onKeyUp }, p_props, { InputLabelProps: {
                        shrink: isShrinked,
                    }, className: clsx(classesFormik.base, (_a = {}, _a[classesFormik.baseStartAdornment] = (_p = p_props === null || p_props === void 0 ? void 0 : p_props.InputProps) === null || _p === void 0 ? void 0 : _p.startAdornment, _a), (_b = {}, _b[classes.adorRoot] = props.v6, _b), (_c = {}, _c[classes1.adorRootV18] = props.v18, _c), (_d = {}, _d[classes1.adorRootV17] = props.v17, _d), (_e = {}, _e[classes1.adorRootV17WithValues] = Array.isArray(inputProps.value) && inputProps.value.length, _e), (_f = {}, _f[classes1.adorRootV17WithoutValues] = Array.isArray(inputProps.value) && !inputProps.value.length, _f), (_g = {}, _g[classes1.adorRootVLoading] = loading, _g), (_h = {}, _h[classes1.rootBase] = props.v5, _h[classes1.root2] = props.v1, _h), (_j = {}, _j[classes1.root4] = props.v4, _j), (_k = {}, _k[classesFormik.fixedLabel] = props.v26, _k), (_l = {}, _l[classesFormik.rootPrimaryStyle] = props.v21, _l), inputProp.className, params.className, (_q = params.InputProps) === null || _q === void 0 ? void 0 : _q.className, (_r = inputProp.InputProps) === null || _r === void 0 ? void 0 : _r.className), error: !!touchedError, helperText: errorText && React.createElement(TextOverflow, { v2: true }, errorText), style: __assign({ position: 'relative' }, style), required: false }, (!((_s = p_props.InputProps) === null || _s === void 0 ? void 0 : _s.label) && {
                    label: !(isTitleComponent || isSwitchToPlaceholder) ? titlePlace : '',
                }), { placeholder: isTitleComponent || isSwitchToPlaceholder ? titlePlace : '', title: '' }))));
        }, disabled: inputProps.disabled || (autocompleteProps && autocompleteProps.disabled), classes: __assign(__assign({}, (v17 && { root: classes.w100 })), { noOptions: classes.noOptions }), freeSolo: freeSolo, disableClearable: props.v20 })));
};
