var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { BaseListItem } from './index';
import { theme } from '../../../theme';
import { Grid, useMediaQuery } from '@material-ui/core';
import { TextOverflow } from '../../TextOverflow';
export var BaseListItemV1 = function (_a) {
    var _b;
    var headerColumns = _a.headerColumns, v1 = _a.v1, headerMobileSize = _a.headerMobileSize, props = __rest(_a, ["headerColumns", "v1", "headerMobileSize"]);
    var isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    var content = (React.createElement(Grid, { container: true, direction: "row", spacing: 2, style: { padding: theme.spacing(1) }, id: "base-table-item-" + (((_b = props.record) === null || _b === void 0 ? void 0 : _b._id) || '') }, props.childs
        .filter(function (e) { return e; })
        .map(function (e, index) {
        var _a, _b;
        return (React.createElement(React.Fragment, { key: e.name },
            isSmall && (headerColumns === null || headerColumns === void 0 ? void 0 : headerColumns.length) && index < headerColumns.length && (React.createElement(Grid, { item: true, xs: headerMobileSize || 3, container: true, alignItems: 'center' },
                React.createElement(TextOverflow, { style: { fontWeight: 600 }, variant: 'caption' }, ((_a = headerColumns[index]) === null || _a === void 0 ? void 0 : _a.name) || 'Please report an issue'))),
            React.createElement(Grid, __assign({ item: true, xs: headerMobileSize ? 12 - headerMobileSize : 9, md: ((_b = headerColumns[index]) === null || _b === void 0 ? void 0 : _b.size) || 3, container: true, alignItems: 'center' }, e.props), e.value)));
    })));
    if (v1)
        return content;
    return React.createElement(BaseListItem, __assign({}, props), content);
};
