var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React from 'react';
import { BaseList } from '../../../components/BasicList';
import { FetchUploads } from '../../../graphql';
import { UploadList } from '../../../components/Uploads/List';
import OrderQuoteDocumentItem from './DocumentItem';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../../i18n';
import { withNotificationStore } from '../../../components/Socket/Notifications/store';
import { withBaseGlobalSocketStoreActiveClient } from '../../../components/Socket/global/store';
import { TextOverflow } from '../../../components';
import { BaseUserAppTYpe } from '../../../stores/common';
import { inject } from 'mobx-react';
import ConsigneeDocumentTypes from './ConsigneDocumentTypes';
import { theme } from '../../../theme';
import { checkIfOrderDetailsIsHide, createAppTypeUser, getAllBaseUserAppTypeAccessForDocuments } from '../../../utils1';
import { Grid, useMediaQuery } from '@material-ui/core';
export var OrderQuoteDocumentsComponent = inject('store')(function (p) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
    var currentUser = p.store.accountStore.currentUser, props = __rest(p, ["store"]);
    var config = props.config, parent = props.parent, onChange = props.onChange, id = props.id;
    var langApp = useTranslation(KEYS.APP);
    var langForm = useTranslation(KEYS.FORM);
    var langOfferForm = useTranslation(KEYS.OFFER_FORM);
    var langToast = useTranslation(KEYS.TOAST);
    var _q = __read(React.useState(0), 2), updates = _q[0], setUpdates = _q[1];
    var isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    if (!config || !config.client)
        return null;
    var _onChange = function (e) {
        setUpdates(Date.now());
        setTimeout(function () { return onChange && onChange(e); }, 1000);
    };
    var _access = getAllBaseUserAppTypeAccessForDocuments(props.quote, currentUser);
    var isCarrierFromCustomerList = ((_d = (_c = (_b = (_a = props.quote) === null || _a === void 0 ? void 0 : _a.createdBy) === null || _b === void 0 ? void 0 : _b.company) === null || _c === void 0 ? void 0 : _c.companiesParents) === null || _d === void 0 ? void 0 : _d.length) &&
        props.quote.createdBy.company.companiesParents
            .map(function (e) { return e.toString(); })
            .includes((_h = (_g = (_f = (_e = props.quote.order) === null || _e === void 0 ? void 0 : _e.createdBy) === null || _f === void 0 ? void 0 : _f.company) === null || _g === void 0 ? void 0 : _g._id) === null || _h === void 0 ? void 0 : _h.toString());
    var isCarrier = currentUser.appType.includes(BaseUserAppTYpe.CARRIER);
    var isCustomer = currentUser.appType.includes(BaseUserAppTYpe.CUSTOMER);
    var hideForwarderToCarrier = isCustomer && p.store.accountStore.isForwarderEnvOnly && !isCarrierFromCustomerList;
    var hideForwarderToCustomer = isCarrier && ((_k = (_j = props.quote.order) === null || _j === void 0 ? void 0 : _j.forwarders) === null || _k === void 0 ? void 0 : _k.length) && !isCarrierFromCustomerList;
    var visibleToName = Object.values(BaseUserAppTYpe)
        .filter(function (e) {
        return ![
            BaseUserAppTYpe.WAREHOUSE,
            BaseUserAppTYpe.CONSIGNEE,
            BaseUserAppTYpe.SYSTEM,
            BaseUserAppTYpe.AFFILIATE,
        ].includes(e);
    })
        .filter(function (e) { return !(_access && !_access(e)); })
        .map(function (e) {
        if (hideForwarderToCarrier) {
            e = e === BaseUserAppTYpe.FORWARDER ? BaseUserAppTYpe.CARRIER : e;
        }
        if (hideForwarderToCustomer) {
            e = e === BaseUserAppTYpe.FORWARDER ? BaseUserAppTYpe.CUSTOMER : e;
        }
        var appUser = createAppTypeUser(e);
        return (appUser.firstName + appUser.lastName).toUpperCase();
    });
    var headerColumns = [
        {
            name: langForm.t('form_input_upload_type'),
            sortable: true,
            size: 2,
            style: { paddingLeft: isSmall ? 0 : theme.spacing(1) },
        },
        {
            name: langApp.t('app_file'),
            size: 3,
        },
        {
            name: langToast.t('toast_email_to_notify'),
            sortable: true,
            size: 3,
        },
        props.isPreparation && {
            name: langOfferForm.t('Preparation step'),
            namesStyle: {
                paddingLeft: theme.spacing(4),
            },
            size: 2,
        },
        ![BaseUserAppTYpe.SENDER, BaseUserAppTYpe.CONSIGNEE].find(function (e) { return currentUser.appType.includes(e); }) &&
            !props.isPreparation && {
            name: isSmall && langOfferForm.t('offer_form_document_visible'),
            names: visibleToName,
            namesStyle: {
                paddingLeft: theme.spacing(4),
            },
            size: 2,
            style: {
                display: 'flex',
                width: '100%',
                justifyContent: isSmall ? 'flex-start' : 'flex-end',
                paddingRight: theme.spacing(2.8),
                textTransform: !isSmall && 'uppercase',
            },
        },
        {
            name: langOfferForm.t('offer_form_document_actions'),
            size: 2,
            style: { display: 'flex', width: '100%', justifyContent: isSmall ? 'flex-start' : 'flex-end' },
        },
    ].filter(function (e) { return e; });
    return (React.createElement(Grid, { container: true, style: { paddingTop: theme.spacing(2) } },
        !props.isPreparation ? React.createElement(ConsigneeDocumentTypes, { order: props.quote.order }) : null,
        React.createElement(BaseList, { key: updates + "-" + props.state.newDocumentSigned, options: {
                client: config.client,
                query: FetchUploads,
                defaultFilter: props.defaultFilter ||
                    {
                        fields: [
                            { name: 'parent', value: parent },
                            { name: 'parentOffer', value: 1 },
                            {
                                name: 'canAccess',
                                value: true,
                            },
                            ((_m = (_l = props.quote) === null || _l === void 0 ? void 0 : _l.order.view_access) === null || _m === void 0 ? void 0 : _m.length) && {
                                name: 'view_access',
                                value: (_o = props.quote) === null || _o === void 0 ? void 0 : _o.order.view_access,
                            },
                            ((_p = props.quote) === null || _p === void 0 ? void 0 : _p.order) &&
                                checkIfOrderDetailsIsHide(p.store, props.quote.order) && {
                                name: 'access',
                                value: [BaseUserAppTYpe.SENDER, BaseUserAppTYpe.CONSIGNEE],
                            },
                        ].filter(function (e) { return e; }),
                    },
            }, config: __assign(__assign({ mainHeaderStyle: { padding: 0, paddingTop: 6 }, id: "" + (id || 'quote-uploads'), hideSearch: true, hideAllFilters: true, dontSaveFilters: true }, (!props.rowItem && {
                headerColumns: headerColumns,
            })), { title: '', rowContent: function (record, onChange) {
                    return props.rowItem ? (props.rowItem(record, onChange)) : (React.createElement(OrderQuoteDocumentItem, { isPreparation: props.isPreparation, lastActiveStep: props.lastActiveStep, folder: props.folder, updateList: _onChange, record: record, quote: props.quote, headerColumns: headerColumns, onChange: function () {
                            var e = [];
                            for (var _i = 0; _i < arguments.length; _i++) {
                                e[_i] = arguments[_i];
                            }
                            onChange.apply(void 0, __spread(e));
                            props.onChange && props.onChange.apply(props, __spread(e));
                        }, config: config }));
                }, noData: props.noData || (React.createElement(React.Fragment, null,
                    React.createElement(TextOverflow, { variant: 'subtitle1' }, langOfferForm.t('offer_form_document_no_data')))) }) }),
        !props.hideAdd && (React.createElement(UploadList, __assign({}, props, { isPreparation: props.isPreparation, lastActiveStep: props.lastActiveStep, isBatch: true, onChange: _onChange })))));
});
var OrderQuoteDocumentsConsumer = withNotificationStore(OrderQuoteDocumentsComponent);
export default withBaseGlobalSocketStoreActiveClient(function (p) {
    var client = p.client, props = __rest(p, ["client"]);
    return React.createElement(OrderQuoteDocumentsConsumer, __assign({}, props));
});
