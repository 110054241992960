var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Grid } from '@material-ui/core';
import BaseSearch from '../../BaseSearch';
import { withBaseListStore } from '../store';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../../i18n';
function Searching(_a) {
    var _b;
    var state = _a.state, config = _a.config, onSearch = _a.onSearch, content = _a.content, props = __rest(_a, ["state", "config", "onSearch", "content"]);
    var langForm = useTranslation(KEYS.FORM);
    var langOrderForm = useTranslation(KEYS.ORDER_FORM);
    var langOrderList = useTranslation(KEYS.ORDER_LIST);
    var matches = [
        {
            name: 'firstName',
            options: langForm.t('form_input_name_first_name'),
        },
        {
            name: 'lastName',
            options: langForm.t('form_input_name_last_name'),
        },
        {
            name: 'email',
            options: langForm.t('form_input_name_email'),
        },
        {
            name: 'amount',
            options: langOrderForm.t('create_order_cargo_amount'),
        },
        {
            name: 'orderId',
            options: langOrderForm.t('create_order_general_id'),
        },
        {
            name: 'address.city',
            options: langForm.t('form_input_name_address'),
        },
        {
            name: 'address.area',
            options: langForm.t('form_input_name_address'),
        },
        {
            name: 'name',
            options: langForm.t('form_input_name'),
        },
    ];
    var searchFilters = [];
    if ((_b = state.searchFieldsMatches) === null || _b === void 0 ? void 0 : _b.length) {
        searchFilters = state.searchFieldsMatches.map(function (e) { return e.text; }).filter(function (e) { return e; });
    }
    else {
        searchFilters = state.searchFields
            .map(function (e) { return (matches.find(function (f) { return f.name === e; }) || { options: '' }).options.toLowerCase(); })
            .filter(function (e) { return e; });
    }
    var onKeyUp = function (e) {
        if (e.keyCode === 13) {
            onSearch(e, 1);
        }
    };
    var hasSort = state.sortFields.length && !config.hideSort;
    var showSearch = searchFilters.length && !config.hideSearch;
    var propsGrid = { md: config.searchMDSize || 4 };
    if (props.v1) {
        Object.assign(propsGrid, { md: (!hasSort && (config.searchMDSize || 12)) || 4 });
    }
    if (!showSearch) {
        Object.assign(propsGrid, { md: 12 });
    }
    var mainHeader = (content && content(propsGrid.md)) || null;
    return showSearch ? (React.createElement(React.Fragment, null,
        mainHeader,
        React.createElement(Grid, __assign({ xs: 12, item: true, container: true, alignItems: 'center' }, propsGrid),
            React.createElement(BaseSearch, { style: { width: '100%' }, placeholder: langOrderList.t('order_list_filter_search_order').replace(/_X_/, searchFilters.join(', ') || ''), name: 'search', key: state.search, defaultValue: state.search, onChange: onSearch, onKeyUp: onKeyUp })))) : (mainHeader);
}
export default withBaseListStore(Searching);
