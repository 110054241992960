var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import { Upload } from '../../models/Upload';
import { Grid } from '@material-ui/core';
import { AddFormDialog } from './AddFormDialog';
import { getSnapshot } from 'mobx-state-tree';
import { inject } from 'mobx-react';
import { AddFormButton } from '../AddFormButton';
import { useTranslation } from 'react-i18next';
import { KEYS, i18n } from '../../i18n';
import { toast } from 'react-toastify';
import { uploadEvent } from '../../api/apolloClient';
import { NotifyOnUploadFinish, UpdateUpload } from '../../graphql';
import { checkIsDevEnv } from '../../utils';
var tempNewItem = JSON.parse(JSON.stringify(getSnapshot(Upload.create())));
export var UploadList = inject('store')(function (_a) {
    var config = _a.config, parent = _a.parent, _b = _a.folder, folder = _b === void 0 ? '' : _b, parent_collection_name = _a.parent_collection_name, onChange = _a.onChange, accountStore = _a.store.accountStore, record = _a.record, props = __rest(_a, ["config", "parent", "folder", "parent_collection_name", "onChange", "store", "record"]);
    if (props.isPreparation)
        parent_collection_name = 'orders';
    var _c = __read(React.useState(false), 2), loading = _c[0], setLoading = _c[1];
    var _d = __read(React.useState(0), 2), progress = _d[0], setProgress = _d[1];
    var langOfferForm = useTranslation(KEYS.OFFER_FORM);
    var isPlaceholder = !(record === null || record === void 0 ? void 0 : record.path);
    /*eslint-disable */
    React.useEffect(function () {
        Object.assign(tempNewItem, { parent: parent, folder: folder }, (parent_collection_name && { parent_collection_name: parent_collection_name }) || null);
        return function () {
            tempNewItem = JSON.parse(JSON.stringify(getSnapshot(Upload.create())));
        };
    }, []);
    /*eslint-enable */
    if (!accountStore.currentUser._id) {
        return null;
    }
    var onUploadFiles = function (_a) { return __awaiter(void 0, void 0, void 0, function () {
        var uploads, updates, input, newFile_1, _b, newFiles, _loop_1, i, e_1;
        var _id = _a._id, createdBy = _a.createdBy, updatedAt = _a.updatedAt, files = _a.files, document = __rest(_a, ["_id", "createdBy", "updatedAt", "files"]);
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    if (!files.length)
                        return [2 /*return*/, console.warn('no files')];
                    _c.label = 1;
                case 1:
                    _c.trys.push([1, 17, 18, 19]);
                    setLoading(true);
                    setProgress(0);
                    uploads = [];
                    if (!(record && !(files[0].file && isPlaceholder))) return [3 /*break*/, 8];
                    updates = {};
                    if (record === null || record === void 0 ? void 0 : record._id)
                        uploads.push(record._id);
                    if (!files[0].file) return [3 /*break*/, 3];
                    input = __assign(__assign(__assign(__assign(__assign(__assign({}, ['access', 'users', 'emails', 'sendEmail', 'type']
                        .filter(function (e) { return document && document[e]; })
                        .map(function (e) {
                        var _a;
                        return (_a = {}, _a[e] = document[e], _a);
                    })
                        .reduce(function (a, b) { return (__assign(__assign({}, a), b)); }, {})), (props.isBatch && { isBatch: props.isBatch })), (props.lastActiveStep && { orderPreparingStep: props.lastActiveStep })), ((record === null || record === void 0 ? void 0 : record._id) && { _id: record._id })), (_id && { _id: _id })), { type: document.type._id, folder: folder, isUserAction: true, file: files[0].file, needSign: !!files[0].needSign });
                    return [4 /*yield*/, uploadEvent(input, function (progress) { return setProgress(progress.toFixed(2)); })];
                case 2:
                    newFile_1 = _c.sent();
                    uploads.push(newFile_1._id);
                    updates = __assign({}, ['path', 'name', 'mimeType', 'needSign', 'encoding', 'size']
                        .map(function (e) {
                        var _a;
                        return (_a = {}, _a[e] = newFile_1[e], _a);
                    })
                        .reduce(function (a, b) { return (__assign(__assign({}, a), b)); }, {}));
                    return [3 /*break*/, 4];
                case 3:
                    if (files[0].path === record.path) {
                        updates = {
                            needSign: !!files[0].needSign,
                        };
                    }
                    _c.label = 4;
                case 4:
                    _b = config;
                    if (!_b) return [3 /*break*/, 6];
                    return [4 /*yield*/, config.client.mutate({
                            mutation: UpdateUpload,
                            variables: {
                                input: __assign(__assign(__assign({ _id: record._id }, ['access', 'emails', 'users', 'sendEmail', 'type']
                                    .map(function (e) {
                                    var _a;
                                    return (_a = {}, _a[e] = document[e], _a);
                                })
                                    .reduce(function (a, b) { return (__assign(__assign({}, a), b)); }, {})), { type: document.type._id, folder: folder }), updates),
                            },
                        })];
                case 5:
                    _b = (_c.sent());
                    _c.label = 6;
                case 6:
                    _b;
                    return [4 /*yield*/, checkIsDevEnv()];
                case 7:
                    _c.sent();
                    onChange && onChange(null);
                    return [3 /*break*/, 14];
                case 8:
                    newFiles = [];
                    _loop_1 = function (i) {
                        var input, item, keys, newFile;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    input = Object.assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, ['access', 'users', 'emails', 'sendEmail', 'type', '_id']
                                        .filter(function (e) { return document && document[e]; })
                                        .map(function (e) {
                                        var _a;
                                        return (_a = {}, _a[e] = document[e], _a);
                                    })
                                        .reduce(function (a, b) { return (__assign(__assign({}, a), b)); }, {})), { type: document.type._id, parent: parent }), (parent_collection_name && { parent_collection_name: parent_collection_name })), { folder: folder }), ((record === null || record === void 0 ? void 0 : record._id) && { _id: record._id })), (_id && { _id: _id })), { isUserAction: true }), (props.isBatch && { isBatch: props.isBatch })), (props.lastActiveStep && { orderPreparingStep: props.lastActiveStep })), { file: files[i].file, needSign: !!files[i].needSign }));
                                    item = void 0;
                                    keys = [
                                        '_id',
                                        'isUserAction',
                                        'updatedAt',
                                        'size',
                                        'access',
                                        'name',
                                        'parent_collection_name',
                                        'parent',
                                        'path',
                                        'isAssigned',
                                        'createdBy',
                                        'type',
                                    ];
                                    return [4 /*yield*/, uploadEvent(input, function (progress) {
                                            setProgress(((i / files.length) * progress).toFixed(2));
                                        })];
                                case 1:
                                    newFile = _a.sent();
                                    item = __assign({}, keys.map(function (e) {
                                        var _a;
                                        return (_a = {}, _a[e] = newFile[e], _a);
                                    }).reduce(function (a, b) { return (__assign(__assign({}, a), b)); }, {}));
                                    if (item.createdBy && !item.createdBy._id)
                                        item.createdBy = { _id: item.createdBy._id };
                                    uploads.push(item._id);
                                    files[i]._uid = item._id;
                                    return [2 /*return*/];
                            }
                        });
                    };
                    i = 0;
                    _c.label = 9;
                case 9:
                    if (!(i < files.length)) return [3 /*break*/, 12];
                    return [5 /*yield**/, _loop_1(i)];
                case 10:
                    _c.sent();
                    _c.label = 11;
                case 11:
                    i++;
                    return [3 /*break*/, 9];
                case 12: return [4 /*yield*/, checkIsDevEnv()];
                case 13:
                    _c.sent();
                    onChange && onChange(newFiles);
                    _c.label = 14;
                case 14:
                    if (!(props.isBatch && uploads.length)) return [3 /*break*/, 16];
                    return [4 /*yield*/, (config === null || config === void 0 ? void 0 : config.client.mutate({
                            mutation: NotifyOnUploadFinish,
                            variables: {
                                input: {
                                    uploads: uploads,
                                },
                            },
                        }))];
                case 15:
                    _c.sent();
                    _c.label = 16;
                case 16:
                    toast.success(i18n
                        .getResource(i18n.language, KEYS.TOAST, 'toast_items_saved')
                        .replace(/_X_/, i18n.getResource(i18n.language, KEYS.APP, 'main_file')));
                    return [3 /*break*/, 19];
                case 17:
                    e_1 = _c.sent();
                    console.log(e_1);
                    toast.error(e_1.message);
                    return [3 /*break*/, 19];
                case 18:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 19: return [2 /*return*/];
            }
        });
    }); };
    var onFresh = function () { return onChange && onChange(null); };
    return (React.createElement(Grid, { container: true, alignItems: 'center', style: { position: 'relative' } },
        React.createElement(AddFormDialog, __assign({}, props, { loading: loading, progress: progress, config: config, parent_collection_name: parent_collection_name, onFresh: onFresh, onChange: onUploadFiles, record: record, actionInit: props.actionInit ||
                (function (onShowModal) { return (React.createElement(AddFormButton, { onClick: onShowModal, title: langOfferForm.t('offer_form_document_add') })); }) }))));
});
