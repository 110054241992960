var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import { inject, observer, useObserver } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { FormikTextField } from '../../..';
import { Formik, Form } from 'formik';
import { getSnapshot } from 'mobx-state-tree';
import { canEdit } from '../utils';
import { useFormStyles, useSectionStyles } from '../../../../styles';
import { KEYS } from '../../../../i18n';
import * as Yup from 'yup';
export var OrderTemperatureForm = inject('store')(observer(React.forwardRef(function (_props, ref) {
    var store = _props.store, handleChange = _props.handleChange;
    var order = store.orderStore.order;
    var initValues = JSON.parse(JSON.stringify(getSnapshot(order)));
    var _a = __read(React.useState(initValues), 2), formValues = _a[0], setFormValues = _a[1];
    var classes = useSectionStyles();
    var classesName = useFormStyles();
    var langOrderForm = useTranslation(KEYS.ORDER_FORM);
    var langForm = useTranslation(KEYS.FORM);
    var langApp = useTranslation(KEYS.APP);
    console.log('OrderTemperatureForm', ref);
    var noEdit = !canEdit(order, store); //|| store.orderStore.isOrderUpdating;
    return useObserver(function () { return (React.createElement("div", { className: classes.section, style: { padding: 0 } },
        React.createElement(Formik, { innerRef: ref, initialValues: formValues, onSubmit: function (e) { return e; }, validationSchema: Yup.object(Object.assign({
                temperatureFrom: Yup.number()
                    .min(-1000)
                    .max(1000)
                    .typeError(langForm.t('form_input_validate_required'))
                    .required(langForm.t('form_input_validate_required'))
                    .test('less_than_to', langOrderForm.t('order_form_from_temparature_should_be_less_than_to'), function (value) {
                    return !this.parent.temperatureTo || value <= this.parent.temperatureTo;
                }),
                temperatureTo: Yup.number()
                    .min(-1000)
                    .max(1000)
                    .typeError(langForm.t('form_input_validate_required'))
                    .required(langForm.t('form_input_validate_required'))
                    .test('greater_than_from', langOrderForm.t('order_form_to_temparature_should_be_greater_than_from'), function (value) {
                    return !this.parent.temperatureFrom || this.parent.temperatureFrom <= value;
                }),
            })) }, function (formik) {
            return (React.createElement(Form, { id: 'temperature-form' },
                React.createElement(Grid, { container: true },
                    React.createElement(Grid, { item: true, xs: 6 },
                        React.createElement(FormikTextField, { disabled: noEdit, placeholder: langApp.t('app_from') + ", " + langApp.t('app_temperature_in_degree_celsius'), className: classesName.beginInput, variant: "outlined", onBlur: handleChange, name: 'temperatureFrom', value: formik.values.temperatureFrom })),
                    React.createElement(Grid, { item: true, md: 6 },
                        React.createElement(FormikTextField, { disabled: noEdit, placeholder: langApp.t('app_to') + ", " + langApp.t('app_temperature_in_degree_celsius'), className: classesName.endInput, variant: "outlined", onBlur: handleChange, name: 'temperatureTo', value: formik.values.temperatureTo })))));
        }))); });
})));
