import { OrderStatus } from '../../../models';
import { BaseUserAppTYpe } from '../../../stores/common';
import { USER_ROLE_TOOLS } from '../../../models/UserRoles';
export var canEdit = function (order, store, opt) {
    if (opt === void 0) { opt = {}; }
    try {
        return (!order._id ||
            (store &&
                (([BaseUserAppTYpe.CUSTOMER].find(function (t) { return store.accountStore.currentUser.appType.includes(t); }) &&
                    ((order.company && order.company._id === store.accountStore.currentCompany._id) ||
                        store.accountStore.currentUser.rolesV1.find(function (e) { var _a; return (_a = e.tools.general) === null || _a === void 0 ? void 0 : _a.includes(USER_ROLE_TOOLS.GENERAL_can_edit_company_data); }))) ||
                    (store.accountStore.currentUser.appType.includes(BaseUserAppTYpe.FORWARDER) &&
                        order.forwarders.find(function (o) { return o.company._id === store.accountStore.currentCompany._id; }))) &&
                [
                    OrderStatus.Draft,
                    OrderStatus.WaitingQuotes,
                    OrderStatus.HasQuotes,
                    OrderStatus.AcceptedQuote,
                    opt.inProgress && OrderStatus.ApprovedQuote,
                    OrderStatus.MANUFACTURING_DRAFT,
                    OrderStatus.MANUFACTURING_IN_PROCESS,
                    OrderStatus.MANUFACTURING_CONFIRMED,
                ]
                    .filter(function (e) { return e; })
                    .includes(order.status)));
    }
    catch (e) {
        return false;
    }
};
export var times = [
    { value: 30, label: "30min" },
    { value: 45, label: "45min" },
    { value: 60, label: "1hour" },
    { value: 90, label: "1hour_30min" },
    { value: 120, label: "2hours" },
    { value: 180, label: "3hours" },
    { value: 240, label: "4hours" },
    { value: 300, label: "5hours" },
    { value: 360, label: "6hours" },
    { value: 420, label: "7hours" },
    { value: 480, label: "8hours" },
];
