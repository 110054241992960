var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React from 'react';
import { Grid, IconButton, Typography } from '@material-ui/core';
import { BasicDialog, BASIC_DIALOG_ACTIONS, onPreventEvent } from '../../Dialog';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../../i18n';
import { inject, observer } from 'mobx-react';
import { toast } from 'react-toastify';
import { ACTION_CHANGE } from '../../BasicList';
import OrderRouteMenuItem from './OrderRoutesMenuItem';
import BaseTooltip from '../../BaseTooltip';
import { theme } from '../../../theme';
import { TextOverflow } from '../../TextOverflow';
import PaymentIcon from '@material-ui/icons/Payment';
import { CreateFastPayment } from '../../../graphql/fastPayment';
import { priceValue } from '../../PriceComponent';
import { dateViewFormat } from '../../DateView';
import { UpdateOrderQuote } from '../../../graphql';
import { OrderCurrency, OrderQuoteFastPaymentStatus, OrderRouteType } from '../../../models';
import { googleTagManager } from '../../../utils1';
var STATE;
(function (STATE) {
    STATE["BEFORE_REQUEST_1"] = "BEFORE_REQUEST_1";
    STATE["BEFORE_REQUEST_2"] = "BEFORE_REQUEST_2";
    STATE["AFTER_REQUEST"] = "AFTER_REQUEST";
})(STATE || (STATE = {}));
function OrderFastPaymentComponent(_a) {
    var _this = this;
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
    var config = _a.config, order = _a.order, store = _a.store, quote = _a.quote, props = __rest(_a, ["config", "order", "store", "quote"]);
    var langApp = useTranslation(KEYS.APP);
    var langOrderList = useTranslation(KEYS.ORDER_LIST);
    var _p = __read(React.useState(STATE.BEFORE_REQUEST_1), 2), state = _p[0], setState = _p[1];
    var openRefV1 = React.useRef();
    var disabled = !!((_b = quote === null || quote === void 0 ? void 0 : quote.fastPayment) === null || _b === void 0 ? void 0 : _b._id);
    var googleTagManagerEventBody = __assign(__assign(__assign({}, ((order &&
        [
            'sorting_countryPickup',
            'sorting_pickupAt',
            'sorting_countryDelivery',
            'sorting_deliveryAt',
            'sorting_totalWeight',
            'orderId',
        ]
            .filter(function (e) { return order[e]; })
            .map(function (e) {
            var _a;
            return (_a = {}, _a["order_" + e] = order[e], _a);
        })
            .reduce(function (a, b) { return (__assign(__assign({}, a), b)); }, {})) ||
        [])), ((quote &&
        ['amount', 'currency', 'startDate', 'endDate']
            .filter(function (e) { return quote[e]; })
            .map(function (e) {
            var _a;
            return (_a = {}, _a["offer_" + e] = quote[e], _a);
        })
            .reduce(function (a, b) { return (__assign(__assign({}, a), b)); }, {})) ||
        [])), { modal_form: state });
    React.useEffect(function () {
        if (props.v1 && (quote === null || quote === void 0 ? void 0 : quote.fastPaymentStatus) === OrderQuoteFastPaymentStatus.Available) {
            setTimeout(function () {
                var _a;
                setState(STATE.BEFORE_REQUEST_1);
                (_a = openRefV1 === null || openRefV1 === void 0 ? void 0 : openRefV1.current) === null || _a === void 0 ? void 0 : _a.click();
            }, 2000);
        }
    }, []);
    var onSubmit = function () { return __awaiter(_this, void 0, void 0, function () {
        var res, _a, e_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 3, , 4]);
                    res = void 0;
                    _a = quote &&
                        config;
                    if (!_a) return [3 /*break*/, 2];
                    return [4 /*yield*/, config.client.mutate({
                            mutation: CreateFastPayment,
                            variables: {
                                input: {
                                    order: order._id,
                                    quote: quote === null || quote === void 0 ? void 0 : quote._id,
                                },
                            },
                        })];
                case 1:
                    _a = (_b.sent());
                    _b.label = 2;
                case 2:
                    res = (_a).data.createFastPayment;
                    googleTagManager('event', "izi_form_fast_payment_accepted", __assign({ event_name: "fast_payment_accepted" }, googleTagManagerEventBody));
                    toast.success(langOrderList.t('order_list_fast_payment_modal_text_success'));
                    if (props.onChange)
                        props.onChange(order, ACTION_CHANGE.DELETED);
                    return [2 /*return*/, res];
                case 3:
                    e_1 = _b.sent();
                    toast.error(e_1.message);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var onOk = function (e) { return __awaiter(_this, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (state === STATE.BEFORE_REQUEST_1) {
                        setState(STATE.BEFORE_REQUEST_2);
                        googleTagManager('event', "izi_form_fast_payment_requested", __assign(__assign({ event_name: "fast_payment_requested" }, googleTagManagerEventBody), { modal_form: STATE.BEFORE_REQUEST_2 }));
                        throw BASIC_DIALOG_ACTIONS.WAIT;
                    }
                    onPreventEvent(e);
                    return [4 /*yield*/, onSubmit()];
                case 1:
                    res = _a.sent();
                    if (res) {
                        setState(STATE.AFTER_REQUEST);
                        if (props.onChange) {
                            props.onChange();
                        }
                        throw BASIC_DIALOG_ACTIONS.WAIT;
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var percentage = (((_d = (_c = order.createdBy) === null || _c === void 0 ? void 0 : _c.company) === null || _d === void 0 ? void 0 : _d.isFastPayment) && order.createdBy.company.fastPaymentPercentage) ||
        (((_g = (_f = (_e = order.forwarders[0]) === null || _e === void 0 ? void 0 : _e.company) === null || _f === void 0 ? void 0 : _f.settings) === null || _g === void 0 ? void 0 : _g.isDefaultFastPayment) &&
            order.forwarders[0].company.settings.defaultFastPaymentPercentage) ||
        0;
    var newPriceValue = (((100 - percentage) * (((_h = order === null || order === void 0 ? void 0 : order.latestQuote) === null || _h === void 0 ? void 0 : _h.amount) || (quote === null || quote === void 0 ? void 0 : quote.amount) || 0)) / 100).toFixed(2);
    var loading = (_j = order.transportations[0]) === null || _j === void 0 ? void 0 : _j.routes.find(function (e) { return e.type === OrderRouteType.Preferred; });
    var unLoading = order.transportations[(((_k = order.transportations) === null || _k === void 0 ? void 0 : _k.length) || 1) - 1].routes.find(function (e) { return e.type === OrderRouteType.Delivery; });
    var orderDetails = (loading.origin.address.country || '-') + " " + (loading.origin.address.postal || '-') + " " + (loading.startDate || '-') + " \u2014 " + (unLoading.origin.address.country || '-') + " " + (unLoading.origin.address.postal || '-') + " " + (unLoading.endDate || '-');
    var newPrice = priceValue({
        amount: newPriceValue,
        currency: ((_l = order === null || order === void 0 ? void 0 : order.latestQuote) === null || _l === void 0 ? void 0 : _l.currency) || (quote === null || quote === void 0 ? void 0 : quote.currency) || OrderCurrency.EUR,
    });
    var __html = langOrderList
        .t("order_list_fast_payment_modal_promotion")
        .replace(/__X__/g, priceValue({
        amount: ((_m = order === null || order === void 0 ? void 0 : order.latestQuote) === null || _m === void 0 ? void 0 : _m.amount) || (quote === null || quote === void 0 ? void 0 : quote.amount) || 0,
        currency: ((_o = order === null || order === void 0 ? void 0 : order.latestQuote) === null || _o === void 0 ? void 0 : _o.currency) || (quote === null || quote === void 0 ? void 0 : quote.currency) || OrderCurrency.EUR,
    }))
        .replace(/_X_/g, orderDetails);
    if (state === STATE.BEFORE_REQUEST_2) {
        __html = langOrderList.t("order_list_fast_payment_modal_offer_text").replace(/_X_/g, newPrice);
    }
    else if (state === STATE.AFTER_REQUEST) {
        __html = langOrderList.t('order_list_fast_payment_modal_text_success');
    }
    var dialogBody = (React.createElement(Grid, { container: true, style: { alignItems: 'center', justifyContent: 'center' } },
        React.createElement(Typography, { style: __assign({}, (state === STATE.AFTER_REQUEST && { textAlign: 'center' })), dangerouslySetInnerHTML: {
                __html: __html,
            } })));
    var title = state === STATE.BEFORE_REQUEST_1
        ? langOrderList.t('fast_payment_modal_title_before_request_1')
        : state === STATE.BEFORE_REQUEST_2
            ? langOrderList.t('fast_payment_modal_title_before_request_2')
            : langOrderList.t('fast_payment_modal_title');
    var dialogTitle = React.createElement(TextOverflow, { variant: 'h6' }, title);
    return ((config && (React.createElement(BasicDialog, { hidActionOkBth: state === STATE.AFTER_REQUEST, actionOkTitle: state === STATE.BEFORE_REQUEST_1
            ? langOrderList.t('fast_payment_accept_btx_before_request_1')
            : state === STATE.BEFORE_REQUEST_2
                ? langApp.t('app_request')
                : undefined, actionCancelTitle: state !== STATE.AFTER_REQUEST ? langOrderList.t('fast_payment_decline_before_request') : undefined, onOk: onOk, keepMounted: true, onClose: function () { return __awaiter(_this, void 0, void 0, function () {
            var res, _a, e_2;
            var _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (!props.v1) return [3 /*break*/, 6];
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, 4, , 5]);
                        _a = config;
                        if (!_a) return [3 /*break*/, 3];
                        return [4 /*yield*/, config.client.mutate({
                                mutation: UpdateOrderQuote,
                                variables: {
                                    input: {
                                        _id: ((_b = order === null || order === void 0 ? void 0 : order.latestQuote) === null || _b === void 0 ? void 0 : _b._id) || (quote === null || quote === void 0 ? void 0 : quote._id),
                                        fastPaymentStatus: OrderQuoteFastPaymentStatus.Cancelled,
                                    },
                                },
                            })];
                    case 2:
                        _a = (_c.sent());
                        _c.label = 3;
                    case 3:
                        res = _a;
                        return [3 /*break*/, 5];
                    case 4:
                        e_2 = _c.sent();
                        toast.error(e_2.message);
                        return [3 /*break*/, 5];
                    case 5:
                        if (props.onChange) {
                            props.onChange();
                        }
                        _c.label = 6;
                    case 6:
                        googleTagManager('event', 'izi_form_fast_payment_declined', __assign({ event_name: "fast_payment_was_declined" }, googleTagManagerEventBody));
                        return [2 /*return*/];
                }
            });
        }); }, body: dialogBody, title: dialogTitle, dialogProps: { fullWidth: true, maxWidth: 'sm' }, withoutWrapper: true, bodyStyle: {
            flexDirection: 'column',
            justifyContent: 'center',
        }, actionInit: function (_handleClickOpen) {
            var _a, _b;
            var handleClickOpen = function () {
                var e = [];
                for (var _i = 0; _i < arguments.length; _i++) {
                    e[_i] = arguments[_i];
                }
                googleTagManager('event', 'izi_form_fast_payment_requested', __assign({ event_name: "fast_payment_requested" }, googleTagManagerEventBody));
                _handleClickOpen.apply(void 0, __spread(e));
            };
            if (props.v1) {
                return React.createElement("span", { onClick: handleClickOpen, ref: openRefV1 });
            }
            var disabledTitle = langOrderList
                .t('order_list_request_fast_payment_disabled_tooltip')
                .replace(/__X__/g, dateViewFormat((_a = quote === null || quote === void 0 ? void 0 : quote.fastPayment) === null || _a === void 0 ? void 0 : _a.createdAt))
                .replace(/_X_/g, priceValue({
                amount: (_b = quote === null || quote === void 0 ? void 0 : quote.fastPayment) === null || _b === void 0 ? void 0 : _b.amount,
                currency: quote === null || quote === void 0 ? void 0 : quote.currency,
            }));
            var icon = (React.createElement(BaseTooltip, { title: disabled ? disabledTitle : title },
                React.createElement(IconButton, { onClick: !disabled && handleClickOpen, id: 'btn-order-archive' },
                    React.createElement(PaymentIcon, { style: __assign({}, (!disabled && { color: theme.palette.warning.light })) }))));
            if (props.label)
                return React.createElement(OrderRouteMenuItem, __assign({}, { icon: icon, label: title, handleClickOpen: !disabled && handleClickOpen }));
            return icon;
        } }))) ||
        null);
}
export default inject('store')(observer(OrderFastPaymentComponent));
