import React, { useEffect } from 'react';
import { withBaseListStore } from './store';
import { BaseListHeader } from './Header';
import { CircularProgress, Grid, Typography, Button, IconButton } from '@material-ui/core';
import ReplayIcon from '@material-ui/icons/Replay';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../i18n';
import BaseTooltip from '../BaseTooltip';
import { BaseListBody } from './Body';
import { BaseListFooter } from './Footer';
var BoxIcon = require('../assets/box.svg');
var BaseListComponent = function (_a) {
    var _b, _c, _d, _e, _f, _g;
    var loadData = _a.loadData, state = _a.state, config = _a.config, onResetFilter = _a.onResetFilter, isDefaultFilter = _a.isDefaultFilter, uselessFilter = _a.uselessFilter;
    var uselessFieldNames = ((_b = uselessFilter === null || uselessFilter === void 0 ? void 0 : uselessFilter.fields) === null || _b === void 0 ? void 0 : _b.map(function (ee) { return ee.name; })) || [];
    var isUsedAnyFilter = ((_e = (_d = (_c = state === null || state === void 0 ? void 0 : state.filter) === null || _c === void 0 ? void 0 : _c.fields) === null || _d === void 0 ? void 0 : _d.filter(function (e) { return !uselessFieldNames.includes(e.name); })) === null || _e === void 0 ? void 0 : _e.length) > 0;
    var langOrderList = useTranslation(KEYS.ORDER_LIST);
    var style = {
        width: '100%',
        maxWidth: "calc(100vw - " + (config.isSelectable ? 161 : 104) + "px)",
    };
    useEffect(function () {
        loadData();
    }, []);
    var theme = useTheme();
    if (useMediaQuery(theme.breakpoints.down('sm'))) {
        Object.assign(style, {
            maxWidth: "calc(100vw - " + (config.isSelectable ? 81 : 24) + "px)",
        });
    }
    return (React.createElement("div", { style: style, id: "base-table-" + (config.id || config.title), "data-total": state.count },
        (((_f = state.error) === null || _f === void 0 ? void 0 : _f.message) && React.createElement(Typography, { color: 'error' }, (_g = state.error) === null || _g === void 0 ? void 0 : _g.message)) || null,
        config.hideHeader ? null : React.createElement(BaseListHeader, { config: config }),
        state.loading ? (React.createElement(Grid, { container: true, justify: 'center', id: "base-table-" + config.title + "-loader" },
            React.createElement(CircularProgress, null))) : state.count === 0 || (state.count && !state.data.length) ? (isDefaultFilter() ? (React.createElement(Grid, { container: true, alignItems: 'center', direction: 'column', id: "base-table-" + config.title + "-no-data" },
            config.hideNoDataIcon ? null : config.noDataImage || React.createElement("img", { src: BoxIcon }),
            config.noData ? (config.noData) : (React.createElement(Typography, null, langOrderList.t('order_list_filter_no_data_general'))))) : (React.createElement(Grid, { container: true, alignItems: 'center', direction: 'column', id: "base-table-" + config.title + "-filters", style: { marginTop: theme.spacing(5) } },
            config.hideNoDataIcon ? null : config.noDataImage || React.createElement("img", { src: BoxIcon }),
            config.noData ? (config.noData) : (React.createElement(React.Fragment, null,
                React.createElement(Typography, { variant: 'subtitle1' }, langOrderList.t('order_list_filter_no_data_general')))),
            config.hideNoDataIconV1 || !isUsedAnyFilter
                ? null
                : (!config.hideNoDataIcon && (React.createElement(Button, { onClick: onResetFilter, variant: "outlined", color: 'primary', style: { marginTop: theme.spacing(1) } },
                    React.createElement(Typography, { variant: 'caption' }, langOrderList.t('order_list_filter_reset_filters'))))) || (React.createElement(BaseTooltip, { title: langOrderList.t('order_list_filter_reset_filters') },
                    React.createElement(IconButton, { onClick: onResetFilter },
                        React.createElement(ReplayIcon, { color: 'primary' }))))))) : (React.createElement(React.Fragment, null,
            React.createElement(BaseListBody, { config: config }),
            config.footer,
            React.createElement(BaseListFooter, { id: "base-table-" + config.title + "-footer", config: config }))),
        config.bottomTitle));
};
export default withBaseListStore(BaseListComponent);
