var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Grid, Typography } from '@material-ui/core';
var BoxIcon = require('./assets/box.svg');
export var NoData = function (_a) {
    var _b = _a.title, title = _b === void 0 ? '' : _b, noData = _a.noData, props = __rest(_a, ["title", "noData"]);
    return (React.createElement(Grid, { container: true, alignItems: 'center', direction: 'column', id: "base-table-" + title + "-no-data", style: __assign({}, props.wrapperStyle) },
        React.createElement("img", { src: BoxIcon }),
        noData ? noData : React.createElement(Typography, null, "No Data")));
};
