var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { types, flow, getSnapshot } from 'mobx-state-tree';
import { toast } from 'react-toastify';
import { Warehouse } from '../models/Warehouse';
import { AsyncActionStatus } from './common';
import { CreateWarehouse, DeleteWarehouse, FetchOrder, FetchOrderQuoteCalendarView, FetchWarehouse, FetchWarehouses, UpdateWarehouse, } from '../graphql';
import { i18n, KEYS } from '../i18n';
import { FetchHolidays } from '../graphql/holiday';
import { FetchWarehouseWorkingDays } from '../graphql/warehouse/warehouseWorkingDay';
import { FetchWarehouseWorkingDayPlans } from '../graphql/warehouse/warehouseWorkingDayPlan';
import { CheckIsAvailableForBook, FetchNoneAvailableDays, FetchTimeToBook } from '../graphql/warehouse/calendar';
import browserHistory from '../browserHistory';
import { CreateWarehouseNoneWorkingDayTime, DeleteWarehouseNoneWorkingDayTime, UpdateWarehouseNoneWorkingDayTime, } from '../graphql/warehouse/warehouseNoneWorkingDayTime';
import { toDateOnly } from '../utils';
export var WarehouseStoreFactory = function (_a) {
    var client = _a.client;
    return types
        .compose(AsyncActionStatus, types.model('WarehouseStore', {
        item: types.optional(types.maybeNull(Warehouse), function () { return Warehouse.create(); }),
        isUpdating: types.optional(types.boolean, false),
        isLoading: types.optional(types.boolean, false),
    }))
        .actions(function (self) { return ({
        loadOrderQuoteCalendarView: flow(function (_id) {
            var result, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        if (self.isLoading)
                            return [2 /*return*/];
                        self.isLoading = true;
                        return [4 /*yield*/, client.query({
                                query: FetchOrderQuoteCalendarView,
                                variables: {
                                    filter: { fields: [{ name: 'order', value: _id }] },
                                },
                            })];
                    case 1:
                        result = _a.sent();
                        return [2 /*return*/, result.data.orderQuoteView];
                    case 2:
                        e_1 = _a.sent();
                        console.log(e_1);
                        return [3 /*break*/, 4];
                    case 3:
                        self.isLoading = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        loadOrderCalendarView: flow(function (_id) {
            var result, e_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        if (self.isLoading)
                            return [2 /*return*/];
                        self.isLoading = true;
                        return [4 /*yield*/, client.query({
                                query: FetchOrder,
                                variables: { _id: _id },
                            })];
                    case 1:
                        result = _a.sent();
                        return [2 /*return*/, result.data.order];
                    case 2:
                        e_2 = _a.sent();
                        console.log(e_2);
                        return [3 /*break*/, 4];
                    case 3:
                        self.isLoading = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        isAvailableForBook: flow(function (filter) {
            var result, e_3;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, 3, 4]);
                        if (self.isLoading)
                            return [2 /*return*/];
                        self.isLoading = true;
                        return [4 /*yield*/, client.mutate({
                                mutation: CheckIsAvailableForBook,
                                variables: {
                                    filter: filter,
                                },
                            })];
                    case 1:
                        result = _b.sent();
                        return [2 /*return*/, (_a = result.data.isAvailableForBook) === null || _a === void 0 ? void 0 : _a.status];
                    case 2:
                        e_3 = _b.sent();
                        console.log(e_3);
                        toast.error(e_3.message);
                        throw e_3;
                    case 3:
                        self.isLoading = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        createWarehouse: flow(function (_a) {
            var result, e_4;
            var _id = _a._id, templateInfo = _a.templateInfo, company = _a.company, input = __rest(_a, ["_id", "templateInfo", "company"]);
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, 3, 4]);
                        self.isUpdating = true;
                        return [4 /*yield*/, client.mutate({
                                mutation: CreateWarehouse,
                                variables: {
                                    input: input,
                                },
                            })];
                    case 1:
                        result = _b.sent();
                        if (!result.data.createWarehouse.parent)
                            self.item &&
                                self.item.reset(__assign({}, result.data.createWarehouse));
                        toast.success(i18n
                            .getResource(i18n.language, KEYS.TOAST, 'toast_items_created')
                            .replace(/_X_/, i18n.getResource(i18n.language, KEYS.APP, 'user_app_type_warehouse')));
                        return [2 /*return*/, self.item];
                    case 2:
                        e_4 = _b.sent();
                        self.isUpdating = false;
                        toast.error(e_4.message);
                        throw e_4;
                    case 3:
                        self.isUpdating = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        updateWarehouse: flow(function (_a) {
            var _b, _id, createdBy, createdAt, updatedBy, generated, __typename, input_1, item_1, keys, result, e_5;
            var templateInfo = _a.templateInfo, company = _a.company, _input = __rest(_a, ["templateInfo", "company"]);
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 5, 6, 7]);
                        _b = JSON.parse(JSON.stringify(_input)), _id = _b._id, createdBy = _b.createdBy, createdAt = _b.createdAt, updatedBy = _b.updatedBy, generated = _b.generated, __typename = _b.__typename, input_1 = __rest(_b, ["_id", "createdBy", "createdAt", "updatedBy", "generated", "__typename"]);
                        self.isUpdating = true;
                        if (input_1.address) {
                            delete input_1.address.__typename;
                            if (input_1.address.location) {
                                delete input_1.address.location.__typename;
                            }
                        }
                        item_1 = self.item;
                        if (!_id) return [3 /*break*/, 1];
                        return [3 /*break*/, 3];
                    case 1:
                        if (!(!item_1 || !item_1._id)) return [3 /*break*/, 3];
                        return [4 /*yield*/, self.createWarehouse(input_1)];
                    case 2:
                        _c.sent();
                        _c.label = 3;
                    case 3:
                        keys = Object.keys(input_1);
                        if (!keys.filter(function (e) { return e; }).length || keys.filter(function (e) { return input_1[e] === item_1[e]; }).length === keys.length) {
                            self.isUpdating = false;
                            return [2 /*return*/, console.warn('No Changes')];
                        }
                        return [4 /*yield*/, client.mutate({
                                mutation: UpdateWarehouse,
                                variables: {
                                    input: Object.assign(__assign(__assign({ _id: _id || (item_1 && item_1._id) }, input_1), (input_1.manager && { manager: input_1.manager._id || input_1.manager }))),
                                },
                            })];
                    case 4:
                        result = _c.sent();
                        //
                        if (!result.data.updateWarehouse.parent)
                            self.item && self.item.reset(result.data.updateWarehouse);
                        toast.success(i18n
                            .getResource(i18n.language, KEYS.TOAST, 'toast_items_saved')
                            .replace(/_X_/, i18n.getResource(i18n.language, KEYS.APP, 'user_app_type_warehouse')));
                        return [2 /*return*/, self.item];
                    case 5:
                        e_5 = _c.sent();
                        toast.error(e_5.message);
                        return [3 /*break*/, 7];
                    case 6:
                        self.isUpdating = false;
                        return [7 /*endfinally*/];
                    case 7: return [2 /*return*/];
                }
            });
        }),
        createWarehouseNoneWorkingDayTime: flow(function (_a) {
            var input, result, e_6;
            var _id = _a._id, _input = __rest(_a, ["_id"]);
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, 3, 4]);
                        input = __assign({ warehouse: _input.ramp._id }, ['timeToSpend', 'name', 'description', 'time', 'date']
                            .map(function (key) {
                            var _a;
                            return (_a = {}, _a[key] = 'date' === key ? toDateOnly(_input[key]) : _input[key], _a);
                        })
                            .reduce(function (a, b) { return (__assign(__assign({}, a), b)); }, {}));
                        self.isUpdating = true;
                        return [4 /*yield*/, client.mutate({
                                mutation: CreateWarehouseNoneWorkingDayTime,
                                variables: {
                                    input: input,
                                },
                            })];
                    case 1:
                        result = _b.sent();
                        toast.success(i18n
                            .getResource(i18n.language, KEYS.TOAST, 'toast_items_created')
                            .replace(/_X_/, i18n.getResource(i18n.language, KEYS.APP, 'none_work_time')));
                        return [2 /*return*/, result.data.createWarehouseNoneWorkingDayTime];
                    case 2:
                        e_6 = _b.sent();
                        toast.error(e_6.message);
                        throw e_6;
                    case 3:
                        self.isUpdating = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        updateWarehouseNoneWorkingDayTime: flow(function (_a) {
            var input, result, e_7;
            var _id = _a._id, _input = __rest(_a, ["_id"]);
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!!_id) return [3 /*break*/, 2];
                        return [4 /*yield*/, self.createWarehouseNoneWorkingDayTime(_input)];
                    case 1: return [2 /*return*/, _b.sent()];
                    case 2:
                        _b.trys.push([2, 4, 5, 6]);
                        input = __assign({ _id: _id, warehouse: _input.ramp._id }, ['timeToSpend', 'name', 'description', 'time', 'date']
                            .map(function (key) {
                            var _a;
                            return (_a = {}, _a[key] = 'date' === key ? toDateOnly(_input[key]) : _input[key], _a);
                        })
                            .reduce(function (a, b) { return (__assign(__assign({}, a), b)); }, {}));
                        self.isUpdating = true;
                        return [4 /*yield*/, client.mutate({
                                mutation: UpdateWarehouseNoneWorkingDayTime,
                                variables: {
                                    input: input,
                                },
                            })];
                    case 3:
                        result = _b.sent();
                        toast.success(i18n
                            .getResource(i18n.language, KEYS.TOAST, 'toast_items_saved')
                            .replace(/_X_/, i18n.getResource(i18n.language, KEYS.APP, 'none_work_time')));
                        return [2 /*return*/, result.data.updateWarehouseNoneWorkingDayTime];
                    case 4:
                        e_7 = _b.sent();
                        toast.error(e_7.message);
                        return [3 /*break*/, 6];
                    case 5:
                        self.isUpdating = false;
                        return [7 /*endfinally*/];
                    case 6: return [2 /*return*/];
                }
            });
        }),
        deleteWarehouseNoneWorkingDayTime: flow(function (_a) {
            var result, e_8;
            var _id = _a._id, _input = __rest(_a, ["_id"]);
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, 3, 4]);
                        self.isUpdating = true;
                        return [4 /*yield*/, client.mutate({
                                mutation: DeleteWarehouseNoneWorkingDayTime,
                                variables: {
                                    _id: _id,
                                },
                            })];
                    case 1:
                        result = _b.sent();
                        toast.success(i18n
                            .getResource(i18n.language, KEYS.TOAST, 'toast_items_deleted')
                            .replace(/_X_/, i18n.getResource(i18n.language, KEYS.APP, 'none_work_time')));
                        return [2 /*return*/, result.data.deleteWarehouseNoneWorkingDayTime];
                    case 2:
                        e_8 = _b.sent();
                        toast.error(e_8.message);
                        return [3 /*break*/, 4];
                    case 3:
                        self.isUpdating = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        loadOne: flow(function (_id) {
            var result, e_9;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        if (self.item && self.item._id === _id) {
                            return [2 /*return*/, console.warn('already loaded')];
                        }
                        self.isLoading = true;
                        self.isLoadingWarehouse = true;
                        return [4 /*yield*/, client.query({
                                query: FetchWarehouse,
                                variables: {
                                    _id: _id,
                                },
                            })];
                    case 1:
                        result = _a.sent();
                        self.item = Warehouse.create(result.data.warehouse);
                        self.isLoading = false;
                        self.isLoadingWarehouse = false;
                        return [2 /*return*/, self.item];
                    case 2:
                        e_9 = _a.sent();
                        console.log(e_9);
                        browserHistory.push('/');
                        return [3 /*break*/, 4];
                    case 3:
                        self.isLoading = false;
                        self.isLoadingWarehouse = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        load: flow(function (filter) {
            var result, e_10;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        self.isLoading = true;
                        return [4 /*yield*/, client.query({
                                query: FetchWarehouses,
                                variables: {
                                    filter: filter,
                                },
                            })];
                    case 1:
                        result = _a.sent();
                        return [2 /*return*/, result.data.warehouses];
                    case 2:
                        e_10 = _a.sent();
                        console.log(e_10);
                        return [2 /*return*/, []];
                    case 3:
                        self.isLoading = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        clear: function () {
            if (self.item) {
                self.item && self.item.reset(getSnapshot(Warehouse.create()));
            }
        },
        deleteWarehouse: flow(function (input) {
            var item, snapshot, e_11;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        self.isUpdating = true;
                        item = self.item;
                        return [4 /*yield*/, client.mutate({
                                mutation: DeleteWarehouse,
                                variables: {
                                    _id: input._id,
                                },
                            })];
                    case 1:
                        _a.sent();
                        if (item) {
                            snapshot = getSnapshot(item);
                            item.reset(__assign({}, snapshot));
                        }
                        toast.success(i18n
                            .getResource(i18n.language, KEYS.TOAST, 'toast_items_deleted')
                            .replace(/_X_/, i18n.getResource(i18n.language, KEYS.APP, 'user_app_type_warehouse')));
                        return [3 /*break*/, 4];
                    case 2:
                        e_11 = _a.sent();
                        toast.error(e_11.message);
                        return [3 /*break*/, 4];
                    case 3:
                        self.isUpdating = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        loadHolidays: flow(function (filter) {
            var result, e_12;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        self.isLoading = true;
                        return [4 /*yield*/, client.query({
                                query: FetchHolidays,
                                variables: {
                                    filter: filter,
                                },
                            })];
                    case 1:
                        result = _a.sent();
                        return [2 /*return*/, result.data.holidays.data];
                    case 2:
                        e_12 = _a.sent();
                        console.log(e_12);
                        return [3 /*break*/, 4];
                    case 3:
                        self.isLoading = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        loadNoneAvailableForBooking: flow(function (filter) {
            var result, e_13;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        self.isLoading = true;
                        return [4 /*yield*/, client.query({
                                query: FetchNoneAvailableDays,
                                variables: {
                                    filter: filter,
                                },
                            })];
                    case 1:
                        result = _a.sent();
                        return [2 /*return*/, result.data.getNoneAvailableForBooking.data];
                    case 2:
                        e_13 = _a.sent();
                        console.log(e_13);
                        return [3 /*break*/, 4];
                    case 3:
                        self.isLoading = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        loadWarehouseWorkingDays: flow(function (filter) {
            var result, e_14;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        self.isLoading = true;
                        return [4 /*yield*/, client.query({
                                query: FetchWarehouseWorkingDays,
                                variables: {
                                    filter: filter,
                                },
                            })];
                    case 1:
                        result = _a.sent();
                        return [2 /*return*/, result.data.warehouseWorkingDays.data];
                    case 2:
                        e_14 = _a.sent();
                        console.log(e_14);
                        return [3 /*break*/, 4];
                    case 3:
                        self.isLoading = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        loadWarehouseWorkingDayPlans: flow(function (filter) {
            var result, e_15;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        self.isLoading = true;
                        return [4 /*yield*/, client.query({
                                query: FetchWarehouseWorkingDayPlans,
                                variables: {
                                    filter: filter,
                                },
                            })];
                    case 1:
                        result = _a.sent();
                        return [2 /*return*/, result.data.warehouseWorkingDayPlans.data];
                    case 2:
                        e_15 = _a.sent();
                        console.log(e_15);
                        return [3 /*break*/, 4];
                    case 3:
                        self.isLoading = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        loadTimeToBook: flow(function (filter) {
            var result, e_16;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        self.isLoading = true;
                        return [4 /*yield*/, client.query({
                                query: FetchTimeToBook,
                                variables: {
                                    filter: filter,
                                },
                            })];
                    case 1:
                        result = _a.sent();
                        return [2 /*return*/, result.data.loadAvailableTimeToBook.times];
                    case 2:
                        e_16 = _a.sent();
                        console.log(e_16);
                        return [3 /*break*/, 4];
                    case 3:
                        self.isLoading = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
    }); })
        .named('WarehouseStore');
};
