var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { ApolloLink } from 'apollo-link';
import { i18n, KEYS } from '../i18n';
import { browserStorage } from '../browserStorage';
import { googleTagManager } from '../utils1';
import { clearZendesk } from '../components/ReportIssue/zendesk';
import { clearRequest } from '../stores/utils';
export var ErrorCodes;
(function (ErrorCodes) {
    ErrorCodes["NOT_FOUND"] = "E0000 Not found";
    ErrorCodes["ACCESS_DENIED_ONLY_CARRIER"] = "E1000 Permission Denied! Only Carrier";
    ErrorCodes["ACCESS_DENIED_ONLY_CUSTOMER"] = "E1001 Permission Denied! Only Customer";
    ErrorCodes["ACCESS_DENIED_ONLY_FORWARDER"] = "E1001_1 Permission Denied! Only Forwarder";
    ErrorCodes["ACCESS_DENIED_ONLY_WAREHOUSE"] = "E1001_2 Permission Denied! Only Warehouse";
    ErrorCodes["ACCESS_DENIED"] = "E1002 Permission Denied";
    ErrorCodes["ACCESS_DENIED_1"] = "E1002_1 Permission Denied. User access source incorrect. Please contact to support";
    ErrorCodes["ACCESS_DENIED_2"] = "E1002_2 Permission Denied. User has restricted to access. Please contact to support";
    ErrorCodes["USER_NOT_VERIFIED"] = "E1003 User not verified, check your email";
    ErrorCodes["USER_NOT_ACTIVE"] = "E1004 User is not active";
    ErrorCodes["USER_NOT_EXIST"] = "E1004_1 User is not exist";
    ErrorCodes["USER_NOT_ACTIVE_1"] = "E1004_1 User got broken. missing company!";
    ErrorCodes["USER_NOT_ACTIVE_2"] = "E1004_2 Token expired";
    ErrorCodes["USER_NOT_ACTIVE_3"] = "E1004_3 User token expired";
    ErrorCodes["INPUT_INCORRECT_EMAIL_OR_PSW"] = "E1005 Incorrect email or password. Please try again.";
    ErrorCodes["INPUT_MISSING_REQUIRED_DATA"] = "E1006 missing required data";
    ErrorCodes["INPUT_REQUIRE_LOGIN"] = "E1007 You must be logged in";
    ErrorCodes["INPUT_ALREADY_LOGIN"] = "E1008 You are already logged in";
    ErrorCodes["ORDER_NO_AVAILABLE"] = "E1009 Order is not available";
    ErrorCodes["ORDER_NO_AVAILABLE_IN_USE"] = "E1010 Order is not available. Already in use for another carrier";
    ErrorCodes["TIME_INCORRECT"] = "E1011 end time should be bigger than start time";
    ErrorCodes["TIME_INCORRECT_1"] = "E1011_1 time range already exist";
    ErrorCodes["WAREHOUSE_LOCATION_EXIST"] = "E1012 location already has warehouse";
    ErrorCodes["BOOKING_INVALID"] = "E1013 not able to book";
    ErrorCodes["BOOKING_INVALID_HOLIDAYS"] = "E1013_1 not able to book, warehouse are on holiday";
    ErrorCodes["BOOKING_INVALID_NONE_PLANNING"] = "E1013_2 not able to book, warehouse not working";
    ErrorCodes["BOOKING_INVALID_ALREADY_IN_USE"] = "E1013_3 not able to book, warehouse is busy";
    ErrorCodes["BOOKING_INVALID_NON_WORKING_TIME"] = "E1013_4 Warehouse isn\u2019t available during chosen time period, please choose another time";
    ErrorCodes["BOOKING_INVALID_OUTSIDE_DAY"] = "E1013_4 not able to book, booking time not fit the working day";
    ErrorCodes["BOOKING_INVALID_ON_LUNCH"] = "E1013_4_2 not able to book on none booking period";
    ErrorCodes["BOOKING_INVALID_CRITICAL"] = "E1013_4_1 critical issue, got bocking on none available days";
    ErrorCodes["BOOKING_INVALID_CRITICAL_1"] = "E1013_5 can`t book with earlier dates";
    ErrorCodes["BOOKING_INVALID_CRITICAL_2"] = "E1013_6 start date cant be later than end date";
    ErrorCodes["BOOKING_INVALID_CRITICAL_3"] = "E1013_7 end date cant be earlier than start date";
    ErrorCodes["INVALID_HOLIDAY_PERIOD"] = "E1014_1 warehouse has orders on selected period";
    ErrorCodes["USER_IS_BUSY"] = "E1015_1 not able to remove user, has in progress order";
    ErrorCodes["FORWARDER_NO_CUSTOMER"] = "E1016_1 no customers";
    ErrorCodes["RECORD_NOT_ABLE_TO_EDIT"] = "E1017_1 not able to edit";
    ErrorCodes["INVOICE_ALREADY_SENT"] = "E1018_1 Invoice already sent";
    ErrorCodes["NOT_EXIST"] = "E1019_1 Not exist";
    ErrorCodes["REQUEST_SIGN_WAIT"] = "E1020_1 Request sign was requested yearlier, please request new one in 15min late";
    ErrorCodes["REQUEST_SIGN_NOT_ABLE"] = "E1020_2 Document is not able to sign";
    ErrorCodes["REQUEST_SIGN_NO_USERS"] = "E1020_3 No users to notify";
    ErrorCodes["CREATE_LOCATION_USER"] = "E1021_1 user already used";
    ErrorCodes["CREATE_LOCATION_USER_1"] = "E1021_2 user already exist";
    ErrorCodes["CREATE_ORDER_ROUTE_1"] = "E1022_2 route already exist";
    ErrorCodes["PUBLISH_ORDER_ROUTE"] = "E1023_1 routes are broken. You may need to refresh the page";
    ErrorCodes["PUBLISH_ORDER_BAD_LOCATION"] = "E1023_3 warehouse was deactivated, please set new warehouse for location";
    ErrorCodes["PUBLISH_ORDER_ROUTE_1"] = "E1023_2 routes dates are invalid";
    ErrorCodes["PUBLISH_ORDER_ROUTE_2"] = "E1023_3_2 Past dates aren't possible, please choose future dates.";
    ErrorCodes["PUBLISH_ORDER_CARGO"] = "E1023_2_1 missing cargo. You may need to refresh the page";
    ErrorCodes["PUBLISH_ORDER_TRANSPORTATION"] = "E1023_2_1 missing transportation. You may need to refresh the page";
    ErrorCodes["PUBLISH_ORDER_VEHICLE_TYPE"] = "E1023_3_1 missing vehicle types. You may need to refresh the page";
    ErrorCodes["PUBLISH_ORDER_LOADING_TYPE"] = "E1023_5 missing loading types. You may need to refresh the page";
    ErrorCodes["PUBLISH_ORDER_REQUIRED_DATA"] = "E1023_4 missing required data. You may need to refresh the page";
    ErrorCodes["PUBLISH_ALREADY_PUBLISHED"] = "E1023_6 Already published";
    ErrorCodes["UPLOAD_FILE_BAD"] = "E1024_1 error on upload the file, please try again";
    ErrorCodes["CREATED_FULL_FILMENT"] = "E1025_1 fullfilment already exist";
    ErrorCodes["DELETE_WAREHOUSE"] = "E1026_1 warehouse is using";
    ErrorCodes["CREATE_LOCATION_COMPANY"] = "E1027 company with this vatCode already exist";
    ErrorCodes["CREATE_LOCATION"] = "E1028 company location already exist on  selected address";
    ErrorCodes["CREATE_LOCATION1"] = "E1029 email already used on another company";
    ErrorCodes["COMPANY_VAT"] = "E1030 company vat already exist";
    ErrorCodes["USER_RATE_ALREADY_SENT"] = "E1031 User rate already sent";
    ErrorCodes["CHAT_CREATE"] = "E1032 Chat already exist";
    ErrorCodes["ITEM_CREATE"] = "E1033 already exist";
    ErrorCodes["ITEM_CREATE_V1"] = "E1034 no data to create";
    ErrorCodes["UPLOAD_MAX_SIZE"] = "E1035 Max file size available";
    ErrorCodes["CANT_SHARE_ORDER_WITH_USER_BASE_ON_APP_TYPE"] = "E1036 Can't share order with user base on app type";
    ErrorCodes["CANT_CREATE_UPLOAD_TYPE_WITH_SELECTED_NAME"] = "E1037 Can't create upload type with selected name";
    ErrorCodes["USER_ALREADY_AFFILIATE"] = "E1038 User already affiliate";
    ErrorCodes["TRADING_REQUEST_LOW_PRICE"] = "E1039 contra offer price is too low";
    ErrorCodes["INVOICE_MISSING_TEMPLATE"] = "E1040 invoice missing template";
    ErrorCodes["APPROVE_OFFER_ALREADY_HAS"] = "E1041_1 Already has approved offer";
    ErrorCodes["APPROVE_OFFER_NOT_AVAILABLE"] = "E1041_2 Quote not available for approve";
    ErrorCodes["INVALID_PROMO"] = "E1042_1 Invalid promo";
})(ErrorCodes || (ErrorCodes = {}));
var ActiveXObject = window.ActiveXObject;
var XMLHttpFactories = [
    function () {
        return new XMLHttpRequest();
    },
    function () {
        return new ActiveXObject('Msxml3.XMLHTTP');
    },
    function () {
        return new ActiveXObject('Msxml2.XMLHTTP.6.0');
    },
    function () {
        return new ActiveXObject('Msxml2.XMLHTTP.3.0');
    },
    function () {
        return new ActiveXObject('Msxml2.XMLHTTP');
    },
    function () {
        return new ActiveXObject('Microsoft.XMLHTTP');
    },
];
function createXMLHTTPObject() {
    var xmlhttp = false;
    for (var i = 0; i < XMLHttpFactories.length; i++) {
        try {
            xmlhttp = XMLHttpFactories[i]();
        }
        catch (e) {
            continue;
        }
        break;
    }
    return xmlhttp;
}
export var HTTP = function (_a) {
    var url = _a.url, isPath = _a.isPath;
    return new Promise(function (resolve, reject) {
        var xhr = createXMLHTTPObject();
        var headers = XmlRequest();
        xhr.onload = xhr.onerror = function () {
            try {
                if ([200, 201].includes(this.status)) {
                    resolve(isPath ? JSON.parse(this.responseText) : this.responseText);
                }
                else {
                    var e = this.responseText;
                    reject(e);
                }
            }
            catch (e) {
                reject(e);
            }
        };
        if (isPath) {
            xhr.open('GET', "" + window.APP_API_URL.replace(/graphql/gi, url), true);
        }
        else {
            xhr.open('GET', window.APP_API_URL.replace(/graphql/gi, '') + "source?url=" + url, true);
        }
        xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
        xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
        Object.keys(headers).forEach(function (key) { return xhr.setRequestHeader(key, headers[key]); });
        xhr.send(null);
    });
};
var XmlRequest = function () {
    var token = browserStorage.getItem('token');
    var izi_client_id = browserStorage.getItem('client_id');
    var booking_token = browserStorage.getItem('booking_token');
    var isManual = browserStorage.getItem('isManual');
    var tempId = browserStorage.getItem('temp-id');
    var testing = browserStorage.getItem('testing');
    return Object.assign({ 'auth-requests-from': location.origin }, { 'auth-requests-from-path': location.pathname }, (window.googleAnalyticsId && { googleAnalyticsId: window.googleAnalyticsId }) || {}, //
    (token && { Authorization: token }) || {}, //
    (booking_token && { booking_token: booking_token }) || {}, //
    (isManual && { isManual: isManual }) || {}, //
    (izi_client_id && { izi_client_id: izi_client_id }) || {}, //
    (testing && { 'izi-app': 'testing' }) || {}, (tempId && { tempId: tempId }) || {});
};
export var uploadEvent = function uploadEvent(data, callBack) {
    return new Promise(function (resolve, reject) {
        var formData = new FormData();
        Object.keys(data).forEach(function (key) {
            return formData.append(key, Array.isArray(data[key]) ? JSON.stringify(data[key]) : data[key]);
        });
        var xhr = createXMLHTTPObject();
        var headers = XmlRequest();
        googleTagManager('event', 'izi_upload', __assign({ event_name: "upload" }, Object.keys(data)
            .filter(function (e) { return !['file', 'emails'].includes(e); })
            .map(function (e) {
            var _a;
            return (_a = {}, _a["izi_upload_" + e] = data[e], _a);
        })
            .reduce(function (a, b) { return (__assign(__assign({}, a), b)); }, {})));
        xhr.upload.onprogress = function (event) {
            callBack(parseFloat(((event.loaded / event.total) * 100).toFixed(2)));
        };
        xhr.onload = xhr.onerror = function () {
            if ([200, 201].includes(this.status)) {
                resolve(JSON.parse(this.responseText));
            }
            else {
                var e_1 = JSON.parse(this.responseText);
                if (!e_1.message)
                    e_1.message = '';
                Object.keys(ErrorCodes).forEach(function (key) {
                    var _a, _b;
                    if (ErrorCodes[key] &&
                        (ErrorCodes[key] === e_1.message || ((_a = ErrorCodes[key]) === null || _a === void 0 ? void 0 : _a.split(' ')[0]) === ((_b = e_1.message) === null || _b === void 0 ? void 0 : _b.split(' ')[0]))) {
                        var message = e_1.message;
                        e_1.message = i18n.getResource(i18n.language, KEYS.ERRORS, key);
                        var details = message.split('._')[1];
                        if (details) {
                            e_1.message += ". " + details;
                        }
                    }
                });
                if (!e_1.message && e_1.err)
                    e_1.message = e_1.err;
                reject(e_1);
            }
        };
        xhr.open('POST', window.APP_API_URL.replace(/graphql/gi, '') + "upload", true);
        Object.keys(headers).forEach(function (key) { return xhr.setRequestHeader(key, headers[key]); });
        xhr.send(formData);
    });
};
function getCookie(name) {
    try {
        var value = "; " + document.cookie;
        var parts = value.split("; " + name + "=");
        if (parts.length === 2)
            return parts.pop().split(';').shift();
    }
    catch (e) { }
    return '';
}
export var apolloClient = function (REACT_APP_API_URL) {
    var link = createUploadLink({
        uri: REACT_APP_API_URL,
    });
    window.APP_API_URL = REACT_APP_API_URL;
    var addDatesLink = new ApolloLink(function (operation, forward) {
        var token = browserStorage.getItem('token');
        var booking_token = browserStorage.getItem('booking_token');
        var testing = browserStorage.getItem('testing');
        var tempId = browserStorage.getItem('temp-id');
        var izi_client_id = browserStorage.getItem('client_id');
        var isManual = browserStorage.getItem('isManual');
        var booking_access_token = browserStorage.getItem('booking_access_token');
        if (operation.variables) {
            clearRequest(operation.variables);
        }
        operation.setContext({
            headers: Object.assign({ 'auth-requests-from': location.origin }, { 'auth-requests-from-path': location.pathname }, izi_client_id && { izi_client_id: izi_client_id }, (booking_access_token && { booking_access_token: booking_access_token }) || {}, (process.env.REACT_APP_NONE_BRAND && { noneBrand: '1' }) || {}, (token && { Authorization: token }) || {}, (booking_token && { booking_token: booking_token }) || {}, (isManual && { isManual: isManual }) || {}, (window.googleAnalyticsId && { googleAnalyticsId: window.googleAnalyticsId }) || {}, //
            (getCookie('_ga') && { gaClientId: getCookie('_ga').split('GA1.1.')[1] }) || {}, //
            (testing && { 'izi-app': 'testing' }) || {}, (tempId && { tempId: tempId }) || {}),
        });
        return forward(operation).map(function (response) {
            var _a, _b, _c, _d;
            var e = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                e[_i - 1] = arguments[_i];
            }
            if (((_b = (_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.message) === null || _b === void 0 ? void 0 : _b.match(/bad token/i)) || ((_d = (_c = response === null || response === void 0 ? void 0 : response.data) === null || _c === void 0 ? void 0 : _c.message) === null || _d === void 0 ? void 0 : _d.match(new RegExp(ErrorCodes.INPUT_REQUIRE_LOGIN, 'gi')))) {
                clearZendesk();
                browserStorage.clearKeys(['API_VERSION', 'APP_VERSION']);
                // browserHistory.push('/');
                location.href = '/';
                return response;
            }
            if (response.errors && response.errors.map(function (e) { return e.message; }).includes(ErrorCodes.INPUT_REQUIRE_LOGIN)) {
                clearZendesk();
                browserStorage.clearKeys(['API_VERSION', 'APP_VERSION']);
                // browserHistory.push('/');
                location.href = '/';
            }
            else if (response.errors && response.errors.length) {
                if (response.errors.find(function (e) { return e.message.match('E11000'); })) {
                    var msg_1 = response.errors.find(function (e) { return e.message.match('E11000'); });
                    var key = ['email', 'vatCode', 'name'].find(function (e) { return msg_1.message.match(e); });
                    window.lastErrorKey = msg_1.message;
                    msg_1.message = i18n
                        .getResource(i18n.language, KEYS.TOAST, 'toast_items_exist')
                        .replace(/_X_/, msg_1.message.split(key + ":")[1].split('}')[0].replace(/\"/g, ''));
                }
                else if (response.errors.find(function (e) { return e.message.match('Validator failed for path'); })) {
                    response.errors.forEach(function (e) {
                        if (e.message.match('Validator failed for path')) {
                            window.lastErrorKey = e.message;
                            e.message = i18n
                                .getResource(i18n.language, KEYS.TOAST, 'toast_items_not_valid')
                                .replace(/_X_/, e.message.split(' ')[e.message.split(' ').length - 1]);
                        }
                    });
                }
                else {
                    response.errors.forEach(function (e) {
                        try {
                            if (e.message.match(/::_/)) {
                                var index = e.message.split('::_')[1].split('_::')[0];
                                var key = e.message.split('_::')[1];
                                e.message = i18n.getResource(i18n.language, index, key);
                                window.lastErrorKey = key;
                            }
                            else {
                                var findMatch_1 = false;
                                Object.keys(ErrorCodes).forEach(function (key) {
                                    var _a, _b;
                                    if (ErrorCodes[key] &&
                                        (ErrorCodes[key] === e.message || ((_a = ErrorCodes[key]) === null || _a === void 0 ? void 0 : _a.split(' ')[0]) === ((_b = e.message) === null || _b === void 0 ? void 0 : _b.split(' ')[0]))) {
                                        var message = e.message;
                                        e.message = i18n.getResource(i18n.language, KEYS.ERRORS, key);
                                        window.lastErrorKey = key;
                                        findMatch_1 = true;
                                        var details = message.split('._')[1];
                                        if (details) {
                                            e.message += ". " + details;
                                        }
                                    }
                                });
                                if (!findMatch_1)
                                    Object.keys(ErrorCodes).forEach(function (key) {
                                        if (e.message.match(ErrorCodes[key])) {
                                            var message = e.message;
                                            e.message = i18n.getResource(i18n.language, KEYS.ERRORS, key);
                                            window.lastErrorKey = key;
                                            var details = message.split('._')[1];
                                            if (details) {
                                                e.message += ". " + details;
                                            }
                                        }
                                    });
                                if (!e.message && e.err)
                                    e.message = e.err;
                            }
                        }
                        catch (e) {
                            console.log(e);
                            window.lastErrorKey = (e || '').toString();
                        }
                    });
                }
            }
            return response;
        });
    });
    var defaultOptions = {
        // watchQuery: {
        //   fetchPolicy: 'cache-and-network',
        //   errorPolicy: 'ignore',
        // },
        query: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'all',
        },
    };
    var s = {
        defaultOptions: defaultOptions,
        cache: new InMemoryCache({
            typePolicies: {
                Locations: {
                    keyFields: [],
                },
            },
        }),
        link: addDatesLink.concat(link),
    };
    return new ApolloClient(s);
};
