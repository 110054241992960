import { types } from 'mobx-state-tree';
import { BaseUserAppTYpe } from './types';
import { OrderCurrency } from '../../models';
import { CompanyPaymentTermModel } from '../../models/CompanyPaymentTerm';
import { Upload } from '../../models/Upload';
import { OrderLocationAddress } from '../../models/LocationAddress';
import { BaseCompanySettings } from './BaseCompanySettings';
export var CompanyState;
(function (CompanyState) {
    CompanyState[CompanyState["ORDER_ACCESS_RESTRICTION"] = 1] = "ORDER_ACCESS_RESTRICTION";
})(CompanyState || (CompanyState = {}));
var is_USA_APP = parseInt(process.env.REACT_APP_APP_FOR_USA || '0') === 1;
export var BaseCompany = types.model('BaseCompany', {
    _id: types.maybeNull(types.optional(types.string, '')),
    type: types.maybeNull(types.optional(types.enumeration('CUSTOMER', Object.keys(BaseUserAppTYpe)), function () { return BaseUserAppTYpe.CUSTOMER; })),
    name: types.maybeNull(types.optional(types.string, '')),
    invited: types.maybeNull(types.optional(types.string, '')),
    logo: types.maybeNull(Upload),
    settings: types.maybeNull(BaseCompanySettings),
    addressV1: types.maybeNull(types.optional(OrderLocationAddress, function () { return OrderLocationAddress.create(); })),
    paymentTerm: types.maybeNull(types.optional(CompanyPaymentTermModel, function () { return CompanyPaymentTermModel.create(); })),
    phone: types.maybeNull(types.optional(types.string, '')),
    companyCode: types.maybeNull(types.optional(types.string, '')),
    bank: types.maybeNull(types.optional(types.string, '')),
    bankCode: types.maybeNull(types.optional(types.string, '')),
    brandName: types.maybeNull(types.optional(types.string, '')),
    companyEmail: types.maybeNull(types.optional(types.string, '')),
    companyEmailName: types.maybeNull(types.optional(types.string, '')),
    p44Code: types.maybeNull(types.optional(types.string, '')),
    bankSwiftCode: types.maybeNull(types.optional(types.string, '')),
    bankAccountCode: types.maybeNull(types.optional(types.string, '')),
    isFastPayment: types.maybeNull(types.optional(types.boolean, false)),
    fastPaymentPercentage: types.maybeNull(types.optional(types.number, 0)),
    forwarderOrderMarkUpPercentage: types.maybeNull(types.optional(types.number, 0)),
    forwarderOrderMarkUpMinPrice: types.maybeNull(types.optional(types.number, 0)),
    forwarderOrderMarkUpMinPriceCurrency: types.maybeNull(types.optional(types.enumeration('USD', Object.keys(OrderCurrency)), is_USA_APP ? OrderCurrency.USD : OrderCurrency.EUR)),
    forwarderOrderMarkUpMaxPriceCurrency: types.maybeNull(types.optional(types.enumeration('USD', Object.keys(OrderCurrency)), is_USA_APP ? OrderCurrency.USD : OrderCurrency.EUR)),
    forwarderOrderMarkUpMaxPrice: types.maybeNull(types.optional(types.number, 0)),
    turnoverCurrency: types.maybeNull(types.optional(types.enumeration('USD', Object.keys(OrderCurrency)), is_USA_APP ? OrderCurrency.USD : OrderCurrency.EUR)),
    companiesParents: types.maybeNull(types.optional(types.array(types.string), [])),
    state: types.maybeNull(types.optional(types.array(types.number), [])),
    hasOffer: types.maybeNull(types.optional(types.number, 0)),
    orders: types.maybeNull(types.optional(types.array(types.string), [])),
    sync: types.maybeNull(types.optional(types.array(types.number), [])),
    turnover: types.maybeNull(types.union(types.null, types.undefined, types.string, types.number)),
    vatCode: types.maybeNull(types.optional(types.string, '')),
    createdAt: types.maybeNull(types.union(types.null, types.undefined, types.string)),
    updatedAt: types.maybeNull(types.union(types.null, types.undefined, types.string)),
});
