var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React from 'react';
import moment from 'moment';
import clsx from 'clsx';
import { Grid } from '@material-ui/core';
import { withCalendarStore } from '../store';
import { useMonthDayStyles } from './style';
import { toDateOnly } from '../../../utils';
import { DayHolidayDetails } from './DayHolidayDetails';
import { inject } from 'mobx-react';
import { CALENDAR_MODE } from '../types';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../../i18n';
import BaseTooltip from '../../BaseTooltip';
import MonthDayAll from './MonthDayAll';
import MonthDayLabel from './MonthDayLabel';
var DayOptions = React.lazy(function () { return import('./DayOptions'); });
export var MonthDay = inject('store')(withCalendarStore(function (_a) {
    var _b;
    var isCurrentMonth = _a.isCurrentMonth, onChangeMode = _a.onChangeMode, day = _a.day, config = _a.config, _c = _a.state, warehouse = _c.warehouse, ramps = _c.ramps, workingDays = _c.workingDays, holidays = _c.holidays, noneWorkingDayTime = _c.noneWorkingDayTime;
    var _d = __read(React.useState(false), 2), showPopUp = _d[0], setShowPopup = _d[1];
    var langApp = useTranslation(KEYS.APP);
    var className = useMonthDayStyles();
    var currentDay = toDateOnly(day);
    var currentDayMoment = moment(toDateOnly(day));
    var currentWeekDay = currentDayMoment.format('dddd');
    var _holidays = holidays.data.filter(function (e) {
        return moment(toDateOnly(e.start)).isSameOrBefore(currentDayMoment) &&
            moment(toDateOnly(e.end)).isSameOrAfter(currentDayMoment) &&
            e.warehouse._id === (warehouse === null || warehouse === void 0 ? void 0 : warehouse._id);
    });
    var allWarehouses = __spread(ramps.data.map(function (e) { return e._id; }), [warehouse === null || warehouse === void 0 ? void 0 : warehouse._id]);
    var _noneWorkingDayTime = noneWorkingDayTime.data.filter(function (e) {
        return e.date === toDateOnly(currentDayMoment) && allWarehouses.includes(e.warehouse._id);
    });
    var isAvailable = workingDays.data.find(function (e) { return e.day === currentWeekDay && e.maxTime && e.maxTime._id; }) &&
        !_holidays.length;
    var onClick = function (e) {
        onChangeMode(e, CALENDAR_MODE.day, day);
    };
    var onTogglePopup = function (v) { return setShowPopup(v); };
    var dayContent = (React.createElement(BaseTooltip, { title: langApp.t('calendar_focus_on_day') },
        React.createElement(MonthDayLabel, __assign({}, { onClick: onClick, day: day, currentDay: currentDay, isCurrentMonth: isCurrentMonth, isAvailable: isAvailable }))));
    return (React.createElement("td", { className: clsx(className.root, (_b = {},
            _b[className.disabled] = !isAvailable,
            _b[className.anotherMonthBackground] = !isCurrentMonth || !isAvailable,
            _b)), id: currentDay },
        React.createElement(Grid, { container: true, justify: 'space-between', alignItems: 'center', style: { position: 'absolute', top: 0 } },
            dayContent,
            React.createElement(DayOptions, { day: day })),
        React.createElement(Grid, { container: true, direction: 'column', justify: 'flex-start', style: __assign(__assign({ flexWrap: 'inherit', maxWidth: '100%' }, ((!showPopUp && { overflowY: 'auto' }) || {})), { maxHeight: 130, height: '100%', position: 'relative' }) },
            (_holidays.length && React.createElement(DayHolidayDetails, { warehouse: warehouse, holidays: _holidays })) || null,
            React.createElement(MonthDayAll, { ramps: ramps.data, dayContent: dayContent, day: day, config: config, onTogglePopup: onTogglePopup, noneWorkingDayTime: _noneWorkingDayTime }))));
}));
