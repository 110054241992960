var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React from 'react';
import { createStyles, Divider, Grid, useMediaQuery, useTheme, withStyles } from '@material-ui/core';
import { OrderRoutes } from '../OrderRoutes';
import IconButton from '@material-ui/core/IconButton';
import clsx from 'clsx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useQuoteStyles } from './styles';
import Collapse from '@material-ui/core/Collapse/Collapse';
import { onPreventEvent } from '../../../Dialog';
import { OrderQuoteFastPaymentStatus, OrderQuoteStatus, OrderStatus } from '../../../../models';
import { FetchOrder, FetchOrderQuoteDetails } from '../../../../graphql/order';
import { CircularIndeterminate } from '../../../CircularIndeterminate';
import { TextOverflow } from '../../../TextOverflow';
import { OrderQuoteActions } from '../OrderQuoteActions';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../../../i18n';
import { BaseUserAppTYpe } from '../../../../stores/common';
import OrderChannels from '../../../../pages/Chat/OrderChannels';
import { inject } from 'mobx-react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { theme } from '../../../../theme';
import OrderMessagesUnread from './OrderMessagesUnread';
import PreloadingComponent from '../../../PreloadingComponent';
import { a11yProps, TabPanel } from '../../../BaseTabs';
import OrderQuoteDocuments from '../../../../pages/Quotes/Documents';
import { StyledBadge } from '../../../StyledBadge';
import { getQueryParams, wrapTextToId } from '../../../../utils';
import { withOrderListItemStore } from '../Customer/storeListItem';
import { ACTION_CHANGE } from '../../../context.store.enums';
import BaseTooltip from '../../../BaseTooltip';
import { isReviewOffer } from '../../../../pages/Quotes/types';
import { USER_ROLE_TOOLS } from '../../../../models/UserRoles';
import { checkIfOrderDetailsIsHide } from '../../../../utils1';
import OrderPreparing from './OrderPreparing';
import { FetchUploadsCount } from '../../../../graphql';
import OrderLink from '../OrderLink';
var ExpandedAreaQuote = React.lazy(function () { return import('./ExpandedArea'); });
var TimeBookingTab = React.lazy(function () { return import('./TimeBookingTab'); });
var OrderTracking = React.lazy(function () { return import('../Customer/OrderQuoteItem/OrderTracking'); });
var OrderUserRating = React.lazy(function () { return import('../OrderUserRating'); });
var OrderFastPayment = React.lazy(function () { return import('../OrderFastPayment'); });
var OrderLoadingComponent = React.lazy(function () { return import('../../List/OrderGoodLoading'); });
var OrderQuoteInvoiceRequested = React.lazy(function () { return import('./OrderQuoteInvoiceRequested/index'); });
var IziCollapse = Collapse;
function OrderLoadingComponentTab(props) {
    return (React.createElement(PreloadingComponent, null,
        React.createElement(OrderLoadingComponent, __assign({}, props))));
}
var StyledTab = withStyles(function (theme) {
    return createStyles({
        root: {
            color: theme.palette.primary.main,
        },
        selected: {
            fontWeight: 700,
        },
    });
})(function (props) { return (React.createElement(Tab, __assign({ disableRipple: true }, props, (typeof (props.title || props['data-id'] || props.label) === 'string' && {
    id: wrapTextToId(props.title || props['data-id'] || props.label),
})))); });
var BookingTab = withStyles(function (theme) {
    var _a;
    return createStyles({
        root: {
            color: theme.palette.primary.main,
        },
        wrapper: (_a = {},
            _a[theme.breakpoints.up('md')] = {
                alignItems: 'flex-start',
            },
            _a),
        selected: {
            fontWeight: 700,
        },
    });
})(function (props) { return (React.createElement(Tab, __assign({ disableRipple: true }, props, (typeof (props.title || props['data-id'] || props.label) === 'string' && {
    id: wrapTextToId(props.title || props['data-id'] || props.label),
})))); });
var BaseOrderQuoteItemComponent = withOrderListItemStore(function (_a) {
    var _b, _c;
    var _this = this;
    var _d, _e, _f, _g, _h, _j;
    var store = _a.store, additionalContentBody = _a.additionalContentBody, config = _a.config, onDeleteItem = _a.onDeleteItem, canRedirect = _a.canRedirect, orderListItemProps = _a.orderListItemProps, props = __rest(_a, ["store", "additionalContentBody", "config", "onDeleteItem", "canRedirect", "orderListItemProps"]);
    var accountStore = store.accountStore;
    var currentUser = accountStore.currentUser, currentCompany = accountStore.currentCompany;
    var _k = __read(React.useState(0), 2), tab = _k[0], setTab = _k[1];
    var _l = __read(React.useState(false), 2), expanded = _l[0], setExpanded = _l[1];
    var _m = __read(React.useState(false), 2), loading = _m[0], setLoading = _m[1];
    var _o = __read(React.useState(''), 2), error = _o[0], setError = _o[1];
    var classes = useQuoteStyles();
    var langApp = useTranslation(KEYS.APP);
    var langMenu = useTranslation(KEYS.MENU);
    var langOrderList = useTranslation(KEYS.ORDER_LIST);
    var langOfferForm = useTranslation(KEYS.OFFER_FORM);
    var _p = __read(React.useState(1), 2), update = _p[0], setUpdate = _p[1];
    var order = orderListItemProps.state.record;
    var orderDetails = order && order.full ? order : null;
    React.useEffect(function () {
        var query = getQueryParams(window.location.href);
        if (query && (query.search === order._id || query.search === order.orderId)) {
            setTimeout(function () {
                handleExpandClick(new window.Event('click'));
            }, 500);
        }
    }, [order.orderId]);
    var isCustomerOnly = currentUser.appType.includes(BaseUserAppTYpe.CUSTOMER);
    var isCustomer = isCustomerOnly || currentUser.appType.includes(BaseUserAppTYpe.FORWARDER);
    var isForwarder = currentUser.appType.includes(BaseUserAppTYpe.FORWARDER);
    var isCarrier = currentUser.appType.includes(BaseUserAppTYpe.CARRIER);
    var openOrders = location.href.toLowerCase().match('/open-orders');
    var inProgressOrders = location.href.toLowerCase().match('/in-progress');
    var isArchiveOrders = location.href.toLowerCase().match('/archive');
    var isPreparation = location.href.toLowerCase().match('/preparation');
    var isSender = [BaseUserAppTYpe.SENDER, BaseUserAppTYpe.CONSIGNEE].find(function (t) { return currentUser.appType.includes(t); });
    var isSenderConsigneeView = (_d = order.view_access) === null || _d === void 0 ? void 0 : _d.find(function (e) {
        return [BaseUserAppTYpe.SENDER, BaseUserAppTYpe.CONSIGNEE].includes(e);
    });
    var loadUploadsCount = function (order) { return __awaiter(_this, void 0, void 0, function () {
        var res;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!order.latestQuote) return [3 /*break*/, 2];
                    return [4 /*yield*/, (config &&
                            config.client.query({
                                query: FetchUploadsCount,
                                variables: {
                                    filter: {
                                        fields: [
                                            { name: 'parent', value: order.latestQuote._id },
                                            { name: 'parentOffer', value: 1 },
                                            { name: 'canAccess', value: true },
                                            ((_a = order.view_access) === null || _a === void 0 ? void 0 : _a.length) && {
                                                name: 'view_access',
                                                value: order.view_access,
                                            },
                                            order &&
                                                checkIfOrderDetailsIsHide(store, order) && {
                                                name: 'access',
                                                value: [BaseUserAppTYpe.SENDER, BaseUserAppTYpe.CONSIGNEE],
                                            },
                                        ].filter(function (e) { return e; }),
                                        limit: 0,
                                    },
                                },
                            }))];
                case 1:
                    res = _b.sent();
                    order.latestQuote.totalUploads = { count: res.data.uploadsCount.count };
                    orderListItemProps.onChange({ record: order });
                    _b.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    var loadOrder = function (opt) {
        if (opt === void 0) { opt = {}; }
        return __awaiter(_this, void 0, void 0, function () {
            var res, orderFull, res1, quote_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!config)
                            return [2 /*return*/];
                        return [4 /*yield*/, config.client.query({
                                query: FetchOrder,
                                variables: {
                                    _id: order._id,
                                },
                            })];
                    case 1:
                        res = _a.sent();
                        delete res.data.order.price;
                        orderFull = JSON.parse(JSON.stringify(__assign(__assign(__assign(__assign({}, order), res.data.order), (opt.softOrderUpdate && { updatedAt: order.updatedAt })), { full: true, totalUnViewedQuotes: 0 })));
                        if (!(order.latestQuote && !opt.withoutQuote)) return [3 /*break*/, 3];
                        return [4 /*yield*/, (config &&
                                config.client.query({
                                    query: FetchOrderQuoteDetails,
                                    variables: {
                                        _id: order.latestQuote._id,
                                    },
                                }))];
                    case 2:
                        res1 = _a.sent();
                        quote_1 = res1.data[Object.keys(res1.data)[0]];
                        orderFull.latestQuote = order.latestQuote = __assign(__assign({}, order.latestQuote), quote_1);
                        orderFull.transportations.forEach(function (e) {
                            var item = quote_1.order.transportations.find(function (t) { return t._id === e._id; });
                            Object.assign(e, item);
                        });
                        if (quote_1 && !opt.softOrderUpdate) {
                            loadUploadsCount(orderFull);
                        }
                        _a.label = 3;
                    case 3:
                        delete orderFull.firstRoute;
                        delete orderFull.lastRoute;
                        orderListItemProps.onChange({ record: orderFull });
                        if (!orderFull.transportations.find(function (e) { return !e.unloadedAt; }) && isCarrier && inProgressOrders)
                            setTab(4);
                        return [2 /*return*/];
                }
            });
        });
    };
    var forceRefreshOrder = function (opt) {
        if (opt === void 0) { opt = {}; }
        return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                loadOrder(opt);
                return [2 /*return*/];
            });
        });
    };
    var onChange = function () {
        var e = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            e[_i] = arguments[_i];
        }
        return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (props.onChange)
                    props.onChange.apply(props, __spread(e));
                setUpdate(Date.now());
                return [2 /*return*/];
            });
        });
    };
    var theme1 = useTheme();
    var isSmall = useMediaQuery(theme1.breakpoints.down('sm'));
    var handleChange = function (event, newValue) {
        setTab(newValue);
    };
    var isAdmin = window.location.href.match('localhost:3002') || window.location.href.match('admin');
    var actionsContent = isAdmin
        ? null
        : orderDetails &&
            (OrderStatus.ApprovedQuote === order.status ||
                isCustomer ||
                ((isCustomer || isCarrier) && [OrderStatus.Finished].includes(order.status)))
            ? function (quote) { return (React.createElement(OrderQuoteActions, { onChange: props.onChange, onChangeV1: forceRefreshOrder, onDeleteItem: onDeleteItem, order: orderDetails || order, itemRecordOrder: order, quote: quote, config: config, style: { width: 'fit-content' } })); }
            : null;
    var isSystemCancel = (order.cancelComment || '').match(/system cancel/gi);
    var isCancelled = order.canceledAt && order.status === OrderStatus.Finished;
    var isDeleted = order.deletedAt;
    var isCAAllowed = !isCarrier ||
        !((_e = order.approvedCarrier) === null || _e === void 0 ? void 0 : _e._id) ||
        (isCarrier && accountStore.currentCompany._id === order.approvedCarrier._id);
    var isOrderOwn = accountStore.currentCompany._id === ((_g = (_f = order.manager) === null || _f === void 0 ? void 0 : _f.company) === null || _g === void 0 ? void 0 : _g._id);
    var isAprroved = order.status === OrderStatus.ApprovedQuote;
    var isDraftOrTemplate = order.status === OrderStatus.Draft || order.isTemplate;
    var onChangeFiles = isAprroved
        ? function (doc, action) {
            var item = orderListItemProps.state.record;
            orderListItemProps.onChange({
                record: __assign(__assign({}, item), { latestQuote: __assign(__assign({}, item.latestQuote), { totalUploads: (item.latestQuote.totalUploads && __assign(__assign({}, item.latestQuote.totalUploads), { count: item.latestQuote.totalUploads.count + (action === ACTION_CHANGE.DELETED ? -1 : (doc === null || doc === void 0 ? void 0 : doc.length) || 0) })) || { count: (doc === null || doc === void 0 ? void 0 : doc.length) || 0 } }) }),
            });
        }
        : function () { return 0; };
    var item = order.latestQuote;
    var isOrderSenderConsignee = [OrderStatus.HasQuotes, OrderStatus.WaitingQuotes, OrderStatus.ApprovedQuote].includes(order.status) &&
        order.transportations
            .map(function (transportation) { return transportation.routes; })
            .reduce(function (a, b) { return __spread(a, b); }, [])
            .find(function (route) { return route.email.includes(accountStore.currentUser.email); });
    var is_review_offer = isReviewOffer(accountStore, item);
    var isBooking = !is_review_offer && item && item.status === OrderQuoteStatus.Approved && order.status === OrderStatus.ApprovedQuote;
    var hasAditionalTabs = isAprroved ||
        (orderDetails &&
            orderDetails.latestQuote &&
            [OrderQuoteStatus.Approved, OrderQuoteStatus.Finished].includes(orderDetails.latestQuote.status));
    var v2 = isCarrier && isBooking;
    var lastTransportation = order && order.transportations && order.transportations[order.transportations.length - 1];
    var isDeliveredLastTransportation = lastTransportation === null || lastTransportation === void 0 ? void 0 : lastTransportation.unloadedAt;
    var isHide = checkIfOrderDetailsIsHide(store, order);
    var tabs = !orderDetails
        ? []
        : [
            {
                access: v2 || !accountStore.isLoggedIn,
                tab: function (index) { return (React.createElement(BookingTab, __assign({ label: langOfferForm.t('offer_time_booking'), color: 'primary', key: "tab-" + index }, a11yProps(index)))); },
                tabContent: function (index) { return (React.createElement(TabPanel, { value: tab, index: index, key: "tab-content-" + index },
                    React.createElement(Grid, { container: true, key: update },
                        React.createElement(Divider, { style: { width: '100%' } }),
                        React.createElement(PreloadingComponent, null,
                            React.createElement(TimeBookingTab, { order: orderDetails, config: config, onChange: function (opt) { return forceRefreshOrder(__assign(__assign({}, opt), { softOrderUpdate: 1 })); }, listOptions: props.listOptions, additionalContentBodyV1: props.additionalContentBodyV1 }))))); },
            },
            {
                access: true,
                tab: function (index) { return (React.createElement(StyledTab, __assign({ label: langOfferForm.t('offer_overview'), color: 'primary', key: "tab-" + index }, a11yProps(index)))); },
                tabContent: function (index) { return (React.createElement(TabPanel, { value: tab, index: index, key: "tab-content-" + index },
                    React.createElement(Grid, { container: true, key: update },
                        React.createElement(Divider, { style: { width: '100%' } }),
                        React.createElement(PreloadingComponent, null,
                            React.createElement(ExpandedAreaQuote, { order: orderDetails, config: config, onChange: onChange, listOptions: props.listOptions, additionalContentBodyV1: props.additionalContentBodyV1, onChangeV1: forceRefreshOrder }))))); },
            },
            {
                access: !isDraftOrTemplate && ((_h = orderDetails.latestQuote) === null || _h === void 0 ? void 0 : _h.approvedAt),
                tab: function (index) { return (React.createElement(StyledTab, __assign({ "data-id": langMenu.t('menu_sidebar_chat_title'), label: React.createElement(OrderMessagesUnread, { order: orderDetails, key: "tab-" + index, label: langMenu.t('menu_sidebar_chat_title') }), color: 'primary' }, a11yProps(index)))); },
                tabContent: function (index) { return (React.createElement(TabPanel, { value: tab, index: index, key: "tab-content-" + index },
                    React.createElement(Divider, { style: { width: '100%' } }),
                    React.createElement(Grid, { container: true, style: { padding: theme.spacing(0, 2) } },
                        React.createElement(OrderChannels, { order: orderDetails, config: config, hideTitle: true })))); },
            },
            {
                access: !isDraftOrTemplate &&
                    !((_j = order.view_access) === null || _j === void 0 ? void 0 : _j.find(function (e) { return [BaseUserAppTYpe.SENDER, BaseUserAppTYpe.CONSIGNEE].includes(e); })) &&
                    !((isCarrier || isCustomer || isForwarder) && openOrders),
                tab: function (index) { return (React.createElement(StyledTab, __assign({ label: langOrderList.t('order_list_good_loading'), color: 'primary', key: "tab-" + index }, a11yProps(index)))); },
                tabContent: function (index) { return (React.createElement(TabPanel, { value: tab, index: index, key: "tab-content-" + index },
                    React.createElement(Divider, { style: { width: '100%' } }),
                    React.createElement(Grid, { container: true, style: { padding: theme.spacing(0, 2) } },
                        React.createElement(OrderLoadingComponentTab, { order: orderDetails, config: config })))); },
            },
            {
                access: (hasAditionalTabs || isCancelled) && orderDetails.latestQuote,
                tab: function (index) {
                    var _a, _b, _c;
                    return (React.createElement(StyledTab, __assign({ key: "tab-" + index, "data-id": langOfferForm.t('offer_document_attached'), label: (((_c = (_b = (_a = orderListItemProps.state.record) === null || _a === void 0 ? void 0 : _a.latestQuote) === null || _b === void 0 ? void 0 : _b.totalUploads) === null || _c === void 0 ? void 0 : _c.count) && (React.createElement(BaseTooltip, { title: langOfferForm.t('offer_document_attached'), style: { marginRight: 12 } },
                            React.createElement(StyledBadge, { badgeContent: orderListItemProps.state.record.latestQuote.totalUploads.count, color: "primary" }, langOfferForm.t('offer_document_attached'))))) ||
                            langOfferForm.t('offer_document_attached'), color: 'primary' }, a11yProps(index))));
                },
                tabContent: function (index) { return (React.createElement(TabPanel, { value: tab, index: index, key: "tab-content-" + index },
                    React.createElement(Divider, { style: { width: '100%' } }),
                    React.createElement(Grid, { container: true, style: { padding: theme.spacing(0, 2) } },
                        React.createElement(OrderQuoteDocuments, { quote: __assign(__assign({}, orderDetails.latestQuote), { order: orderDetails }), onChange: onChangeFiles, config: config, parent: orderDetails.latestQuote._id, folder: "private/orders/" + orderDetails._id + "/quotes/" + orderDetails.latestQuote._id + "/" })))); },
            },
            {
                access: hasAditionalTabs && !isSender,
                tab: function (index) { return (React.createElement(StyledTab, __assign({ key: "tab-" + index, label: langOfferForm.t('offer_form_invoice_request'), color: 'primary' }, a11yProps(index)))); },
                tabContent: function (index) { return (React.createElement(TabPanel, { value: tab, index: index, key: "tab-content-" + index },
                    React.createElement(Divider, { style: { width: '100%' } }),
                    React.createElement(Grid, { container: true, style: { padding: theme.spacing(0, 2) } },
                        React.createElement(PreloadingComponent, null,
                            React.createElement(OrderQuoteInvoiceRequested, { config: config, order: orderDetails }))))); },
            },
            {
                access: accountStore.currentUser.rolesV1.find(function (e) { var _a; return (_a = e.tools.general) === null || _a === void 0 ? void 0 : _a.includes(USER_ROLE_TOOLS.GENERAL_can_see_p44_tracking_settings); }) &&
                    order.canP44Sync &&
                    (isCustomer || isForwarder) &&
                    [OrderStatus.ApprovedQuote, OrderStatus.Finished].includes(order.status),
                tab: function (index) { return (React.createElement(StyledTab, __assign({ key: "tab-" + index, label: langApp.t('traking_order'), color: 'primary' }, a11yProps(index)))); },
                tabContent: function (index) { return (React.createElement(TabPanel, { value: tab, index: index, key: "tab-content-" + index },
                    React.createElement(Divider, { style: { width: '100%' } }),
                    React.createElement(Grid, { container: true, item: true, xs: 12, md: 4, style: { padding: theme.spacing(0, 2) } },
                        React.createElement(PreloadingComponent, null,
                            React.createElement(OrderTracking, { config: config, order: orderDetails }))))); },
            },
            {
                access: order.preparedAt && (isOrderOwn || isOrderSenderConsignee),
                tab: function (index) { return (React.createElement(StyledTab, __assign({ key: "tab-" + index, label: langApp.t('app_preparation'), color: 'primary' }, a11yProps(index)))); },
                tabContent: function (index) { return (React.createElement(TabPanel, { value: tab, index: index, key: "tab-content-" + index },
                    React.createElement(Divider, { style: { width: '100%' } }),
                    React.createElement(Grid, { container: true, item: true, xs: 12, style: { padding: theme.spacing(0, 2) } },
                        React.createElement(OrderPreparing, { onChangeV1: forceRefreshOrder, config: config, order: orderDetails })))); },
            },
        ].filter(function (e) { return e.access; });
    React.useEffect(function () {
        if (isPreparation && tabs.length > 0) {
            setTab(tabs.length - 1);
        }
    }, [tabs.length || 0]);
    var handleExpandClick = function (e) { return __awaiter(_this, void 0, void 0, function () {
        var e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setExpanded(!expanded);
                    if (!(!expanded && !orderDetails)) return [3 /*break*/, 5];
                    if (!config) return [3 /*break*/, 5];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, 4, 5]);
                    setLoading(true);
                    return [4 /*yield*/, loadOrder({ preparationTab: tabs.length })];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 5];
                case 3:
                    e_1 = _a.sent();
                    setError(e_1.message);
                    return [3 /*break*/, 5];
                case 4:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/, onPreventEvent(e)];
            }
        });
    }); };
    var additionalContent = (React.createElement(Grid, { container: true, alignItems: 'center', style: { width: 'initial' }, direction: isSmall ? 'column' : 'row' },
        React.createElement(BaseTooltip, { title: (expanded ? langApp.t('collapse_less') : langApp.t('collapse_more')) },
            React.createElement(IconButton, { disabled: loading, className: clsx(classes.expand, (_b = {},
                    _b[classes.expandOpen] = expanded,
                    _b)), onClick: handleExpandClick, "aria-expanded": expanded, "aria-label": "show more" },
                React.createElement("span", { style: { width: 24, height: 24 } }, loading ? React.createElement(CircularIndeterminate, { style: { width: 24, height: 24 } }) : React.createElement(ExpandMoreIcon, null))))));
    if (!accountStore.isLoggedIn) {
        tabs.splice(1, tabs.length);
    }
    if (!isCAAllowed) {
        tabs.splice(1, tabs.length);
    }
    return (React.createElement(Grid, { container: true, direction: "row", key: JSON.stringify(['_id', 'updatedAt'].map(function (e) {
            var _a;
            return (_a = {}, _a[e] = (orderDetails || order)[e], _a);
        })), className: clsx((_c = {},
            _c[classes.canceled] = isCancelled && isSystemCancel,
            _c[classes.userCanceled] = isCancelled && !isSystemCancel,
            _c[classes.userDeleted] = isDeleted,
            _c)) },
        error ? React.createElement(TextOverflow, { color: 'error' }, error) : null,
        React.createElement(OrderRoutes, __assign({ onDeleteItem: onDeleteItem, config: config, onClick: canRedirect && 0 ? function () { return 0; } : handleExpandClick, canRedirect: canRedirect, canViewAdditional: expanded, additionalContent: additionalContent, key: (expanded && 1) || -1 }, props, { order: order, onChangeV1: forceRefreshOrder })),
        React.createElement(IziCollapse, { in: expanded, id: "expaned-container", timeout: "auto", unmountOnExit: true, style: { width: '100%' } }, loading ? (React.createElement(Grid, { container: true, justify: 'center' },
            React.createElement(CircularIndeterminate, null))) : orderDetails ? (React.createElement(React.Fragment, null,
            React.createElement(Divider, { style: { width: '100%' } }),
            React.createElement(Tabs, { value: tab, onChange: handleChange, indicatorColor: "primary", textColor: "primary", orientation: isSmall ? 'vertical' : 'horizontal', style: __assign({ width: isSmall ? '100%' : 'initial' }, (true && { alignItems: 'flex-start', padding: '1px' })) }, tabs.map(function (e, index) { return e.tab(index); })),
            React.createElement(React.Fragment, null,
                tabs.map(function (e, index) { return e.tabContent(index); }),
                React.createElement(Grid, { container: true, "data-id": "izi-order-bottom-aditional-content" }, (additionalContentBody && accountStore.isLoggedIn && (React.createElement(React.Fragment, null,
                    React.createElement(Divider, { style: { width: '100%' } }),
                    React.createElement(Grid, { container: true, justify: "space-between" },
                        React.createElement(Grid, { container: true, style: __assign({ width: 'fit-content', alignItems: 'center', justifyContent: 'center', margin: theme.spacing(0, 2) }, (isSmall && { margin: theme.spacing(2) })) }, (inProgressOrders || openOrders || isArchiveOrders) &&
                            order.createdBy.company._id.toString() === currentCompany._id.toString() ? (React.createElement(OrderLink, { order: order, config: config })) : null),
                        typeof additionalContentBody === 'function'
                            ? additionalContentBody(orderDetails, actionsContent)
                            : additionalContentBody)))) ||
                    (actionsContent && accountStore.isLoggedIn && (React.createElement(React.Fragment, null,
                        React.createElement(Divider, { style: { width: '100%' } }),
                        React.createElement(Grid, { container: true, justify: "space-between" },
                            React.createElement("span", null),
                            actionsContent(orderDetails.latestQuote)))))),
                (isCarrier &&
                    isCAAllowed &&
                    accountStore.isLoggedIn &&
                    orderDetails.status === OrderStatus.ApprovedQuote &&
                    orderDetails.latestQuote &&
                    orderDetails.latestQuote.fastPaymentStatus === OrderQuoteFastPaymentStatus.Available && (React.createElement(React.Fragment, null,
                    React.createElement(PreloadingComponent, { withoutLoader: true },
                        React.createElement(OrderFastPayment, { v1: true, onChange: forceRefreshOrder, config: config, order: orderDetails, quote: orderDetails.latestQuote }))))) ||
                    null,
                (!isHide &&
                    order &&
                    order.latestQuote &&
                    order.createdBy &&
                    order.latestQuote.createdBy &&
                    order.publishedAt &&
                    (isDeliveredLastTransportation || [OrderStatus.Finished].includes(order.status)) && (React.createElement(React.Fragment, null,
                    React.createElement(PreloadingComponent, { withoutLoader: true },
                        React.createElement(OrderUserRating, { v1: true, onChangeV1: forceRefreshOrder, config: config, order: orderDetails, quote: orderDetails.latestQuote }))))) ||
                    null))) : null)));
});
export var BaseOrderQuoteItem = inject('store')(BaseOrderQuoteItemComponent);
