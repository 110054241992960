var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Grid, IconButton, Typography } from '@material-ui/core';
import BlockIcon from '@material-ui/icons/Block';
import { BasicDialog, onPreventEvent } from '../../Dialog';
import { useTranslation } from 'react-i18next';
import { i18n, KEYS } from '../../../i18n';
import { inject, observer, useLocalStore } from 'mobx-react';
import { Form, Formik } from 'formik';
import { withRouter } from 'react-router-dom';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { OrderStatus } from '../../../models';
import { ACTION_CHANGE } from '../../BasicList';
import { FormikTextField } from '../../Formik';
import { CancelOrder } from '../../../graphql/order';
import { BaseUserAppTYpe, USER_ROLE } from '../../../stores/common';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { onlySpacesRegExp } from '../../../common';
import OrderRouteMenuItem from './OrderRoutesMenuItem';
import browserHistory from '../../../browserHistory';
import BaseTooltip from '../../BaseTooltip';
import { useInputV1Styles } from '../../../styles/form/input';
import { USER_ROLE_TOOLS } from '../../../models/UserRoles';
var useIconStyles = makeStyles(function (theme) {
    return createStyles({
        icon: {
            color: 'red',
            fill: theme.palette.error.light,
            '& path': {
                fill: theme.palette.error.light,
            },
        },
    });
});
export var OrderCancel = withRouter(inject('store')(observer(function (_a) {
    var config = _a.config, order = _a.order, currentUser = _a.store.accountStore.currentUser, props = __rest(_a, ["config", "order", "store"]);
    var classes = useIconStyles();
    var classesInput = useInputV1Styles();
    var formRef = React.useRef();
    var langOrderList = useTranslation(KEYS.ORDER_LIST);
    var langForm = useTranslation(KEYS.FORM);
    var localStore = useLocalStore(function () { return ({
        error: '',
        isLoading: false,
        onSubmit: function (_input) {
            return __awaiter(this, void 0, void 0, function () {
                var input, res, _a, e_1;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            localStore.error = '';
                            localStore.isLoading = true;
                            _b.label = 1;
                        case 1:
                            _b.trys.push([1, 4, 5, 6]);
                            input = __assign(__assign({}, _input), { _id: order._id });
                            _a = config;
                            if (!_a) return [3 /*break*/, 3];
                            return [4 /*yield*/, config.client.mutate({
                                    mutation: CancelOrder,
                                    variables: {
                                        input: input,
                                    },
                                })];
                        case 2:
                            _a = (_b.sent());
                            _b.label = 3;
                        case 3:
                            res = _a;
                            toast.success(langOrderList.t('order_list_cancel_order_finish'));
                            if (props.match.url.toLowerCase().startsWith('/quote-details')) {
                                if (browserHistory.length > 1) {
                                    browserHistory.goBack();
                                }
                                else {
                                    browserHistory.push('/');
                                }
                            }
                            else if (props.onChange)
                                props.onChange(order, ACTION_CHANGE.DELETED);
                            return [2 /*return*/, res.data.cancelOrder];
                        case 4:
                            e_1 = _b.sent();
                            toast.error(e_1.message);
                            return [3 /*break*/, 6];
                        case 5:
                            localStore.isLoading = false;
                            return [7 /*endfinally*/];
                        case 6: return [2 /*return*/];
                    }
                });
            });
        },
        clearError: function () {
            localStore.error = '';
        },
    }); });
    var canCancelOrder = currentUser.rolesV1.find(function (e) { var _a; return (_a = e.tools.general) === null || _a === void 0 ? void 0 : _a.includes(USER_ROLE_TOOLS.GENERAL_can_cancel_orderl); });
    if (!order || !order.createdBy || !canCancelOrder) {
        return null;
    }
    var canCancel = (currentUser.appType.includes(BaseUserAppTYpe.CUSTOMER) &&
        [order.createdBy._id || order.createdBy].includes(currentUser._id)) ||
        canCancelOrder ||
        currentUser.roles.includes(USER_ROLE.ADMIN) ||
        [BaseUserAppTYpe.FORWARDER, BaseUserAppTYpe.CARRIER].find(function (t) { return currentUser.appType.includes(t); });
    if (!(canCancel && order.status === OrderStatus.ApprovedQuote))
        return null;
    var onOk = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var rejectedItem;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    onPreventEvent(e);
                    return [4 /*yield*/, formRef.current.submitForm()];
                case 1:
                    rejectedItem = _a.sent();
                    if (!rejectedItem)
                        throw Error(i18n.getResource(i18n.language, KEYS.TOAST, 'toast_form_not_valid'));
                    if (props.onChange)
                        props.onChange(rejectedItem, ACTION_CHANGE.DELETED);
                    return [2 /*return*/];
            }
        });
    }); };
    var dialogBody = (React.createElement(React.Fragment, null,
        React.createElement(Typography, { color: "error" }, localStore.error),
        React.createElement(Formik, { key: localStore.error, innerRef: formRef, initialValues: { cancelComment: '' }, validationSchema: Yup.object({
                cancelComment: Yup.string()
                    .max(1000, langForm.t('form_input_validate_max_char').replace(/_X_/, '1000'))
                    .matches(onlySpacesRegExp, langForm.t('form_input_validate_invalid_text'))
                    .required(langForm.t('form_input_validate_required')),
            }), onSubmit: localStore.onSubmit }, function (formik) {
            return (React.createElement(Form, null,
                React.createElement(Grid, { container: true, direction: "column", className: classesInput.root },
                    React.createElement(FormikTextField, { multiline: true, rows: 4, title: langForm.t('form_input_comment_reason'), variant: "outlined", name: "cancelComment", value: formik.values.cancelComment }))));
        })));
    return ((config && (React.createElement(BasicDialog, { onOk: onOk, body: dialogBody, title: langOrderList.t('order_list_cancel_order'), dialogProps: { fullWidth: true, maxWidth: 'sm' }, withoutWrapper: true, actionInit: function (handleClickOpen) {
            var icon = (React.createElement(BaseTooltip, { title: langOrderList.t('order_list_cancel_order') },
                React.createElement(IconButton, { onClick: handleClickOpen, className: classes.icon },
                    React.createElement(BlockIcon, null))));
            if (props.label)
                return React.createElement(OrderRouteMenuItem, __assign({}, { icon: icon, label: props.label, handleClickOpen: handleClickOpen }));
            return icon;
        } }))) ||
        null);
})));
