var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { makeStyles, createStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import { theme } from '../../../theme';
import { AssignedInTransitIcon, AssignedLoadingTimeIcon, AssignedTransportIcon, AssignedUnLoadedIcon, ConfirmedIcon, } from '../../icons';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../../i18n';
import BaseTooltip from '../../BaseTooltip';
import { OrderInProgressStatus } from '../../../models';
export var QontoConnectorV1 = withStyles({
    alternativeLabel: {
        top: 13,
        left: 'calc(-50% + 4px)',
        right: 'calc(50% + 0px)',
    },
    active: {
        '& $line': {
            borderColor: theme.palette.primary.main,
        },
    },
    completed: {
        '& $line': {
            borderColor: theme.palette.primary.main,
        },
    },
    line: {
        borderColor: theme.palette.secondary.main,
        borderTopWidth: 1,
        borderRadius: 1,
    },
})(StepConnector);
var useColorlibStepIconStyles = makeStyles({
    root: {
        backgroundColor: theme.palette.secondary.main,
        zIndex: 1,
        width: 14,
        height: 14,
        display: 'flex',
        borderRadius: '50%',
        alignItems: 'center',
        transition: 'all .2s ease-in-out',
        marginTop: 6,
        '&:hover': {
            width: 28,
            height: 28,
            marginTop: 0,
            '& svg': {
                width: 23,
                height: 23,
                paddingLeft: 5,
            },
        },
    },
    active: {
        backgroundColor: theme.palette.success.light,
        width: 28,
        height: 28,
        marginRight: 5,
        marginLeft: 5,
        marginTop: 0,
        '&:hover': {
            width: 28,
            height: 28,
            marginTop: 0,
            '& svg': {
                width: 23,
                height: 23,
                paddingLeft: 5,
            },
        },
    },
    rootDisabled: {
        backgroundColor: theme.palette.secondary.main,
        zIndex: 1,
        width: 14,
        height: 14,
        display: 'flex',
        borderRadius: '50%',
        alignItems: 'center',
        transition: 'all .2s ease-in-out',
        marginTop: 6,
    },
    activeDisabled: {
        backgroundColor: theme.palette.success.light,
        width: 28,
        height: 28,
        marginRight: 5,
        marginLeft: 5,
        marginTop: 0,
    },
    completed: {
        backgroundColor: theme.palette.primary.main,
    },
    icon: {
        width: 12,
        height: 12,
        paddingLeft: 2,
        transition: 'all .2s ease-in-out',
        '& *': {
            fill: 'white',
        },
        '& .izi-svg-path': {
            stroke: theme.palette.primary.main,
            fill: theme.palette.primary.main,
        },
    },
    activeIcon: {
        width: 23,
        height: 23,
        paddingLeft: 5,
        '& *': {
            fill: 'white',
        },
        '& .izi-svg-path': {
            stroke: theme.palette.success.light,
            fill: theme.palette.success.light,
        },
    },
    notCompletedIcon: {
        '& *': {
            fill: theme.palette.secondary.dark,
        },
        '& .izi-svg-path': {
            stroke: theme.palette.secondary.main,
            fill: theme.palette.secondary.main,
        },
    },
});
export function ColorlibStepIconV1(props) {
    var _a;
    var classes = useColorlibStepIconStyles();
    var active = props.active, completed = props.completed, icon = props.icon;
    var langMenu = useTranslation(KEYS.MENU);
    var langOfferForm = useTranslation(KEYS.OFFER_FORM);
    var getIconClass = function (index) {
        var _a;
        return clsx(classes.icon, (_a = {
                'izi-active-step': icon === index && active
            },
            _a[classes.activeIcon] = icon === index && active,
            _a[classes.notCompletedIcon] = icon === index && !(active || completed),
            _a));
    };
    var icons = ((props === null || props === void 0 ? void 0 : props.icons) && props.icons(getIconClass)) || {
        1: {
            icon: React.createElement(ConfirmedIcon, { className: getIconClass(1), "data-id": langMenu.t('menu_sidebar_in_progress_confirmed') }),
            title: langMenu.t('menu_sidebar_in_progress_confirmed'),
        },
        2: {
            icon: (React.createElement(AssignedTransportIcon, { className: getIconClass(2), "data-id": langMenu.t('menu_sidebar_in_progress_transport_assigned') })),
            title: langMenu.t('menu_sidebar_in_progress_transport_assigned'),
        },
        3: {
            icon: (React.createElement(AssignedLoadingTimeIcon, { className: getIconClass(3), "data-id": langMenu.t('menu_sidebar_in_progress_time_selected') })),
            title: langMenu.t('menu_sidebar_in_progress_time_selected'),
        },
        4: {
            icon: (React.createElement(AssignedInTransitIcon, { className: getIconClass(4), "data-id": langMenu.t('menu_sidebar_in_progress_in_transit') })),
            title: langMenu.t('menu_sidebar_in_progress_in_transit'),
        },
        5: {
            icon: React.createElement(AssignedUnLoadedIcon, { className: getIconClass(5), "data-id": langOfferForm.t('offer_un_loaded') }),
            title: langOfferForm.t('offer_un_loaded'),
        },
    };
    var iconObject = icons[String(icon)];
    var content = (React.createElement("div", { className: clsx(props.disabled ? classes.rootDisabled : classes.root, (_a = {},
            _a[props.disabled ? classes.activeDisabled : classes.active] = active,
            _a[classes.completed] = completed,
            _a)) }, iconObject.icon));
    return props.disabled ? content : React.createElement(BaseTooltip, { title: iconObject.title }, content);
}
export var useStepperStylesV1 = makeStyles(function (theme) {
    return createStyles({
        root: {
            width: '170px',
            height: '30px',
        },
        button: {
            marginRight: theme.spacing(1),
        },
        instructions: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
        stepRoot: {
            padding: 0,
        },
    });
});
export default function OrderProgress(_a) {
    var _b;
    var _c;
    var order = _a.order, props = __rest(_a, ["order"]);
    if (!order.latestQuote)
        return null;
    var classes = useStepperStylesV1();
    var STATUS_IN_PROGRESS_ORDER = (_b = {},
        _b[OrderInProgressStatus.AssignedTransport] = 1,
        _b[OrderInProgressStatus.AssignedLoadingTime] = 2,
        _b[OrderInProgressStatus.AssignedUnLoadingTime] = 2,
        _b[OrderInProgressStatus.AssignedInTransit] = 3,
        _b[OrderInProgressStatus.AssignedUnLoaded] = 4,
        _b);
    var curStep = STATUS_IN_PROGRESS_ORDER[(_c = order.latestQuote) === null || _c === void 0 ? void 0 : _c.inProgressStatus] || 0;
    var MAX_STEPS = 5;
    var activeStep = Math.min(typeof curStep === 'number' ? curStep : 0, MAX_STEPS);
    return (React.createElement("div", { className: classes.root, style: { padding: theme.spacing(0, 1, 1, 1) }, "data-id": "izi-order-progress" },
        React.createElement(Stepper, { alternativeLabel: true, activeStep: activeStep, connector: React.createElement(QontoConnectorV1, null), style: { padding: 0, backgroundColor: 'transparent' } }, Array.from(Array(MAX_STEPS)).map(function (_, index) { return (React.createElement(Step, { classes: { root: classes.stepRoot }, key: index },
            React.createElement(StepLabel, { style: {}, StepIconComponent: ColorlibStepIconV1 }))); }))));
}
