var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { TextField } from '@material-ui/core';
import React from 'react';
export var BaseTextField = function (props) {
    var _a;
    var _b = __read(React.useState(false), 2), focused = _b[0], setFocused = _b[1];
    var onFocus = function (e) {
        setFocused(true);
        if (props.onFocus) {
            props.onFocus(e);
        }
    };
    var onBlur = function (e) {
        setFocused(false);
        if (props.onBlur) {
            props.onBlur(e);
        }
    };
    return (React.createElement(TextField, __assign({}, props, { autoComplete: "off", onFocus: onFocus, onBlur: onBlur, InputLabelProps: __assign(__assign({}, props.InputLabelProps), { shrink: focused || ((_a = props.InputLabelProps) === null || _a === void 0 ? void 0 : _a.shrink) }) })));
};
