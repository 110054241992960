var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import { inject, observer, useObserver } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Grid, FormControlLabel, Checkbox, FormGroup } from '@material-ui/core';
import { FormikTextField } from '../../../../components';
import { Formik, Form } from 'formik';
import { getSnapshot } from 'mobx-state-tree';
import { canEdit } from '../utils';
import { useFormStyles, useSectionStyles } from '../../../../styles';
import { FormikAutocompleteAsync } from '../../../../components';
import { OrderStraps, OrderSideboards, FetchUsers } from '../../../../graphql';
import { KEYS } from '../../../../i18n';
import { TextOverflow } from '../../../../components';
import * as Yup from 'yup';
import { onlySpacesRegExp } from '../../../../common';
import InputTitle from '../../../Formik/InputTitle';
import { BaseOrderCertificatesSelect } from '../../dialogs/BaseOrderCertificatesSelect';
import ExpandFormItem from '../../../ExpandFormItem';
import PaddingSpace from './PaddingSpce';
import { theme } from '../../../../theme';
import { USER_ROLE_TOOLS } from '../../../../models/UserRoles';
import { BaseUserAppTYpe } from '../../../../stores';
import { OrderTemperatureForm } from './OrderTemperatureForm';
import { hasThermoTrailer } from './TransportRequirementsForm';
export var AdditionalRequirementsForm = inject('store')(observer(React.forwardRef(function (_props, ref) {
    var store = _props.store, client = _props.config.client;
    var order = store.orderStore.order;
    var initValues = JSON.parse(JSON.stringify(getSnapshot(order)));
    ['comment', 'commentCMR'].forEach(function (e) {
        if (!initValues[e])
            initValues[e] = '';
    });
    if (!initValues.certificates)
        initValues.certificates = [];
    var _a = __read(React.useState(initValues), 2), formValues = _a[0], setFormValues = _a[1];
    var classes = useSectionStyles();
    var classesName = useFormStyles();
    var langOrderForm = useTranslation(KEYS.ORDER_FORM);
    var langForm = useTranslation(KEYS.FORM);
    var langApp = useTranslation(KEYS.APP);
    var temperatureRef = React.useRef();
    var isThermoTrailer = hasThermoTrailer(order.vehicleType);
    var handleCheckChange = function (e, value) { return __awaiter(void 0, void 0, void 0, function () {
        var v;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    v = (_a = {
                            _id: order._id
                        },
                        _a[e.target.name] = value,
                        _a);
                    return [4 /*yield*/, ref.current.setFieldValue(e.target.name, value)];
                case 1:
                    _b.sent();
                    store.orderStore.updateOrder(v);
                    setFormValues(__assign(__assign({}, formValues), v));
                    return [2 /*return*/];
            }
        });
    }); };
    var handleChangeCertificates = function (value) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2 /*return*/, handleChange({ target: { name: 'certificates', value: value } })];
    }); }); };
    var handleChange = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, name, value, v;
        var _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _a = e.target, name = _a.name, value = _a.value;
                    if (!value || value === '')
                        return [2 /*return*/];
                    if (['temperatureFrom', 'temperatureTo'].includes(name)) {
                        value = parseInt(value);
                    }
                    if (value === formValues[name])
                        return [2 /*return*/];
                    return [4 /*yield*/, ref.current.setFieldValue(name, value)];
                case 1:
                    _c.sent();
                    if (ref.current.errors[name])
                        return [2 /*return*/, ref.current.setFieldTouched(name)];
                    v = (_b = {
                            _id: order._id
                        },
                        _b[name] = value,
                        _b);
                    store.orderStore.updateOrder(v);
                    setFormValues(__assign(__assign(__assign({}, formValues), v), { updatedAt: Date.now() }));
                    return [2 /*return*/];
            }
        });
    }); };
    var noEdit = !canEdit(order, store); //|| store.orderStore.isOrderUpdating;
    var noEditForm = noEdit && !location.href.toLowerCase().match(/restore/);
    var canTestOrder = store.accountStore.currentUser.rolesV1.find(function (e) { var _a; return (_a = e.tools.general) === null || _a === void 0 ? void 0 : _a.includes(USER_ROLE_TOOLS.GENERAL_can_test_orders); });
    var usersProps = {
        query: FetchUsers,
        variables: {
            filter: {
                fields: [
                    {
                        name: 'company',
                        value: store.accountStore.currentCompany._id,
                    },
                    {
                        name: 'appType',
                        value: [BaseUserAppTYpe.CUSTOMER],
                    },
                ],
            },
        },
    };
    if (store.accountStore.currentUser.appType.includes(BaseUserAppTYpe.FORWARDER)) {
        Object.assign(usersProps, {
            variables: { filter: { fields: [] } },
        });
    }
    return useObserver(function () { return (React.createElement("div", { className: classes.section, style: { padding: 0 }, key: [order.createdAt, order.updatedAt].join('_'), id: 'step-content-additional-requirements' },
        React.createElement(Formik, { innerRef: ref, initialValues: formValues, onSubmit: function (e) { return __awaiter(void 0, void 0, void 0, function () {
                var _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            _a = isThermoTrailer;
                            if (!_a) return [3 /*break*/, 2];
                            return [4 /*yield*/, temperatureRef.current.submitForm()];
                        case 1:
                            _a = !(_b.sent());
                            _b.label = 2;
                        case 2:
                            if (_a) {
                                return [2 /*return*/, null];
                            }
                            return [2 /*return*/, e];
                    }
                });
            }); }, validationSchema: Yup.object(Object.assign({
                comment: Yup.string()
                    .max(1000, langForm.t('form_input_validate_max_char').replace(/_X_/g, '1000'))
                    .matches(onlySpacesRegExp, langForm.t('form_input_validate_invalid_text')),
                commentCMR: Yup.string()
                    .max(1000, langForm.t('form_input_validate_max_char').replace(/_X_/g, '1000'))
                    .matches(onlySpacesRegExp, langForm.t('form_input_validate_invalid_text')),
            })) }, function (formik) {
            return (React.createElement(Form, { id: 'additional-form' },
                React.createElement(Grid, { container: true, item: true, xs: 12, spacing: 2, style: { marginTop: theme.spacing(1) } },
                    React.createElement(PaddingSpace, null),
                    React.createElement(Grid, { item: true, xs: 12, md: 2, container: true, alignItems: 'center' },
                        React.createElement(TextOverflow, { className: classesName.sectionTitle1 }, langOrderForm.t('create_order_general_manger'))),
                    React.createElement(Grid, { item: true, xs: 12, md: 3 },
                        React.createElement(FormikAutocompleteAsync, __assign({ required: true, v17: true, disabled: noEdit }, usersProps, { title: langOrderForm.t('create_order_general_manger'), help: langForm.t('form_input_help_order_manager'), name: "manager", type: "text", client: client, variant: "outlined", margin: 'dense', filterFields: ['firstName', 'lastName'], fullWidth: true, autocompleteProps: __assign(__assign({ disabled: noEdit }, (order.manager && order.manager._id && { defaultValue: order.manager })), { getOptionSelected: function (option, value) { return option._id === value._id; }, getOptionLabel: function (option) { var _a; return "" + (((_a = option.fullName) === null || _a === void 0 ? void 0 : _a.trim()) || option.email || '--'); } }), onChange: handleChange })))),
                isThermoTrailer ? (React.createElement(Grid, { container: true, item: true, xs: 12, spacing: 2, style: { marginTop: theme.spacing(1) } },
                    React.createElement(PaddingSpace, null),
                    React.createElement(Grid, { item: true, xs: 12, md: 2, container: true, alignItems: 'center' },
                        React.createElement(TextOverflow, { className: classesName.sectionTitle1 }, langOrderForm.t('order_form_temperature'))),
                    React.createElement(Grid, { item: true, xs: 12, md: 2 },
                        React.createElement(OrderTemperatureForm, { ref: temperatureRef, handleChange: handleChange })))) : null,
                React.createElement(Grid, { container: true, item: true, xs: 12, spacing: 2 },
                    React.createElement(PaddingSpace, null),
                    React.createElement(Grid, { item: true, xs: 12, md: 2, container: true, alignItems: 'center' },
                        React.createElement(TextOverflow, { className: classesName.sectionTitle1 }, langForm.t('form_input_additional'))),
                    React.createElement(Grid, { item: true, xs: 12, md: 8 },
                        React.createElement(FormGroup, { row: true, className: classes.group },
                            React.createElement(FormControlLabel, { control: React.createElement(Checkbox, { disabled: noEdit, checked: formik.values.isADR, onChange: handleCheckChange, name: "isADR", color: "primary" }), label: React.createElement(InputTitle, { v1: true, noStyle: true, help: langOrderForm.t('create_order_additional_adr_checkbox_help'), title: langOrderForm.t('create_order_additional_adr_checkbox') }) }),
                            React.createElement(FormControlLabel, { control: React.createElement(Checkbox, { disabled: noEdit, checked: formik.values.isCargoCornerProtector, onChange: handleCheckChange, name: "isCargoCornerProtector", color: "primary" }), label: React.createElement(TextOverflow, null, langOrderForm.t('create_order_additional_cargo_corner_protector_checkbox')) }),
                            React.createElement(FormControlLabel, { control: React.createElement(Checkbox, { disabled: noEdit, checked: formik.values.isAntiSplitMats, onChange: handleCheckChange, name: "isAntiSplitMats", color: "primary" }), label: React.createElement(TextOverflow, null, langOrderForm.t('create_order_additional_asm_checkbox')) }),
                            React.createElement(FormControlLabel, { control: React.createElement(Checkbox, { disabled: noEdit, checked: formik.values.isTent, onChange: handleCheckChange, name: "isTent", color: "primary" }), label: React.createElement(InputTitle, { v1: true, noStyle: true, help: langOrderForm.t('create_order_additional_tent_checkbox_help'), title: langOrderForm.t('create_order_additional_tent_checkbox') }) }),
                            React.createElement(FormControlLabel, { control: React.createElement(Checkbox, { disabled: noEdit, checked: formik.values.isGPM, onChange: handleCheckChange, name: "isGPM", color: "primary" }), label: React.createElement(InputTitle, { v1: true, noStyle: true, help: langOrderForm.t('create_order_additional_gpm_checkbox_help'), title: langOrderForm.t('create_order_additional_gpm_checkbox') }) }),
                            React.createElement(FormControlLabel, { control: React.createElement(Checkbox, { disabled: noEdit, checked: formik.values.isOnlyToMyCA, onChange: handleCheckChange, name: "isOnlyToMyCA", color: "primary" }), label: React.createElement(InputTitle, { v1: true, noStyle: true, help: langForm.t('form_offers_only_my_companies_see'), title: langForm.t('form_offers_to_my_carriers') }) }),
                            (store.accountStore.currentCompany.canP44Sync && (React.createElement(FormControlLabel, { control: React.createElement(Checkbox, { disabled: noEdit, checked: formik.values.canP44Sync, onChange: handleCheckChange, name: "canP44Sync", color: "primary" }), label: React.createElement(InputTitle, { v1: true, noStyle: true, help: langApp.t('traking_order_help'), title: langApp.t('traking_order') }) }))) ||
                                null,
                            React.createElement(FormControlLabel, { control: React.createElement(Checkbox, { disabled: noEdit, checked: formik.values.noQuotes, onChange: handleCheckChange, name: "noQuotes", color: "primary" }), label: React.createElement(InputTitle, { v1: true, noStyle: true, help: langApp.t('inner_help_2'), title: langApp.t('inner') }) }),
                            (canTestOrder && (React.createElement(FormControlLabel, { control: React.createElement(Checkbox, { disabled: noEdit, checked: formik.values.isTest, onChange: handleCheckChange, name: "isTest", color: "primary" }), label: React.createElement(InputTitle, { v1: true, noStyle: true, help: langOrderForm.t('is_test_order_help'), title: langOrderForm.t('is_test') }) }))) ||
                                null))),
                React.createElement(Grid, { container: true, direction: "row", spacing: 2, style: { marginBottom: theme.spacing(1) } },
                    React.createElement(PaddingSpace, null),
                    React.createElement(Grid, { item: true, xs: 12, md: 2, key: "certificate-" + formValues.updatedAt + "-" + JSON.stringify(formik.values.certificates) },
                        React.createElement(BaseOrderCertificatesSelect, __assign({}, _props, { multiple: true, help: langForm.t('form_input_certificate_help'), autocompleteProps: { disabled: noEdit, multiple: true }, name: 'certificates', onChange: handleChangeCertificates, value: formik.values.certificates }))),
                    React.createElement(Grid, { item: true, xs: 12, md: 2, key: "sideboard-" + formValues.updatedAt },
                        React.createElement(FormikAutocompleteAsync, { v17: true, title: langOrderForm.t('create_order_additional_vehicle_sideboards'), help: langForm.t('form_input_help_new_option'), variant: "outlined", margin: 'dense', fullWidth: true, canAddNew: true, canAddNewV1: true, name: "sideBoards", client: client, query: OrderSideboards, value: formik.values.sideBoards, autocompleteProps: {
                                disabled: noEdit,
                                defaultValue: formik.values.sideBoards
                                    ? {
                                        _id: formik.values.sideBoards,
                                    }
                                    : null,
                                getOptionSelected: function (option, value) {
                                    return option._id === value._id;
                                },
                                getOptionLabel: function (option) {
                                    return (option.title || option._id || '').toString();
                                },
                            }, onChange: handleChange })),
                    React.createElement(Grid, { item: true, xs: 12, md: 2, key: "strap-" + formValues.updatedAt },
                        React.createElement(FormikAutocompleteAsync, { v17: true, title: langOrderForm.t('create_order_additional_vehicle_straps'), help: langForm.t('form_input_help_new_option'), variant: "outlined", margin: 'dense', fullWidth: true, canAddNew: true, canAddNewV1: true, name: "strap", client: client, query: OrderStraps, value: formik.values.strap, autocompleteProps: {
                                disabled: noEdit,
                                defaultValue: formik.values.strap
                                    ? {
                                        _id: formik.values.strap,
                                    }
                                    : null,
                                getOptionSelected: function (option, value) {
                                    return option._id === value._id;
                                },
                                getOptionLabel: function (option) {
                                    return (option.title || option._id || '').toString();
                                },
                            }, onChange: handleChange }))),
                React.createElement(ExpandFormItem, { title: langApp.t('app_comments') },
                    React.createElement(Grid, { container: true, direction: "row", spacing: 2 },
                        React.createElement(PaddingSpace, null),
                        React.createElement(Grid, { item: true, xs: 12, md: 5 },
                            React.createElement(FormikTextField, { disabled: noEditForm, title: langApp.t('app_comments'), multiline: true, variant: "outlined", type: "text", rows: 4, name: "comment", onBlur: function () {
                                    return handleChange({
                                        target: {
                                            name: 'comment',
                                            value: formik.values.comment,
                                        },
                                    });
                                }, value: formik.values.comment })),
                        React.createElement(Grid, { item: true, xs: 12, md: 5 },
                            React.createElement(FormikTextField, { disabled: noEditForm, title: langOrderForm.t('create_order_additional_requirements_comment_cmr'), multiline: true, variant: "outlined", type: "text", rows: 4, name: "commentCMR", onBlur: function () {
                                    return handleChange({
                                        target: {
                                            name: 'commentCMR',
                                            value: formik.values.commentCMR,
                                        },
                                    });
                                }, value: formik.values.commentCMR }))))));
        }))); });
})));
