var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React from 'react';
import { Grid } from '@material-ui/core';
import { toast } from 'react-toastify';
import { AddFormButton, DeleteDialog, FormikAutocompleteAsync, onPreventEvent, ListboxComponent, } from '../';
import { theme } from '../../theme';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../i18n';
function findValue(obj, key) {
    var v = obj && obj[key];
    var keys = key.split('.');
    if (keys.length > 1) {
        return findValue(obj && obj[keys[0]], keys.filter(function (e, i) { return i > 0; }).join('.'));
    }
    return v;
}
export var FormikAutocompleteAsyncAdd = function (_a) {
    var onRemove = _a.onRemove, onChange = _a.onChange, onClose = _a.onClose, defaultValue = _a.defaultValue, onEditItem = _a.onEditItem, onDeleteItem = _a.onDeleteItem, deleteTitle = _a.deleteTitle, renderOption = _a.renderOption, _b = _a.options, options = _b === void 0 ? [] : _b, addTitle = _a.addTitle, addContent = _a.addContent, dialog = _a.dialog, autocompleteProps = _a.autocompleteProps, onOpenDialog = _a.onOpenDialog, titleOptions = _a.titleOptions, noDataTitle = _a.noDataTitle, canEdit = _a.canEdit, props = __rest(_a, ["onRemove", "onChange", "onClose", "defaultValue", "onEditItem", "onDeleteItem", "deleteTitle", "renderOption", "options", "addTitle", "addContent", "dialog", "autocompleteProps", "onOpenDialog", "titleOptions", "noDataTitle", "canEdit"]);
    var deleteRef = React.useRef(null);
    var langToast = useTranslation(KEYS.TOAST);
    var _c = __read(React.useState(null), 2), itemToDelete = _c[0], setItemToDelete = _c[1];
    var _d = __read(React.useState(false), 2), open = _d[0], setOpen = _d[1];
    function openNewRouteDialog(e, item) {
        if (canEdit) {
        }
        else if (onOpenDialog) {
            onOpenDialog(e, { item: item });
        }
        else {
            setOpen(item || true);
        }
        if (item)
            return onPreventEvent(e);
    }
    function onCloseDialog(optionCreated) {
        setOpen(false);
        if (onClose)
            onClose(optionCreated);
    }
    var optionsToUse = JSON.parse(JSON.stringify(options)).filter(function (e) { return e && e._id; });
    if (defaultValue && defaultValue._id && !optionsToUse.find(function (e) { return e._id === defaultValue._id || defaultValue === e; })) {
        optionsToUse.splice(0, 0, JSON.parse(JSON.stringify(defaultValue)));
    }
    var onApproveDelete = function () { return __awaiter(void 0, void 0, void 0, function () {
        var item_1, key, data, _a, e_1;
        var _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _c.trys.push([0, 5, , 6]);
                    item_1 = itemToDelete;
                    key = props.query.definitions[0].selectionSet.selections[0].name.value;
                    if (!(props.client && props.removeQuery)) return [3 /*break*/, 2];
                    return [4 /*yield*/, props.client.mutate({
                            mutation: props.removeQuery,
                            variables: {
                                _id: item_1._id,
                            },
                        })];
                case 1:
                    _c.sent();
                    data = props.client.readQuery({ query: props.query });
                    props.client.writeQuery({
                        //Update Apollo Cache
                        query: props.query,
                        data: (_b = {},
                            _b[key] = {
                                count: data[key].count - 1,
                                data: data[key].data.filter(function (e) { return e._id !== item_1._id; }),
                            },
                            _b),
                    });
                    setTimeout(function () {
                        try {
                            props.client.resetStore(); //TODO: need to clear all store
                        }
                        catch (e) { }
                    }, 1000);
                    _c.label = 2;
                case 2:
                    _a = onDeleteItem;
                    if (!_a) return [3 /*break*/, 4];
                    return [4 /*yield*/, onDeleteItem({}, item_1)];
                case 3:
                    _a = (_c.sent());
                    _c.label = 4;
                case 4:
                    _a;
                    if (onRemove && defaultValue && item_1._id === defaultValue._id) {
                        onRemove(null);
                    }
                    setItemToDelete(null);
                    toast.success(langToast.t('toast_items_deleted').replace(/_X_/, props.title || ''));
                    return [3 /*break*/, 6];
                case 5:
                    e_1 = _c.sent();
                    console.log(e_1);
                    toast.error(e_1.message || e_1);
                    return [3 /*break*/, 6];
                case 6: return [2 /*return*/];
            }
        });
    }); };
    var _onEditItem = function (e, item) {
        if (onEditItem)
            onEditItem(e, item);
        openNewRouteDialog(e, item);
    };
    var _onDeleteItem = function (e, item) {
        deleteRef.current.click();
        setItemToDelete(item);
        return onPreventEvent(e);
    };
    var actions = {
        onEditItem: _onEditItem,
        onDeleteItem: _onDeleteItem,
    };
    var id = (addTitle || '').toString().replace(/ /g, '').toLowerCase();
    var optionsList = __spread(optionsToUse.map(function (e) { return Object.assign(e, actions); })).filter(function (e) { return e; });
    var autocompletePropsAsync = {};
    if (renderOption)
        Object.assign(autocompletePropsAsync, { renderOption: renderOption });
    return (React.createElement(React.Fragment, null,
        React.createElement(FormikAutocompleteAsync, __assign({ id: id, variant: "outlined", type: "text", name: "origin", margin: 'dense', fullWidth: true }, props, { disabled: (autocompleteProps && autocompleteProps.disabled) || props.disabled, options: optionsList, mapResponse: function (res) {
                var _a;
                (_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.forEach(function (e) { return Object.assign(e, actions); });
            }, autocompleteProps: __assign(__assign(__assign(__assign(__assign({ ListboxComponent: function (props) { return (React.createElement(ListboxComponent, __assign({}, props, { titleOptions: titleOptions, addContent: React.createElement(React.Fragment, null,
                        React.createElement(AddFormButton, { id: "add-btn-" + id, style: {
                                justifyContent: 'flex-start',
                                background: 'transparent',
                                padding: theme.spacing(1, 2),
                            }, title: addTitle, onClick: openNewRouteDialog, fullWidth: true }),
                        addContent || null) }))); }, ListboxProps: __assign({ titleOptions: titleOptions, v20: props.v20, addContent: !props.canAddNewV3 && (React.createElement(Grid, { container: true, style: __assign({ padding: theme.spacing(1, 2) }, ((props === null || props === void 0 ? void 0 : props.optionFilter) && { paddingBottom: 0 })) },
                        React.createElement(AddFormButton, { v22: props.v22, id: "add-btn-" + id, style: {
                                justifyContent: 'flex-start',
                                background: 'transparent',
                            }, title: addTitle, onClick: openNewRouteDialog, fullWidth: true }),
                        addContent || null)), optionFilter: props === null || props === void 0 ? void 0 : props.optionFilter, optionFilterStyle: props === null || props === void 0 ? void 0 : props.optionFilterStyle }, autocompleteProps.ListboxProps), filterOptions: function (options, params) {
                    if (props.backendFilterOnly) {
                        return options;
                    }
                    var fields = props.filterFields || (options.length && Object.keys(options[0])) || [];
                    var filterText = params.inputValue &&
                        (params.inputValue
                            .toString()
                            .toLowerCase()
                            .match(/[a-zA-Z0-9]/gi) || []).join('');
                    var filtered = filterText && fields.length
                        ? options.filter(function (el) {
                            for (var i = 0; i < fields.length; i++) {
                                var v = encodeURI((findValue(el, fields[i]) || '').toString().toLowerCase());
                                if (v && v.match(filterText))
                                    return true;
                            }
                            return false;
                        })
                        : options; //filterOptions(options, params);
                    if (!filtered.find(function (e) { return e.content; }))
                        filtered.push.apply(filtered, __spread(options.filter(function (e) { return e.content; }))); //force have add new content
                    return filtered;
                } }, autocompleteProps), { defaultValue: defaultValue }), autocompletePropsAsync), { titleOptions: titleOptions,
                noDataTitle: noDataTitle }), (props.v27
                ? {}
                : {
                    getOptionSelected: function (option, value) {
                        return (option && value && (option._id === value._id || option._id === value)) || null;
                    },
                })), onChange: onChange })),
        dialog && dialog({ open: open, defaultValue: open, allActions: actions, onClose: onCloseDialog }),
        React.createElement(DeleteDialog, { value: (itemToDelete && deleteTitle && deleteTitle(itemToDelete)) || 'record', onOk: onApproveDelete, actionInit: function (onClick) { return React.createElement("span", { ref: deleteRef, onClick: onClick }); } })));
};
