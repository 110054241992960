var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React from 'react';
import clsx from 'clsx';
import TermConfirmation from '../../../TermConfirmation';
import { TERMS_REQUEST } from '../../../../models/Terms';
import { MainButton } from '../../../MainButton';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { i18n, KEYS } from '../../../../i18n';
import { FetchLoadOnDays } from '../../../../graphql/warehouse/calendar';
import { ORDER_PREPARE_MODE, OrderLocation, OrderStatus } from '../../../../models';
import { getSnapshot } from 'mobx-state-tree';
import { BASIC_DIALOG_ACTIONS, BasicDialog } from '../../../Dialog';
import { Grid, Typography } from '@material-ui/core';
import { TextOverflow } from '../../../TextOverflow';
import { useMainStyles } from '../../../../styles';
import { BaseSelect, FormikRadioGroupField, FormikTextField } from '../../../Formik';
import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import { theme } from '../../../../theme';
import { UpdateOrder } from '../../../../graphql';
import { HelpIconComponent } from '../../../HelpIcon';
import { UploadList } from '../../../Uploads/List';
import browserHistory from '../../../../browserHistory';
import { IS_DEV_ENV } from '../../../../utils';
function PublishOrder(_a) {
    var _this = this;
    var store = _a.store, noQuotes = _a.noQuotes, canPublishOrderForAnotherUser = _a.canPublishOrderForAnotherUser, canStartPreparing = _a.canStartPreparing, props = __rest(_a, ["store", "noQuotes", "canPublishOrderForAnotherUser", "canStartPreparing"]);
    var dialogBody = null;
    var dialogBodyPublishAs = null;
    var dialogBodyStartPreparation = null;
    var order = getSnapshot(store.orderStore.order);
    var options = [];
    if (canPublishOrderForAnotherUser) {
        options = order.transportations
            .map(function (e) {
            return e.routes
                .filter(function (e) { var _a, _b, _c, _d; return ((_b = (_a = e.origin) === null || _a === void 0 ? void 0 : _a.companyId) === null || _b === void 0 ? void 0 : _b._id) && ((_d = (_c = e.origin) === null || _c === void 0 ? void 0 : _c.companyId) === null || _d === void 0 ? void 0 : _d._id) !== store.accountStore.currentCompany._id; })
                .map(function (e) { return e.origin.companyId; });
        })
            .reduce(function (a, b) { return __spread(a, b); }, [])
            .map(function (e) { return ({
            name: [e.name, OrderLocation.create({ address: e.addressV1 }).fullAddress].filter(function (e) { return e; }).join(', '),
            value: e,
        }); });
    }
    var langForm = useTranslation(KEYS.FORM);
    var langMenu = useTranslation(KEYS.MENU);
    var langOfferForm = useTranslation(KEYS.OFFER_FORM);
    var langApp = useTranslation(KEYS.APP);
    var langOrderForm = useTranslation(KEYS.ORDER_FORM);
    var classes = useMainStyles();
    var filesFormRef = React.useRef();
    var spanRef = React.useRef(null);
    var preparationRef = React.useRef(null);
    var publishAsRef = React.useRef(null);
    var startPreparationRef = React.useRef(null);
    var _b = __read(React.useState([]), 2), files = _b[0], setFiles = _b[1];
    var _c = __read(React.useState(false), 2), loading = _c[0], setLoading = _c[1];
    var _d = __read(React.useState(null), 2), publishAs = _d[0], setPublishAs = _d[1];
    var _e = __read(React.useState(null), 2), loadOnDay = _e[0], setLoadOnDay = _e[1];
    React.useEffect(function () {
        if (canPublishOrderForAnotherUser && !publishAs) {
            setPublishAs((options.length && options[0].value) || null);
        }
    }, [canStartPreparing, options, canPublishOrderForAnotherUser]);
    var onPublish = function (e) { return __awaiter(_this, void 0, void 0, function () {
        var isNotLoading, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 6, 7, 8]);
                    isNotLoading = !loading;
                    setLoading(true);
                    return [4 /*yield*/, props.onSubmit(e)];
                case 1:
                    if (!((_a.sent()) > 0)) return [3 /*break*/, 5];
                    if (props.selectedTab === 0) {
                        props.handleChangeTab(e, 1);
                        throw 'switch tab';
                    }
                    else if (order.status !== OrderStatus.Draft || order.isTemplate) {
                        if (!order.isTemplate &&
                            [OrderStatus.HasQuotes, OrderStatus.WaitingQuotes].includes(order.status) &&
                            canStartPreparing) {
                            return [2 /*return*/, onStartPreparation()];
                        }
                        toast.success(i18n
                            .getResource(i18n.language, KEYS.TOAST, 'toast_items_saved')
                            .replace(/_X_/, i18n.getResource(i18n.language, KEYS.APP, 'main_order')));
                        if (isNotLoading) {
                            props.config.client.mutate({
                                mutation: UpdateOrder,
                                variables: {
                                    input: {
                                        _id: order._id,
                                    },
                                },
                            });
                        }
                        throw 'simple save';
                    }
                    if (!canPublishOrderForAnotherUser) return [3 /*break*/, 2];
                    onPublishAs();
                    return [3 /*break*/, 5];
                case 2:
                    if (!canStartPreparing) return [3 /*break*/, 3];
                    onStartPreparation();
                    return [3 /*break*/, 5];
                case 3: return [4 /*yield*/, onOk(e)];
                case 4:
                    _a.sent();
                    _a.label = 5;
                case 5: return [3 /*break*/, 8];
                case 6:
                    e_1 = _a.sent();
                    return [3 /*break*/, 8];
                case 7:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 8: return [2 /*return*/];
            }
        });
    }); };
    var onPublishAs = function () {
        publishAsRef.current.click();
    };
    var onStartPreparation = function () {
        startPreparationRef.current.click();
    };
    var onCancel = function () {
        setLoadOnDay(null);
        setLoading(false);
    };
    var onCancelV1 = function () {
        setLoading(false);
    };
    var onOk = function (e) { return __awaiter(_this, void 0, void 0, function () {
        var res, e_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setLoading(true);
                    if (!!loadOnDay) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, props.config.client.query({
                            query: FetchLoadOnDays,
                            variables: {
                                filter: {
                                    fields: [
                                        {
                                            name: 'order',
                                            value: order._id,
                                        },
                                    ].filter(function (e) { return e; }),
                                },
                            },
                        })];
                case 2:
                    res = _a.sent();
                    setLoadOnDay(res.data.loadOnDays);
                    if (res.data.loadOnDays.length > 0 && ((IS_DEV_ENV && window.showOrderInternalLoadOnDays) || !IS_DEV_ENV)) {
                        return [2 /*return*/, spanRef.current.click()];
                    }
                    return [3 /*break*/, 4];
                case 3:
                    e_2 = _a.sent();
                    console.log(e_2);
                    return [3 /*break*/, 4];
                case 4:
                    store.orderStore.publishOrder(__assign({ _id: order._id, noQuotes: noQuotes }, (canPublishOrderForAnotherUser && publishAs && { publishAs: (publishAs === null || publishAs === void 0 ? void 0 : publishAs._id) || publishAs })), { freeRedirect: canPublishOrderForAnotherUser });
                    setLoadOnDay(null);
                    setLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var preparingStatuses = store.orderStore.preparingStatuses;
    if (loadOnDay === null || loadOnDay === void 0 ? void 0 : loadOnDay.length) {
        var list = [];
        for (var i = 0; i < loadOnDay.length; i++) {
            var _f = loadOnDay[i], data = _f.data, warehouse = __rest(_f, ["data"]);
            for (var j = 0; j < data.length; j++) {
                list.push({
                    warehouse: warehouse,
                    index: j,
                    item: data[j],
                    total: data.length,
                });
            }
        }
        dialogBody = (React.createElement("table", { className: classes.table },
            React.createElement("thead", null,
                React.createElement("tr", null,
                    React.createElement("th", null, langMenu.t('menu_sidebar_warehouse')),
                    React.createElement("th", null, langApp.t('app_date')),
                    React.createElement("th", null, langApp.t('total_orders')),
                    React.createElement("th", null, langOfferForm.t('offer_time_booking')))),
            React.createElement("tbody", null, list.map(function (e) {
                var _a, _b;
                return (React.createElement("tr", { key: e.warehouse._id + "-" + e.index },
                    (e.index === 0 && (React.createElement("td", { rowSpan: e.total, style: { verticalAlign: 'start' } },
                        React.createElement(TextOverflow, { v1: true, variant: 'caption', style: { maxWidth: 150 } }, [e.warehouse.name, OrderLocation.create(e.warehouse).fullAddress].filter(function (e) { return e; }).join(', '))))) ||
                        null,
                    React.createElement("td", null,
                        React.createElement(Grid, { container: true, alignItems: 'center', style: { minWidth: 130 } },
                            React.createElement(TextOverflow, { className: clsx((_a = {},
                                    _a[classes.textError] = e.item.count > 4,
                                    _a[classes.textSuccess] = e.item.count < 5,
                                    _a)) }, e.item.date))),
                    React.createElement("td", null,
                        React.createElement(TextOverflow, { v1: true, style: { maxWidth: 50 }, className: clsx((_b = {},
                                _b[classes.textError] = e.item.count > 4,
                                _b[classes.textSuccess] = e.item.count < 5,
                                _b)) }, e.item.count || '-')),
                    React.createElement("td", null,
                        React.createElement(Grid, { container: true, alignItems: 'center', style: { minWidth: 150 } },
                            React.createElement(TextOverflow, null, e.item.times.join(', ') || '-')))));
            }))));
    }
    if (canPublishOrderForAnotherUser) {
        dialogBodyPublishAs = (React.createElement(Grid, { container: true, key: publishAs ? (publishAs === null || publishAs === void 0 ? void 0 : publishAs._id) || 1 : -1 },
            React.createElement(BaseSelect, { style: { width: "100%" }, margin: "dense", variant: "outlined", name: 'publishAs', options: options, value: publishAs, onChange: function (e) { return setPublishAs(e.target.value); } })));
    }
    if (canStartPreparing) {
        dialogBodyStartPreparation = (React.createElement(Grid, { container: true, style: { justifyContent: 'center', alignItems: 'center' } },
            React.createElement(Typography, null, langForm.t('form_start_preparation_body_text')),
            React.createElement(Formik, { innerRef: preparationRef, validateOnBlur: false, initialValues: {
                    comment: '',
                    preparingMode: ORDER_PREPARE_MODE.SELF_ORGANIZE,
                }, onSubmit: function (e) { return e; } }, function (formik) {
                return (React.createElement(Form, { id: "preparation-form", style: { width: '100%' } },
                    React.createElement(Grid, { container: true, direction: "column", alignItems: 'center', justify: "flex-start", style: { marginBottom: theme.spacing(1) } },
                        React.createElement(FormikRadioGroupField, { v1: true, name: 'preparingMode', value: formik.values.preparingMode, options: Object.keys(ORDER_PREPARE_MODE).map(function (key) { return ({
                                value: ORDER_PREPARE_MODE[key],
                                label: (React.createElement(Grid, { container: true, alignItems: 'center' },
                                    React.createElement(TextOverflow, { style: { marginRight: theme.spacing(1) } }, langApp.t("preparing_mode_" + key.toUpperCase())),
                                    React.createElement(HelpIconComponent, { title: langApp.t("preparing_mode_" + key.toUpperCase() + "_help") }))),
                            }); }) })),
                    React.createElement(Grid, { container: true, direction: "column", item: true, xs: 12, style: { marginBottom: theme.spacing(3) } },
                        React.createElement(FormikTextField, { multiline: true, rows: 4, title: langForm.t('form_btn_to_comment'), variant: "outlined", name: "comment", value: formik.values.comment })),
                    React.createElement(Grid, { container: true, direction: "column", item: true, xs: 12, style: { marginBottom: theme.spacing(1) } },
                        React.createElement(UploadList, { config: props.config, parent: order._id, folder: "private/orders/" + order._id + "/preparationSteps/", isPreparation: true, v3: true, withoutDialog: true, formRef: filesFormRef, quote: {
                                order: order,
                            }, onChange: setFiles, parent_collection_name: 'orders' }))));
            })));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(BasicDialog, { body: dialogBody, title: langOrderForm.t('offer_form_load_on_days'), onOk: onOk, onClose: onCancel, actionOkTitle: langForm.t('form_btn_continue'), dialogProps: { fullWidth: true, maxWidth: 'md' }, bodyStyle: { display: 'block' }, actionInit: function (handleClickOpen) { return React.createElement("span", { onClick: handleClickOpen, ref: spanRef }); } }),
        (canPublishOrderForAnotherUser && (React.createElement(BasicDialog, { body: dialogBodyPublishAs, title: langOrderForm.t('offer_form_publish_for'), onOk: onOk, onClose: onCancelV1, actionOkTitle: langForm.t('form_btn_continue'), actionInit: function (handleClickOpen) { return React.createElement("span", { onClick: handleClickOpen, ref: publishAsRef }); } }))) ||
            null,
        (canStartPreparing && (React.createElement(BasicDialog, { isMiddleBody: true, v1: true, withoutWrapper: true, body: dialogBodyStartPreparation, title: langOrderForm.t('order_form_start_preparation_btn'), onOk: function (e) { return __awaiter(_this, void 0, void 0, function () {
                var uploads, form;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, filesFormRef.current.submitForm()];
                        case 1:
                            uploads = _a.sent();
                            if (!uploads)
                                throw Error(BASIC_DIALOG_ACTIONS.WAIT);
                            return [4 /*yield*/, preparationRef.current.submitForm()];
                        case 2:
                            form = _a.sent();
                            return [4 /*yield*/, store.orderStore.updateOrderPreparing(__assign(__assign({}, form), { files: uploads.files.map(function (e) { return e._uid || e._id; }), order: (order === null || order === void 0 ? void 0 : order._id) || store.orderStore.order._id, _id: preparingStatuses[0]._id }))];
                        case 3:
                            _a.sent();
                            browserHistory.push("/preparation?search=" + ((order === null || order === void 0 ? void 0 : order.orderId) || store.orderStore.order.orderId));
                            return [2 /*return*/];
                    }
                });
            }); }, onClose: onCancelV1, actionOkTitle: langForm.t('form_btn_continue'), actionInit: function (handleClickOpen) { return React.createElement("span", { onClick: handleClickOpen, ref: startPreparationRef }); } }))) ||
            null,
        React.createElement(TermConfirmation, __assign({}, (order.forwarders &&
            order.forwarders[0] &&
            order.forwarders[0].company && {
            filter: {
                fields: [
                    { name: 'request', value: TERMS_REQUEST.ORDER_PUBLISHED },
                    { name: 'company', value: order.forwarders[0].company._id },
                ],
            },
        }), { config: props.config, content: function (passed) { return (React.createElement(MainButton, { id: 'btn-publish-order', style: { width: '100%' }, onClick: onPublish, disabled: !passed || (canPublishOrderForAnotherUser && order.noQuotes), loading: props.loading || loading || store.orderStore.isOrderPublishing, title: props.selectedTab === 0
                    ? langApp.t('app_next')
                    : canPublishOrderForAnotherUser
                        ? langOrderForm.t('create_order_btn_publish_for')
                        : canStartPreparing
                            ? langOrderForm.t('order_form_start_preparation')
                            : !order._id
                                ? langForm.t('form_btn_create')
                                : order.isTemplate
                                    ? langForm.t('form_btn_save')
                                    : order.status === OrderStatus.Draft
                                        ? langOrderForm.t('create_order_btn_publish')
                                        : langForm.t('form_btn_save') })); } }))));
}
export default inject('store')(observer(PublishOrder));
