import moment from 'moment';
export var timeViewFormat = function (value) {
    return ((value || typeof value === 'number') &&
        ((value.toString().match(':') && value) || "" + (value < 10 ? '0' : '') + value + ":00")) ||
        '-';
};
export var timeViewFormat1 = function (value) {
    return ((typeof value === 'number' || value) && moment(value).format('HH:mm')) || '-';
};
export var timeViewFormat2 = function (min) {
    return timeViewFormat1(new Date(new Date(new Date(0).setHours(new Date(0).getHours() + new Date(0).getTimezoneOffset() / 60)).setMinutes(min)));
};
export var timeViewFormat3 = function (hour, duration) {
    var d = new Date(new Date(0).setHours(new Date(0).getHours() + new Date(0).getTimezoneOffset() / 60));
    d.setHours(parseInt(hour.toString().split(':')[0]));
    d.setMinutes(parseInt(hour.toString().split(':')[1]));
    d.setMinutes(d.getMinutes() + duration);
    return timeViewFormat1(d);
};
export var timeViewFormat4 = function (hour, duration) {
    if (duration === void 0) { duration = 0; }
    if (!hour)
        return 0;
    var d = new Date(new Date(0).setHours(new Date(0).getHours() + new Date(0).getTimezoneOffset() / 60));
    d.setHours(parseInt(hour.split(':')[0]));
    d.setMinutes(parseInt(hour.split(':')[1]));
    if (duration > 0)
        d.setMinutes(d.getMinutes() + duration);
    return d.getTime();
};
export var dateViewFormat = function (value, withTime, withScn) {
    return (value && moment(value).format("DD.MM.YY" + ((withTime && ' HH:mm') || '') + ((withScn && ':SS') || ''))) || '-';
};
export var dateViewFormatV1 = function (value, withTime, withScn) {
    return (value && moment(value).format("DD.MM" + ((withTime && ' HH:mm') || '') + ((withScn && ':SS') || ''))) || '-';
};
export var DateView = function (_a) {
    var value = _a.value;
    return moment(value).format('DD.MM.YYYY');
};
export var dateDifference = function (date) {
    if (date === void 0) { date = new Date(); }
    var diff = Date.now() - new Date(date).getTime();
    if (Math.round(diff / 1000) === 0) {
        return "just now";
    }
    else if (diff < 1000 * 60) {
        return Math.round(diff / 1000) + " scn ago";
    }
    else if (diff < 1000 * 60 * 60) {
        return Math.round(diff / (1000 * 60)) + " min ago";
    }
    else if (diff < 1000 * 60 * 60 * 24) {
        return Math.round(diff / (1000 * 60 * 60)) + " hours ago";
    }
    else if (diff < 1000 * 60 * 60 * 24 * 7) {
        return Math.round(diff / (1000 * 60 * 60 * 24)) + " days ago";
    }
    else {
        return dateViewFormat(date, 1);
    }
};
