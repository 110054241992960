var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Grid } from '@material-ui/core';
import { ACTION_CHANGE, BaseList } from '../../components/BasicList';
import { useLocalStore } from 'mobx-react-lite';
import { FetchOrderChats } from '../../graphql/chat';
import { ChatCreateEditForm } from './ChannelForm';
import { defaultChatUsers } from './utils';
import ChannelItem from './ChannelItem';
import { inject, observer } from 'mobx-react';
import { withChannelListStore } from './stores/ChannelList';
var ChannelsComponent = inject('store')(observer(function (_a) {
    var channelListStore = _a.channelListStore, store = _a.store, props = __rest(_a, ["channelListStore", "store"]);
    var localStore = useLocalStore(function () { return ({
        key: 1,
        onChange: function () {
            localStore.key = Date.now();
        },
        onLoad: function (_a) {
            var data = _a.data;
            channelListStore.setChannelReadStatusData(data.map(function (_a) {
                var _id = _a._id, totalUnread = _a.totalUnread;
                return ({ _id: _id, totalUnread: totalUnread });
            }));
            if (props.onLoad)
                props.onLoad(data);
        },
    }); });
    var baseListProps = __assign({ onLoad: localStore.onLoad, client: props.config.client, query: FetchOrderChats, searchFields: ['orderId'], defaultFilter: {
            sortBy: 'updatedAt',
            fields: [{ name: 'allChats', value: 1 }],
        } }, (props.noQueryParams && { noQueryParams: true }));
    if (props.order) {
        Object.assign(baseListProps, {
            defaultFilter: {
                fields: [
                    { name: 'order', value: props.order._id },
                    { name: 'allChats', value: 1 },
                    props.quote && {
                        name: 'users',
                        value: defaultChatUsers({
                            accountStore: store.accountStore,
                            order: __assign(__assign({}, props.order), { latestQuote: props.quote }),
                        }).map(function (e) { return e._id; }),
                    },
                ].filter(function (e) { return e; }),
                sortBy: 'updatedAt',
            },
        });
    }
    var isAdmin = props.isAdmin; //store.accountStore.currentUser.roles.includes(USER_ROLE.ADMIN);
    var config = {
        hideSort: true,
        title: '',
        id: 'chats',
        footer: (React.createElement(Grid, { container: true, justify: 'flex-end' }, !isAdmin && React.createElement(ChatCreateEditForm, __assign({ isModalForm: true, onChange: localStore.onChange }, props)))),
        rowContent: function (record, onChange) {
            var _onChange = function (record, action) {
                localStore.onChange();
                if (action === ACTION_CHANGE.DELETED && props.onRemove)
                    props.onRemove(record);
            };
            return (React.createElement(ChannelItem, { key: (props.channel && props.channel._id) || -1, isAdmin: isAdmin, record: record, onChange: _onChange, config: props.config, selected: props.channel && props.channel._id === record._id, onClick: props.onClick }));
        },
        noData: !isAdmin && (React.createElement(Grid, { container: true, justify: 'center' },
            React.createElement(ChatCreateEditForm, __assign({ isModalForm: true, onChange: localStore.onChange }, props)))),
    };
    var key = localStore.key + "-" + channelListStore.state.updatedAt;
    return React.createElement(BaseList, { key: key, options: baseListProps, config: config });
}));
var ChannelsShortComponent = inject('store')(observer(function (_a) {
    var channelListStore = _a.channelListStore, store = _a.store, withLastMessage = _a.withLastMessage, props = __rest(_a, ["channelListStore", "store", "withLastMessage"]);
    var localStore = useLocalStore(function () { return ({
        key: 1,
        onChange: function () {
            localStore.key = Date.now();
        },
        onLoad: function (_a) {
            var data = _a.data;
            channelListStore.setChannelReadStatusData(data.map(function (_a) {
                var _id = _a._id, totalUnread = _a.totalUnread;
                return ({ _id: _id, totalUnread: totalUnread });
            }));
            if (props.onLoad)
                props.onLoad(data);
        },
    }); });
    var baseListProps = __assign({ onLoad: localStore.onLoad, client: props.config.client, query: FetchOrderChats, searchFields: ['orderId'], defaultFilter: {
            sortBy: 'updatedAt',
        } }, (props.noQueryParams && { noQueryParams: true }));
    if (props.order) {
        Object.assign(baseListProps, {
            defaultFilter: {
                fields: [
                    withLastMessage && { name: 'onlyWithMessage', value: 1 },
                    { name: 'order', value: props.order._id },
                    { name: 'usersRelated', value: [store.accountStore.currentUser._id] },
                    props.quote && {
                        name: 'users',
                        value: defaultChatUsers({
                            accountStore: store.accountStore,
                            order: __assign(__assign({}, props.order), { latestQuote: props.quote }),
                        }).map(function (e) { return e._id; }),
                    },
                ].filter(function (e) { return e; }),
                sortBy: 'updatedAt',
            },
        });
    }
    var isAdmin = props.isAdmin; //store.accountStore.currentUser.roles.includes(USER_ROLE.ADMIN);
    var config = __assign({ dontSaveFilters: true, hideHeader: true, title: '', chats: '', rowContent: function (record, onChange) {
            var _onChange = function (record, action) {
                localStore.onChange();
                if (action === ACTION_CHANGE.DELETED && props.onRemove)
                    props.onRemove(record);
            };
            return React.createElement(ChannelItem, __assign({}, props, { isShort: true, record: record, onChange: _onChange }));
        } }, ((!withLastMessage && {
        noData: !isAdmin && (React.createElement(Grid, { container: true, justify: 'center' },
            React.createElement(ChatCreateEditForm, __assign({ isModalForm: true, onChange: localStore.onChange }, props)))),
    }) ||
        null));
    var key = localStore.key + "-" + channelListStore.state.updatedAt;
    return React.createElement(BaseList, { key: key, options: baseListProps, config: config });
}));
export var Channels = withChannelListStore(ChannelsComponent);
export var ChannelsShort = withChannelListStore(ChannelsShortComponent);
