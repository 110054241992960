var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import Zendesk, { ZendeskAPI } from './zendesk.js';
import { inject } from 'mobx-react';
var ZENDESK_KEY = process.env.REACT_APP_ZENDESK_SNIPPET;
var setting = {
    color: {
        theme: '#000',
    },
    launcher: {
        chatLabel: {
            'en-US': 'Need Help',
        },
    },
    contactForm: {
        fields: [{ id: 'description', prefill: { '*': 'My pre-filled description' } }],
    },
};
var ZendeskV = Zendesk;
function ReportIssue(_a) {
    var accountStore = _a.store.accountStore;
    if (!ZENDESK_KEY)
        return null; //
    return (React.createElement(ZendeskV, __assign({ defer: true, zendeskKey: ZENDESK_KEY }, setting, { onLoaded: function () {
            var _a, _b, _c;
            if (accountStore.currentUser.email) {
                ZendeskAPI('config', __assign(__assign(__assign({ email: accountStore.currentUser.email }, (((_a = accountStore.currentUser.avatar) === null || _a === void 0 ? void 0 : _a.path) && { url: (_b = accountStore.currentUser.avatar) === null || _b === void 0 ? void 0 : _b.path })), (accountStore.currentUser.phone && { phone: accountStore.currentUser.phone })), { name: [
                        [accountStore.currentUser.firstName, accountStore.currentUser.lastName].filter(function (e) { return e; }).join(' '),
                        accountStore.currentCompany.name,
                        accountStore.currentCompany.vatCode,
                    ]
                        .filter(function (e) { return e; })
                        .join(', ') }));
                ZendeskAPI('email', accountStore.currentUser.email);
                if ((_c = accountStore.currentUser.avatar) === null || _c === void 0 ? void 0 : _c.path)
                    ZendeskAPI('url', accountStore.currentUser.avatar.path);
                if (accountStore.currentUser.phone)
                    ZendeskAPI('phone', accountStore.currentUser.phone);
                ZendeskAPI('name', [
                    [accountStore.currentUser.firstName, accountStore.currentUser.lastName].filter(function (e) { return e; }).join(' '),
                    accountStore.currentCompany.name,
                    accountStore.currentCompany.vatCode,
                ]
                    .filter(function (e) { return e; })
                    .join(', '));
            }
        } })));
}
export default inject('store')(ReportIssue);
/*import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@material-ui/core';
import BaseTooltip from '../BaseTooltip';
import BugReportIcon from '@material-ui/icons/BugReport';

import { KEYS } from '../../i18n';
import { useIconStyles } from '../../styles/main';

import OrderRouteMenuItem from '../Order/List/OrderRoutesMenuItem';
import { Link } from 'react-router-dom';
import { IssuesChannelsSocket, withIssuesChannelsStore } from './store';
import { StyledBadgeV7 } from '../StyledBadge';
import { makeStyles } from '@material-ui/core/styles';
import { theme } from '../../theme';
import { TextOverflow } from '../TextOverflow';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiBadge-badge': {
      right: 0,
      top: 0,
    },
  },
}));

function ReportIssue(props: any) {
  const langApp = useTranslation(KEYS.APP);
  const globalIconStyles = useIconStyles();
  const title = langApp.t('main_issue_report');
  const classesS = useStyles();

  const iconO = (
    <BaseTooltip title={title}>
      <IconButton
        id={'report-btn'}
        style={{
          ...((props.isAdmin && { padding: 0 }) || { marginLeft: -theme.spacing(0.65) }),
        }}>
        <BugReportIcon className={globalIconStyles.menuIcon} />
      </IconButton>
    </BaseTooltip>
  );
  let icon = props.isAdmin ? (
    iconO
  ) : (
    <OrderRouteMenuItem
      v2
      icon={iconO}
      label={<TextOverflow style={{ marginLeft: theme.spacing(1) }}>{title}</TextOverflow>}
    />
  );
  if (props.issueChannelStore.state.totalUnread) {
    icon = (
      <StyledBadgeV7
        className={clsx({ [classesS.root]: props.isAdmin })}
        badgeContent={props.issueChannelStore.state.totalUnread}>
        {icon}
      </StyledBadgeV7>
    );
  }
  if (props.isAdmin) return icon;
  return <Link to={'/issues'}>{icon}</Link>;
}
const ReportIssueC = withIssuesChannelsStore(ReportIssue);
export default function (p) {
  return (
    <IssuesChannelsSocket>
      <ReportIssueC {...p} />
    </IssuesChannelsSocket>
  );
}
*/
