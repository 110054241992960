var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import { inject, observer, useObserver } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Grid, MenuItem } from '@material-ui/core';
import { FormikAutocomplete, FormikSelect, FormikTextField } from '../../../../components';
import { FormikAutocompleteAsync } from '../../../../components';
import { Formik, Form } from 'formik';
import { FetchOrderTemplates } from '../../../../graphql/order';
import { DeliveryTerms } from '../../../../models/Order';
import { getSnapshot } from 'mobx-state-tree';
import { OrderCargoType } from '../../../../models';
import { theme } from '../../../../theme';
import { canEdit } from '../utils';
import { BaseUserAppTYpe } from '../../../../stores';
import * as Yup from 'yup';
import { useChipStyles, useSectionStyles } from '../../../../styles';
import { KEYS } from '../../../../i18n';
import { onlySpacesRegExp } from '../../../../common';
import { FormikSliderRange } from '../../../Formik/FormikSliderRange';
import { dateViewFormat } from '../../../DateView';
import clsx from 'clsx';
import PaddingSpace from './PaddingSpce';
import { ConfirmDialog } from '../../../Dialog/ConfirmDialog';
import { HelpIconComponent } from '../../../HelpIcon';
import BaseTooltip from '../../../BaseTooltip';
import BaseChip from '../../../BaseChip';
import { OrderCreationMode } from '../../../../pages/Order/FormV1';
export var OrderNameForm = inject('store')(observer(React.forwardRef(function (_a, ref) {
    var store = _a.store, client = _a.config.client, mode = _a.mode;
    var _b = __read(React.useState(null), 2), targetCopy = _b[0], setTargetCopy = _b[1];
    var langForm = useTranslation(KEYS.FORM);
    var langApp = useTranslation(KEYS.APP);
    var langOrderForm = useTranslation(KEYS.ORDER_FORM);
    var langSettings = useTranslation(KEYS.SETTINGS);
    var confirmDialog = React.useRef(null);
    var classes = useSectionStyles();
    var classesChip = useChipStyles();
    var deliveryTermsOptions = Object.keys(DeliveryTerms).map(function (key) { return ({
        label: key + "(" + langOrderForm.t("create_order_delivery_terms_" + key.toUpperCase()) + ")",
        value: DeliveryTerms[key],
    }); });
    React.useEffect(function () {
        var _a;
        (_a = ref.current) === null || _a === void 0 ? void 0 : _a.setFieldValue('orderId', store.orderStore.order.orderId);
    }, [store.orderStore.order.orderId]);
    var onChange = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, name, value;
        var _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _a = e.target, name = _a.name, value = _a.value;
                    return [4 /*yield*/, ref.current.setFieldValue(name, (name === 'manager' && { _id: value }) || value)];
                case 1:
                    _c.sent();
                    if (ref.current.errors[name])
                        return [2 /*return*/, ref.current.setFieldTouched(name)];
                    if (name === 'deliveryTerms' && !(value === null || value === void 0 ? void 0 : value.value))
                        return [2 /*return*/];
                    store.orderStore.updateOrder(__assign((_b = { _id: order._id }, _b[name] = name === 'deliveryTerms' ? [value === null || value === void 0 ? void 0 : value.value] : value, _b), (store.accountStore.currentCompany.canP44Sync &&
                        name === 'cargoType' &&
                        value === OrderCargoType.FullTrackLoad && { canP44Sync: true })));
                    return [2 /*return*/];
            }
        });
    }); };
    var onSelectTemplate = function (e) {
        if (order._id) {
            setTargetCopy(e.target.value);
            confirmDialog && confirmDialog.current && confirmDialog.current.click();
        }
        else {
            store.orderStore.duplicateOrder(e.target.value);
        }
    };
    var onConfirmSelectTemplate = function (e) {
        return store.orderStore.copyOrder(targetCopy);
    };
    var order = store.orderStore.order;
    var isForwarder = store.accountStore.currentUser.appType.includes(BaseUserAppTYpe.FORWARDER);
    var noEdit = !canEdit(order, store); ///|| store.orderStore.isOrderUpdating;
    var validations = {
        validationSchema: __assign({}, (mode === OrderCreationMode.BASIC
            ? __assign({ orderId: Yup.string()
                    .typeError(langForm.t('form_input_validate_required'))
                    .max(50, langForm.t('form_input_validate_max_char').replace(/_X_/g, '50'))
                    .matches(onlySpacesRegExp, langForm.t('form_input_validate_invalid_text'))
                    .required(langForm.t('form_input_validate_required')), cargoType: Yup.string()
                    .typeError(langForm.t('form_input_validate_required'))
                    .required(langForm.t('form_input_validate_required')), deliveryTerms: Yup.object()
                    .typeError(langForm.t('form_input_validate_required'))
                    .required(langForm.t('form_input_validate_required')) }, (order.hasPreparing
                ? {
                    orderCustomId: Yup.string()
                        .typeError(langForm.t('form_input_validate_required'))
                        .max(50, langForm.t('form_input_validate_max_char').replace(/_X_/g, '50'))
                        .matches(onlySpacesRegExp, langForm.t('form_input_validate_invalid_text'))
                        .required(langForm.t('form_input_validate_required')),
                }
                : {})) : {
            orderCustomId: Yup.string()
                .typeError(langForm.t('form_input_validate_required'))
                .max(50, langForm.t('form_input_validate_max_char').replace(/_X_/g, '50'))
                .matches(onlySpacesRegExp, langForm.t('form_input_validate_invalid_text'))
                .required(langForm.t('form_input_validate_required')),
        })),
    };
    validations.validationSchema = Yup.object(validations.validationSchema);
    var initialValues = JSON.parse(JSON.stringify(getSnapshot(order)));
    ['orderId', 'orderCustomId', 'cargoType'].forEach(function (e) {
        if (!initialValues[e])
            initialValues[e] = '';
    });
    ['deliveryTerms'].forEach(function (e) {
        var _a;
        if ((_a = initialValues[e]) === null || _a === void 0 ? void 0 : _a.length)
            initialValues[e] = deliveryTermsOptions.find(function (ee) { return ee.value === initialValues[e][0]; });
    });
    var isTest = (order.isTest && (React.createElement(Grid, { style: { marginRight: theme.spacing(1), width: 'initial' }, container: true, justify: 'flex-end', alignItems: 'center' },
        React.createElement(BaseChip, { style: { color: theme.palette.common.white }, size: "small", label: "" + langOrderForm.t('is_test'), clickable: true, color: "primary" }),
        React.createElement(HelpIconComponent, { title: langOrderForm.t('is_test_order_help') })))) ||
        null;
    return useObserver(function () { return (React.createElement(React.Fragment, null,
        React.createElement(Grid, { container: true, justify: 'flex-end', alignItems: 'center' },
            order.publishedAt || isTest ? (React.createElement(Grid, { xs: 12, md: 11, container: true, justify: 'flex-end', alignItems: 'center', style: { marginBottom: theme.spacing(1) } },
                isTest,
                (order.publishedAt && (React.createElement(BaseChip, { style: { color: theme.palette.common.white }, size: "small", label: langOrderForm.t('create_order_general_published') + " " + dateViewFormat(order.publishedAt, 1), clickable: true, color: "primary" }))) ||
                    null)) : null,
            order.noQuotes && order.createdBy.company._id === store.accountStore.currentCompany._id ? (React.createElement(Grid, { xs: 12, md: 11, container: true, justify: 'flex-end', alignItems: 'center', style: { marginBottom: theme.spacing(1) } },
                isTest,
                React.createElement(BaseTooltip, { title: langApp.t('inner_help') },
                    React.createElement(BaseChip, { style: { color: theme.palette.common.white }, size: "small", label: langApp.t('inner'), clickable: true, color: "primary" })))) : null,
            order.isTemplate ? (React.createElement(Grid, { xs: 12, md: 11, container: true, justify: 'flex-end', alignItems: 'center', style: { marginBottom: theme.spacing(1) } },
                isTest,
                React.createElement(BaseChip, { style: { color: theme.palette.common.white }, size: "small", label: langOrderForm.t('create_order_general_as_template'), clickable: true, color: "primary" }))) : null,
            React.createElement(PaddingSpace, null)),
        React.createElement("div", { className: clsx(classes.section, classes.sectionItem) },
            React.createElement(ConfirmDialog, { text: langOrderForm.t('confirm_force_rewrite_order'), title: langApp.t('app_warning'), onOk: onConfirmSelectTemplate, actionInit: function (onClick) { return React.createElement("span", { onClick: onClick, ref: confirmDialog }); } }),
            React.createElement(Formik, __assign({ innerRef: ref, initialValues: initialValues }, validations, { onSubmit: function (e) { return e; }, key: order === null || order === void 0 ? void 0 : order._id }), function (formik) {
                return (React.createElement(Form, { id: 'name-form', style: { width: '100%' } },
                    React.createElement(Grid, { container: true, alignItems: 'flex-end', spacing: 3 },
                        React.createElement(PaddingSpace, null),
                        React.createElement(Grid, { item: true, xs: 12, md: 2, container: true, alignItems: 'center', justify: 'space-between' },
                            React.createElement(FormikTextField, { required: true, disabled: noEdit, title: langOrderForm.t('create_order_general_id'), help: langForm.t('form_input_help_order_id'), variant: "outlined", name: "orderId", value: formik.values.orderId, onBlur: onChange })),
                        React.createElement(Grid, { item: true, xs: 12, md: 2, container: true, alignItems: 'center', justify: 'space-between' },
                            React.createElement(FormikTextField, { required: true, disabled: noEdit, title: langOrderForm.t('order_form_customer_order_number'), help: langOrderForm.t('order_form_customer_order_number_help'), variant: "outlined", name: "orderCustomId", value: formik.values.orderCustomId, onBlur: onChange })),
                        React.createElement(Grid, { item: true, xs: 12, md: 2 },
                            React.createElement(FormikAutocomplete, { v17: true, disabled: noEdit, onChageText: function () { return 0; }, autocompleteProps: {
                                    disabled: noEdit,
                                    // multiple: true,
                                    onChange: function (e, v) {
                                        return onChange({
                                            target: {
                                                name: e.target.name,
                                                value: ((v === null || v === void 0 ? void 0 : v.length) && [v.map(function (e) { return e.value || e; })[v.length - 1]]) || v,
                                            },
                                        });
                                    },
                                    filterOptions: function (options, params) {
                                        var fields = (options.length && Object.keys(options[0])) || [];
                                        var filterText = params.inputValue && params.inputValue.toString().toLowerCase();
                                        var res = filterText && fields.length
                                            ? options.filter(function (el) { return el.value.toLowerCase().match(filterText); })
                                            : options; //filterOptions(options, params);
                                        if (Array.isArray(formik.values.deliveryTerms)) {
                                            var values_1 = formik.values.deliveryTerms.map(function (e) { return e.value || e; });
                                            return res.filter(function (e) { return !values_1.includes(e.value); });
                                        }
                                        return res;
                                    },
                                    getOptionLabel: function (option) { return option && option.label; },
                                    renderOption: function (option) { return option && option.label; },
                                    renderTags: function (value, getTagProps) {
                                        var re = (Array.isArray(value) && value) || [];
                                        return re.map(function (option, index) { return (React.createElement(BaseChip, __assign({ label: option }, getTagProps({ index: index }), { color: 'primary', style: { marginRight: theme.spacing(0.5) }, className: clsx(classesChip.chipRootActive, classesChip.chipRoot) }))); });
                                    },
                                    value: formik.values.deliveryTerms,
                                }, title: langOrderForm.t('create_order_delivery_terms'), isBigTooltip: true, help: React.createElement(Grid, { container: true },
                                    React.createElement("img", { style: { width: '100%', minWidth: 1200 }, src: 'https://izi-logistics-prod.s3.us-east-2.amazonaws.com/public/icons/deliveryTerms/Full_Incoterms_2020-1024x577.jpg' })), variant: "outlined", name: "deliveryTerms", value: ((Array.isArray(formik.values.deliveryTerms) && formik.values.deliveryTerms) || []).map(function (value) { return ({
                                    label: value + "(" + langOrderForm.t("create_order_delivery_terms_" + value.toUpperCase()) + ")",
                                    value: value,
                                }); }) || formik.values.deliveryTerms[0], options: deliveryTermsOptions })),
                        React.createElement(Grid, { container: true, item: true, xs: 12, md: 2 },
                            React.createElement(FormikSelect, { required: true, disabled: noEdit, variant: "outlined", title: langOrderForm.t('create_order_transport_loading_type'), onChange: onChange, name: "cargoType", value: formik.values.cargoType }, [
                                { value: OrderCargoType.FullTrackLoad, title: 'create_order_cargo_ftl' },
                                { value: OrderCargoType.PTLTrackLoad, title: 'create_order_cargo_ptl' },
                            ].map(function (_a) {
                                var value = _a.value, title = _a.title;
                                return (React.createElement(MenuItem, { id: "menu-item-select-" + value, key: value, value: value }, langOrderForm.t(title)));
                            }))),
                        React.createElement(Grid, { item: true, xs: 12, md: 2 },
                            React.createElement(FormikAutocompleteAsync, { v17: true, help: langForm.t('form_input_help_order_template'), title: langOrderForm.t('create_order_general_template'), variant: "outlined", type: "text", margin: 'dense', fullWidth: true, name: "template", client: client, query: FetchOrderTemplates, filterFields: ['templateName'], variables: {
                                    filter: {
                                        fields: [{ name: 'isTemplate', value: true }],
                                    },
                                }, value: order.template, autocompleteProps: {
                                    disabled: noEdit,
                                    getOptionSelected: function (option, value) { return option._id === value._id; },
                                    getOptionLabel: function (option) {
                                        return [option.templateName || option.orderId, option.noQuotes && langApp.t('inner')]
                                            .filter(function (e) { return e; })
                                            .join('. ');
                                    },
                                }, onChange: onSelectTemplate }))),
                    (isForwarder && (React.createElement(Grid, { container: true, alignItems: 'flex-end', spacing: 3 },
                        React.createElement(PaddingSpace, null),
                        React.createElement(Grid, { item: true, xs: 12, md: 4 },
                            React.createElement(FormikSliderRange, { disabled: noEdit, title: langSettings
                                    .t('settings_order_forwarder_offer_percentage')
                                    .replace(/_X_/, formik.values.forwarderOrderMarkUpPercentage || ''), variant: "outlined", name: "forwarderOrderMarkUpPercentage", value: formik.values.forwarderOrderMarkUpPercentage, onChangeCommitted: function (e, value) {
                                    return onChange({ target: { name: 'forwarderOrderMarkUpPercentage', value: value } });
                                } }))))) ||
                        null));
            })))); });
})));
