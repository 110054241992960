var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import { useLocalStore } from 'mobx-react';
import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { inject } from 'mobx-react';
import { FormikTextField, TextOverflow } from '../../../../components';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { formUserErrorMessage } from '../../../../common';
import { DeleteOrderCargoPalletType, FetchOrderCargoPalletType } from '../../../../graphql/order';
import { FormikAutocompleteAsyncAdd } from '../../../../components';
import { BasicDialog } from '../../../../components';
import { onPreventEvent } from '../../../../components';
import { getSnapshot } from 'mobx-state-tree';
import { useStyles } from './styles';
import { OrderCargoPalletType } from '../../../../models/OrderCargoPalletType';
import { KEYS } from '../../../../i18n';
import { i18n } from '../../../../i18n';
import DropDownItemActions from './DropDownItemActions';
import { theme } from '../../../../theme';
export var OrderCargoPalletTypeSelect = inject('store')(function (_a) {
    var autocompleteProps = _a.autocompleteProps, props = __rest(_a, ["autocompleteProps"]);
    var langOrderForm = useTranslation(KEYS.ORDER_FORM);
    var langForm = useTranslation(KEYS.FORM);
    var store = props.store, onChange = props.onChange, client = props.config.client;
    var _b = __read(React.useState(JSON.parse(JSON.stringify(__assign(__assign({}, getSnapshot(OrderCargoPalletType.create())), (props.defaultValue && props.defaultValue._id && props.defaultValue))))), 2), _initialValue = _b[0], setInitialValue = _b[1];
    var classes = useStyles();
    var formRef = React.useRef();
    var addOrEditPalletType = function (input, localStore) {
        return new Promise(function (resolve, reject) {
            var _a, _b;
            if (input === null || input === void 0 ? void 0 : input._id) {
                store.orderStore
                    .updateOrderCargoPalletType(input, (_a = props.transportation) === null || _a === void 0 ? void 0 : _a._id, props.cargoId, props.isPublic)
                    .then(resolve)
                    .catch(function (error) {
                    localStore.error = formUserErrorMessage(error);
                    reject(localStore.error);
                });
            }
            else {
                store.orderStore
                    .createOrderCargoPalletType(input, (_b = props.transportation) === null || _b === void 0 ? void 0 : _b._id, props.cargoId, props.isPublic)
                    .then(resolve)
                    .catch(function (error) {
                    localStore.error = formUserErrorMessage(error);
                    reject(localStore.error);
                });
            }
        });
    };
    var localStore = useLocalStore(function () { return ({
        error: '',
        isLoading: false,
        onSubmit: function (input) {
            return __awaiter(this, void 0, void 0, function () {
                var val;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localStore.error = '';
                            localStore.isLoading = true;
                            return [4 /*yield*/, addOrEditPalletType(input, localStore)];
                        case 1:
                            val = _a.sent();
                            if (val)
                                toast.success(i18n
                                    .getResource(i18n.language, KEYS.TOAST, 'toast_items_saved')
                                    .replace(/_X_/, i18n.getResource(i18n.language, KEYS.ORDER_FORM, 'create_order_cargo_pallet_type')));
                            return [2 /*return*/, val];
                    }
                });
            });
        },
        clearError: function () {
            localStore.error = '';
        },
    }); });
    var onOk = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var createdItem;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    onPreventEvent(e);
                    return [4 /*yield*/, formRef.current.submitForm()];
                case 1:
                    createdItem = _a.sent();
                    if (!createdItem)
                        throw Error(i18n.getResource(i18n.language, KEYS.TOAST, 'toast_form_not_valid'));
                    if (onChange)
                        onChange(e, createdItem);
                    return [2 /*return*/];
            }
        });
    }); };
    var initialValue = _initialValue || __assign({}, getSnapshot(OrderCargoPalletType.create()));
    ['length', 'width'].forEach(function (e) {
        initialValue[e] = isNaN(parseFloat(initialValue[e])) ? '' : parseFloat(initialValue[e]);
    });
    var dialogBody = (React.createElement(React.Fragment, null,
        (localStore.error && React.createElement(Typography, { color: "error" }, localStore.error)) || null,
        React.createElement(Formik, { key: initialValue === null || initialValue === void 0 ? void 0 : initialValue._id, innerRef: formRef, initialValues: initialValue, validationSchema: Yup.object({
                length: Yup.number()
                    .min(0, langForm.t('form_input_validate_min_size').replace(/_X_/g, '0'))
                    .max(100000000, langForm.t('form_input_validate_max_size').replace(/_X_/g, '100000000'))
                    .required(langForm.t('form_input_validate_required')),
                width: Yup.number()
                    .min(0, langForm.t('form_input_validate_min_size').replace(/_X_/g, '0'))
                    .max(100000000, langForm.t('form_input_validate_max_size').replace(/_X_/g, '100000000'))
                    .required(langForm.t('form_input_validate_required')),
            }), onSubmit: localStore.onSubmit }, function (formik) {
            return (React.createElement(Form, { className: classes.form, id: 'form-order-create-cargo-pallet' },
                React.createElement(Grid, { container: true, direction: "row", spacing: 2 },
                    React.createElement(Grid, { item: true, xs: 12, md: 6 },
                        React.createElement(FormikTextField, { required: true, title: langForm.t('form_input_name_dimension_length'), variant: "outlined", type: "number", name: "length", value: formik.values.length })),
                    React.createElement(Grid, { item: true, xs: 12, md: 6 },
                        React.createElement(FormikTextField, { required: true, title: langForm.t('form_input_good_loading_loads_width'), variant: "outlined", type: "number", name: "width", value: formik.values.width })))));
        })));
    return (React.createElement(BasicDialog, { askCanClose: true, body: dialogBody, title: langOrderForm.t('create_order_cargo_pallet_type'), onOk: onOk, dialogProps: { fullWidth: true, maxWidth: 'md' }, actionInit: function (handleClickOpen) { return (React.createElement(FormikAutocompleteAsyncAdd, __assign({}, props, { v7: true, v17: true, v20: true, isClient: true, onOpenDialog: function (e, _a) {
                var item = _a.item;
                handleClickOpen(e);
                setInitialValue(item);
            }, onChange: function (e, item) { return item && item._id && onChange && onChange(e, item); }, onRemove: function (e) {
                onChange && onChange(e, null);
            }, title: langOrderForm.t('create_order_cargo_pallet_type'), client: client, query: FetchOrderCargoPalletType, addTitle: langOrderForm.t('order_form_add_dimension'), autocompleteProps: __assign(__assign({}, autocompleteProps), { getOptionSelected: function (option, value) {
                    return (option && value && (option._id === value._id || option._id === value)) || null;
                }, getOptionLabel: function (option) {
                    if (!option.length || !option.width)
                        return '';
                    return option.length + " cm X " + option.width + " cm";
                } }), removeQuery: DeleteOrderCargoPalletType, deleteTitle: function (option) { return option.name; }, renderOption: function (option) { return (React.createElement(Grid, { container: true, alignItems: 'center', justify: 'space-between', style: { height: theme.spacing(4.7) } },
                React.createElement(TextOverflow, { style: { maxWidth: "calc(100% - 40px)" } }, option.length + " cm X " + option.width + " cm"),
                !option.isGlobal ? React.createElement(DropDownItemActions, { option: option, canEdit: !option.isGlobal }) : null)); } }))); } }));
});
