var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import gql from 'graphql-tag';
import { BASE_RESPONSE, LOCATION_ADVANCED_BASE, LOCATION_BASE, LOCATION_BASE_LIST } from './base';
import { USER_BASE_MIN } from './user';
export var FetchLocationCountries = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query locationCountries($filter: MainFilter) {\n    locationCountries(filter: $filter) {\n      data {\n        _id\n        count\n      }\n      count\n    }\n  }\n"], ["\n  query locationCountries($filter: MainFilter) {\n    locationCountries(filter: $filter) {\n      data {\n        _id\n        count\n      }\n      count\n    }\n  }\n"])));
export var FetchLocations = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    query locations($filter:MainFilter){\n    locations(filter:$filter) {\n      data {\n        ", "\n         locationAdvanced{\n              ", "\n          }\n      }\n      count\n    }\n  }\n"], ["\n    query locations($filter:MainFilter){\n    locations(filter:$filter) {\n      data {\n        ", "\n         locationAdvanced{\n              ", "\n          }\n      }\n      count\n    }\n  }\n"])), LOCATION_BASE_LIST, LOCATION_ADVANCED_BASE);
export var FetchLocationsMore = gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    query locations($filter:MainFilter){\n    locations(filter:$filter) {\n      data {\n        ", "\n          createdBy{\n              ", "\n          }\n          locationAdvanced{\n              ", "\n          }\n          \n          company{\n            _id\n          }\n      }\n      count\n    }\n  }\n"], ["\n    query locations($filter:MainFilter){\n    locations(filter:$filter) {\n      data {\n        ", "\n          createdBy{\n              ", "\n          }\n          locationAdvanced{\n              ", "\n          }\n          \n          company{\n            _id\n          }\n      }\n      count\n    }\n  }\n"])), LOCATION_BASE_LIST, USER_BASE_MIN, LOCATION_ADVANCED_BASE);
export var FetchLocationPerWarehouse = gql(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    query locationPerWarehouse($_id:MongoId!){\n     locationPerWarehouse(_id:$_id) {\n            ", "\n    }\n  }\n"], ["\n    query locationPerWarehouse($_id:MongoId!){\n     locationPerWarehouse(_id:$_id) {\n            ", "\n    }\n  }\n"])), LOCATION_BASE);
export var CreateLocation = gql(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n mutation createLocation($input:LocationInput!){\n   createLocation(input:$input) { \n      ", "\n    }\n  }\n"], ["\n mutation createLocation($input:LocationInput!){\n   createLocation(input:$input) { \n      ", "\n    }\n  }\n"])), LOCATION_BASE);
export var CheckLocationAdvanced = gql(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n mutation checkLocationAdvanced($input:LocationAdvancedInputItems!){\n     checkLocationAdvanced(input:$input) { \n      ", "\n    }\n  }\n"], ["\n mutation checkLocationAdvanced($input:LocationAdvancedInputItems!){\n     checkLocationAdvanced(input:$input) { \n      ", "\n    }\n  }\n"])), LOCATION_ADVANCED_BASE);
export var UpdateLocation = gql(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n mutation updateLocation($input:LocationInputUpdate!){\n     updateLocation(input:$input) { \n      ", "\n    }\n  }\n"], ["\n mutation updateLocation($input:LocationInputUpdate!){\n     updateLocation(input:$input) { \n      ", "\n    }\n  }\n"])), LOCATION_BASE);
export var UpdateLocationWarehouse = gql(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n mutation updateLocationWarehouse($input:LocationInputUpdate!){\n     updateLocationWarehouse(input:$input) { \n      ", "\n    }\n  }\n"], ["\n mutation updateLocationWarehouse($input:LocationInputUpdate!){\n     updateLocationWarehouse(input:$input) { \n      ", "\n    }\n  }\n"])), LOCATION_BASE);
export var DeleteLocation = gql(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n    mutation deleteLocation($_id: MongoId!) {\n        deleteLocation(_id: $_id) {\n            ", "\n        }\n    }\n"], ["\n    mutation deleteLocation($_id: MongoId!) {\n        deleteLocation(_id: $_id) {\n            ", "\n        }\n    }\n"])), BASE_RESPONSE);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
