var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import { OrderCargoType, OrderLocation, OrderStatus } from '../../../models';
import { TextOverflow } from '../../TextOverflow';
import { PriceComponent } from '../../PriceComponent';
import { Grid, useMediaQuery, useTheme } from '@material-ui/core';
import BaseTooltip from '../../BaseTooltip';
import { StyledBadgeV10 } from '../../StyledBadge';
import { checkIfOrderDetailsIsHide } from '../../../utils1';
import { BaseUserAppTYpe } from '../../../stores';
import { theme } from '../../../theme';
import PreloadingComponent from '../../PreloadingComponent';
import { CMRIcon } from '../../icons';
import clsx from 'clsx';
import ReceiptIcon from '@material-ui/icons/Receipt';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import { dateViewFormatV1, timeViewFormat } from '../../DateView';
import OrderRoutesDirection from './OrderRoutesDirection';
import { onPreventEvent } from '../../Dialog';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { inject } from 'mobx-react';
import { KEYS } from '../../../i18n';
import { useIconStyles } from '../../../styles';
import EmptyItem from './Empty';
import { toDateOnly, toDateOnlyV2 } from '../../../utils';
var OrderRoutesCreatedBy = React.lazy(function () { return import('./OrderRoutesCreatedBy'); });
function OrderRoutesItem(_a) {
    var _b, _c;
    var _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
    var continueContent = _a.continueContent, canViewAdditional = _a.canViewAdditional, helpIcons = _a.helpIcons, v1 = _a.v1, store = _a.store, route = _a.route, index = _a.index, mainIndex = _a.mainIndex, transportation = _a.transportation, order = _a.order, hideActions = _a.hideActions, menuIcon = _a.menuIcon, props = __rest(_a, ["continueContent", "canViewAdditional", "helpIcons", "v1", "store", "route", "index", "mainIndex", "transportation", "order", "hideActions", "menuIcon"]);
    var _q = store.accountStore, currentUser = _q.currentUser, currentCompany = _q.currentCompany;
    var langOrderForm = useTranslation(KEYS.ORDER_FORM);
    var langApp = useTranslation(KEYS.APP);
    var langOrderList = useTranslation(KEYS.ORDER_LIST);
    var iconStyles = useIconStyles();
    var theme1 = useTheme();
    var isSmall = useMediaQuery(theme1.breakpoints.down('sm'));
    var empty = React.createElement(EmptyItem, null);
    var mainBodyStyles = { padding: theme.spacing(1, 0, 1, 2) };
    if (v1) {
        Object.assign(mainBodyStyles, { padding: theme.spacing(3, 4) });
    }
    var isCarrier = currentUser.appType.includes(BaseUserAppTYpe.CARRIER);
    var isForwarder = currentUser.appType.includes(BaseUserAppTYpe.FORWARDER);
    var isCAAllowed = !isCarrier || !((_d = order.approvedCarrier) === null || _d === void 0 ? void 0 : _d._id) || (isCarrier && currentCompany._id === order.approvedCarrier._id);
    var isInProgress = [OrderStatus.ApprovedQuote].includes(order.status) || props.inProgressView;
    var isAdmin = window.location.href.match('localhost:3002') || window.location.href.match('admin');
    var isShowCrmInvoice = order.status === OrderStatus.ApprovedQuote || order.status === OrderStatus.Finished;
    var routeLocation = route && route.origin && OrderLocation.create(route.origin);
    var additional = null;
    var moreAdditional;
    if (index === 1) {
        if ([OrderStatus.HasQuotes, OrderStatus.AcceptedQuote, OrderStatus.WaitingQuotes].includes(order.status)) {
            var customerPrice = (order.price && (React.createElement(TextOverflow, { v3: true },
                React.createElement(PriceComponent, { amount: order.price, currency: order.currency, style: { lineHeight: 1.2 } })))) ||
                null;
            var totalQuotes = (React.createElement(TextOverflow, { variant: 'subtitle1', style: { fontWeight: 500 }, color: 'primary' }, [order.totalQuotes, langApp.t('main_quotes')].filter(function (e) { return e; }).join(' ')));
            var content = ([OrderStatus.HasQuotes, OrderStatus.AcceptedQuote].includes(order.status) && order.totalQuotes && (React.createElement(Grid, { container: true, direction: 'column', item: true, xs: 12, sm: 4, md: 4, lg: 4 },
                order.totalUnViewedQuotes ? (React.createElement(BaseTooltip, { title: langApp.t('none_viewed_offers').replace(/_X_/, order.totalUnViewedQuotes.toString()) },
                    React.createElement(StyledBadgeV10, { badgeContent: order.totalUnViewedQuotes, key: order.totalUnViewedQuotes }, totalQuotes))) : (totalQuotes),
                customerPrice))) ||
                (customerPrice && (React.createElement(Grid, { container: true, direction: 'column', item: true, xs: 12, sm: 4, md: 4, lg: 4 }, customerPrice))) ||
                null;
            moreAdditional = (mainIndex === 0 && (order.totalQuotes || order.price) && content) || null;
        }
        else if (order.latestQuote && !order.noQuotes) {
            moreAdditional = (React.createElement(Grid, { container: true, direction: 'column', item: true, xs: 12, md: props.v4 ? 6 : 4 },
                React.createElement(PriceComponent, { v1: true, hide: checkIfOrderDetailsIsHide(store, order), amount: order.latestQuote.amount, currency: order.latestQuote.currency || order.currency, style: { lineHeight: 1.2, fontSize: '0.9rem', fontWeight: 600 } }),
                (order.latestQuote && order.latestQuote.createdBy && (React.createElement(Grid, { container: true },
                    React.createElement(Grid, { item: true, xs: 12, md: 12 },
                        React.createElement(TextOverflow, { variant: 'caption', style: { display: 'block' } },
                            order.latestQuote.createdBy.company && order.latestQuote.createdBy.company.name,
                            order.latestQuote.createdBy.appType &&
                                !order.latestQuote.createdBy.appType.includes(BaseUserAppTYpe.FORWARDER) &&
                                ", " + [order.latestQuote.createdBy.firstName, order.latestQuote.createdBy.lastName]
                                    .filter(function (e) { return e; })
                                    .join(',')))))) ||
                    null));
        }
    }
    var documentStatus = isCAAllowed && !hideActions && isShowCrmInvoice;
    var orderIdDetails = (React.createElement(Grid, { container: true, direction: 'column', item: true, xs: 12, md: props.v4 ? 6 : 3, style: { width: 'initial', position: 'relative' } },
        React.createElement("div", { style: { display: 'flex', maxWidth: '100%' } },
            React.createElement(TextOverflow, { v3: true, id: 'order-list-orderId', valueToCopy: (order.isTemplate && order.templateName) || order.orderId || '-', variant: 'subtitle1', style: {
                    lineHeight: '150%',
                    fontSize: '0.9rem',
                    fontWeight: 600,
                    color: theme.palette.success.light,
                } }, "#" + ((order.isTemplate && order.templateName) || order.orderId || '-'))),
        order.orderCustomId ? (React.createElement("div", { style: { display: 'flex', maxWidth: '100%', marginTop: theme.spacing(1) } },
            React.createElement(TextOverflow, { v3: true, v9: true, variant: 'subtitle1', style: {
                    fontSize: '0.75rem',
                    fontWeight: 600,
                    marginTop: theme.spacing(-0.5),
                } }, order.orderCustomId))) : null));
    var managerComponent = (React.createElement(PreloadingComponent, { style: { maxWidth: 'calc(100% - 46px)' } },
        React.createElement(OrderRoutesCreatedBy, { createdBy: order.manager, style: __assign({ padding: theme.spacing(0, 0.5), maxWidth: "calc(100% - " + (isAdmin ? 97 : documentStatus ? 81 : 24) + "px)", width: '100%' }, (isSmall && { alignItems: 'flex-end' })), textStyle: __assign({}, (isSmall && { textAlign: 'end' })) })));
    var hasInvoice = order.sorting_hasInvoice || (!isForwarder && !isCarrier && order.sorting_customerHasInvoice);
    var actionDetail = (!props.v4 && index !== 0 && (React.createElement(Grid, { container: true, alignItems: 'center', direction: 'row', justify: 'space-between' },
        orderIdDetails,
        moreAdditional,
        React.createElement(Grid, { container: true, alignItems: 'center', justify: 'space-between', item: true, xs: 12, md: 5, "data-id": 'izi-additional-info', style: __assign({ width: 'initial', position: 'relative' }, (isSmall && { justifyContent: 'flex-end' })) },
            !isSmall ? managerComponent : null,
            React.createElement(Grid, { container: true, "data-id": 'izi-file-status', alignItems: 'center', justify: 'space-between', style: { width: 'initial', position: 'relative' } },
                documentStatus ? (React.createElement(React.Fragment, null,
                    React.createElement(BaseTooltip, { title: order.sorting_hasCMR
                            ? langOrderList.t('in_progress_has_cmr')
                            : langOrderList.t('in_progress_has_not_cmr'), style: { marginRight: 3, marginTop: 3 } },
                        React.createElement("span", { "data-id": order.sorting_hasCMR
                                ? langOrderList.t('in_progress_has_cmr')
                                : langOrderList.t('in_progress_has_not_cmr') },
                            React.createElement(CMRIcon, { style: { width: 20, height: 20 }, className: clsx(iconStyles.orderListDefaultIcon, (_b = {},
                                    _b[iconStyles.orderListIcon] = order.sorting_hasCMR,
                                    _b)) }))),
                    React.createElement(BaseTooltip, { title: hasInvoice
                            ? langOrderList.t('in_progress_has_invoice_help')
                            : langOrderList.t('in_progress_has_not_invoice'), style: { marginRight: 5, marginTop: 4 } },
                        React.createElement("span", { "data-id": hasInvoice
                                ? langOrderList.t('in_progress_has_invoice_help')
                                : langOrderList.t('in_progress_has_not_invoice') },
                            React.createElement(ReceiptIcon, { className: clsx(iconStyles.orderListDefaultIcon, (_c = {},
                                    _c[iconStyles.orderListIcon] = hasInvoice,
                                    _c)), style: { width: 20, transform: 'scale(1.25)' } }))))) : null,
                !props.v3 && menuIcon))))) ||
        (props.v4 && (React.createElement(Grid, { container: true, alignItems: 'center', direction: 'row', justify: 'space-between' },
            orderIdDetails,
            moreAdditional))) ||
        null;
    if (index === 0) {
        var isSmall1 = useMediaQuery(theme.breakpoints.down('sm'));
        var certificates = ((_e = order === null || order === void 0 ? void 0 : order.certificates) === null || _e === void 0 ? void 0 : _e.length) > 0 ? (React.createElement(Grid, { item: true, xs: 8, style: {
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                paddingRight: theme.spacing(isSmall1 ? 1 : 3),
                gap: 1,
            } },
            React.createElement(TextOverflow, { variant: 'subtitle1', style: { fontSize: '0.65rem', maxWidth: "calc(100% - 24px)" } }, (order.certificates.length > 1 ? langApp.t('app_certificates') : langApp.t('app_certificate')) + "\n                " + order.certificates.map(function (e) { return e.name; }).join(',')),
            React.createElement(BaseTooltip, { tooltip: langApp.t('app_certificate') },
                React.createElement(AssignmentTurnedInIcon, { style: { paddingBottom: '1px', fontSize: '0.85rem', color: theme.palette.success.light } })))) : null;
        additional = (React.createElement(Grid, { container: true, alignItems: "center", justify: "flex-start", style: { paddingRight: theme.spacing(4) } },
            React.createElement(Grid, { container: true, item: true, xs: 12 },
                React.createElement(Grid, { container: true, item: true, xs: 12, justify: 'center', style: { marginBottom: theme.spacing(1) } },
                    React.createElement(Grid, { item: true, xs: certificates ? 4 : 12 },
                        React.createElement(TextOverflow, { variant: 'subtitle1', style: { lineHeight: 1.2, fontWeight: 600 } }, [order.sorting_totalWeight, isAdmin && order.distance].filter(function (e) { return e; }).join(', '))),
                    certificates),
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(TextOverflow, { variant: 'caption', style: { lineHeight: 1.3, display: 'block', fontWeight: 600 } }, ['cargoType', 'vehicleType', 'loadingType', 'transport_other']
                        .map(function (key, index) {
                        return ((Array.isArray(order[key])
                            ? order[key].map(function (e) { return e.name || e; }).join(', ')
                            : index === 0
                                ? langOrderForm.t(OrderCargoType.PTLTrackLoad === order[key]
                                    ? 'create_order_cargo_ptl_short'
                                    : 'create_order_cargo_ftl_short')
                                : order[key]) || '').trim();
                    })
                        .filter(function (e) { return e; })
                        .join(', '))))));
    }
    else {
        additional = (mainIndex === 0 && actionDetail) || null;
    }
    var _mainBodyStyles = __assign({}, mainBodyStyles);
    if (index === 1 && props.v2) {
        Object.assign(_mainBodyStyles, { padding: theme.spacing(1, 2) });
    }
    if (props.v4) {
        Object.assign(_mainBodyStyles, { padding: 0 });
    }
    var isApproved = order.status === OrderStatus.ApprovedQuote;
    var isFinished = order.status === OrderStatus.Finished;
    var dates = (isCAAllowed &&
        [
            (isApproved || isFinished
                ? // ? dateViewFormatV1(route.orderTransportation[(index === 0 && 'startDate') || 'endDate']) +
                    dateViewFormatV1(canViewAdditional || !(isApproved || isFinished) || !order.sorting_pickupAt
                        ? route.orderTransportation[(index === 0 && 'startDate') || 'endDate']
                        : order[(index === 0 && 'sorting_pickupAt') || 'sorting_deliveryAt']) +
                        ((route.orderTransportation[(index === 0 && 'startTime') || 'endTime'] &&
                            " " + langApp.t('date_at') + " " + timeViewFormat(route.orderTransportation[(index === 0 && 'startTime') || 'endTime'][0])) ||
                            '')
                : route[(mainIndex === 0 && 'startDate') || 'endDate'] &&
                    dateViewFormatV1(route[(mainIndex === 0 && 'startDate') || 'endDate'])) || '-',
            isApproved || isFinished ? '' : (route.endDate && dateViewFormatV1(route.endDate)) || '-',
        ].join(isApproved || isFinished ? '' : ' - ')) ||
        null;
    var arrivedDateValue = transportation[(index === 0 && 'arrivedToPickup') || 'arrivedToDelivery'];
    var arrivedDates = (isCAAllowed && arrivedDateValue && dateViewFormatV1(arrivedDateValue, 1)) || null;
    var availableDates = isApproved || isFinished ? [dateViewFormatV1(route.startDate), dateViewFormatV1(route.endDate)].join('-') : null;
    var dateMarginLeft = 39;
    var paddingFromArrowRight = index === 1 && !isSmall && !props.v5 && { paddingLeft: theme.spacing(4) };
    var isShowBookingChanged = (transportation["members" + (index === 0 ? 'Start' : 'End') + "BookingUpdateUnread"] || []).find(function (user) { return user._id === currentUser._id; });
    var warehouseInfo = __spread(new Set([(routeLocation === null || routeLocation === void 0 ? void 0 : routeLocation.companyName) || ((_f = routeLocation === null || routeLocation === void 0 ? void 0 : routeLocation.companyId) === null || _f === void 0 ? void 0 : _f.name), (_h = (_g = route === null || route === void 0 ? void 0 : route.origin) === null || _g === void 0 ? void 0 : _g.warehouse) === null || _h === void 0 ? void 0 : _h.name].filter(function (e) { return e; }))).join(', ');
    warehouseInfo = warehouseInfo ? (React.createElement(TextOverflow, { variant: 'subtitle2', style: { display: 'flex', alignItems: 'center' } }, warehouseInfo)) : (empty);
    if (order.sorting_pickupAt && ((_l = (_k = (_j = route === null || route === void 0 ? void 0 : route.origin) === null || _j === void 0 ? void 0 : _j.warehouse) === null || _k === void 0 ? void 0 : _k.company) === null || _l === void 0 ? void 0 : _l._id) === currentCompany._id) {
        warehouseInfo = (React.createElement(Link, { to: "/warehouses/" + ((_o = (_m = route === null || route === void 0 ? void 0 : route.origin) === null || _m === void 0 ? void 0 : _m.warehouse.parent) === null || _o === void 0 ? void 0 : _o._id) + "/calendar?ramps=" + ((_p = route === null || route === void 0 ? void 0 : route.origin) === null || _p === void 0 ? void 0 : _p.warehouse._id) + "&date=" + toDateOnlyV2(order["sorting_" + (index === 0 ? 'pickup' : 'delivery') + "At"] || toDateOnly(route.startDate)) }, warehouseInfo));
    }
    var isShowHelpIcons = index === 0 && !props.isPreparation && !(isInProgress && !isAdmin) && isCAAllowed && helpIcons;
    var helpIconsComponent = (React.createElement(Grid, { container: true, md: 1, sm: 12, style: __assign({ width: "30px", flexDirection: isSmall ? 'row' : 'column', padding: theme.spacing(1, 0), alignItems: 'center' }, (isSmall && { width: "100%" })), spacing: 1 }, helpIcons));
    var isShowHelpIconsFirst = !props.v5 || isSmall;
    return (React.createElement(React.Fragment, null,
        React.createElement(Grid, { container: true, alignItems: "center", justify: "space-between", style: __assign({}, _mainBodyStyles) },
            (isShowHelpIcons && isShowHelpIconsFirst && helpIconsComponent) || null,
            React.createElement(Grid, { md: props.v5 ? 4 : props.v4 ? 6 : index === 0 ? (isInProgress && !isAdmin ? 5 : 4) : 3, xs: 6, sm: 6, item: true, container: true, alignItems: "center", justify: "flex-start", style: __assign({}, paddingFromArrowRight) },
                React.createElement(OrderRoutesDirection, { v1: true, route: route }),
                route ? (React.createElement(Grid, { container: true, style: {
                        marginTop: theme.spacing(1),
                        marginLeft: dateMarginLeft,
                        width: "calc(100% - " + dateMarginLeft + "px)",
                    } },
                    React.createElement(Grid, __assign({ container: true, alignItems: "flex-start" }, (additional && { direction: 'column' }), { justify: "flex-start" }),
                        React.createElement(Grid, { container: true, alignItems: 'center', justify: 'flex-start' },
                            React.createElement(TextOverflow, { variant: 'subtitle1', style: __assign({ fontWeight: 700, lineHeight: 1, fontSize: '0.68rem' }, (!arrivedDates &&
                                    isShowBookingChanged && {
                                    color: theme.palette.error.light,
                                })) }, arrivedDates || dates)),
                        (availableDates && (React.createElement(TextOverflow, { variant: 'caption', style: { lineHeight: 1, fontSize: '0.68rem', marginTop: theme.spacing(0.5) } }, availableDates))) ||
                            null))) : (empty)),
            index === 0 && isSmall ? (React.createElement(Grid, { md: props.v5 ? 4 : props.v4 ? 6 : index === 0 ? (isInProgress && !isAdmin ? 5 : 4) : 3, xs: 6, sm: 6, item: true, container: true, alignItems: "center", justify: "flex-end" }, managerComponent)) : null,
            route ? (React.createElement(Grid, { container: true, alignItems: "center", justify: "flex-start", md: props.v5 ? (isShowHelpIcons ? 7 : 8) : props.v4 ? 6 : index === 0 ? 7 : 9, sm: 12 }, additional)) : (empty),
            (isShowHelpIcons && !isShowHelpIconsFirst && helpIconsComponent) || null),
        canViewAdditional ? (React.createElement(Grid, { container: true, direction: 'column', style: __assign(__assign({}, _mainBodyStyles), { paddingTop: 0 }), onClick: onPreventEvent },
            routeLocation && routeLocation.fullAddress ? (React.createElement("div", { style: __assign({ display: 'flex', maxWidth: '100%' }, paddingFromArrowRight) },
                React.createElement(TextOverflow, { variant: 'h6', v3: true, style: { lineHeight: 'initial' } }, routeLocation.fullAddress))) : (empty),
            React.createElement(Grid, { container: true, justify: 'space-between', style: __assign({}, paddingFromArrowRight) },
                warehouseInfo,
                React.createElement("span", { style: index === 0 ? { visibility: 'hidden' } : {} }, continueContent)))) : null));
}
export default inject('store')(OrderRoutesItem);
