var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
export var withGeneralStates = function (Component) {
    return function (props) {
        var _a = __read(React.useState(false), 2), loading = _a[0], setLoading = _a[1];
        var _b = __read(React.useState(null), 2), error = _b[0], setError = _b[1];
        var _c = __read(React.useState(null), 2), data = _c[0], setData = _c[1];
        var init = function () {
            setError(null);
            setData(null);
            setLoading(true);
        };
        var onFinish = function (result) {
            try {
                setError(null);
                setData(result.data[Object.keys(result.data)[0]]);
            }
            catch (e) {
                setError(e.message || e);
            }
            finally {
                setLoading(false);
            }
        };
        return (React.createElement(Component, __assign({}, { generalStates: { data: data, onFinish: onFinish, setData: setData, loading: loading, setLoading: setLoading, error: error, setError: setError, init: init } }, props)));
    };
};
