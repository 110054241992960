var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { inject, observer, useObserver } from 'mobx-react';
import React from 'react';
import { getSnapshot } from 'mobx-state-tree';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../../../i18n';
import { canEdit } from '../utils';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { Checkbox, FormControlLabel, Grid, LinearProgress, useMediaQuery } from '@material-ui/core';
import InputTitle from '../../../Formik/InputTitle';
import { FormikTextField } from '../../../Formik';
import { useCargoFormStyles } from './CargoForm';
import { TextOverflow } from '../../../TextOverflow';
import { theme } from '../../../../theme';
import { useSectionStyles } from '../../../../styles';
import PaddingSpace from './PaddingSpce';
import { FetchOrderPreparingStatuses } from '../../../../graphql/order/orderPreparing';
import { FormikCheckBoxField } from '../../../Formik/FormikCheckboxField';
import moment from 'moment';
import { SingleDatePicker } from '../../../DatePicker';
import { OrderCreationMode } from '../../../../pages/Order/FormV1';
import PreloadingComponent from '../../../PreloadingComponent';
import { langTranslateValue } from '../../../../utils1';
import UploadWithDragAndDrop from '../../../UploadWithDragAndDrop';
import { FetchUploads } from '../../../../graphql';
import { BaseUserAppTYpe } from '../../../../stores';
var PreparationAssignDate = React.lazy(function () { return import('./PreparationAssignDate'); });
export default inject('store')(observer(React.forwardRef(function (_a, ref) {
    var store = _a.store, index = _a.index, defaultValue = _a.defaultValue, open = _a.open, config = _a.config, mode = _a.mode, props = __rest(_a, ["store", "index", "defaultValue", "open", "config", "mode"]);
    var langOrderForm = useTranslation(KEYS.ORDER_FORM);
    var langForm = useTranslation(KEYS.FORM);
    var langApp = useTranslation(KEYS.APP);
    var noEdit = !canEdit(store.orderStore.order, store); // || store.orderStore.isOrderUpdating;
    var classes = useCargoFormStyles();
    var classesSection = useSectionStyles();
    var _b = __read(React.useState(null), 2), statuses = _b[0], setStatuses = _b[1];
    var _c = __read(React.useState([]), 2), files = _c[0], setFiles = _c[1];
    var _d = __read(React.useState(false), 2), loading = _d[0], setLoading = _d[1];
    var _e = __read(React.useState(1), 2), updated = _e[0], setUpdated = _e[1];
    var client = config.client;
    var order = store.orderStore.order;
    var copyDef = JSON.parse(JSON.stringify(defaultValue || getSnapshot(order)));
    var isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    var _f = __read(React.useState(copyDef), 2), formValues = _f[0], setFormValues = _f[1];
    var loadData = function () { return __awaiter(void 0, void 0, void 0, function () {
        var res, res1, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setLoading(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 5, 6, 7]);
                    if (!client) return [3 /*break*/, 4];
                    return [4 /*yield*/, client.query({
                            query: FetchOrderPreparingStatuses,
                            variables: {
                                filter: { fields: [], sortBy: 'order', sortDir: 'asc' },
                            },
                        })];
                case 2:
                    res = _a.sent();
                    setStatuses(res.data.orderPreparingStatuses.data);
                    return [4 /*yield*/, client.query({
                            query: FetchUploads,
                            variables: {
                                filter: { fields: [{ name: 'parent', value: copyDef._id }] },
                            },
                        })];
                case 3:
                    res1 = _a.sent();
                    setFiles(res1.data.uploads.data);
                    _a.label = 4;
                case 4: return [3 /*break*/, 7];
                case 5:
                    e_1 = _a.sent();
                    console.log('error on load order preparation statuses', e_1);
                    return [3 /*break*/, 7];
                case 6:
                    setLoading(false);
                    setUpdated(Date.now());
                    return [7 /*endfinally*/];
                case 7: return [2 /*return*/];
            }
        });
    }); };
    React.useEffect(function () {
        if (!order.preparedAt && !(statuses === null || statuses === void 0 ? void 0 : statuses.length) && open) {
            loadData();
        }
    }, [open, order.preparedAt]);
    function onSubmit(input) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, input];
            });
        });
    }
    function onChange(input, opt) {
        if (opt === void 0) { opt = {}; }
        return __awaiter(this, void 0, void 0, function () {
            var i, list, name_1;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        i = 0, list = Object.keys(input);
                        _b.label = 1;
                    case 1:
                        if (!(i < list.length)) return [3 /*break*/, 4];
                        name_1 = list[i];
                        return [4 /*yield*/, ref.current.setFieldValue(name_1, input[name_1])];
                    case 2:
                        _b.sent();
                        if (ref.current.errors[name_1])
                            return [2 /*return*/, ref.current.setFieldTouched(name_1)];
                        _b.label = 3;
                    case 3:
                        i++;
                        return [3 /*break*/, 1];
                    case 4:
                        if (!opt.filterStates) return [3 /*break*/, 6];
                        return [4 /*yield*/, onSave(__assign((_a = {}, _a['preparingStatuses'] = ref.current.values.preparingStatuses
                                .map(function (state, index) {
                                if (state.checked) {
                                    return { value: statuses[index]._id, estimatedAt: state.estimatedAt };
                                }
                            })
                                .filter(function (e) { return e; }), _a), (input.hasPreparing ? { hasPreparing: input.hasPreparing } : {})))];
                    case 5:
                        _b.sent();
                        return [3 /*break*/, 8];
                    case 6: return [4 /*yield*/, onSave(input)];
                    case 7:
                        _b.sent();
                        _b.label = 8;
                    case 8: return [2 /*return*/];
                }
            });
        });
    }
    function onSave(input) {
        return __awaiter(this, void 0, void 0, function () {
            var newVal;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, store.orderStore.updateOrder(__assign(__assign({}, input), { _id: formValues._id }))];
                    case 1:
                        _a.sent();
                        newVal = __assign(__assign({}, formValues), input);
                        setFormValues(newVal);
                        return [2 /*return*/];
                }
            });
        });
    }
    var formProps = {
        onSubmit: onSubmit,
    };
    Object.assign(formProps, {
        validationSchema: Yup.object(Object.assign({}, order.hasPreparing
            ? {
                preparingStatuses: Yup.array().of(Yup.object({
                    estimatedAt: Yup.mixed().test('validate estimate at', langForm.t('form_input_validate_required'), function (value) {
                        var shouldExist = this.from[0].value.checked && this.from[1].value.hasPreparing;
                        return !shouldExist || this.from[0].value.estimatedAt;
                    }),
                })),
                form: Yup.mixed().test('validate form', langForm.t('form_input_validate_required'), function (value) {
                    return !!this.from[0].value.preparingStatuses.find(function (e) { return e.checked; });
                }),
            }
            : {}, mode === OrderCreationMode.PREPARATION
            ? {
                hasPreparing: Yup.boolean().test('hasPreparing should be true', langForm.t('form_input_validate_required'), function (value) {
                    return !!this.from[0].value.hasPreparing;
                }),
            }
            : {})),
    });
    var preparingStatuses = order.preparingStatuses || [];
    return useObserver(function () { return (React.createElement(Grid, { container: true, direction: 'column', className: classesSection.section },
        React.createElement(Grid, { container: true },
            React.createElement(PaddingSpace, null),
            React.createElement(Grid, { item: true, xs: 12, md: 11 },
                React.createElement(TextOverflow, { variant: "h4", className: classesSection.sectionTitle }, langApp.t('app_preparation')))),
        order.preparedAt ? (React.createElement(Grid, { container: true },
            React.createElement(PaddingSpace, null),
            React.createElement(Grid, { item: true, xs: 12, md: 10 },
                React.createElement(PreloadingComponent, null,
                    React.createElement(PreparationAssignDate, { withDocuments: true, config: config, preparingStatuses: order.preparingStatuses }))))) : (React.createElement(Formik, __assign({ key: updated, innerRef: ref, validateOnBlur: false, 
            // validateOnChange={false}
            initialValues: {
                preparingStatuses: (statuses || []).map(function (status) {
                    var orderState = preparingStatuses.find(function (e) { return e.value._id.toString() === status._id.toString(); });
                    return {
                        name: status.name,
                        estimatedAt: (orderState === null || orderState === void 0 ? void 0 : orderState.estimatedAt) ? orderState === null || orderState === void 0 ? void 0 : orderState.estimatedAt : null,
                        checked: !!orderState,
                    };
                }),
                hasPreparing: order.hasPreparing,
                preparingComment: order.preparingComment,
            } }, formProps), function (formik) {
            var addRemoveStatusName = 'checked';
            function onChangeFiles(e) {
                setFiles(e.target.value);
            }
            function onChangeInput(e, checked) {
                var _a;
                var isHasPreparingName = ['hasPreparing'].includes(e.target.name);
                var isHasPreparing = isHasPreparingName && checked;
                onChange(__assign((_a = {}, _a[e.target.name] = isHasPreparingName ? checked : e.target.value, _a), (isHasPreparing
                    ? { preparingStatuses: formik.values.preparingStatuses.map(function (e) { return (__assign(__assign({}, e), { checked: true })); }) }
                    : {})), { filterStates: isHasPreparing });
            }
            function onChangeStatusState(e, stateIndex) {
                var _a;
                var newOrderPreparingStatusState = formik.values.preparingStatuses.map(function (status, index) {
                    var _a;
                    if (index === stateIndex)
                        return __assign(__assign({}, status), (_a = {}, _a[e.target.name] = e.target.name === addRemoveStatusName ? e.target.checked : e.target.value, _a));
                    else
                        return __assign({}, status);
                });
                onChange((_a = {}, _a['preparingStatuses'] = newOrderPreparingStatusState, _a), { filterStates: 1 });
            }
            var preparationForm = (React.createElement(React.Fragment, null,
                React.createElement(Grid, { container: true, style: { height: theme.spacing(2) } },
                    React.createElement(PaddingSpace, null),
                    formik.errors.form ? (React.createElement(TextOverflow, { v2: true }, langOrderForm.t('order_form_zero_preparation_steps_error'))) : null),
                React.createElement(Grid, { container: true },
                    React.createElement(PaddingSpace, null),
                    React.createElement(Grid, { item: true, xs: 12, md: 8, style: { marginBottom: theme.spacing(2) } },
                        React.createElement(FormControlLabel, { classes: {
                                root: classes.checkBoxRoot,
                                label: classes.checkBoxLabel,
                            }, control: React.createElement(Checkbox, { disabled: noEdit, checked: formik.values.hasPreparing, name: "hasPreparing", onChange: onChangeInput, color: "primary" }), label: React.createElement(InputTitle, { v1: true, title: langOrderForm.t('order_form_enable_preparation_tracking') }) }))),
                React.createElement(Grid, { container: true, alignItems: "center", spacing: 3 },
                    React.createElement(PaddingSpace, null),
                    React.createElement(Grid, { item: true, xs: 12, md: 10 }, loading ? (React.createElement(Grid, { container: true, direction: 'column', className: classesSection.section, style: { padding: theme.spacing(4), height: theme.spacing(10) } },
                        React.createElement(LinearProgress, null))) : (React.createElement(Grid, { container: true }, (statuses === null || statuses === void 0 ? void 0 : statuses.length) ? statuses.map(function (status, index) {
                        var _a, _b;
                        var midDate = undefined;
                        for (var i = index - 1; i >= 0; i--) {
                            if (formik.values.preparingStatuses.length &&
                                formik.values.preparingStatuses[i].estimatedAt) {
                                midDate = moment(new Date(formik.values.preparingStatuses[i].estimatedAt));
                            }
                        }
                        return (React.createElement(Grid, { container: true, style: {
                                paddingBottom: theme.spacing(1),
                                height: isSmall ? undefined : theme.spacing(8),
                            } },
                            React.createElement(Grid, { item: true, xs: 12, md: 7, style: __assign({ paddingTop: theme.spacing(0.8) }, (isSmall && { marginBottom: theme.spacing(2) })) },
                                React.createElement(TextOverflow, null,
                                    index + 1,
                                    ". ",
                                    langTranslateValue(status.name, store.accountStore))),
                            React.createElement(Grid, { item: true, xs: 9, md: 3, style: __assign({}, (isSmall && { marginBottom: theme.spacing(2) })) },
                                React.createElement(SingleDatePicker
                                // v1
                                // inputStyle={{ height: theme.spacing(5.9) }}
                                , { 
                                    // v1
                                    // inputStyle={{ height: theme.spacing(5.9) }}
                                    withLabel: true, isFormik: true, disabled: !formik.values.hasPreparing, name: "preparingStatuses[" + index + "].estimatedAt", variant: "outlined", InputProps: {
                                        placeholder: 'Planned date',
                                    }, minDate: midDate, value: ((_a = formik.values.preparingStatuses[index]) === null || _a === void 0 ? void 0 : _a.estimatedAt) || null, onChange: function (e) {
                                        var _a;
                                        return onChangeStatusState({ target: { name: 'estimatedAt', value: (_a = e.target.value) === null || _a === void 0 ? void 0 : _a.toDate() } }, index);
                                    } })),
                            React.createElement(Grid, { item: true, container: true, xs: 3, md: 2, style: {
                                    justifyContent: 'flex-start',
                                    alignItems: 'flex-start',
                                    paddingLeft: theme.spacing(3),
                                } },
                                React.createElement(FormikCheckBoxField, { color: "primary", checkboxStyle: { padding: 3.5 }, disabled: !formik.values.hasPreparing, v14: true, name: "preparingStatuses[" + index + "].checked", onChange: function (e) {
                                        onChangeStatusState(__assign(__assign({}, e), { target: __assign(__assign({}, e.target), { name: 'checked' }) }), index);
                                    }, value: ((_b = formik.values.preparingStatuses[index]) === null || _b === void 0 ? void 0 : _b.checked) || false }))));
                    })
                        : null)))),
                React.createElement(Grid, { container: true, style: { marginBottom: theme.spacing(1) } },
                    React.createElement(PaddingSpace, null),
                    React.createElement(Grid, { container: true, direction: "column", item: true, xs: 12, md: 10 },
                        React.createElement(FormikTextField, { multiline: true, rows: 4, title: langForm.t('form_btn_to_comment'), variant: "outlined", name: "preparingComment", onBlur: onChangeInput, value: formik.values.preparingComment }))),
                React.createElement(Grid, { container: true },
                    React.createElement(PaddingSpace, null),
                    React.createElement(Grid, { container: true, direction: "column", item: true, xs: 12, md: 10 },
                        React.createElement(UploadWithDragAndDrop, { config: config, value: files, name: 'files', onChange: onChangeFiles, item: copyDef, docType: 'PreparingOrder', UploadProps: {
                                access: [BaseUserAppTYpe.CUSTOMER, BaseUserAppTYpe.SENDER],
                                folder: "private/orders/" + copyDef._id + "/preparationSteps/",
                            }, parent_collection_name: 'orders' })))));
            return (React.createElement(Form, { id: "declaration-form", style: { width: '100%' } }, preparationForm));
        })))); });
})));
