var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import { types } from 'mobx-state-tree';
import { MaybeEmptyModel } from './MaybeEmptyModel';
import { UserForwarder } from './BaseUserShort';
import { ORDER_CARGO_WEIGHT_MEASURE } from '../../models';
var is_USA_APP = parseInt(process.env.REACT_APP_APP_FOR_USA || '0') === 1;
export var BaseCompanySettings = types.model('BaseCompanySettings', {
    only_my_companies_receive_notifications: types.maybeNull(types.boolean),
    has_email_notifications: types.maybeNull(types.boolean),
    canViewAllOrders: types.maybeNull(types.boolean),
    canViewAllOrderBookings: types.maybeNull(types.boolean),
    chat_open_from_my_team: types.maybeNull(types.boolean),
    chat_open_from_to_team: types.maybeNull(types.boolean),
    canPublishOrderForAnotherUser: types.maybeNull(types.boolean),
    hasToDo: types.maybeNull(types.boolean),
    isViewAllOrders: types.maybeNull(types.boolean),
    isIziBranding: types.maybeNull(types.boolean),
    autoArchiveOrderInDays: types.maybeNull(types.number),
    autoArchiveOpenOrderOrderInDays: types.maybeNull(types.number),
    paymentAutoApprove: types.maybeNull(types.number),
    invoiceTermPayment: types.maybeNull(types.number),
    affiliateFraction: types.maybeNull(types.number),
    affiliateRevenue: types.maybeNull(types.number),
    weightMeasure: types.maybeNull(types.optional(types.enumeration(is_USA_APP ? ORDER_CARGO_WEIGHT_MEASURE.Pounds : ORDER_CARGO_WEIGHT_MEASURE.Tonnes, __spread([
        'lb'
    ], Object.values(ORDER_CARGO_WEIGHT_MEASURE))), is_USA_APP ? ORDER_CARGO_WEIGHT_MEASURE.Pounds : ORDER_CARGO_WEIGHT_MEASURE.Tonnes)),
    accounting_emails: types.maybeNull(types.optional(types.array(types.string), [])),
    chat_users: types.maybeNull(types.optional(types.array(MaybeEmptyModel(UserForwarder)), [])),
    isDefaultFastPayment: types.maybeNull(types.optional(types.boolean, false)),
    defaultFastPaymentPercentage: types.maybeNull(types.optional(types.number, 0)),
});
