var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { applySnapshot, castToSnapshot, types } from 'mobx-state-tree';
import { AsyncActionStatus, MaybeEmptyModel, ShortCompany } from '../stores/common';
import { OrderLocationAddress } from './LocationAddress';
import { BaseModel } from './BaseModel';
export var EWarehouse = types
    .compose(AsyncActionStatus, types
    .model('EWarehouse', {
    _id: types.maybeNull(types.optional(types.string, function () { return ''; })),
    updatedAt: types.maybeNull(types.optional(types.string, '')),
    // updatedBy: types.maybeNull(types.optional(MaybeEmptyModel(User), () => ({}))),
    // createdBy: types.maybeNull(types.optional(MaybeEmptyModel(User), () => ({}))),
    description: types.maybeNull(types.optional(types.string, '')),
    name: types.maybeNull(types.optional(types.string, '')),
    manager: types.maybeNull(types.optional(types.string, '')),
    phones: types.maybeNull(types.optional(types.array(types.string), [])),
    emails: types.maybeNull(types.optional(types.array(types.string), [])),
    parent: types.maybeNull(MaybeEmptyModel(BaseModel)),
})
    .actions(function (self) { return ({
    clear: function () {
        var snap = castToSnapshot(EWarehouse.create());
        applySnapshot(self, snap);
    },
    reset: function (snapshot) {
        applySnapshot(self, __assign(__assign({}, snapshot), { updatedAt: new Date().toString() }));
    },
}); })
    .views(function (self) { return ({}); }))
    .named('EWarehouse');
export var OrderLocationAdvanced = types.model('OrderLocationAdvanced', {
    _id: types.optional(types.identifier, ''),
    access: types.maybeNull(types.optional(types.array(types.string), [])),
    email: types.maybeNull(types.optional(types.array(types.string), [])),
    documentType: types.maybeNull(types.optional(MaybeEmptyModel(BaseModel), function () { return BaseModel.create(); })),
});
export var OrderLocationMember = types.model('OrderLocationMember', {
    name: types.maybeNull(types.optional(types.string, '')),
    phone: types.maybeNull(types.optional(types.string, '')),
    email: types.maybeNull(types.optional(types.string, '')),
    notify: types.maybeNull(types.optional(types.array(types.string), [])),
});
export var OrderLocationMode = {
    SIMPLE: 1,
    ADVANCED: 2,
};
export var OrderLocation = types
    .model('OrderLocation', {
    _id: types.optional(types.identifier, ''),
    name: types.maybeNull(types.optional(types.string, '')),
    companyName: types.maybeNull(types.optional(types.string, '')),
    companyVat: types.optional(types.string, ''),
    address: types.maybeNull(types.optional(OrderLocationAddress, function () { return OrderLocationAddress.create(); })),
    companyAddress: types.maybeNull(types.optional(OrderLocationAddress, function () { return OrderLocationAddress.create(); })),
    locationAdvanced: types.maybeNull(types.optional(types.array(MaybeEmptyModel(OrderLocationAdvanced)), [])),
    companyId: types.maybeNull(types.optional(MaybeEmptyModel(ShortCompany), function () { return ShortCompany.create(); })),
    warehouse: types.maybeNull(types.optional(MaybeEmptyModel(EWarehouse), function () { return EWarehouse.create(); })),
    warehouseTemp: types.maybeNull(types.optional(MaybeEmptyModel(EWarehouse), function () { return EWarehouse.create(); })),
    members: types.maybeNull(types.optional(types.array(MaybeEmptyModel(OrderLocationMember)), function () { return []; })),
    additionalMembers: types.maybeNull(types.optional(types.array(MaybeEmptyModel(OrderLocationMember)), function () { return []; })),
    mode: types.maybeNull(types.optional(types.number, OrderLocationMode.SIMPLE)),
    startTime: types.maybeNull(types.optional(types.string, '')),
    endTime: types.maybeNull(types.optional(types.string, '')),
    approvedAt: types.maybeNull(types.optional(types.string, '')),
    isDeleted: types.maybeNull(types.optional(types.boolean, false)),
    email: types.maybeNull(types.optional(types.array(types.string), [])),
    warehouses: types.maybeNull(types.optional(types.array(MaybeEmptyModel(EWarehouse)), [])),
    my_warehouses: types.maybeNull(types.optional(types.array(MaybeEmptyModel(EWarehouse)), [])),
    timeToSpend: types.maybeNull(types.number),
})
    .views(function (self) { return ({
    get fullAddress() {
        if (!self.address || Object.keys(self.address).length === 0)
            return ''; //i18n.getResource(i18n.language, KEYS.ERRORS, 'NO_ADDRESS');
        if (self.address.address)
            return self.address.address;
        return [
            self.address.streetNumber,
            self.address.streetName,
            self.address.area,
            self.address.state,
            self.address.country,
            self.address.postal,
        ]
            .filter(function (e) { return e; })
            .join(', ');
    },
    get fullAddressV2() {
        if (!self.address || Object.keys(self.address).length === 0)
            return ''; //i18n.getResource(i18n.language, KEYS.ERRORS, 'NO_ADDRESS');
        if (self.address.address)
            return self.address.address;
        return [self.address.streetNumber, self.address.streetName, self.address.area, self.address.state]
            .filter(function (e) { return e; })
            .join(', ');
    },
    get fullAddressV1() {
        if (!self.address || Object.keys(self.address).length === 0)
            return '';
        var addressStr = [
            self.address.streetNumber,
            self.address.streetName,
            self.address.area,
            self.address.state,
            self.address.country,
            self.address.postal,
        ]
            .filter(function (e) { return e; })
            .join(',');
        return self.address.address || addressStr || '';
    },
}); });
export * from './LocationAddress';
