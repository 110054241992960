var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import { Grid, ListItemText } from '@material-ui/core';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import { useTranslation } from 'react-i18next';
import { OrderLocation, OrderRouteType } from '../../../models';
import { KEYS } from '../../../i18n';
import { ACTION_CHANGE } from '../../BasicList';
import { FormikAutocompleteAsyncAdd } from '../../Formik';
import { DeleteLocation, FetchLocations } from '../../../graphql';
import { LocationDialog } from './LocationDialog';
import { theme } from '../../../theme';
import DropDownItemActions, { actionsVisibility } from '../form/dialogs/DropDownItemActions';
import { TextOverflow } from '../../TextOverflow';
export var FormLocationInput = function (_a) {
    var onChange = _a.onChange, noEdit = _a.noEdit, config = _a.config, locationType = _a.locationType, defaultFilter = _a.defaultFilter, props = __rest(_a, ["onChange", "noEdit", "config", "locationType", "defaultFilter"]);
    var _b = __read(React.useState(1), 2), updated = _b[0], setUpdated = _b[1];
    var langOrderForm = useTranslation(KEYS.ORDER_FORM);
    function closeNewRouteDialog(location) {
        if (location._id && onChange)
            onChange(JSON.parse(JSON.stringify(location)));
    }
    var _onDeleteItem = function (e, option) {
        if (option._id && onChange && props.defaultValue && option._id === props.defaultValue._id) {
            onChange(option, ACTION_CHANGE.DELETED);
        }
        setUpdated(Date.now());
    };
    var _onChange = function (e, location, action) {
        location && location._id && onChange && onChange(JSON.parse(JSON.stringify(location)), action);
    };
    var inputProps = {
        disabled: noEdit,
    };
    var client = config && config.client;
    var langFormLocation = useTranslation(KEYS.FORM_LOCATIONS);
    var isPickup = locationType === OrderRouteType.Preferred;
    var lang_key = isPickup ? 'pickup' : 'delivery';
    return (React.createElement(FormikAutocompleteAsyncAdd, __assign({}, props, { v17: true, v20: true, v25: true, key: updated, onChange: _onChange, onClose: closeNewRouteDialog, noDataTitle: langOrderForm.t('create_order_route_location_my_locations_no_data'), client: client, removeQuery: DeleteLocation, query: FetchLocations, variables: defaultFilter, addTitle: langFormLocation.t("form_location_" + lang_key + "_title") }, inputProps, { onDeleteItem: _onDeleteItem, backendFilterOnly: true, filterFields: [
            'members.name',
            'members.email',
            'name',
            'firstEmail',
            'warehouse.name',
            'companyName',
            'contactName',
            'address.address',
            'address.city',
            'address.country',
            'address.postal',
            'address.streetName',
            'address.state',
            'address.streetNumber',
        ], skipModelValidationFilterFields: ['warehouse.name', 'firstEmail'], deleteTitle: function (option) { return OrderLocation.create(option).fullAddress; }, dialog: function (dialogProps) {
            var _a, _b;
            return (React.createElement(LocationDialog, __assign({ key: [props.open, ((_a = dialogProps.defaultValue) === null || _a === void 0 ? void 0 : _a._id) || ((_b = dialogProps.defaultValue) === null || _b === void 0 ? void 0 : _b.updatedAt)].join('-'), config: { client: client }, onChange: _onChange }, dialogProps, { locationType: locationType })));
        }, autocompleteProps: {
            disabled: noEdit,
            getOptionLabel: function (option) {
                return [option.name, option.warehouse && option.warehouse.name, OrderLocation.create(option).fullAddress]
                    .filter(function (e) { return e; })
                    .join(', ');
            },
            onClose: props.onAutocompleteClose,
        }, renderOption: function (option) {
            return (React.createElement(FormLocationInputAddress, { option: option, canEdit: true, cannotDelete: true, content: React.createElement(DropDownItemActions, { option: option, canEdit: true, cannotDelete: true, deleteTitle: langOrderForm.t('create_order_route_location_btn_remove'), errorTitle: langOrderForm.t('create_order_route_location_no_edit'), editTitle: langOrderForm.t('create_order_route_location_btn_edit') }) }));
        } })));
};
export var FormLocationInputAddress = function (_a) {
    var content = _a.content, option = _a.option, props = __rest(_a, ["content", "option"]);
    var optionsVisible = actionsVisibility(__assign({ option: option }, props)).filter(function (e) { return e; });
    return (React.createElement(Grid, __assign({ container: true, alignItems: 'center', justify: 'flex-start' }, props),
        React.createElement(LocationOnOutlinedIcon, { style: {
                marginRight: theme.spacing(1),
                marginLeft: theme.spacing(-0.5),
            } }),
        React.createElement(Grid, { container: true, style: {
                justifyContent: 'space-between',
                maxWidth: 'calc(100% - 32px)',
            } },
            React.createElement(ListItemText, { style: { maxWidth: "calc(100% - " + optionsVisible.length * 32 + "px)" }, primary: React.createElement(TextOverflow, { variant: 'caption', style: { display: 'block' }, "data-id": 'location-address' }, OrderLocation.create(option).fullAddress), secondary: React.createElement(TextOverflow, null, [option.name, option.warehouse && option.warehouse.name].filter(function (e) { return e; }).join(', ')) }),
            content)));
};
