var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import { createStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import clsx from 'clsx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useExpandRowStyles } from './styles';
import Collapse from '@material-ui/core/Collapse/Collapse';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../../i18n';
import { DeleteDialog, onPreventEvent } from '../../Dialog';
import { TextOverflow } from '../../TextOverflow';
import { CircularIndeterminate } from '../../CircularIndeterminate';
import { theme } from '../../../theme';
import { makeStyles } from '@material-ui/core/styles';
import { BaseListItem } from '../ListItem';
import BaseTooltip from '../../BaseTooltip';
import Divider from '@material-ui/core/Divider';
var useStyles = makeStyles(function (theme) {
    return createStyles({
        root: {
            background: theme.palette.common.white,
            width: '100%',
        },
    });
});
export var ExpandedRow = function (_a) {
    var _b;
    var _this = this;
    var _c;
    var config = _a.config, deleteTitle = _a.deleteTitle, onDeleteItem = _a.onDeleteItem, onLoadData = _a.onLoadData, header = _a.header, record = _a.record, body = _a.body, isOpened = _a.isOpened, props = __rest(_a, ["config", "deleteTitle", "onDeleteItem", "onLoadData", "header", "record", "body", "isOpened"]);
    var _d = __read(React.useState(!!isOpened), 2), expanded = _d[0], setExpanded = _d[1];
    var _e = __read(React.useState(false), 2), loading = _e[0], setLoading = _e[1];
    var _f = __read(React.useState(true), 2), loaded = _f[0], setLoaded = _f[1];
    var _g = __read(React.useState(''), 2), error = _g[0], setError = _g[1];
    var classes = useExpandRowStyles();
    var langApp = useTranslation(KEYS.APP);
    var classesMain = useStyles();
    var handleExpandClick = function (e) { return __awaiter(_this, void 0, void 0, function () {
        var _a, e_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    setExpanded(!expanded);
                    if (!(!expanded && !loaded)) return [3 /*break*/, 6];
                    if (!config) return [3 /*break*/, 6];
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 4, 5, 6]);
                    setLoading(true);
                    setLoaded(false);
                    _a = onLoadData;
                    if (!_a) return [3 /*break*/, 3];
                    return [4 /*yield*/, onLoadData()];
                case 2:
                    _a = (_b.sent());
                    _b.label = 3;
                case 3:
                    _a;
                    setLoaded(true);
                    return [3 /*break*/, 6];
                case 4:
                    e_1 = _b.sent();
                    setError(e_1.message);
                    return [3 /*break*/, 6];
                case 5:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 6: return [2 /*return*/, onPreventEvent(e)];
            }
        });
    }); };
    var theme1 = useTheme();
    var isSmall = useMediaQuery(theme1.breakpoints.down('sm'));
    var additionalContent = (React.createElement(Grid, { container: true, alignItems: 'center', style: { width: 'initial' }, direction: isSmall ? 'column' : 'row' },
        (onDeleteItem &&
            ((onDeleteItem === 1 && React.createElement("span", { style: { width: 48 } })) || (React.createElement(DeleteDialog, { withoutWrapper: true, value: deleteTitle, onOk: onDeleteItem })))) ||
            null,
        React.createElement(BaseTooltip, { title: (expanded ? langApp.t('collapse_less') : langApp.t('collapse_more')) },
            React.createElement(IconButton, { disabled: loading, className: clsx(classes.expand, 'expand-icon', (_b = {
                        expanded: expanded
                    },
                    _b[classes.expandOpen] = expanded,
                    _b)), onClick: handleExpandClick, "aria-expanded": expanded, "aria-label": "show more" }, loading ? React.createElement(CircularIndeterminate, null) : React.createElement(ExpandMoreIcon, null)))));
    var content = (React.createElement(Grid, { container: true, direction: "row", key: "" + ((loaded && 1) || -1), id: "table-item-" + record._id },
        error ? React.createElement(TextOverflow, { color: 'error' }, error) : null,
        (typeof header === 'function' && header(additionalContent, handleExpandClick)) || (React.createElement(Grid, { container: true, alignItems: 'center' },
            React.createElement(Grid, { onClick: handleExpandClick, container: true, alignItems: 'center', style: { maxWidth: "calc(100% - 48px)", padding: theme.spacing(0, 1) } }, header),
            additionalContent)),
        (props.v2 && expanded && React.createElement(Divider, { style: { width: '100%' } })) || null,
        React.createElement(Collapse, { in: expanded, timeout: "auto", unmountOnExit: true, className: clsx(classesMain.root, (_c = props.containerBodyProps) === null || _c === void 0 ? void 0 : _c.className) },
            React.createElement(Grid, { container: true, alignItems: 'center', style: expanded ? { padding: theme.spacing(1) } : {} }, loading ? React.createElement(CircularIndeterminate, null) : loaded ? body : null))));
    if (props.v1)
        return content;
    return (React.createElement(BaseListItem, __assign({ record: record, style: { width: '100%' } }, props.containerProps, { onClick: props.v3 && handleExpandClick }), content));
};
