import { CALENDAR_ELEMENTS } from './types';
import { MainFilterSortDir } from '../context.store.enums';
export var initialState = {
    loading: true,
    loaded: false,
    isPublic: false,
    error: null,
    warehouse: null,
    dragId: null,
    noneWorkingDayTime: { data: [], count: 0 },
    transportations: { data: [], count: 0 },
    transportationsBusy: { data: [], count: 0 },
    holidays: { data: [], count: 0 },
    workingDays: { data: [], count: 0 },
    ramps: { data: [], count: 0 },
    calendarElements: Object.values(CALENDAR_ELEMENTS),
    filter: {
        fields: [],
        sortBy: 'createdAt',
        sortDir: MainFilterSortDir.DESC,
        limit: 100,
        page: 1,
        skip: 0,
    },
};
