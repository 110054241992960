var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { Grid } from '@material-ui/core';
import { TextOverflow } from '../../TextOverflow';
import { useMonthDayHolidayDetailsStyles } from './style';
import { withCalendarStore } from '../store';
import { CALENDAR_ELEMENTS } from '../types';
import BaseTooltip from '../../BaseTooltip';
import AddNoneWorkingTime from './AddNoneWorkingTime';
import MenuListItem from '../../MenuListItem';
import { USER_ROLE_TOOLS } from '../../../models/UserRoles';
import { inject } from 'mobx-react';
import { DeleteDialog } from '../../Dialog';
export var NoneWorkingDayTimeDetails = inject('store')(withCalendarStore(function (_a) {
    var style = _a.style, items = _a.items, store = _a.store, v1 = _a.v1, _b = _a.state, calendarElements = _b.calendarElements, noneWorkingDayTime = _b.noneWorkingDayTime, changeData = _a.changeData;
    var isPublic = !!location.pathname.match(/^\/public\//gi);
    if (isPublic)
        return null;
    var className = useMonthDayHolidayDetailsStyles();
    if (!calendarElements.includes(CALENDAR_ELEMENTS.NONE_WORKING_DAY))
        return null;
    var canEdit = store.accountStore.currentUser.rolesV1.find(function (e) { var _a; return (_a = e.tools.general) === null || _a === void 0 ? void 0 : _a.includes(USER_ROLE_TOOLS.GENERAL_can_edit_company_data); });
    var onDeleteItem = function (record) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, store.warehouseStore.deleteWarehouseNoneWorkingDayTime(record)];
                case 1:
                    _a.sent();
                    changeData({
                        noneWorkingDayTime: {
                            data: noneWorkingDayTime.data.filter(function (e) { return e._id !== record._id; }),
                            count: noneWorkingDayTime.count--,
                        },
                    });
                    return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(Grid, { container: true, direction: 'row', className: className.root, style: style }, items.map(function (item) {
        var isOwn = ((item.createdBy && item.createdBy._id) || item.createdBy) === store.accountStore.currentUser._id;
        var listOptions = [
            {
                content: React.createElement(AddNoneWorkingTime, { label: true, day: item.date, noneWorking: true, defaultValue: item }),
                available: isOwn || canEdit,
            },
            {
                content: React.createElement(DeleteDialog, { onOk: function () { return onDeleteItem(item); }, withText: true, withoutWrapper: true, value: item.name }),
                available: isOwn || canEdit,
            },
        ].filter(function (e) { return e.available; });
        var content = (React.createElement(Grid, { key: item.updatedAt, container: true, className: className.item, justify: 'space-between', alignItems: 'center', style: v1 ? { minHeight: '21px', overflowY: 'hidden', marginBottom: 0 } : {} },
            React.createElement(Grid, { container: true, justify: 'space-between', alignItems: 'center', style: __assign({}, (item.time && { maxWidth: "calc(100% - 48px)" })) },
                React.createElement(TextOverflow, { variant: "caption", style: __assign({}, (listOptions.length && { maxWidth: "calc(100% - 48px)" })), noTitle: true }, item.name),
                (listOptions.length && React.createElement(MenuListItem, { show: true, list: listOptions })) || null),
            item.time && (React.createElement(TextOverflow, { style: { lineHeight: 1, width: 'initial', fontFamily: 'sans-serif' } }, item.time))));
        if (!item.description)
            return content;
        return React.createElement(BaseTooltip, { title: item.description }, content);
    })));
}));
