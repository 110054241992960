var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { IconButton } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../../i18n';
import { Link } from 'react-router-dom';
import { inject, observer, useLocalStore } from 'mobx-react';
import { toast } from 'react-toastify';
import { OrderStatus } from '../../../models';
import { CancelOrder } from '../../../graphql/order';
import { USER_ROLE } from '../../../stores/common';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import OrderRouteMenuItem from './OrderRoutesMenuItem';
import BaseTooltip from '../../BaseTooltip';
import { USER_ROLE_TOOLS } from '../../../models/UserRoles';
var useIconStyles = makeStyles(function (theme) {
    return createStyles({
        icon: {
            color: theme.palette.success.light,
            fill: theme.palette.success.light,
            '& path': {
                fill: theme.palette.success.light,
            },
        },
    });
});
export var OrderRestore = inject('store')(observer(function (_a) {
    var config = _a.config, order = _a.order, currentUser = _a.store.accountStore.currentUser, props = __rest(_a, ["config", "order", "store"]);
    var classes = useIconStyles();
    var langOrderList = useTranslation(KEYS.ORDER_LIST);
    var localStore = useLocalStore(function () { return ({
        error: '',
        isLoading: false,
        onSubmit: function (_input) {
            return __awaiter(this, void 0, void 0, function () {
                var input, res, _a, e_1;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            localStore.error = '';
                            localStore.isLoading = true;
                            _b.label = 1;
                        case 1:
                            _b.trys.push([1, 4, 5, 6]);
                            input = __assign(__assign({}, _input), { _id: order._id });
                            _a = config;
                            if (!_a) return [3 /*break*/, 3];
                            return [4 /*yield*/, config.client.mutate({
                                    mutation: CancelOrder,
                                    variables: {
                                        input: input,
                                    },
                                })];
                        case 2:
                            _a = (_b.sent());
                            _b.label = 3;
                        case 3:
                            res = _a;
                            toast.success(langOrderList.t('order_list_restore_order_finish'));
                            return [2 /*return*/, res.data.cancelOrder];
                        case 4:
                            e_1 = _b.sent();
                            toast.error(e_1.message);
                            return [3 /*break*/, 6];
                        case 5:
                            localStore.isLoading = false;
                            return [7 /*endfinally*/];
                        case 6: return [2 /*return*/];
                    }
                });
            });
        },
        clearError: function () {
            localStore.error = '';
        },
    }); });
    if (!order || !order.createdBy || !order.publishedAt) {
        return null;
    }
    var isCustomer = (order.manager._id || order.manager) === currentUser._id || currentUser.roles.includes(USER_ROLE.ADMIN);
    var isAllowChangeDate = currentUser.rolesV1.find(function (role) { var _a, _b; return (_b = (_a = role === null || role === void 0 ? void 0 : role.tools) === null || _a === void 0 ? void 0 : _a.general) === null || _b === void 0 ? void 0 : _b.includes(USER_ROLE_TOOLS.GENERAL_allow_change_dates); });
    if (!(isCustomer &&
        (order.status === OrderStatus.ApprovedQuote || order.status === OrderStatus.Finished) &&
        isAllowChangeDate))
        return null;
    var label = order.status === OrderStatus.ApprovedQuote
        ? langOrderList.t('order_list_restore_order_dates')
        : langOrderList.t('order_list_publish_as_new_order');
    if (props.label) {
        var icon = (React.createElement(BaseTooltip, { title: [label, order.lastRequestChange && order.lastRequestChange.comment]
                .filter(function (e) { return e; })
                .join('. ') },
            React.createElement(IconButton, { onClick: function () { return 0; }, className: classes.icon, id: 'btn-order-restore' },
                React.createElement(SettingsBackupRestoreIcon, null))));
        return (React.createElement(Link, { to: "/orders/" + order._id + "/restore", style: { textDecoration: 'none', width: '100%' } },
            React.createElement(OrderRouteMenuItem, __assign({}, { icon: icon, label: label, handleClickOpen: function () { return 0; } }))));
    }
    return (React.createElement(BaseTooltip, { title: [label, order.lastRequestChange && order.lastRequestChange.comment].filter(function (e) { return e; }).join('. ') },
        React.createElement(Link, { to: "/orders/" + order._id + "/restore", id: 'btn-order-restore' },
            React.createElement(IconButton, { onClick: function () { return 0; }, className: classes.icon },
                React.createElement(SettingsBackupRestoreIcon, null)))));
}));
