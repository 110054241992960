var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React from 'react';
import moment from 'moment';
import { Grid } from '@material-ui/core';
import { RouteForm } from './Routes/RouteForm';
import { CargoForm } from './CargoForm';
import { useFormStyles } from '../../../../styles';
import Divider from '@material-ui/core/Divider';
import { theme } from '../../../../theme';
import { DeleteDialog, TextOverflow } from '../../../../components';
import { inject } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../../../i18n';
import { canEdit } from '../utils';
import PaddingSpace from './PaddingSpce';
export var TransportationItemComponent = inject('store')(React.forwardRef(function (_a, ref) {
    var store = _a.store, transportation = _a.transportation, prevTransportation = _a.prevTransportation, nextTransportation = _a.nextTransportation, index = _a.index, total = _a.total, props = __rest(_a, ["store", "transportation", "prevTransportation", "nextTransportation", "index", "total"]);
    var langOrderForm = useTranslation(KEYS.ORDER_FORM);
    var classes = useFormStyles();
    var onDeleteItem = function () { return store.orderStore.deleteOrderTransportations(transportation); };
    var nextDates = nextTransportation &&
        nextTransportation.routes
            .map(function (e) { return [e.startDate, e.endDate]; })
            .reduce(function (a, b) { return __spread(a, b); }, [])
            .filter(function (e) { return e; })
            .map(function (e) { return moment(e); });
    var maxDate = nextDates && nextDates.length && moment.max(nextDates);
    var minDate = undefined;
    var noEdit = !canEdit(store.orderStore.order, store);
    return (React.createElement(Grid, { container: true, direction: 'column', id: "step-content-transportation-" + (index + 1) },
        React.createElement(RouteForm, __assign({}, props, { transportationIndex: index + 1, content: total > 1 && !noEdit ? (React.createElement(Grid, { container: true, alignItems: 'center', style: { width: 'initial' } },
                React.createElement(TextOverflow, { variant: 'h5', color: 'primary', className: classes.sectionTitle1 },
                    index + 1,
                    "/",
                    total),
                React.createElement(DeleteDialog, { iconStyle: { marginRight: theme.spacing(2) }, onOk: onDeleteItem, value: langOrderForm.t('create_order_transportation_label') + " " + (index + 1) + "/" + total }))) : null, transportation: transportation, maxDate: maxDate, minDate: minDate, index: index, ref: ref[0] })),
        React.createElement(CargoForm, __assign({ transportationIndex: index + 1 }, props, { transportation: transportation, index: index, ref: ref[1] })),
        total > 1 && index < total - 1 ? (React.createElement(Grid, { container: true },
            React.createElement(PaddingSpace, null),
            React.createElement(Grid, { item: true, xs: 12, md: 10 },
                React.createElement(Divider, { style: { margin: theme.spacing(2, 0), height: 2, backgroundColor: theme.palette.grey[300] } })))) : null));
}));
