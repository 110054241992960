var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import moment from 'moment';
import clsx from 'clsx';
import { withCalendarStore } from '../store';
import { ACTIVE_COLOR, useMonthDayStyles } from '../MonthViewV1/style';
import { toDateOnly } from '../../../utils';
import { DayOrderDetails } from '../MonthViewV1/DayOrderDetails';
import { onPreventEvent } from '../../Dialog';
import { cellTransportations } from './utils';
export var DayHourView = withCalendarStore(function (_a) {
    var _b, _c, _d, _e;
    var _f, _g, _h, _j;
    var day = _a.day, hour = _a.hour, item = _a.item, _k = _a.state, warehouse = _k.warehouse, transportations = _k.transportations, workingDays = _k.workingDays, holidays = _k.holidays, dragId = _k.dragId, isStyled = _a.isStyled, allDayTransportations = _a.allDayTransportations, rowSpan = _a.rowSpan, props = __rest(_a, ["day", "hour", "item", "state", "isStyled", "allDayTransportations", "rowSpan"]);
    var className = useMonthDayStyles();
    if (!rowSpan)
        return null;
    var currentDay = toDateOnly(day);
    var currentDayMoment = moment(toDateOnly(day));
    var currentWeekDay = currentDayMoment.format('dddd');
    var dayTransportations = allDayTransportations || cellTransportations({ transportations: transportations, day: day, hour: hour, item: item });
    var isPublic = !!location.pathname.match(/^\/public\//gi);
    var content = (dayTransportations.length && (React.createElement(DayOrderDetails, { dayTransportations: dayTransportations, currentDay: currentDay, hour: hour, rowSpan: rowSpan, config: props.config, item: item, onChange: props.onChange, isStyled: isStyled, canDrag: true, rows: props.rows, hours: props.hours }))) ||
        null;
    if (props.notAvailable) {
        return (React.createElement("td", __assign({ style: __assign({}, props.style) }, (props.colSpan && { colSpan: props.colSpan }), { rowSpan: rowSpan, className: clsx((_b = {},
                _b[className.disabled] = 1,
                _b[className.disabledV1] = 1,
                _b), className.rootHour), id: currentDay + "-" + (hour || 1) }, (_c = {},
            _c['data-cell-id'] = ("order-" + item.ramp._id + "-" + currentDay + "-" + hour).replace(/\//g, '-').replace(/\:/g, '-'),
            _c)), content));
    }
    var isToDay = toDateOnly(day) === toDateOnly();
    var currentHourInZone = isToDay && ((_g = (_f = warehouse === null || warehouse === void 0 ? void 0 : warehouse.address) === null || _f === void 0 ? void 0 : _f.location) === null || _g === void 0 ? void 0 : _g.zone)
        ? new Date().toLocaleString('ua', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
            timeZone: (_j = (_h = warehouse === null || warehouse === void 0 ? void 0 : warehouse.address) === null || _h === void 0 ? void 0 : _h.location) === null || _j === void 0 ? void 0 : _j.zone,
        })
        : hour;
    var isHourAvailable = isToDay && hour >= currentHourInZone;
    var isAvailable = (!isToDay || isHourAvailable) &&
        toDateOnly(day) >= toDateOnly() &&
        workingDays.data.find(function (e) { return e.day === currentWeekDay && e.maxTime && e.maxTime._id; }) &&
        !holidays.data.filter(function (e) {
            return moment(toDateOnly(e.start)).isSameOrBefore(currentDayMoment) &&
                moment(toDateOnly(e.end)).isSameOrAfter(currentDayMoment) &&
                [warehouse === null || warehouse === void 0 ? void 0 : warehouse._id, item.ramp._id].includes(e.warehouse._id);
        }).length;
    var onDragOver = function (e) { return onPreventEvent(e); };
    var onDragEnter = function (e) {
        if (e.currentTarget.getAttribute('datatype') === 'order-can-drop') {
            if (e.currentTarget.getAttribute('data-none-over')) {
            }
            else {
                e.currentTarget.style.backgroundColor = ACTIVE_COLOR;
            }
        }
        return onPreventEvent(e);
    };
    var onDragLeave = function (e) {
        e.currentTarget.style.backgroundColor = '';
    };
    var onDrop = function (e) {
        try {
            var day_1 = JSON.parse(e.dataTransfer.getData('day'));
            e.currentTarget.style.backgroundColor = '';
            if (currentDay === day_1.date && hour === day_1.time[0] && item.ramp._id === day_1.item.ramp._id) {
                return console.log('no changes');
            }
            if (e.currentTarget.children.length) {
                return console.log('cell is busy');
            }
            if (props.onChange)
                props.onChange({ oldData: day_1, newData: { day: currentDay, hour: hour, item: item } });
        }
        catch (e) {
            console.log(e);
        }
    };
    var onClick = function () {
        props.onClick &&
            props.onClick({ date: currentDay, time: hour, item: item, transportations: dayTransportations });
    };
    var available = (dragId && isAvailable) || !dragId;
    var isBusy = dayTransportations.find(function (e) { var _a; return e.isBusy || ((_a = e.record) === null || _a === void 0 ? void 0 : _a.isBusy); });
    var bookCeil = !(!isAvailable || isBusy);
    return (React.createElement("td", __assign({ style: __assign({}, props.style) }, (props.colSpan && { colSpan: props.colSpan }), { rowSpan: rowSpan }, (bookCeil && { onClick: onClick }), { className: clsx((_d = {},
            _d[className.bookCell] = bookCeil,
            _d[className.disabled] = !isAvailable,
            _d[className.disabledV1] = isBusy,
            _d), className.rootHour), id: currentDay + "-" + (hour || 1) }, (available &&
        !isBusy &&
        !isPublic && __assign(__assign({ onDragOver: onDragOver,
        onDragLeave: onDragLeave,
        onDragEnter: onDragEnter,
        onDrop: onDrop }, (!dayTransportations.length && { datatype: 'order-can-drop' })), (_e = {}, _e['data-cell-id'] = ("order-" + item.ramp._id + "-" + currentDay + "-" + hour).replace(/\//g, '-').replace(/\:/g, '-'), _e)))), !isBusy && dayTransportations.length ? content : null));
});
