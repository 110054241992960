import { applySnapshot, types } from 'mobx-state-tree';
import { OrderLocation } from './Location';
import { Warehouse } from './Warehouse';
export var OrderRouteType;
(function (OrderRouteType) {
    OrderRouteType["Preferred"] = "Preferred";
    OrderRouteType["Delivery"] = "Delivery";
})(OrderRouteType || (OrderRouteType = {}));
export var OrderRoute = types
    .model('OrderRoute', {
    _id: types.optional(types.identifier, ''),
    updatedAt: types.maybeNull(types.optional(types.string, '')),
    // email: types.maybeNull(types.optional(types.string, '')),
    origin: types.maybeNull(types.optional(OrderLocation, function () { return OrderLocation.create(); })),
    warehouse: types.maybeNull(types.optional(Warehouse, function () { return Warehouse.create(); })),
    startDate: types.maybeNull(types.string),
    endDate: types.maybeNull(types.string),
    allowBookingOnlyOnSelectedPeriods: types.maybeNull(types.boolean),
    isCreationCopy: types.maybeNull(types.boolean),
    // startTime: types.maybeNull(
    //   types.optional(types.string, () => startDay(1000 * 60 * 60 * 8))
    // ),
    // endTime: types.maybeNull(
    //   types.optional(types.string, () => startDay(1000 * 60 * 60 * 18))
    // ),
    email: types.maybeNull(types.optional(types.array(types.string), [])),
    rangeDates: types.maybeNull(types.optional(types.array(types.maybeNull(types.string)), function () { return [null, null]; })),
    orderTransportation: types.optional(types.model({ _id: types.optional(types.string, '') }), function () { return ({ _id: '' }); }),
    type: types.maybeNull(types.optional(types.enumeration('Preferred', Object.keys(OrderRouteType)), OrderRouteType.Preferred)),
})
    .actions(function (self) { return ({
    reset: function (snapshot) {
        applySnapshot(self, snapshot);
    },
}); });
