var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { createStyles, Grid, IconButton } from '@material-ui/core';
import { TextOverflow } from '../../TextOverflow';
import BaseTooltip from '../../BaseTooltip';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { usePrevNextButtonStyles } from '../../Buttons/PrevNextButton';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../../i18n';
import { makeStyles } from '@material-ui/core/styles';
import { StyledBadgeV4 } from '../../StyledBadge';
import { withCalendarStore } from '../store';
import moment from 'moment';
import { toDateOnly } from '../../../utils';
import { DayHolidayDetails } from '../MonthViewV1/DayHolidayDetails';
import { theme } from '../../../theme';
import clsx from 'clsx';
var useRampsSwitcherStyles = makeStyles(function (theme) {
    return createStyles({
        rootV1: {
            '& *': {
                color: theme.palette.common.black,
            },
        },
        opened: {
            background: theme.palette.primary.main,
            '& *': {
                color: theme.palette.common.white + "!important",
            },
        },
        root: {
            background: theme.palette.common.white,
            border: '1px solid #BAB8B8',
            boxSizing: 'border-box',
        },
    });
});
export default withCalendarStore(function (_a) {
    var _b;
    var record = _a.record, show = _a.show, total = _a.total, open = _a.open, onToggleShow = _a.onToggleShow, style = _a.style, _c = _a.state, transportations = _c.transportations, holidays = _c.holidays, props = __rest(_a, ["record", "show", "total", "open", "onToggleShow", "style", "state"]);
    var classesV = useRampsSwitcherStyles();
    var classes = usePrevNextButtonStyles();
    var langApp = useTranslation(KEYS.APP);
    var date = record.id.split('-')[0];
    var totalBadge = open
        ? (transportations.data.find(function (e) { return e.date === date; }) || { items: [] }).items.filter(function (e) {
            return e.routes.map(function (e) { return e.origin.warehouse._id; }).includes(record.ramp._id);
        }).length
        : (transportations.data.find(function (e) { return e.date === date; }) || { items: [] }).items.length;
    var currentDayMoment = moment(date);
    var _holidays = holidays.data.filter(function (e) {
        return moment(toDateOnly(e.start)).isSameOrBefore(currentDayMoment) &&
            moment(toDateOnly(e.end)).isSameOrAfter(currentDayMoment) &&
            e.warehouse._id === record.ramp._id;
    });
    var container = (React.createElement(Grid, { container: true, alignItems: 'center', justify: 'center', className: clsx((_b = {}, _b[classesV.opened] = open, _b)) },
        React.createElement(Grid, { className: clsx(classesV.rootV1), container: true, direction: 'column', style: { maxWidth: 'calc(100% - 40px)', padding: theme.spacing(1, 0) } },
            React.createElement(TextOverflow, { v4: true, variant: 'subtitle1' }, record.ramp.name),
            React.createElement(TextOverflow, { v4: true, variant: 'caption' }, record.ramp.description)),
        (total > 1 && show && (React.createElement(BaseTooltip, { title: langApp.t(open ? 'collapse_less' : 'collapse_more') },
            React.createElement(IconButton, { onClick: onToggleShow, className: classes.buttonV1, style: { width: 24, height: 24 } }, open ? React.createElement(ArrowBackIosIcon, null) : React.createElement(ArrowForwardIosIcon, null))))) ||
            null));
    return (React.createElement("th", { style: __assign({ top: 60, padding: 0 }, style), className: classesV.root, "data-Id": props.index },
        (_holidays.length && React.createElement(DayHolidayDetails, { holidays: _holidays })) || null,
        (totalBadge && (React.createElement(StyledBadgeV4, { badgeContent: React.createElement(BaseTooltip, { title: langApp.t('total_orders') },
                React.createElement("div", null, totalBadge)) }, container))) ||
            container));
});
