var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React, { useCallback } from 'react';
import moment from 'moment';
import { Grid, Typography } from '@material-ui/core';
import { withRouter } from 'react-router';
import clsx from 'clsx';
import { FetchOrderQuoteDetails } from '../../graphql/order';
import TrackBooking from './TrackBooking';
import { toast } from 'react-toastify';
import { OrderQuotes, OrderQuoteStatus, OrderStatus, } from '../../models';
import { getSnapshot } from 'mobx-state-tree';
import { CircularIndeterminate, MainForm, OrderQuoteCardHeader, TextOverflow } from '../../components';
import { TransportForm } from './TransportForm';
import { useMainStyles } from '../../styles';
import { QuoteDetailsComponentOverview } from './Overview';
import { inject } from 'mobx-react';
import { BaseUserAppTYpe } from '../../stores/common';
import { dateViewFormat } from '../../components/DateView';
import { theme } from '../../theme';
import { UpdateOrderTransportation } from '../../graphql/order/orderTransportation';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../i18n';
import { OrderQuoteActions } from '../../components/Order/List/OrderQuoteActions';
import { toDateOnly } from '../../utils';
import OrderQuoteDocuments from './Documents/index';
import browserHistory from '../../browserHistory';
import { isReviewOffer } from './types';
import { checkSteps, checkStepValidation, QUOTE_STEP } from './utils';
import { googleTagManager } from '../../utils1';
import PreloadingComponent from '../../components/PreloadingComponent';
var QuoteDetailsItem = React.lazy(function () { return import('./QuoteDetailsItem'); });
var tempData = {};
export var QuoteDetailsComponent = inject('store')(function (_a) {
    var _b;
    var accountStore = _a.store.accountStore, config = _a.config, quoteId = _a.match.params.quoteId;
    var langOfferForm = useTranslation(KEYS.OFFER_FORM);
    var langForm = useTranslation(KEYS.FORM);
    var langOrderForm = useTranslation(KEYS.ORDER_FORM);
    var langToast = useTranslation(KEYS.TOAST);
    var _c = __read(React.useState(null), 2), error = _c[0], setError = _c[1];
    var _d = __read(React.useState(true), 2), loading = _d[0], setLoading = _d[1];
    var _e = __read(React.useState(false), 2), pending = _e[0], setPending = _e[1];
    var mainClasses = useMainStyles();
    var _f = __read(React.useState(__assign(__assign({}, getSnapshot(OrderQuotes.create({}))), { order: {
            transportations: [],
        } })), 2), item = _f[0], setItem = _f[1];
    var loadData = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var res_1, _a, item_1, e_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 3, , 4]);
                    setLoading(true);
                    _a = config;
                    if (!_a) return [3 /*break*/, 2];
                    return [4 /*yield*/, config.client.query({
                            query: FetchOrderQuoteDetails,
                            variables: { _id: quoteId },
                        })];
                case 1:
                    _a = (_b.sent());
                    _b.label = 2;
                case 2:
                    res_1 = _a;
                    res_1.data.orderQuoteDetails.order.transportations.forEach(function (t) {
                        ['endTime', 'startTime'].forEach(function (e) {
                            if (!t[e])
                                t[e] = [];
                        });
                    });
                    ['endTime', 'startTime'].forEach(function (e) {
                        if (!res_1.data.orderQuoteDetails[e])
                            delete res_1.data.orderQuoteDetails[e];
                    });
                    if (![OrderQuoteStatus.Approved, OrderQuoteStatus.Finished].includes(res_1.data.orderQuoteDetails.status)) {
                        throw 'Not available';
                    }
                    item_1 = __assign(__assign({}, res_1.data.orderQuoteDetails), getSnapshot(OrderQuotes.create(res_1.data.orderQuoteDetails)));
                    setItem(item_1);
                    setLoading(false);
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _b.sent();
                    console.log(e_1);
                    setError(e_1.message);
                    setTimeout(function () { return setLoading(false); }, 1000);
                    if (browserHistory.length > 1) {
                        browserHistory.goBack();
                    }
                    else {
                        browserHistory.push('/');
                    }
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); }, [quoteId]);
    React.useEffect(function () {
        loadData();
        return function () {
            tempData = {};
        };
    }, []);
    var isActive = function (parent) {
        var _item = parent || item;
        return checkSteps(__assign(__assign({}, _item), { order: item.order }), this.label.replace(/-[0-9]/, '') || this.title)[this.label.replace(/-[0-9]/, '') || this.title];
    };
    var isValid = function (parent) {
        var _item = parent || item;
        return checkStepValidation(__assign(__assign({}, _item), { order: item.order }), this.label.replace(/-[0-9]/, '') || this.title)[this.label.replace(/-[0-9]/, '') || this.title];
    };
    var is_review_offer = isReviewOffer(accountStore, item);
    var onChangeDateRange = function (updates) {
        if (!updates) {
            console.warn('no event', updates);
            return;
        }
        setItem(__assign(__assign({}, item), { updatedAt: new Date().toString(), order: __assign(__assign({}, item.order), { transportations: item.order.transportations.map(function (e) {
                    if (e._id === updates._id) {
                        return __assign(__assign(__assign({}, e), tempData), updates);
                    }
                    return e;
                }) }) }));
        onChange(updates);
    };
    var steps = __spread(item.order.transportations.map(function (transportation, index) {
        var _isActive = function () {
            return isActive.call(this, transportation);
        };
        var _isValid = function () {
            return isValid.call(this, transportation);
        };
        var _isValidMain = function () {
            if (transportation.unloadedAt)
                return false;
            return (index === 0 || (item.order.transportations[index - 1].unloadedAt && isValid.call(this, transportation)));
        };
        var labelIndex = index + 1;
        return {
            createdAt: transportation.createdAt,
            updatedAt: transportation.updatedAt,
            title: langOrderForm.t('create_order_transportation_label') + " " + labelIndex,
            label: QUOTE_STEP.Transportation + "-" + labelIndex,
            isValid: _isValidMain,
            isActive: _isActive,
            children: [
                {
                    label: QUOTE_STEP.Overview + "-" + labelIndex,
                    title: langOfferForm.t('offer_overview'),
                    isValid: function () { return true; },
                    isActive: _isActive,
                    content: (React.createElement(React.Fragment, null,
                        React.createElement(Typography, { variant: "subtitle1", className: 'step-label' }, langOfferForm.t('offer_from_label')),
                        React.createElement(Typography, { variant: "subtitle1", className: 'step-label' }, langOfferForm.t('offer_to_label')),
                        React.createElement(Typography, { variant: "subtitle1", className: 'step-label' }, langOfferForm.t('offer_navigation_cargo')))),
                    section: function () {
                        return (React.createElement(QuoteDetailsComponentOverview, { v3: true, order: {
                                _id: item.order._id,
                                transportations: [transportation],
                            }, v6: true }));
                    },
                },
                {
                    label: QUOTE_STEP.Transport + "-" + labelIndex,
                    title: langOfferForm.t('offer_transport'),
                    isActive: _isActive,
                    isValid: _isValid,
                    additionalTitle: is_review_offer &&
                        transportation.transports &&
                        transportation.transports.length &&
                        transportation.transports[0].updatedAt && (React.createElement("span", null,
                        langOfferForm.t('offer_accepted_at'),
                        ' ',
                        React.createElement(Typography, { variant: 'caption', color: 'primary' }, dateViewFormat(transportation.transports[0].updatedAt)))),
                    content: (React.createElement(React.Fragment, null,
                        React.createElement(Typography, { variant: "subtitle1", className: 'step-label' }, langOfferForm.t('offer_navigation_vehicle_info')),
                        React.createElement(Typography, { variant: "subtitle1", className: 'step-label' }, langOfferForm.t('offer_navigation_delivery_info')))),
                    section: function () {
                        return (React.createElement(TransportForm, { order: item.order, is_review_offer: is_review_offer, config: config, item: item, transportation: transportation, onChange: onChangeTransport }));
                    },
                },
                {
                    title: null,
                    label: QUOTE_STEP.Booking + "-" + labelIndex,
                    noPadding: true,
                    section: function () { return (React.createElement(TrackBooking, { config: config, onChangeDateRange: onChangeDateRange, item: item, is_review_offer: is_review_offer, disabled: is_review_offer ||
                            transportation.inTransitAt ||
                            transportation.unloadedAt ||
                            (transportation.endDate && transportation.endDate < toDateOnly(new Date())), endTo: (moment(transportation.startDate).isSame(moment(transportation.endDate), 'day') &&
                            transportation.endTime[0]) ||
                            0, transportation: transportation, onChange: onChange })); },
                    isValid: _isValid,
                    isActive: _isActive,
                },
            ],
        };
    }), [
        {
            label: QUOTE_STEP.DocumentAttached,
            title: langOfferForm.t('offer_document_attached'),
            isValid: function () { return 1; },
            isActive: isActive,
            section: function () {
                return (React.createElement(OrderQuoteDocuments, { onChange: onChangeFiles, config: config, quote: item, parent: item._id, folder: "private/orders/" + item.order._id + "/quotes/" + item._id + "/" }));
            },
        },
    ]).map(function (e, _id) { return (__assign(__assign({}, e), { _id: _id })); });
    if (!accountStore.currentUser._id) {
        steps.splice(steps.length - 1, 0, {
            label: QUOTE_STEP.Finished,
            title: langOfferForm.t('offer_finished'),
            isValid: function () { return item.order.status === OrderStatus.Finished; },
            isActive: function () { return item.order.status === OrderStatus.Finished; },
            section: function () { return null; },
        });
    }
    var startFrom = 0;
    if (is_review_offer &&
        !accountStore.currentUser.appType.find(function (e) { return [BaseUserAppTYpe.SENDER, BaseUserAppTYpe.CONSIGNEE].includes(e); })) {
        startFrom = 1;
        steps.splice(0, 0, {
            title: "" + langToast.t('toast_items_accepted').replace(/_X_/, langOfferForm.t('offer_title')),
            label: QUOTE_STEP.AcceptedQuote,
            isValid: function () { return true; },
            isActive: function () { return true; },
            additionalTitle: (React.createElement("span", null,
                langOfferForm.t('offer_accepted_at'),
                ' ',
                React.createElement(Typography, { variant: 'caption', color: 'primary' }, dateViewFormat(item.approvedAt)))),
            section: function () { return (React.createElement(OrderQuoteCardHeader, { short: true, noSpacing: true, order: item.order, record: item, hidePrice: !accountStore.currentUser._id })); },
        });
    }
    function onChangeTransport(transport) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                setItem(__assign(__assign({}, item), { updatedAt: new Date().toString(), order: __assign(__assign({}, item.order), { transportations: item.order.transportations.map(function (e) {
                            if (e._id === transport.orderTransportation._id) {
                                var orderTransportation = transport.orderTransportation, remainTransport = __rest(transport, ["orderTransportation"]);
                                return __assign(__assign(__assign({}, e), tempData), { transports: [remainTransport] });
                            }
                            return e;
                        }) }) }));
                return [2 /*return*/];
            });
        });
    }
    var onChange = function (input) {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function () {
            var transportation, key, suffixUpdates_1, res_2, _d, updatedAt_1, v, e_2;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        _e.trys.push([0, 3, 4, 5]);
                        setPending(true);
                        transportation = item.order.transportations.find(function (e) { return e._id === input._id; });
                        key = Object.keys(input).find(function (e) { return e !== '_id'; }) || '';
                        suffixUpdates_1 = "";
                        if (input.endDate &&
                            transportation.startDate &&
                            transportation.endTime[0] > transportation.startTime[0] &&
                            moment(transportation.startDate).isSame(moment(transportation.endDate), 'day')) {
                            input.endTime = [];
                            suffixUpdates_1 = ((_a = transportation.endTime) === null || _a === void 0 ? void 0 : _a.length) ? '_removed' : '_changed';
                        }
                        else if (input.startDate &&
                            transportation.endDate &&
                            moment(transportation.startDate).diff(moment(transportation.endDate), 'day') > 0) {
                            input.endDate = moment(input.startDate).add(1, 'days').toString();
                            suffixUpdates_1 = '_changed';
                        }
                        ['startDate', 'endDate'].forEach(function (e) {
                            if (input[e]) {
                                input[e] = toDateOnly(input[e]);
                                input.isBooking = 1;
                                suffixUpdates_1 = '_changed';
                            }
                        });
                        if (['startTime', 'endTime'].includes(key) && ((_b = transportation[key]) === null || _b === void 0 ? void 0 : _b.length)) {
                            if ((_c = input[key]) === null || _c === void 0 ? void 0 : _c.length) {
                                suffixUpdates_1 = '_changed';
                            }
                            else {
                                suffixUpdates_1 = '_removed';
                            }
                        }
                        _d = config;
                        if (!_d) return [3 /*break*/, 2];
                        return [4 /*yield*/, config.client.mutate({
                                mutation: UpdateOrderTransportation,
                                variables: {
                                    input: __assign(__assign({}, input), { quote: item._id }),
                                },
                            })];
                    case 1:
                        _d = (_e.sent());
                        _e.label = 2;
                    case 2:
                        res_2 = _d;
                        ['startDate', 'endDate'].forEach(function (e) {
                            if (input[e]) {
                                input[e] = toDateOnly(input[e]);
                                input.isBooking = 1;
                            }
                        });
                        ['endTime', 'startTime'].forEach(function (e) {
                            if (!res_2.data.updateOrderTransportation[e])
                                delete res_2.data.updateOrderTransportation[e];
                        });
                        updatedAt_1 = res_2.data.updateOrderTransportation.updatedAt;
                        v = __assign(__assign({}, item), { updatedAt: new Date().toString(), order: __assign(__assign({}, item.order), { transportations: item.order.transportations.map(function (e) {
                                    if (e._id === res_2.data.updateOrderTransportation._id) {
                                        var opt = __assign(__assign(__assign({}, e), input), { updatedAt: updatedAt_1 });
                                        if (input.startDate) {
                                            opt.startTime = [];
                                            opt.inTransitAt = null;
                                        }
                                        if (input.endDate) {
                                            opt.endTime = [];
                                            opt.unloadedAt = null;
                                        }
                                        return opt;
                                    }
                                    return e;
                                }) }) });
                        setItem(v);
                        if (key)
                            googleTagManager('event', "izi_form_offer_booking" + suffixUpdates_1, __assign(__assign(__assign({ event_name: "offer_booking" + suffixUpdates_1, action_source: "offer_details_page", booking_key: key, booking_value: input[key] }, [
                                'sorting_countryPickup',
                                'sorting_pickupAt',
                                'sorting_countryDelivery',
                                'sorting_deliveryAt',
                                'sorting_totalWeight',
                                'orderId',
                            ]
                                .filter(function (e) { return item.order[e]; })
                                .map(function (e) {
                                var _a;
                                return (_a = {}, _a["order_" + e] = item.order[e], _a);
                            })
                                .reduce(function (a, b) { return (__assign(__assign({}, a), b)); }, {})), (item.tempTransportations &&
                                item.tempTransportations[0] && {
                                offer_startDate: item.tempTransportations[0].startDate,
                                offer_endDate: item.tempTransportations[item.tempTransportations.length - 1].endDate,
                            })), { offer_amount: item.amount, offer_currency: item.currency }));
                        toast.success(langToast.t('toast_items_saved').replace(/_X_/, langOfferForm.t('offer_title')));
                        return [3 /*break*/, 5];
                    case 3:
                        e_2 = _e.sent();
                        toast.error(e_2.message);
                        googleTagManager('event', 'izi-error', { event_name: "error_offer_booking", value: e_2.message || e_2 });
                        return [3 /*break*/, 5];
                    case 4:
                        setTimeout(function () { return setPending(false); }, 1000);
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    var onChangeFiles = function (files, action) {
        if (action)
            return;
        setItem(__assign(__assign(__assign({}, item), tempData), { files: files }));
        tempData.files = files;
    };
    var body = loading ? (React.createElement(CircularIndeterminate, null)) : error ? (React.createElement(TextOverflow, { color: 'error' }, "Permission denied")) : (item &&
        item.order && (React.createElement(Grid, { key: item.updatedAt, container: true, id: "step-content-overview", className: clsx((_b = {},
            _b[mainClasses.disabled] = (pending || item.order.status !== OrderStatus.ApprovedQuote) && !is_review_offer,
            _b)) },
        React.createElement(Grid, { container: true, justify: 'space-between' },
            React.createElement(Typography, { variant: 'h4' },
                is_review_offer || item.order.state === OrderStatus.Finished
                    ? langForm.t('form_btn_view_offer')
                    : langForm.t('form_btn_view_offer_edit'),
                ":",
                React.createElement("b", { style: { marginLeft: theme.spacing(1) } }, item.order.orderId || '')),
            React.createElement(Grid, { container: true, style: { width: 'initial' }, alignItems: 'center' },
                React.createElement(OrderQuoteActions, { noParent: true, order: item.order, quote: item, hideActions: true, config: config, onChange: function () { return setItem(__assign(__assign({}, item), { updatedAt: Date.now() })); } }))),
        steps
            .sort(function (a, b) { return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(); })
            .map(function (step, index) { return (React.createElement(PreloadingComponent, { key: step.updatedAt || step._id },
            React.createElement(QuoteDetailsItem, { isCustomer: is_review_offer, index: index - startFrom, step: step, steps: steps, parent: item }))); }))));
    return (React.createElement(MainForm, { mapV1: true, steps: steps, markers: item.order.transportations.map(function (e) { return e.routes; }) }, body));
});
export var QuoteDetails = withRouter(QuoteDetailsComponent);
export default QuoteDetails;
