var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { Grid } from '@material-ui/core';
import { useSectionStyles } from '../../../../styles';
import { TextOverflow } from '../../../../components';
import { inject, observer } from 'mobx-react';
import { TransportationItemComponent } from './TransportationItem';
import { getSnapshot } from 'mobx-state-tree';
import { canEdit } from '../utils';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../../../i18n';
import { theme } from '../../../../theme';
import BaseTooltip from '../../../BaseTooltip';
import PaddingSpace from './PaddingSpce';
import { wrapTextToId } from '../../../../utils';
export var TransportationComponent = inject('store')(observer(React.forwardRef(function (props, ref) {
    var _a = props.store.orderStore, order = _a.order, createOrderTransportation = _a.createOrderTransportation;
    var langOrderForm = useTranslation(KEYS.ORDER_FORM);
    var _b = __read(React.useState(false), 2), loading = _b[0], setLoading = _b[1];
    var classes = useSectionStyles();
    var _onClick = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, 3, 4]);
                    setLoading(true);
                    return [4 /*yield*/, createOrderTransportation()];
                case 1:
                    _a.sent();
                    if (props.onChange)
                        props.onChange(e);
                    return [3 /*break*/, 4];
                case 2:
                    e_1 = _a.sent();
                    return [3 /*break*/, 4];
                case 3:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var snapshot = getSnapshot(order);
    var noEdit = !canEdit(order, props.store); //|| isOrderUpdating;
    return (React.createElement(Grid, { container: true, direction: 'column', className: classes.section },
        React.createElement(Grid, { container: true },
            React.createElement(PaddingSpace, null),
            React.createElement(Grid, { item: true, xs: 12, md: 11 },
                React.createElement(TextOverflow, { variant: "h4", className: classes.sectionTitle, style: { marginBottom: theme.spacing(2) } }, snapshot.transportations.length > 1
                    ? langOrderForm.t('order_form_transportations')
                    : langOrderForm.t('create_order_transportation_label')))),
        React.createElement(Grid, { container: true, direction: 'column' }, snapshot.transportations.map(function (e, index) { return (React.createElement(TransportationItemComponent, __assign({}, props, { key: "transportation-" + e._id, ref: ref[index], transportation: e, index: index, total: snapshot.transportations.length, nextTransportation: snapshot.transportations[index + 1], prevTransportation: snapshot.transportations[index - 1] }))); })),
        snapshot.transportations.length < 10 && !noEdit && snapshot._id ? (React.createElement(Grid, { container: true },
            React.createElement(PaddingSpace, { md: 10 }),
            React.createElement(Grid, { item: true, xs: 12, md: 1, container: true, justify: 'flex-end' },
                React.createElement(BaseTooltip, { title: langOrderForm.t('create_order_transportation_add_label') },
                    React.createElement(Fab, { id: wrapTextToId(langOrderForm.t('create_order_transportation_add_label')), color: "primary", disabled: loading, onClick: _onClick },
                        React.createElement(AddIcon, { style: { width: 54, height: 54 } })))))) : null));
})));
