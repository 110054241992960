var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { Grid, useMediaQuery, useTheme } from '@material-ui/core';
import { BaseListItem } from '../ListItem';
import { withBaseListStore } from '../store';
import { ACTION_CHANGE } from '../types.enums';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import { useStyles } from './styles';
import { makeStyles, createStyles } from '@material-ui/core/styles';
export var useStylesItem = makeStyles(function (theme) {
    return createStyles({
        item: {
            position: 'relative',
            '&:hover': {
                '& .base-list-item': {
                    boxShadow: "0px 2px 1px -2px rgb(0 0 0 / 20%), 0px 3px 3px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
                },
            },
        },
    });
});
export var BaseListBody = withBaseListStore(function (_a) {
    var state = _a.state, config = _a.config, onDeleteItem = _a.onDeleteItem, onAddItem = _a.onAddItem, onUpdateItem = _a.onUpdateItem, onFreshFilter = _a.onFreshFilter, onToggleSelection = _a.onToggleSelection, softRefresh = _a.softRefresh;
    var classesItem = useStylesItem();
    var classes = useStyles();
    var onChange = function (record, action, _a) {
        var _b = _a === void 0 ? {} : _a, refreshFilter = _b.refreshFilter;
        switch (action) {
            case ACTION_CHANGE.DELETED: {
                onDeleteItem(record);
                break;
            }
            case ACTION_CHANGE.UPDATED: {
                onUpdateItem(record);
                break;
            }
            case ACTION_CHANGE.ADDED: {
                onAddItem(record);
                break;
            }
            case ACTION_CHANGE.SOFT_REFRESH: {
                softRefresh();
                break;
            }
            default: {
                console.warn("Unrecognized action " + action);
            }
        }
        if (refreshFilter)
            onFreshFilter();
    };
    var theme = useTheme();
    var isSmall = useMediaQuery(theme.breakpoints.down('xs'));
    var rowStyles = Object.assign({}, config.rowStyles);
    var rowChildStyles = Object.assign({}, config.rowChildStyles, isSmall ? { width: '100%' } : null);
    var footer = config.dependableFooter ? config.dependableFooter(onChange) : null;
    if (config.isCard) {
        Object.assign(rowStyles, {
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
        });
        Object.assign(rowChildStyles, {
            marginRight: theme.spacing(2),
            marginBottom: theme.spacing(2),
        });
    }
    return (React.createElement(Grid, __assign({ style: rowStyles, id: "base-table-" + (config.id || config.title) + "-body", "data-total": state.count }, config.bodyProps),
        state.data.map(function (record) {
            var itemRow = config.rowContent ? (config.rowContent(record, onChange, { state: state })) : (React.createElement(BaseListItem, { record: record }));
            var rowProps = config.isSelectable
                ? {
                    container: true,
                    alignItems: 'center',
                }
                : null;
            return (React.createElement(Grid, __assign({ key: [record.updatedAt, record._id].join('_'), style: rowChildStyles, className: classesItem.item }, rowProps),
                config.isSelectable ? (React.createElement(Checkbox, __assign({ color: 'primary', style: { marginTop: theme.spacing(-2) }, checked: state.selected.includes(record._id), onChange: function () { return onToggleSelection(record._id); }, inputProps: { 'aria-label': 'select all desserts' } }, __assign({}, (config.canSelect && { disabled: !config.canSelect(record) }))))) : null,
                config.isSelectable ? (React.createElement(Grid, { container: true, className: classes.root }, itemRow)) : (itemRow)));
        }),
        footer));
});
