import React from 'react';
import { Grid } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import { SimpleMap } from '../../location/SimpleMap';
import { theme } from '../../../../theme';
import { TextOverflow } from '../../../TextOverflow';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../../../i18n';
import { useFormStyles } from '../../../../styles/form';
import { OrderStatus } from '../../../../models';
// import Stepper from '@material-ui/core/Stepper';
// import Step from '@material-ui/core/Step';
// import StepLabel from '@material-ui/core/StepLabel';
// import { makeStyles } from '@material-ui/styles';
var MyMap = inject('store')(observer(function (_a) {
    var markers = _a.markers, store = _a.store;
    return (store.orderStore.order && store.orderStore.order.updatedAt && (React.createElement(SimpleMap, { markers: markers, hideBack: true, key: store.orderStore &&
            (store.orderStore.order.transportations
                ? store.orderStore.order.transportations.map(function (t) {
                    return t.routes.map(function (r) {
                        return r.origin &&
                            [
                                (r.origin.address.location && r.origin.address.location.coordinates[1]) || 0,
                                (r.origin.address.location && r.origin.address.location.coordinates[0]) || 0,
                            ].join('-');
                    });
                })
                : store.orderStore.order.updatedAt) }))) || React.createElement(Grid, { container: true, style: { height: 168, backgroundColor: theme.palette.grey[100] } });
}));
// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     root1: {
//       minWidth: 330,
//     },
//     root: {
//       padding: 0,
//       '& svg': {
//         width: 40,
//         height: 40,
//       },
//       '& .MuiStepLabel-iconContainer': {
//         padding: 0,
//       },
//     },
//   })
// );
export default inject('store')(function OrderMap(props) {
    var langForm = useTranslation(KEYS.FORM);
    // const classes = useStyles();
    var classesFormStyle = useFormStyles();
    // const [steps] = React.useState([1, 2, 3]);
    // const [activeStep] = React.useState(props.store.orderStore.order.transportations[0].routes.length > 1 ? 1 : 0);
    return (React.createElement(Grid, { container: true, style: { marginBottom: theme.spacing(4) } },
        (props.store.orderStore.order.status === OrderStatus.Draft && (React.createElement(Grid, { container: true, direction: 'column', alignItems: 'center' },
            React.createElement(TextOverflow, { variant: 'h4', className: classesFormStyle.sectionTitle2 }, langForm.t('order_creation'))))) ||
            null,
        React.createElement(MyMap, { markers: [] })));
});
