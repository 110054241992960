var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import gql from 'graphql-tag';
import { BASE_RESPONSE } from '../base';
import { ORDER_TRANSPORTATION_BASE, ORDER_TRANSPORTATION_BASE_MORE } from './types';
export var FetchOrderTransportation = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    query orderTransportation($_id: MongoId!) {\n        orderTransportation(_id: $_id) {\n            ", "\n        }\n    }\n"], ["\n    query orderTransportation($_id: MongoId!) {\n        orderTransportation(_id: $_id) {\n            ", "\n        }\n    }\n"])), ORDER_TRANSPORTATION_BASE);
export var CreateOrderTransportation = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    mutation createOrderTransportation($input: OrderTransportationInput!) {\n        createOrderTransportation(input: $input) {\n            ", "\n        }\n    }\n"], ["\n    mutation createOrderTransportation($input: OrderTransportationInput!) {\n        createOrderTransportation(input: $input) {\n            ", "\n        }\n    }\n"])), ORDER_TRANSPORTATION_BASE);
export var UpdateOrderTransportation = gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    mutation updateOrderTransportation($input: OrderTransportationInputUpdate!) {\n        updateOrderTransportation(input: $input) {\n            ", "\n        }\n    }\n"], ["\n    mutation updateOrderTransportation($input: OrderTransportationInputUpdate!) {\n        updateOrderTransportation(input: $input) {\n            ", "\n        }\n    }\n"])), ORDER_TRANSPORTATION_BASE_MORE);
export var UpdateOrderTransportationV1 = gql(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    mutation updateOrderTransportationV1($input: OrderTransportationInputUpdate!) {\n        updateOrderTransportationV1(input: $input) {\n            ", "\n        }\n    }\n"], ["\n    mutation updateOrderTransportationV1($input: OrderTransportationInputUpdate!) {\n        updateOrderTransportationV1(input: $input) {\n            ", "\n        }\n    }\n"])), ORDER_TRANSPORTATION_BASE_MORE);
export var DeleteOrderTransportation = gql(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  mutation deleteOrderTransportation($_id: MongoId!) {\n    deleteOrderTransportation(_id: $_id){\n        ", "\n    }\n  }\n"], ["\n  mutation deleteOrderTransportation($_id: MongoId!) {\n    deleteOrderTransportation(_id: $_id){\n        ", "\n    }\n  }\n"])), BASE_RESPONSE);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
