var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { inject, useLocalStore } from 'mobx-react';
import { Grid, useMediaQuery } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { observer } from 'mobx-react';
import { withSidebarChannelChatStore } from './storeSidebarChannel';
import { KEYS } from '../../../../i18n';
import { formUserErrorMessage, onlySpacesRegExp } from '../../../../common';
import { onPreventEvent } from '../../../Dialog';
import { FormikTextField } from '../../../Formik';
import { theme } from '../../../../theme';
import Emoji from './Emojii';
import { makeStyles } from '@material-ui/core/styles';
import { MainButton } from '../../../MainButton';
import PreloadingComponent from '../../../PreloadingComponent';
import { UploadList } from '../../../Uploads/List';
import { AddFormButton } from '../../../AddFormButton';
var AllUploadList = React.lazy(function () { return import('./Uploads'); });
var useStyles = makeStyles(function (theme) { return ({
    root: {
        '& textarea': {
            paddingRight: theme.spacing(2.5),
        },
    },
}); });
export var SidebarChannelItemDetailsChatForm = observer(function (_a) {
    var sidebarChannelChat = _a.sidebarChannelChat, config = _a.config, props = __rest(_a, ["sidebarChannelChat", "config"]);
    var formRef = React.useRef(null);
    var messageInputRef = React.useRef(null);
    var currentUser = props.store.accountStore.currentUser;
    var langForm = useTranslation(KEYS.FORM);
    var langOfferForm = useTranslation(KEYS.OFFER_FORM);
    var classes = useStyles();
    var localStore = useLocalStore(function () { return ({
        updatedAt: 1,
        defValue: { text: '', upload: null, chat: sidebarChannelChat.state.item && sidebarChannelChat.state.item._id },
        error: '',
        isLoading: false,
        isSent: false,
        onSubmit: function (input, opt) {
            var _a;
            return __awaiter(this, void 0, void 0, function () {
                var res, error_1;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            (_a = messageInputRef === null || messageInputRef === void 0 ? void 0 : messageInputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
                            localStore.error = '';
                            localStore.isLoading = true;
                            if (opt === null || opt === void 0 ? void 0 : opt.resetForm) {
                                opt.resetForm({ values: '' });
                            }
                            _b.label = 1;
                        case 1:
                            _b.trys.push([1, 3, 4, 5]);
                            localStore.defValue = {
                                text: '',
                                upload: null,
                                chat: sidebarChannelChat.state.item && sidebarChannelChat.state.item._id,
                            };
                            return [4 /*yield*/, sidebarChannelChat.createOrderChatMessage(input, { createdBy: { _id: currentUser._id } })];
                        case 2:
                            res = _b.sent();
                            localStore.updatedAt = Date.now();
                            if (res.isRestored) {
                                props.store.accountStore.refreshSidebarChatList();
                            }
                            localStore.isSent = true;
                            return [3 /*break*/, 5];
                        case 3:
                            error_1 = _b.sent();
                            localStore.error = formUserErrorMessage(error_1);
                            return [3 /*break*/, 5];
                        case 4:
                            localStore.isLoading = false;
                            return [7 /*endfinally*/];
                        case 5: return [2 /*return*/];
                    }
                });
            });
        },
        clearError: function () {
            localStore.error = '';
        },
    }); });
    var onEnter = function (e) {
        formRef.current.submitForm();
        return onPreventEvent(e);
    };
    var onChange = function (e) {
        sidebarChannelChat.typing({ chat: sidebarChannelChat.state.item });
    };
    var isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    var chat = sidebarChannelChat.state.item;
    var key = localStore.updatedAt + "-" + (localStore.isLoading ? 1 : 2) + "-" + (sidebarChannelChat.state.updating ? 1 : 2);
    return (React.createElement(Grid, { container: true, style: { padding: props.v3 ? 0 : theme.spacing(0, 2, 2, 2) } },
        React.createElement(Formik, { innerRef: formRef, initialValues: localStore.defValue, onSubmit: localStore.onSubmit, validationSchema: Yup.object({
                text: Yup.string()
                    .required(langForm.t('form_input_validate_required'))
                    .matches(onlySpacesRegExp, langForm.t('form_input_validate_invalid_text'))
                    .max(1500, langForm.t('form_input_validate_max_char').replace(/_X_/g, '1500')),
            }), validateOnBlur: false, validateOnChange: false }, function (formik) {
            var loading = sidebarChannelChat.state.updating || localStore.isLoading;
            var onInput = function (e) {
                formik.setFieldValue(e.target.name, e.target.value);
                onChange(e);
            };
            var _onChange = function (files) { return __awaiter(void 0, void 0, void 0, function () {
                var e;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!files.length) return [3 /*break*/, 4];
                            e = files.shift();
                            return [4 /*yield*/, localStore.onSubmit(__assign(__assign({}, localStore.defValue), { text: formik.values.text || '', upload: e._id }))];
                        case 1:
                            _a.sent();
                            return [4 /*yield*/, formik.setFieldValue('text', "")];
                        case 2:
                            _a.sent();
                            return [4 /*yield*/, formik.setFieldValue('upload', null)];
                        case 3:
                            _a.sent();
                            return [3 /*break*/, 0];
                        case 4: return [2 /*return*/];
                    }
                });
            }); };
            var allUploads = (React.createElement(PreloadingComponent, null,
                React.createElement(AllUploadList, __assign({}, (props.v3 && { v3: true }), { hideHeader: true, config: config, defaultFilter: { fields: [{ name: 'parent', value: localStore.defValue.chat }] } }))));
            var uploadNew = (React.createElement(UploadList, __assign({ onChange: _onChange, config: config, actionInit: function (onShowModal) {
                    return props.v3 ? (React.createElement(AddFormButton, { onClick: onShowModal, title: langOfferForm.t('offer_form_document_add') })) : (React.createElement(MainButton, { fullWidth: true, 
                        // loading={loading}
                        onClick: onShowModal, title: langOfferForm.t('offer_form_document_add') }));
                }, quote: {
                    parent_collection_name: 'order_chats',
                    parent: chat === null || chat === void 0 ? void 0 : chat._id,
                } }, {
                parent_collection_name: 'order_chats',
                parent: chat === null || chat === void 0 ? void 0 : chat._id,
                folder: "private/chats/" + (chat === null || chat === void 0 ? void 0 : chat._id),
            })));
            var documentBtns = (React.createElement(React.Fragment, null,
                React.createElement(Grid, { container: true, item: true, xs: 12, md: 5, style: __assign({}, ((!isSmall && { paddingRight: theme.spacing(2) }) || { paddingRight: 0 })) }, allUploads),
                React.createElement(Grid, { container: true, item: true, xs: 12, md: 7, style: __assign({}, (isSmall && { paddingRight: 0 })) }, uploadNew)));
            var sendBtn = (React.createElement(MainButton, __assign({}, (props.v3 && { v3: true }), { fullWidth: !props.v3, type: "submit", title: langForm.t('form_btn_to_send_from_forwarder_to_customer') })));
            return (React.createElement(Form, { id: 'creation-form', style: { width: '100%' } }, props.v3 ? (React.createElement(Grid, { item: true, container: true, xs: 12, style: { position: 'relative' }, alignItems: 'center', spacing: 1 },
                React.createElement(Grid, { item: true, container: true, xs: 12, md: 4, alignItems: 'center' },
                    allUploads,
                    React.createElement("div", null, uploadNew)),
                React.createElement(Grid, { item: true, container: true, xs: 12, md: true, justify: 'flex-end' },
                    React.createElement(Grid, { item: true, xs: 12, md: 7 },
                        React.createElement(FormikTextField, { id: 'chat-text-input', title: langForm.t('form_input_comment'), variant: "outlined", name: "text", value: formik.values.text, className: classes.root, onEnter: onEnter, onChange: onChange, inputRef: messageInputRef }))),
                React.createElement(Grid, { item: true, xs: 12, md: 'auto', style: { paddingRight: 0 } }, sendBtn))) : (React.createElement(Grid, { container: true, direction: "column", spacing: 2 },
                React.createElement(Grid, { item: true, xs: 12, style: { position: 'relative' } },
                    React.createElement(FormikTextField, { id: 'chat-text-input', multiline: true, rows: 4, title: langForm.t('form_input_comment'), variant: "outlined", name: "text", value: formik.values.text, className: classes.root, onEnter: onEnter, onChange: onChange, inputRef: messageInputRef }),
                    React.createElement(Emoji, { onChange: function (emoji) {
                            return onInput({ target: { name: 'text', value: (formik.values.text || '') + emoji } });
                        } })),
                React.createElement(Grid, { item: true, xs: 12, container: true, style: { width: 'initial', paddingRight: 0 }, direction: 'row', spacing: 2, justify: 'flex-end' },
                    (config &&
                        (isSmall ? (documentBtns) : (React.createElement(Grid, { container: true, item: true, xs: 12, md: 6 }, documentBtns)))) ||
                        null,
                    React.createElement(Grid, { container: true, item: true, xs: 12, md: config ? 6 : 12, style: { paddingRight: 0 } }, sendBtn))))));
        })));
});
// @ts-ignore
export default inject('store')(withSidebarChannelChatStore(SidebarChannelItemDetailsChatForm));
