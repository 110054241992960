import React from 'react';
import { Divider, Grid } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { TabPanel, a11yProps } from '@izi-logistics/common/lib/components';
import { useTranslation } from 'react-i18next';
import { KEYS } from '@izi-logistics/common/lib/i18n';
import UsersList from '@izi-logistics/common/lib/pages/Users';
import { FetchAdminUsers } from '@izi-logistics/common/lib/graphql';
import { client } from '../../api/apolloClient';
import { theme } from '@izi-logistics/common/lib/theme';
import { InviteTeamMembers } from '@izi-logistics/common/lib/graphql';

export default ({ record }) => {
  const [tab, setTab] = React.useState(0);
  const langApp = useTranslation(KEYS.APP);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
  };

  return (
    <Grid container direction={'column'}>
      <Divider style={{ width: '100%' }} />
      <Tabs value={tab} onChange={handleChange} indicatorColor="primary" textColor="primary">
        <Tab label={langApp.t('team_label')} color={'primary'} {...a11yProps(0)} />
      </Tabs>

      <TabPanel value={tab} index={0}>
        <Grid container>
          <Divider style={{ width: '100%', marginBottom: theme.spacing(1) }} />
          <Grid container style={{ padding: theme.spacing(0, 2) }}>
            <UsersList
              v1
              title={langApp.t('team_label')}
              config={{
                client,
                query: FetchAdminUsers,
                variables: { fields: [{ name: 'company', value: record._id }] },
              }}
              addMemberProps={{
                hideCompany: true,
                company: record,
                onAddUserCompany: (users) =>
                  client.mutate({
                    mutation: InviteTeamMembers,
                    variables: {
                      input: users.map((user) => ({
                        user: {
                          rolesV1: user.rolesV1.map((e) => e._id || e),
                          phone: user.phone,
                          firstName: user.firstName,
                          lastName: user.lastName,
                          roles: user.roles,
                          email: user.email,
                          appType: [record.type],
                        },
                        company: record._id,
                      })),
                    },
                  }),
              }}
            />
          </Grid>
        </Grid>
      </TabPanel>
    </Grid>
  );
};
