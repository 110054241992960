import React from 'react';
import { client } from '../../api/apolloClient';
import { MainFilter } from '@izi-logistics/common/lib/components/BasicList/types';
import { FetchForwarderCompanies } from '@izi-logistics/common/lib/graphql';
import { BaseList } from '@izi-logistics/common/lib/components';
import { useTranslation } from 'react-i18next';
import { KEYS } from '@izi-logistics/common/lib/i18n';
import { theme } from '@izi-logistics/common/lib/theme';
import CompanyItem from './CompanyItem';
import { IZIBaseCompany } from '@izi-logistics/common/lib/stores';
import { TextOverflow } from '@izi-logistics/common/lib/components';
import { Box, Grid } from '@material-ui/core';
import { BaseCompany } from '@izi-logistics/common/lib/stores';
import { useLocalStore } from 'mobx-react-lite';
import { inject, observer } from 'mobx-react';
import { BaseUserAppTYpe } from '@izi-logistics/common/lib';
import FormGroup from '@material-ui/core/FormGroup/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import Switch from '@material-ui/core/Switch/Switch';
import { onPreventEvent } from '@izi-logistics/common/lib';
import CompanyFormModal from '@izi-logistics/common/lib/components/Account/CompanyFormModal';
import CarrierFormModal from '@izi-logistics/common/lib/components/Team/Modal/CarrierFormModal';
import AddSplitButton from '@izi-logistics/common/lib/pages/Invoices/AddButton';
import BaseListTitle from '@izi-logistics/common/lib/components/BasicList/Header/BaseListTitle';

const CompaniesTitle = inject('store')(
  observer(
    ({
      store: {
        accountStore: { currentCompany, currentUser },
      },
      onChange,
    }: any) => {
      const langForm = useTranslation(KEYS.FORM);
      const langMenu = useTranslation(KEYS.MENU);
      return (
        <Grid container alignItems={'center'} justify={'space-between'}>
          <Grid container alignItems={'center'} style={{ width: 'initial' }}>
            <BaseListTitle title={langMenu.t('menu_sidebar_carriers')} style={{ marginRight: theme.spacing(1) }} />
            <FormGroup
              onClick={(e) => {
                onPreventEvent(e);
                currentCompany.update({
                  settings: {
                    ...currentCompany.settings,
                    only_my_companies_receive_notifications: !currentCompany.settings
                      .only_my_companies_receive_notifications,
                  },
                });
              }}>
              <FormControlLabel
                label={
                  <TextOverflow>
                    {langForm.t('form_input_company_only_my_companies_receive_notifications')}
                  </TextOverflow>
                }
                control={
                  <Switch
                    color={'primary'}
                    checked={currentCompany.settings.only_my_companies_receive_notifications}
                    name={'settings'}
                  />
                }
              />
            </FormGroup>
          </Grid>

          <Box display={'flex'} gridGap={2}>
            <AddSplitButton
              v1
              buttons={[
                <CarrierFormModal client={client} onChange={onChange} />,
                <CompanyFormModal
                  record={BaseCompany.create({
                    type: BaseUserAppTYpe.CARRIER,
                    settings: { has_email_notifications: true },
                  })}
                  isCarrier
                  onChange={onChange}
                />,
              ]}
            />
          </Box>
        </Grid>
      );
    }
  )
);

export default observer(() => {
  const langOfferForm = useTranslation(KEYS.OFFER_FORM);
  const langForm = useTranslation(KEYS.FORM);
  const langMenu = useTranslation(KEYS.MENU);

  const localStore: any = useLocalStore(() => ({
    key: 1,
    onChange() {
      localStore.key = Date.now();
    },
  }));
  const headerColumns = [
    { size: 2, name: langForm.t('form_input_name') },
    { size: 2, name: langForm.t('form_input_name_vat_code') },
    { size: 2, name: langForm.t('form_input_name_company_code') },
    { size: 3, name: langForm.t('form_input_name_address') },
    { size: 1, name: langForm.t('form_input_name_joined_at') },
    {
      size: 2,
      name: langOfferForm.t('offer_form_document_actions'),
      style: { display: 'flex', justifyContent: 'flex-end' },
    },
  ];
  const _config = {
    id: 'ca-companies',
    title: <CompaniesTitle onChange={localStore.onChange} />,
    headerStyle: { padding: theme.spacing(0, 0) },
    headerColumns,
    rowContent: (record: IZIBaseCompany) => (
      <CompanyItem headerColumns={headerColumns} config={{ client }} record={record} onChange={localStore.onChange} />
    ),
  };
  const defaultFilter = { fields: [{ name: 'type', value: BaseUserAppTYpe.CARRIER }] } as MainFilter;

  const baseListProps = {
    client,
    query: FetchForwarderCompanies,
    defaultFilter,
    searchFields: ['name', 'vatCode'],
    sortFields: [
      { name: langForm.t('form_input_name'), value: 'name' },
      { name: langForm.t('form_input_name_vat_code'), value: 'vatCode' },
      { name: langForm.t('form_input_name_company_code'), value: 'companyCode' },
      { name: langForm.t('form_input_name_joined_at'), value: 'createdAt' },
    ],
  };

  return (
    <BaseList
      key={localStore.key}
      options={baseListProps}
      title={langMenu.t('menu_sidebar_companies_title')}
      config={_config}
    />
  );
});
