var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useCallback } from 'react';
import { inject, observer } from 'mobx-react';
import { OrderNameForm } from '../../components/Order/form/formsV1/OrderNameForm';
import { TransportRequirementsForm } from '../../components/Order/form/formsV1/TransportRequirementsForm';
import { AdditionalRequirementsForm } from '../../components/Order/form/formsV1/AdditionalRequirementsForm';
import { CreationForm } from '../../components/Order/form/formsV1/CreationForm';
import DeclarationForm from '../../components/Order/form/formsV1/Declaration';
import PreparationForm from '../../components/Order/form/formsV1/Preparation';
import { Order, OrderCargo, OrderLocationMode, OrderRouteType, OrderStatus } from '../../models';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { TransportationComponent } from '../../components/Order/form/formsV1/Transportations';
import { CircularIndeterminate } from '../../components';
import { KEYS } from '../../i18n';
import * as reactRouter from 'react-router';
import { getSnapshot } from 'mobx-state-tree';
import { FetchLocationPerWarehouse } from '../../graphql';
import { toDateOnly } from '../../utils';
import { BaseUserAppTYpe } from '../../stores/common';
import { a11yProps, TabPanelV1, TabsV1, TabV1 } from '../../components/Settings/Tabs';
import { useSectionStyles } from '../../styles';
import { theme } from '../../theme';
import OrderMap from '../../components/Order/form/formsV1/OrderMap';
import { withGeneralStates } from '../../HOK/withGeneralStates';
var Redirect = reactRouter.Redirect;
export var OrderCreationMode;
(function (OrderCreationMode) {
    OrderCreationMode["BASIC"] = "BASIC";
    OrderCreationMode["PREPARATION"] = "PREPARATION";
})(OrderCreationMode || (OrderCreationMode = {}));
var OrderForm = inject('store')(observer(function (_a) {
    var _b;
    var generalStates = _a.generalStates, props = __rest(_a, ["generalStates"]);
    var langApp = useTranslation(KEYS.APP);
    var langOrderForm = useTranslation(KEYS.ORDER_FORM);
    var store = props.store, match = props.match, config = props.config;
    var orderId = match.params.orderId;
    var orderStore = store.orderStore;
    var declarationFormRef = React.useRef();
    var preparationFormRef = React.useRef();
    var transportFormRef = React.useRef();
    var transportationFormRef = Array.from({ length: 10 }, function (a) {
        return Array.from({ length: 2 }, function () { return Array.from({ length: 10 }, function () { return React.useRef(null); }); });
    }); //TODO: hope we will not rich out the 10 forms, otherwise need to have class form validation
    var additionFormRef = React.useRef();
    var nameFormRef = React.useRef();
    var createFormRef = React.useRef();
    var isRestore = location.href.toLowerCase().match(/restore/i);
    var isCreate = location.href.toLowerCase().match(/create/i);
    var classes = useSectionStyles();
    var _c = __read(React.useState(0), 2), selectedTab = _c[0], setTab = _c[1];
    var _d = __read(React.useState(OrderCreationMode.BASIC), 1), mode = _d[0];
    var order = store.orderStore.order;
    var transportation = order === null || order === void 0 ? void 0 : order.transportations[0];
    var withStartPreparation = !order.isTemplate && order.hasPreparing;
    if (transportation) {
        withStartPreparation =
            withStartPreparation &&
                !((transportation.startDate < toDateOnly(new Date()) || transportation.inTransitAt) &&
                    !order.hasPreparing &&
                    !order.preparedAt);
    }
    var handleChangeTab = function (event, newValue) {
        setTab(newValue);
    };
    var statusOrders = false;
    /*eslint-disable */
    var loadOrder = useCallback(function () {
        var fetchOrder = function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if ((isRestore || isCreate) &&
                                [BaseUserAppTYpe.CONSIGNEE, BaseUserAppTYpe.SENDER, BaseUserAppTYpe.WAREHOUSE].includes(store.accountStore.currentCompany.type)) {
                                return [2 /*return*/];
                            }
                            return [4 /*yield*/, orderStore.loadOrder(orderId)];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        };
        fetchOrder();
    }, [orderId]);
    useEffect(function () {
        orderStore.loadPreparingStatuses();
    }, []);
    useEffect(function () {
        if (isCreate && !orderStore.isOrderUpdating) {
            generalStates.init();
            orderStore.clearOrder(__assign(__assign({}, getSnapshot(Order.create())), { transportations: [
                    {
                        routes: [],
                        cargoes: [],
                    },
                ] }));
            orderStore.createOrder({ transportations: [{ routes: [], cargoes: [] }] }).finally(generalStates.onFinish);
        }
    }, []);
    useEffect(function () {
        if (orderId && !statusOrders) {
            loadOrder();
        }
        else {
            var query_1 = Object.fromEntries(new URLSearchParams(window.location.search).entries());
            var orderToReset_1;
            if (query_1.warehouse && (query_1.pickup || query_1.delivery)) {
                var oldOrder_1 = getSnapshot(orderStore.order);
                config.client
                    .query({
                    query: FetchLocationPerWarehouse,
                    variables: {
                        _id: query_1.warehouse,
                    },
                })
                    .then(function (res) {
                    var origin = res.data.locationPerWarehouse;
                    var isSimple = origin.mode === OrderLocationMode.SIMPLE;
                    orderToReset_1 = __assign(__assign(__assign(__assign({ noQuotes: true }, (query_1.timeToSpend && { timeToSpend: parseInt(query_1.timeToSpend) })), (query_1.time && { timePickupBooked: query_1.time })), (query_1.timeIsPickup && { timeIsPickup: parseInt(query_1.timeIsPickup) })), { transportations: [
                            __assign(__assign({}, oldOrder_1.transportations[0]), { routes: [
                                    __assign({ type: OrderRouteType.Preferred }, ((query_1.pickup && {
                                        origin: origin,
                                        email: isSimple ? origin.email : (origin.members[0] && [origin.members[0].email]) || [],
                                        startDate: toDateOnly(query_1.pickup),
                                        endDate: toDateOnly(query_1.pickup),
                                    }) ||
                                        {})),
                                    __assign({ type: OrderRouteType.Delivery }, ((query_1.delivery && {
                                        origin: origin,
                                        email: isSimple ? origin.email : (origin.members[0] && [origin.members[0].email]) || [],
                                        startDate: toDateOnly(query_1.delivery),
                                        endDate: toDateOnly(query_1.delivery),
                                    }) ||
                                        {})),
                                ], cargoes: [
                                    getSnapshot(OrderCargo.create({ weightMeasure: store.accountStore.currentCompany.settings.weightMeasure })),
                                ] }),
                        ] });
                    orderStore.clearOrder(orderToReset_1);
                })
                    .catch(console.log);
            }
            // orderStore.clearOrder({
            //   transportations: [
            //     {
            //       cargoes: [getSnapshot(OrderCargo.create())],
            //     },
            //   ],
            // });
        }
        return function () {
            setTimeout(function () {
                try {
                    if (!location.pathname.startsWith('/orders/')) {
                        orderStore.clearOrder();
                        console.log('clear order store');
                    }
                }
                catch (e) { }
            }, 300);
        };
    }, [orderId, loadOrder, statusOrders]);
    /*eslint-enable */
    var onSubmitAll = function (lvl, opt) {
        if (lvl === void 0) { lvl = 3; }
        if (opt === void 0) { opt = {}; }
        return __awaiter(void 0, void 0, void 0, function () {
            var _loop_1, i, state_1, createFormRefInput, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (lvl === 1)
                            return [2 /*return*/, 1];
                        return [4 /*yield*/, onSubmitAll(lvl - 1, { validateAll: 1 })];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 12, , 13]);
                        return [4 /*yield*/, nameFormRef.current.submitForm()];
                    case 3:
                        if (!(_a.sent())) {
                            document.getElementById("name-form").querySelector('input').focus();
                            console.error('name Form No Valid');
                            if (!opt.validateAll)
                                return [2 /*return*/, -1];
                        }
                        _loop_1 = function (i) {
                            var refs, parentItemId, j, forRef, _loop_2, k, state_2;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        refs = transportationFormRef[i];
                                        parentItemId = "step-content-transportation-" + (i + 1);
                                        j = 0;
                                        _a.label = 1;
                                    case 1:
                                        if (!(j < refs.length)) return [3 /*break*/, 6];
                                        forRef = refs[j];
                                        _loop_2 = function (k) {
                                            var parentSubItemId, _a;
                                            return __generator(this, function (_b) {
                                                switch (_b.label) {
                                                    case 0:
                                                        parentSubItemId = j === 0 ? "route-form-" + k : "cargo-form-" + k;
                                                        _a = forRef[k].current;
                                                        if (!_a) return [3 /*break*/, 2];
                                                        return [4 /*yield*/, forRef[k].current.submitForm()];
                                                    case 1:
                                                        _a = !(_b.sent());
                                                        _b.label = 2;
                                                    case 2:
                                                        if (_a) {
                                                            if (!opt.validateAll) {
                                                                setTab(function () {
                                                                    var parent = document.querySelector("#" + parentItemId + " #" + parentSubItemId + " input:not(:disabled)[type=\"text\"]");
                                                                    parent.focus();
                                                                    return 0;
                                                                });
                                                                console.error(parentItemId + " " + parentSubItemId + " Form No Valid");
                                                                return [2 /*return*/, { value: -1 }];
                                                            }
                                                        }
                                                        return [2 /*return*/];
                                                }
                                            });
                                        };
                                        k = isRestore && j === 0 ? forRef.length - 1 : 0;
                                        _a.label = 2;
                                    case 2:
                                        if (!(isRestore && j === 0 ? k >= 0 : k < forRef.length)) return [3 /*break*/, 5];
                                        return [5 /*yield**/, _loop_2(k)];
                                    case 3:
                                        state_2 = _a.sent();
                                        if (typeof state_2 === "object")
                                            return [2 /*return*/, state_2];
                                        _a.label = 4;
                                    case 4:
                                        isRestore && j === 0 ? k-- : k++;
                                        return [3 /*break*/, 2];
                                    case 5:
                                        j++;
                                        return [3 /*break*/, 1];
                                    case 6: return [2 /*return*/];
                                }
                            });
                        };
                        i = 0;
                        _a.label = 4;
                    case 4:
                        if (!(i < transportationFormRef.length)) return [3 /*break*/, 7];
                        return [5 /*yield**/, _loop_1(i)];
                    case 5:
                        state_1 = _a.sent();
                        if (typeof state_1 === "object")
                            return [2 /*return*/, state_1.value];
                        _a.label = 6;
                    case 6:
                        i++;
                        return [3 /*break*/, 4];
                    case 7: return [4 /*yield*/, transportFormRef.current.submitForm()];
                    case 8:
                        if (!(_a.sent())) {
                            if (!opt.validateAll) {
                                setTab(function () {
                                    document.getElementById("transport-form").querySelector('input').focus();
                                    return 1;
                                });
                                console.error('Transport Form No Valid');
                                return [2 /*return*/, -1];
                            }
                        }
                        return [4 /*yield*/, additionFormRef.current.submitForm()];
                    case 9:
                        if (!(_a.sent())) {
                            if (!opt.validateAll) {
                                setTab(function () {
                                    document.getElementById("additional-form").querySelector('input').focus();
                                    return 1;
                                });
                                console.error('Additional Form No Valid');
                                return [2 /*return*/, -1];
                            }
                        }
                        return [4 /*yield*/, declarationFormRef.current.submitForm()];
                    case 10:
                        if (!(_a.sent())) {
                            if (!opt.validateAll) {
                                setTab(function () {
                                    document.getElementById("declaration-form").querySelector('input').focus();
                                    return 2;
                                });
                                console.error('declaration Form No Valid');
                                return [2 /*return*/, -1];
                            }
                        }
                        return [4 /*yield*/, createFormRef.current.submitForm()];
                    case 11:
                        createFormRefInput = _a.sent();
                        if (!createFormRefInput) {
                            document.getElementById("creation-form").querySelector('input').focus();
                            console.error('create Form No Valid');
                            if (!opt.validateAll)
                                return [2 /*return*/, -1];
                        }
                        return [2 /*return*/, 1];
                    case 12:
                        e_1 = _a.sent();
                        console.log('onSubmitAll error--', e_1);
                        return [2 /*return*/, -1];
                    case 13: return [2 /*return*/];
                }
            });
        });
    };
    var onChangeAll = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/];
        });
    }); };
    if (isRestore && order._id && ![OrderStatus.Finished, OrderStatus.ApprovedQuote].includes(order.status)) {
        return React.createElement(Redirect, { to: "/open-orders" });
    }
    else if ((isRestore || isCreate) &&
        [BaseUserAppTYpe.CONSIGNEE, BaseUserAppTYpe.SENDER, BaseUserAppTYpe.WAREHOUSE].includes(store.accountStore.currentCompany.type)) {
        return React.createElement(Redirect, { to: "/in-progress" });
    }
    var body = store.orderStore.isOrderLoading || generalStates.loading ? (React.createElement("div", { style: { width: '100%', position: 'relative' } },
        React.createElement(Grid, { container: true, justify: 'center', style: { width: '100%', position: 'absolute' } },
            React.createElement(CircularIndeterminate, { color: 'primary' })))) : (React.createElement(React.Fragment, null,
        React.createElement(OrderMap, null),
        React.createElement(OrderNameForm, { mode: mode, ref: nameFormRef, config: config }),
        React.createElement(Grid, { container: true, style: { marginBottom: theme.spacing(2) } }),
        React.createElement(Grid, { container: true, justify: 'flex-end' },
            React.createElement(TabsV1, { value: selectedTab, onChange: handleChangeTab, id: 'order-form-tabs' },
                React.createElement(TabV1, __assign({ label: ((_b = order === null || order === void 0 ? void 0 : order.transportations) === null || _b === void 0 ? void 0 : _b.length) > 1
                        ? langOrderForm.t('order_form_transportations')
                        : langOrderForm.t('create_order_transportation_label') }, a11yProps(0))),
                React.createElement(TabV1, __assign({ label: langOrderForm.t('create_order_transport_label') }, a11yProps(1))),
                React.createElement(TabV1, __assign({ label: langOrderForm.t('create_order_cargo_declaration_more') }, a11yProps(2), { isLast: !withStartPreparation })),
                withStartPreparation ? (React.createElement(TabV1, __assign({ label: langApp.t('app_preparation') }, a11yProps(3), { isLast: withStartPreparation }))) : null)),
        React.createElement("div", { className: classes.sectionItem, style: { marginBottom: theme.spacing(2) } },
            React.createElement(TabPanelV1, { value: selectedTab, index: 0 },
                React.createElement(TransportationComponent, { mode: mode, ref: transportationFormRef, config: config, onChange: onChangeAll })),
            React.createElement(TabPanelV1, { value: selectedTab, index: 1 },
                React.createElement(TransportRequirementsForm, { mode: mode, ref: transportFormRef, config: config }),
                React.createElement(AdditionalRequirementsForm, { ref: additionFormRef, config: config, key: store.orderStore.order.updatedAt || 1 }),
                React.createElement(Grid, { container: true, style: { marginBottom: theme.spacing(4) } })),
            React.createElement(TabPanelV1, { value: selectedTab, index: 2 },
                React.createElement(DeclarationForm, { mode: mode, ref: declarationFormRef, config: config })),
            withStartPreparation ? (React.createElement(TabPanelV1, { value: selectedTab, index: 3 },
                React.createElement(PreparationForm, { mode: mode, open: selectedTab === 3, ref: preparationFormRef, config: config }))) : null),
        React.createElement(Grid, { container: true, className: classes.sectionItem },
            React.createElement(CreationForm, { onSubmit: function () { return onSubmitAll(); }, ref: createFormRef, config: config, handleChangeTab: handleChangeTab, selectedTab: selectedTab }))));
    return (React.createElement(Grid, { id: "createOrder", style: { margin: 'auto', maxWidth: 1220 }, container: true, key: store.orderStore.updatedAt }, body));
}));
export default withGeneralStates(OrderForm);
