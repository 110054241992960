var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import { inject, observer, useObserver } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { Formik, Form } from 'formik';
import { OrderLoadingType } from '../../../../models';
import { getSnapshot } from 'mobx-state-tree';
import { canEdit, times } from '../utils';
import { BASIC_DIALOG_ACTIONS, BasicDialog, FormikTextField, TextOverflow } from '../../../../components';
import * as Yup from 'yup';
import { KEYS, i18n } from '../../../../i18n';
import { FormikAutocomplete } from '../../../../components';
import { useChipStyles } from '../../../../styles';
import { useSectionStyles } from '../../../../styles';
import { onlySpacesRegExp } from '../../../../common';
import { theme } from '../../../../theme';
import PaddingSpace, { PaddingSpaceSmall } from './PaddingSpce';
import PreloadingComponent from '../../../PreloadingComponent';
import ExpandFormItem from '../../../ExpandFormItem';
import BaseChip from '../../../BaseChip';
import { OrderCreationMode } from '../../../../pages/Order/FormV1';
import { OrderTemperatureForm } from './OrderTemperatureForm';
var FormikVehicleType = React.lazy(function () { return import('../../../../components/Formik/FormikVehicleType'); });
export var getLoadingType = function (option) {
    return option.toLowerCase() === 'other'
        ? i18n.getResource(i18n.language, KEYS.FORM, 'form_input_other')
        : i18n.getResource(i18n.language, KEYS.ORDER_FORM, "create_order_loading_types_dropwodn_menu_" + option.toLowerCase());
};
export var hasThermoTrailer = function (vehicleTypes) {
    return !!vehicleTypes.find(function (vehicleType) { return vehicleType.name === 'Thermo trailer, reefer'; });
};
export var TransportRequirementsForm = inject('store')(observer(React.forwardRef(function (_a, ref) {
    var store = _a.store, client = _a.config.client, mode = _a.mode, props = __rest(_a, ["store", "config", "mode"]);
    var classes = useSectionStyles();
    var classesChip = useChipStyles();
    var langOrderForm = useTranslation(KEYS.ORDER_FORM);
    var langForm = useTranslation(KEYS.FORM);
    var langApp = useTranslation(KEYS.APP);
    var _b = __read(React.useState(false), 1), onlyMyTransports = _b[0];
    var temperatureRef = React.useRef();
    var temperatureDialogRef = React.useRef();
    var order = store.orderStore.order;
    var _onChange = function (e) {
        var _a;
        return store.orderStore.updateOrder((_a = {}, _a[e.target.name] = e.target.value, _a));
    };
    var onChange = function (key, value) { return __awaiter(void 0, void 0, void 0, function () {
        var updates;
        var _a, _b;
        return __generator(this, function (_c) {
            updates = {};
            if (typeof key === 'string') {
                Object.assign(updates, (_a = {},
                    _a[key] = value,
                    _a));
                ref.current.setFieldValue(key, value);
            }
            else {
                Object.assign(updates, (_b = {},
                    _b[key.target.name] = key.target.value,
                    _b));
                ref.current.setFieldValue(key.target.name, key.target.value);
            }
            store.orderStore.updateOrder(updates);
            return [2 /*return*/];
        });
    }); };
    var noEdit = !canEdit(order, store); //|| store.orderStore.isOrderUpdating;
    var noEditForm = noEdit && !location.href.toLowerCase().match(/restore/);
    var initialValue = JSON.parse(JSON.stringify(getSnapshot(order)));
    if (!initialValue.transport_other)
        initialValue.transport_other = '';
    return useObserver(function () { return (React.createElement("div", { className: classes.section, style: { paddingBottom: 0 }, id: 'step-content-transport-requirements' },
        React.createElement(Grid, { container: true },
            React.createElement(PaddingSpace, null),
            React.createElement(Grid, { container: true, xs: 12, md: 11 },
                React.createElement(TextOverflow, { variant: "h4", className: classes.sectionTitle, style: { marginBottom: theme.spacing(2) } }, langOrderForm.t('create_order_transport_label')))),
        React.createElement(Formik, { validationSchema: Yup.object(mode === OrderCreationMode.BASIC
                ? Object.assign({
                    vehicleType: Yup.array()
                        .typeError(langForm.t('form_input_validate_required'))
                        .required(langForm.t('form_input_validate_required'))
                        .min(1),
                    loadingType: Yup.array()
                        .typeError(langForm.t('form_input_validate_required'))
                        .required(langForm.t('form_input_validate_required'))
                        .min(1),
                    transport_other: Yup.string()
                        .max(1000, langForm.t('form_input_validate_max_char').replace(/_X_/g, '1000'))
                        .matches(onlySpacesRegExp, langForm.t('form_input_validate_invalid_text')),
                })
                : {}), innerRef: ref, key: order._id + (onlyMyTransports ? 1 : -1), initialValues: initialValue, onSubmit: function (e) { return e; } }, function (formik) {
            var inputItem = (React.createElement(ExpandFormItem, { v1: true, hideSpace: true, key: JSON.stringify(formik.values.vehicleType), title: langForm.t('form_input_other'), open: formik.values.transport_other ||
                    formik.values.vehicleType.find(function (e) { return (e.name || e).toString().match(/other/gi); }) },
                React.createElement(Grid, { container: true },
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(FormikTextField, { disabled: noEditForm, title: langForm.t('form_input_other'), multiline: true, variant: "outlined", rows: 4, name: "transport_other", onBlur: function () {
                                return _onChange({ target: { name: 'transport_other', value: formik.values.transport_other } });
                            }, value: formik.values.transport_other })))));
            return (React.createElement(Form, { id: 'transport-form', style: { width: '100%' } },
                React.createElement("div", { style: { margin: theme.spacing(2, 0) } },
                    React.createElement(Grid, { container: true, spacing: 2 },
                        React.createElement(PaddingSpace, null),
                        React.createElement(Grid, { item: true, xs: 12, md: 4 },
                            React.createElement(FormikAutocomplete, { v17: true, disabled: noEdit, onChageText: function () { return 0; }, fullWidth: true, variant: "outlined", name: "timeToSpend", autocompleteProps: {
                                    disabled: noEdit,
                                    onChange: function (e) { return onChange('timeToSpend', e.target.value && e.target.value.value); },
                                    getOptionLabel: function (option) { return option && option.label; },
                                    renderOption: function (option) { return option && option.label; },
                                    value: formik.values.timeToSpend &&
                                        times
                                            .map(function (e) {
                                            var _a;
                                            return (__assign(__assign({}, e), { label: ((_a = e.label) === null || _a === void 0 ? void 0 : _a.length) ? langForm.t("form_time_" + e.label)
                                                    : [e.value, langApp.t('app_time_minutes_short')].join(' ') }));
                                        })
                                            .find(function (e) { return e.value === formik.values.timeToSpend; }),
                                }, title: langOrderForm.t('create_order_timeToSpend'), options: times.map(function (e) {
                                    var _a;
                                    return (__assign(__assign({}, e), { label: ((_a = e.label) === null || _a === void 0 ? void 0 : _a.length) ? langForm.t("form_time_" + e.label)
                                            : [e.value, langApp.t('app_time_minutes_short')].join(' ') }));
                                }) })),
                        React.createElement(Grid, { xs: 12, item: true, md: 4 },
                            React.createElement(FormikAutocomplete, { v17: true, disabled: noEdit, onChageText: function () { return 0; }, autocompleteProps: {
                                    disabled: noEdit,
                                    multiple: true,
                                    onChange: function (e, v) {
                                        return onChange(e.target.name, v.map(function (e) { return e.value || e; }));
                                    },
                                    filterOptions: function (options, params) {
                                        var fields = props.filterFields || (options.length && Object.keys(options[0])) || [];
                                        var filterText = params.inputValue && params.inputValue.toString().toLowerCase();
                                        var res = filterText && fields.length
                                            ? options.filter(function (el) { return el.value.toLowerCase().match(filterText); })
                                            : options; //filterOptions(options, params);
                                        if (Array.isArray(formik.values.loadingType)) {
                                            var values_1 = formik.values.loadingType.map(function (e) { return e.value || e; });
                                            return res.filter(function (e) { return !values_1.includes(e.value); });
                                        }
                                        return res;
                                    },
                                    getOptionLabel: function (option) { return option && option.label; },
                                    renderOption: function (option) { return option && option.label; },
                                    freeSolo: true,
                                    renderTags: function (value, getTagProps) {
                                        var re = (Array.isArray(value) && value) || [];
                                        return re.map(function (option, index) { return (React.createElement(BaseChip, __assign({ label: getLoadingType(option) }, getTagProps({ index: index }), { className: classesChip.chipRoot }))); });
                                    },
                                    value: formik.values.loadingType,
                                }, title: langOrderForm.t('create_order_transport_loading_type'), variant: "outlined", name: "loadingType", value: ((Array.isArray(formik.values.loadingType) && formik.values.loadingType) || []).map(function (value) { return ({
                                    label: getLoadingType(value),
                                    value: value,
                                }); }), options: Object.keys(OrderLoadingType).map(function (key) { return ({
                                    label: getLoadingType(key),
                                    value: OrderLoadingType[key],
                                }); }) })))),
                React.createElement(PreloadingComponent, null,
                    React.createElement(FormikVehicleType, { disabled: noEdit, config: { client: client }, required: true, variant: "outlined", title: langOrderForm.t('create_order_transport_vehicle_type_label'), help: langForm.t('form_input_help_order_transport'), name: 'vehicleType', onChange: function (e) {
                            var _a;
                            if (!hasThermoTrailer(formik.values.vehicleType) && hasThermoTrailer(e.target.value)) {
                                (_a = temperatureDialogRef === null || temperatureDialogRef === void 0 ? void 0 : temperatureDialogRef.current) === null || _a === void 0 ? void 0 : _a.click();
                            }
                            return onChange(e.target.name, e.target.value);
                        }, value: formik.values.vehicleType, content: inputItem, defaultValue: formik.values.vehicleType })),
                React.createElement(PaddingSpaceSmall, null, inputItem)));
        }),
        React.createElement(BasicDialog, { body: React.createElement(OrderTemperatureForm, { handleChange: function (e) {
                    var _a = e.target, name = _a.name, value = _a.value;
                    if (!value || value === '')
                        return;
                    if (['temperatureFrom', 'temperatureTo'].includes(name)) {
                        value = parseInt(value);
                    }
                    onChange(name, value);
                }, ref: temperatureRef }), title: langOrderForm.t('order_form_temperature_setup'), onOk: function () { return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, temperatureRef.current.submitForm()];
                        case 1:
                            if (!(_a.sent())) {
                                throw Error(BASIC_DIALOG_ACTIONS.WAIT);
                            }
                            return [2 /*return*/];
                    }
                });
            }); }, dialogProps: { fullWidth: true, maxWidth: 'sm' }, actionInit: function (onOpen) { return React.createElement("span", { ref: temperatureDialogRef, onClick: onOpen }); } }))); });
})));
