var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useLocalStore } from 'mobx-react';
import { toast } from 'react-toastify';
import { OrderStatus } from '../../../../models';
import { KEYS } from '../../../../i18n';
import { RestoreOrder } from '../../../../graphql';
import { BasicDialog, MainButton, onPreventEvent, TextOverflow } from '../../../../components';
import browserHistory from '../../../../browserHistory';
import BaseTooltip from '../../../BaseTooltip';
export var OrderRestore = function (_a) {
    var client = _a.config.client, order = _a.order, onSubmit = _a.onSubmit;
    var langOrderForm = useTranslation(KEYS.ORDER_FORM);
    var langOrderList = useTranslation(KEYS.ORDER_LIST);
    var localStore = useLocalStore(function () { return ({
        error: '',
        isLoading: false,
        onSubmit: function () {
            return __awaiter(this, void 0, void 0, function () {
                var input, res, back, e_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localStore.error = '';
                            _a.label = 1;
                        case 1:
                            _a.trys.push([1, 3, 4, 5]);
                            input = {
                                order: order._id,
                            };
                            return [4 /*yield*/, client.mutate({
                                    mutation: RestoreOrder,
                                    variables: {
                                        input: input,
                                    },
                                })];
                        case 2:
                            res = _a.sent();
                            toast.success(langOrderList.t('order_list_restore_order_finish'));
                            back = document.getElementById('back_to_orders');
                            if (back) {
                                back.click();
                            }
                            else {
                                browserHistory.push('/');
                            }
                            return [2 /*return*/, res.data.restoreOrder];
                        case 3:
                            e_1 = _a.sent();
                            toast.error(e_1.message);
                            return [3 /*break*/, 5];
                        case 4:
                            localStore.isLoading = false;
                            return [7 /*endfinally*/];
                        case 5: return [2 /*return*/];
                    }
                });
            });
        },
        clearError: function () {
            localStore.error = '';
        },
    }); });
    if (!order) {
        return null;
    }
    var onOk = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    localStore.isLoading = true;
                    onPreventEvent(e);
                    return [4 /*yield*/, localStore.onSubmit()];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var onBeforeSubmit = function (e, onShowModal) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, onSubmit()];
                case 1:
                    if (!(_a.sent()))
                        return [2 /*return*/, console.log('Form invalid')];
                    onShowModal(e);
                    return [2 /*return*/];
            }
        });
    }); };
    var dialogBody = (React.createElement(Grid, { container: true, direction: "column" },
        React.createElement(TextOverflow, { style: { textAlign: 'center' }, v1: true, variant: 'subtitle1' }, order.status === OrderStatus.ApprovedQuote
            ? langOrderForm.t('restore_order_dates_modal_confirm')
            : langOrderForm.t('restore_order_modal_confirm'))));
    var title = order.status === OrderStatus.ApprovedQuote
        ? langOrderForm.t('create_order_btn_restore_confirm')
        : langOrderList.t('order_list_restore_order');
    var tooltipTitle = order.status === OrderStatus.ApprovedQuote
        ? langOrderList.t('order_list_restore_order_dates')
        : langOrderList.t('order_list_restore_order');
    return ((React.createElement(BasicDialog, { withoutWrapper: true, onOk: onOk, body: dialogBody, title: title, dialogProps: { fullWidth: true, maxWidth: 'sm' }, actionInit: function (handleClickOpen) { return (React.createElement(BaseTooltip, { title: tooltipTitle },
            React.createElement(MainButton, { style: { width: '100%' }, id: 'btn-restore-order', disabled: localStore.isLoading, onClick: function (e) { return onBeforeSubmit(e, handleClickOpen); }, title: title }))); } })) || null);
};
