export var SOCKET_EVENT_NAME;
(function (SOCKET_EVENT_NAME) {
    SOCKET_EVENT_NAME["CONNECT"] = "connection";
    SOCKET_EVENT_NAME["DISCONNECT"] = "disconnect";
})(SOCKET_EVENT_NAME || (SOCKET_EVENT_NAME = {}));
export var SOCKET_CUSTOM_VERSION;
(function (SOCKET_CUSTOM_VERSION) {
    SOCKET_CUSTOM_VERSION["FAST_PAYMENT"] = "fast_payment";
})(SOCKET_CUSTOM_VERSION || (SOCKET_CUSTOM_VERSION = {}));
export var SOCKET_CUSTOM_EVENT_NAME;
(function (SOCKET_CUSTOM_EVENT_NAME) {
    SOCKET_CUSTOM_EVENT_NAME["NOTIFICATION_REGISTRATION_REQUEST"] = "NOTIFICATION_REGISTRATION_REQUEST";
    SOCKET_CUSTOM_EVENT_NAME["NOTIFICATION_REGISTRATION_APPROVED"] = "NOTIFICATION_REGISTRATION_APPROVED";
    SOCKET_CUSTOM_EVENT_NAME["NOTIFICATION_ORDER_PUBLISHED"] = "NOTIFICATION_ORDER_PUBLISHED";
    SOCKET_CUSTOM_EVENT_NAME["NOTIFICATION_ORDER_CANCELED"] = "NOTIFICATION_ORDER_CANCELED";
    SOCKET_CUSTOM_EVENT_NAME["NOTIFICATION_ORDER_RESTORED"] = "NOTIFICATION_ORDER_RESTORED";
    SOCKET_CUSTOM_EVENT_NAME["NOTIFICATION_ORDER_UPDATED"] = "NOTIFICATION_ORDER_UPDATED";
    SOCKET_CUSTOM_EVENT_NAME["NOTIFICATION_ORDER_DELETED"] = "NOTIFICATION_ORDER_DELETED";
    SOCKET_CUSTOM_EVENT_NAME["NOTIFICATION_ITEM_DELETED"] = "NOTIFICATION_ITEM_DELETED";
    SOCKET_CUSTOM_EVENT_NAME["NOTIFICATION_ORDER_QUOTE_CREATED"] = "NOTIFICATION_ORDER_QUOTE_CREATED";
    SOCKET_CUSTOM_EVENT_NAME["NOTIFICATION_ORDER_QUOTE_DELETED"] = "NOTIFICATION_ORDER_QUOTE_DELETED";
    SOCKET_CUSTOM_EVENT_NAME["NOTIFICATION_ORDER_QUOTE_REJECTED"] = "NOTIFICATION_ORDER_QUOTE_REJECTED";
    SOCKET_CUSTOM_EVENT_NAME["NOTIFICATION_ORDER_QUOTE_ACCEPTED"] = "NOTIFICATION_ORDER_QUOTE_ACCEPTED";
    SOCKET_CUSTOM_EVENT_NAME["NOTIFICATION_ORDER_QUOTE_COUNTER_CREATED"] = "NOTIFICATION_ORDER_QUOTE_COUNTER_CREATED";
    SOCKET_CUSTOM_EVENT_NAME["NOTIFICATION_ORDER_QUOTE_REJECTED_CUSTOMER"] = "NOTIFICATION_ORDER_QUOTE_REJECTED_CUSTOMER";
    SOCKET_CUSTOM_EVENT_NAME["NOTIFICATION_ORDER_QUOTE_ACCEPTED_CUSTOMER"] = "NOTIFICATION_ORDER_QUOTE_ACCEPTED_CUSTOMER";
    SOCKET_CUSTOM_EVENT_NAME["NOTIFICATION_ORDER_QUOTE_APPROVED"] = "NOTIFICATION_ORDER_QUOTE_APPROVED";
    SOCKET_CUSTOM_EVENT_NAME["NOTIFICATION_CARRIER_ORDER_QUOTE_APPROVED"] = "NOTIFICATION_CARRIER_ORDER_QUOTE_APPROVED";
    SOCKET_CUSTOM_EVENT_NAME["NOTIFICATION_ORDER_QUOTE_UPDATED"] = "NOTIFICATION_ORDER_QUOTE_UPDATED";
    SOCKET_CUSTOM_EVENT_NAME["NOTIFICATION_ORDER_QUOTE_LOADING_TIME_BOOKED"] = "NOTIFICATION_ORDER_QUOTE_LOADING_TIME_BOOKED";
    SOCKET_CUSTOM_EVENT_NAME["NOTIFICATION_ORDER_QUOTE_UNLOADING_TIME_BOOKED"] = "NOTIFICATION_ORDER_QUOTE_UNLOADING_TIME_BOOKED";
    SOCKET_CUSTOM_EVENT_NAME["NOTIFICATION_ORDER_QUOTE_LOADED"] = "NOTIFICATION_ORDER_QUOTE_LOADED";
    SOCKET_CUSTOM_EVENT_NAME["NOTIFICATION_ORDER_QUOTE_UNLOADED"] = "NOTIFICATION_ORDER_QUOTE_UNLOADED";
    SOCKET_CUSTOM_EVENT_NAME["NOTIFICATION_ORDER_QUOTE_PAYMENT_RELEASED"] = "NOTIFICATION_ORDER_QUOTE_PAYMENT_RELEASED";
    SOCKET_CUSTOM_EVENT_NAME["NOTIFICATION_INVOICE_REQUESTED"] = "NOTIFICATION_INVOICE_REQUESTED";
    SOCKET_CUSTOM_EVENT_NAME["NOTIFICATION_INVOICE_SENT"] = "NOTIFICATION_INVOICE_SENT";
    SOCKET_CUSTOM_EVENT_NAME["NOTIFICATION_INVOICE_PAYED"] = "NOTIFICATION_INVOICE_PAYED";
    SOCKET_CUSTOM_EVENT_NAME["NOTIFICATION_LOCATION_UPDATED_WAREHOUSE"] = "NOTIFICATION_LOCATION_UPDATED_WAREHOUSE";
    SOCKET_CUSTOM_EVENT_NAME["ORDER_QUOTE_REQUEST_CHANGE"] = "ORDER_QUOTE_REQUEST_CHANGE";
    SOCKET_CUSTOM_EVENT_NAME["USER_JOINED_TO_ENV"] = "USER_JOINED_TO_ENV";
    SOCKET_CUSTOM_EVENT_NAME["USER_LEFT_ENV"] = "USER_LEFT_ENV";
    SOCKET_CUSTOM_EVENT_NAME["ORDER_DOCUMENT_REQUEST"] = "ORDER_DOCUMENT_REQUEST";
    SOCKET_CUSTOM_EVENT_NAME["DOCUMENT_REQUEST_SIGN"] = "DOCUMENT_SIGNED";
    SOCKET_CUSTOM_EVENT_NAME["DOCUMENT_REMOVED"] = "DOCUMENT_REMOVED";
    SOCKET_CUSTOM_EVENT_NAME["DOCUMENT_SIGNED"] = "DOCUMENT_SIGNED";
    SOCKET_CUSTOM_EVENT_NAME["DOCUMENT_UPDATED"] = "DOCUMENT_UPDATED";
    SOCKET_CUSTOM_EVENT_NAME["ORDER_PAID_RELEASE"] = "ORDER_PAID_RELEASE";
    SOCKET_CUSTOM_EVENT_NAME["ORDER_PREPARING_STATUS_UPDATED"] = "ORDER_PREPARING_STATUS_UPDATED";
    SOCKET_CUSTOM_EVENT_NAME["ORDER_PAID_ACCEPT"] = "ORDER_PAID_ACCEPT";
    SOCKET_CUSTOM_EVENT_NAME["ORDER_PAID_REJECT"] = "ORDER_PAID_REJECT";
    SOCKET_CUSTOM_EVENT_NAME["INVOICE_PAID_RELEASE"] = "INVOICE_PAID_RELEASE";
    SOCKET_CUSTOM_EVENT_NAME["INVOICE_PAID_ACCEPT"] = "INVOICE_PAID_ACCEPT";
    SOCKET_CUSTOM_EVENT_NAME["INVOICE_PAID_REJECT"] = "INVOICE_PAID_REJECT";
    SOCKET_CUSTOM_EVENT_NAME["DOCUMENT_CREATED"] = "DOCUMENT_CREATED";
    SOCKET_CUSTOM_EVENT_NAME["QUOTE_ASSIGN_TRANSPORT"] = "QUOTE_ASSIGN_TRANSPORT";
    SOCKET_CUSTOM_EVENT_NAME["QUOTE_LOADING_AT_BOOKED"] = "QUOTE_LOADING_AT_BOOKED";
    SOCKET_CUSTOM_EVENT_NAME["QUOTE_LOADING_AT_CHANGED"] = "QUOTE_LOADING_AT_CHANGED";
    SOCKET_CUSTOM_EVENT_NAME["QUOTE_UNLOADING_AT_BOOKED"] = "QUOTE_UNLOADING_AT_BOOKED";
    SOCKET_CUSTOM_EVENT_NAME["QUOTE_UNLOADING_AT_CHANGED"] = "QUOTE_UNLOADING_AT_CHANGED";
    SOCKET_CUSTOM_EVENT_NAME["QUOTE_NO_LOADING_AT"] = "QUOTE_NO_LOADING_AT";
    SOCKET_CUSTOM_EVENT_NAME["QUOTE_NO_UNLOADING_AT"] = "QUOTE_NO_UNLOADING_AT";
    SOCKET_CUSTOM_EVENT_NAME["QUOTE_LOADING_AT"] = "QUOTE_LOADING_AT";
    SOCKET_CUSTOM_EVENT_NAME["QUOTE_UNLOADING_AT"] = "QUOTE_UNLOADING_AT";
    SOCKET_CUSTOM_EVENT_NAME["COMPANY_REQUEST_TERM"] = "COMPANY_REQUEST_TERM";
    SOCKET_CUSTOM_EVENT_NAME["COMPANY_REQUEST_TERM_OWNER"] = "COMPANY_REQUEST_TERM_OWNER";
    SOCKET_CUSTOM_EVENT_NAME["COMPANY_REQUEST_TERM_APPROVED"] = "COMPANY_REQUEST_TERM_APPROVED";
    SOCKET_CUSTOM_EVENT_NAME["COMPANY_REQUEST_TERM_DECLINED"] = "COMPANY_REQUEST_TERM_DECLINED";
    SOCKET_CUSTOM_EVENT_NAME["ORDER_CARRIER_IN_PROGRESS"] = "ORDER_CARRIER_IN_PROGRESS";
    SOCKET_CUSTOM_EVENT_NAME["ORDER_CARRIER_IN_PROGRESS_UPDATED"] = "ORDER_CARRIER_IN_PROGRESS_UPDATED";
    SOCKET_CUSTOM_EVENT_NAME["SOCKET_NOTIFY_ISSUE_CREATED"] = "SOCKET_NOTIFY_ISSUE_CREATED";
    SOCKET_CUSTOM_EVENT_NAME["SOCKET_NOTIFY_ISSUE_UPDATED"] = "SOCKET_NOTIFY_ISSUE_UPDATED";
    SOCKET_CUSTOM_EVENT_NAME["SOCKET_NOTIFY_ISSUE_DELETED"] = "SOCKET_NOTIFY_ISSUE_DELETED";
    SOCKET_CUSTOM_EVENT_NAME["SOCKET_NOTIFY_FAST_PAYMENT_CREATED"] = "SOCKET_NOTIFY_FAST_PAYMENT_CREATED";
    SOCKET_CUSTOM_EVENT_NAME["REFRESH_USER"] = "REFRESH_USER";
})(SOCKET_CUSTOM_EVENT_NAME || (SOCKET_CUSTOM_EVENT_NAME = {}));
