var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import gql from 'graphql-tag';
import { BASE_QUERY, USER_WITH_COMPANY_BASE_I } from './base';
export var LOGS_BASE = "\n " + BASE_QUERY + "\n company{\n _id\n }\n        IP \n        changes\n        action\n        collectionName\n        review{\n            _id\n        }\n        object{\n          _id\n          orderId\n          status\n          name\n          email\n          createdBy{\n          " + USER_WITH_COMPANY_BASE_I + "\n          }\n          tempTransportations{\n             startDate\n              endDate\n          }\n        amount \n        forwarderAmount \n        currency \n          company{\n            _id\n          }\n        }";
export var LOGS_EMAIL_NOTIFICATION_BASE = "\n " + BASE_QUERY + "\n        status\n        stateStatus\n        event\n        uploads{\n            _id\n        }\n        response\n        requestData{\n          from{\n            email\n          }\n           to{\n              email\n            }\n            subject\n            dynamic_template_data{\n                firstName\n                lastName\n                body\n            }\n        }\n";
export var LOGS_PHONE_NOTIFICATION_BASE_SHORT = "\n " + BASE_QUERY + "\n        event\n        response\n        type\n";
export var LOGS_PHONE_NOTIFICATION_BASE = "\n " + BASE_QUERY + "\n        event\n        status\n        stateStatus\n        requestData\n        response\n        type\n";
export var FetchLogs = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query logs($filter: MainFilter) {\n    logs(filter: $filter) {\n      data {\n        ", "\n      }\n      count\n    }\n  }\n"], ["\n  query logs($filter: MainFilter) {\n    logs(filter: $filter) {\n      data {\n        ", "\n      }\n      count\n    }\n  }\n"])), LOGS_BASE);
export var FetchEmailNotificationsLogs = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  query logsEmailNotification($filter: MainFilter) {\n    logsEmailNotification(filter: $filter) {\n      data {\n        ", "\n        receiver{\n          ", "\n        }\n      }\n      count\n    }\n  }\n"], ["\n  query logsEmailNotification($filter: MainFilter) {\n    logsEmailNotification(filter: $filter) {\n      data {\n        ", "\n        receiver{\n          ", "\n        }\n      }\n      count\n    }\n  }\n"])), LOGS_EMAIL_NOTIFICATION_BASE, USER_WITH_COMPANY_BASE_I);
export var FetchPhoneNotificationsLogs = gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  query logsPhoneNotification($filter: MainFilter) {\n    logsPhoneNotification(filter: $filter) {\n      data {\n        ", "\n        receiver{\n          ", "\n        }\n      }\n      count\n    }\n  }\n"], ["\n  query logsPhoneNotification($filter: MainFilter) {\n    logsPhoneNotification(filter: $filter) {\n      data {\n        ", "\n        receiver{\n          ", "\n        }\n      }\n      count\n    }\n  }\n"])), LOGS_PHONE_NOTIFICATION_BASE, USER_WITH_COMPANY_BASE_I);
export var FetchLog = gql(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  query log($_id: MongoId) {\n    log(_id: $_id) {\n      ", "\n    }\n  }\n"], ["\n  query log($_id: MongoId) {\n    log(_id: $_id) {\n      ", "\n    }\n  }\n"])), LOGS_BASE);
export var CreateLogEmailNotification = gql(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  mutation createLogEmailNotification($input: LogEmailNotificationInput) {\n    createLogEmailNotification(input: $input) {\n      responses {\n        result\n      }\n    }\n  }\n"], ["\n  mutation createLogEmailNotification($input: LogEmailNotificationInput) {\n    createLogEmailNotification(input: $input) {\n      responses {\n        result\n      }\n    }\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
