var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React from 'react';
import Grid from '@material-ui/core/Grid';
import { getDaysArray, toDateOnly } from '../utils';
import moment from 'moment';
import { inject } from 'mobx-react';
import { RangeDatePicker } from './RangeDatePicker';
import { useField } from 'formik';
import { CircularIndeterminate } from './CircularIndeterminate';
export var LimitDateRangePicker = inject('store')(function (_a) {
    var defaultValue = _a.defaultValue, route = _a.route, warehouseStore = _a.store.warehouseStore, props = __rest(_a, ["defaultValue", "route", "store"]);
    var currentProps = Object.assign({}, defaultValue && defaultValue.minDate && { minDate: defaultValue.minDate }, props.maxDate && { maxDate: props.maxDate }, props.minDate && { minDate: props.minDate }, defaultValue && defaultValue.maxDate && { maxDate: defaultValue.maxDate }, defaultValue && typeof defaultValue === 'string' && { defaultValue: defaultValue }, props);
    var _b = __read(React.useState(false), 2), loading = _b[0], setLoading = _b[1];
    var _c = __read(useField(props), 3), field = _c[0], meta = _c[1], helpers = _c[2];
    var _onChange = function (value) {
        if (!value[0] || !value[1])
            return;
        var val = [value[0]];
        if (Math.abs(value[0].diff(value[1], 'days')) > 7) {
            val[1] = moment(value[0].format('YYYY/MM/DD')).add(5, 'd').format('YYYY/MM/DD');
            val[1] = moment(val[1]);
        }
        else {
            val[1] = value[1];
        }
        helpers.setValue(val);
        props.onChange && props.onChange(val);
    };
    var hundredYearsBeforeNow = new Date();
    hundredYearsBeforeNow.setFullYear(hundredYearsBeforeNow.getFullYear() - 100);
    if (!currentProps.minDate)
        currentProps.minDate = props.notDisablePast ? hundredYearsBeforeNow : new Date();
    var _d = __read(React.useState(currentProps), 2), dateProps = _d[0], setDateProps = _d[1];
    var onOpen = function (name) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            loadDates(dateProps.minDate, dateProps.maxDate || new Date(new Date().setMonth(new Date(currentProps.minDate).getMonth() + 2)));
            return [2 /*return*/];
        });
    }); };
    var loadDates = function (minDate, maxDate) { return __awaiter(void 0, void 0, void 0, function () {
        var filters_1, data, daysUnavailable, workingDays, unavailableDays_1, i, days, daysToExclude_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(route && route.origin.warehouse && route.origin.warehouse._id)) return [3 /*break*/, 4];
                    setLoading(true);
                    filters_1 = {
                        fields: [
                            {
                                name: 'warehouse',
                                value: (route.origin.warehouse.parent && [route.origin.warehouse._id, route.origin.warehouse.parent._id]) ||
                                    route.origin.warehouse._id,
                            },
                            {
                                name: 'start',
                                value: toDateOnly(minDate || new Date(new Date().setMonth(new Date(maxDate).getMonth() - 2))),
                            },
                            {
                                name: 'end',
                                value: toDateOnly(maxDate || new Date(new Date().setMonth(new Date(minDate).getMonth() + 2))),
                            },
                        ],
                    };
                    return [4 /*yield*/, warehouseStore.loadHolidays(filters_1)];
                case 1:
                    data = (_a.sent()) || [];
                    filters_1.fields = filters_1.fields.map(function (e) {
                        return e.name === filters_1.fields[0].name ? __assign(__assign({}, filters_1.fields[0]), { value: route.origin.warehouse._id }) : e;
                    });
                    return [4 /*yield*/, warehouseStore.loadNoneAvailableForBooking(filters_1)];
                case 2:
                    daysUnavailable = (_a.sent()) || [];
                    return [4 /*yield*/, warehouseStore.loadWarehouseWorkingDays(filters_1)];
                case 3:
                    workingDays = (_a.sent()) || [];
                    unavailableDays_1 = [];
                    for (i = 0; i < data.length; i++) {
                        days = getDaysArray(new Date(data[i].start), new Date(data[i].end)).map(function (e) { return toDateOnly(e); });
                        unavailableDays_1 = __spread(new Set(__spread(days, unavailableDays_1)));
                    }
                    daysToExclude_1 = workingDays.filter(function (e) { return !(e.minTime || e.maxTime); }).map(function (e) { return e.day; });
                    unavailableDays_1 = __spread(new Set(__spread(getDaysArray(new Date(minDate), new Date(maxDate))
                        .filter(function (day) { return daysToExclude_1.includes(moment(day).format('dddd')); })
                        .map(function (e) { return toDateOnly(e); }), unavailableDays_1, daysUnavailable)));
                    setDateProps(__assign(__assign({}, dateProps), { shouldDisableDate: function (date) { return unavailableDays_1.includes(toDateOnly(date)); }, onMonthChange: function (date) {
                            loadDates(date, new Date(new Date().setMonth(new Date(date).getMonth() + 2)));
                        } }));
                    setLoading(false);
                    _a.label = 4;
                case 4: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(RangeDatePicker, __assign({}, dateProps, field, { maxDate: currentProps.maxDate, id: currentProps.id, onChange: _onChange, onOpen: onOpen, loading: loading, renderLoading: function () { return (React.createElement(Grid, { container: true, justify: 'center' },
            React.createElement(CircularIndeterminate, null))); } })));
});
