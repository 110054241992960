var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import gql from 'graphql-tag';
import { BASE_QUERY, LOCATION_ADDRESS } from '../base';
import { ORDER_ROUTE_BASE } from '../order';
import { ORDER_BASE } from '../order';
export var CALENDAR_BASE_ORDER_TRANSPORTATION = "\n          startDate \n          endDate \n          endTime \n          startTime \n";
export var CALENDAR_ORDER_TRANSPORTATION = "\n    " + BASE_QUERY + "\n       order{\n        " + ORDER_BASE + "\n        originCompany{\n            _id\n        }\n        noQuotes\n        cancelComment\n        sorting_countryPickup\n        sorting_countryDelivery\n        sorting_totalWeight\n        timeToSpends{\n            name\n            value\n        }\n       }\n       arrivedToPickup \n        arrivedToDelivery \n        p44_arrivedToPickup \n        p44_arrivedToDelivery \n        inTransitAt \n        unloadedAt \n       \n         isBusy\n       routes{\n       " + ORDER_ROUTE_BASE + "\n       }\n         " + CALENDAR_BASE_ORDER_TRANSPORTATION + "\n       \n";
export var CALENDAR_ORDER_TRANSPORTATION_MORE = "\n                date\n                count\n               items{\n                   " + CALENDAR_ORDER_TRANSPORTATION + "\n               }";
export var FetchCalendarTransportations = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    query calendarTransportations($filter: MainFilter)  {\n        calendarTransportations(filter:$filter) {\n            data {\n                ", "\n            }\n            count\n        }\n    }\n"], ["\n    query calendarTransportations($filter: MainFilter)  {\n        calendarTransportations(filter:$filter) {\n            data {\n                ", "\n            }\n            count\n        }\n    }\n"])), CALENDAR_ORDER_TRANSPORTATION_MORE);
export var CheckIsAvailableForBook = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  mutation isAvailableForBook($filter: MainFilter) {\n    isAvailableForBook(filter: $filter) {\n      status\n    }\n  }\n"], ["\n  mutation isAvailableForBook($filter: MainFilter) {\n    isAvailableForBook(filter: $filter) {\n      status\n    }\n  }\n"])));
export var FetchTimeToBook = gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  query loadAvailableTimeToBook($filter: MainFilter) {\n    loadAvailableTimeToBook(filter: $filter) {\n      times\n    }\n  }\n"], ["\n  query loadAvailableTimeToBook($filter: MainFilter) {\n    loadAvailableTimeToBook(filter: $filter) {\n      times\n    }\n  }\n"])));
export var FetchNoneAvailableDays = gql(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  query getNoneAvailableForBooking($filter: MainFilter) {\n    getNoneAvailableForBooking(filter: $filter) {\n      data\n    }\n  }\n"], ["\n  query getNoneAvailableForBooking($filter: MainFilter) {\n    getNoneAvailableForBooking(filter: $filter) {\n      data\n    }\n  }\n"])));
export var FetchLoadOnDays = gql(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  query loadOnDays($filter: MainFilter) {\n    loadOnDays(filter: $filter) {\n        _id\n          name\n             data{\n                    date\n                    count\n                    times\n                  }\n          address{\n                        ", "\n                    }\n           \n    }\n  }\n"], ["\n  query loadOnDays($filter: MainFilter) {\n    loadOnDays(filter: $filter) {\n        _id\n          name\n             data{\n                    date\n                    count\n                    times\n                  }\n          address{\n                        ", "\n                    }\n           \n    }\n  }\n"])), LOCATION_ADDRESS);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
