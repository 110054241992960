var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { Grid, IconButton, useMediaQuery } from '@material-ui/core';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import EventIcon from '@material-ui/icons/Event';
import { inject } from 'mobx-react';
import { KEYS } from '../../i18n';
import { useIconStyles } from '../../styles';
import { DeleteWarehouse } from '../../graphql';
import { ACTION_CHANGE } from '../../components/BasicList';
import { DeleteDialog, TextOverflow, getDefaultCalendarMode } from '../../components';
import { OrderLocation } from '../../models';
import { theme } from '../../theme';
import { WarehouseCreateEditForm } from './Form';
import { FlagComponent } from '../../components/Flag';
import DeactivateItem from './DeactivateItem';
import { VirtualWarehouseIcon } from '../../components/icons';
import BaseTooltip from '../../components/BaseTooltip';
import MenuListItem from '../../components/MenuListItem';
import PanoramaWideAngleIcon from '@material-ui/icons/PanoramaWideAngle';
import OrderRouteMenuItem from '../../components/Order/List/OrderRoutesMenuItem';
import { toDateOnlyV1 } from '../../utils';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { USER_ROLE_TOOLS } from '../../models/UserRoles';
import { Link } from 'react-router-dom';
import { BaseListItemV1 } from '../../components/BasicList/ListItem/BaseListItemV1';
import { browserStorage } from '../../browserStorage';
var OrderRoutesCreatedBy = React.lazy(function () { return import('../../components/Order/List/OrderRoutesCreatedBy'); });
export var WarehouseListItem = inject('store')(function (_a) {
    var _this = this;
    var _b, _c, _d;
    var record = _a.record, onChange = _a.onChange, config = _a.config, isTab = _a.isTab, headerColumns = _a.headerColumns, _e = _a.store.accountStore, _f = _e.currentUser, _id = _f._id, rolesV1 = _f.rolesV1, currentCompany = _e.currentCompany;
    var client = config.client;
    var langApp = useTranslation(KEYS.APP);
    var langOrderForm = useTranslation(KEYS.ORDER_FORM);
    var classesIcon = useIconStyles();
    var isMiddle = useMediaQuery(theme.breakpoints.down('sm'));
    var onDelete = function () { return __awaiter(_this, void 0, void 0, function () {
        var e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, client.mutate({
                            mutation: DeleteWarehouse,
                            variables: {
                                _id: record._id,
                            },
                        })];
                case 1:
                    _a.sent();
                    onChange(record, ACTION_CHANGE.DELETED);
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    toast.error(e_1.message || e_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var isPublic = !!location.pathname.match(/^\/public\//gi);
    var localStoreKey = "calendar-v2-" + record._id;
    var search = browserStorage.getUserItem(localStoreKey);
    var defaultCalendarMode = getDefaultCalendarMode(isPublic);
    var canEditV1 = rolesV1.find(function (e) { var _a; return (_a = e.tools.general) === null || _a === void 0 ? void 0 : _a.includes(USER_ROLE_TOOLS.GENERAL_can_edit_company_data); });
    var isOwner = _id === ((_b = record.createdBy) === null || _b === void 0 ? void 0 : _b._id) || _id === ((_c = record.manager) === null || _c === void 0 ? void 0 : _c._id);
    var isOwnerCompany = ((_d = record.createdBy) === null || _d === void 0 ? void 0 : _d.company._id) === currentCompany._id;
    var childs = [
        {
            value: (React.createElement(Grid, { item: true, xs: 12, container: true, alignItems: 'center' },
                React.createElement(Grid, { container: true, direction: 'column', style: { maxWidth: 300 } },
                    React.createElement(TextOverflow, { v6: true, variant: 'subtitle1' }, record.name),
                    record.description ? React.createElement(TextOverflow, { variant: 'caption' }, record.description) : null))),
        },
        {
            value: (React.createElement(Grid, { item: true, xs: 12, container: true, alignItems: 'center' },
                record.address.country && (React.createElement(FlagComponent, { country: record.address.country.toLowerCase(), style: { marginRight: theme.spacing(1) } })),
                React.createElement(TextOverflow, { variant: 'subtitle1', style: { maxWidth: 'calc(100% - 41px)' } }, OrderLocation.create({ address: record.address }).fullAddress))),
        },
        {
            value: (React.createElement(Grid, { item: true, xs: 12, container: true, alignItems: 'center' },
                React.createElement(TextOverflow, { variant: 'subtitle1' }, record.emails.join(', ')))),
        },
        {
            value: (React.createElement(Grid, { item: true, xs: 12, container: true, alignItems: 'center' },
                React.createElement(TextOverflow, { variant: 'subtitle1' }, (record.manager && (record.manager.fullName.trim() || record.manager.email)) || ''))),
        },
        !isTab
            ? {
                value: (React.createElement(Grid, { item: true, xs: 12, style: { paddingLeft: theme.spacing(1) }, container: true, alignItems: 'center', justify: isMiddle ? 'flex-start' : 'flex-end' },
                    (record.generated && (React.createElement(BaseTooltip, { title: 'Generated' },
                        React.createElement("span", { style: { display: 'flex' } },
                            React.createElement(VirtualWarehouseIcon, { style: { color: theme.palette.error.light, transform: 'scale(1.2)' } }))))) ||
                        null,
                    React.createElement(MenuListItem, { show: true, list: [
                            {
                                content: (React.createElement(Link, { to: "/warehouses/" + record._id + "/calendar", style: { textDecoration: 'none', width: '100%' } },
                                    React.createElement(OrderRouteMenuItem, __assign({}, {
                                        icon: (React.createElement(BaseTooltip, { title: langApp.t('working_plan_calendar') },
                                            React.createElement(IconButton, { style: { padding: 2 }, className: classesIcon.icon },
                                                React.createElement(EventIcon, { color: 'primary' })))),
                                        label: [langApp.t('working_plan_calendar'), langApp.t('calendar_label_month')].join('. '),
                                    })))),
                                available: true,
                            },
                            {
                                content: (React.createElement(Link, { to: "/warehouses/" + record._id + "/calendar?mode=week&date=" + toDateOnlyV1(new Date()), style: { textDecoration: 'none', width: '100%' } },
                                    React.createElement(OrderRouteMenuItem, __assign({}, {
                                        icon: (React.createElement(BaseTooltip, { title: langApp.t('working_plan_calendar') },
                                            React.createElement(IconButton, { style: { padding: 2 }, className: classesIcon.icon },
                                                React.createElement(EventIcon, { color: 'primary' })))),
                                        label: [langApp.t('working_plan_calendar'), langApp.t('calendar_label_week')].join('. '),
                                    })))),
                                available: true,
                            },
                            {
                                content: (React.createElement(Link, { to: "/warehouses/" + record._id + "/ramps", style: { textDecoration: 'none', width: '100%' } },
                                    React.createElement(OrderRouteMenuItem, __assign({}, {
                                        icon: (React.createElement(BaseTooltip, { title: langApp.t('warehouse_ramps') },
                                            React.createElement(IconButton, { style: { padding: 2 }, className: classesIcon.icon },
                                                React.createElement(PanoramaWideAngleIcon, { color: 'primary' })))),
                                        label: langApp.t('warehouse_ramps'),
                                    })))),
                                available: isOwner || (isOwnerCompany && canEditV1),
                            },
                            {
                                content: (React.createElement(Link, { to: "/warehouses/" + record._id + "/locations/my", style: { textDecoration: 'none', width: '100%' } },
                                    React.createElement(OrderRouteMenuItem, __assign({}, {
                                        icon: (React.createElement(BaseTooltip, { title: langOrderForm.t('create_order_route_location_my_locations') },
                                            React.createElement(IconButton, { style: { padding: 2 }, className: classesIcon.icon },
                                                React.createElement(LocationOnIcon, { color: 'primary' })))),
                                        label: langOrderForm.t('create_order_route_location_my_locations'),
                                    })))),
                                available: isOwner || (isOwnerCompany && canEditV1),
                            },
                            {
                                content: React.createElement(WarehouseCreateEditForm, { item: record, config: config, label: true, onChange: onChange }),
                                available: isOwner || (isOwnerCompany && canEditV1),
                            },
                            {
                                content: (React.createElement(DeleteDialog, { onOk: onDelete, v1: true, withoutWrapper: true, withText: true, value: record.name, disabled: !(isOwner || (isOwnerCompany && canEditV1)) })),
                                available: isOwner || (isOwnerCompany && canEditV1),
                            },
                            {
                                content: React.createElement(DeactivateItem, { item: record, onChange: onChange, label: true, config: config }),
                                available: isOwner || (isOwnerCompany && canEditV1),
                            },
                        ] }))),
            }
            : null,
    ];
    return (React.createElement(Link, { to: location.pathname + "/" + record._id + "/calendar?" + (search || "ramps=" + record.ramps.map(function (e) { return e._id; }) + "&mode=" + defaultCalendarMode), style: { width: '100%', textDecoration: 'none' } },
        React.createElement(Grid, { container: true, direction: "row", spacing: 2, style: { padding: theme.spacing(1) } },
            React.createElement(BaseListItemV1, { headerColumns: headerColumns, childs: childs, v1: true, headerMobileSize: 4 }))));
});
