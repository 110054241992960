var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Typography, Grid, Box } from '@material-ui/core';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { i18n, KEYS } from '../../../../../../i18n';
import { BasicDialog, onPreventEvent } from '../../../../../Dialog';
import { CompanyPaymentTermModel, OrderQuoteStatus } from '../../../../../../models';
import { AcceptOrderQuote } from '../../../../../../graphql/order';
import { ACTION_CHANGE } from '../../../../../BasicList';
import { useHistory } from 'react-router-dom';
import { theme } from '../../../../../../theme';
import OrderRouteMenuItem from '../../../OrderRoutesMenuItem';
import BaseTooltip from '../../../../../BaseTooltip';
import { inject } from 'mobx-react';
import { BaseUserAppTYpe, TERM_STATUS } from '../../../../../../stores';
import { googleTagManager } from '../../../../../../utils1';
import { MainButton } from '../../../../../MainButton';
export var AcceptDialog = inject('store')(function (_a) {
    var _b, _c, _d, _e;
    var config = _a.config, className = _a.className, currentCompany = _a.store.accountStore.currentCompany, props = __rest(_a, ["config", "className", "store"]);
    var langOfferForm = useTranslation(KEYS.OFFER_FORM);
    var langToast = useTranslation(KEYS.TOAST);
    var langMenu = useTranslation(KEYS.MENU);
    var langForm = useTranslation(KEYS.FORM);
    var langApp = useTranslation(KEYS.APP);
    var history = useHistory();
    var inputDialog = React.useRef();
    var balance = ((_b = currentCompany.paymentTerm) === null || _b === void 0 ? void 0 : _b.balance) ||
        0 - (((_c = props.record.order.forwarders) === null || _c === void 0 ? void 0 : _c.length) ? props.record.forwarderAmount : props.record.amount);
    var reached = currentCompany.type === BaseUserAppTYpe.CUSTOMER &&
        ((_d = currentCompany.paymentTerm) === null || _d === void 0 ? void 0 : _d.status) === TERM_STATUS.APPROVED && ((_e = currentCompany.paymentTerm) === null || _e === void 0 ? void 0 : _e._id) &&
        balance <= 0;
    var onOk = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var client, input, res, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    onPreventEvent(e);
                    client = config && config.client;
                    input = {
                        _id: props.record._id,
                        status: OrderQuoteStatus.Accepted,
                    };
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, client.mutate({
                            mutation: AcceptOrderQuote,
                            variables: {
                                input: input,
                            },
                        })];
                case 2:
                    res = _a.sent();
                    googleTagManager('event', 'izi_form_offer_accept', __assign(__assign({ event_name: "offer_accept" }, [
                        'sorting_countryPickup',
                        'sorting_pickupAt',
                        'sorting_countryDelivery',
                        'sorting_deliveryAt',
                        'sorting_totalWeight',
                        'orderId',
                    ]
                        .filter(function (e) { return props.order[e]; })
                        .map(function (e) {
                        var _a;
                        return (_a = {}, _a["order_" + e] = props.order[e], _a);
                    })
                        .reduce(function (a, b) { return (__assign(__assign({}, a), b)); }, {})), { offer_startDate: props.record.tempTransportations[0].startDate, offer_endDate: props.record.tempTransportations[props.record.tempTransportations.length - 1].endDate, offer_amount: props.record.amount, offer_currency: props.record.currency }));
                    toast.success(i18n
                        .getResource(i18n.language, KEYS.TOAST, 'toast_items_accepted')
                        .replace(/_X_/, i18n.getResource(i18n.language, KEYS.OFFER_FORM, 'offer_title')));
                    if (res.data.acceptOrderQuote.status === OrderQuoteStatus.Approved) {
                        if (inputDialog && inputDialog.current)
                            return [2 /*return*/, inputDialog.current.click()];
                    }
                    if (reached) {
                        currentCompany.update({
                            updatedAt: Date.now().toString(),
                            paymentTerm: CompanyPaymentTermModel.create({ term: 30 }),
                        });
                    }
                    if (props.onChange)
                        props.onChange(input, ACTION_CHANGE.DELETED); //should remove order from requested quotes list
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _a.sent();
                    toast.error(e_1.message);
                    googleTagManager('event', 'izi-error', { value: e_1.message || e_1, event_name: 'error_offer_accept' });
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var onVisitOffer = function () {
        history.push("/quote-details/" + props.record._id);
    };
    var onClose = function () { return props.onChange && props.onChange(props.record, ACTION_CHANGE.DELETED); };
    return (React.createElement(React.Fragment, null,
        React.createElement(BasicDialog, { onClose: onClose, onOk: onVisitOffer, withoutWrapper: true, title: langOfferForm.t('offer_to_approve'), actionOkTitle: langForm.t('form_btn_view_offer'), isMiddleBody: true, actionCancelTitle: langApp.t('modal_actions_close'), body: React.createElement(Grid, { container: true, alignItems: 'center' },
                React.createElement(Grid, { container: true, justify: 'center', alignItems: 'center' },
                    React.createElement(Typography, { variant: 'subtitle1', style: { textAlign: 'center' }, color: 'primary', dangerouslySetInnerHTML: {
                            __html: langOfferForm.t('offer_carrier_approved').replace('_X_', props.record._id),
                        } }))), actionInit: function (onOpen) { return React.createElement("input", { ref: inputDialog, onClick: onOpen, type: 'hidden' }); } }),
        React.createElement(BasicDialog, __assign({}, props, { withoutWrapper: true, onOk: onOk }, (reached && { actionOkTitle: langForm.t('form_btn_continue') }), { title: [langOfferForm.t('offer_to_accept'), langOfferForm.t('offer_title').toLowerCase()].join(' '), actionInit: function (handleClickOpen) {
                var title = (!props.record.needApprove && langOfferForm.t('offer_start_immediately')) ||
                    langOfferForm.t('offer_to_accept_help');
                var icon = (React.createElement(BaseTooltip, { title: title },
                    React.createElement(Box, null,
                        React.createElement(MainButton, { disabled: props.disabled, onClick: handleClickOpen, title: langOfferForm.t('offer_to_accept'), disabledStyle: { pointerEvents: 'none' }, style: __assign({ height: 25, backgroundColor: theme.palette.success.light }, ((!props.disabled && { padding: theme.spacing(0, 1), color: theme.palette.success.main }) || {
                                padding: theme.spacing(0),
                            })) }))));
                return props.label ? React.createElement(OrderRouteMenuItem, __assign({}, { icon: icon, label: title, handleClickOpen: handleClickOpen })) : icon;
            }, isMiddleBody: true, body: React.createElement(Grid, { container: true },
                React.createElement(Grid, { container: true, justify: 'center', direction: 'column', alignItems: 'center' },
                    React.createElement(Typography, { variant: 'subtitle1', color: 'primary', style: { textAlign: 'center' }, dangerouslySetInnerHTML: {
                            __html: (!props.record.needApprove && langOfferForm.t('offer_start_immediately')) ||
                                langToast
                                    .t('toast_items_available')
                                    .replace(/_X_/, langMenu.t('menu_sidebar_offer_submitted').toLowerCase()),
                        } }),
                    (reached && (React.createElement(Typography, { variant: 'subtitle1', style: { color: theme.palette.primary.light, textAlign: 'center' }, dangerouslySetInnerHTML: {
                            __html: langOfferForm.t('offer_start_invoiceTermPaymentBudget'),
                        } }))) ||
                        null)) }))));
});
