var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { BasicDialog, BASIC_DIALOG_ACTIONS, onPreventEvent } from '../../Dialog';
import { TextOverflow } from '../../TextOverflow';
import { KEYS } from '../../../i18n';
import { MainButton } from '../../MainButton';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { FormikTextField } from '../../Formik';
import { SoftInvite } from '../../../graphql';
import { toast } from 'react-toastify';
export default observer(function (_a) {
    var client = _a.client, props = __rest(_a, ["client"]);
    var formRef = React.useRef(null);
    var langApp = useTranslation(KEYS.APP);
    var langForm = useTranslation(KEYS.FORM);
    var langToast = useTranslation(KEYS.TOAST);
    /*eslint-disable */
    var onOk = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var item, res, e_1;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    onPreventEvent(e);
                    return [4 /*yield*/, formRef.current.submitForm()];
                case 1:
                    item = _c.sent();
                    if (!item)
                        throw BASIC_DIALOG_ACTIONS.WAIT;
                    _c.label = 2;
                case 2:
                    _c.trys.push([2, 4, , 5]);
                    return [4 /*yield*/, client.mutate({
                            mutation: SoftInvite,
                            variables: {
                                input: {
                                    email: item.email,
                                },
                            },
                        })];
                case 3:
                    res = (_b = (_a = (_c.sent())) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.softInvite;
                    if (!res)
                        throw Error('No Valid');
                    props.onChange();
                    if (res.isNew) {
                        toast.success(langToast.t('toast_items_invitation_sent'));
                    }
                    return [3 /*break*/, 5];
                case 4:
                    e_1 = _c.sent();
                    toast.error(e_1.message);
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var onSubmit = function (values, actions) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2 /*return*/, values];
    }); }); };
    var validationSchema = Yup.object().shape({
        email: Yup.string()
            .max(50, langForm.t('form_input_validate_max_char').replace(/_X_/g, '50'))
            .email(langForm.t('form_input_validate_invalid_email'))
            .required(langForm.t('form_input_validate_required')),
    });
    var dialogBody = (React.createElement(Formik, { validationSchema: validationSchema, onSubmit: onSubmit, initialValues: { email: '' }, innerRef: formRef }, function (_a) {
        var values = _a.values;
        return (React.createElement(Form, null,
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(FormikTextField, { value: values.email, variant: "outlined", name: "email", title: langForm.t('form_input_name_email') }))));
    }));
    var title = langApp.t('team_new_add_new_carrier');
    return (React.createElement(BasicDialog, { withoutWrapper: true, dialogProps: { maxWidth: 'xl' }, onOk: onOk, actionOkTitle: title, body: dialogBody, title: React.createElement(Grid, { container: true, justify: 'center', alignItems: 'center', direction: 'column' },
            React.createElement(TextOverflow, null, title)), actionInit: function (handleClickOpen) {
            return (React.createElement(MainButton, { style: { width: '100%' }, onClick: handleClickOpen, color: 'primary', title: langApp.t('team_new_add_new_carrier') }));
        } }));
});
