var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { createContext, useReducer } from 'react';
import socketIOClient from 'socket.io-client';
import { browserStorage } from '../../../browserStorage';
import { i18n, KEYS } from '../../../i18n';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles, Typography } from '@material-ui/core';
var initialState = {
    client: null,
    isOffline: false,
    isClose: false,
};
var BaseGlobalSocketStore = createContext(JSON.parse(JSON.stringify(initialState)));
var Provider = BaseGlobalSocketStore.Provider, Consumer = BaseGlobalSocketStore.Consumer;
export var ACTIONS;
(function (ACTIONS) {
    ACTIONS["CONNECT"] = "CONNECT";
    ACTIONS["UPDATE"] = "UPDATE";
    ACTIONS["DISCONNECT"] = "DISCONNECT";
})(ACTIONS || (ACTIONS = {}));
var useStyles = makeStyles(function (theme) { return ({
    root: {
        '& .MuiSnackbarContent-root': {
            background: theme.palette.common.white,
        },
        '& .MuiSnackbarContent-message': {
            maxWidth: "calc(100% - 60px)",
        },
    },
}); });
export var BaseSocketProvider = function (_a) {
    var children = _a.children, config = _a.config;
    var initState = __assign({}, JSON.parse(JSON.stringify(initialState)));
    var _b = __read(useReducer(function (state, action) {
        switch (action.type) {
            case ACTIONS.UPDATE:
            case ACTIONS.CONNECT: {
                return __assign(__assign({}, state), action.payload);
            }
            case ACTIONS.DISCONNECT: {
                state.client && state.client.disconnect();
                return __assign(__assign({}, state), { client: null });
            }
            default:
                throw new Error("No " + action.type + " found");
        }
    }, initState), 2), state = _b[0], dispatch = _b[1];
    var connect = function () {
        try {
            var options = {
                // rememberUpgrade: true,
                // transports: ["websocket"],
                secure: !!location.origin.match('https'),
                reconnectionAttempts: 'Infinity',
                timeout: 100001,
                withCredentials: false,
                rejectUnauthorized: false,
                query: {
                    izi_client_id: browserStorage.getItem('client_id'),
                    token: browserStorage.getItem('token'),
                },
            };
            var client_1 = config && socketIOClient((config.URL || '').replace('/graphql', ''), options);
            var onError_1 = function () {
                if (browserStorage.getItem('token')) {
                    dispatch({
                        type: ACTIONS.UPDATE,
                        payload: __assign(__assign({}, state), { isOffline: true }),
                    });
                }
            };
            client_1.on('disconnect', function (d) {
                // console.log('socket disconnect', d);
                onError_1();
            });
            client_1.on('error', function (d) {
                console.error('socket error', d);
                onError_1();
            });
            client_1.on('connect', function () {
                // console.log('client connected');
                dispatch({
                    type: ACTIONS.CONNECT,
                    payload: __assign(__assign({}, state), { isOffline: false, client: client_1 }),
                });
                // client.on("disconnect", () => {
                //   disconnect();
                // });
            });
        }
        catch (e) {
            console.log(e);
            // disconnect();
        }
    };
    var disconnect = function () {
        // console.log('client disconnect');
        dispatch({
            type: ACTIONS.DISCONNECT,
        });
    };
    var configData = function () { return config; };
    var handleClose = function () { return dispatch({ type: ACTIONS.UPDATE, payload: __assign(__assign({}, state), { isClose: true }) }); };
    var value = {
        globalSocketState: state,
        connect: connect,
        disconnect: disconnect,
        configData: configData,
    };
    var classes = useStyles();
    return (React.createElement(Provider, { value: value },
        React.createElement(Snackbar, { className: classes.root, anchorOrigin: { vertical: 'bottom', horizontal: 'center' }, open: !!state.isOffline && !state.isClose, action: React.createElement(React.Fragment, null,
                React.createElement(IconButton, { "aria-label": "close", color: "inherit", onClick: handleClose },
                    React.createElement(CloseIcon, { color: 'primary' }))), message: React.createElement(Typography, { color: 'primary', dangerouslySetInnerHTML: {
                    __html: i18n.getResource(i18n.language, KEYS.APP, 'lost_connection') || '',
                } }) }),
        children));
};
export var withBaseGlobalSocketStore = function (Component) {
    return function (props) { return (React.createElement(Consumer, null, function (storeProps) {
        return React.createElement(Component, __assign({}, __assign(__assign({}, props), storeProps)));
    })); };
};
export var withBaseGlobalSocketStoreActiveClient = function (Component) {
    return function (props) { return (React.createElement(Consumer, null, function (storeProps) {
        var _a;
        var key = ((_a = storeProps.globalSocketState) === null || _a === void 0 ? void 0 : _a.client) ? 1 : -1;
        return React.createElement(Component, __assign({ key: key }, __assign(__assign({}, props), storeProps)));
    })); };
};
