var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Grid, useMediaQuery } from '@material-ui/core';
import { theme } from '../../../theme';
import { priceValue, TextOverflow } from '../../../components';
import ServiceTotals from '../../Invoices/Form/ServicesTotal';
import React from 'react';
import SectionDetails from '../../../components/SectionDetails';
import { InvoiceServices } from '../../../graphql/invoices/invoiceService';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../../i18n';
import { useSectionRowStyles } from '../../../components/SectionDetails/SectionRow';
import { BaseListItemV1 } from '../../../components/BasicList/ListItem/BaseListItemV1';
export default (function (_a) {
    var config = _a.config, record = _a.record;
    var langForm = useTranslation(KEYS.FORM);
    var className = useSectionRowStyles();
    var dataView = function (data) {
        var isSmall = useMediaQuery(theme.breakpoints.down('sm'));
        var otherJustifyContent = isSmall ? 'flex-start' : 'flex-end';
        var headerColumns = [
            {
                size: 3,
                name: langForm.t('form_input_name'),
            },
            {
                size: 2,
                name: langForm.t('form_invoice_quantity'),
                style: { display: 'flex', justifyContent: otherJustifyContent },
            },
            {
                size: 2,
                name: langForm.t('form_invoice_without_vat'),
                style: { display: 'flex', justifyContent: otherJustifyContent },
            },
            {
                size: 2,
                name: langForm.t('form_invoice_total_without_vat'),
                style: { display: 'flex', justifyContent: otherJustifyContent },
            },
            {
                size: 1,
                name: langForm.t('form_invoice_vat_amount'),
                style: { display: 'flex', justifyContent: otherJustifyContent },
            },
            {
                size: 2,
                name: langForm.t('form_invoice_total'),
                style: { display: 'flex', justifyContent: otherJustifyContent },
            },
        ];
        var childsFunc = function (e) {
            var withoutVat = e.price && e.quantity && e.quantity * e.price;
            var discount = e.discountUnit === '%' ? withoutVat * (e.discount / 100) : e.discount;
            return [
                {
                    value: (React.createElement(Grid, { item: true, xs: 12, container: true, direction: 'column', justify: 'flex-start' },
                        React.createElement(Grid, { container: true },
                            React.createElement(TextOverflow, null, e.name)),
                        e.description && React.createElement(TextOverflow, { variant: 'caption' }, e.description))),
                },
                {
                    value: (React.createElement(Grid, { item: true, xs: 12, container: true, justify: otherJustifyContent }, [e.quantity, e.unit && e.unit.name].filter(function (e) { return e; }).join('') || '-')),
                },
                {
                    value: (React.createElement(Grid, { item: true, xs: 12, container: true, justify: otherJustifyContent }, (withoutVat && (withoutVat - discount).toFixed(2)) || '-')),
                },
                {
                    value: (React.createElement(Grid, { item: true, xs: 12, container: true, justify: otherJustifyContent }, (e.vatAmount && e.total && (e.total - e.vatAmount).toFixed(2)) || '-')),
                },
                {
                    value: (React.createElement(Grid, { item: true, xs: 12, container: true, justify: otherJustifyContent }, (e.vatAmount && e.vatAmount.toFixed(2)) || '-')),
                },
                {
                    value: (React.createElement(Grid, { item: true, xs: 12, container: true, justify: otherJustifyContent }, priceValue({ amount: e.total, currency: record.currency }))),
                },
            ];
        };
        return (React.createElement(Grid, { container: true },
            !isSmall ? (React.createElement(Grid, { container: true, className: className.root, style: { paddingBottom: theme.spacing(1) } }, headerColumns.map(function (e) { return (React.createElement(Grid, { item: true, xs: 12, md: e.size, key: e.name },
                React.createElement(TextOverflow, { variant: 'caption', style: __assign({ fontWeight: 700 }, e.style) }, e.name))); }))) : null,
            data.map(function (e) {
                return (React.createElement(Grid, { key: e._id, container: true, alignItems: 'flex-start', className: className.root },
                    React.createElement(BaseListItemV1, { v1: true, headerMobileSize: 4, childs: childsFunc(e), headerColumns: headerColumns })));
            }),
            React.createElement(ServiceTotals, { services: data, item: record, isPreview: true })));
    };
    return (React.createElement(Grid, { container: true, style: { marginBottom: theme.spacing(2) } },
        React.createElement(SectionDetails, { title: langForm.t('form_invoice_create_service'), config: config, query: InvoiceServices, variables: { filter: { fields: [{ name: 'invoice', value: record._id }] } }, dataView: dataView })));
});
