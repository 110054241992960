var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import clsx from 'clsx';
import * as material from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { LimitDatePicker } from '../../../components/LimitDatePicker';
import { toDateOnly } from '../../../utils';
import { OrderQuoteRequestChanges } from '../../../components/Order/List/OrderQuoteRequestChanges';
import { theme } from '../../../theme';
import { HelpIconComponent } from '../../../components/HelpIcon';
import { OrderQuoteStatus, OrderRouteType, OrderStatus, } from '../../../models';
import { BaseUserAppTYpe } from '../../../stores/common';
import moment from 'moment';
import { inject } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../../i18n';
import { TextOverflow } from '../../../components';
import { LoadingUnLoadingTime } from '../LoadingUnLoadingTime';
import { dateViewFormat } from '../../../components/DateView';
import { CargoStatus } from '../CargoStatus';
import { useMiniStyles } from '../../../styles/form';
var Box = material.Box;
export default inject('store')(function (_a) {
    var _b;
    var _c;
    var transportation = _a.transportation, keyVal = _a.keyVal, is_mini = _a.is_mini, mini_component = _a.mini_component, is_review_offer = _a.is_review_offer, accountStore = _a.store.accountStore, onChangeDateRange = _a.onChangeDateRange, config = _a.config, onRequestUpdates = _a.onRequestUpdates, onChange = _a.onChange, item = _a.item;
    var classes = useMiniStyles();
    var langApp = useTranslation(KEYS.APP);
    var langOfferForm = useTranslation(KEYS.OFFER_FORM);
    var langOrderList = useTranslation(KEYS.ORDER_LIST);
    var isSmall = material.useMediaQuery(theme.breakpoints.down('xs'));
    var isStart = keyVal === 'startTime';
    var keyValDate = isStart ? 'startDate' : 'endDate';
    var keyValStatus = isStart ? 'inTransitAt' : 'unloadedAt';
    var loading = transportation.routes.find(function (e) { return e.type === OrderRouteType.Preferred; });
    var unLoading = transportation.routes.find(function (e) { return e.type === OrderRouteType.Delivery; });
    var isCarrier = accountStore.currentUser.appType.includes(BaseUserAppTYpe.CARRIER);
    var hundredYearsBeforeNow = new Date();
    hundredYearsBeforeNow.setFullYear(hundredYearsBeforeNow.getFullYear() - 100);
    var hundredYearsFromNow = new Date();
    hundredYearsFromNow.setFullYear(hundredYearsFromNow.getFullYear() + 100);
    var isStrictly = isStart
        ? loading.allowBookingOnlyOnSelectedPeriods
        : unLoading.allowBookingOnlyOnSelectedPeriods;
    var minDate = isStart
        ? toDateOnly(hundredYearsBeforeNow)
        : transportation.startDate || toDateOnly(hundredYearsBeforeNow);
    var maxDate = isStart ? transportation.endDate || toDateOnly(hundredYearsFromNow) : toDateOnly(hundredYearsFromNow);
    var specialStart = null;
    var specialEnd = null;
    if ((loading === null || loading === void 0 ? void 0 : loading.startDate) && !isStrictly) {
        if (isStart) {
            specialStart = moment(loading.startDate);
            specialEnd = moment(loading.endDate);
            minDate = toDateOnly(hundredYearsBeforeNow);
            maxDate = transportation.endDate;
        }
        else {
            specialStart = moment(unLoading.startDate);
            specialEnd = moment(unLoading.endDate);
            minDate = transportation.startDate;
            maxDate = toDateOnly(hundredYearsFromNow);
        }
    }
    var startProps = {};
    var endProps = {
        startFrom: 
        // (transportation.routes.find((e) => e.type === OrderRouteType.Preferred).origin?.warehouse?._id ===
        //   transportation.routes.find((e) => e.type === OrderRouteType.Delivery).origin.warehouse?._id &&
        (transportation.endDate === transportation.startDate && transportation.startTime[0]) || 0,
    };
    var isBooking = (!is_review_offer || !accountStore.isLoggedIn) &&
        item &&
        item.status === OrderQuoteStatus.Approved &&
        ((_c = item.order) === null || _c === void 0 ? void 0 : _c.status) === OrderStatus.ApprovedQuote;
    var hasTransport = true; //STEP_VALIDATION[QUOTE_STEP.Transport](transportation);
    var v2 = isCarrier && is_mini && isBooking;
    var isCarrierMobile = isSmall && v2;
    var bookingComp = (React.createElement(React.Fragment, null,
        React.createElement(LimitDatePicker, { notDisablePast: true, order: item.order, v3: true, v21: v2, is_mini: is_mini, isSimple: true, route: isStart ? loading : unLoading, specialStart: specialStart, specialEnd: specialEnd, disabled: !hasTransport ||
                is_review_offer ||
                (transportation.inTransitAt && isStart) ||
                (transportation.unloadedAt && !isStart), style: __assign(__assign(__assign(__assign({ width: 'initial', maxWidth: 85 }, (is_mini && { maxWidth: 110, marginRight: 0, height: 22, position: 'relative' })), (v2 && { maxWidth: 115, width: 115 })), ((v2 || (!isCarrier && !v2)) && { marginRight: theme.spacing(2) })), (isCarrierMobile && is_mini && { height: 26 })), className: clsx(classes.rooInputDate, (_b = {}, _b[classes.miniInputDate] = is_mini, _b)), minDate: minDate, maxDate: maxDate, name: keyValDate, defaultValue: transportation[isStart ? 'startDate' : 'endDate'], onChange: function (value) {
                var _a;
                return (isStart
                    ? toDateOnly(transportation.startDate) !== toDateOnly(value)
                    : toDateOnly(transportation.endDate) !== toDateOnly(value)) &&
                    onChangeDateRange((_a = {
                            _id: transportation._id
                        },
                        _a[isStart ? 'startDate' : 'endDate'] = value,
                        _a));
            } }),
        isCarrier && !v2 && accountStore.isLoggedIn ? (React.createElement(OrderQuoteRequestChanges, { is_mini: is_mini, quote: item, order: item.order, config: config, onChange: onRequestUpdates })) : null,
        React.createElement(LoadingUnLoadingTime, __assign({ v21: v2 }, (isStart ? startProps : endProps), { is_mini: is_mini, is_review_offer: is_review_offer, key: transportation[keyValDate], day: transportation[keyValDate], noTransport: !hasTransport, disabled: !hasTransport ||
                is_review_offer ||
                (isStart && transportation.inTransitAt) ||
                transportation.unloadedAt ||
                (transportation.endDate && transportation.endDate < toDateOnly(new Date())), route: isStart ? loading : unLoading, endTo: (isStart &&
                transportation.startDate &&
                moment(transportation.startDate).isSame(moment(transportation.endDate), 'day') &&
                transportation.endTime[0]) ||
                0, keyVal: keyVal, transportation: transportation, onChange: onChange }))));
    var cargoState = (React.createElement(CargoStatus, { isCarrierMobile: isCarrierMobile, is_mini: is_mini, keyVal: keyValStatus, title: isStart ? langOrderList.t('in_progress_has_loaded') : langOfferForm.t("offer_un_loaded"), titleNoOk: isStart ? langOrderList.t('in_progress_has_not_loaded') : langOrderList.t('in_progress_has_not_un_loaded'), item: item, titleConfirm: langApp.t("modal_prompt_question_" + ((is_review_offer && 'no_') || '') + (isStart ? 'in_transit' : 'delivered')), titleConfirmNo: langApp.t("modal_prompt_question_no_" + (isStart ? 'in_transit' : 'delivered')), titleMain: transportation[keyValStatus]
            ? langOfferForm.t("offer_cargo_" + (isStart ? 'in_transit' : 'delivered'))
            : langOfferForm.t("offer_cargo_to_" + (isStart ? 'in_transit' : 'delivered')), transportation: transportation, submitHelp: "offer_cargo_" + (isStart ? 'in_transit' : 'delivered') + "_help", disabled: !hasTransport || transportation[keyValDate] > toDateOnly(new Date()), onChange: onChange }));
    if (is_mini) {
        return (React.createElement(React.Fragment, null,
            React.createElement(Box, { alignItems: 'center', justifyContent: 'center' },
                React.createElement(Box, { display: 'flex', alignItems: 'center', justifyContent: 'center', style: __assign(__assign({ width: 'initial' }, (v2 && { height: 35 })), (isCarrierMobile && { height: 45 })) },
                    React.createElement(Box, { style: __assign({ marginRight: theme.spacing(2), width: 50 }, (isCarrierMobile && { width: 70 })) }, mini_component),
                    bookingComp,
                    v2 && !isSmall ? cargoState : null),
                v2 && !isSmall ? null : cargoState)));
    }
    return (React.createElement(Grid, { container: true, alignItems: 'center' },
        React.createElement(Grid, { container: true, alignItems: 'center', justify: 'flex-start' },
            React.createElement(Grid, { container: true, alignItems: 'center', style: { width: 'initial', marginRight: theme.spacing(1) } },
                React.createElement(TextOverflow, { variant: 'h4' }, langOfferForm.t("offer_" + (isStart ? 'loading' : 'un_loading') + "_time")),
                (!item[keyValStatus] && (React.createElement(HelpIconComponent, { title: langOfferForm.t(isStart ? 'offer_form_transit_status_help' : 'offer_form_delivered_status_help') }))) ||
                    null),
            bookingComp),
        React.createElement(Grid, { container: true, alignItems: 'center', justify: 'flex-start', style: { paddingTop: theme.spacing(1) } },
            is_review_offer &&
                (item[keyValStatus] ? (React.createElement("span", { style: { marginRight: theme.spacing(1) } },
                    langOfferForm.t('offer_confirmed_at'),
                    React.createElement(TextOverflow, { variant: 'caption', color: 'primary' }, dateViewFormat(item[keyValStatus])))) : null),
            cargoState)));
});
