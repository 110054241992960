import React from 'react';
import moment from 'moment';
import { TextOverflow } from './TextOverflow';
import { dateViewFormat } from './DateView';
import { GridV1 } from './Order/List/HTMLElements';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../i18n';
import { theme } from '../theme';
import BaseTooltip from './BaseTooltip';
import { Box } from '@material-ui/core';
var checkDate = function (date) {
    var d = new Date(date);
    if (d.toString() === 'Invalid Date')
        return '';
    var start = moment(d);
    var end = moment(new Date());
    var check = ['years', 'months', 'days', 'hours', 'minutes', 'seconds'];
    var time = start.diff(end, check[0]);
    while (time === 0 && check.length) {
        check.shift();
        time = start.diff(end, check[0]);
    }
    if (check[0] === 'days' && time > 0) {
        time++;
    }
    return {
        time: time,
        label: check[0],
    };
};
export default function TimeLeft(_a) {
    var date = _a.date, style = _a.style;
    var d = checkDate(date);
    var langApp = useTranslation(KEYS.APP);
    if (!d || !d.label || d.time < 0)
        return null;
    return (React.createElement(GridV1, { v1: true, style: style },
        React.createElement(BaseTooltip, { title: [
                langApp.t('main_expire_at').replace('_X_', dateViewFormat(date, 1)),
                langApp.t('app_time_left').replace('_X_', [d.time, langApp.t("app_time_" + d.label)].join(' ')),
            ].join('. ') },
            React.createElement(Box, null,
                React.createElement(TextOverflow, { style: { color: theme.palette.primary.main, pointerEvents: 'none' } }, [d.time, langApp.t("app_time_" + d.label + "_short")].join(' '))))));
}
