var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import clsx from 'clsx';
import { createStyles, Grid, Typography } from '@material-ui/core';
import ChatUserAvatar from './ChatUserAvatar';
import { FilePreview, TextOverflow } from '../../components';
import { dateDifference } from '../../components/DateView';
import { makeStyles } from '@material-ui/core/styles';
import { useMainStyles } from '../../styles';
import { inject } from 'mobx-react';
import { theme } from '../../theme';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../i18n';
var useStyles = makeStyles(function (theme) {
    return createStyles({
        root: {
            marginBottom: theme.spacing(1),
            paddingTop: theme.spacing(1),
        },
        text: {
            backgroundColor: theme.palette.grey['300'],
            boxShadow: '0px 3px 3px rgba(0, 0, 0, 0.1)',
            borderRadius: 4,
            padding: theme.spacing(0.5, 1),
            minWidth: 200,
            maxWidth: '100%',
            marginBottom: theme.spacing(1),
        },
        textRoot: {
            maxWidth: '100%',
            position: 'relative',
            overflow: 'hidden',
            whiteSpace: 'normal',
            boxSizing: 'border-box',
            '& *': {
                maxWidth: '100%',
                overflow: 'hidden',
                whiteSpace: 'normal',
            },
        },
        textLast: {
            '&::before': {
                zIndex: 1,
                position: 'absolute',
                bottom: -2,
                content: "' '",
                width: 0,
                height: 0,
                borderStyle: 'solid',
                borderWidth: "10px 10px 0 0",
                boxSizing: 'border-box',
                borderColor: theme.palette.grey['300'] + " transparent transparent transparent",
                left: theme.spacing(1),
            },
        },
        textLastReverse: {
            '&::before': {
                zIndex: 1,
                position: 'absolute',
                bottom: -2,
                right: theme.spacing(1),
                content: "' '",
                width: 0,
                height: 0,
                borderStyle: 'solid',
                borderWidth: "0 10px 10px 0",
                boxSizing: 'border-box',
                borderColor: "transparent " + theme.palette.primary.light + " transparent transparent",
            },
        },
        textTime: {
            position: 'absolute',
        },
        reverse: {
            flexDirection: 'row-reverse',
            paddingRight: theme.spacing(1),
            '& .textTime': {
                textAlign: 'right',
            },
            '& .textRoot': {
                backgroundColor: theme.palette.primary.light,
                color: theme.palette.common.white,
                '& *': {
                    color: theme.palette.common.white,
                },
            },
        },
    });
});
var ChannelItemDetailsChatHistoryItem = inject('store')(function (_a) {
    var _b;
    var record = _a.record, _id = _a.store.accountStore.currentUser._id, props = __rest(_a, ["record", "store"]);
    var classes = useStyles();
    var langForm = useTranslation(KEYS.FORM);
    var classesMain = useMainStyles();
    return (React.createElement(Grid, { container: true, alignItems: 'flex-end', className: clsx(classes.root, (_b = {}, _b[classes.reverse] = _id === record.createdBy._id, _b)) },
        React.createElement(ChatUserAvatar, { user: record.createdBy }),
        React.createElement(Grid, { container: true, direction: 'column', className: classesMain.widthInitial, style: { maxWidth: "calc(100% - 68px)" } },
            React.createElement(TextOverflow, { className: clsx(classes.textTime, 'textTime'), variant: 'caption', style: (_id !== record.createdBy._id && {
                    left: theme.spacing(1.6),
                }) || { right: theme.spacing(1) } }, dateDifference(record.createdAt)),
            React.createElement(Grid, { container: true, direction: 'column' }, record.messages.map(function (e, index) {
                var _a;
                var content = function (onClick) {
                    var _a;
                    return (React.createElement("div", { className: clsx(classes.text, 'textRoot'), style: (_id !== record.createdBy._id && { marginLeft: theme.spacing(1) }) || {
                            marginRight: theme.spacing(1),
                        } },
                        (e.text && React.createElement(Typography, { variant: 'subtitle1' }, e.text)) || null,
                        React.createElement("a", { href: '#', onClick: onClick },
                            React.createElement(Typography, { variant: 'subtitle1', style: __assign({}, (e.upload && { textDecoration: 'underline' })) }, (_a = e.upload) === null || _a === void 0 ? void 0 : _a.name))));
                };
                var preview = e.upload && props.config ? (React.createElement(FilePreview, { item: e.upload, title: langForm.t('form_btn_view'), config: props.config, actionInit: content })) : null;
                return (React.createElement("div", { key: e._id, className: clsx(classes.textRoot, (_a = {},
                        _a[classes.textLast] = index === record.messages.length - 1 && !(_id === record.createdBy._id),
                        _a[classes.textLastReverse] = index === record.messages.length - 1 && _id === record.createdBy._id,
                        _a)) }, preview || content()));
            })))));
});
export default ChannelItemDetailsChatHistoryItem;
