var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { useObserver } from 'mobx-react';
import { ListItem, ListItemText, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { useIconStyles } from '../../styles';
import clsx from 'clsx';
import BaseTooltip from '../BaseTooltip';
export var useMenuItemStyles = makeStyles(function (theme) { return ({
    select: {
        borderRadius: '0px 5px 5px 0px',
        '&:focus': {
            color: theme.palette.primary.main,
        },
        '&:hover': {
            backgroundColor: theme.palette.secondary.light,
            fill: theme.palette.primary.light,
        },
        '&.Mui-selected': {
            backgroundColor: theme.palette.primary.main,
            '& *': {
                color: theme.palette.common.white,
                fill: theme.palette.common.white,
            },
            '&:hover': {
                backgroundColor: theme.palette.primary.main,
                fill: theme.palette.common.white,
            },
        },
    },
    icon: {
        marginRight: theme.spacing(2),
    },
}); });
export var MenuItem = function (p) {
    var icon = p.icon, title = p.title, props = __rest(p, ["icon", "title"]);
    var classes = useMenuItemStyles();
    var globalIconClasses = useIconStyles();
    return useObserver(function () { return (React.createElement(ListItem, __assign({ className: classes.select, button: true }, props),
        React.createElement(ListItemIcon, null,
            React.createElement(Grid, { container: true, alignItems: "center", justify: "center", className: clsx(globalIconClasses.menuIcon, classes.icon) },
                React.createElement(BaseTooltip, { title: title }, icon))),
        React.createElement(ListItemText, { primary: title }))); });
};
