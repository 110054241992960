var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import gql from 'graphql-tag';
import { BASE_QUERY, BASE_RESPONSE, LOCATION_ADDITIONAL } from '../base';
import { LOCATION_BASE } from '../base';
export var ORDER_ROUTE_BASE_1 = "\n    " + BASE_QUERY + "\n       order{\n        _id\n       }\n       orderTransportation{\n        _id\n       }\n    email \n    startDate \n    endDate \n    type \n    allowBookingOnlyOnSelectedPeriods\n    isCreationCopy\n";
export var ORDER_ROUTE_BASE = "\n    " + ORDER_ROUTE_BASE_1 + "\n       origin{\n        " + LOCATION_BASE + "\n       } \n";
export var ORDER_ROUTE_BASE_2 = "\n    " + ORDER_ROUTE_BASE_1 + "\n       origin{\n        " + LOCATION_ADDITIONAL + "\n       } \n";
export var CreateOrderRoute = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    mutation createOrderRoute($input: OrderRouteInput!) {\n        createOrderRoute(input: $input) {\n            ", "\n        }\n    }\n"], ["\n    mutation createOrderRoute($input: OrderRouteInput!) {\n        createOrderRoute(input: $input) {\n            ", "\n        }\n    }\n"])), ORDER_ROUTE_BASE);
export var UpdateOrderRoute = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    mutation updateOrderRoute($input: OrderRouteInputUpdate!) {\n        updateOrderRoute(input: $input) {\n            ", "\n        }\n    }\n"], ["\n    mutation updateOrderRoute($input: OrderRouteInputUpdate!) {\n        updateOrderRoute(input: $input) {\n            ", "\n        }\n    }\n"])), ORDER_ROUTE_BASE);
export var DeleteOrderRoute = gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    mutation deleteOrderRoute($_id: MongoId!) {\n        deleteOrderRoute(_id: $_id){\n            ", "\n        }\n    }\n"], ["\n    mutation deleteOrderRoute($_id: MongoId!) {\n        deleteOrderRoute(_id: $_id){\n            ", "\n        }\n    }\n"])), BASE_RESPONSE);
var templateObject_1, templateObject_2, templateObject_3;
