var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React from 'react';
import GoogleMapReactV from 'google-map-react';
import { makeStyles, Grid } from '@material-ui/core';
import './map.css';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Link, withRouter } from 'react-router-dom';
import { MainButton } from '../../../MainButton';
import { inject } from 'mobx-react';
import { onPreventEvent } from '../../../Dialog';
import browserHistory from '../../../../browserHistory';
import { NAVIGATION } from '../../../../HOK/LocationHistory';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../../../i18n';
import { OrderStatus } from '../../../../models';
import { getSnapshot } from 'mobx-state-tree';
import { useIconStyles } from '../../../../styles';
import { TextOverflow } from '../../../TextOverflow';
import { createMapOptions, getMapBounds, MAP_DEFAULT_COORDINATES } from '../../../../utils1';
var GoogleMapReact = GoogleMapReactV;
var is_USA_APP = parseInt(process.env.REACT_APP_APP_FOR_USA || '0') === 1;
var is_map_hidden = parseInt(process.env.REACT_APP_HIDE_MAP || '0') === 1;
export var useStyles3 = makeStyles(function (theme) {
    var _a;
    return ({
        map: {
            width: '100%',
            height: '168px',
            minHeight: '168px',
            position: 'relative',
        },
        overview: {
            backgroundColor: theme.palette.common.white,
            border: "2px solid " + theme.palette.primary.light,
            padding: theme.spacing(1),
            position: 'absolute',
            right: 0,
            bottom: 30,
            maxWidth: 200,
            zIndex: 11,
        },
        backButton: (_a = {
                position: 'absolute',
                textDecoration: 'unset',
                zIndex: 2,
                left: 100,
                top: 40
            },
            _a[theme.breakpoints.down('sm')] = {
                left: 'initial',
                top: 'initial',
                bottom: 10,
                right: 10,
            },
            _a),
    });
});
var Marker = function (_a) {
    var text = _a.text, icon = _a.icon, p = __rest(_a, ["text", "icon"]);
    var classesUseIconStyles = useIconStyles();
    return React.createElement("span", { className: classesUseIconStyles.iconMapRoot }, icon);
};
var bindResizeListener = function (map, maps, bounds) {
    maps.event.addDomListenerOnce(map, 'idle', function () {
        maps.event.addDomListener(window, 'resize', function () {
            map.fitBounds(bounds);
        });
    });
};
function makeGradientColor(color1, color2, percent) {
    var newColor = {};
    function makeChannel(a, b) {
        return a + Math.round((b - a) * (percent / 100));
    }
    function makeColorPiece(num) {
        num = Math.min(num, 255); // not more than 255
        num = Math.max(num, 0); // not less than 0
        var str = num.toString(16);
        if (str.length < 2) {
            str = '0' + str;
        }
        return str;
    }
    newColor.r = makeChannel(color1.r, color2.r);
    newColor.g = makeChannel(color1.g, color2.g);
    newColor.b = makeChannel(color1.b, color2.b);
    newColor.cssColor = '#' + makeColorPiece(newColor.r) + makeColorPiece(newColor.g) + makeColorPiece(newColor.b);
    return newColor;
}
function createMarkers(props) {
    var _markers = props.markers;
    if (props.store.orderStore && (!_markers || !_markers.length)) {
        _markers = getSnapshot(props.store.orderStore.order).transportations.map(function (e) {
            return e.routes.map(function (e) { return (__assign(__assign({}, e), { origin: {
                    address: (e.origin &&
                        e.origin.address && __assign(__assign({}, e.origin.address), { lat: (e.origin.address.location && e.origin.address.location.coordinates[1]) || 0, lng: (e.origin.address.location && e.origin.address.location.coordinates[0]) || 0 })) || { lng: 0, lat: 0 },
                } })); });
        });
    }
    _markers = _markers.filter(function (e) { return e.filter(function (f) { return f.origin && f.origin.address.location && f.origin.address.location.coordinates[0]; }).length > 1; });
    return _markers;
}
var SimpleMapComponent = inject('store')(React.memo(function (props) {
    var classes = useStyles3();
    var _markers = createMarkers(props);
    // const mapRef: any = React.createRef();
    var _a = __read(React.useState(null), 2), details = _a[0], setDetails = _a[1];
    var _c = __read(React.useState([]), 2), markers = _c[0], setMarkers = _c[1];
    var _d = __read(React.useState([]), 2), routes = _d[0], setRoutes = _d[1];
    var langForm = useTranslation(KEYS.FORM);
    var langApp = useTranslation(KEYS.APP);
    var store = props.store, v1 = props.v1;
    var defaultProps = __assign({}, MAP_DEFAULT_COORDINATES);
    var drawRoadPath = function (map) {
        if (!_markers[0].length ||
            _markers[0].length < 2 ||
            [
                _markers[0][0].origin.address.location && _markers[0][0].origin.address.location.coordinates[1],
                _markers[0][0].origin.address.location && _markers[0][0].origin.address.location.coordinates[0],
                _markers[_markers.length - 1][1].origin.address.location.coordinates[1],
                _markers[_markers.length - 1][1].origin.address.location.coordinates[0],
            ].filter(function (e) { return e; }).length != 4)
            return console.log('no data for path');
        var start = _markers[0][0].origin.address.location &&
            new google.maps.LatLng(_markers[0][0].origin.address.location.coordinates[1], _markers[0][0].origin.address.location.coordinates[0]);
        var end = _markers[_markers.length - 1][1].origin.address.location &&
            new google.maps.LatLng(_markers[_markers.length - 1][1].origin.address.location.coordinates[1], _markers[_markers.length - 1][1].origin.address.location.coordinates[0]);
        var display = new google.maps.DirectionsRenderer();
        var services = new google.maps.DirectionsService();
        display.setMap(map);
        if (start && end) {
            var request = {
                origin: start,
                destination: end,
                travelMode: 'DRIVING',
            };
            services.route(request, function (result, status) {
                if (status == 'OK') {
                    display.setDirections(result);
                    setRoutes(result.routes);
                    if (window.location.pathname.startsWith('/orders/') &&
                        store.orderStore.order._id &&
                        store.orderStore.order.status === OrderStatus.Draft) {
                        try {
                            store.orderStore.updateOrder({
                                _id: store.orderStore.order._id,
                                distance: result.routes
                                    .map(function (r) {
                                    return r.legs
                                        .filter(function (e) { return e.distance; })
                                        .map(function (leg) { return leg.distance.text; })
                                        .join(',');
                                })
                                    .join(','),
                            });
                        }
                        catch (e) {
                            console.log('==========', e);
                        }
                    }
                    /*const route = result.routes[0];
              const summaryPanel = document.getElementById(
                  "directions-panel"
              ) as HTMLElement;
              summaryPanel.innerHTML = "";
      
              // For each route, display summary information.
              for (let i = 0; i < route.legs.length; i++) {
                const routeSegment = i + 1;
                summaryPanel.innerHTML +=
                    "<b>Route Segment: " + routeSegment + "</b><br>";
                summaryPanel.innerHTML += route.legs[i].start_address + " to ";
                summaryPanel.innerHTML += route.legs[i].end_address + "<br>";
                summaryPanel.innerHTML += route.legs[i].distance!.text + "<br><br>";
              }*/
                }
                else if (store.orderStore.order.distance) {
                    store.orderStore.updateOrder({
                        _id: store.orderStore.order._id,
                        distance: '',
                    });
                }
            });
        }
    };
    var calcBounds = function (d) {
        if (is_map_hidden)
            return;
        var _b = _markers
            .map(function (e) {
            return e.map(function (f) { return ({
                geometry: {
                    location: {
                        lat: f.origin.address.location.coordinates[1],
                        lng: f.origin.address.location.coordinates[0],
                    },
                },
            }); });
        })
            .reduce(function (a, b) { return __spread(a, b); }, [])
            .filter(function (e) { return e; });
        var _details = d || details;
        if (!_b.length || !_details)
            return;
        var map = _details.map, maps = _details.maps;
        var bounds = getMapBounds(map, maps, _b);
        // Fit map to bounds
        map.fitBounds(bounds);
        // Bind the resize listener
        // bindResizeListener(map, maps, bounds);
        // const strokeColor = makeGradientColor({ r: 0, g: 255, b: 204 }, { r: 255, g: 0, b: 0 }, 100);
        var m = __spread(_markers);
        // _markers.forEach((_markers: IZIOrderRoute[]) => {
        //   if (_markers.filter((e: IZIOrderRoute) => e.origin.address.lat && e.origin.address.lng).length < 2) return;
        //   m.push(
        //     new maps.Polyline({
        //       map: map,
        //       path: _markers.map((e: IZIOrderRoute) => new maps.LatLng(e.origin.address.lat, e.origin.address.lng)),
        //       strokeColor: 'rgb(242, 160, 71)',
        //       strokeWeight: 3,
        //       strokeOpacity: 0.5,
        //     })
        //   );
        // });
        drawRoadPath(map);
        setMarkers(m);
    };
    var handleApiLoaded = function (_a) {
        var map = _a.map, maps = _a.maps;
        setDetails({ map: map, maps: maps });
        calcBounds({ map: map, maps: maps });
    };
    React.useEffect(function () {
        calcBounds();
    }, [props.markers]);
    if (is_map_hidden)
        return null;
    var mapKey = process.env.REACT_APP_GOOGLE_PLACES_API_KEY;
    var onBack = function (e) {
        var prevLoc = NAVIGATION.prevLocation.toLowerCase();
        if ((prevLoc || '').trim() &&
            browserHistory.length &&
            !NAVIGATION.hash &&
            !prevLoc.startsWith('/orders/create') /* &&
    (prevLoc.startsWith('/orders') || prevLoc.startsWith('/in-progress') || prevLoc.startsWith('/draft'))*/) {
            browserHistory.goBack();
            return onPreventEvent(e);
        }
    };
    var showMap = v1 ? _markers.length : true;
    return (React.createElement("div", { className: classes.map, id: "map" },
        (!props.hideBack && (React.createElement(Link, { to: "/open-orders", className: classes.backButton },
            React.createElement(MainButton, { id: 'back_to_orders', onClick: onBack, title: langForm.t('form_btn_back') + " " + langApp.t('main_orders').toLowerCase(), variant: "outlined", v1: true, icon: React.createElement(ArrowBackIosIcon, null) })))) ||
            null,
        (routes.length && (React.createElement(Grid, { container: true, className: classes.overview, id: 'map-view' }, routes.map(function (route, index) { return (React.createElement(Grid, { key: "route-" + index, style: { width: '100%' } }, route.legs
            .filter(function (e) { return e.distance; })
            .map(function (leg) { return (React.createElement(Grid, { container: true, key: leg.distance.text },
            React.createElement(TextOverflow, { variant: 'subtitle2' }, [leg.start_address, leg.end_address].join('-')),
            React.createElement(TextOverflow, { variant: 'caption' },
                langForm.t('form_label_distance'),
                ":",
                ' ',
                React.createElement("b", null, is_USA_APP && !(leg.distance.text || '').match('mi')
                    ? (leg.distance.value * 0.000000621371192).toFixed(2) + ' mi'
                    : leg.distance.text)))); }))); })))) ||
            null,
        showMap ? (React.createElement(GoogleMapReact, { bootstrapURLKeys: {
                key: mapKey || '',
                libraries: 'places',
                language: 'en',
            }, center: defaultProps.center, defaultCenter: defaultProps.center, defaultZoom: defaultProps.zoom, 
            // yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded: handleApiLoaded, options: createMapOptions })) : null));
}, function (prevProps, nextProps) {
    var prevMarkers = createMarkers(prevProps);
    var nextMarkers = createMarkers(nextProps);
    if (prevMarkers.length !== nextMarkers.length)
        return false;
    for (var i = 0; i < prevMarkers.length; i++) {
        if (prevMarkers[i]._id !== nextMarkers[i]._id)
            return false;
    }
    return true;
}));
export var SimpleMap = withRouter(SimpleMapComponent);
