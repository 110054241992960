var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import gql from 'graphql-tag';
import { USER_BASE_I } from './base';
import { ORDER_QUOTE_BASE_LESS } from './order';
import { ORDER_BASE } from './order';
export var USER_NOTIFICATION_BASE = "\n     _id\n    createdAt\n    event\n    status\n    createdBy{\n        " + USER_BASE_I + "\n    }\n    user{\n        " + USER_BASE_I + "\n    }\n    order{\n        " + ORDER_BASE + "\n    }\n    offer{\n        " + ORDER_QUOTE_BASE_LESS + "\n    }\n";
export var FetchUserNotifications = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    query userNotifications($filter: MainFilter) {\n        userNotifications(filter: $filter) {\n            data {\n                ", "\n            }\n            count\n        }\n    }\n"], ["\n    query userNotifications($filter: MainFilter) {\n        userNotifications(filter: $filter) {\n            data {\n                ", "\n            }\n            count\n        }\n    }\n"])), USER_NOTIFICATION_BASE);
export var DeleteUserNotification = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  mutation deleteNotification($_id: MongoId!) {\n    deleteNotification(_id: $_id) {\n      status\n      code\n    }\n  }\n"], ["\n  mutation deleteNotification($_id: MongoId!) {\n    deleteNotification(_id: $_id) {\n      status\n      code\n    }\n  }\n"])));
var templateObject_1, templateObject_2;
