var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React from 'react';
import { Form, Formik, useField } from 'formik';
import ReactDOM from 'react-dom';
import EditIcon from '@material-ui/icons/Edit';
import { FormikAutocompleteAsync, ListboxComponent } from './FormikAutocompleteAsync';
import { GooglePlaces } from '../../graphql';
import { OrderLocation, OrderLocationAddress } from '../../models';
import { Grid, IconButton } from '@material-ui/core';
import { TextOverflow } from '../TextOverflow';
import { theme } from '../../theme';
import { getSnapshot } from 'mobx-state-tree';
import { inject } from 'mobx-react';
import { BasicDialog, onPreventEvent } from '../Dialog';
import AddIcon from '@material-ui/icons/Add';
import BaseTooltip from '../BaseTooltip';
import { useTranslation } from 'react-i18next';
import { KEYS, i18n } from '../../i18n';
import * as Yup from 'yup';
import { onlySpacesRegExp } from '../../common';
import { FormikTextField } from './FormikTextField';
import { ACTION_CHANGE } from '../BasicList';
import FormikGoogleTextFieldV1PickTheAddress from './FormikGoogleTextFieldV1PickTheAddress';
import RoomIcon from '@material-ui/icons/Room';
import { AddFormButton } from '../AddFormButton';
export var FormikGoogleTextFieldV1 = inject('store')(function (props) {
    var _a = props.helperText, helperText = _a === void 0 ? '' : _a, onChange = props.onChange, _b = props.margin, margin = _b === void 0 ? 'dense' : _b, _c = props.fullWidth, fullWidth = _c === void 0 ? true : _c, _d = props.variant, variant = _d === void 0 ? 'outlined' : _d, title = props.title, currentCompany = props.store.accountStore.currentCompany, autocompleteProps = props.autocompleteProps, queryProps = props.queryProps, moreProps = props.moreProps, inputProps = __rest(props, ["helperText", "onChange", "margin", "fullWidth", "variant", "title", "store", "autocompleteProps", "queryProps", "moreProps"]);
    var adorRef = React.useRef();
    var chooseOnMapRef = React.useRef();
    var _e = __read(React.useState(0), 2), updated = _e[0], setUpdated = _e[1];
    var _f = __read(React.useState(0), 2), newVal = _f[0], setNewVal = _f[1];
    var _g = __read(useField(props), 3), field = _g[0], meta = _g[1], helpers = _g[2];
    var error = meta.error, touched = meta.touched;
    var _onChange = function (e) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        var value = e.target.value;
        if (args[1] === 'select-option') {
            value = args[0];
        }
        if (!value || !value.name) {
            value = {};
            helpers.setValue(value);
            if (onChange)
                onChange.apply(void 0, __spread([{ target: __assign(__assign({}, field), { value: value }) }], args));
        }
        else if (value && value.address && value.address.country) {
            helpers.setValue(value.address);
            if (onChange)
                onChange({ target: __assign(__assign({}, field), { value: value.address }) }, value);
        }
        else {
            currentCompany.locationGeoCode({ fields: [{ name: 'name', value: value.name }] }).then(function (_address) {
                var value = JSON.parse(JSON.stringify(getSnapshot(OrderLocationAddress.create(_address))));
                helpers.setValue(value);
                var e = { target: __assign(__assign({}, field), { value: value }) };
                if (onChange)
                    onChange.apply(void 0, __spread([e], args));
            });
        }
    };
    var _onChangeAddress = function (value) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        helpers.setValue(value);
        if (onChange)
            onChange.apply(void 0, __spread([{ target: __assign(__assign({}, field), { value: value }) }], args));
        setNewVal(Date.now());
    };
    var addContent = (!props.v25 && (React.createElement(AddFormButton, { id: "choose-on-map", style: {
            justifyContent: 'flex-start',
            background: 'transparent',
            padding: theme.spacing(1, 2),
        }, icon: React.createElement(RoomIcon, null), title: i18n.getResource(i18n.language, KEYS.FORM, 'form_choose_on_map'), onClick: function (e) { return chooseOnMapRef.current.click(); }, fullWidth: true }))) ||
        null;
    var withLabel = variant === 'outlined';
    var titlePlace = !props.withoutPlaceholder
        ? props.placeholder || (typeof props.title === 'string' && props.title) || null
        : '';
    if (withLabel) {
        Object.assign(inputProps, {
            label: props.title || titlePlace,
        });
        delete inputProps.required;
    }
    return (React.createElement(React.Fragment, { key: newVal },
        React.createElement(FormikAutocompleteAsync, __assign({ required: true, resetOnNewRequest: true, title: title, variant: variant, margin: margin, fullWidth: true, v14: true, v16: true, v6: true, name: field.name, isClient: true }, inputProps, { query: GooglePlaces }, queryProps, { value: field.value, client: currentCompany.client(), textFieldProps: __assign(__assign({}, props.textFieldProps), { InputProps: __assign(__assign(__assign({}, props.InputProps), ((inputProps === null || inputProps === void 0 ? void 0 : inputProps.label) && { label: inputProps.label })), { endAdornment: field.value && (React.createElement(Grid, { container: true, style: { width: 'initial' }, ref: function (d) {
                            if (d) {
                                adorRef.current = d;
                                if (!updated)
                                    setUpdated(Date.now());
                            }
                        } })) }) }), autocompleteProps: __assign({ filterOptions: function (e) { return e; }, defaultValue: field.value, 
                // mapValue: (value: IZIBaseCompany) => value && [value.name].filter((e) => e).join(', '),
                getOptionSelected: function (option, value) {
                    return value && option && option._id === value._id;
                }, ListboxProps: {
                    v20: true,
                    hideNoOption: true,
                    addContent: addContent,
                }, ListboxComponent: function (props) { return React.createElement(ListboxComponent, __assign({}, props, { v20: true, hideNoOption: true, addContent: addContent })); }, noDataTitle: '', getOptionLabel: function (option) {
                    if (!option)
                        return '';
                    var address = (option.address && (option.address.address || option.address.location) && option.address) ||
                        (option.location && option);
                    return ([
                        address &&
                            OrderLocation.create(JSON.parse(JSON.stringify({
                                address: address,
                            }))).fullAddress,
                        option.name,
                    ]
                        .filter(function (e) { return e; })
                        .join(', ') || option.title);
                }, renderOption: function (option) {
                    if (!option)
                        return '';
                    return ((option && option.name && (React.createElement(Grid, { container: true, alignItems: 'center' },
                        React.createElement(Grid, { item: true, xs: 12, container: true, alignItems: 'center' }, option.place_id ? (React.createElement(TextOverflow, { style: {
                                width: 'initial',
                            } }, option.name)) : (React.createElement(React.Fragment, null,
                            (OrderLocation.create(JSON.parse(JSON.stringify({ address: option.address }))).fullAddress && (React.createElement(TextOverflow, { style: { width: 'initial', marginRight: theme.spacing(1) } }, OrderLocation.create(JSON.parse(JSON.stringify({ address: option.address }))).fullAddress))) ||
                                null,
                            (option.source === 'google' && option.name && (React.createElement(TextOverflow, { style: {
                                    width: 'initial',
                                } }, option.name))) ||
                                (!option.generated && option.name && (React.createElement(TextOverflow, { style: {
                                        width: 'initial',
                                        color: theme.palette.success.light,
                                    } }, "[" + option.name + "]"))) ||
                                null)))))) ||
                        [OrderLocation.create(JSON.parse(JSON.stringify({ address: option.address }))).fullAddress, option.name]
                            .filter(function (e) { return e; })
                            .join(', ') ||
                        option.title);
                } }, autocompleteProps), onChange: _onChange, onClear: _onChange })),
        (!props.v25 && (React.createElement(FormikGoogleTextFieldV1PickTheAddress, { onSelect: function (adddress) {
                var item = __assign(__assign({}, adddress), addressKeys.map(function (key) {
                    var _a;
                    return (_a = {}, _a[key] = adddress[key] || '', _a);
                }).reduce(function (a, b) { return (__assign(__assign({}, a), b)); }, {}));
                _onChangeAddress(item);
            }, actionInit: function (onOpen) { return React.createElement("span", { ref: chooseOnMapRef, onClick: onOpen }); } }))) ||
            null,
        (!props.v25 && updated && adorRef && adorRef.current && field.value && (React.createElement(EditLocation, { parent: adorRef.current, key: JSON.stringify(field.value), onChange: _onChangeAddress, config: { client: currentCompany.client() }, defaultValue: field.value }))) ||
            null));
});
var addressKeys = ['country', 'area', 'streetName', 'streetNumber', 'state', 'postal'];
var EditLocation = function (props) {
    var _a, _b, _c, _d;
    var langForm = useTranslation(KEYS.FORM);
    var formRef = React.useRef();
    var _e = __read(React.useState(__assign(__assign(__assign({}, props.defaultValue), addressKeys
        .map(function (key) {
        var _a;
        return (_a = {}, _a[key] = (props.defaultValue && props.defaultValue[key]) || '', _a);
    })
        .reduce(function (a, b) { return (__assign(__assign({}, a), b)); }, {})), { zone: ((_b = (_a = props.defaultValue) === null || _a === void 0 ? void 0 : _a.location) === null || _b === void 0 ? void 0 : _b.zone) || '', rawOffset: ((_d = (_c = props.defaultValue) === null || _c === void 0 ? void 0 : _c.location) === null || _d === void 0 ? void 0 : _d.rawOffset) || 0, longitude: (props.defaultValue &&
            props.defaultValue.location &&
            props.defaultValue.location.coordinates &&
            props.defaultValue.location.coordinates[0]) ||
            0, latitude: (props.defaultValue &&
            props.defaultValue.location &&
            props.defaultValue.location.coordinates &&
            props.defaultValue.location.coordinates[1]) ||
            0 })), 1), defaultValue = _e[0];
    var onChange = props.onChange;
    var onOk = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var createdItem, latitude, longitude, rawOffset, zone, item, address_1, res_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    onPreventEvent(e);
                    return [4 /*yield*/, formRef.current.submitForm()];
                case 1:
                    createdItem = _a.sent();
                    if (!createdItem)
                        throw Error('No Valid');
                    if (onChange) {
                        latitude = createdItem.latitude, longitude = createdItem.longitude, rawOffset = createdItem.rawOffset, zone = createdItem.zone, item = __rest(createdItem, ["latitude", "longitude", "rawOffset", "zone"]);
                        address_1 = __assign(__assign({}, item), { location: __assign(__assign({}, item.location), { zone: zone || item.location.zone || '', rawOffset: rawOffset || item.location.rawOffset || 0, type: item.location.type || 'Point', coordinates: [parseFloat(longitude.toString()), parseFloat(latitude.toString())] }) });
                        res_1 = {};
                        [
                            'address',
                            'company',
                            'place_id',
                            'streetNumber',
                            'streetName',
                            'companyAddress',
                            'state',
                            'country',
                            'postal',
                            'area',
                        ].forEach(function (key) { return (res_1[key] = address_1[key]); });
                        res_1.location = {};
                        ['type', 'coordinates', 'rawOffset', 'zone'].forEach(function (key) { return (res_1['location'][key] = address_1['location'][key]); });
                        onChange(res_1, res_1, ACTION_CHANGE.ADDED);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var isEdit = defaultValue.country || defaultValue.streetName;
    var validationSchema = isEdit
        ? __assign({ country: Yup.string()
                .max(50, langForm.t('form_input_validate_max_char').replace(/_X_/, '50'))
                .matches(onlySpacesRegExp, langForm.t('form_input_validate_invalid_text'))
                .required(langForm.t('form_input_validate_required')) }, addressKeys
            .map(function (key) {
            var _a;
            return (_a = {},
                _a[key] = Yup.string()
                    .max(50, langForm.t('form_input_validate_max_char').replace(/_X_/, '50'))
                    .matches(onlySpacesRegExp, langForm.t('form_input_validate_invalid_text')),
                _a);
        })
            .reduce(function (a, b) { return (__assign(__assign({}, a), b)); }, {})) : __assign({}, addressKeys
        .map(function (key) {
        var _a;
        return (_a = {},
            _a[key] = ([addressKeys[0], addressKeys[1], addressKeys[2], addressKeys[5]].includes(key) &&
                Yup.string()
                    .max(50, langForm.t('form_input_validate_max_char').replace(/_X_/, '50'))
                    .matches(onlySpacesRegExp, langForm.t('form_input_validate_invalid_text'))
                    .required(langForm.t('form_input_validate_required'))) ||
                Yup.string()
                    .max(50, langForm.t('form_input_validate_max_char').replace(/_X_/, '50'))
                    .matches(onlySpacesRegExp, langForm.t('form_input_validate_invalid_text')),
            _a);
    })
        .reduce(function (a, b) { return (__assign(__assign({}, a), b)); }, {}));
    validationSchema = Yup.object(validationSchema);
    var dialogBody = (React.createElement(Formik, { innerRef: formRef, initialValues: defaultValue, validationSchema: validationSchema, onSubmit: function (e) { return e; } }, function (formik) {
        var onSelect = function (adddress) {
            var item = __assign(__assign(__assign({}, adddress), addressKeys.map(function (key) {
                var _a;
                return (_a = {}, _a[key] = adddress[key] || '', _a);
            }).reduce(function (a, b) { return (__assign(__assign({}, a), b)); }, {})), { longitude: (adddress.location.coordinates && adddress.location.coordinates[0]) || 0, latitude: (adddress.location.coordinates && adddress.location.coordinates[1]) || 0, zone: adddress.location.zone || '', rawOffset: adddress.location.rawOffset || 0 });
            formik.setValues(item);
        };
        var styles = { marginBottom: theme.spacing(1) };
        return (React.createElement(Form, { style: { width: '100%' }, id: 'form-google-address' },
            React.createElement(Grid, { container: true, spacing: 2, style: styles },
                React.createElement(Grid, { item: true, xs: 12, md: 3 },
                    React.createElement(FormikTextField, { v1: true, placeHolder: langForm.t('form_input_country'), title: langForm.t('form_input_country'), variant: "outlined", name: "country", value: formik.values.country })),
                React.createElement(Grid, { item: true, xs: 12, md: 3 },
                    React.createElement(FormikTextField, { v1: true, placeHolder: langForm.t('form_input_state'), title: langForm.t('form_input_state'), variant: "outlined", name: "state", value: formik.values.state })),
                React.createElement(Grid, { item: true, xs: 12, md: 3 },
                    React.createElement(FormikTextField, { v1: true, placeHolder: langForm.t('form_input_city'), title: langForm.t('form_input_city'), variant: "outlined", name: "area", value: formik.values.area })),
                React.createElement(Grid, { item: true, xs: 12, md: 3 },
                    React.createElement(FormikTextField, { v1: true, placeHolder: langForm.t('form_input_postal'), title: langForm.t('form_input_postal'), variant: "outlined", name: "postal", value: formik.values.postal }))),
            React.createElement(Grid, { container: true, spacing: 2, style: styles },
                React.createElement(Grid, { item: true, xs: 12, md: 3 },
                    React.createElement(FormikTextField, { v1: true, placeHolder: langForm.t('form_input_street_number'), title: langForm.t('form_input_street_number'), variant: "outlined", name: "streetNumber", value: formik.values.streetNumber })),
                React.createElement(Grid, { item: true, xs: 12, md: 3 },
                    React.createElement(FormikTextField, { v1: true, placeHolder: langForm.t('form_input_street'), title: langForm.t('form_input_street'), variant: "outlined", name: "streetName", value: formik.values.streetName })),
                React.createElement(Grid, { item: true, xs: 12, md: 3 },
                    React.createElement(FormikTextField, { v1: true, placeHolder: langForm.t('form_input_latitude'), title: langForm.t('form_input_latitude'), variant: "outlined", name: "latitude", value: formik.values.latitude })),
                React.createElement(Grid, { item: true, xs: 12, md: 3 },
                    React.createElement(FormikTextField, { v1: true, placeHolder: langForm.t('form_input_longitude'), title: langForm.t('form_input_longitude'), variant: "outlined", name: "longitude", value: formik.values.longitude }))),
            React.createElement(Grid, { container: true, spacing: 2, alignItems: 'flex-end', style: styles },
                React.createElement(Grid, { item: true, xs: 12, md: 3 },
                    React.createElement(FormikTextField, { v1: true, placeHolder: langForm.t('form_input_time_offset'), title: langForm.t('form_input_time_offset'), variant: "outlined", name: "rawOffset", type: 'number', value: formik.values.rawOffset })),
                React.createElement(Grid, { item: true, xs: 12, md: 3 },
                    React.createElement(FormikTextField, { v1: true, placeHolder: langForm.t('form_input_time_zone'), title: langForm.t('form_input_time_zone'), variant: "outlined", name: "zone", value: formik.values.zone })),
                React.createElement(Grid, { item: true, xs: 12, md: 3 },
                    React.createElement(FormikGoogleTextFieldV1PickTheAddress, { onSelect: onSelect })))));
    }));
    var title = (isEdit ? langForm.t('form_btn_edit') : langForm.t('form_btn_add')) + " " + langForm
        .t('form_input_name_address')
        .toLowerCase();
    var iconTitle = isEdit
        ? [
            { name: 'country', title: langForm.t('form_input_country') },
            { name: 'state', title: langForm.t('form_input_state') },
            { name: 'area', title: langForm.t('form_input_city') },
            { name: 'postal', title: langForm.t('form_input_postal') },
            { name: 'streetNumber', title: langForm.t('form_input_street_number') },
            { name: 'streetName', title: langForm.t('form_input_street') },
        ]
            .map(function (e) { return e.title + ":" + (defaultValue[e.name] || '-'); })
            .join('; ')
        : title;
    return ReactDOM.createPortal(React.createElement(BasicDialog, { body: dialogBody, onOk: onOk, shouldNotPrevent: true, withoutWrapper: true, dialogProps: { fullWidth: true, maxWidth: 'md' }, title: title, actionInit: function (onOpen) { return (React.createElement(BaseTooltip, { title: iconTitle },
            React.createElement(IconButton, { "aria-label": "menu", onClick: onOpen, color: 'primary', style: { padding: theme.spacing(0.2) } }, isEdit ? React.createElement(EditIcon, { style: { width: 20, height: 20 } }) : React.createElement(AddIcon, { style: { width: 20, height: 20 } })))); } }), props.parent);
};
