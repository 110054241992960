var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var _a;
import React from 'react';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import * as material from '@material-ui/core';
import { Tab, Tabs, createStyles, withStyles } from '@material-ui/core';
import { theme } from '../../theme';
import { makeStyles } from '@material-ui/core/styles';
var Box = material.Box;
export function TabPanel(props) {
    var children = props.children, value = props.value, index = props.index, other = __rest(props, ["children", "value", "index"]);
    return (React.createElement("div", __assign({ role: "tabpanel", hidden: value !== index, id: "simple-tabpanel-" + index, "aria-labelledby": "simple-tab-" + index, style: __assign({ width: '100%' }, props.style) }, other), value === index && (React.createElement(Box, { p: 3 },
        React.createElement(Typography, null, children)))));
}
export function TabPanelV1(props) {
    var children = props.children, value = props.value, index = props.index, other = __rest(props, ["children", "value", "index"]);
    return (React.createElement("div", __assign({ role: "tabpanel", hidden: value !== index, id: "simple-tabpanel-" + index, "aria-labelledby": "simple-tab-" + index }, other),
        React.createElement(Box, { p: 0, style: __assign({}, (value === index ? {} : { display: 'none' })) }, children)));
}
export var TabsV1 = withStyles({
    root: (_a = {
            backgroundColor: theme.palette.common.white,
            marginBottom: -1,
            zIndex: 1
        },
        _a[theme.breakpoints.down('sm')] = {
            width: '100%',
            '& .MuiTabs-flexContainer': {
                flexDirection: 'column',
                '& button': {
                    maxWidth: '100%',
                },
            },
        },
        _a),
    indicator: {
        // display: 'flex',
        justifyContent: 'center',
        width: '100%',
        backgroundColor: theme.palette.success.light,
        top: 0,
        display: 'none',
        bottom: 'initial',
    },
})(function (props) { return React.createElement(Tabs, __assign({}, props)); });
export var TabV1Styles = makeStyles(function (theme) {
    var _a;
    return createStyles({
        root: (_a = {},
            _a[theme.breakpoints.down('sm')] = {
                border: '1px solid #D5DDE5',
            },
            _a),
        last: {
            borderRight: '1px solid #D5DDE5',
        },
    });
});
export var TabV1 = withStyles(function (theme) {
    var _a;
    return createStyles({
        root: (_a = {
                textTransform: 'none',
                minWidth: 72,
                padding: "6px 22px",
                fontWeight: theme.typography.fontWeightRegular,
                marginRight: 0,
                fontSize: '1.2rem',
                borderLeft: '1px solid #D5DDE5',
                borderTop: '1px solid #D5DDE5',
                borderBottom: '1px solid #D5DDE5'
            },
            _a[theme.breakpoints.down('sm')] = {
                width: '100%',
            },
            _a['&:hover'] = {
                color: theme.palette.primary.light,
                opacity: 1,
            },
            _a['&$selected'] = {
                borderTop: "2px solid " + theme.palette.success.light,
                borderBottom: 'none',
                color: theme.palette.primary.light,
                fontWeight: theme.typography.fontWeightMedium,
            },
            _a['&:focus'] = {
                color: theme.palette.primary.light,
            },
            _a['& .MuiTab-wrapper'] = {
                maxWidth: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                display: 'block',
            },
            _a),
        selected: {},
    });
})(function (_a) {
    var _b;
    var isLast = _a.isLast, props = __rest(_a, ["isLast"]);
    var classesTab = TabV1Styles();
    return (React.createElement(Tab, __assign({ disableRipple: true }, props, { className: clsx(classesTab.root, props.className, (_b = {}, _b[classesTab.last] = isLast, _b)) })));
});
export function a11yProps(index) {
    return {
        id: "simple-tab-" + index,
        'aria-controls': "simple-tabpanel-" + index,
    };
}
