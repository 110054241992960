var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React from 'react';
import { Formik, Form } from 'formik';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { FormikAutocomplete, formikAutocompleteMultiOptionsProps, FormikSelect, FormikTextField } from '../Formik';
import * as material from '@material-ui/core';
import moment from 'moment';
import * as Yup from 'yup';
import { onlySpacesRegExp, phoneRegExp, vatCodeRegExp } from '../../common/validators';
import { BaseCompany, BaseUserAppTYpe } from '../../stores/common';
import { getSnapshot } from 'mobx-state-tree';
import { OrderCurrency } from '../../models';
import { useFormStyles } from '../../styles';
import { KEYS } from '../../i18n';
import { FormikSliderRange } from '../Formik/FormikSliderRange';
import { AvatarItem } from './Avatar';
import FormGroup from '@material-ui/core/FormGroup/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import Switch from '@material-ui/core/Switch/Switch';
import { TextOverflow } from '../TextOverflow';
import { BasicDialog, onPreventEvent } from '../Dialog';
import { theme } from '../../theme';
import { toast } from 'react-toastify';
import { FormikGoogleTextFieldV1 } from '../Formik/FormikGoogleTextFieldV1';
import BaseTooltip from '../BaseTooltip';
import { FormikCheckBoxField } from '../Formik/FormikCheckboxField';
var Box = material.Box;
var makeStyles = material.makeStyles, Grid = material.Grid, Typography = material.Typography, MenuItem = material.MenuItem;
var is_USA_APP = parseInt(process.env.REACT_APP_APP_FOR_USA || '0') === 1;
var useFormGroupStyles = makeStyles(function (theme) { return ({
    label: {
        width: 'calc(100% - 50px)',
    },
    root: {
        width: '100%',
    },
}); });
var useStyles = makeStyles(function (theme) { return ({
    section: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(3, 4),
        marginBottom: theme.spacing(2.5),
        width: '100%',
    },
    title: {
        margin: theme.spacing(6, 0, 3, 0),
    },
    marginForm: {
        marginBottom: theme.spacing(2.5),
    },
}); });
export var CompanyProfile = observer(React.forwardRef(function (_a, formRef) {
    var store = _a.store, props = __rest(_a, ["store"]);
    var classes = useStyles();
    var langApp = useTranslation(KEYS.APP);
    var accountStore = store.accountStore;
    var joinRequestCompany = accountStore.currentCompany.joinRequest;
    var onChange = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, name, value, currentVal, e_1;
        var _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _a = e.target, name = _a.name, value = _a.value;
                    _c.label = 1;
                case 1:
                    _c.trys.push([1, 3, , 4]);
                    currentVal = accountStore.currentCompany[name];
                    if (value === ((currentVal && currentVal._id) || currentVal)) {
                        return [2 /*return*/, console.warn('no changes')];
                    }
                    return [4 /*yield*/, accountStore.currentCompany.update((_b = {},
                            _b[name] = (value && value._id) || value,
                            _b))];
                case 2:
                    _c.sent();
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _c.sent();
                    formRef.current.setFieldValue(name, '');
                    toast.error(e_1.message);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(React.Fragment, null,
        React.createElement(Typography, { variant: props.simple ? 'h5' : 'h3', className: classes.title, style: __assign({}, (props.simple && { padding: 0, margin: 0 })) }, langApp.t('profile_company_label')),
        joinRequestCompany ? (React.createElement(Typography, { style: { marginLeft: theme.spacing(3) } }, joinRequestCompany._id === accountStore.currentCompany._id
            ? langApp.t('app_wait_approve_new_company')
            : langApp.t('app_wait_approve_join_company').replace(/_X_/, joinRequestCompany.name))) : (React.createElement(CompanyForm, __assign({}, props, { ref: formRef, record: getSnapshot(accountStore.currentCompany), onChange: onChange })))));
}));
export var CompanyForm = inject('store')(observer(React.forwardRef(function (_a, formRef) {
    var record = _a.record, onChange = _a.onChange, simple = _a.simple, accountStore = _a.store.accountStore, props = __rest(_a, ["record", "onChange", "simple", "store"]);
    var langForm = useTranslation(KEYS.FORM);
    var langApp = useTranslation(KEYS.APP);
    var langSettings = useTranslation(KEYS.SETTINGS);
    var langOrderForm = useTranslation(KEYS.ORDER_FORM);
    var classes = useStyles();
    var formGroupClasses = useFormGroupStyles();
    var classesMain = useFormStyles();
    var _b = __read(React.useState(null), 2), joinCompany = _b[0], setJoinCompany = _b[1];
    var confirmJoinCompanyDialog = React.useRef(null);
    var _onChange = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, name, value, updates, companyWithSameVatCode, currentVal;
        var _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    if (simple)
                        return [2 /*return*/];
                    _a = e.target, name = _a.name, value = _a.value;
                    updates = {
                        name: name,
                        value: (value && value._id) || value,
                    };
                    if (!(name === 'vatCode' &&
                        (!accountStore.currentCompany[name] || accountStore.currentCompany[name] === '') &&
                        value != '')) return [3 /*break*/, 2];
                    return [4 /*yield*/, accountStore.currentCompany.checkVatCodeExists(value)];
                case 1:
                    companyWithSameVatCode = _c.sent();
                    if (companyWithSameVatCode) {
                        setJoinCompany(companyWithSameVatCode);
                        (_b = confirmJoinCompanyDialog.current) === null || _b === void 0 ? void 0 : _b.click();
                        return [2 /*return*/];
                    }
                    _c.label = 2;
                case 2:
                    currentVal = record[name];
                    if (value === ((currentVal && currentVal._id) || currentVal) || value === '') {
                        return [2 /*return*/, console.warn('no changes')];
                    }
                    onChange({
                        target: {
                            name: name,
                            value: (value && value._id) || value,
                        },
                    });
                    return [2 /*return*/];
            }
        });
    }); };
    var defValue = JSON.parse(JSON.stringify(__assign({}, getSnapshot(BaseCompany.create(record)))));
    if (!defValue.settings)
        defValue.settings = {};
    if (!defValue.settings.canViewAllOrders)
        defValue.settings.canViewAllOrders = false;
    if (!defValue.settings.accounting_emails)
        defValue.settings.accounting_emails = [];
    defValue.accounting_emails = defValue.settings.accounting_emails;
    defValue.affiliateFraction = defValue.settings.affiliateFraction;
    ['forwarderOrderMarkUpMinPriceCurrency', 'forwarderOrderMarkUpMaxPriceCurrency'].forEach(function (e) {
        if (!defValue[e])
            defValue[e] = OrderCurrency.EUR;
    });
    [
        'bank',
        'bankCode',
        'bankSwiftCode',
        'bankAccountCode',
        'phone',
        'addressV1',
        'brandName',
        'vatCode',
        'companyCode',
        'p44Code',
        'name',
        'turnover',
        'fastPaymentPercentage',
        'forwarderOrderMarkUpPercentage',
        'affiliateFraction',
        'forwarderOrderMarkUpMinPrice',
        'companyEmail',
        'companyEmailName',
        'forwarderOrderMarkUpMaxPrice',
    ].forEach(function (e) {
        if (!defValue[e])
            defValue[e] = '';
    });
    if (defValue.addressV1)
        ['postal'].forEach(function (e) {
            if (!defValue.addressV1[e])
                defValue.addressV1[e] = '';
        });
    var is_forwarder = accountStore.currentUser.appType.includes(BaseUserAppTYpe.FORWARDER);
    // const is_customer = accountStore.currentUser.appType.includes(BaseUserAppTYpe.CUSTOMER);
    var hasForwarderSettings = is_forwarder && defValue.type === BaseUserAppTYpe.CUSTOMER;
    return (React.createElement(Formik, { innerRef: formRef, initialValues: defValue, key: record._id + "-" + ((record.logo && record.logo._id) || 1), validationSchema: Yup.object(Object.assign({
            accounting_emails: Yup.array()
                .typeError(langForm
                .t('form_input_validate_required_list')
                .replace('_X_', langForm.t('form_input_name_emails').toLowerCase()))
                .of(Yup.string()
                .max(50, langForm.t('form_input_validate_max_char').replace(/_X_/, '50'))
                .email(langForm.t('form_input_validate_invalid_email')))
                .max(10, langForm
                .t('form_input_validate_field_must_have_no_more')
                .replace(/_X_/, '10')
                .replace(/_XX_/, langForm.t('form_input_name_email').toLowerCase())),
            phone: Yup.string()
                .max(50, langForm.t('form_input_validate_max_char').replace(/_X_/g, '50'))
                .matches(phoneRegExp, langForm.t('form_input_validate_invalid_phone')),
            name: Yup.string()
                .max(250, langForm.t('form_input_validate_max_char').replace(/_X_/g, '250'))
                .matches(onlySpacesRegExp, langForm.t('form_input_validate_invalid_text'))
                .required(langForm.t('form_input_validate_required')),
            brandName: Yup.string()
                .max(250, langForm.t('form_input_validate_max_char').replace(/_X_/g, '250'))
                .matches(onlySpacesRegExp, langForm.t('form_input_validate_invalid_text')),
            companyEmail: Yup.string()
                .max(250, langForm.t('form_input_validate_max_char').replace(/_X_/g, '250'))
                .matches(onlySpacesRegExp, langForm.t('form_input_validate_invalid_text')),
            companyEmailName: Yup.string()
                .max(250, langForm.t('form_input_validate_max_char').replace(/_X_/g, '250'))
                .matches(onlySpacesRegExp, langForm.t('form_input_validate_invalid_text')),
            addressV1: Yup.object({
                postal: Yup.string().typeError(langForm.t('form_input_validate_required')),
            })
                .typeError(langForm.t('form_input_validate_required'))
                .test('postal', langForm.t('form_input_validate_required'), function (val) {
                return !!val.postal;
            }),
            turnover: Yup.number()
                .min(0, langForm.t('form_input_validate_min_size').replace(/_X_/g, '0'))
                .max(10000000000000, langForm.t('form_input_validate_max_size').replace(/_X_/g, '10000000000000'))
                .required(langForm.t('form_input_validate_required')),
            vatCode: Yup.string()
                .max(200, langForm.t('form_input_validate_max_char').replace(/_X_/g, '200'))
                .matches(vatCodeRegExp, langForm.t('form_input_invalid_vat_code'))
                .required(langForm.t('form_input_validate_required')),
            companyCode: Yup.string()
                .max(200, langForm.t('form_input_validate_max_char').replace(/_X_/g, '200'))
                .matches(onlySpacesRegExp, langForm.t('form_input_validate_invalid_text'))
                .required(langForm.t('form_input_validate_required')),
            bank: Yup.string()
                .max(200, langForm.t('form_input_validate_max_char').replace(/_X_/g, '200'))
                .matches(onlySpacesRegExp, langForm.t('form_input_validate_invalid_text')),
            bankCode: Yup.string()
                .max(200, langForm.t('form_input_validate_max_char').replace(/_X_/g, '200'))
                .matches(onlySpacesRegExp, langForm.t('form_input_validate_invalid_text')),
            bankSwiftCode: Yup.string()
                .max(200, langForm.t('form_input_validate_max_char').replace(/_X_/g, '200'))
                .matches(onlySpacesRegExp, langForm.t('form_input_validate_invalid_text')),
            bankAccountCode: Yup.string()
                .max(200, langForm.t('form_input_validate_max_char').replace(/_X_/g, '200'))
                .matches(onlySpacesRegExp, langForm.t('form_input_validate_invalid_text')),
        }, simple && {
            name: Yup.string()
                .max(250, langForm.t('form_input_validate_max_char').replace(/_X_/g, '250'))
                .matches(onlySpacesRegExp, langForm.t('form_input_validate_invalid_text')),
            turnover: Yup.number()
                .min(0, langForm.t('form_input_validate_min_size').replace(/_X_/g, '0'))
                .max(10000000000000, langForm.t('form_input_validate_max_size').replace(/_X_/g, '10000000000000')),
            companyCode: Yup.string()
                .max(200, langForm.t('form_input_validate_max_char').replace(/_X_/g, '200'))
                .matches(onlySpacesRegExp, langForm.t('form_input_validate_invalid_text')),
        }, hasForwarderSettings && {
            forwarderOrderMarkUpMinPrice: Yup.number()
                .min(0, langForm.t('form_input_validate_min_size').replace(/_X_/g, '0'))
                .max(100, langForm.t('form_input_validate_max_size').replace(/_X_/g, '100')),
            forwarderOrderMarkUpMaxPrice: Yup.number()
                .min(0, langForm.t('form_input_validate_min_size').replace(/_X_/g, '0'))
                .max(100, langForm.t('form_input_validate_max_size').replace(/_X_/g, '100')),
        })), onSubmit: function (e) { return e; } }, function (formik) {
        var isAll = formik.values._id && !props.isModal;
        var onEnter = function (e, options) { return __awaiter(void 0, void 0, void 0, function () {
            var name, exist, value;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        name = e.target.name;
                        exist = formik.values.accounting_emails.map(function (e) { return e._id || e; }).filter(function (e) { return e; });
                        value = __spread(formik.values.accounting_emails, [options.find(function (e) { return !exist.includes(e._id); })]).filter(function (e) { return e; });
                        formik.setFieldValue(name, value);
                        return [4 /*yield*/, formik.validateField(name)];
                    case 1:
                        _a.sent();
                        if (formik.errors[name]) {
                            return [2 /*return*/, formik.setFieldTouched(name)];
                        }
                        return [2 /*return*/];
                }
            });
        }); };
        var onFieldChangeHandler = function (e) {
            var options = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                options[_i - 1] = arguments[_i];
            }
            return __awaiter(void 0, void 0, void 0, function () {
                var _a, value, name;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            _a = e.target, value = _a.value, name = _a.name;
                            if (!['accounting_emails'].includes(name)) return [3 /*break*/, 4];
                            if (!['create-option', 'select-option', 'remove-option'].includes(options[1])) return [3 /*break*/, 3];
                            return [4 /*yield*/, formik.setFieldValue(name, value)];
                        case 1:
                            _b.sent();
                            return [4 /*yield*/, formik.validateField(name)];
                        case 2:
                            _b.sent();
                            if (formik.errors[name]) {
                                return [2 /*return*/, formik.setFieldTouched(name)];
                            }
                            _b.label = 3;
                        case 3: return [3 /*break*/, 5];
                        case 4:
                            formik.handleChange(e);
                            _b.label = 5;
                        case 5: return [2 /*return*/];
                    }
                });
            });
        };
        return (React.createElement(React.Fragment, null,
            React.createElement(BasicDialog, { onOk: function () { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, accountStore.currentCompany.joinCompanyByVatCode(joinCompany === null || joinCompany === void 0 ? void 0 : joinCompany.vatCode)];
                            case 1:
                                _a.sent();
                                return [2 /*return*/];
                        }
                    });
                }); }, onClose: function () {
                    setJoinCompany(null);
                    formik.setFieldValue('vatCode', '');
                }, body: React.createElement(Box, { textAlign: 'center' }, langForm.t('form_ask_for_company_join_body').replace(/_X_/, (joinCompany === null || joinCompany === void 0 ? void 0 : joinCompany.name) || '')), title: langForm.t('form_ask_for_company_join_title'), actionOkTitle: langApp.t('app_join'), withoutWrapper: true, bodyStyle: { alignItems: 'center', justifyContent: 'center' }, dialogProps: { maxWidth: 'xl' }, actionInit: function (onOpen) { return React.createElement("span", { ref: confirmJoinCompanyDialog, onClick: onOpen }); } }),
            React.createElement("div", { className: classes.section, style: __assign({}, (simple && { paddingTop: 0, paddingBottom: 0, marginBottom: 0, marginTop: 0 })) },
                React.createElement(Form, { id: 'company-form' },
                    React.createElement(Grid, { container: true, spacing: 2, alignItems: 'center' },
                        React.createElement(Grid, { item: true, container: true, direction: "column", spacing: 2, md: isAll ? 10 : 12, xs: 12 },
                            React.createElement(Grid, { container: true, direction: "row", spacing: 2 },
                                React.createElement(Grid, { item: true, xs: 12, sm: 4, style: { marginBottom: theme.spacing(2.5) } },
                                    React.createElement(FormikTextField, { variant: "outlined", name: "name", title: langForm.t('form_input_name_company'), value: formik.values.name, onBlur: _onChange })),
                                React.createElement(Grid, { item: true, xs: 12, md: 4 },
                                    React.createElement(Grid, { container: true, direction: "column" },
                                        React.createElement(Grid, { container: true, direction: "row", spacing: 0, style: { marginBottom: theme.spacing(2.5) } },
                                            React.createElement(Grid, { item: true, xs: 7 },
                                                React.createElement(FormikTextField, { fullWidth: true, className: classesMain.priceInput, variant: "outlined", type: "number", title: langForm.t('form_input_name_turnover'), name: "turnover", value: formik.values.turnover, onBlur: _onChange })),
                                            React.createElement(Grid, { item: true, xs: 5 },
                                                React.createElement(FormikSelect, { fullWidth: true, className: classesMain.currencyInput, variant: "outlined", type: "text", name: "turnoverCurrency", value: formik.values.turnoverCurrency, onChange: _onChange }, Object.keys(OrderCurrency).map(function (value) {
                                                    return (React.createElement(MenuItem, { key: value, value: value }, value));
                                                })))))),
                                React.createElement(Grid, { item: true, xs: 12, sm: 4, style: { marginBottom: theme.spacing(3.5) } },
                                    React.createElement(FormikGoogleTextFieldV1, { v17: true, required: true, title: langForm.t('form_input_name_address'), variant: "outlined", name: "addressV1", value: formik.values.addressV1, onChange: _onChange }))),
                            React.createElement(Grid, { container: true, direction: "row", spacing: 2 },
                                React.createElement(Grid, { item: true, xs: 12, sm: 3, style: { marginBottom: theme.spacing(2.5) } },
                                    React.createElement(FormikTextField, { variant: "outlined", name: "phone", placeholder: "+XXXXXXXXXXXX", title: langForm.t('form_input_name_phone'), value: formik.values.phone, onBlur: _onChange })),
                                React.createElement(Grid, { item: true, xs: 12, sm: 3, style: { marginBottom: theme.spacing(2.5) } },
                                    React.createElement(FormikTextField, { variant: "outlined", name: "companyCode", title: langForm.t('form_input_name_company_code'), value: formik.values.companyCode, onBlur: _onChange })),
                                React.createElement(Grid, { item: true, xs: 12, sm: 3, container: true, direction: "column", style: { marginBottom: theme.spacing(2.5) } },
                                    React.createElement(FormikTextField, { variant: "outlined", name: "incorporationDate", title: langForm.t('form_input_name_created_at'), value: moment(formik.values.createdAt).format('DD/MM/YYYY hh:mm'), disabled: true })),
                                React.createElement(Grid, { item: true, xs: 12, sm: 3, style: { marginBottom: theme.spacing(2.5) } },
                                    React.createElement(FormikTextField, { variant: "outlined", name: "vatCode", title: langForm.t('form_input_name_vat_code'), value: formik.values.vatCode, onBlur: _onChange })),
                                (hasForwarderSettings && (React.createElement(React.Fragment, null,
                                    React.createElement(Grid, { item: true, xs: 12, sm: 3, style: { marginBottom: theme.spacing(2.5) } },
                                        React.createElement(FormikSliderRange, { title: langSettings
                                                .t('settings_order_forwarder_offer_percentage')
                                                .replace(/_X_/, formik.values.forwarderOrderMarkUpPercentage || '0'), variant: "outlined", name: "forwarderOrderMarkUpPercentage", value: formik.values.forwarderOrderMarkUpPercentage, onChangeCommitted: function (e, value) {
                                                return onChange({ target: { name: 'forwarderOrderMarkUpPercentage', value: value } });
                                            } })),
                                    React.createElement(Grid, { item: true, xs: 12, sm: 3, direction: "column", style: { marginBottom: theme.spacing(2.5) } },
                                        React.createElement(Grid, { container: true, direction: "row", spacing: 0 },
                                            React.createElement(Grid, { item: true, xs: 7 },
                                                React.createElement(FormikTextField, { fullWidth: true, className: classesMain.priceInput, variant: "outlined", type: "number", title: langSettings.t('settings_forwarder_min_price'), name: "forwarderOrderMarkUpMinPrice", value: formik.values.forwarderOrderMarkUpMinPrice })),
                                            React.createElement(Grid, { item: true, xs: 5 },
                                                React.createElement(FormikSelect, { fullWidth: true, className: classesMain.currencyInput, variant: "outlined", type: "text", name: "forwarderOrderMarkUpMinPriceCurrency", value: formik.values.forwarderOrderMarkUpMinPriceCurrency }, Object.keys(OrderCurrency).map(function (value) {
                                                    return (React.createElement(MenuItem, { key: value, value: value }, value));
                                                }))))),
                                    React.createElement(Grid, { item: true, xs: 12, sm: 3, direction: "column", style: { marginBottom: theme.spacing(2.5) } },
                                        React.createElement(Grid, { container: true, direction: "row", spacing: 0 },
                                            React.createElement(Grid, { item: true, xs: 7 },
                                                React.createElement(FormikTextField, { fullWidth: true, title: langSettings.t('settings_forwarder_max_price'), className: classesMain.priceInput, variant: "outlined", type: "number", name: "forwarderOrderMarkUpMaxPrice", value: formik.values.forwarderOrderMarkUpMaxPrice })),
                                            React.createElement(Grid, { item: true, xs: 5 },
                                                React.createElement(FormikSelect, { fullWidth: true, className: classesMain.currencyInput, variant: "outlined", type: "text", name: "forwarderOrderMarkUpMaxPriceCurrency", value: formik.values.forwarderOrderMarkUpMaxPriceCurrency }, Object.keys(OrderCurrency).map(function (value) {
                                                    return (React.createElement(MenuItem, { key: value, value: value }, value));
                                                }))))),
                                    React.createElement(Grid, { item: true, xs: 12, sm: 3, direction: "column", style: { marginBottom: theme.spacing(2.5) } },
                                        React.createElement(Grid, { container: true, direction: "row", spacing: 0 },
                                            React.createElement(FormikAutocomplete, __assign({ v17: true }, formikAutocompleteMultiOptionsProps(formik.values.accounting_emails), { style: { width: '100%' }, title: langForm.t('accounting_emails'), help: langForm.t('form_input_name_emails'), name: "accounting_emails", required: true, value: formik.values.accounting_emails, onChange: onFieldChangeHandler, onEnter: onEnter }))))))) ||
                                    null,
                                formik.values._id !== accountStore.currentCompany._id && (React.createElement(Grid, { item: true, xs: 12, sm: 6, direction: "column" },
                                    React.createElement(Grid, { container: true, direction: "row", spacing: 0 },
                                        React.createElement(Grid, { item: true, xs: 12, style: { marginBottom: theme.spacing(4) } },
                                            React.createElement(FormikSelect, { fullWidth: true, className: classesMain.currencyInput, variant: "outlined", title: langForm.t('form_input_name_app_type'), disabled: formik.values.type === BaseUserAppTYpe.SYSTEM, type: "text", name: "type", value: formik.values.type }, (is_forwarder
                                                ? [BaseUserAppTYpe.CARRIER, BaseUserAppTYpe.CUSTOMER]
                                                : props.isAdmin
                                                    ? Object.values(BaseUserAppTYpe)
                                                    : [BaseUserAppTYpe.CARRIER]).map(function (value) {
                                                return (React.createElement(MenuItem, { key: value, value: value }, value));
                                            }))))))),
                            React.createElement(Grid, { container: true, direction: "row", spacing: 2 },
                                React.createElement(Grid, { item: true, xs: 12, sm: 3, style: { marginBottom: theme.spacing(2.5) } },
                                    React.createElement(FormikTextField, { variant: "outlined", name: "bank", title: langForm.t('form_input_name_company_bank'), value: formik.values.bank, onBlur: _onChange })),
                                React.createElement(Grid, { item: true, xs: 12, sm: 3, style: { marginBottom: theme.spacing(2.5) } },
                                    React.createElement(FormikTextField, { variant: "outlined", name: "bankCode", title: langForm.t('form_input_name_company_bank_code'), value: formik.values.bankCode, onBlur: _onChange })),
                                React.createElement(Grid, { item: true, xs: 12, sm: 3, style: { marginBottom: theme.spacing(2.5) } },
                                    React.createElement(FormikTextField, { variant: "outlined", name: "bankSwiftCode", title: langForm.t('form_input_name_company_bank_swift'), value: formik.values.bankSwiftCode, onBlur: _onChange })),
                                React.createElement(Grid, { item: true, xs: 12, sm: 3, style: { marginBottom: theme.spacing(2.5) } },
                                    React.createElement(FormikTextField, { variant: "outlined", name: "bankAccountCode", title: langForm.t('form_input_name_company_bank_account'), value: formik.values.bankAccountCode, onBlur: _onChange }))),
                            ([BaseUserAppTYpe.FORWARDER].includes(accountStore.currentUser.company.type) && (React.createElement(Grid, { container: true, direction: "row", spacing: 2 },
                                React.createElement(Grid, { container: true, item: true, xs: 12, sm: 4, style: { marginBottom: theme.spacing(2.5) } },
                                    React.createElement(Grid, { item: true, xs: 2, style: { paddingLeft: 18 } },
                                        React.createElement(BaseTooltip, { title: formik.values.isFastPayment
                                                ? langSettings.t('company_fast_payment_percentage_on')
                                                : langSettings.t('company_fast_payment_percentage_off') },
                                            React.createElement(Box, { style: { width: 0 } },
                                                React.createElement(FormikCheckBoxField, { style: { padding: 0 }, variant: "outlined", name: "isFastPayment", title: formik.values.isFastPayment
                                                        ? langSettings.t('company_fast_payment_percentage_on')
                                                        : langSettings.t('company_fast_payment_percentage_off'), value: formik.values.isFastPayment, onChange: function (e) {
                                                        return _onChange({ target: { name: 'isFastPayment', value: e.target.checked } });
                                                    } })))),
                                    React.createElement(Grid, { item: true, xs: 10 },
                                        React.createElement(FormikSliderRange, { disabled: !formik.values.isFastPayment, title: langSettings
                                                .t('company_fast_payment_percentage')
                                                .replace(/_X_/, formik.values.fastPaymentPercentage || '0'), variant: "outlined", name: "fastPaymentPercentage", value: formik.values.fastPaymentPercentage, onChangeCommitted: function (e, value) {
                                                return onChange({ target: { name: 'fastPaymentPercentage', value: value } });
                                            } })))))) ||
                                null,
                            props.isAdmin && [BaseUserAppTYpe.FORWARDER].includes(record.type) && (React.createElement(Grid, { container: true, direction: "row", spacing: 2 },
                                React.createElement(Grid, { item: true, xs: 12, sm: 3 },
                                    React.createElement(FormGroup, { key: formik.values.settings.canViewAllOrders, onClick: function (e) {
                                            var value = __assign(__assign({}, formik.values.settings), { canViewAllOrders: !formik.values.settings.canViewAllOrders });
                                            _onChange({
                                                target: {
                                                    name: 'settings',
                                                    value: value,
                                                },
                                            });
                                            formik.setFieldValue('settings', value);
                                            return onPreventEvent(e);
                                        } },
                                        React.createElement(FormControlLabel, { classes: { label: formGroupClasses.label }, className: formGroupClasses.root, label: React.createElement(TextOverflow, null, langForm.t('form_input_company_canViewAllOrders')), control: React.createElement(BaseTooltip, { title: langForm.t(formik.values.settings.canViewAllOrders
                                                    ? 'form_input_company_canViewAllOrders_restrict'
                                                    : 'form_input_company_canViewAllOrders_allow') },
                                                React.createElement(Switch, { color: 'primary', checked: formik.values.settings.canViewAllOrders, name: 'settings' })) }))),
                                React.createElement(Grid, { item: true, xs: 12, sm: 3 },
                                    React.createElement(FormGroup, { key: formik.values.settings.canViewAllOrderBookings, onClick: function (e) {
                                            if (!formik.values.settings.canViewAllOrders)
                                                return;
                                            var value = __assign(__assign({}, formik.values.settings), { canViewAllOrderBookings: !formik.values.settings.canViewAllOrderBookings });
                                            _onChange({
                                                target: {
                                                    name: 'settings',
                                                    value: value,
                                                },
                                            });
                                            formik.setFieldValue('settings', value);
                                            return onPreventEvent(e);
                                        } },
                                        React.createElement(FormControlLabel, { classes: { label: formGroupClasses.label }, className: formGroupClasses.root, disabled: !formik.values.settings.canViewAllOrders, label: React.createElement(TextOverflow, { style: { maxWidth: 'calc(100% - 20px)' } }, langForm.t('form_input_company_canViewAllOrderBookings')), control: React.createElement(BaseTooltip, { title: langForm.t(formik.values.settings.canViewAllOrderBookings
                                                    ? 'form_input_company_canViewAllOrderBookings_restrict'
                                                    : 'form_input_company_canViewAllOrderBookings_allow') },
                                                React.createElement(Switch, { color: 'primary', checked: formik.values.settings.canViewAllOrderBookings, name: 'settings' })) }))),
                                React.createElement(Grid, { item: true, xs: 12, sm: 3 },
                                    React.createElement(FormGroup, { onClick: function (e) {
                                            var value = __assign(__assign({}, formik.values.settings), { isIziBranding: !formik.values.settings.isIziBranding });
                                            _onChange({
                                                target: {
                                                    name: 'settings',
                                                    value: value,
                                                },
                                            });
                                            formik.setFieldValue('settings', value);
                                            return onPreventEvent(e);
                                        } },
                                        React.createElement(FormControlLabel, { classes: { label: formGroupClasses.label }, className: formGroupClasses.root, label: React.createElement(TextOverflow, null, "izi-expedition to izi logistics branding"), control: React.createElement(BaseTooltip, { title: 'All expeditor companies will see platform as izi logistic clients' },
                                                React.createElement(Switch, { color: 'primary', checked: formik.values.settings.isIziBranding, name: 'settings' })) }))))),
                            props.isAdmin && [BaseUserAppTYpe.CARRIER, BaseUserAppTYpe.FORWARDER].includes(record.type) && (React.createElement(Grid, { container: true, direction: "row", spacing: 2 },
                                React.createElement(Grid, { item: true, xs: 12, sm: 3 },
                                    React.createElement(FormGroup, { onClick: function (e) {
                                            var value = __assign(__assign({}, formik.values.settings), { hasToDo: !formik.values.settings.hasToDo });
                                            _onChange({
                                                target: {
                                                    name: 'settings',
                                                    value: value,
                                                },
                                            });
                                            formik.setFieldValue('settings', value);
                                            return onPreventEvent(e);
                                        } },
                                        React.createElement(FormControlLabel, { classes: { label: formGroupClasses.label }, className: formGroupClasses.root, label: React.createElement(TextOverflow, null, "Has to do"), control: React.createElement(BaseTooltip, { title: 'Has to do' },
                                                React.createElement(Switch, { color: 'primary', checked: formik.values.settings.hasToDo, name: 'settings' })) }))))),
                            props.isAdmin && [BaseUserAppTYpe.CUSTOMER, BaseUserAppTYpe.AFFILIATE].includes(record.type) && (React.createElement(Grid, { container: true, direction: "row", spacing: 2 },
                                React.createElement(Grid, { item: true, xs: 12, sm: 3 },
                                    React.createElement(FormGroup, { onClick: function (e) {
                                            var value = __assign(__assign({}, formik.values.settings), { canPublishOrderForAnotherUser: !formik.values.settings
                                                    .canPublishOrderForAnotherUser });
                                            _onChange({
                                                target: {
                                                    name: 'settings',
                                                    value: value,
                                                },
                                            });
                                            formik.setFieldValue('settings', value);
                                            return onPreventEvent(e);
                                        } },
                                        React.createElement(FormControlLabel, { classes: { label: formGroupClasses.label }, className: formGroupClasses.root, label: React.createElement(TextOverflow, null, "Can publish order for another user"), control: React.createElement(BaseTooltip, { title: 'Can publish order for another user' },
                                                React.createElement(Switch, { color: 'primary', checked: formik.values.settings.canPublishOrderForAnotherUser, name: 'settings' })) }))),
                                React.createElement(Grid, { item: true, xs: 12, sm: 3 },
                                    React.createElement(FormikSliderRange, { title: 'Affiliate fraction', v1: true, min: 0, step: 1, max: 100, variant: "outlined", name: "affiliateFraction", value: formik.values.affiliateFraction, onChangeCommitted: function (e) {
                                            var value = __assign(__assign({}, formik.values.settings), { affiliateFraction: formik.values.affiliateFraction });
                                            _onChange({
                                                target: {
                                                    name: 'settings',
                                                    value: value,
                                                },
                                            });
                                            formik.setFieldValue('settings', value);
                                        } })))),
                            React.createElement(Grid, { container: true, direction: "row", spacing: 2 },
                                props.isAdmin && (React.createElement(React.Fragment, null,
                                    React.createElement(Grid, { item: true, xs: 12, sm: 4 },
                                        React.createElement(FormikTextField, { variant: "outlined", name: "brandName", title: 'Brand name', value: formik.values.brandName, onBlur: _onChange })),
                                    React.createElement(Grid, { item: true, xs: 12, sm: 3 },
                                        React.createElement(FormikTextField, { variant: "outlined", name: "p44Code", title: 'Project 44 id', value: formik.values.p44Code, onBlur: _onChange })))),
                                React.createElement(Grid, { item: true, xs: 12, sm: 4 },
                                    React.createElement(FormikTextField, { variant: "outlined", name: "companyEmailName", title: langForm.t('form_input_name'), help: React.createElement("div", { dangerouslySetInnerHTML: {
                                                __html: langForm.t('form_input_name_email_company_name_help') || '',
                                            } }), value: formik.values.companyEmailName, onBlur: _onChange })),
                                React.createElement(Grid, { item: true, xs: 12, sm: 4 },
                                    React.createElement(FormikTextField, { variant: "outlined", name: "companyEmail", title: langForm.t('form_input_name_email'), help: React.createElement("div", { dangerouslySetInnerHTML: {
                                                __html: langForm.t('form_input_name_email_prefix_help') || '',
                                            } }), value: formik.values.companyEmail, onBlur: _onChange })))),
                        (isAll && (React.createElement(Grid, { container: true, direction: "column", alignItems: "center", item: true, xs: 12, md: 2 },
                            React.createElement(AvatarItem, { config: props.config, folder: 'company', parent_collection_name: 'users', user: { firstName: formik.values.name, _id: formik.values._id, avatar: formik.values.logo }, onChange: function (doc) {
                                    onChange({
                                        target: {
                                            name: 'logo',
                                            value: Array.isArray(doc) && doc.length ? doc[0] : doc._id || doc,
                                        },
                                    });
                                } })))) ||
                            null)))));
    }));
})));
