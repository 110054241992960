var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { Grid } from '@material-ui/core';
import ChannelItemDetailsChatHistory from './ChannelItemDetailsChatHistory';
import ChannelItemDetailsChatForm from './ChannelItemDetailsChatForm';
import { theme } from '../../theme';
var ChannelItemDetailsChat = function (props) {
    return (React.createElement(Grid, { container: true, justify: "flex-end", style: { padding: theme.spacing(2) } },
        React.createElement(ChannelItemDetailsChatHistory, __assign({}, props)),
        !props.isAdmin && (React.createElement(Grid, { item: true, xs: 12, md: props.v3 ? 12 : 8 },
            React.createElement(ChannelItemDetailsChatForm, __assign({}, props))))));
};
export default ChannelItemDetailsChat;
