var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import { BasicDialog, MainButton, TextOverflow } from '../../components';
import * as material from '@material-ui/core';
import { Grid, Typography } from '@material-ui/core';
import { theme } from '../../theme';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../i18n';
import { dateViewFormat } from '../../components/DateView';
import { HelpIconComponent } from '../../components/HelpIcon';
import PreloadingComponent from '../../components/PreloadingComponent';
import BaseTooltip from '../../components/BaseTooltip';
import { FormikCheckBoxField } from '../../components/Formik/FormikCheckboxField';
import { Form, Formik } from 'formik';
import { inject } from 'mobx-react';
import { BaseUserAppTYpe } from '../../stores';
var Box = material.Box;
var CargoStatusDetails = React.lazy(function () { return import('./CargoStatusDetails'); });
export var CargoStatus = inject('store')(function (props) {
    var transportation = props.transportation, onChange = props.onChange, keyVal = props.keyVal, title = props.title, titleMain = props.titleMain, titleConfirm = props.titleConfirm, titleConfirmNo = props.titleConfirmNo, disabled = props.disabled, titleNoOk = props.titleNoOk, is_mini = props.is_mini, store = props.store;
    var accountStore = store.accountStore;
    var langForm = useTranslation(KEYS.FORM);
    var langOfferForm = useTranslation(KEYS.OFFER_FORM);
    var langOrderList = useTranslation(KEYS.ORDER_LIST);
    var _a = __read(React.useState(false), 2), loading = _a[0], setLoading = _a[1];
    var isCarrier = accountStore.currentUser.appType.includes(BaseUserAppTYpe.CARRIER);
    var _onChange = function () { return __awaiter(void 0, void 0, void 0, function () {
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    setLoading(true);
                    return [4 /*yield*/, onChange((_a = {
                                _id: transportation._id
                            },
                            _a[(!!transportation[keyVal] ? 'no_' : '') + keyVal] = new Date().toString(),
                            _a[keyVal] = !!transportation[keyVal] ? null : new Date().toString(),
                            _a))];
                case 1:
                    _b.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var v2 = isCarrier && is_mini;
    var dialogToConfirm = (React.createElement(BasicDialog, { onOk: _onChange, title: langForm.t('form_btn_confirm'), bodyStyle: { display: 'flex' }, body: React.createElement(Grid, { container: true, justify: 'center', alignItems: 'center' },
            React.createElement(TextOverflow, null, !!transportation[keyVal] ? titleConfirmNo : titleConfirm)), withoutWrapper: true, actionOkTitle: langForm.t('form_btn_confirm'), actionInit: function (onClick) {
            var _a;
            return is_mini ? (React.createElement(Formik
            // key={`${JSON.stringify(initialValues)}-${loading ? 1 : -1}`}
            , { 
                // key={`${JSON.stringify(initialValues)}-${loading ? 1 : -1}`}
                initialValues: (_a = {}, _a[keyVal] = transportation[keyVal], _a), onSubmit: function (e) { return e; } }, function (formik) {
                var checkboxStyle = disabled ? {} : { color: theme.palette.primary.main };
                var checkbox = (React.createElement(FormikCheckBoxField, { v1: true, style: __assign(__assign({}, checkboxStyle), { padding: 0 }), labelStyle: __assign(__assign({}, checkboxStyle), { marginRight: theme.spacing(1), justifyContent: 'center' }), disabled: loading || disabled, onChange: onClick, color: "primary", value: formik.values[keyVal], name: keyVal, label: React.createElement(Typography, { style: __assign(__assign({}, checkboxStyle), { fontWeight: 600 }) }, keyVal === 'inTransitAt'
                        ? langOrderList.t('in_progress_has_loaded')
                        : langOfferForm.t('offer_un_loaded')) }));
                if (v2) {
                    checkbox = (React.createElement(MainButton, { variant: "contained", disabled: loading || disabled, 
                        // className={classes.btn}
                        title: keyVal === 'inTransitAt'
                            ? langOrderList.t('in_progress_has_loaded')
                            : langOfferForm.t('offer_un_loaded'), disabledStyle: loading || disabled ? { color: theme.palette.secondary.dark } : undefined, onClick: onClick, style: __assign(__assign({ width: '100%', height: '22px' }, (loading || disabled
                            ? {}
                            : formik.values[keyVal]
                                ? { backgroundColor: theme.palette.success.light }
                                : { backgroundColor: theme.palette.primary.main })), (props.isCarrierMobile && {
                            width: theme.spacing(25),
                            height: '26px',
                            margin: theme.spacing(1, 0, 2, 0),
                        })) }));
                }
                return (React.createElement(Form, { style: __assign(__assign({ marginLeft: theme.spacing(2), display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }, checkboxStyle), (props.isCarrierMobile && {
                        justifyContent: undefined,
                        marginLeft: 0,
                    })) }, transportation[keyVal] ? (React.createElement(BaseTooltip, { title: [titleMain, dateViewFormat(transportation[keyVal])].join('. ') },
                    React.createElement(Box, null, checkbox))) : disabled ? (React.createElement(BaseTooltip, { title: [langOfferForm.t(props.submitHelp)].join('. ') },
                    React.createElement(Box, null, checkbox))) : (React.createElement(BaseTooltip, { title: [titleNoOk, title + '?'].join('. ') },
                    React.createElement(Box, null, checkbox)))));
            })) : (React.createElement(Grid, { container: true, alignItems: 'center', style: { width: 'initial' } },
                React.createElement(MainButton, { v3: v2, loading: loading, disabled: loading || disabled, onClick: onClick, title: title, style: { marginRight: theme.spacing(1), height: 20 } }),
                React.createElement(HelpIconComponent, { title: langOfferForm.t(props.submitHelp) })));
        } }));
    var content = is_mini ? (React.createElement(React.Fragment, null, dialogToConfirm)) : (React.createElement(Grid, { container: true, alignItems: 'center', spacing: 1 },
        React.createElement(Grid, { item: true, xs: 12, md: 8 },
            React.createElement(TextOverflow, { variant: 'subtitle1', v1: true }, titleMain)),
        React.createElement(Grid, { item: true, xs: 12, md: 4 }, transportation[keyVal] ? (React.createElement("span", { style: { color: theme.palette.primary.light } }, dateViewFormat(transportation[keyVal]))) : (dialogToConfirm))));
    return (React.createElement(PreloadingComponent, null,
        React.createElement(CargoStatusDetails, __assign({}, props), content)));
});
