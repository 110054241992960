var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import moment from 'moment';
import { Grid, useMediaQuery } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../../i18n';
import { onPreventEvent, PriceComponent, TextOverflow } from '../../../components';
import PresentToAllIcon from '@material-ui/icons/PresentToAll';
import { theme } from '../../../theme';
import { dateViewFormat } from '../../../components/DateView';
import PaymentIcon from '@material-ui/icons/Payment';
import { makeStyles } from '@material-ui/core/styles';
import DocumentsAttached from './DocumentsAttached';
import OrderShortView from '../../../components/Order/OrderShortView';
import ItemActions from './ItemActions';
import { OrderPaymentStatus } from '../../../models/OrderPayment';
import BaseTooltip from '../../../components/BaseTooltip';
import { BaseListItemV1 } from '../../../components/BasicList/ListItem/BaseListItemV1';
import { getLangInvoiceStatus } from '../../Invoices/filters';
export var useInvoiceStyles = makeStyles(function (theme) { return ({
    draft: {
        marginLeft: theme.spacing(2),
        overflow: 'initial',
        '&::before': {
            content: "' '",
            position: 'absolute',
            left: theme.spacing(-2),
            width: 10,
            height: 10,
            borderRadius: '50%',
            backgroundColor: theme.palette.grey['300'],
            top: '50%',
            transform: 'translateY(-50%)',
        },
    },
    unpaid: {
        marginLeft: theme.spacing(2),
        overflow: 'initial',
        '&::before': {
            content: "' '",
            position: 'absolute',
            left: theme.spacing(-2),
            width: 10,
            height: 10,
            borderRadius: '50%',
            backgroundColor: theme.palette.error.main,
            top: '50%',
            transform: 'translateY(-50%)',
        },
    },
    item: {
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    paid: {
        marginLeft: theme.spacing(2),
        overflow: 'initial',
        '&::before': {
            content: "' '",
            position: 'absolute',
            left: theme.spacing(-2),
            width: 10,
            height: 10,
            borderRadius: '50%',
            backgroundColor: theme.palette.success.main,
            top: '50%',
            transform: 'translateY(-50%)',
        },
    },
}); });
export default (function (_a) {
    var record = _a.record, config = _a.config, onChange = _a.onChange, isCost = _a.isCost, expandContent = _a.expandContent, handleExpandClick = _a.handleExpandClick, headerColumns = _a.headerColumns;
    var langForm = useTranslation(KEYS.FORM);
    var langApp = useTranslation(KEYS.APP);
    var className = useInvoiceStyles();
    var isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    var diff = moment(record.endDate).diff(moment(), 'days');
    var lastPayment = record.lastPayment || (record.quote && record.quote.order.lastPayment);
    var status = lastPayment
        ? lastPayment.status === OrderPaymentStatus.PAID &&
            (lastPayment.approvedAt || (lastPayment.releasedAt && !lastPayment.rejectedAt))
            ? OrderPaymentStatus.PAID
            : OrderPaymentStatus.UNPAID
        : record.status;
    var details = lastPayment
        ? lastPayment.status === OrderPaymentStatus.UNPAID
            ? langApp.t('not_received') + ". " + lastPayment.comment
            : lastPayment.approvedAt
                ? langApp.t('received_at') + " " + dateViewFormat(lastPayment.approvedAt, 1)
                : langApp.t('released_at') + " " + dateViewFormat(lastPayment.releasedAt, 1)
        : null;
    var childs = [
        {
            value: (React.createElement(Grid, { item: true, xs: 12, container: true, alignItems: 'center', direction: 'column' },
                React.createElement(TextOverflow, { variant: 'subtitle1' },
                    " ",
                    dateViewFormat(record.startDate)))),
        },
        {
            value: (React.createElement(Grid, { item: true, xs: 12, container: true, alignItems: 'center' },
                (record.sentAt && (React.createElement(BaseTooltip, { title: langForm.t('form_invoice_sent_at') + " " + dateViewFormat(record.sentAt, 1) },
                    React.createElement(PresentToAllIcon, { color: "primary", style: { marginRight: theme.spacing(1) } })))) || React.createElement(PresentToAllIcon, { style: { marginRight: theme.spacing(1) } }),
                React.createElement(Grid, __assign({ container: true, style: { width: 'initial', maxWidth: 'calc(100% - 40px)' } }, (((details || record.payedAt || isNaN(diff) || diff >= 0) && { alignItems: 'center' }) || {
                    direction: 'column',
                })),
                    (details && (React.createElement(BaseTooltip, { title: details },
                        React.createElement(PaymentIcon, { color: "primary", style: { marginRight: theme.spacing(1) } })))) ||
                        (record.payedAt && (React.createElement(BaseTooltip, { title: langForm.t('form_invoice_paid_at') + " " + dateViewFormat(record.payedAt, 1) },
                            React.createElement(PaymentIcon, { color: "primary", style: { marginRight: theme.spacing(1) } })))) ||
                        (diff < 0 && (React.createElement(TextOverflow, { color: 'error', variant: 'caption', style: { marginRight: theme.spacing(1) } }, langForm.t('form_invoice_late').replace(/_X_/, Math.abs(diff).toString())))) || React.createElement(PaymentIcon, { style: { marginRight: theme.spacing(1) } }),
                    React.createElement("div", { style: { position: 'relative' } },
                        React.createElement(TextOverflow, { className: record.status && className[status.toLowerCase()] }, (record.status && getLangInvoiceStatus(status)) || ''))))),
        },
        {
            value: (React.createElement(Grid, { item: true, xs: 12, container: true, alignItems: 'center', onClick: onPreventEvent },
                React.createElement(DocumentsAttached, { record: record, config: config, content: React.createElement(TextOverflow, { style: { maxWidth: "calc(100% - 30px)", cursor: 'pointer' }, className: className.item }, (record.type && (record.type._id ? record.type.name : record.type)) || '-') }))),
        },
        {
            value: (React.createElement(Grid, { item: true, xs: 12, container: true, alignItems: 'center' },
                React.createElement(TextOverflow, null, [record.typeSerial, record.serial].filter(function (e) { return e; }).join(', ') || '-'))),
        },
        {
            value: (React.createElement(Grid, { item: true, xs: 12, container: true, alignItems: 'center' }, (record.quote && record.quote.order && React.createElement(OrderShortView, { record: record.quote })) || '-')),
        },
        {
            value: (React.createElement(Grid, { item: true, xs: 12, container: true, alignItems: 'center' },
                React.createElement(TextOverflow, null, !isCost
                    ? record.client_name || (record.client_id && record.client_id.name) || '-'
                    : record.seller_name || (record.seller_id && record.seller_id.name) || '-'))),
        },
        {
            value: (React.createElement(Grid, { item: true, xs: 12, container: true, alignItems: 'center', justify: isSmall ? 'flex-start' : 'flex-end', style: { width: 'initial' } },
                React.createElement(PriceComponent, { amount: record.total, currency: record.currency, style: { maxWidth: 70 } }),
                React.createElement(ItemActions, __assign({}, { record: record, config: config, onChange: onChange, isCost: isCost })),
                expandContent)),
        },
    ];
    return (React.createElement(BaseListItemV1, { v1: true, headerMobileSize: 3, childs: childs, headerColumns: headerColumns, container: true, style: { padding: theme.spacing(0, 1) }, onClick: handleExpandClick }));
});
